import { typingConstant } from '../constant';

const { ADD_TYPING_STATUS } = typingConstant;

const initialState: any = {
  typingStatus: '' as any,
};

const TypingReducer = (state = initialState, action: any): any => {
  switch (action.type) {
    case ADD_TYPING_STATUS:
      return {
        typingStatus: action.payload.typingStatus,
      };

    default:
      return state;
  }
};
export default TypingReducer;
