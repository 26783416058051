import { dashboardWidgetConstant } from '../constant';

const {
  ADD_NEWS_DASHBOARD_FEED_DATA,
  REMOVE_NEWS_DASHBOARD_FEED_DATA,
  ADD_MOODY_NEWS_FEED_DATA,
  REMOVE_MOODY_NEWS_FEED_DATA,
  ADD_TASKS_WIDGET_DATA,
  REMOVE_TASKS_WIDGET_DATA,
  ADD_NPL_FINANCIAL_WIDGET_DATA,
  REMOVE_NPL_FINANCIAL_WIDGET_DATA,
  ADD_TRANSACTION_REPORTS_WIDGET_DATA,
  REMOVE_TRANSACTION_REPORTS_WIDGET_DATA,
  ADD_EMBEDDED_BROWSER_WIDGET_DATA,
  REMOVE_EMBEDDED_BROWSER_WIDGET_DATA,
  CLEAR_DASHBOARD_SELECTED_NEWS_ENDPOINT,
  SELECT_DASHBOARD_NEWS_ENDPOINT,
  REMOVE_DASHBOARD_NEWS_ENDPOINT,
  ADD_PERFORMANCE_PORTFOLIO_DATA,
  REMOVE_PERFORMANCE_PORTFOLIO_DATA,
} = dashboardWidgetConstant;

const initialState: any = {
  newsFeedWidgetData: [] as any,
  moodyNewsFeedWidgetData: [] as any,
  tasksWidgetData: [] as any,
  nplFinancialWidgetData: [] as any,
  transactionReportsWidgetData: [] as any,
  embeddedBrowserWidgetData: [] as any,
  selectedNewsWidgetData: [] as any,
  performancePortfolioData: [] as any,
};

const filterByRemoveId = (data: any, widgetId: any) => {
  let dataArray = [...data];
  let tempArray = dataArray.filter(x => x.id !== widgetId);
  return tempArray;
};

const filterNewsByRemoveId = (data: any, widgetId: any) => {
  let dataArray = [...data];
  let tempArray = dataArray.filter(x => x._id !== widgetId);
  return tempArray;
};

const updatedStateData = (data: any, widgetData: any) => {
  //newsData = {}
  let tempData = [...data];

  let widgetIndex = tempData.findIndex((x: any) => x?.id === widgetData.id);

  if (widgetIndex > -1) {
    tempData[widgetIndex] = widgetData;
  } else {
    tempData.push(widgetData);
  }

  return tempData;
};

const DashboardWidgetReducer = (state = initialState, action: any): any => {
  switch (action.type) {
    case ADD_NEWS_DASHBOARD_FEED_DATA:
      return {
        ...state,
        newsFeedWidgetData: updatedStateData(
          state.newsFeedWidgetData,
          action.payload.newsFeedWidgetData,
        ),
      };

    case REMOVE_NEWS_DASHBOARD_FEED_DATA:
      return {
        ...state,
        newsFeedWidgetData: filterByRemoveId(
          state.newsFeedWidgetData,
          action.payload.removeId,
        ),
      };

    case ADD_MOODY_NEWS_FEED_DATA:
      return {
        ...state,
        moodyNewsFeedWidgetData: updatedStateData(
          state.moodyNewsFeedWidgetData,
          action.payload.moodyNewsFeedWidgetData,
        ),
      };

    case REMOVE_MOODY_NEWS_FEED_DATA:
      return {
        ...state,
        moodyNewsFeedWidgetData: filterByRemoveId(
          state.moodyNewsFeedWidgetData,
          action.payload.removeId,
        ),
      };

    case ADD_TASKS_WIDGET_DATA:
      return {
        ...state,
        tasksWidgetData: updatedStateData(
          state.tasksWidgetData,
          action.payload.tasksWidgetData,
        ),
      };

    case REMOVE_TASKS_WIDGET_DATA:
      return {
        ...state,
        tasksWidgetData: filterByRemoveId(
          state.tasksWidgetData,
          action.payload.removeId,
        ),
      };

    case ADD_NPL_FINANCIAL_WIDGET_DATA:
      return {
        ...state,
        nplFinancialWidgetData: updatedStateData(
          state.nplFinancialWidgetData,
          action.payload.nplFinancialWidgetData,
        ),
      };

    case REMOVE_NPL_FINANCIAL_WIDGET_DATA:
      return {
        ...state,
        nplFinancialWidgetData: filterByRemoveId(
          state.nplFinancialWidgetData,
          action.payload.removeId,
        ),
      };

    case ADD_TRANSACTION_REPORTS_WIDGET_DATA:
      return {
        ...state,
        transactionReportsWidgetData: updatedStateData(
          state.transactionReportsWidgetData,
          action.payload.transactionReportsWidgetData,
        ),
      };

    case REMOVE_TRANSACTION_REPORTS_WIDGET_DATA:
      return {
        ...state,
        transactionReportsWidgetData: filterByRemoveId(
          state.transactionReportsWidgetData,
          action.payload.removeId,
        ),
      };

    case ADD_EMBEDDED_BROWSER_WIDGET_DATA:
      return {
        ...state,
        embeddedBrowserWidgetData: updatedStateData(
          state.embeddedBrowserWidgetData,
          action.payload.embeddedBrowserWidgetData,
        ),
      };

    case REMOVE_EMBEDDED_BROWSER_WIDGET_DATA:
      return {
        ...state,
        embeddedBrowserWidgetData: filterByRemoveId(
          state.embeddedBrowserWidgetData,
          action.payload.removeId,
        ),
      };

    // For dashboard news endpoints

    case SELECT_DASHBOARD_NEWS_ENDPOINT:
      return {
        ...state,
        selectedNewsWidgetData: updatedStateData(
          state.selectedNewsWidgetData,
          action.payload.selectedNewsWidgetData,
        ),
      };

    case CLEAR_DASHBOARD_SELECTED_NEWS_ENDPOINT:
      return {
        ...state,
        selectedNewsWidgetData: filterNewsByRemoveId(
          state.selectedNewsWidgetData,
          action.payload.removeId,
        ),
      };

    case REMOVE_DASHBOARD_NEWS_ENDPOINT:
      return {
        ...state,
        selectedNewsWidgetData: [],
      };
    case ADD_PERFORMANCE_PORTFOLIO_DATA:
      return {
        ...state,
        performancePortfolioData: updatedStateData(
          state.performancePortfolioData,
          action.payload.performancePortfolioData,
        ),
      };

    case REMOVE_PERFORMANCE_PORTFOLIO_DATA:
      return {
        ...state,
        performancePortfolioData: filterByRemoveId(
          state.performancePortfolioData,
          action.payload.removeId,
        ),
      };

    default:
      return state;
  }
};
export default DashboardWidgetReducer;
