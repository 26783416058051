import React, { useEffect, useState, JSX } from 'react';
import useAction from '../../../../../components/hooks/useAction';
import { useSelector } from 'react-redux';
import { userDetailsData } from '../../../../../redux/selector/UserDetailsSelector';
import axios from 'axios';
import config from '../../../../../../config';
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';
import classNames from 'classnames';
import {
  IconCross,
  IconDropdownArrow,
  IconEye,
  IconEyeHide,
  IconInfo,
  // IconSearch,
} from '../../../../../components/public/Icons';
import CustomSelect from '../../../../../components/ui/CustomSelect';
import { CustomTooltip } from '../../../../../components/ui/CustomTooltip';
import LoanCollateralButton from '../../addportfoliowidget/components/LoanCollateralButton';
import { isAxiosError } from '../../../../../redux/constant';
import PortfolioService from '../../../../../services/portfolio.service';
import loaderGIF from '../../../../../../assets/images/building-data-loader.gif';
import unidecode from 'unidecode';
import { AvailableAliasTypes } from '../../../../../../@types/PortfolioType';
import { cloneDeep } from 'lodash';

const portfolioService = new PortfolioService();

type ModelListTypes = {
  _id: string;
  model_name: string;
  display_name: string;
};

type PayloadField = {
  actualFieldName: string;
  synonymsField: string;
  type: 'FINANCIAL' | 'COLLATERAL' | 'OTHER';
};

interface ResponseItem {
  key: string;
  value: Record<string, any>;
}

type PortfoliosData = {
  key: string;
  value: {
    [key: string]: FieldData;
  };
};

interface FieldData {
  type: string;
  actualFieldName: string;
  alias_suggestion_required: boolean;
  hidden?: boolean;
}

interface DataItem {
  key: string;
  value: { [key: string]: FieldData };
}

interface ValueData {
  [key: string]: FieldData;
}

interface DataObject {
  key: string;
  value: ValueData;
}

type RowPreviewDataTypes = {
  [key: string]: Array<{ [key: string]: string | number | null }>;
};

type MapPortfolioDataPropsTypes = {
  combinedArray: Array<string>;
  portfoliosDataArray: Array<DataObject>;
  isRefresh: boolean;
  setIsRefresh: React.Dispatch<React.SetStateAction<boolean>>;
  widgetId: number | string;
  modal: boolean;
  toggle: () => void;
  allFileNames: {
    [key: string]: {
      key: string;
      file_name: string;
    };
  };
  allPortfolios: {
    key: string;
    value: {
      key: string;
      file_name: string;
    };
  }[];
  rowPreviewData: RowPreviewDataTypes;
  availableAliasName: AvailableAliasTypes;
  creditWidgetReduxData: any;
};

const UpdateMapPortfolioData = ({
  modal,
  toggle,
  combinedArray,
  portfoliosDataArray,
  allFileNames,
  allPortfolios,
  setIsRefresh,
  isRefresh,
  rowPreviewData,
  availableAliasName,
  creditWidgetReduxData,
}: MapPortfolioDataPropsTypes) => {
  const [isLoading, setIsLoading] = useState(false);
  const { showModal, addPortfolioCreditWidgetData } = useAction();

  const [modelList, setModelList] = useState<
    Array<{
      label: JSX.Element;
      value: string;
    }>
  >([]);
  const userDetails = useSelector(userDetailsData);

  const [selectedModelList, setSelectedModelList] = useState<{
    label: JSX.Element;
    value: string;
  } | null>(null);

  const [portfoliosData, setPortfoliosData] = useState<DataItem[]>(
    portfoliosDataArray || [],
  );
  const oldPortfoliosData = cloneDeep(creditWidgetReduxData?.oldPortfoliosData);

  const [currentActiveTab, setCurrentActiveTab] = useState(
    allPortfolios[0]?.key,
  );
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    if (currentIndex < rowPreviewData?.[currentActiveTab]?.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  // Toggle active state for Tab
  const toggleTab = (tab: React.SetStateAction<string>) => {
    setCurrentIndex(0);
    if (currentActiveTab !== tab) setCurrentActiveTab(tab);
  };

  const getFoundationalModelList = async () => {
    try {
      const response = await axios({
        method: 'get',
        url: `${config.ER_ML_URL}/llm/list`,
      });
      const responseManipulation: ModelListTypes[] = response?.data?.data;
      let updatedRes = responseManipulation?.map(x => {
        return {
          label: (
            <div className="select-img">
              {/* <img
                src={ImgOrca2}
                height="16"
                width="16"
                className="img-cover"
              /> */}
              <span>{x.display_name}</span>
            </div>
          ),
          value: x?.display_name,
        };
      });

      setSelectedModelList(updatedRes[0]);
      setModelList(updatedRes);
    } catch (error) {
      showModal('something went wrong');
    }
  };

  const getAliasSuggestion = async (index: number, fieldName: string) => {
    setIsLoading(true);
    try {
      const payload = {
        field_name: fieldName,
        model_id: selectedModelList?.value,
      };
      const response = await axios({
        method: 'post',
        url: `${config.ER_ML_URL}/portfolio/get-alias-suggestion`,
        data: payload,
      });
      let aliasSuggestionName = response?.data?.name || '';

      handleInputChange(index, aliasSuggestionName);

      setIsLoading(false);
    } catch (error) {
      showModal('something went wrong');
      setIsLoading(false);
    }
  };

  const updateCategory = (
    fieldName: string,
    category: 'FINANCIAL' | 'COLLATERAL' | 'OTHER',
  ) => {
    setPortfoliosData(prevData =>
      prevData.map(data => ({
        ...data,
        value: Object.fromEntries(
          Object.entries(data.value).map(([key, field]) => {
            // Ensure the returned value is always a tuple [key, fieldData]
            const updatedField =
              key === fieldName
                ? {
                    ...field,
                    type: category,
                  }
                : field;

            return [key, updatedField] as [string, FieldData]; // Explicitly assert the tuple type
          }),
        ),
      })),
    );
  };

  const updateActualFieldName = (index: number, value: string) => {
    setPortfoliosData(prevPortfolios => {
      const updatedPortfolios = [...prevPortfolios];
      const activePortfolio = updatedPortfolios.find(
        item => item.key === currentActiveTab,
      );
      if (activePortfolio) {
        const fieldNames = Object.keys(activePortfolio.value);
        const fieldName = fieldNames[index];
        if (fieldName) {
          activePortfolio.value[fieldName].actualFieldName = value;
        }
      }
      return updatedPortfolios;
    });
  };

  const handleInputChange = (index: number, value: string) => {
    updateActualFieldName(index, value);
  };

  const toggleFieldVisibility = (key: string, fieldName: string) => {
    setPortfoliosData(prevData =>
      prevData.map(item =>
        item.key === key
          ? {
              ...item,
              value: {
                ...item.value,
                [fieldName]: {
                  ...item.value[fieldName],
                  hidden: !item.value[fieldName]?.hidden,
                },
              },
            }
          : item,
      ),
    );
  };

  const transformPortfoliosData = (
    data: PortfoliosData[],
    previousData: { key: string; value: any }[] = [],
  ): { fields: PayloadField[] } => {
    const fields: PayloadField[] = data.flatMap(item =>
      Object.entries(item.value).flatMap(
        ([synonymsField, field]): PayloadField[] => {
          const { type, actualFieldName } = field;

          // Find the corresponding entry in previousData by key
          const previousItem = previousData.find(
            prevItem => prevItem.key === item.key,
          );
          const previousField = previousItem?.value[synonymsField];

          // Check if the actualFieldName has changed
          const isChanged =
            previousField &&
            (previousField?.actualFieldName !== actualFieldName ||
              (type
                ? type !== previousField?.type
                : availableAliasName[synonymsField]?.field_type !==
                  previousField?.type));

          // Only add fields where the actualFieldName has changed
          if (isChanged) {
            return [
              {
                actualFieldName: actualFieldName
                  ? actualFieldName
                  : availableAliasName[synonymsField]?.alias_name ||
                    synonymsField,
                synonymsField,
                type: (type ||
                  availableAliasName[synonymsField]?.field_type) as
                  | 'FINANCIAL'
                  | 'COLLATERAL',
              },
            ];
          }

          // If no changes, return an empty array
          return [];
        },
      ),
    );
    return { fields };
  };

  const transformDataForMerge = (
    data: Record<
      string,
      {
        type: string;
        actualFieldName: string;
        alias_suggestion_required: boolean;
      }
    >,
  ) => {
    const transformedData: Record<string, string> = {};

    for (const [key, value] of Object.entries(data)) {
      if (value.actualFieldName) {
        transformedData[key] = value.actualFieldName;
      } else {
        transformedData[key] = key;
      }
    }

    return transformedData;
  };

  const filterJsonFiles = (data: Record<string, any>, isJson: boolean) => {
    if (isJson) {
      return Object.fromEntries(
        Object.entries(data).filter(([key]) => key.endsWith('.json')),
      );
    } else {
      return Object.fromEntries(
        Object.entries(data).filter(([key]) => !key.endsWith('.json')),
      );
    }
  };

  const arrayToObject = (arr: Record<string, any>[]) => {
    return arr.reduce((acc, current) => {
      return { ...acc, ...current };
    }, {});
  };

  const formatData = (data: ResponseItem[]) => {
    return data.map(item => {
      const formattedValue: Record<string, any> = {};

      Object.entries(item.value).forEach(([key, obj]) => {
        if (!obj.hidden) {
          formattedValue[key] = {
            ...obj,
            [key]: obj.actualFieldName || key,
          };
        }
      });

      return {
        [allFileNames[item.key]?.key]: transformDataForMerge(formattedValue),
      };
    });
  };

  const replaceInnerQuotes = (jsonString: string): string => {
    // Replace only double quotes inside the string content with single quotes
    return jsonString.replace(/([a-zA-Z])"([a-zA-Z])/g, "$1'$2");
  };

  const preprocessJsonData = (jsonData: any) => {
    // Convert JSON object to string
    let jsonString = JSON.stringify(jsonData);

    // Remove or replace non-ASCII characters
    jsonString = unidecode(jsonString);

    // Parse the processed string back to JSON
    return JSON.parse(jsonString);
  };

  const mergeApiCall = async (data?: any) => {
    setIsLoading(true);
    const payload = {
      parquet_files:
        data?.length > 0
          ? replaceInnerQuotes(
              JSON.stringify(
                preprocessJsonData(filterJsonFiles(arrayToObject(data), false)),
              ),
            )
          : replaceInnerQuotes(
              JSON.stringify(
                preprocessJsonData(
                  filterJsonFiles(
                    arrayToObject(formatData(portfoliosData)),
                    false,
                  ),
                ),
              ),
            ),
      json_files:
        data?.length > 0
          ? replaceInnerQuotes(
              JSON.stringify(
                preprocessJsonData(filterJsonFiles(arrayToObject(data), true)),
              ),
            )
          : replaceInnerQuotes(
              JSON.stringify(
                preprocessJsonData(
                  filterJsonFiles(
                    arrayToObject(formatData(portfoliosData)),
                    true,
                  ),
                ),
              ),
            ),
    };

    axios
      .post(`${config.ER_ML_URL}/data-bricks/run-merge-job`, payload)
      .then(response => {
        setIsLoading(false);

        addPortfolioCreditWidgetData({
          ...creditWidgetReduxData,

          portfolio_id: response?.data?.portfolio_id,

          portfoliosData: portfoliosData,
          oldPortfoliosData: cloneDeep(portfoliosData),
        });
        toggle();
        // togglePortfolioWidget();
        // document.body.classList.remove('show-dataitem');
      })
      .catch(err => {
        setIsLoading(false);
        if (isAxiosError(err)) {
          showModal(
            (err.response?.data as { error?: string })?.error ||
              'Unexpected Axios error',
          );
        } else {
          showModal('something went wrong');
        }
      });
  };

  const getHeaderDataOneByOne = async () => {
    if (combinedArray?.length === 1) {
      addPortfolioCreditWidgetData({
        ...creditWidgetReduxData,

        portfolio_id: combinedArray[0],

        portfoliosData: portfoliosData,
        oldPortfoliosData: cloneDeep(portfoliosData),
      });
      setIsRefresh(!isRefresh);
      toggle();
    }

    if (
      combinedArray?.length === portfoliosData?.length &&
      combinedArray?.length !== 1
    ) {
      mergeApiCall([]);
    }

    if (
      combinedArray?.length !== portfoliosData?.length &&
      combinedArray?.length !== 1
    ) {
      try {
        const results = await Promise.all(
          combinedArray.map(async id => {
            const response = await axios({
              method: 'get',
              url: `${config.ER_ML_URL}/portfolio/get-headers/${id}`,
            });
            return {
              [allFileNames[id]?.key]: transformDataForMerge(
                response?.data?.header_schema?.data,
              ),
            };
          }),
        );

        mergeApiCall(results);
      } catch (err) {
        if (isAxiosError(err)) {
          showModal(
            (err.response?.data as { error?: string })?.error ||
              'Unexpected Axios error',
          );
        } else {
          showModal('something went wrong');
        }
      }
    }
  };

  const saveSynonymsData = () => {
    setIsLoading(true);
    const payload = transformPortfoliosData(portfoliosData, oldPortfoliosData);
    if (payload?.fields?.length > 0) {
      portfolioService
        .addSynonymsFields(payload)
        .then(() => {
          setIsLoading(false);

          getHeaderDataOneByOne();
        })
        .catch(err => {
          setIsLoading(false);
          if (isAxiosError(err)) {
            showModal(
              (err.response?.data as { error?: string })?.error ||
                'Unexpected Axios error',
            );
          } else {
            showModal('something went wrong');
          }
        });
    } else {
      getHeaderDataOneByOne();
    }
  };

  useEffect(() => {
    if (userDetails?._id) getFoundationalModelList();
  }, [userDetails?._id]);

  const closeBtn = (
    <button className="close" onClick={toggle} type="button">
      <IconCross fill="#36567D" />
    </button>
  );

  const activeData = portfoliosData.find(item => item.key === currentActiveTab);
  const currentRow = rowPreviewData?.[currentActiveTab]?.[currentIndex];

  const toggleAllFieldsVisibility = (key: string, shouldHide: boolean) => {
    setPortfoliosData(prevData =>
      prevData.map(item =>
        item.key === key
          ? {
              ...item,
              value: Object.fromEntries(
                Object.entries(item.value).map(([field, data]) => [
                  field,
                  {
                    ...data,
                    hidden: shouldHide, // Set the hidden value based on shouldHide
                  },
                ]),
              ),
            }
          : item,
      ),
    );
  };

  // Helper function to determine whether to hide or show all fields
  const determineShouldHideAll = (item: {
    value: { [key: string]: { hidden?: boolean } };
  }) => {
    return Object.values(item.value).some(
      field => field && field.hidden !== true,
    );
  };

  // Usage in component
  const handleToggleAll = (key: string) => {
    // Find the specific item by key
    const currentItem = portfoliosData.find(item => item.key === key);

    if (currentItem) {
      // Determine whether to hide or show all based on current visibility
      const shouldHideAll = determineShouldHideAll(currentItem);

      // Toggle all fields based on the above check
      toggleAllFieldsVisibility(key, shouldHideAll);
    }
  };

  return (
    <Modal isOpen={modal} toggle={toggle} centered size="xl">
      <ModalHeader toggle={toggle} close={closeBtn}>
        Normalize and Map Portfolio
      </ModalHeader>
      <ModalBody>
        {isLoading ? (
          <div className="loader flexbox-align-c">
            <img src={loaderGIF} alt="" />
          </div>
        ) : (
          <div className="main-widget pe-0 widget-clear">
            <div className="react-grid-item">
              <div className="common-widget">
                <div className="tabs-menu-line">
                  <Nav tabs>
                    {allPortfolios?.map(pList => (
                      <NavItem>
                        <NavLink
                          className={classNames({
                            active: currentActiveTab === pList.key,
                          })}
                          onClick={() => {
                            toggleTab(pList.key);
                          }}
                        >
                          {pList.value?.file_name}
                        </NavLink>
                      </NavItem>
                    ))}
                  </Nav>
                </div>
                <TabContent activeTab={currentActiveTab}>
                  <TabPane tabId={currentActiveTab}>
                    <div className="flexbox">
                      <div className="flexbox-c gap-3 mb-2">
                        {/* <div className="form-group form-icon mb-0">
                          <span className="input-icon">
                            <IconSearch />
                          </span>
                          <input
                            id="searchQueryEndpoints"
                            type="text"
                            placeholder="Search"
                            className="form-control"
                          />
                        </div> */}
                        <button
                          className="btn btn-outline-secondary text-info"
                          type="button"
                          onClick={() => {
                            handleToggleAll(activeData?.key as string);
                          }}
                        >
                          Toggle Hide All
                        </button>
                      </div>
                      {modelList?.length > 0 && (
                        <div className="mb-2 me-2">
                          <div className="ai-tools">
                            <div className="select-clear">
                              <CustomSelect
                                options={modelList}
                                value={selectedModelList}
                                isSearchable={false}
                                render
                                onChange={(e: {
                                  label: JSX.Element;
                                  value: string;
                                }) => {
                                  setSelectedModelList(e);
                                }}
                              />
                              <div className="arrow-icon">
                                <IconDropdownArrow />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="table-responsive tablescroll">
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th
                              style={{ width: '55px' }}
                              scope="col"
                              className="text-center"
                            >
                              Hide
                            </th>
                            <th style={{ width: '220px' }} scope="col">
                              <div className="flexbox-c gap-1">
                                Row Data Mapping
                                <span
                                  className="d-flex zindex-1"
                                  id="bloomberg"
                                >
                                  <IconInfo />
                                </span>
                                <CustomTooltip
                                  placement="top"
                                  target="bloomberg"
                                />
                              </div>
                            </th>
                            <th style={{ width: '170px' }} scope="col">
                              Imported header
                            </th>
                            <th style={{ width: '210px' }} scope="col">
                              Header Alias
                            </th>
                            <th style={{ width: '200px' }} scope="col">
                              ROW Data Preview
                            </th>
                            <th>
                              <div className="flexbox gap-12">
                                <button
                                  className="btn-clear link"
                                  onClick={handlePrevious}
                                  disabled={currentIndex === 0}
                                >
                                  Previous
                                </button>
                                <button
                                  className="btn-clear link"
                                  onClick={handleNext}
                                  disabled={
                                    currentIndex ===
                                    rowPreviewData?.[currentActiveTab]?.length -
                                      1
                                  }
                                >
                                  Next
                                </button>
                              </div>
                            </th>
                          </tr>
                        </thead>

                        <tbody>
                          {activeData &&
                            Object.entries(activeData.value).map(
                              ([fieldName, details], index) => (
                                <tr key={`${currentActiveTab}-${fieldName}`}>
                                  <td className="text-center">
                                    <button
                                      className="btn btn-icon mx-auto"
                                      onClick={() => {
                                        toggleFieldVisibility(
                                          activeData?.key,
                                          fieldName,
                                        );
                                      }}
                                    >
                                      {details?.hidden ? (
                                        <IconEyeHide />
                                      ) : (
                                        <IconEye />
                                      )}
                                    </button>
                                  </td>
                                  <td className="text-uppercase">
                                    <LoanCollateralButton
                                      index={index}
                                      // row={row}
                                      fieldName={fieldName}
                                      categoryData={details?.type}
                                      updateCategory={updateCategory}
                                      aliasSuggestion={
                                        details?.alias_suggestion_required
                                      }
                                      getAliasSuggestion={(
                                        fieldData: string,
                                      ) => {
                                        getAliasSuggestion(index, fieldData);
                                      }}
                                    />
                                  </td>
                                  <td>{fieldName}</td>
                                  <td>
                                    <input
                                      type="text"
                                      placeholder="Click to name"
                                      className="form-control min-w-150"
                                      value={details?.actualFieldName}
                                      onChange={e => {
                                        handleInputChange(
                                          index,
                                          e.target.value,
                                        );
                                      }}
                                    />
                                  </td>
                                  <td colSpan={2}>{currentRow?.[fieldName]}</td>
                                </tr>
                              ),
                            )}
                        </tbody>
                      </table>
                    </div>
                  </TabPane>
                </TabContent>
              </div>
            </div>
          </div>
        )}
      </ModalBody>
      <ModalFooter centered>
        <button className="btn btn-outline-info" onClick={toggle}>
          Cancel
        </button>
        <button className="btn btn-primary" onClick={saveSynonymsData}>
          Update Widget
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default UpdateMapPortfolioData;
