import React from 'react';

type CreditWidgetHeadingProps = {
  currentActiveTab: string;
};

const CreditWidgetHeading = ({
  currentActiveTab,
}: CreditWidgetHeadingProps) => {
  return (
    <>
      {currentActiveTab === '2' && (
        <h4 className="title-20">Select files for the Credit widget</h4>
      )}
      {currentActiveTab === '3' && (
        <h4 className="title-20">Select data sources for the Credit widget</h4>
      )}
      {currentActiveTab === '4' && (
        <h4 className="title-20">Normalise and map portfolio data</h4>
      )}
    </>
  );
};

export default CreditWidgetHeading;
