import { loaderConstant } from '../constant';

const { SHOW_LOADING, HIDE_LOADING } = loaderConstant;

const initialState = {
  loader: {
    title: '',
    isLoading: false,
  },
};

const LoadingReducer = (state = initialState, action: any): any => {
  switch (action.type) {
    case SHOW_LOADING:
      return {
        ...state,
        loader: { ...action.payload },
      };

    case HIDE_LOADING:
      return initialState;

    default:
      return state;
  }
};

export default LoadingReducer;
