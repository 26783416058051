// import { useDispatch } from 'react-redux';
import { Daum, HeadingSchema } from '../../../@types/Widget';
import { _HeaderSchema } from '../actions';
import { totalRowsConstant, totalRowsIndex } from '../constant';

const { ADD_ALL_ROWS } = totalRowsConstant;

const { ADD_WIDGET_INDEX } = totalRowsIndex;
// const { HEADER_SCHEMA } = headerSchema;

export type _calculate =
  | 'AMORTIZATION'
  | 'CAGR'
  | 'ROI'
  | 'CDR'
  | 'CLC'
  | 'DTI'
  | 'YIELD'
  | 'RECOVERY_RATE'
  | 'PAYMENT_IMPAIRMENT'
  | 'NAR'
  | 'CPR'
  | 'MIN_OC'
  | 'CLS';

export type AllWidgetRow = {
  table: Daum[];
  selectedIds: number[];
  widgetIndex: number;
};

export type TableHeader = {
  header: HeadingSchema[];
};

export type calWidgetData = {
  lable: string;
  value: _calculate[];
};

export type AllCalculation = {
  allCalculation: calWidgetData[];
  widgetCalIndex: number;
};

type InitialState = {
  allRows: any[];
  allWidgetRow: AllWidgetRow[];
  allHeaders: TableHeader[];
  allCalculateData: AllCalculation[];
};

const initialState: InitialState = {
  allRows: [],
  allWidgetRow: [],
  allHeaders: [],
  allCalculateData: [],
};

const TotalRowsReducer = (
  state = initialState,
  action: _HeaderSchema | _calculate | any,
): InitialState => {
  switch (action.type) {
    case ADD_ALL_ROWS:
      return {
        ...state,
        allRows: action.payload.allRows,
      };
    case ADD_WIDGET_INDEX: {
      // const rowIndex = state.allWidgetRow.findIndex((data)=>data.id == action.payload.id)
      const payload = action.payload.allWidgetRow;
      const index = state.allWidgetRow.findIndex(
        row => row.widgetIndex === payload.widgetIndex,
      );
      if (index > -1) {
        state.allWidgetRow[index] = payload;
      } else {
        state.allWidgetRow.push(payload);
      }

      return {
        ...state,
      };
    }

    case 'HEADER_SCHEMA':
      return {
        ...state,
        allHeaders: action.payload.allHeaders.header,
      };

    case 'CALCULATE_DATA': {
      const payloadCal = action.payload.allCalculateData;
      const index = state.allCalculateData.findIndex(
        row => row.widgetCalIndex === payloadCal.widgetCalIndex,
      );
      if (index > -1) {
        state.allCalculateData[index] = payloadCal;
      } else {
        state.allCalculateData.push(payloadCal);
      }

      return {
        ...state,
      };
    }

    default:
      return state;
  }
};
export default TotalRowsReducer;
