import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';

import PublicHeader from '../../../components/public/PublicHeader';
import AugmentationDataSources from './AugmentationDataSources';
import DataSources from './DataSources';
import FoundationalModels from './FoundationalModels';
import LocalStorage from './LocalStorage';
import NewsSources from './NewsSources';
import useAction from '../../../components/hooks/useAction';
import { useSelector } from 'react-redux';
import {
  augumentationSourceCountSelector,
  dataSourceCountSelector,
  foundationalModelCountSelector,
  newsSourceCountSelector,
} from '../../../redux/selector/MarketplaceSelector';
import AddDataMarketplace from './addDataMarketplace/AddDataMarketplace';
import axios from 'axios';
import config from '../../../../config';
type ModelListTypes = {
  _id: string;
  model_name: string;
  display_name: string;
};

const DataMarketplace = () => {
  // State for current active Tab
  const {
    addMarketplaceTab,
    showModal,
    addFoundationalModelCount,
    addMarketplaceType,
  } = useAction();
  const [currentActiveTab, setCurrentActiveTab] = useState('1');
  const [dataItemModal, setDataItemModal] = useState(false);
  const [selectedSourceId, setSelectedSourceId] = useState('');
  const [modelList, setModelList] = useState<Array<ModelListTypes> | null>(
    null,
  );

  const newsSourceCountData = useSelector(newsSourceCountSelector);
  const dataSourceCountData = useSelector(dataSourceCountSelector);
  const augumentationSourceCountData = useSelector(
    augumentationSourceCountSelector,
  );
  const foundationalModelCountData = useSelector(
    foundationalModelCountSelector,
  );

  const toggleDataItemModal = () => {
    setDataItemModal(!dataItemModal);
    document.body.classList.toggle('show-dataitem');
  };

  const updateSourceId = (data: string) => {
    setSelectedSourceId(data);
  };

  // Toggle active state for Tab
  const toggle = (tab: string) => {
    if (currentActiveTab !== tab) {
      setCurrentActiveTab(tab);
      addMarketplaceTab(tab);
    }
  };

  const getFoundationalModelList = async () => {
    try {
      const response = await axios({
        method: 'get',
        url: `${config.ER_ML_URL}/llm/list`,
      });
      const responseManipulation: ModelListTypes[] = response?.data?.data;
      addFoundationalModelCount(responseManipulation?.length);
      setModelList(responseManipulation);
    } catch (error) {
      showModal('something went wrong');
    }
  };

  useEffect(() => {
    getFoundationalModelList();
  }, []);

  return (
    <div className="page-content">
      <PublicHeader
        dataItemModal={dataItemModal}
        toggleDataItemModal={toggleDataItemModal}
        getFoundationalModelList={getFoundationalModelList}
      />
      <div className="sub-header">
        <div className="tabs-h-menu">
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({
                  active: currentActiveTab === '1',
                })}
                onClick={() => {
                  toggle('1');
                  addMarketplaceType({
                    label: 'Data Source',
                    value: 'DATA_SOURCE',
                  });
                }}
              >
                Data Sources ({dataSourceCountData})
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: currentActiveTab === '2',
                })}
                onClick={() => {
                  toggle('2');
                  addMarketplaceType({
                    label: 'Augumentation data source',
                    value: 'AUGMENTED_DATA_SOURCE',
                  });
                }}
              >
                Augmentation Data Sources ({augumentationSourceCountData})
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: currentActiveTab === '3',
                })}
                onClick={() => {
                  toggle('3');
                  addMarketplaceType({
                    label: 'News source',
                    value: 'NEWS_SOURCES',
                  });
                }}
              >
                News Sources ({newsSourceCountData})
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: currentActiveTab === '4',
                })}
                onClick={() => {
                  toggle('4');
                  addMarketplaceType({
                    label: 'Foundational modals',
                    value: 'FOUNDATIONAL_MODALS',
                  });
                }}
              >
                Foundational Models ({foundationalModelCountData})
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: currentActiveTab === '5',
                })}
                onClick={() => {
                  toggle('5');
                }}
              >
                Local Storage
              </NavLink>
            </NavItem>
          </Nav>
        </div>
      </div>
      <div className="page-middle-content dm-scroll">
        <TabContent activeTab={currentActiveTab}>
          <TabPane tabId="1" className="data-marketplace">
            <div className="container container-1552">
              <div className="row row-cols-1 row-cols-md-1 row-cols-lg-3 row-cols-xl-4 row-cols-xxl-5 gap-6 justify-content-center">
                <DataSources
                  updateSourceId={updateSourceId}
                  toggleDataItemModal={toggleDataItemModal}
                />
              </div>
            </div>
          </TabPane>
          <TabPane tabId="2" className="data-marketplace">
            <div className="container container-1552">
              <div className="row row-cols-1 row-cols-md-1 row-cols-lg-3 row-cols-xl-4 row-cols-xxl-5 gap-6 justify-content-center">
                <AugmentationDataSources
                  updateSourceId={updateSourceId}
                  toggleDataItemModal={toggleDataItemModal}
                />
              </div>
            </div>
          </TabPane>
          <TabPane tabId="3" className="data-marketplace">
            <div className="container container-1552">
              <div className="row row-cols-1 row-cols-md-1 row-cols-lg-3 row-cols-xl-4 row-cols-xxl-5 gap-6 justify-content-center">
                <NewsSources
                  updateSourceId={updateSourceId}
                  toggleDataItemModal={toggleDataItemModal}
                />
              </div>
            </div>
          </TabPane>
          <TabPane tabId="4" className="data-marketplace">
            <div className="container container-1552">
              <div className="row row-cols-1 row-cols-md-1 row-cols-lg-3 row-cols-xl-4 row-cols-xxl-5 gap-6 justify-content-center">
                <FoundationalModels
                  updateSourceId={updateSourceId}
                  toggleDataItemModal={toggleDataItemModal}
                  modelList={modelList}
                  getFoundationalModelList={getFoundationalModelList}
                />
              </div>
            </div>
          </TabPane>
          <TabPane tabId="5">
            <div className="container">
              <LocalStorage />
            </div>
          </TabPane>
        </TabContent>
      </div>
      {dataItemModal && (
        <AddDataMarketplace
          toggleAddDataMarketplace={toggleDataItemModal}
          sourceId={selectedSourceId}
          getFoundationalModelList={getFoundationalModelList}
        />
      )}
    </div>
  );
};

export default DataMarketplace;
