// import { CHARTS } from '../constants';
import { CHARTS } from '../constants';
import AreaChart from './AreaChart/AreaChart';
import BarChart from './BarChart/BarChart';
import BubbleChart from './BubbleChart/BubbleChart';
import HeatMap from './HeatMap/HeatMap';
import LineChart from './LineChart/LineChart';
import PieChart from './PieChart/PieChart';
import RadarChart from './RadarChart/RadarChart';
import ScatterChart from './ScatterChart/ScatterChart';
import StackedBarChart from './StackedBarChart/StackedBarChart';
import WaterfallChart from './WaterfallChart/WaterfallChart';

interface ChartsWrapperProps {
  chart?: any;
  chartData?: any;
}

const ChartBody = ({ chart, chartData }: ChartsWrapperProps) => {
  const xAxisLabel = () => {
    return chart?.xAxisLabel && chart?.xAxisLabel !== ''
      ? chart?.xAxisLabel
      : chart?.fields?.xAxis?.label;
  };

  const yAxisLabel = () => {
    return chart?.yAxisLabel && chart?.yAxisLabel !== ''
      ? chart?.yAxisLabel
      : chart?.fields?.yAxis?.label;
  };

  const renderChart = () => {
    const chartType = chart?.visualisationType?.value;
    switch (chartType) {
      case 'AREA':
        return (
          <AreaChart
            chartData={chartData}
            chartTitle={chart?.chartTitle}
            initialXAxis={xAxisLabel()}
            initialYAxis={yAxisLabel()}
          />
        );
      case 'BAR':
        return (
          <BarChart
            chartData={chartData}
            chartTitle={chart?.chartTitle}
            initialXAxis={xAxisLabel()}
            initialYAxis={yAxisLabel()}
          />
        );
      case 'LINE':
        return (
          <LineChart
            chartData={chartData}
            chartTitle={chart?.chartTitle}
            initialXAxis={xAxisLabel()}
            initialYAxis={yAxisLabel()}
          />
        );
      case CHARTS.PIE:
        return (
          <PieChart chartData={chartData} chartTitle={chart?.chartTitle} />
        );
      case 'RADAR':
        return (
          <RadarChart
            chartData={chartData}
            chartTitle={chart?.chartTitle}
            initialXAxis={xAxisLabel()}
            initialYAxis={yAxisLabel()}
          />
        );
      case 'SCATTER':
        return (
          <ScatterChart
            chartData={chartData}
            chartTitle={chart?.chartTitle}
            initialXAxis={xAxisLabel()}
            initialYAxis={yAxisLabel()}
          />
        );
      case 'BUBBLE':
        return (
          <BubbleChart
            chartData={chartData}
            chartTitle={chart?.chartTitle}
            initialXAxis={xAxisLabel()}
            initialYAxis={yAxisLabel()}
          />
        );
      case 'HEATMAP':
        return (
          <HeatMap
            chartData={chartData}
            chartTitle={chart?.chartTitle}
            initialXAxis={xAxisLabel()}
            initialYAxis={yAxisLabel()}
          />
        );
      case 'STACKED':
        return (
          <StackedBarChart
            chartData={chartData}
            chartTitle={chart?.chartTitle}
            initialXAxis={xAxisLabel()}
            initialYAxis={yAxisLabel()}
          />
        );
      case 'WATERFALL':
        return (
          <WaterfallChart
            chartData={chartData}
            chartTitle={chart?.chartTitle}
            initialXAxis={xAxisLabel()}
            initialYAxis={yAxisLabel()}
          />
        );
      default:
        return <div />;
    }
  };
  return <>{renderChart()}</>;
};

export default ChartBody;
