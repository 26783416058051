import { useEffect, useState } from 'react';
import { SelectPortfoliosProps } from '../../../../../../@types/PortfolioType';
import useAction from '../../../../../components/hooks/useAction';
import { IconInfo } from '../../../../../components/public/Icons';
import { CustomTooltip } from '../../../../../components/ui/CustomTooltip';
import { PortfolioProps } from '../../../../../../@types/Widget';
import axios from 'axios';
import config from '../../../../../../config';
import InfiniteScroll from 'react-infinite-scroll-component';
import DataLoader from '../../../../../components/loaders/DataLoader';

const SelectPortfolioCharts = ({
  setSelectedVisualisationPortfolio,
}: SelectPortfoliosProps) => {
  const { showModal } = useAction();

  const [isMainLoading, setIsMainLoading] = useState(false);
  const [fileNamesPage, setFileNamesPage] = useState(1);
  const [portfolio, setPortfolio] = useState<PortfolioProps>(
    {} as PortfolioProps,
  );

  const [selectedPortfolios, setSelectedPortfolios] = useState<string[]>([]);

  const getPortfolio = async (isPageChange: boolean) => {
    setIsMainLoading(true);

    try {
      const response = await axios({
        method: 'get',
        url: `${config.ER_ML_URL}/portfolio/?limit=10&page=${fileNamesPage}`,
      });
      const responseManipulation = !isPageChange
        ? response.data
        : Object.keys(portfolio).length > 0
        ? {
            ...portfolio,
            data: [...(portfolio?.data || {}), ...(response.data.data || {})],
          }
        : response.data;

      setPortfolio(responseManipulation);
      if (isPageChange) {
        setFileNamesPage(fileNamesPage + 1);
      }
      setIsMainLoading(false);
    } catch (error) {
      showModal('something went wrong');
      setIsMainLoading(false);
    } finally {
      setIsMainLoading(false);
    }
  };

  useEffect(() => {
    getPortfolio(true);
  }, []);

  const handlePortfolioSelection = (portfolioId: string) => {
    setSelectedPortfolios(prevSelected =>
      prevSelected.includes(portfolioId)
        ? prevSelected.filter(id => id !== portfolioId)
        : [...prevSelected, portfolioId],
    );
  };

  useEffect(() => {
    setSelectedVisualisationPortfolio(selectedPortfolios);
  }, [selectedPortfolios, setSelectedVisualisationPortfolio]);

  return (
    <>
      <div className="addwidget-content mw-100">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-6">
            <div className="d-flex align-items-center">
              <p className="widget-category me-1 mb-0">SELECT PORTFOLIO(s)</p>
              <span className="d-flex" id="tooltipsppw">
                <IconInfo />
              </span>
              <CustomTooltip placement="top" target="tooltipsppw" />
            </div>
            <div
              className="create-widget create-widget-portfolio"
              id="scrollableDiv"
            >
              <div className="widget-item">
                <div className="row row-cols-1">
                  <InfiniteScroll
                    dataLength={portfolio?.data?.length || 0}
                    next={() => getPortfolio(true)}
                    hasMore={portfolio?.data?.length <= 0 ? false : true}
                    loader={
                      portfolio?.data?.length <= 0 ? (
                        <div style={{ paddingLeft: '12px' }}>Loading...</div>
                      ) : null
                    }
                    scrollableTarget="scrollableDiv"
                  >
                    {portfolio?.data &&
                      portfolio?.data.length > 0 &&
                      portfolio?.data?.map((item, index) => {
                        const isChecked = selectedPortfolios.includes(item._id);
                        return (
                          <div className="col" key={index}>
                            <input
                              type="checkbox"
                              name="select-portfolio"
                              checked={isChecked}
                              onClick={() => {
                                handlePortfolioSelection(item._id);
                              }}
                            />
                            <div className="widget-card">
                              <p>{item.fileName}</p>
                            </div>
                          </div>
                        );
                      })}
                  </InfiniteScroll>
                  {isMainLoading && <DataLoader />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SelectPortfolioCharts;
