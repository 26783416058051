import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from 'reactstrap';
import useAction from '../hooks/useAction';
import MarketPlaceService from '../../services/marketplace.service';
import { Datum } from '../../../@types/MarketPlaceType';
import { IconSearch } from './Icons';
import useDebounce from '../hooks/useDebounce';
import moment from 'moment';
import { getFileType } from '../../helpers/utils.helper';

const marketplaceService = new MarketPlaceService();

type FileSelectorModalProps = {
  modal: boolean;
  toggle: () => void;
  // eslint-disable-next-line no-unused-vars
  onSelect: (selectedFiles: { _id: string; file_name: string }[]) => void;
};

const FileSelectorModal = ({
  modal,
  toggle,
  onSelect,
}: FileSelectorModalProps) => {
  const { showModal } = useAction();
  const [selectedFiles, setSelectedFiles] = useState<
    { _id: string; file_name: string }[]
  >([]);
  const [filesList, setFilesList] = useState<Array<Datum>>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const filesPerPage = 10;
  const debouncedUserInput = useDebounce(searchQuery, 1000);

  const getFiles = async () => {
    try {
      const { data } =
        await marketplaceService.getLocalStorageFiles(searchQuery);

      const sortedFiles = data.sort((a: Datum, b: Datum) => {
        return (
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );
      });

      setFilesList(sortedFiles);
    } catch (error) {
      showModal('Error Fetching Local Storage Files', false);
    }
  };

  useEffect(() => {
    if (modal) {
      getFiles();
    }
  }, [modal, debouncedUserInput]);

  const handleFileSelection = (file: Datum) => {
    const alreadySelected = selectedFiles.find((f) => f._id === file._id);

    const fileName = file.saveName || file.file_name || file.fileName || '';
    const fileId = file._id;
    // const documentId = file.document_id;

    if (alreadySelected) {
      setSelectedFiles((prevSelectedFiles) =>
        prevSelectedFiles.filter((f) => f._id !== fileId),
      );
    } else {
      setSelectedFiles((prevSelectedFiles) => [
        ...prevSelectedFiles,
        { _id: fileId, file_name: fileName },
      ]);
    }
  };

  const indexOfLastFile = currentPage * filesPerPage;
  const indexOfFirstFile = indexOfLastFile - filesPerPage;
  const currentFiles = filesList.slice(indexOfFirstFile, indexOfLastFile);

  const nextPage = () => {
    if (currentPage < Math.ceil(filesList.length / filesPerPage)) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const previousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const isSelected = (file: Datum) =>
    selectedFiles.some((f) => f._id === file._id);

  return (
    <Modal isOpen={modal} toggle={toggle} centered size="lg">
      <ModalHeader toggle={toggle}>Select Files from Local Storage</ModalHeader>
      <ModalBody>
        <div className="d-flex mb-3 align-items-center">
          <span className="input-icon">
            <IconSearch />
          </span>
          <input
            id="searchQueryLocalStorage"
            type="text"
            placeholder="Search"
            className="form-control ms-2"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>

        <div className="table-responsive">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th style={{ width: '40px' }} scope="col"></th>
                <th scope="col">File name</th>
                <th style={{ width: '190px' }} scope="col">
                  Type
                </th>
                <th style={{ width: '170px' }} scope="col">
                  Uploaded ON
                </th>
              </tr>
            </thead>
            <tbody>
              {currentFiles.map((item, index) => {
                const fileName =
                  item.saveName || item.file_name || item.fileName;

                return (
                  <tr
                    key={`${fileName}-${index}`}
                    onClick={() => handleFileSelection(item)}
                    style={{
                      cursor: 'pointer',
                      backgroundColor: isSelected(item) ? '' : '',
                    }}
                  >
                    <td>
                      <div className="form-check flexbox-align-c">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id={`checkbox-${index}`}
                          checked={isSelected(item)}
                          onChange={() => handleFileSelection(item)}
                        />
                      </div>
                    </td>
                    <td>{fileName}</td>
                    <td>{getFileType(item)}</td>
                    <td>
                      {moment(item?.createdAt).format('DD-MM-YYYY')}
                      <p className="font-12 mb-0 text-white-4">
                        {moment(item?.createdAt)
                          .utcOffset('+05:30')
                          .format('hh:mm A')}
                      </p>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="pagination-controls gap-2">
          <Button
            color="secondary"
            onClick={previousPage}
            disabled={currentPage === 1}
          >
            Previous
          </Button>
          <Button
            color="secondary"
            onClick={nextPage}
            disabled={
              currentPage === Math.ceil(filesList.length / filesPerPage)
            }
          >
            Next
          </Button>
        </div>
        <Button
          color="primary"
          onClick={() => onSelect(selectedFiles)}
          disabled={selectedFiles.length === 0}
        >
          Select
        </Button>
        <Button color="secondary" onClick={toggle}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default FileSelectorModal;
