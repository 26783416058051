import { warrenFileConstant } from '../constant/warrenFile.constant';

const { ADD_WARREN_FILE } = warrenFileConstant;

export const addWarrenFile = (data: any) => {
  return {
    type: ADD_WARREN_FILE,
    payload: {
      warrenFile: data,
    },
  };
};
