import { UserProfileResponseType } from '../../@types/UserType';
import ApiUtil from '../../utils/apiUtil';
import { UserDetail } from '../../@types/UserProfileTypes';

export default class AuthService {
  async getLoginUser(): Promise<any> {
    const url = '/auth/getUser';
    const res = await ApiUtil.SendAsync<any>({
      url: url,
      method: 'GET',
    });
    return res.data.data;
  }

  async updateLoginProfile(
    payload: UserDetail,
  ): Promise<UserProfileResponseType> {
    const url = '/auth/updateUser';
    const res = await ApiUtil.SendAsync<UserProfileResponseType>({
      url: url,
      method: 'PUT',
      data: payload,
    });
    return res.data;
  }

  async logout(): Promise<any> {
    const url = '/auth/logout';
    const res = await ApiUtil.SendAsync<any>({
      url: url,
      method: 'GET',
    });
    return res.data;
  }

  async callFcm(payload: any): Promise<any> {
    const url = `/auth/fcmToken`;
    const res = await ApiUtil.SendAsync<any>({
      url: url,
      method: 'PUT',
      data: payload,
    });
    return res.data;
  }

  async changePassword(payload: any): Promise<any> {
    const url = `/auth/change-password`;
    const res = await ApiUtil.SendAsync<any>({
      url: url,
      method: 'PUT',
      data: payload,
    });
    return res.data;
  }

  async resetPasswordUser(payload: any): Promise<any> {
    const url = `/settings/updateUserPassword`;
    const res = await ApiUtil.SendAsync<any>({
      url: url,
      method: 'PATCH',
      data: payload,
    });
    return res.data;
  }

  async resendInviteUser(id: string): Promise<any> {
    const url = `/settings/resendInvite/${id}`;
    const res = await ApiUtil.SendAsync<any>({
      url: url,
      method: 'GET',
    });
    return res.data;
  }
}
