import React, { useEffect } from 'react';
import config from '../../../config';
//import { useNavigate } from 'react-router-dom';

const Home = () => {
  //const navigate = useNavigate();

  useEffect(() => {
    /*if (localStorage.getItem('_ERFTKEN')) {
      window.location.href =
        config.EXCHANGE_ROBOTICS_LINK +
        `/dashboard?token=${localStorage.getItem('_ERFTKEN')}`;
    } else {*/

    window.location.href = config.WEBSOCKET_URL + '/api/auth/login';

    //}
  }, []);
  return <></>;
};

export default Home;
