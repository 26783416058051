// import { Progress } from 'reactstrap';
import { IconSearch, IconSort } from '../../../components/public/Icons';
// import CustomSelect from '../../../components/ui/CustomSelect';
import LocalStorageActionDropdown from './LocalStorageActionDropdown';
import { useEffect, useState } from 'react';
import MarketPlaceService from '../../../services/marketplace.service';
import useAction from '../../../components/hooks/useAction';
import { Datum } from '../../../../@types/MarketPlaceType';
import moment from 'moment';
import AppLoader from '../../../components/loaders/AppLoader';
import useDebounce from '../../../components/hooks/useDebounce';
import { getFileType } from '../../../helpers/utils.helper';

const marketplaceService = new MarketPlaceService();

const LocalStorage = () => {
  const { showModal } = useAction();
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [localStorageFilesList, setLocalStorageFilesList] = useState<
    Array<Datum>
  >([]);
  const debouncedUserInput = useDebounce(searchQuery, 1000);

  const getFilesList = () => {
    setIsLoading(true);
    marketplaceService
      .getLocalStorageFiles(searchQuery)
      .then(({ data }) => {
        setIsLoading(false);
        const sortedData = data.sort((a: Datum, b: Datum) => {
          return (
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
          );
        });

        setLocalStorageFilesList(sortedData);
      })
      .catch((err) => {
        setIsLoading(false);
        showModal(err?.response?.data?.message);
      });
  };

  useEffect(() => {
    getFilesList();
  }, [debouncedUserInput]);

  return (
    <>
      {isLoading && <AppLoader />}
      <div className="main-widget pe-0">
        <div className="react-grid-item widget-hs-full">
          <div className="common-widget">
            <div className="common-widget-header">
              <div className="common-widget-header-title">
                <h2 className="me-10">Local Storage</h2>
              </div>
            </div>
            <div className="common-widget-content px-0 overflow-hidden">
              <div className="wc-scroll">
                <div className="row align-items-center">
                  <div className="col-12 col-lg-7">
                    <div className="row gx-3">
                      <div className="col-12 col-lg-4">
                        <div className="form-group form-icon mb-10">
                          <span className="input-icon">
                            <IconSearch />
                          </span>
                          <input
                            id="searchQueryLocalStorage"
                            type="text"
                            placeholder="Search"
                            className="form-control"
                            value={searchQuery}
                            onChange={(e) => {
                              setSearchQuery(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="table-responsive">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        {/* <th style={{ width: '40px' }} scope="col"></th> */}
                        <th scope="col">File name</th>
                        <th style={{ width: '130px' }} scope="col">
                          Size
                        </th>
                        <th style={{ width: '190px' }} scope="col">
                          Type
                        </th>
                        <th style={{ width: '170px' }} scope="col">
                          Uploaded ON
                        </th>
                        <th style={{ width: '160px' }} scope="col">
                          Last Used
                          <span className="sortable asc">
                            <IconSort />
                          </span>
                        </th>
                        <th style={{ width: '48px' }} scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {localStorageFilesList?.map((item, index) => {
                        const fileName =
                          item.saveName || item.file_name || item.fileName;
                        return (
                          <tr key={`${fileName} - ${index}`}>
                            {/* <td>
                              <div className="form-check flexbox-align-c">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="flexCheckChecked"
                                />
                              </div>
                            </td> */}
                            <td>{fileName}</td>
                            <td className="text-end">{item?.size || 'N/A'}</td>
                            {/* <td>{item?.type || 'N/A'}</td> */}
                            <td>{getFileType(item)}</td>
                            <td>
                              {moment(item?.createdAt).format('DD-MM-YYYY')}
                              <p className="font-12 mb-0 text-white-4">
                                {moment(item?.createdAt)
                                  .utcOffset('+05:30')
                                  .format('hh:mm A')}
                              </p>
                            </td>
                            <td>today</td>
                            <td>
                              <LocalStorageActionDropdown
                                localStorageId={item?._id}
                                setIsLoading={setIsLoading}
                                deleteSuccess={() => {
                                  getFilesList();
                                }}
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LocalStorage;
