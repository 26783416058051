import * as Yup from 'yup';

export type InviteUser = {
  firstName: string;
  middleName?: string;
  lastName: string;
  email: string;
  departmentId: string;
  userRole: string;
  licenseId: string;
  organizationId?: string;
};

export const roleOptions = [
  { label: 'All Roles', value: '' },
  { label: 'Owner', value: 'OWNER' },
  { label: 'Admin', value: 'ADMIN' },
  { label: 'Member', value: 'MEMBER' },
];
export const licenceOptions = [
  { label: 'Basic', value: 1 },
  { label: 'Silver (5 available)', value: 2 },
  { label: 'Gold (2 available)', value: 3 },
  { label: 'Platinum (1 available)', value: 4 },
];

// Validation schema using {Yup}
export const validationSchema = Yup.object({
  firstName: Yup.string().required('First Name is required'),
  middleName: Yup.string(),
  lastName: Yup.string().required('Last Name is required'),
  email: Yup.string()
    .email('Invalid email')
    .required('Email is required'),
  departmentId: Yup.string().required('Department is required'),
  userRole: Yup.string().required('Role is required'),
  licenseId: Yup.string().required('Licence is required'),
});
