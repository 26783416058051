import ApiUtil from '../../utils/apiUtil';
import {
  SynonymsFieldPayload,
  SynonymsFieldResponse,
} from '../../@types/PortfolioType';
import config from '../../config';

export default class PortfolioService {
  async saveCalculatedData(payload: any): Promise<any> {
    const url = '/userPortfolio';
    const res = await ApiUtil.SendAsync<any>({
      url: url,
      method: 'POST',
      data: payload,
    });
    return res.data;
  } //not used

  async getRowData(id: any, category?: any, columnType?: any): Promise<any> {
    const url = `/userPortfolio/getRowData?id=${id}&category=${category}&columnType=${columnType}`;
    const res = await ApiUtil.SendAsync<any>({
      url: url,
      method: 'GET',
    });
    return res.data;
  }

  async getSummary(id: any, columnType?: any): Promise<any> {
    const url = `/userPortfolio/getSummary?id=${id}&columnType=${columnType}`;
    const res = await ApiUtil.SendAsync<any>({
      url: url,
      method: 'GET',
    });
    return res.data;
  }

  async getPortfolioList(payload?: any): Promise<any> {
    const url = `/userPortfolio/listPortfolioName?category=${payload.category}`;
    const res = await ApiUtil.SendAsync<any>({
      url: url,
      method: 'GET',
    });
    return res.data;
  }

  async getPortfolioListProductStructuring(): Promise<any> {
    const url = `/userPortfolio/listPortfolioName`;
    const res = await ApiUtil.SendAsync<any>({
      url: url,
      method: 'GET',
    });
    return res.data;
  }

  async getCalculations(id: any): Promise<any> {
    const url = `/userPortfolio/getCalculation?id=${id}`;
    const res = await ApiUtil.SendAsync<any>({
      url: url,
      method: 'GET',
    });
    return res.data;
  }

  async allocationRowCalculation(id: number, payload: any): Promise<any> {
    const url = `/userPortfolio/calculation?id=${id}`;
    const res = await ApiUtil.SendAsync<any>({
      url: url,
      method: 'POST',
      data: payload,
    });
    return res.data;
  }

  async saveAllocationRowCalculation(id: number, payload: any): Promise<any> {
    const url = `/userPortfolio/saveCalculation?id=${id}`;
    const res = await ApiUtil.SendAsync<any>({
      url: url,
      method: 'POST',
      data: payload,
    });
    return res.data;
  } //not used

  async financialCalculation(payload: any): Promise<any> {
    const url = '/calculation';
    const res = await ApiUtil.SendAsync<any>({
      url: url,
      method: 'POST',
      data: payload,
    });
    return res.data;
  } //not used

  async telemetryRecord(payload: any): Promise<any> {
    const url = '/telemetry/record';
    const res = await ApiUtil.SendAsync<any>({
      url: url,
      method: 'POST',
      data: payload,
    });
    return res.data;
  }

  async addTovoData(payload: any): Promise<any> {
    const url = `/tovoData`;
    const res = await ApiUtil.SendAsync<any>({
      url: url,
      method: 'POST',
      data: payload,
    });
    return res.data;
  }

  async addCustomCreditReport(payload: any): Promise<any> {
    const url = `/creditReport`;
    const res = await ApiUtil.SendAsync<any>({
      url: url,
      method: 'POST',
      data: payload,
    });
    return res.data;
  }

  async addColumnType(id: string, payload: any): Promise<any> {
    const url = `/file/portfolio/${id}`;
    const res = await ApiUtil.SendAsync<any>({
      url: url,
      method: 'POST',
      data: payload,
    });
    return res.data;
  }

  async saveNodeEndpoints(payload: { serviceIds: string[] }): Promise<any> {
    const url = `/dataMarketplace/service/getFileDetailsByIds`;
    const res = await ApiUtil.SendAsync<any>({
      url: url,
      method: 'POST',
      data: payload,
    });
    return res.data;
  }

  async addSynonymsFields(
    payload: SynonymsFieldPayload,
  ): Promise<SynonymsFieldResponse> {
    const url = `/normalize/`;
    const res = await ApiUtil.SendAsync<SynonymsFieldResponse>({
      url: url,
      method: 'POST',
      data: payload,
    });
    return res.data;
  }

  async saveHeaders(payload: any): Promise<any> {
    const url = `/v1/saveHeaders/`;
    const res = await ApiUtil.SendAsync<SynonymsFieldResponse>({
      url: url,
      method: 'POST',
      data: payload,
    });
    return res.data;
  }

  async getPerformanceWidgetPortfolio(payload: any): Promise<any> {
    // const url = `/v1/saveHeaders/`;
    const res = await ApiUtil.SendAsync<SynonymsFieldResponse>({
      url: `${config.ER_ML_URL}/statistical/get-performance-widget-portfolio`,
      method: 'POST',
      data: payload,
    });
    return res.data;
  }
}
