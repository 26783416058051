import { IconInfo } from '../../../../../components/public/Icons';
import { CustomTooltip } from '../../../../../components/ui/CustomTooltip';
import React, { useEffect, useState } from 'react';
import MarketPlaceService from '../../../../../services/marketplace.service';
import useAction from '../../../../../components/hooks/useAction';
import EndpointCollapse from './customComponents/EndpointCollapse';
import DataLoader from '../../../../../components/loaders/DataLoader';

type DataSourceListType = {
  _id: string;
  serviceName: string;
  serviceType: string;
  serviceUrl: string;
  isPaidService: boolean;
  id: string;
  endpointCount: number;
};

type selectedDataSourceType = {
  file: string;
  subFile: string;
  type: string;
};

type SelectDataSourcesTypes = {
  isUpdate: boolean;
  amplifyDataArr: { fileName: string; id: string }[] | [];
  nodeEndpointArr: { fileName: string; id: string }[] | [];
  setAmplifyDataArr: React.Dispatch<
    React.SetStateAction<
      | []
      | {
          fileName: string;
          id: string;
        }[]
    >
  >;
  setNodeEndpointArr: React.Dispatch<
    React.SetStateAction<
      | []
      | {
          fileName: string;
          id: string;
        }[]
    >
  >;
  selectedDataSourcesWidget: selectedDataSourceType[];
  setSelectedDataSourcesWidget: React.Dispatch<
    React.SetStateAction<selectedDataSourceType[]>
  >;
};

const marketplaceService = new MarketPlaceService();

const SelectDataSources = ({
  isUpdate,
  amplifyDataArr,
  nodeEndpointArr,
  setAmplifyDataArr,
  setNodeEndpointArr,
  selectedDataSourcesWidget,
  setSelectedDataSourcesWidget,
}: SelectDataSourcesTypes) => {
  const { showModal } = useAction();
  const [isLoading, setIsLoading] = useState(false);
  // const [isCollapseOpen, setIsCollapseOpen] = useState(false);
  // const toggleCollapse = () => setIsCollapseOpen(!isCollapseOpen);

  const [dataSourceList, setDataSourceList] = useState<
    Array<DataSourceListType>
  >([]);
  const [augDataSourceList, setAugDataSourceList] = useState<
    Array<DataSourceListType>
  >([]);

  const getAllDataSourcesList = () => {
    setIsLoading(true);
    marketplaceService
      .getAllSourceList('DATA_SOURCE')
      .then(({ data }) => {
        setDataSourceList(data);
        setIsLoading(false);
      })
      .catch(err => {
        showModal(err?.response?.data?.message);
        setIsLoading(false);
      });
  };

  const getAllAugSourcesList = () => {
    setIsLoading(true);
    marketplaceService
      .getAllSourceList('AUGMENTED_DATA_SOURCE')
      .then(({ data }) => {
        setAugDataSourceList(data);
        setIsLoading(false);
      })
      .catch(err => {
        showModal(err?.response?.data?.message);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getAllDataSourcesList();
    getAllAugSourcesList();
    28;
  }, []);

  return (
    <>
      <div className="addwidget-content mw-100">
        <div className="row row-divider justify-content-center">
          <div className="col-12 col-lg-6">
            <div className="d-flex align-items-center">
              <p className="widget-category me-1 mb-0">SELECT Data Source(S)</p>
              <span className="d-flex" id="tooltip11">
                <IconInfo />
              </span>
              <CustomTooltip placement="top" target="tooltip11" />
            </div>
            <div className="create-widget create-widget-portfolio">
              <div className="widget-item">
                <div className="row row-cols-1">
                  {isLoading && (
                    <div className="col">
                      <DataLoader title={''} />
                    </div>
                  )}

                  {dataSourceList?.length > 0
                    ? dataSourceList?.map(sourceData => (
                        <EndpointCollapse
                          isUpdate={isUpdate}
                          sourceType="DATA_SOURCE"
                          sourceData={sourceData}
                          amplifyDataArr={amplifyDataArr}
                          setAmplifyDataArr={setAmplifyDataArr}
                          nodeEndpointArr={nodeEndpointArr}
                          setNodeEndpointArr={setNodeEndpointArr}
                          selectedDataSourcesWidget={selectedDataSourcesWidget}
                          setSelectedDataSourcesWidget={
                            setSelectedDataSourcesWidget
                          }
                        />
                      ))
                    : 'No Record Found!'}
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6">
            <div className="d-flex align-items-center">
              <p className="widget-category me-1 mb-0">
                SELECT AUGMENTATION Data Source(S)
              </p>
              <span className="d-flex" id="tooltip12">
                <IconInfo />
              </span>
              <CustomTooltip placement="top" target="tooltip12" />
            </div>
            <div className="create-widget create-widget-portfolio">
              <div className="widget-item">
                <div className="row row-cols-1">
                  {augDataSourceList?.length > 0
                    ? augDataSourceList?.map(augSourceData => (
                        <EndpointCollapse
                          isUpdate={isUpdate}
                          sourceType="AUGMENTATION_DATA_SOURCE"
                          sourceData={augSourceData}
                          amplifyDataArr={amplifyDataArr}
                          setAmplifyDataArr={setAmplifyDataArr}
                          nodeEndpointArr={nodeEndpointArr}
                          setNodeEndpointArr={setNodeEndpointArr}
                          selectedDataSourcesWidget={selectedDataSourcesWidget}
                          setSelectedDataSourcesWidget={
                            setSelectedDataSourcesWidget
                          }
                        />
                      ))
                    : 'No Record Found!'}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SelectDataSources;
