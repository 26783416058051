import { errorConstant } from '../constant';

const { SHOW_MODAL, HIDE_MODAL } = errorConstant;

export const showModal = (message: string, success?: boolean) => {
  return {
    type: SHOW_MODAL,
    modal: { message, isShow: true, success: success },
  };
};

export const hideModal = () => {
  return {
    type: HIDE_MODAL,
    modal: { message: '', isShow: false, success: false },
  };
};
