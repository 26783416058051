import { IconChat } from '../../../../../components/public/Icons';
import { CustomTooltip } from '../../../../../components/ui/CustomTooltip';

interface AvailableAliasTypes {
  [key: string]: {
    alias_name: string;
    field_type: string;
  };
}

type LoanCollateralButtonProps = {
  index: number;
  categoryData: string;
  aliasSuggestion: boolean;
  fieldName: string;
  availableAliasName?: AvailableAliasTypes;
  // eslint-disable-next-line no-unused-vars
  getAliasSuggestion: (fieldData: string) => void;
  updateCategory: (
    // eslint-disable-next-line no-unused-vars
    fieldName: string,
    // eslint-disable-next-line no-unused-vars
    category: 'FINANCIAL' | 'COLLATERAL',
  ) => void;
};

const LoanCollateralButton = ({
  categoryData,
  availableAliasName,
  index,
  aliasSuggestion,
  fieldName,
  updateCategory,
  getAliasSuggestion,
}: LoanCollateralButtonProps) => {
  return (
    <div className="d-flex gap-8 align-items-center">
      <button
        className={`status-o ${(categoryData
          ? categoryData === 'COLLATERAL'
          : availableAliasName?.[fieldName]?.field_type === 'COLLATERAL') &&
          'status-o-fill'}`}
        onClick={() => {
          updateCategory(fieldName, 'COLLATERAL');
        }}
      >
        Collateral
      </button>
      <button
        className={`status-o ${(categoryData
          ? categoryData === 'FINANCIAL'
          : availableAliasName?.[fieldName]?.field_type === 'FINANCIAL') &&
          'status-o-fill'}`}
        onClick={() => {
          updateCategory(fieldName, 'FINANCIAL'); // Update category in parent component
        }}
      >
        Financial
      </button>
      {aliasSuggestion && (
        <>
          <span
            className="d-flex c-pointer"
            id={`tooltip${index}`}
            onClick={() => {
              getAliasSuggestion(fieldName);
            }}
          >
            <IconChat width={24} height={24} fill="#00FFE1" />
          </span>
          <CustomTooltip placement="top" target={`tooltip${index}`} />
        </>
      )}
    </div>
  );
};

export default LoanCollateralButton;
