/* ACTIONS */
import { selectedRowsConstant } from '../constant';

const {
  ADD_SELECTED_ROWS,
  REMOVE_SELECTED_ROWS,
  ADD_COLUMN_TYPE,
} = selectedRowsConstant;

export const addSelectedRows = (data: any) => {
  return {
    type: ADD_SELECTED_ROWS,
    payload: {
      selectedRows: data,
    },
  };
};

export const addColumnType = (data: any) => {
  return {
    type: ADD_COLUMN_TYPE,
    payload: {
      columnType: data,
    },
  };
};

export const removeSelectedRows = () => {
  return {
    type: REMOVE_SELECTED_ROWS,
    payload: {
      selectedRows: [],
    },
  };
};
