import { useEffect, useState } from 'react';

import useAction from '../hooks/useAction';
import { IconCalendar, IconCross } from '../public/Icons';
import NotificationService from '../../services/notification.service';

type NotificationModalProps = {
  toggle: () => void;
};

const ingService = new NotificationService();

const NotificationModal = ({ toggle }: NotificationModalProps) => {
  const pageLimit = 10;
  const [page, setPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [notificationListData, setNotificationListData] = useState<any[]>([]);
  const [tokenAvailable, setTokenAvailable] = useState(false);
  const {
    showModal,
    addDashboardTasksData,
    showLoading,
    hideLoading,
  } = useAction();

  // useEffect(() => {
  //   loadNotifications();
  // }, [page]);

  useEffect(() => {
    let tokenCheckInterval: number;

    const checkToken = () => {
      const token = localStorage.getItem('_ERFTKEN');
      if (token) {
        setTokenAvailable(true);
        clearInterval(tokenCheckInterval);
      }
    };
    checkToken();
    tokenCheckInterval = window.setInterval(checkToken, 1000);
    return () => clearInterval(tokenCheckInterval);
  }, []);

  useEffect(() => {
    if (tokenAvailable) {
      loadNotifications();
    }
  }, [page, tokenAvailable]);

  const loadNotifications = async () => {
    showLoading();
    const payload = {
      limit: pageLimit,
      page: page,
    };
    try {
      const response = await ingService.getNotificationList(payload);
      const { notification, totalCount } = response.data;

      setNotificationListData(notification.reverse());
      setTotalRecords(totalCount);
      hideLoading();
    } catch (error) {
      showModal('Something went wrong!');
      hideLoading();
    }
  };

  const handleNotificationClick = async (id: string) => {
    showLoading();
    try {
      // const updatedNotification = await ingService.updateNotification(id);
      await ingService.updateNotification(id);
      // console.log('Updated Notification Response:', updatedNotification);

      setNotificationListData(prevData =>
        prevData.map(notif =>
          notif._id === id ? { ...notif, isRead: true } : notif,
        ),
      );

      addDashboardTasksData({
        id: id,
        value: 'task',
        widgetType: 'DASHBOARD',
      });

      toggle();
    } catch (error) {
      showModal('Failed to update notification!');
    } finally {
      hideLoading();
    }
  };

  const markAllAsRead = () => {
    showModal('API to mark all pending');
    // axios
    //   .patch(`${config.WEBSOCKET_URL}/notification/mark-all-read`)
    //   .then(() => {
    //     setNotificationListData(prevData =>
    //       prevData.map(notif => ({ ...notif, isRead: true })),
    //     );
    //   });
  };

  return (
    <div className="sidebar-widget sw-notification">
      <div className="sw-header">
        <div className="sw-h-title">
          <h5 className="title-20">Notification Center</h5>
        </div>
        <button
          type="button"
          className="btn sw-close-btn"
          onClick={() => toggle()}
        >
          <IconCross width={24} height={24} fill="#36567D" />
        </button>
      </div>
      <div className="sw-content">
        {notificationListData.length > 0 && (
          <div className="mark-block">
            <button type="button" className="btn link" onClick={markAllAsRead}>
              Mark all as read
            </button>
          </div>
        )}

        {notificationListData.length > 0 ? (
          notificationListData.map((notfyData: any) => (
            <div
              key={notfyData._id}
              className={`notification-item ${
                notfyData.isRead ? 'read-notification' : 'unread-notification'
              }`}
              style={{ cursor: 'pointer' }}
              onClick={() => handleNotificationClick(notfyData._id)}
            >
              <div className="notification-date">
                <div className="flexbox-c gap-1">
                  <IconCalendar />
                  <h5 className="n-date">
                    {new Date(notfyData.createdAt).toLocaleString()}
                  </h5>
                </div>
                {!notfyData.isRead && (
                  <span className="notification-mark"></span>
                )}
              </div>
              <p>{notfyData.description}</p>
              {notfyData.notificationType === 'Task Rejected' && (
                <div className="notification-reason">
                  <strong>Reason for Rejection:</strong>
                  <p>{notfyData.reason}</p>
                </div>
              )}
            </div>
          ))
        ) : (
          <div className="notification-item">You have no notification!</div>
        )}

        {/* Pagination */}
        {totalRecords > pageLimit && (
          <div className="pagination-container">
            <button
              disabled={page === 1}
              onClick={() => setPage(prevPage => prevPage - 1)}
            >
              Previous
            </button>
            <button
              disabled={page * pageLimit >= totalRecords}
              onClick={() => setPage(prevPage => prevPage + 1)}
            >
              Next
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default NotificationModal;
