import { useState } from 'react';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';

import {
  IconAction,
  IconCross,
  IconEdit,
} from '../../../../../components/public/Icons';
import MarketPlaceService from '../../../../../services/marketplace.service';
import useAction from '../../../../../components/hooks/useAction';

const marketplaceService = new MarketPlaceService();

type EndpointsActionDropdownTypes = {
  endpointId: string;
  deleteSuccess?: () => void;
  editSuccess: () => void;
};

const EndpointsActionDropdown = ({
  endpointId,
  deleteSuccess,
  editSuccess,
}: EndpointsActionDropdownTypes) => {
  const { showModal } = useAction();
  const [dropdownOpen, setDropDownOpen] = useState(false);
  const toggleDropDown = () => {
    setDropDownOpen(state => !state);
  };

  const removeNewsSourceEndpoint = () => {
    marketplaceService
      .deleteSourceEndpointById(endpointId as string)
      .then(() => {
        deleteSuccess?.();
      })
      .catch(err => {
        showModal(err?.response?.data?.message);
      });
  };

  return (
    <>
      <Dropdown
        className="add-dropdown"
        isOpen={dropdownOpen}
        toggle={toggleDropDown}
      >
        <DropdownToggle
          caret
          direction="start"
          className="btn-icon icon-hr mx-auto"
        >
          <IconAction />
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={() => editSuccess()}>
            <IconEdit />
            Edit
          </DropdownItem>
          <DropdownItem
            onClick={() => {
              removeNewsSourceEndpoint();
            }}
          >
            <IconCross />
            Remove
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

export default EndpointsActionDropdown;
