import ImgNemotron from '../../../../assets/images/nemotron.png';
import CommonOptionsDropdown from './DataMarketActionDropdown';

type ModelListTypes = {
  _id: string;
  model_name: string;
  display_name: string;
  isSystemDefined?: boolean;
};

type FoundationalModelProps = {
  // eslint-disable-next-line
  updateSourceId: (data: string) => void;
  toggleDataItemModal: () => void;
  modelList: Array<ModelListTypes> | null;
  getFoundationalModelList: () => void;
};

const FoundationalModels = ({
  modelList,
  getFoundationalModelList,
  updateSourceId,
  toggleDataItemModal,
}: FoundationalModelProps) => {
  return (
    <>
      {modelList?.map(item => (
        <div className="col" key={item?._id}>
          <div className="main-widget">
            <div className="react-grid-item">
              <div className="common-widget">
                <div className="common-widget-header">
                  <div className="common-widget-header-title">
                    <img src={ImgNemotron} alt="" className="img-16" />
                    <h2>{item?.display_name}</h2>
                  </div>
                  <div className="common-widget-header-action">
                    <CommonOptionsDropdown
                      sourceId={item._id}
                      updateSourceId={updateSourceId}
                      toggleDataItemModal={toggleDataItemModal}
                      modelType={'FOUNDATIONAL_MODELS'}
                      deleteSuccess={() => {
                        getFoundationalModelList();
                      }}
                      isSystemDefined={item?.isSystemDefined}
                    />
                  </div>
                </div>
                <div className="common-widget-content">
                  <p className="gap-1">{item?.model_name}</p>
                  {/* <div className="alert-msg">
                <p>api.equifax.com</p>
                <div className="text-error">
                  <IconError />
                  error
                </div>
              </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default FoundationalModels;
