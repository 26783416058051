import {
  UserData,
  UserActionType,
  UserStateType,
} from '../../../@types/UserType';
import { userConstant } from '../constant';

const { USERS_LOGOUT, USER_FAILURE, USER } = userConstant;

const initialState: UserStateType = {
  isLoggedIn: localStorage.getItem('_ERFTKEN') ? true : undefined,
  //type: undefined,
  user: {} as UserData,
  message: '',
};

const UserReducer = (
  state = initialState,
  { type, payload }: UserActionType,
): UserStateType => {
  switch (type) {
    case USER:
      return {
        isLoggedIn: payload.isLoggedIn,
        user: { ...payload.user },
        message: payload.message,
        //type: payload.type,
      };

    case USER_FAILURE:
      return {
        isLoggedIn: payload.isLoggedIn,
        user: payload.user,
        message: payload.message,
        //type: payload.type,
      };

    case USERS_LOGOUT:
      return payload;

    default:
      return state;
  }
};
export default UserReducer;
