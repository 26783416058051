import ChartBody from './ChartBody';
import {
  chartColors,
  // darkColors,
  heatMapColors,
  // PurpleExrColor,
  stackedChartColors,
} from '../chartsTheme';
import { CHARTS, optionsVisualisation } from '../constants';
// import {
//   barChartTestData,
//   heatMapTestData,
//   radarChartTestData,
//   stackedBarChartTestData,
//   waterfallChartTestData,
// } from '../testData';
import React, { useState } from 'react';
import { widgetRankData } from '../../../../../redux/selector/WidgetRankSelector';
import {
  IconChatbot,
  Iconclose,
  // IconCross,
  IconFullScreen,
} from '../../../../../components/public/Icons';
import ChartWidgetMenuDropdown from '../../../../../components/ui/ChartWidgetMenuDropdown';
import RemoveWidget from '../../../newsfeed/widgets/RemoveWidget';
import { useSelector } from 'react-redux';
import { allPortfolioWidgetsSelector } from '../../../../../redux/selector/PortfolioWidgetsSelector';
import { callTelemetryRecord } from '../../../../../helpers/utils.helper';
import useAction from '../../../../../components/hooks/useAction';
import PublicChat from '../../../../../components/public/PublicChat';
import {
  heatType,
  LineType,
  RadarType,
  ScatterType,
  StackedType,
} from '../../../../../../@types/PortfolioType';
import axios, { AxiosError } from 'axios';
import config from '../../../../../../config';
import SendWidgetAsTask from '../../../newsfeed/widgets/SendWidgetAsTask';
// import { widgetReferenceIdData } from '../../../../../redux/selector/WidgetReferenceIdSelector';
// import WidgetLoader from '../../../../../components/loaders/WidgetLoader';
import ChartSaveModal from '../../../../../components/modals/SaveModal';
import ShareWidgetExternally from '../../../newsfeed/widgets/ShareWidgetExternally';
// import { color, width } from '@mui/system';
// import SelectTypeAndParameters from '../../addportfoliowidget/analyticsWidgetWorkflow/SelectTypeAndParameters';
// import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
// import AnalyticsWidgetHeading from '../../addportfoliowidget/WidgetWorkflowHeadings/AnalyticsWidgetHeading';

interface ChartsWrapperProps {
  onClose: any;
  embeddedBrowserWidgetIndex: number;
  type?: string;
  widgetFullScreen: boolean;
  setWidgetFullScreen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ChartsWrapper = ({
  onClose,
  embeddedBrowserWidgetIndex,
  widgetFullScreen,
  setWidgetFullScreen,
}: // type
ChartsWrapperProps) => {
  const widRank = useSelector(widgetRankData);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const allPortfolioWidgetReduxData = useSelector(allPortfolioWidgetsSelector);
  const {
    removePortfolioVisualizationWidgetData,
    addWidgetReferenceId,
    addWidgetType,
    showModal,
  } = useAction();

  const chartsConfiguration =
    allPortfolioWidgetReduxData[embeddedBrowserWidgetIndex];

  const chartType = chartsConfiguration?.visualisationType?.value;

  const toggleFilterModal = () => {
    // document.body.classList.toggle('has-overlay');
    document.body.classList.add('show-widget-chat');
  };

  const toggleWidgetFullScreen = (type?: string) => {
    document.body.classList.toggle('widget-fullscreen');
    setWidgetFullScreen((widgetFullScreen: any) => !widgetFullScreen);
    setIsChatOpen(false);

    // fullscreen widget go to top on fullscreen click
    const pagecontent = document.querySelector('.page-content');
    if (pagecontent) {
      pagecontent.scroll({ top: 0 });
    }

    if (type === 'add') {
      document.body.classList.add(`widget-${embeddedBrowserWidgetIndex}`);
      document.body.classList.add('widget-fullscreen');

      const addElement = document.getElementById(
        `fs-analytics-${embeddedBrowserWidgetIndex}`,
      );
      if (addElement) {
        const parentElement = addElement.closest('.react-grid-item');
        if (parentElement) {
          setTimeout(() => {
            //
            parentElement.classList.add('widget-active');
          }, 10);
        }
      }
    } else if (type === 'remove') {
      document.body.classList.remove(`widget-${embeddedBrowserWidgetIndex}`);
      document.body.classList.remove('widget-fullscreen');

      const removeElement = document.getElementById(
        `fsrem-analytics-${embeddedBrowserWidgetIndex}`,
      );
      if (removeElement) {
        const parentElement = removeElement.closest('.react-grid-item');
        if (parentElement) {
          parentElement.classList.remove('widget-active');
        }
      }
    }
  };

  const [dropdownOpen, setDropDownOpen] = useState(false);
  const toggleDropDown = () => {
    setDropDownOpen((state) => !state);
  };

  const [removeWidgetModal, setRemoveWidget] = useState(false);
  const toggleRemoveWidgetModal = () => setRemoveWidget(!removeWidgetModal);

  const [sendWidgetAsTaskModal, setSendWidgetAsTaskModal] =
    React.useState(false);

  const shareVisualizationModal = () =>
    setSendWidgetAsTaskModal(!sendWidgetAsTaskModal);

  const [shareExternallyModal, setShareExternallyModal] = useState(false);
  const toggleShareExternallyModal = () =>
    setShareExternallyModal(!shareExternallyModal);

  // const [isCalculated, setIsCalculated] = useState(false);

  const removeAnalyticsWidget = () => {
    onClose();

    if (widgetFullScreen) {
      toggleWidgetFullScreen('remove');
    }

    removePortfolioVisualizationWidgetData(embeddedBrowserWidgetIndex);
    document.body.classList.remove('widget-fullscreen');

    if (
      widRank &&
      widRank.widgetRank &&
      widRank?.widgetRank?.slots?.length > 0
    ) {
      callTelemetryRecord(
        'widgets',
        widRank?.widgetRank?.event_id,
        widRank?.widgetRank?.slots[0]?.id,
        ['close'],
      );
    }
  };
  const isAxiosError = (error: any): error is AxiosError => {
    return axios.isAxiosError(error);
  };

  const [isSaveModalOpen, setSaveModalOpen] = useState(false);

  const toggleSaveModal = () => setSaveModalOpen(!isSaveModalOpen);

  // const handleSaveChart = async (chartName: string) => {
  //   await chartSaveModal(chartName); // Pass the chart name to the saving function
  // };

  const chartSaveModal = async (chartName: string) => {
    const { chartId, chartTitle, fields, portfolioId, visualisationType } =
      chartsConfiguration;

    const selectedVisualisation = optionsVisualisation.find(
      (option: any) => option.value === visualisationType.value,
    );

    const payload = {
      chartId: chartId,
      chartTitle: chartTitle || 'Default Title',
      savedName: chartName || 'Default Name',
      portfolioId: portfolioId,
      visualisationType: selectedVisualisation
        ? selectedVisualisation.label
        : 'Unknown Chart',
      fields: fields,
      xAxisLabel: fields.xAxis.label || 'X Axis',
      yAxisLabel: fields.yAxis ? fields.yAxis.label || 'Y Axis' : null,
      // xAxisLabel: fields.xAxis.length ? fields.xAxis[0].label : 'X Axis',
      // yAxisLabel: fields.yAxis.length ? fields.yAxis[0].label : 'Y Axis',
    };
    try {
      await axios.post(`${config.ER_ML_URL}/portfolio/save-chart`, payload);
      showModal('Saved Successfully!', true);
    } catch (error) {
      if (isAxiosError(error)) {
        showModal(
          (error.response?.data as { error?: string })?.error ||
            'Unexpected Axios error',
        );
      } else {
        showModal('An unexpected error occurred');
      }
    }
  };

  const returnTestData = () => {
    const tempData = chartsConfiguration?.data;
    let lineChartData: LineType[] = [];
    let radarChartData: RadarType[] = [];
    let scatterChartData: ScatterType[] = [];
    let stackedChartData: StackedType[] = [];
    let heatChartData: heatType[] = [];
    let pieChartData: any[] = [];
    let waterfallChartData: any[] = [];
    let bubbleChartData: any[] = [];

    const barChartData = [
      {
        [chartType === CHARTS.BAR ? 'x' : 'labels']:
          chartType === CHARTS.BAR ? tempData?.x : tempData?.labels,
        [chartType === CHARTS.BAR ? 'y' : 'values']:
          chartType === CHARTS.BAR ? tempData?.y : tempData?.values,
        type: chartType?.toLowerCase(),
        marker: {
          color: 'rgba(149,68,213,1)',
          line: {
            width: 1,
            color: 'rgba(149,68,213,1)',
          },
        },
      },
    ];

    if (chartType === CHARTS.BUBBLE) {
      if (tempData) {
        bubbleChartData = [
          {
            x: tempData?.x,
            y: tempData?.y,
            mode: 'markers',
            type: 'scatter',
          },
        ];
      }
    }

    // if (chartType === CHARTS.LINE) {
    //   console.log(tempData);
    //   if (tempData) {
    //     lineChartData = [
    //       {
    //         x: tempData?.x,
    //         y: tempData?.y,
    //         fill: '',
    //         type: 'scatter',
    //         mode: 'lines+markers',
    //         marker: {
    //           color: 'rgba(149,68,213,1)',
    //         },
    //       },
    //     ];
    //   }
    // }

    if (chartType === CHARTS.LINE) {
      if (tempData) {
        if (
          Object.prototype.hasOwnProperty.call(tempData, 'x') &&
          Object.prototype.hasOwnProperty.call(tempData, 'y')
        ) {
          lineChartData = [
            {
              x: tempData.x,
              y: tempData.y,
              fill: '',
              type: 'scatter',
              mode: 'lines+markers',
              marker: {
                color: 'rgba(149,68,213,1)',
              },
            },
          ];
        } else {
          lineChartData = Object.keys(tempData).map((traceKey) => ({
            x: tempData[traceKey]?.x,
            y: tempData[traceKey]?.y,
            fill: '',
            type: 'scatter',
            mode: 'lines+markers',
            marker: {
              color: 'rgba(149,68,213,1)',
            },
          }));
        }
      }
    }

    if (chartType === CHARTS.PIE) {
      if (tempData) {
        pieChartData = [
          {
            labels: tempData?.labels,
            values: tempData?.values,
            type: 'pie',
            marker: {
              colors: heatMapColors,
            },
          },
        ];
      }
    }

    if (chartType === CHARTS.AREA) {
      if (tempData) {
        lineChartData = Object.keys(tempData).map((key) => {
          return {
            x: tempData[key]?.x,
            y: tempData[key]?.y,
            fill: 'tonexty',
            mode: '',
            type: 'scatter',
            marker: {
              color: 'rgba(149,68,213,1)',
            },
          };
        });
      }
    }

    if (chartType === CHARTS.RADAR) {
      if (tempData) {
        radarChartData = [
          {
            r: tempData?.r,
            type: 'scatterpolar',
            theta: tempData?.theta,
            fill: 'toself',
            name: 'Group A',
            marker: {
              color: chartColors[0],
            },
            line: {
              color: chartColors[7],
            },
          },
        ];
      }
    }

    if (chartType === CHARTS.SCATTER) {
      // console.log(tempData);
      if (tempData) {
        scatterChartData = Object.keys(tempData).map((key, index) => {
          return {
            x: tempData[key]?.x,
            y: tempData[key]?.y,
            type: 'scatter',
            mode: 'markers',
            marker: {
              size: 12,
              color: heatMapColors[index % heatMapColors.length],
            },
          };
        });
      }
    }

    if (chartType === CHARTS.STACKED) {
      if (tempData) {
        // console.log(tempData);
        stackedChartData = Object.keys(tempData).map((key, index) => {
          return {
            x: tempData[key]?.x,
            y: tempData[key]?.y,
            type: 'bar',
            marker: {
              color: stackedChartColors[index % stackedChartColors.length],
            },
          };
        });
      }
    }

    if (chartType === CHARTS.HEATMAP) {
      if (tempData) {
        const heatMapColorscale = [
          [0, heatMapColors[0]],
          [0.3, heatMapColors[1]],
          [0.6, heatMapColors[2]],
          [0.8, heatMapColors[3]],
          [1, heatMapColors[4]],
        ];
        heatChartData.push({
          z: tempData?.z,
          x: tempData?.x,
          y: tempData?.y,
          type: 'heatmap',
          hoverongaps: false,
          colorscale: heatMapColorscale,
        });
      }
    }

    if (chartType === CHARTS.WATERFALL) {
      if (tempData) {
        waterfallChartData = Object.keys(tempData).map((key, index) => {
          return {
            name: key,
            x: tempData[key]?.x,
            y: tempData[key]?.y,
            type: 'waterfall',
            orientation: 'v',
            textposition: 'outside',
            measure: tempData[key]?.measure || ['relative'],
            decreasing: { marker: { color: chartColors[0] } },
            increasing: { marker: { color: chartColors[1] } },
            totals: { marker: { color: chartColors[2] } },
            connector: {
              line: {
                color: 'rgba(63, 63, 63, 0.5)',
              },
            },
            marker: {
              color: chartColors[index % chartColors.length],
            },
          };
        });
      }
    }

    switch (chartType) {
      case CHARTS.BAR:
        return barChartData;
      // return barChartTestData;
      case CHARTS.LINE:
        return lineChartData;
      case CHARTS.STACKED:
        return stackedChartData;
      // return stackedBarChartTestData;
      case CHARTS.PIE:
        return pieChartData;
      case CHARTS.SCATTER:
        return scatterChartData;
      case CHARTS.AREA:
        return lineChartData;
      case CHARTS.BUBBLE:
        return bubbleChartData;
      case CHARTS.HEATMAP:
        return heatChartData;
      // return heatMapTestData;
      case CHARTS.RADAR:
        return radarChartData;
      // return radarChartTestData;
      case CHARTS.WATERFALL:
        return waterfallChartData;
      default:
        return [];
    }
  };

  return (
    <>
      {/* {<WidgetLoader title="" />} */}
      <div className="common-widget">
        <div className="common-widget-header">
          <div className="common-widget-header-title">
            <h2>{chartsConfiguration?.chartTitle || 'Analytics widget'}</h2>

            {widgetFullScreen && (
              <div className="ms-6 no-drag">
                <ChartWidgetMenuDropdown
                  dropdownOpen={dropdownOpen}
                  toggleDropDown={toggleDropDown}
                  chartSaveModal={toggleSaveModal}
                  shareVisualizationModal={shareVisualizationModal}
                  // toggleEditWidgetModal={toggleEditWidgetModal}
                  toggleShareExternallyModal={toggleShareExternallyModal}
                  toggleRemoveWidgetModal={toggleRemoveWidgetModal}
                />
              </div>
            )}

            {!widgetFullScreen && (
              <div className="d-flex aling-items-center ms-12 no-drag">
                <button
                  id={`fs-analytics-${embeddedBrowserWidgetIndex}`}
                  type="button"
                  className="btn-icon"
                  onClick={() => {
                    toggleWidgetFullScreen('add');
                    addWidgetType('EMBEDDED_BROWSER');
                    addWidgetReferenceId(embeddedBrowserWidgetIndex);
                  }}
                >
                  <IconFullScreen />
                </button>
                <button
                  type="button"
                  className={isChatOpen ? 'icon-active btn-icon' : 'btn-icon'}
                  onClick={() => {
                    setIsChatOpen(!isChatOpen);
                    toggleFilterModal();
                    addWidgetType('EMBEDDED_BROWSER');
                    addWidgetReferenceId(embeddedBrowserWidgetIndex);
                    if (
                      widRank &&
                      widRank.widgetRank &&
                      widRank?.widgetRank?.slots?.length > 0
                    ) {
                      callTelemetryRecord(
                        'widgets',
                        widRank?.widgetRank?.event_id,
                        widRank?.widgetRank?.slots[0]?.id,
                        ['chat'],
                      );
                    }
                  }}
                >
                  <IconChatbot index={'derivative'} />
                </button>
              </div>
            )}
          </div>

          {widgetFullScreen && (
            <div className="common-widget-header-action no-drag">
              <button
                id={`fsrem-analytics-${embeddedBrowserWidgetIndex}`}
                type="button"
                className="btn-icon"
                onClick={() => {
                  toggleWidgetFullScreen('remove');
                }}
              >
                <Iconclose />
              </button>
            </div>
          )}

          {!widgetFullScreen && (
            <div className="common-widget-header-action no-drag">
              <ChartWidgetMenuDropdown
                dropdownOpen={dropdownOpen}
                toggleDropDown={toggleDropDown}
                chartSaveModal={toggleSaveModal}
                shareVisualizationModal={shareVisualizationModal}
                toggleShareExternallyModal={toggleShareExternallyModal}
                // toggleEditWidgetModal={toggleEditWidgetModal}
                toggleRemoveWidgetModal={toggleRemoveWidgetModal}
              />
            </div>
          )}
        </div>

        <div
          id="chart-dynamic-div"
          className="common-widget-content"
          onClick={() => {
            if (
              widRank &&
              widRank.widgetRank &&
              widRank?.widgetRank?.slots?.length > 0
            ) {
              callTelemetryRecord(
                'widgets',
                widRank?.widgetRank?.event_id,
                widRank?.widgetRank?.slots[0]?.id,
                ['click'],
              );
            }
          }}
        >
          {/* Chat True Show*/}
          {isChatOpen && !widgetFullScreen && (
            <div className="widget-chat">
              <PublicChat />
            </div>
          )}
          {widgetFullScreen && chartType && (
            <ChartBody
              chart={chartsConfiguration}
              chartData={returnTestData()}
            />
          )}
          {!isChatOpen && (
            <ChartBody
              chart={chartsConfiguration}
              chartData={returnTestData()}
            />
          )}
        </div>
      </div>

      {removeWidgetModal && (
        <RemoveWidget
          modal={removeWidgetModal}
          toggle={toggleRemoveWidgetModal}
          removeWidget={removeAnalyticsWidget}
        />
      )}

      {sendWidgetAsTaskModal && (
        <SendWidgetAsTask
          type="VISUALISATION WIDGET"
          modal={sendWidgetAsTaskModal}
          toggle={shareVisualizationModal}
          headers={chartsConfiguration.fields}
          portfolioId={chartsConfiguration.portfolioId}
          chartType={chartType}
        />
      )}

      {shareExternallyModal && (
        <ShareWidgetExternally
          type="VISUALISATION WIDGET"
          modal={shareExternallyModal}
          toggle={toggleShareExternallyModal}
          headers={chartsConfiguration.fields}
          portfolioId={chartsConfiguration.portfolioId}
          chartType={chartType}
          xData={
            chartType === 'SCATTER' ||
            chartType === 'AREA' ||
            chartType === 'STACKED' ||
            chartType === 'WATERFALL'
              ? Object.values(chartsConfiguration?.data).map(
                  (trace: any) => trace.x,
                )
              : chartType === 'HEATMAP'
                ? chartsConfiguration?.data?.x
                : chartType === 'RADAR'
                  ? chartsConfiguration?.data?.theta
                  : chartsConfiguration?.data?.x?.length
                    ? chartsConfiguration.data.x
                    : chartsConfiguration?.data?.labels
          }
          yData={
            chartType === 'SCATTER' ||
            chartType === 'AREA' ||
            chartType === 'STACKED' ||
            chartType === 'WATERFALL'
              ? Object.values(chartsConfiguration?.data).map(
                  (trace: any) => trace.y,
                )
              : chartType === 'HEATMAP'
                ? chartsConfiguration?.data?.y
                : chartType === 'RADAR'
                  ? chartsConfiguration?.data?.r
                  : chartsConfiguration?.data?.y?.length
                    ? chartsConfiguration.data.y
                    : chartsConfiguration?.data?.values
          }
          measure={
            chartType === 'WATERFALL'
              ? Object.values(chartsConfiguration?.data).map(
                  (trace: any) => trace.measure,
                )
              : undefined
          }
          zData={
            chartType === 'HEATMAP' ? chartsConfiguration?.data?.z : undefined
          }
        />
      )}

      <ChartSaveModal
        modal={isSaveModalOpen}
        toggle={toggleSaveModal}
        onSave={(chartName) => chartSaveModal(chartName)}
      />
    </>
  );
};

export default ChartsWrapper;
