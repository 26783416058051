import { useEffect, useState } from 'react';
import CustomSelect from '../../../components/ui/CustomSelect';
import axios, { AxiosError } from 'axios';
import SettingsService from '../../../services/settings.service';
import { showModal } from '../../../redux/actions';
import {
  StatisticList,
  StatisticsData,
  StatisticsUser,
} from '../../../../@types/SettingsType';
import AppLoader from '../../../components/loaders/AppLoader';
import { SERVICE_TYPES } from '../../../helpers/utils.helper';
import { getCurrentYearMonths } from '../../../helpers/constants.function';

const Statistics = ({ activeTab }: { activeTab: number | string }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [allStatisticsData, setAllStatisticsData] = useState<Array<
    StatisticList
  > | null>(null);
  const [allUsers, setAllUsers] = useState<Array<StatisticsUser> | null>(null);
  const [selectedUser, setSelectedUser] = useState<{
    label: string;
    value: string;
  } | null>(null);
  const [selectedDataItem, setSelectedDataItem] = useState<{
    label: string;
    value: string;
  } | null>(null);
  const [selectedTime, setSelectedTime] = useState<{
    label: string;
    value: string;
  } | null>(null);

  const settingsService = new SettingsService();

  const isAxiosError = (error: any): error is AxiosError => {
    return axios.isAxiosError(error);
  };

  const getUserData = async () => {
    setIsLoading(true);
    try {
      const response: any = await settingsService.getStatisticsUsers();
      setAllUsers(response.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if (isAxiosError(error)) {
        showModal(
          (error.response?.data as { error?: string })?.error ||
            'Unexpected Axios error',
        );
      } else {
        showModal('An unexpected error occurred');
      }
    }
  };

  const getStatisticsAllData = async () => {
    setIsLoading(true);
    const data: StatisticsData = {};
    if (selectedUser?.value !== undefined) {
      data.userId = selectedUser?.value;
    }
    if (selectedDataItem?.value !== undefined) {
      data.dataType = selectedDataItem?.value;
    }
    if (selectedTime?.value !== undefined) {
      data.timePeriod = selectedTime?.value;
    }

    try {
      const response: any = await settingsService.getStatisticsData(
        Object.keys(data)?.length > 0 ? data : {},
      );
      setAllStatisticsData(response.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if (isAxiosError(error)) {
        showModal(
          (error.response?.data as { error?: string })?.error ||
            'Unexpected Axios error',
        );
      } else {
        showModal('An unexpected error occurred');
      }
    }
  };

  useEffect(() => {
    if (activeTab === '3') getUserData();
  }, [activeTab]);

  useEffect(() => {
    getStatisticsAllData();
  }, [selectedUser, selectedTime, selectedDataItem]);

  return (
    <>
      {isLoading && <AppLoader />}

      <div className="user-form wc-scroll">
        <div className="user-form-item">
          <h5 className="fi-heading">Data Marketplace Queries</h5>
          <div className="row">
            <div className="col-12 col-lg-10 offset-2">
              <div className="row">
                <div className="col-12 col-lg-5">
                  <div className="form-group">
                    <label className="form-label">User</label>
                    <CustomSelect
                      options={
                        allUsers &&
                        allUsers.map(user => ({
                          label: user.email,
                          value: user._id,
                        }))
                      }
                      placeholder={'All Users'}
                      value={selectedUser}
                      onChange={(e: any) => {
                        setSelectedUser(e);
                      }}
                    />
                  </div>
                </div>
                <div className="col-12 col-lg-5">
                  <div className="form-group">
                    <label className="form-label">Data Item Type</label>
                    <CustomSelect
                      options={Object.entries(SERVICE_TYPES).map(
                        ([value, label]) => ({
                          label: label,
                          value: value,
                        }),
                      )}
                      placeholder={'Data Source'}
                      value={selectedDataItem}
                      onChange={(e: any) => {
                        setSelectedDataItem(e);
                      }}
                    />
                  </div>
                </div>
                <div className="col-12 col-lg-2">
                  <div className="form-group">
                    <label className="form-label">Time period</label>
                    <CustomSelect
                      options={getCurrentYearMonths()}
                      placeholder="May 2024"
                      value={selectedTime}
                      onChange={(e: any) => {
                        setSelectedTime(e);
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="table-responsive">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">Data Item NAme</th>
                      <th scope="col">URL</th>
                      <th style={{ width: '116px' }}>API Requests</th>
                    </tr>
                  </thead>
                  <tbody>
                    {isLoading ? (
                      <></>
                    ) : allStatisticsData && allStatisticsData?.length > 0 ? (
                      <>
                        {allStatisticsData?.map(item => (
                          <tr key={item._id}>
                            <td>{item?.endpointName}</td>
                            <td>{item?.endpoint}</td>
                            <td className="text-end">{item?.count}</td>
                          </tr>
                        ))}
                      </>
                    ) : (
                      <div className="text-center">No Record Found!</div>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Statistics;
