import { widgetTypeConstant } from '../constant';

const { ADD_WIDGET_TYPE } = widgetTypeConstant;

export const addWidgetType = (widgetType: any) => {
  return {
    type: ADD_WIDGET_TYPE,
    payload: { widgetType },
  };
};
