import { useEffect, useState } from 'react';
import CustomSelect from '../../../../../components/ui/CustomSelect';
import {
  optionsVisualisation,
  CHARTS,
} from '../../../portfolio/Charts/constants';
import { Formik } from 'formik';
import { Form } from 'reactstrap';
// import DataLoader from '../../../../../components/loaders/DataLoader';

const SelectParametersCharts = ({
  dataArray,
  onFormChange,
  initialValues,
}: {
  dataArray: any[];
  onFormChange: any;
  initialValues?: any;
}) => {
  // const [isLoading, setIsLoading] = useState(false);
  const [selectedChartType, setSelectedChartType] = useState(CHARTS.BAR);

  // const prevValuesRef = useRef({
  //   xAxis: [],
  //   yAxis: [],
  // });

  const defaultValues = {
    chartTitle: '',
    visualisationType: optionsVisualisation[0],
    fields: {
      xAxis: [],
      yAxis: [],
    },
  };

  const updateFields = (fields: any, type: string, value: any) => {
    const updatedFields = { ...fields };
    updatedFields[type] = value;
    return updatedFields;
  };

  const axisOptions = dataArray.map(item => ({
    label: item.label,
    value: item.label,
  }));

  const getValueByLabel = (label: string) => {
    const foundItem = dataArray.find(item => item.label === label);
    return foundItem ? foundItem.value : null;
  };

  return (
    <div className="addwidget-content mw-100">
      <div className="row justify-content-center">
        <div className="col-12 col-lg-6">
          <div className="create-widget wl-440 px-0">
            {/* {isLoading ? (
              <DataLoader title={''} />
            ) : ( */}
            <div className="widget-item">
              <Formik
                initialValues={initialValues || defaultValues}
                onSubmit={() => {}}
                enableReinitialize
              >
                {({ values, setFieldValue, handleChange }) => {
                  useEffect(() => {
                    onFormChange(values);
                  }, [values, onFormChange]);

                  return (
                    <Form>
                      <div className="row">
                        <div className="col-12">
                          <div className="form-group">
                            <label className="form-label">
                              Chart title (Optional)
                            </label>
                            <input
                              id="chartTitle"
                              name="chartTitle"
                              type="text"
                              className="form-control"
                              value={values.chartTitle}
                              onChange={(e: any) => {
                                setFieldValue('chartTitle', e.target.value);
                                handleChange(e.target.value);
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group">
                            <label className="form-label">
                              Visualisation Type
                            </label>
                            <CustomSelect
                              id="visualisationType"
                              name="visualisationType"
                              options={optionsVisualisation}
                              placeholder=""
                              value={values.visualisationType}
                              onChange={(e: any) => {
                                setSelectedChartType(e.value);
                                setFieldValue('visualisationType', e);
                                handleChange(e);
                              }}
                            />
                          </div>
                        </div>

                        {/* Chart wise fields */}
                        <div className="row">
                          <div className="col-12 col-lg-6">
                            <div className="form-group">
                              <label className="form-label">X-Axis</label>
                              <CustomSelect
                                id="xAxis"
                                name="xAxis"
                                options={axisOptions}
                                isMulti={true}
                                value={values.fields['xAxis']}
                                onChange={(selectedOptions: any) => {
                                  const updatedValue = selectedOptions.map(
                                    (option: any) => ({
                                      label: option.label,
                                      value: getValueByLabel(option.label),
                                    }),
                                  );

                                  setFieldValue(
                                    'fields',
                                    updateFields(
                                      values.fields,
                                      'xAxis',
                                      updatedValue,
                                    ),
                                  );
                                  handleChange(selectedOptions);
                                }}
                              />
                            </div>
                          </div>

                          {selectedChartType !== CHARTS.PIE &&
                            selectedChartType !== CHARTS.RADAR && (
                              <div className="col-12 col-lg-6">
                                <div className="form-group">
                                  <label className="form-label">Y-Axis</label>
                                  <CustomSelect
                                    id="yAxis"
                                    name="yAxis"
                                    options={axisOptions}
                                    isMulti={true}
                                    value={values.fields['yAxis']}
                                    onChange={(selectedOptions: any) => {
                                      const updatedValue = selectedOptions.map(
                                        (option: any) => ({
                                          label: option.label,
                                          value: getValueByLabel(option.label),
                                        }),
                                      );

                                      setFieldValue(
                                        'fields',
                                        updateFields(
                                          values.fields,
                                          'yAxis',
                                          updatedValue,
                                        ),
                                      );
                                      handleChange(selectedOptions);
                                    }}
                                  />
                                </div>
                              </div>
                            )}
                        </div>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
            {/* )} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectParametersCharts;
