import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import {
  IconDownArrow,
  IconLeftArrowAngle,
  IconRightArrowAngle,
} from './Icons';
import CustomSelect from '../ui/CustomSelect';

interface PaginationData {
  totalData: number;
  currentPage: number;
  limit: number;
  // eslint-disable-next-line no-unused-vars
  onPageChange: (arg0: number, arg1?: number) => void;
}

const PublicPagination = ({
  totalData,
  currentPage,
  limit,
  onPageChange,
}: PaginationData) => {
  const pageOptions = [
    { label: '25', value: 25 },
    { label: '50', value: 50 },
    { label: '75', value: 75 },
    { label: '100', value: 100 },
  ];

  const totalPages = Math.ceil(totalData / limit);

  const generatePageNumbers = () => {
    let pages = [];
    const delta = 2;

    if (totalPages <= 10) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      pages.push(1);

      if (currentPage > delta + 2) {
        pages.push('...');
      }
      const startPage = Math.max(2, currentPage - delta);
      const endPage = Math.min(totalPages - 1, currentPage + delta);
      for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
      }

      if (currentPage < totalPages - delta - 1) {
        pages.push('...');
      }
      pages.push(totalPages);
    }

    return pages;
  };

  const handlePageChange = (page: number) => {
    if (page > 0 && page <= totalPages) {
      onPageChange(page);
    }
  };

  return (
    <div className="pagination-main">
      <div className="page-dropdown">
        <div className="select-clear text-info">
          <CustomSelect
            options={pageOptions}
            isSearchable={false}
            value={`1-5 of ${totalData}`}
            placeholder={
              <div className="d-flex gap-2">
                <span className="ps-1">{`${(currentPage - 1) * limit +
                  1}-${Math.min(
                  currentPage * limit,
                  totalData,
                )} of ${totalData}`}</span>
                <div className="arrow-icon">
                  <IconDownArrow width={16} height={16} />
                </div>
              </div>
            }
            onChange={(selectedOption: { value: any }) =>
              onPageChange(1, selectedOption.value)
            }
          />
        </div>
      </div>
      <div className="page-nav">
        <Pagination aria-label="Page navigation example">
          <PaginationItem disabled={currentPage <= 1}>
            <PaginationLink
              href="#"
              onClick={() => handlePageChange(currentPage - 1)}
            >
              <span>
                <IconLeftArrowAngle fill="#36567D" width={24} height={24} />
              </span>
            </PaginationLink>
          </PaginationItem>

          {generatePageNumbers().map((page: any, index: number) =>
            page === '...' ? (
              <PaginationItem className="page-dot" key={index}>
                <PaginationLink href="#">...</PaginationLink>
              </PaginationItem>
            ) : (
              <PaginationItem key={page} active={page === currentPage}>
                <PaginationLink href="#" onClick={() => handlePageChange(page)}>
                  {page}
                </PaginationLink>
              </PaginationItem>
            ),
          )}
          <PaginationItem disabled={currentPage >= totalPages}>
            <PaginationLink
              href="#"
              onClick={() => handlePageChange(currentPage + 1)}
            >
              <span>
                <IconRightArrowAngle fill="#36567D" width={24} height={24} />
              </span>
            </PaginationLink>
          </PaginationItem>
        </Pagination>
      </div>
    </div>
  );
};
export default PublicPagination;
