/* eslint-disable */
import { useEffect, useState } from 'react';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import { IconPlus } from '../../../components/public/Icons';
import PublicHeader from '../../../components/public/PublicHeader';
// import PublicPageHeader from '../../../components/public/PublicPageHeader';
//import NewsfeedWidget from '../dashboard/NewsfeedsWidget';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { callTelemetryRecord } from '../../../helpers/utils.helper';
import { allNewsfeedWidgetsData } from '../../../redux/selector/NewsFeedWidgetsSelector';
import { widgetRankData } from '../../../redux/selector/WidgetRankSelector';
import MoodyNewsfeedsWidget from './widgets/MoodyNewsfeedsWidget';
import NewsfeedsWidget from './widgets/NewsfeedsWidget';

const jumpBy = [0, 0, 1, 1, 2, 2, 3, 3, 4, 4, 5, 5, 6, 6, 7, 7, 8, 8];

type _widgets = 'news' | 'moody_news';

const ResponsiveWidth = WidthProvider(Responsive);
const layouts = {
  md: [
    { i: 'a', x: 0, y: 0, w: 6, h: 4 },
    { i: 'b', x: 6, y: 0, w: 6, h: 4 },
    { i: 'c', x: 4, y: 6, w: 6, h: 4 },
    { i: 'd', x: 4, y: 6, w: 6, h: 4 },
    { i: 'e', x: 4, y: 6, w: 6, h: 4 },
    { i: 'f', x: 4, y: 6, w: 6, h: 4 },
    { i: 'g', x: 4, y: 6, w: 6, h: 4 },
  ],
};

const Newsfeed = () => {
  const [dropdownOpen, setDropDownOpen] = useState(false);
  const location = useLocation();
  const widRank = useSelector(widgetRankData);
  const newsFeedData = useSelector(allNewsfeedWidgetsData);
  const [widgetFullScreen, setWidgetFullScreen] = useState(false);

  const toggleDropDown = () => {
    setDropDownOpen((state) => !state);
  };

  //const user = useSelector(userData);
  // const [layout, setLayout] = useState(layoutSchema);
  //const [layout] = useState(layoutSchema);

  const [widgetToShow, setWidgetToShow] = useState<
    { label: string; value: _widgets; component: any }[] | null
  >(null);
  //const { search } = useLocation();

  const closeHandler = (widgetName: _widgets, index: number) => {
    setWidgetToShow((toShow) => {
      if (toShow === null) {
        return null;
      }
      return toShow.filter((wid, widIndex) => widIndex !== index);
    });
    /*setWidgets(oldWidget => {
      let widgetToAdd = _widgetInitials.find(
        widget => widget.value === widgetName,
      );
      if (widgetToAdd) {
        return [...oldWidget, widgetToAdd];
      }
      return [...oldWidget];
    });*/
  };

  const _widgetInitials: {
    label: string;
    value: _widgets;
    component: any;
  }[] = [
    {
      label: 'News Feed Widget',
      value: 'news',
      component: (index: any) => (
        <NewsfeedsWidget
          onClose={(val: _widgets) => closeHandler('news', index)}
          newsWidgetIndex={index}
          type="NEWSFEED"
          widgetFullScreen={widgetFullScreen}
          setWidgetFullScreen={setWidgetFullScreen}
        />
      ),
    },
  ];

  useEffect(() => {
    if (newsFeedData?.length) {
      let newArray = [] as any;

      newsFeedData.forEach((y: any, widReduxIndex: number) => {
        y.id = widReduxIndex;

        _widgetInitials.forEach((x) => {
          if (y.value === x.value) {
            newArray.push(x);
          }
        });
      });

      setWidgetToShow(newArray);
    }
  }, [newsFeedData, widgetFullScreen]);

  const [widgets, setWidgets] = useState<
    {
      label: string;
      value: _widgets;
      component: any;
    }[]
  >(_widgetInitials);

  useEffect(() => {
    if (!newsFeedData?.length) {
      if (
        widRank &&
        widRank.widgetRank &&
        widRank?.widgetRank?.slots?.length > 0
      ) {
        let widToShow =
          widRank?.widgetRank?.slots[0]?.widget_group?.features?.filter(
            (x: any) =>
              x.platform_sections.includes(
                location.pathname.split('/')[1] === 'newsfeed' && 'news',
              ),
          );

        const newArr = [] as any;
        let widArry = [] as any;

        _widgetInitials.forEach((x: any) => {
          widToShow.forEach((y: any) => {
            if (x.value === y.name) {
              newArr.push(x);
              return;
            } else {
              if (!newArr.includes(x)) {
                widArry.push(x);
              }
            }
          });
        });

        widArry = widArry
          .filter(
            (value: any, index: any, self: any) =>
              index === self.findIndex((t: any) => t.value === value.value),
          )
          .filter((val: any) => !newArr.includes(val));

        if (newArr?.length === 0) {
          widArry = [..._widgetInitials];
        }
        setWidgetToShow(newArr);

        //setWidgets(widArry);
        setWidgets([..._widgetInitials]);
      }
    }
  }, [widRank]);

  const [dimensions] = useState<
    {
      i: string;
      x: number;
      y: number;
      w: number;
      h: number;
      minW: number;
      maxW: number;
      minH: number;
      maxH: number;
    }[]
  >([{ i: '0', x: 0, y: 0, w: 6, h: 4, minW: 2.5, minH: 2, maxW: 7, maxH: 5 }]);

  return (
    <>
      {/* <Sidebar /> */}
      <div className="page-content ">
        <PublicHeader />
        {/* <PublicPageHeader /> */}
        <div className="page-middle-content">
          {/* <div className="sliding-content">
            <div className="holder">
              <div className="news">
                <Nav className="newscroll">
                  <NavItem>
                    <label htmlFor="">IDXESGL</label>
                    134139.967 825
                    <span className="text-success">
                      {' '}
                      0.24% <IconAngleUp />
                    </span>
                  </NavItem>
                  <NavItem>
                    <label htmlFor="">Composite</label>
                    6,638.019
                    <span className="text-danger">
                      {' '}
                      0.33% <IconAngleDown />
                    </span>
                  </NavItem>
                  <NavItem>
                    <label htmlFor="">LQ45</label>
                    945.068
                    <span className="text-danger">
                      0.03% <IconAngleDown />
                    </span>
                  </NavItem>
                  <NavItem>
                    <label htmlFor="">IDX30</label>
                    134.825
                    <span className="text-success">
                      {' '}
                      0.12%
                      <IconAngleUp />
                    </span>
                  </NavItem>
                  <NavItem>
                    <label htmlFor="">IDX80</label>
                    134.825
                    <span className="text-success">
                      {' '}
                      0.12%
                      <IconAngleUp />
                    </span>
                  </NavItem>
                  <NavItem>
                    <label htmlFor="">Composite</label>
                    6,638.019
                    <span className="text-danger">
                      {' '}
                      0.33% <IconAngleDown />
                    </span>
                  </NavItem>
                </Nav>
              </div>
            </div>
          </div> */}
          {/* {window.location.host?.includes('localhost') && (
            <div className="mb-3">
              <Dropdown
                className="add-dropdown"
                isOpen={dropdownOpen}
                toggle={toggleDropDown}
              >
                <DropdownToggle caret direction="down" className="btn">
                  <IconPlus /> Add
                </DropdownToggle>
                <DropdownMenu>
                  {widgets.map(widget => {
                    return (
                      <DropdownItem
                        onClick={() => {
                          setWidgetToShow(toShow => {
                            if (toShow === null) {
                              return [widget];
                            }
                            return [...toShow, widget];
                          });
                          
                        }}
                      >
                        {widget.label}
                      </DropdownItem>
                    );
                  })}
                </DropdownMenu>
              </Dropdown>
            </div>
          )} */}
          <div className="main-widget" id={'main-widget'}>
            <ResponsiveWidth
              breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
              cols={{ lg: 12, md: 10, sm: 6, xs: 2, xxs: 1 }}
              className="text-white res-view"
              layouts={layouts}
              // margin="[10, 10]"
              draggableCancel=".no-drag"
              isDraggable={
                widgetFullScreen
                  ? false
                  : window.innerWidth > 767
                    ? true
                    : false
              }
              onResizeStop={() => {
                if (
                  widRank &&
                  widRank.widgetRank &&
                  widRank?.widgetRank?.slots?.length > 0
                ) {
                  callTelemetryRecord(
                    'widgets',
                    widRank?.widgetRank?.event_id,
                    widRank?.widgetRank?.slots[0]?.id,
                    ['resize'],
                  );
                }
              }}
            >
              {widgetToShow?.map((widget, index) => {
                let grid = dimensions[0];

                if (index % 2 === 1) {
                  grid = {
                    i: `${index}`,
                    x: 0,
                    y: grid.h * jumpBy[index],
                    w: 6,
                    h: 4,
                    minW: 2.5,
                    minH: 2,
                    maxW: 7,
                    maxH: 5,
                  };
                }

                if (index % 2 === 1) {
                  grid = {
                    i: `${index}`,
                    x: 6,
                    y: grid.h * jumpBy[index],
                    w: 6,
                    h: 4,
                    minW: 2.5,
                    minH: 2,
                    maxW: 7,
                    maxH: 5,
                  };
                }

                return (
                  <div key={index} data-grid={grid}>
                    {widget.component(index)}
                  </div>
                );
              })}
            </ResponsiveWidth>
          </div>
        </div>
      </div>
    </>
  );
};

export default Newsfeed;
