/* ACTIONS */
import { marketplaceConstant } from '../constant';

const {
  ADD_NEWS_SOURCE_COUNT,
  ADD_DATA_SOURCE_COUNT,
  ADD_AUGUMENTATION_DATA_SOURCE_COUNT,
  ADD_FOUNDATIONAL_MODEL_COUNT,
  IS_SAVE_NEWS_SOURCE,
  IS_SAVE_DATA_SOURCE,
  IS_SAVE_AUGUMENTATION_SOURCE,
  MARKET_PLACE_TYPE,
} = marketplaceConstant;

export const addNewsSourceCount = (data: string) => {
  return {
    type: ADD_NEWS_SOURCE_COUNT,
    payload: {
      newsSourceCount: data,
    },
  };
};

export const addDataSourceCount = (data: string) => {
  return {
    type: ADD_DATA_SOURCE_COUNT,
    payload: {
      dataSourceCount: data,
    },
  };
};

export const addAugumentationSourceCount = (data: string) => {
  return {
    type: ADD_AUGUMENTATION_DATA_SOURCE_COUNT,
    payload: {
      augumentationSourceCount: data,
    },
  };
};

export const addFoundationalModelCount = (data: number) => {
  return {
    type: ADD_FOUNDATIONAL_MODEL_COUNT,
    payload: {
      foundationalModelCount: data,
    },
  };
};

export const saveNewsSource = (data: boolean) => {
  return {
    type: IS_SAVE_NEWS_SOURCE,
    payload: {
      isSaveNewsSource: data,
    },
  };
};

export const saveDataSource = (data: boolean) => {
  return {
    type: IS_SAVE_DATA_SOURCE,
    payload: {
      isSaveDataSource: data,
    },
  };
};

export const saveAugumentationSource = (data: boolean) => {
  return {
    type: IS_SAVE_AUGUMENTATION_SOURCE,
    payload: {
      isSaveAugumentationSource: data,
    },
  };
};

export const addMarketplaceType = (data: { label: string; value: string }) => {
  return {
    type: MARKET_PLACE_TYPE,
    payload: {
      marketplaceType: data,
    },
  };
};
