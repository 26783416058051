import React, { useEffect, useState } from 'react';
import axios from 'axios';
import config from '../../../../../../config';
import useAction from '../../../../../components/hooks/useAction';
import {
  IconMinusCircle,
  IconPlusCircle,
} from '../../../../../components/public/Icons';
import loaderGIF from '../../../../../../assets/images/building-data-loader.gif';

type AnalysisResultPropsTypes = {
  inputData: DataStructure;
  setInputData: React.Dispatch<React.SetStateAction<DataStructure>>;
  selectedPortfolioStat: string[];

  statQuery: string;
};

// interface FieldData {
//   type: string;
//   actualFieldName: string;
//   alias_suggestion_required: boolean;
//   hidden?: boolean;
// }

type StatsObject = {
  average: number;
  max: number;
  min: number;
  sum: number;
  total_fields: number;
};

// Define the type for the object in the key1 array
interface MinMax {
  min: number;
  max: number;
}

type DataStructure = {
  [key: string]: MinMax[]; // Keys are unknown (e.g., key1, key2, key3), each with an array of MinMax objects
};

type DynamicStats = {
  [key: string]: StatsObject;
};

type AliasNameObjTypes = {
  [key: string]: string;
};

const AnalysisResult = ({
  inputData,
  setInputData,
  selectedPortfolioStat,
  statQuery,
}: AnalysisResultPropsTypes) => {
  const { showModal } = useAction();
  const [isLoading, setIsLoading] = useState(true);
  const [statPortfolioData, setStatPortfoliosData] = useState<DynamicStats>({});
  const [aliasNameObj, setAliasNameObj] = useState<AliasNameObjTypes>({});

  // Function to handle adding a new row
  const handleAddRow = (key: string) => {
    setInputData(prevState => ({
      ...prevState,
      [key]: [...prevState[key], { min: 0, max: 0 }],
    }));
  };

  // Function to remove a row for a specific key and index
  const handleRemoveRow = (key: string, index: number) => {
    setInputData(prevState => ({
      ...prevState,
      [key]: prevState[key].filter((_, i) => i !== index),
    }));
  };

  // Function to handle input change for min/max values
  const handleInputChange = (
    key: string,
    index: number,
    field: keyof MinMax,
    value: number,
  ) => {
    const updatedKeyData = inputData[key].map((item, i) => {
      if (i === index) {
        return { ...item, [field]: value };
      }
      return item;
    });

    setInputData(prevState => ({
      ...prevState,
      [key]: updatedKeyData,
    }));
  };

  const getHeaders = async () => {
    setIsLoading(true);
    try {
      const payload = {
        portfolio_ids: selectedPortfolioStat,
      };
      const response = await axios({
        method: 'post',
        url: `${config.ER_ML_URL}/statistical/get-headers`,
        data: payload,
      });

      let rangeResponse: DataStructure = Object.keys(
        response?.data?.data || {},
      ).reduce(
        (acc, key) => {
          acc[key] = [{ min: 0, max: 0 }]; // Populate with MinMax objects
          return acc;
        },
        {} as DataStructure, // Type assertion for the accumulator
      );
      setIsLoading(false);

      setAliasNameObj(response?.data?.alias_names);
      setInputData(rangeResponse);
      setStatPortfoliosData(response?.data?.data);
    } catch (error) {
      setIsLoading(false);
      showModal('something went wrong');
    }
  };

  useEffect(() => {
    if (statQuery !== '') {
      getHeaders();
    }
  }, [selectedPortfolioStat?.length]);
  return (
    <>
      <div className="addwidget-content mw-100 ">
        {isLoading ? (
          <div className="loader flexbox-align-c">
            <img src={loaderGIF} alt="" />
          </div>
        ) : (
          <div className="main-widget pe-0 widget-clear">
            <div className="react-grid-item">
              <div className="common-widget">
                <div className="table-responsive tablescroll">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th scope="col">Data Header Name</th>
                        <th scope="col">Data Min-Max</th>
                        <th style={{ width: '180px' }} scope="col">
                          Header Alias
                        </th>
                        <th style={{ width: '170px' }} scope="col">
                          Min Value to Track
                        </th>
                        <th style={{ width: '170px' }} scope="col">
                          Max Value to Track
                        </th>
                        <th style={{ width: '48px' }} scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.entries(statPortfolioData).map(
                        ([key, values]) => (
                          <>
                            <tr
                              key={`${key}-${values.min}-${values.max}`}
                              // className={index === 1 ? 'text-primary' : ''}
                            >
                              <td>{key}</td>
                              <td>
                                ${values.min} - ${values.max}
                              </td>
                              <td>
                                <input
                                  type="text"
                                  placeholder="Click to name"
                                  className="form-control"
                                  value={aliasNameObj[key]}
                                  // onChange={e => {
                                  //   handleInputChange(index, e.target.value);
                                  // }}
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  placeholder="Enter min value"
                                  className="form-control"
                                  value={inputData?.[key]?.[0]?.min}
                                  onChange={e =>
                                    handleInputChange(
                                      key,
                                      0,
                                      'min',
                                      Number(e.target.value),
                                    )
                                  }
                                />
                              </td>

                              <td>
                                <input
                                  type="text"
                                  placeholder="Enter max value"
                                  className="form-control"
                                  value={inputData?.[key]?.[0]?.max}
                                  onChange={e =>
                                    handleInputChange(
                                      key,
                                      0,
                                      'max',
                                      Number(e.target.value),
                                    )
                                  }
                                />
                              </td>

                              <td>
                                <button
                                  className="btn btn-clear btn-icon btn-16"
                                  onClick={() => handleAddRow(key)}
                                >
                                  <IconPlusCircle width={24} height={24} />
                                </button>
                              </td>
                            </tr>

                            {inputData?.[key]?.length > 1 &&
                              inputData?.[key]?.slice(1)?.map((val, index) => (
                                <tr>
                                  <td
                                    colSpan={4}
                                    className="text-end"
                                    key={`${index + 1}-${val?.min}-${val?.max}`}
                                  >
                                    <div className="form-icon form-icon-end">
                                      {/* <button className="btn btn-clear input-icon">
                                    <IconCross
                                      width={16}
                                      height={16}
                                      fill="#36567D"
                                    />
                                  </button> */}
                                      <input
                                        type="text"
                                        placeholder="Enter min value"
                                        className="form-control ms-auto"
                                        style={{ width: '145px' }}
                                        value={val?.min}
                                        onChange={e =>
                                          handleInputChange(
                                            key,
                                            index + 1,
                                            'min',
                                            Number(e.target.value),
                                          )
                                        }
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    <div className="form-icon form-icon-end">
                                      {/* <button className="btn btn-clear input-icon">
                                    <IconCross
                                      width={16}
                                      height={16}
                                      fill="#36567D"
                                    />
                                  </button> */}
                                      <input
                                        type="text"
                                        placeholder="Enter max value"
                                        className="form-control"
                                        value={val?.max}
                                        onChange={e =>
                                          handleInputChange(
                                            key,
                                            index + 1,
                                            'max',
                                            Number(e.target.value),
                                          )
                                        }
                                      />
                                    </div>
                                  </td>

                                  <td>
                                    <button
                                      className="btn btn-clear btn-icon btn-16 btn-h-danger"
                                      onClick={() =>
                                        handleRemoveRow(key, index + 1)
                                      }
                                    >
                                      <IconMinusCircle
                                        width={24}
                                        height={24}
                                        fill="#ff9d0b"
                                      />
                                    </button>
                                  </td>
                                </tr>
                              ))}
                          </>
                        ),
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default AnalysisResult;
