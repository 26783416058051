import { portfolioConstant } from '../constant';

const {
  ADD_NPL_PORTFOLIO_ID,
  REMOVE_NPL_PORTFOLIO_ID,
  ADD_LOAN_PORTFOLIO_ID,
  REMOVE_LOAN_PORTFOLIO_ID,
  ADD_RECEIVABLE_PORTFOLIO_ID,
  REMOVE_RECEIVABLE_PORTFOLIO_ID,
} = portfolioConstant;

const initialState = {
  nplPortfolioId: '',
  loanPortfolioId: '',
  receivablePortfolioId: '',
};

const PortfolioReducer = (state = initialState, action: any): any => {
  switch (action.type) {
    case ADD_NPL_PORTFOLIO_ID:
      return {
        ...state,
        nplPortfolioId: action.payload.nplPortfolioId,
      };

    case ADD_LOAN_PORTFOLIO_ID:
      return {
        ...state,
        loanPortfolioId: action.payload.loanPortfolioId,
      };

    case ADD_RECEIVABLE_PORTFOLIO_ID:
      return {
        ...state,
        receivablePortfolioId: action.payload.receivablePortfolioId,
      };

    case REMOVE_NPL_PORTFOLIO_ID:
      return {
        ...state,
        nplPortfolioId: '',
      };

    case REMOVE_LOAN_PORTFOLIO_ID:
      return {
        ...state,
        loanPortfolioId: '',
      };

    case REMOVE_RECEIVABLE_PORTFOLIO_ID:
      return {
        ...state,
        receivablePortfolioId: '',
      };

    default:
      return state;
  }
};

export default PortfolioReducer;
