/* eslint-disable */
import { useEffect, useState } from 'react';
//import { useLocation } from 'react-router';
//import queryString from 'query-string';
import queryString from 'query-string';
//import { useSelector } from "react-redux";
//import { userData } from "../../../redux/selector/UserSelector";
// import {
//   Dropdown,
//   DropdownItem,
//   DropdownMenu,
//   DropdownToggle,
// } from 'reactstrap';
// import { IconPlus } from '../../../components/public/Icons';
import PublicHeader from '../../../components/public/PublicHeader';
// import PublicPageHeader from '../../../components/public/PublicPageHeader';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import { callTelemetryRecord } from '../../../helpers/utils.helper';
import { allDashboardWidgetsData } from '../../../redux/selector/DashboardWidgetSelector';
import { widgetRankData } from '../../../redux/selector/WidgetRankSelector';
import MoodyNewsfeedsWidget from '../newsfeed/widgets/MoodyNewsfeedsWidget';
import NPLWidget from '../portfolio/widgets/npl_widgets/NPLWidget';
import NewsfeedsWidget from '../newsfeed/widgets/NewsfeedsWidget';
import TasksWidget from './widgets/TasksWidget';
import MyPortfolioPerformanceWidget from './widgets/MyPortfolioPerformanceWidget';
import AuthService from '../../../services/auth.service';
import config from '../../../../config';
import { getToken } from '@firebase/messaging';
import { messaging } from '../../../../firebase';
import ChartsWrapper from './addDashboardWidget/portfolioChartsWidget/Charts/components/ChartsWrapper';
import useAction from '../../../components/hooks/useAction';
import { userData } from '../../../redux/selector/UserSelector';
// import TransactionReports from './unused/TransactionReports';
// import { setWith } from 'lodash';

const jumpBy = [0, 0, 1, 1, 2, 2, 3, 3, 4, 4, 5, 5, 6, 6, 7, 7, 8, 8];

type _widgets =
  | 'news'
  | 'task'
  | 'performance'
  | 'npl'
  | 'transactionreports'
  | 'embedded_browser'
  | 'moody_news';

const ResponsiveWidth = WidthProvider(Responsive);
const layouts = {
  md: [
    { i: 'a', x: 0, y: 0, w: 6, h: 4 },
    { i: 'b', x: 6, y: 0, w: 6, h: 4 },
    { i: 'c', x: 4, y: 6, w: 6, h: 4 },
    { i: 'd', x: 4, y: 6, w: 6, h: 4 },
    { i: 'e', x: 4, y: 6, w: 6, h: 4 },
    { i: 'f', x: 4, y: 6, w: 6, h: 4 },
    { i: 'g', x: 4, y: 6, w: 6, h: 4 },
  ],
};

const Dashboard = () => {
  // const [dropdownOpen, setDropDownOpen] = useState(false);
  const location = useLocation();
  //const [portfolioId, setPortfolioId] = useState(null as any);
  const user = useSelector(userData);
  const navigate = useNavigate();
  const widRank = useSelector(widgetRankData);

  const allDashboardWidgetReduxData = useSelector(allDashboardWidgetsData);
  const { showModal } = useAction();

  const { search } = useLocation();
  const [widgetFullScreen, setWidgetFullScreen] = useState(false);

  useEffect(() => {
    let { token } = queryString.parse(search);

    if (token) {
      localStorage.setItem('_ERFTKEN', token as string);
    }

    if (user?.userDetail?.license?.licenseType === 'BASIC') {
      navigate('/newsfeed');
    }
  }, [search]);

  // const toggleDropDown = () => {
  //   setDropDownOpen(state => !state);
  // };
  // const toggleDropDown = () => {
  //   setDropDownOpen(state => !state);
  // };

  //const user = useSelector(userData);
  // const [layout, setLayout] = useState(layoutSchema);
  //const [layout] = useState(layoutSchema);

  const [widgetToShow, setWidgetToShow] = useState<
    { label: string; value: _widgets; component: any }[] | null
  >(null);
  //const { search } = useLocation();

  /*const portfolioChange = (id: any) => {
    setPortfolioId(id);
  };*/

  const closeHandler = (widgetName: _widgets, index: number) => {
    setWidgetToShow((toShow) => {
      if (toShow === null) {
        return null;
      }

      return toShow.filter((wid, widIndex) => widIndex !== index);
    });

    /*setWidgets(oldWidget => {
      let widgetToAdd = _widgetInitials.find(
        widget => widget.value === widgetName,
      );
      if (widgetToAdd) {
        return [...oldWidget, widgetToAdd];
      }
      return [...oldWidget];
    });*/
  };

  const _widgetInitials: {
    label: string;
    value: _widgets;
    component: any;
  }[] = [
    {
      label: 'News Feed Widget',
      value: 'news',
      component: (index: any) => (
        <NewsfeedsWidget
          onClose={(val: _widgets) => closeHandler('news', index)}
          newsWidgetIndex={index}
          type="DASHBOARD"
          widgetFullScreen={widgetFullScreen}
          setWidgetFullScreen={setWidgetFullScreen}
        />
      ),
    },

    {
      label: 'Tasks Widget',
      value: 'task',
      component: (index: any) => (
        <TasksWidget
          onClose={(val: _widgets) => closeHandler('task', index)}
          tasksWidgetIndex={index}
          type="DASHBOARD"
          widgetFullScreen={widgetFullScreen}
          setWidgetFullScreen={setWidgetFullScreen}
        />
      ),
    },

    {
      label: 'My Portfolio Performance Widget',
      value: 'performance',
      component: (index: any) => (
        <MyPortfolioPerformanceWidget
          onClose={(val: _widgets) => closeHandler('performance', index)}
          performanceIndex={index}
          type="DASHBOARD"
          widgetFullScreen={widgetFullScreen}
          setWidgetFullScreen={setWidgetFullScreen}
        />
      ),
    },

    {
      label: 'My Portfolio Charts Widget',
      value: 'embedded_browser',
      component: (index: number) => {
        return (
          <ChartsWrapper
            onClose={(val: _widgets) => closeHandler('embedded_browser', index)}
            chartsWidgetIndex={index}
            type="DASHBOARD"
            widgetFullScreen={widgetFullScreen}
            setWidgetFullScreen={setWidgetFullScreen}
          />
        );
      },
    },
  ];

  useEffect(() => {
    if (allDashboardWidgetReduxData?.length) {
      let newArray = [] as any;

      allDashboardWidgetReduxData.forEach((y: any, widReduxIndex: number) => {
        y.id = widReduxIndex;

        _widgetInitials.forEach((x) => {
          if (y.value === x.value) {
            newArray.push(x);
          }
        });
      });

      setWidgetToShow(newArray);
    }
  }, [allDashboardWidgetReduxData?.length, widgetFullScreen]);

  const [widgets, setWidgets] = useState<
    {
      label: string;
      value: _widgets;
      component: any;
    }[]
  >(_widgetInitials);

  useEffect(() => {
    if (!allDashboardWidgetReduxData.length) {
      if (
        widRank &&
        widRank.widgetRank &&
        widRank?.widgetRank?.slots?.length > 0
      ) {
        let widToShow =
          widRank?.widgetRank?.slots[0]?.widget_group?.features?.filter(
            (x: any) =>
              x.platform_sections.includes(location.pathname.split('/')[1]),
          );

        const newArr = [] as any;
        let widArry = [] as any;

        _widgetInitials.forEach((x: any) => {
          widToShow.forEach((y: any) => {
            if (x.value === y.name) {
              newArr.push(x);
              return;
            } else {
              if (!newArr.includes(x)) {
                widArry.push(x);
              }
            }
          });
        });

        widArry = widArry
          .filter(
            (value: any, index: any, self: any) =>
              index === self.findIndex((t: any) => t.value === value.value),
          )
          .filter((val: any) => !newArr.includes(val));

        if (newArr?.length === 0) {
          widArry = [..._widgetInitials];
        }
        setWidgetToShow(newArr);

        //setWidgets(widArry);
        setWidgets([..._widgetInitials]);
      }
    }
  }, [widRank]);

  const [dimensions] = useState<
    {
      i: string;
      x: number;
      y: number;
      w: number;
      h: number;
      minW: number;
      maxW: number;
      minH: number;
      maxH: number;
    }[]
  >([{ i: '0', x: 0, y: 0, w: 6, h: 4, minW: 2.5, minH: 2, maxW: 7, maxH: 5 }]);

  /* useEffect(() => {
    let { token } = queryString.parse(search);

    if (!localStorage.getItem('_ERFTKEN')) {
      localStorage.setItem('_ERFTKEN', token as string);
    }
  }, [search]); */
  const [tableWidth, setTableWidth] = useState<number>(1920);
  useEffect(() => {
    const tabWidth = document.getElementById('main-widget')!;

    let resizeObserver = new ResizeObserver(() => {
      setTableWidth(tabWidth.offsetWidth);
    });

    resizeObserver.observe(tabWidth);
  }, []);

  const authService = new AuthService();

  useEffect(() => {
    const checkAndGenerateToken = async () => {
      // Check if deviceIdER is already stored in localStorage
      const storedDeviceID = localStorage.getItem('deviceIdER');
      if (!storedDeviceID) {
        try {
          // Check if the notification permission is granted
          // const permission = await Notification.requestPermission();
          // if (permission === 'granted') {
          // Generate FCM token
          const token = await getToken(messaging, {
            vapidKey: config.PUBLIC_VAPID_KEY,
          });

          const userAgent = window.navigator.userAgent;
          const platform = window.navigator.platform;
          const randomString =
            Math.random().toString(20).substring(2, 14) +
            Math.random().toString(20).substring(2, 14);

          // Generate device ID if not already available
          const deviceID = `${userAgent}-${platform}-${randomString}`;

          const payload = {
            fcmToken: token,
            deviceId: deviceID,
            source: 'WEB',
          };

          // Store device ID in localStorage
          localStorage.setItem('deviceIdER', deviceID);
          localStorage.setItem('hasRequestedNotificationPermission', 'true');

          // Call FCM token API
          await authService.callFcm(payload);
        } catch (e) {
          // showModal('something went wrong');
        }
      }
    };

    checkAndGenerateToken();
  }, []); // Empty dependency array ensures this runs only once on component mount

  return (
    <>
      {/* <Sidebar /> */}
      <div className="page-content ">
        <PublicHeader />

        {/* <PublicPageHeader /> */}
        <div className="page-middle-content">
          <div className="main-widget" id={'main-widget'}>
            <ResponsiveWidth
              className="text-white res-view"
              // width={tableWidth}
              breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
              cols={{ lg: 12, md: 10, sm: 6, xs: 2, xxs: 1 }}
              layouts={layouts}
              draggableCancel=".no-drag"
              isDraggable={
                widgetFullScreen
                  ? false
                  : window.innerWidth > 767
                    ? true
                    : false
              }
              onResizeStop={() => {
                if (
                  widRank &&
                  widRank.widgetRank &&
                  widRank?.widgetRank?.slots?.length > 0
                ) {
                  callTelemetryRecord(
                    'widgets',
                    widRank?.widgetRank?.event_id,
                    widRank?.widgetRank?.slots[0]?.id,
                    ['resize'],
                  );
                }
              }}
            >
              {/* <div key="1">a</div>
              <div key="2">b</div>
              <div key="3">c</div> */}
              {widgetToShow?.map((widget, index) => {
                let grid = dimensions[0];

                if (index % 2 === 1) {
                  grid = {
                    i: `${index}`,
                    x: 0,
                    y: grid.h * jumpBy[index],
                    w: 6,
                    h: 4,
                    minW: 2.5,
                    minH: 2,
                    maxW: 7,
                    maxH: 5,
                  };
                }

                if (index % 2 === 1) {
                  grid = {
                    i: `${index}`,
                    x: 6,
                    y: grid.h * jumpBy[index],
                    w: 6,
                    h: 4,
                    minW: 2.5,
                    minH: 2,
                    maxW: 7,
                    maxH: 5,
                  };
                }

                return (
                  <div key={index} data-grid={grid}>
                    {widget.component(index)}
                  </div>
                );
              })}
            </ResponsiveWidth>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
