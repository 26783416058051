import IngestionService from '../../services/ingestion.service';
import useAction from '../hooks/useAction';

const ingService = new IngestionService();
const renderMessage = (
  message: string | { columns: string[]; rows: any[][] | any[] } | undefined,
  id: any,
) => {
  const { showModal } = useAction();
  if (!message) return null;

  const extractJson = (text: string) => {
    const jsonStart = text.indexOf('```json');
    const jsonEnd = text.lastIndexOf('```');

    if (jsonStart !== -1 && jsonEnd !== -1) {
      const jsonSubstring = text.substring(jsonStart + 7, jsonEnd).trim();
      try {
        return JSON.parse(jsonSubstring);
      } catch (error) {
        // console.error('Failed to parse JSON:', error);
        return null;
      }
    }
    return null;
  };

  const jsonData = typeof message === 'string' ? extractJson(message) : null;
  const textWithoutJson =
    typeof message === 'string'
      ? message.replace(/```json[\s\S]*?```/, '').trim()
      : '';

  const parseAndRenderText = (text: string) => {
    const segments = text.split(/(\*\*.*?\*\*)/);
    return segments.map((segment, idx) => {
      if (segment.startsWith('**') && segment.endsWith('**')) {
        const boldText = segment.slice(2, -2);
        return (
          <strong key={idx} style={{ fontWeight: 'bold' }}>
            {boldText}
          </strong>
        );
      }
      return <span key={idx}>{segment}</span>;
    });
  };

  const renderValue = (value: any) => {
    if (value === null || value === undefined) return '';
    if (typeof value === 'object') {
      return (
        <pre style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
          {JSON.stringify(value, null, 2)}
        </pre>
      );
    }
    return value;
  };

  const renderArrayAsTable = (arrayData: any[]) => {
    if (arrayData.length === 0) return <p>No data available.</p>;
    const keys = Object.keys(arrayData[0]);

    const rowsToShow =
      arrayData.length > 15
        ? [...arrayData.slice(0, 5), ...arrayData.slice(-5)]
        : arrayData;

    return (
      <table
        style={{
          border: '1px solid #36567d',
          borderCollapse: 'collapse',
          width: '100%',
          margin: '1rem 0',
          tableLayout: 'auto',
        }}
      >
        <thead>
          <tr>
            {keys.map((key) => (
              <th
                key={key}
                style={{
                  padding: '0.5rem',
                  textAlign: 'left',
                  border: '1px solid #36567d',
                  whiteSpace: 'nowrap',
                  maxWidth: '150px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {key}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rowsToShow.map((entry, rowIndex) => (
            <tr key={rowIndex}>
              {keys.map((key) => (
                <td
                  key={key}
                  style={{
                    padding: '0.5rem',
                    border: '1px solid #36567d',
                    whiteSpace: 'nowrap',
                    maxWidth: '150px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {renderValue(entry[key])}
                </td>
              ))}
            </tr>
          ))}
          {arrayData.length > 15 && (
            <tr>
              <td
                colSpan={keys.length}
                style={{
                  textAlign: 'center',
                  fontStyle: 'italic',
                  padding: '0.5rem',
                }}
              >
                ... Showing first 5 and last 5 rows of {arrayData.length} total
                rows ...
              </td>
            </tr>
          )}
        </tbody>
      </table>
    );
  };

  const renderTableFromStructuredMessage = (
    data: { columns: string[]; rows: any[][] }[],
  ) => {
    return data.map((tableData, tableIndex) => {
      const { columns, rows } = tableData;

      if (
        !columns ||
        !rows ||
        !Array.isArray(columns) ||
        !Array.isArray(rows)
      ) {
        // console.error(`Invalid table structure at index ${tableIndex}`);
        return null;
      }

      return (
        <div key={tableIndex} style={{ marginBottom: '2rem' }}>
          <h4>Table {tableIndex + 1}</h4>
          <table
            style={{
              border: '1px solid #36567d',
              borderCollapse: 'collapse',
              width: '100%',
              margin: '1rem 0',
              tableLayout: 'auto',
            }}
          >
            <thead>
              <tr>
                {columns.map((column, columnIndex) => (
                  <th
                    key={columnIndex}
                    style={{
                      padding: '0.5rem',
                      textAlign: 'left',
                      border: '1px solid #36567d',
                      whiteSpace: 'nowrap',
                      maxWidth: '150px',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {column}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {rows.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {row.map((cell, cellIndex) => (
                    <td
                      key={cellIndex}
                      style={{
                        padding: '0.5rem',
                        border: '1px solid #36567d',
                        whiteSpace: 'nowrap',
                        maxWidth: '150px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {cell || ''}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
          {/* Add Save to Account button */}
          <button
            onClick={() => handleTableIconClick(tableIndex, id)}
            style={{
              padding: '0.5rem 1rem',
              backgroundColor: '#36567d',
              color: '#fff',
              border: 'none',
              cursor: 'pointer',
              borderRadius: '4px',
              //   marginTop: '1rem',
            }}
          >
            Save to Account
          </button>
        </div>
      );
    });
  };

  const handleTableIconClick = async (tableIndex: number, tableId: any) => {
    const payload = {
      tableIndex: tableIndex,
      chatId: tableId,
    };
    try {
      await ingService.saveTableFromPDF(payload);
      showModal('Table Normalized and Ready to use!', true);
    } catch (error) {
      showModal('Failed to save table', false);
    }
  };

  if (
    typeof message === 'object' &&
    message !== null &&
    'columns' in message &&
    'rows' in message
  ) {
    // Handle the new structured message format with 'columns' and 'rows'
    return renderTableFromStructuredMessage([message]);
  }

  return (
    <>
      {textWithoutJson.split('\n').map((line, idx) => (
        <p key={idx}>{parseAndRenderText(line)}</p>
      ))}

      {Array.isArray(jsonData) && renderArrayAsTable(jsonData)}

      {jsonData && !Array.isArray(jsonData) && (
        <div>
          <h4>Raw JSON</h4>
          <pre style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
            {JSON.stringify(jsonData, null, 2)}
          </pre>
        </div>
      )}
    </>
  );
};

export default renderMessage;
