import { useSelector } from 'react-redux';
import AIassistantGIF from '../../../../../../assets/images/ai-assistant.gif';
import {
  IconDropdownArrow,
  IconThumbsDown,
  IconThumbsUp,
} from '../../../../../components/public/Icons';
import axios from 'axios';
import config from '../../../../../../config';
import React, { useEffect, useState, JSX } from 'react';
import useAction from '../../../../../components/hooks/useAction';
import { userData } from '../../../../../redux/selector/UserSelector';
import loaderGIF from '../../../../../../assets/images/building-data-loader.gif';
import CustomSelect from '../../../../../components/ui/CustomSelect';
import { userDetailsData } from '../../../../../redux/selector/UserDetailsSelector';

type TypeChatPropsTypes = {
  selectedPortfolioStat: string[];
  setIsStatValueCalculated: React.Dispatch<React.SetStateAction<boolean>>;
  statQuery: string;
  setStatQuery: React.Dispatch<React.SetStateAction<string>>;
  statSelectedModel: {
    label: JSX.Element;
    value: string;
  } | null;
  setStatSelectedModel: React.Dispatch<
    React.SetStateAction<{
      label: JSX.Element;
      value: string;
    } | null>
  >;
  isStatLoading: boolean;
  setIsStatLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setNewFieldsArray: React.Dispatch<React.SetStateAction<string[]>>;
};

type ModelListTypes = {
  _id: string;
  model_name: string;
  display_name: string;
};

const TypeChat = ({
  selectedPortfolioStat,
  statQuery,
  setStatQuery,
  statSelectedModel,
  setStatSelectedModel,
  isStatLoading,
  setIsStatLoading,
  setNewFieldsArray,
  setIsStatValueCalculated,
}: TypeChatPropsTypes) => {
  const { showModal } = useAction();
  const user = useSelector(userData);

  const [modelList, setModelList] = useState<
    Array<{
      label: JSX.Element;
      value: string;
    }>
  >([]);

  const userDetails = useSelector(userDetailsData);
  const getFoundationalModelList = async () => {
    try {
      const response = await axios({
        method: 'get',
        url: `${config.ER_ML_URL}/llm/list`,
      });
      const responseManipulation: ModelListTypes[] = response?.data?.data || [];
      if (responseManipulation?.length > 0) {
        let updatedRes = responseManipulation?.map(x => {
          return {
            label: (
              <div className="select-img">
                {/* <img
                src={ImgOrca2}
                height="16"
                width="16"
                className="img-cover"
              /> */}
                <span>{x.display_name}</span>
              </div>
            ),
            value: x?.display_name,
          };
        });

        setStatSelectedModel(updatedRes[0]);
        if (updatedRes?.length <= 0) {
          showModal('Foundation models not available!');
        }
        setModelList(updatedRes);
      }
    } catch (error) {
      showModal('something went wrong');
    }
  };

  const getCalculationApi = async () => {
    setIsStatLoading(true);
    const payload = {
      portfolio_ids: selectedPortfolioStat,
      user_query: statQuery,
      model: statSelectedModel?.value,
    };
    await axios({
      method: 'post',
      url: `${config.ER_ML_URL}/statistical/add-function`,
      data: payload,
    })
      .then(({ data }) => {
        showModal(data?.message, true);
        setNewFieldsArray(data?.new_fields);
        setIsStatValueCalculated(true);
        setIsStatLoading(false);
      })
      .catch(err => {
        setIsStatLoading(false);
        showModal(err?.response?.data?.error);
      });
  };

  useEffect(() => {
    if (userDetails?._id) getFoundationalModelList();
  }, [userDetails?._id]);

  return (
    <>
      <div className="addwidget-content mw-100">
        {isStatLoading ? (
          <div className="loader flexbox-align-c">
            <img src={loaderGIF} alt="" />
          </div>
        ) : (
          <div className="addwidget-c-item">
            <div className="row justify-content-center">
              <div className="col-12 col-lg-6">
                <div className="create-widget create-widget-portfolio">
                  <div className="widget-item">
                    <div className="d-flex gap-1 form-group px-lg-4 thumbs-hover-show">
                      <div className="ai-ass-gif">
                        <img
                          src={AIassistantGIF}
                          alt=""
                          className="img-ai-ass"
                        />
                      </div>
                      <div className="d-flex flex-column">
                        <p className="mb-2">
                          Hi {user?.name}!
                          <br /> What are you interested in tracking from the
                          loan portfolio you’ve selected?
                        </p>
                        <div className="flexbox-c gap-8 thumbs-btn">
                          <button className="btn btn-icon btn-20">
                            <IconThumbsUp />
                          </button>
                          <button className="btn btn-icon btn-20">
                            <IconThumbsDown />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <textarea
                        className="form-control"
                        placeholder="Explain the widget goal, geographical scope and time frame"
                        rows={10}
                        value={statQuery}
                        onChange={e => {
                          setStatQuery(e.target.value);
                        }}
                      />
                      {modelList?.length > 0 && (
                        <div className="mb-2 mt-1">
                          <div className="ai-tools">
                            <div className="select-clear justify-content-end">
                              <CustomSelect
                                options={modelList}
                                value={statSelectedModel}
                                isSearchable={false}
                                render
                                onChange={(e: {
                                  label: JSX.Element;
                                  value: string;
                                }) => {
                                  setStatSelectedModel(e);
                                }}
                              />
                              <div className="arrow-icon">
                                <IconDropdownArrow />
                              </div>
                              <button
                                type="button"
                                className="btn p-0 btn-link ms-10"
                                onClick={() => {
                                  getCalculationApi();
                                }}
                              >
                                Calculate
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <p className="text-center text-info mb-0">
                      *You can change this later by talking to the AI assistant
                      via chat
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default TypeChat;
