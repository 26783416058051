import Snackbar from '@mui/material/Snackbar';
// import { AlertProps } from '@material-ui/lab';
import { default as MuiAlert } from '@mui/material/Alert';
import React from 'react';
import { AlertPropsType, ModalPropsType } from '../../../@types/ModalType';

function Alert(props: any & AlertPropsType): any {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const ErrorSuccessModal = ({
  message,
  isShow,
  onToggle,
  success,
}: ModalPropsType) => (
  <>
    {' '}
    {!success ? (
      <Snackbar
        open={isShow}
        autoHideDuration={4000}
        onClose={onToggle}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <div>
          <Alert onClose={onToggle} severity="error">
            <span style={{ fontSize: '1.500rem' }}>
              <strong>Oops! </strong>
              {message || 'Something Went Wrong!'}
            </span>
          </Alert>
        </div>
      </Snackbar>
    ) : (
      <Snackbar
        open={isShow}
        autoHideDuration={4000}
        onClose={onToggle}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <div>
          <Alert onClose={onToggle} severity="success">
            <span style={{ fontSize: '1.500rem' }}>{message}</span>
          </Alert>
        </div>
      </Snackbar>
    )}
  </>
);

export default ErrorSuccessModal;
