/* eslint-disable no-unused-vars */
import { useSelector } from 'react-redux';
import { News } from '../../../../../@types/NewsWidgetType';
import moment from 'moment';
import { widgetRankData } from '../../../../redux/selector/WidgetRankSelector';
import { callTelemetryRecord } from '../../../../helpers/utils.helper';
import NewsImage from '../../../../../assets/images/dummy-news.jpg';

type LatestNewsCardViewProps = {
  newsTopData: {
    id: string | number;
    news: News;
  }[];
  handleNewsClick: (url: string) => void;
  newsWidgetIndex: number | string;
  title: string;
};

const DateWiseNewsCard = ({
  newsTopData,
  handleNewsClick,
  newsWidgetIndex,
  title,
}: LatestNewsCardViewProps) => {
  const widRank = useSelector(widgetRankData);

  return (
    <>
      <div className="news-widget-date">
        <h5 className="mb-0">{title}</h5>
      </div>
      <div
        className={`news-widget`}
        onClick={() => {
          if (
            widRank &&
            widRank.widgetRank &&
            widRank?.widgetRank?.slots?.length > 0
          ) {
            callTelemetryRecord(
              'widgets',
              widRank?.widgetRank?.event_id,
              widRank?.widgetRank?.slots[0]?.id,
              ['click'],
            );
          }
        }}
      >
        <div className={`row-g-10 row`}>
          {newsTopData?.length &&
            newsTopData?.map((news: any) => (
              <div
                key={news?.id}
                className={`newsfeed-item col-md-12 col-xl-6`}
              >
                <div
                  className="news-inner-item"
                  onClick={() => {
                    handleNewsClick(news?.url);
                  }}
                >
                  <div className="newsfeed-image">
                    <img
                      src={news?.thumbnail_url}
                      alt="news_image"
                      onError={e => {
                        e.currentTarget.src = NewsImage;
                      }}
                    />
                  </div>
                  <div className="newsfeed-description">
                    <div className="newsfeed-publisher">
                      <p
                        className="newsfeed-date"
                        dangerouslySetInnerHTML={{
                          __html: moment(news?.date_published).isValid()
                            ? moment(news?.date_published).format('MM/DD/YYYY')
                            : news?.date_published,
                        }}
                      ></p>
                    </div>
                    <h2
                      dangerouslySetInnerHTML={{
                        __html: news?.title,
                      }}
                    ></h2>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: news?.description,
                      }}
                    ></p>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
};
export default DateWiseNewsCard;
