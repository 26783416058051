import { Link } from 'react-router-dom';
import { IconSort } from '../../../../components/public/Icons';
import CustomSelect from '../../../../components/ui/CustomSelect';
import UsersActionDropdown from '../UsersActionDropdown';
import { UserType } from '../../../../../@types/SettingsType';
import { roleOptions } from '../../../../redux/constant/setting.constant';
import moment from 'moment';
import { TextConstants } from '../../../../helpers/constants';
import { userData } from '../../../../redux/selector/UserSelector';
import { useSelector } from 'react-redux';
import { LicenseData } from '../../../../../@types/LicenseType';

interface UserTableTypeProps {
  data: UserType[];
  // eslint-disable-next-line no-unused-vars
  setSelectedUserId: (val: string) => void;
  // eslint-disable-next-line no-unused-vars
  setSelectedOption: (val: string) => void;
  // eslint-disable-next-line no-unused-vars
  setSelectedLicenseId: (val: string) => void;
  // eslint-disable-next-line no-unused-vars
  onUserSelect: (user: UserType | null, type?: any) => void;
  allLicenses: LicenseData[] | null;
}

const UsersTable = ({
  data,
  setSelectedUserId,
  setSelectedOption,
  setSelectedLicenseId,
  onUserSelect,
  allLicenses,
}: UserTableTypeProps) => {
  const user = useSelector(userData);
  const toggleAction = (item: UserType, type: string) => {
    onUserSelect(item, type);
  };

  return (
    <>
      <div className="table-responsive">
        <table className="table table-bordered">
          <thead>
            <tr>
              <th scope="col">
                Full Name{' '}
                <span className="sortable asc">
                  <IconSort />
                </span>
              </th>
              <th scope="col">Last active</th>
              <th scope="col">Department</th>
              <th style={{ width: '130px' }} scope="col">
                Role
              </th>
              <th style={{ width: '130px' }} scope="col">
                Licence
              </th>
              {user?.userRole === TextConstants.OWNER && (
                <th style={{ width: '48px' }}></th>
              )}
            </tr>
          </thead>
          {data?.length > 0 && (
            <>
              <tbody>
                {data?.map((item, index) => {
                  return (
                    <>
                      <tr key={index}>
                        <td>
                          <Link
                            to={
                              user?.userRole === TextConstants.OWNER
                                ? `/users?userId=${item?.user_id}`
                                : ''
                            }
                            className="link"
                          >
                            {item?.firstName} {item?.middleName}{' '}
                            {item?.lastName}
                          </Link>
                          <p className="font-12 mb-0 text-white-4 mt2">
                            {item?.email}
                          </p>
                        </td>
                        <td
                          style={{
                            color:
                              item?.userDetail?.userStatus === 'PENDING'
                                ? '#ff9d0b'
                                : item?.userDetail?.userStatus === 'INACTIVE'
                                ? 'red'
                                : '',
                          }}
                        >
                          {item?.userDetail?.userStatus === 'PENDING'
                            ? 'Invite Pending'
                            : item?.userDetail?.userStatus === 'INACTIVE'
                            ? 'Suspended'
                            : item?.userDetail?.lastActive
                            ? moment(
                                Number(item?.userDetail?.lastActive),
                              ).fromNow()
                            : ''}
                        </td>
                        <td>{item?.userDetail?.department?.departmentName}</td>
                        <td>
                          <div className="select-clear">
                            <CustomSelect
                              isDisable={
                                user?.userRole === TextConstants.OWNER
                                  ? false
                                  : true
                              }
                              options={roleOptions}
                              placeholder={'Owner'}
                              isSearchable={false}
                              value={roleOptions.find(
                                option => option.value === item?.userRole,
                              )}
                              onChange={(e: any) => {
                                setSelectedOption(e.value);
                                setSelectedUserId(item?.user_id);
                              }}
                            />
                            <div className="arrow-icon">
                              <IconSort />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="select-clear">
                            <CustomSelect
                              options={
                                allLicenses &&
                                allLicenses?.map(user => {
                                  const formattedLicenseType =
                                    user?.licenseType
                                      ?.charAt(0)
                                      ?.toUpperCase() +
                                    user?.licenseType?.slice(1)?.toLowerCase();

                                  return {
                                    label:
                                      user.licenseType === 'BASIC'
                                        ? formattedLicenseType
                                        : `${formattedLicenseType} (${user?.availableLicenses} available)`,
                                    value: user.licenseId,
                                  };
                                })
                              }
                              isDisable={
                                user?.userRole === TextConstants.OWNER
                                  ? false
                                  : true
                              }
                              placeholder={''}
                              isSearchable={false}
                              value={
                                allLicenses &&
                                allLicenses
                                  .map(user => ({
                                    label:
                                      user.licenseType.charAt(0).toUpperCase() +
                                      user.licenseType.slice(1).toLowerCase(),
                                    value: user.licenseId,
                                  }))
                                  .find(
                                    option =>
                                      option.value ===
                                      item?.userDetail?.licenseId,
                                  )
                              }
                              onChange={(e: {
                                label: string;
                                value: string;
                              }) => {
                                setSelectedLicenseId(e?.value);
                                setSelectedUserId(item?.user_id);
                              }}
                            />
                            <div className="arrow-icon">
                              <IconSort />
                            </div>
                          </div>
                        </td>
                        {user?.userRole === TextConstants.OWNER && (
                          <td>
                            <UsersActionDropdown
                              data={item}
                              toggleSuspendUserModal={() => {
                                toggleAction(item, 'suspend');
                              }}
                              toggleActivateUserModal={() => {
                                toggleAction(item, 'active');
                              }}
                              toggleRemoveUserModal={() => {
                                toggleAction(item, 'remove');
                              }}
                              toggleResendUserModal={() => {
                                toggleAction(item, 'resend');
                              }}
                              toggleResetPasswordUserModal={() => {
                                toggleAction(item, 'reset');
                              }}
                            />
                          </td>
                        )}
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </>
          )}
        </table>
      </div>
    </>
  );
};

export default UsersTable;
