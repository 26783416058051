import React, { useEffect, useState } from 'react';
import { Collapse } from 'reactstrap';
import {
  IconMinusCircle,
  IconPlusCircle,
  IconStar,
} from '../../../../../../components/public/Icons';
import { CustomTooltip } from '../../../../../../components/ui/CustomTooltip';
import useAction from '../../../../../../components/hooks/useAction';
import MarketPlaceService from '../../../../../../services/marketplace.service';
import { useSelector } from 'react-redux';
import { dashboardNewsFeedWidgetsDataSelector } from '../../../../../../redux/selector/DashboardWidgetSelector';
import { NewsEndpointListTypes } from '../../../../../../../@types/NewsWidgetType';
import DataLoader from '../../../../../../components/loaders/DataLoader';

type EndpointListTypes = {
  _id: string;
  endpointName: string;
  endpointDescription: string;
  createdAt: Date;
  isServiceActive: boolean;
  isAmplifyData: boolean;
  id: string;
  source: {
    id: string;
    serviceName: string;
    serviceType: string;
    isPaidService: boolean;
  };
};

type EndpointCollapseTypes = {
  isUpdate?: boolean;
  sourceType?: string;
  sourceData: DataSourceListType;
  isDashboard?: boolean;
  isSingleCheck?: boolean;
  amplifyDataArr?: { fileName: string; id: string }[] | [];
  setAmplifyDataArr?: React.Dispatch<
    React.SetStateAction<
      | []
      | {
          fileName: string;
          id: string;
        }[]
    >
  >;

  nodeEndpointArr?: { fileName: string; id: string }[] | [];

  setNodeEndpointArr?: React.Dispatch<
    React.SetStateAction<
      | []
      | {
          fileName: string;
          id: string;
        }[]
    >
  >;
  selectedDataSourcesWidget?: selectedDataSourceType[];
  setSelectedDataSourcesWidget?: React.Dispatch<
    React.SetStateAction<selectedDataSourceType[]>
  >;
};

type selectedDataSourceType = {
  file: string;
  subFile: string;
  type: string;
};

type DataSourceListType = {
  _id: string;
  serviceName: string;
  serviceType: string;
  serviceUrl: string;
  isPaidService: boolean;
  id: string;
  endpointCount: number;
};

const marketplaceService = new MarketPlaceService();

const EndpointCollapse = ({
  isUpdate,
  sourceType,
  sourceData,
  isDashboard,
  isSingleCheck,
  amplifyDataArr,
  nodeEndpointArr,
  setAmplifyDataArr,
  setNodeEndpointArr,
  selectedDataSourcesWidget,
  setSelectedDataSourcesWidget,
}: EndpointCollapseTypes) => {
  const newsDashboardWidgetReduxData = useSelector(
    dashboardNewsFeedWidgetsDataSelector,
  );
  const {
    showModal,
    selectEndDashboardNewsPoint,
    removeSelectedEndDashboardNewsFeedData,
    selectEndNewsPoint,
  } = useAction();
  const [isCollapseOpen, setIsCollapseOpen] = useState(false);
  const toggleCollapse = () => setIsCollapseOpen(!isCollapseOpen);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDataSourceId, setSelectedDataSourceId] = useState('');
  const [dataSourceEndpoints, setDataSourceEndpoints] = useState<
    Array<EndpointListTypes>
  >([]);

  const getDataSourceEndpoints = (id: string) => {
    setIsLoading(true);

    const payload = {
      sourceId: id,
      q: '',
      isServiceActiveRequired: true,
    };
    marketplaceService
      .getAllServicesOfSources(payload)
      .then(({ data }) => {
        setDataSourceEndpoints(data);
        setIsLoading(false);
      })
      .catch(err => {
        setIsLoading(false);
        showModal(err?.response?.data?.error);
      });
  };

  useEffect(() => {
    if (selectedDataSourceId) {
      getDataSourceEndpoints(selectedDataSourceId);
    }
  }, [selectedDataSourceId]);

  const handleEndpointSelection = (endpoint: EndpointListTypes) => {
    const modifiedEndpoint = {
      ...endpoint,
      sourceId: sourceData?.id,
      sourceName: sourceData?.serviceName,
    };

    selectEndNewsPoint(modifiedEndpoint);
  };

  const handleEndpointDashboardSelection = (
    endpoint: NewsEndpointListTypes,
  ) => {
    const modifiedDashboardEndpoint = {
      ...endpoint,
      sourceId: sourceData?.id,
      sourceName: sourceData?.serviceName,
      selectedNewsId: endpoint?.id,
    };

    if (
      newsDashboardWidgetReduxData.some(
        (e: NewsEndpointListTypes) => e._id === endpoint._id,
      )
    ) {
      removeSelectedEndDashboardNewsFeedData(endpoint?._id);
    } else {
      selectEndDashboardNewsPoint(modifiedDashboardEndpoint);
    }
  };

  useEffect(() => {
    if (isUpdate) {
      setSelectedDataSourceId(
        selectedDataSourcesWidget?.find(
          x => x?.file === sourceData?.serviceName,
        )?.file || '',
      );
    }
  }, []);

  return (
    <div className="col" key={sourceData.id}>
      <input
        type="checkbox"
        name=""
        checked={selectedDataSourcesWidget?.some(
          x => x?.file === sourceData?.serviceName,
        )}
        onClick={() => {
          toggleCollapse();

          setSelectedDataSourceId(sourceData?.id);
        }}
        className="showonly"
      />
      <div className="widget-card">
        <div className="flexbox-c gap-6">
          <button className="btn-clear btn-icon btn-16">
            {isCollapseOpen ? <IconMinusCircle /> : <IconPlusCircle />}
          </button>
          <p>{sourceData?.serviceName}</p>
          {sourceData?.isPaidService && (
            <>
              <span className="d-flex zindex-1" id="tooltip103">
                <IconStar />
              </span>
              <CustomTooltip placement="top" target="tooltip103" />
            </>
          )}
        </div>
      </div>

      {sourceData?.id === selectedDataSourceId && (
        <Collapse isOpen={isCollapseOpen}>
          {isLoading ? (
            <DataLoader title={''} />
          ) : dataSourceEndpoints?.length > 0 ? (
            dataSourceEndpoints?.map(
              (endpointData: EndpointListTypes | any) => (
                <div className="collapse-item" key={endpointData?._id}>
                  <input
                    type={isSingleCheck ? 'radio' : 'checkbox'}
                    name="sub-options"
                    checked={selectedDataSourcesWidget?.some(
                      x => x?.subFile === endpointData?.endpointName,
                    )}
                    onChange={() => {
                      if (isSingleCheck) handleEndpointSelection(endpointData);
                      if (isDashboard) {
                        handleEndpointDashboardSelection(endpointData);
                      }

                      if (endpointData?.isAmplifyData) {
                        let tempArr = [...(amplifyDataArr || [])];

                        if (selectedDataSourcesWidget) {
                          let tempSelectedArray = [
                            ...selectedDataSourcesWidget,
                          ];

                          const endpointIndex = tempSelectedArray?.findIndex(
                            val =>
                              val?.file === endpointData?.source?.serviceName,
                          );

                          if (endpointIndex >= 0) {
                            tempSelectedArray.splice(endpointIndex, 1);
                          } else {
                            tempSelectedArray.push({
                              file: endpointData?.source?.serviceName,
                              subFile: endpointData?.endpointName,
                              type: sourceType as string,
                            });
                          }

                          setSelectedDataSourcesWidget?.(tempSelectedArray);
                        }

                        const valIndex = tempArr.findIndex(
                          x => x?.id === endpointData?.id,
                        );

                        if (valIndex > -1) {
                          tempArr.splice(valIndex, 1);
                        } else {
                          tempArr.push({
                            fileName: endpointData?.endpointName,
                            id: endpointData?.id,
                          });
                        }

                        setAmplifyDataArr?.(tempArr || []);
                      } else {
                        let tempArr = [...(nodeEndpointArr || [])];
                        if (selectedDataSourcesWidget) {
                          let tempSelectedArray = [
                            ...selectedDataSourcesWidget,
                          ];

                          const endpointIndex = tempSelectedArray?.findIndex(
                            val =>
                              val?.file === endpointData?.source?.serviceName,
                          );

                          if (endpointIndex >= 0) {
                            tempSelectedArray.splice(endpointIndex, 1);
                          } else {
                            tempSelectedArray.push({
                              file: endpointData?.source?.serviceName,
                              subFile: endpointData?.endpointName,
                              type: sourceType as string,
                            });
                          }

                          setSelectedDataSourcesWidget?.(tempSelectedArray);
                        }

                        const valIndex = tempArr.findIndex(
                          x => x?.id === endpointData?.id,
                        );

                        if (valIndex > -1) {
                          tempArr.splice(valIndex, 1);
                        } else {
                          tempArr.push({
                            fileName: endpointData?.endpointName,
                            id: endpointData?.id,
                          });
                        }

                        setNodeEndpointArr?.(tempArr);
                      }
                    }}
                  />
                  <div className="widget-card">
                    <div className="flexbox-c gap-6">
                      <p>{endpointData?.endpointName}</p>
                    </div>
                  </div>
                </div>
              ),
            )
          ) : (
            <div className="collapse-item" key="endpoint-not-available">
              <div className="widget-card">
                <div className="flexbox-c gap-6">
                  <p>Endpoints not available</p>
                </div>
              </div>
            </div>
          )}
        </Collapse>
      )}
    </div>
  );
};

export default EndpointCollapse;
