import React from 'react';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import {
  IconAction,
  IconCross,
  IconEdit,
} from '../../../components/public/Icons';

type DepartmentDropdownProps = {
  toggleEditModal: () => void;
  toggleRemoveModal: () => void;
};

const DepartmentsActionDropdown = ({
  toggleEditModal,
  toggleRemoveModal,
}: DepartmentDropdownProps) => {
  const [dropdownOpen, setDropDownOpen] = React.useState(false);
  const toggleDropDown = () => {
    setDropDownOpen(state => !state);
  };

  return (
    <Dropdown
      className="add-dropdown"
      isOpen={dropdownOpen}
      toggle={toggleDropDown}
    >
      <DropdownToggle caret direction="down" className="btn-icon icon-hr">
        <IconAction />
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={toggleEditModal}>
          <IconEdit />
          Rename
        </DropdownItem>
        <DropdownItem onClick={toggleRemoveModal}>
          <IconCross />
          Remove
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

export default DepartmentsActionDropdown;
