/* ACTIONS */
import { themeConstant } from '../constant';

const { UPDATE_THEME_TYPE } = themeConstant;

export const updateThemeType = (data: any) => {
  return {
    type: UPDATE_THEME_TYPE,
    payload: {
      themeType: data,
    },
  };
};
