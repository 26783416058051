import React, {
  /* useEffect, */ useEffect,
  useRef /* useState */,
  useState,
} from 'react';
import Plot from 'react-plotly.js';
import {
  getXAxisConfig,
  getYAxisConfig,
  getLayoutConfig,
} from '../../chartsTheme';
import { rangeSlider } from '../../utils/modeBarIcons';

const ScatterChart = ({
  chartData,
  // chartTitle,
  initialXAxis,
  initialYAxis,
}: {
  chartData: any;
  chartTitle: string;
  initialXAxis: string;
  initialYAxis: string;
}) => {
  // const [updatedData, setUpdatedData] = useState([] as any);
  const layoutConfig = getLayoutConfig();
  const [isRangeSliderVisible, setIsRangeSliderVisible] = useState(false);
  const [xAxisRange, setXAxisRange] = useState<number[] | undefined>(undefined);
  const [updatedChartData, setUpdatedChartData] = useState(chartData);

  const plotRef = useRef<any>(null);

  useEffect(() => {
    if (chartData[0]?.x?.length > 100) {
      setXAxisRange([0, 100]);
    } else {
      setXAxisRange(undefined);
    }

    const modifiedData = chartData.map((data: any) => {
      return {
        ...data,
        x: data.x.map((value: any) =>
          value === 0 ? 'Undefined Value' : value,
        ),
      };
    });
    setUpdatedChartData(modifiedData);
  }, [chartData]);

  const resizeLayout = () => {
    plotRef.current.resizeHandler();
  };

  const toggleRangeSlider = () => {
    setIsRangeSliderVisible(prev => !prev);
  };

  const customButtons = [
    {
      name: 'Toggle Range Slider',
      icon: rangeSlider,
      title: 'Toggle Range Slider',
      click: toggleRangeSlider,
    },
  ];
  return (
    <>
      {
        <div className="chart-wrapper" onMouseDown={e => e.stopPropagation()}>
          <Plot
            ref={plotRef}
            data={updatedChartData}
            // data={updatedData}
            config={{
              scrollZoom: true,
              displaylogo: false,
              editable: true,
              responsive: true,
              modeBarButtonsToAdd: customButtons,
            }}
            layout={{
              title: '',
              xaxis: {
                ...getXAxisConfig(initialXAxis),
                range: xAxisRange,
                rangeslider: { visible: isRangeSliderVisible },
              },
              yaxis: getYAxisConfig(initialYAxis),
              ...layoutConfig,
              showlegend: false,
            }}
            useResizeHandler={true}
            onRelayout={resizeLayout}
          />
        </div>
      }
    </>
  );
};

export default ScatterChart;
