import { portfolioConstant } from '../constant';

const {
  ADD_NPL_PORTFOLIO_ID,
  REMOVE_NPL_PORTFOLIO_ID,
  ADD_LOAN_PORTFOLIO_ID,
  REMOVE_LOAN_PORTFOLIO_ID,
  ADD_RECEIVABLE_PORTFOLIO_ID,
  REMOVE_RECEIVABLE_PORTFOLIO_ID,
} = portfolioConstant;

export const addNplPortfolioId = (id?: string) => {
  return {
    type: ADD_NPL_PORTFOLIO_ID,
    payload: { nplPortfolioId: id },
  };
};

export const removeNplPortfolioId = () => {
  return {
    type: REMOVE_NPL_PORTFOLIO_ID,
    payload: { nplPortfolioId: '' },
  };
};

export const addLoanPortfolioId = (id?: string) => {
  return {
    type: ADD_LOAN_PORTFOLIO_ID,
    payload: { loanPortfolioId: id },
  };
};

export const removeLoanPortfolioId = () => {
  return {
    type: REMOVE_LOAN_PORTFOLIO_ID,
    payload: { loanPortfolioId: '' },
  };
};

export const addReceivablePortfolioId = (id?: string) => {
  return {
    type: ADD_RECEIVABLE_PORTFOLIO_ID,
    payload: { receivablePortfolioId: id },
  };
};

export const removeReceivablePortfolioId = () => {
  return {
    type: REMOVE_RECEIVABLE_PORTFOLIO_ID,
    payload: { receivablePortfolioId: '' },
  };
};
