import classnames from 'classnames';
import React, { useEffect, useState, JSX } from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
// import ImgNemotron from '../../../../../../../assets/images/nemotron.png';
// import ImgOrca2 from '../../../../../../../assets/images/orca2.png';
// import ImgClaudeAI from '../../../../../../../assets/images/claudeai.png';
// import ImgGemini from '../../../../../../../assets/images/gemini.png';
import loaderGIF from '../../../../../../../assets/images/building-data-loader.gif';
import {
  IconDropdownArrow,
  IconEye,
  IconEyeHide,
  IconInfo,
  IconSearch,
} from '../../../../../../components/public/Icons';
import CustomSelect from '../../../../../../components/ui/CustomSelect';
import { CustomTooltip } from '../../../../../../components/ui/CustomTooltip';
// import LoanCollateralButton from '../../../addportfoliowidget/components/LoanCollateralButton';
import useAction from '../../../../../../components/hooks/useAction';
import { useSelector } from 'react-redux';
import { userDetailsData } from '../../../../../../redux/selector/UserDetailsSelector';
import axios from 'axios';
import config from '../../../../../../../config';

type ModelListTypes = {
  _id: string;
  model_name: string;
  display_name: string;
};

const Portfolio_Data = [
  {
    hide: '',
    row_data: 'Collateral',
    imported: 'id',
    header_alias: 'Click to name',
    data_preview: '21945135798',
  },
  {
    hide: '',
    row_data: 'Collateral',
    imported: 'propertyID',
    header_alias: 'Click to name',
    data_preview: '21945135798',
  },
  {
    hide: '',
    row_data: 'Collateral',
    imported: 'transactionID',
    header_alias: 'Click to name',
    data_preview: '21945135798',
  },
  {
    hide: '',
    row_data: 'Collateral',
    imported: 'MODITYPE',
    header_alias: 'Click to name',
    data_preview: 'TRANS2500120047646',
  },
  {
    hide: '',
    row_data: 'Collateral',
    imported: 'propertyID',
    header_alias: 'Click to name',
    data_preview: '21945135798',
  },
  {
    hide: '',
    row_data: 'Collateral',
    imported: 'transactionID',
    header_alias: 'Click to name',
    data_preview: '21945135798',
  },
  {
    hide: '',
    row_data: 'Collateral',
    imported: 'MODITYPE',
    header_alias: 'Click to name',
    data_preview: 'MA',
  },
  {
    hide: '',
    row_data: 'Collateral',
    imported: 'propertyID',
    header_alias: 'Click to name',
    data_preview: '21945135798',
  },
  {
    hide: '',
    row_data: 'Collateral',
    imported: 'transactionID',
    header_alias: 'Click to name',
    data_preview: '-',
  },
  {
    hide: '',
    row_data: 'Collateral',
    imported: 'transactionID',
    header_alias: 'Click to name',
    data_preview: '21945135798',
  },
  {
    hide: '',
    row_data: 'Collateral',
    imported: 'MODITYPE',
    header_alias: 'Click to name',
    data_preview: 'TRANS2500120047646',
  },
  {
    hide: '',
    row_data: 'Collateral',
    imported: 'propertyID',
    header_alias: 'Click to name',
    data_preview: '21945135798',
  },
  {
    hide: '',
    row_data: 'Collateral',
    imported: 'transactionID',
    header_alias: 'Click to name',
    data_preview: '21945135798',
  },
  {
    hide: '',
    row_data: 'Collateral',
    imported: 'MODITYPE',
    header_alias: 'Click to name',
    data_preview: 'MA',
  },
  {
    hide: '',
    row_data: 'Collateral',
    imported: 'propertyID',
    header_alias: 'Click to name',
    data_preview: '21945135798',
  },
  {
    hide: '',
    row_data: 'Collateral',
    imported: 'transactionID',
    header_alias: 'Click to name',
    data_preview: '-',
  },
  {
    hide: '',
    row_data: 'Collateral',
    imported: 'MODITYPE',
    header_alias: 'Click to name',
    data_preview: '21945135798',
  },
];

// const roleOptions = [
//   {
//     value: 'cl',
//     label: (
//       <div className="select-img">
//         <img src={ImgClaudeAI} height="16" width="16" className="img-cover" />
//         <span>ClaudeAI</span>
//       </div>
//     ),
//   },
//   {
//     value: 'ge',
//     label: (
//       <div className="select-img">
//         <img src={ImgGemini} height="16" width="16" className="img-cover" />
//         <span>Gemini</span>
//       </div>
//     ),
//   },
//   {
//     value: 'ne',
//     label: (
//       <div className="select-img">
//         <img src={ImgNemotron} height="16" width="16" className="img-cover" />
//         <span>Nemotron</span>
//       </div>
//     ),
//   },
//   {
//     value: 'or',
//     label: (
//       <div className="select-img">
//         <img src={ImgOrca2} height="16" width="16" className="img-cover" />
//         <span>Orca 2</span>
//       </div>
//     ),
//   },
// ];

const PortfolioNormalizing = () => {
  const [isLoaing, setIsLoaing] = useState(true);
  const { showModal } = useAction();

  const [isHide, setisHide] = useState<{ [key: number]: boolean }>({});
  useEffect(() => {
    setTimeout(() => {
      setIsLoaing(false);
    }, 5000);
  }, []);

  const [currentActiveTab, setCurrentActiveTab] = useState('1');
  const [modelList, setModelList] = useState<
    Array<{
      label: JSX.Element;
      value: string;
    }>
  >([]);
  const userDetails = useSelector(userDetailsData);

  // Toggle active state for Tab
  const toggle = (tab: React.SetStateAction<string>) => {
    if (currentActiveTab !== tab) setCurrentActiveTab(tab);
  };

  const toggleHide = (index: number) => {
    setisHide(prevVisibility => ({
      ...prevVisibility,
      [index]: !prevVisibility[index],
    }));
  };

  const getFoundationalModelList = async () => {
    try {
      const response = await axios({
        method: 'get',
        url: `${config.ER_ML_URL}/llm/list`,
      });
      const responseManipulation: ModelListTypes[] = response?.data?.data;
      let updatedRes = responseManipulation?.map(x => {
        return {
          label: (
            <div className="select-img">
              {/* <img
                src={ImgOrca2}
                height="16"
                width="16"
                className="img-cover"
              /> */}
              <span>{x.display_name}</span>
            </div>
          ),
          value: x?.display_name,
        };
      });

      setModelList(updatedRes);
    } catch (error) {
      showModal('something went wrong');
    }
  };

  useEffect(() => {
    if (userDetails?._id) getFoundationalModelList();
  }, [userDetails?._id]);

  return (
    <>
      <div className="addwidget-content mw-100 ">
        {isLoaing ? (
          <div className="loader flexbox-align-c">
            <img src={loaderGIF} alt="" />
          </div>
        ) : (
          <>
            <div className="main-widget pe-0 widget-clear">
              <div className="react-grid-item">
                <div className="common-widget">
                  <div className="tabs-menu-line">
                    <Nav tabs>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: currentActiveTab === '1',
                          })}
                          onClick={() => {
                            toggle('1');
                          }}
                        >
                          Portfolio #1
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: currentActiveTab === '2',
                          })}
                          onClick={() => {
                            toggle('2');
                          }}
                        >
                          Portfolio #2
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: currentActiveTab === '3',
                          })}
                          onClick={() => {
                            toggle('3');
                          }}
                        >
                          Portfolio #3
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </div>
                  <TabContent activeTab={currentActiveTab}>
                    <TabPane tabId="1">
                      <div className="flexbox">
                        <div className="flexbox-c gap-3 mb-2">
                          <div className="form-group form-icon mb-0">
                            <span className="input-icon">
                              <IconSearch />
                            </span>
                            <input
                              id="searchQueryEndpoints"
                              type="text"
                              placeholder="Search"
                              className="form-control"
                            />
                          </div>
                          <button
                            className="btn btn-outline-secondary text-info"
                            type="button"
                          >
                            Toggle Hide All
                          </button>
                        </div>
                        {modelList?.length > 0 && (
                          <div className="mb-2 me-2">
                            <div className="ai-tools">
                              <div className="select-clear">
                                <CustomSelect
                                  options={modelList}
                                  value={modelList?.[0]}
                                  isSearchable={false}
                                  render
                                />
                                <div className="arrow-icon">
                                  <IconDropdownArrow />
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="table-responsive tablescroll">
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th
                                style={{ width: '55px' }}
                                scope="col"
                                className="text-center"
                              >
                                Hide
                              </th>
                              <th style={{ width: '220px' }} scope="col">
                                <div className="flexbox-c gap-1">
                                  Row Data Mapping
                                  <span
                                    className="d-flex zindex-1"
                                    id="bloomberg"
                                  >
                                    <IconInfo />
                                  </span>
                                  <CustomTooltip
                                    placement="top"
                                    target="bloomberg"
                                  />
                                </div>
                              </th>
                              <th style={{ width: '170px' }} scope="col">
                                Imported header
                              </th>
                              <th style={{ width: '210px' }} scope="col">
                                Header Alias
                              </th>
                              <th style={{ width: '200px' }} scope="col">
                                ROW Data Preview
                              </th>
                              <th>
                                <div className="flexbox gap-12">
                                  <button className="btn-clear link">
                                    Previous
                                  </button>
                                  <button className="btn-clear link">
                                    Next
                                  </button>
                                </div>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {Portfolio_Data.map((row, index) => {
                              return (
                                <tr key={index}>
                                  <td className="text-center">
                                    <button
                                      className="btn btn-icon mx-auto"
                                      onClick={() => toggleHide(index)}
                                    >
                                      {isHide[index] ? (
                                        <IconEyeHide />
                                      ) : (
                                        <IconEye />
                                      )}
                                    </button>
                                  </td>
                                  <td className="text-uppercase">
                                    {/* <LoanCollateralButton
                                      index={index}
                                      // row={row}
                                    /> */}
                                  </td>
                                  <td>{row.imported}</td>
                                  <td>
                                    <input
                                      type="text"
                                      placeholder="Click to name"
                                      className="form-control"
                                    />
                                  </td>
                                  <td colSpan={2}>{row.data_preview}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </TabPane>
                    <TabPane tabId="2">
                      <h5>Portfolio #2</h5>
                    </TabPane>
                    <TabPane tabId="3">
                      <h5>Portfolio #3</h5>
                    </TabPane>
                  </TabContent>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default PortfolioNormalizing;
