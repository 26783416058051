import { rowSelectionConstant } from '../constant';

const { ADD_ROW_SELECTION_TYPE } = rowSelectionConstant;

const initialState: any = {
  rowSelectionType: '',
};

const RowSelectionTypeReducer = (state = initialState, action: any): any => {
  switch (action.type) {
    case ADD_ROW_SELECTION_TYPE:
      return {
        rowSelectionType: action.payload.rowSelectionType,
      };

    default:
      return state;
  }
};
export default RowSelectionTypeReducer;
