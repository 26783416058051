/* ACTIONS */
import { portfolioWidgetsConstant } from '../constant';

const {
  ADD_CREDIT_WIDGET_DATA,
  REMOVE_CREDIT_WIDGET_DATA,
  ADD_NPL_WIDGET_DATA,
  REMOVE_NPL_WIDGET_DATA,
  ADD_NPL_COLLATERAL_WIDGET_DATA,
  REMOVE_NPL_COLLATERAL_WIDGET_DATA,
  ADD_NPL_LOAN_WIDGET_DATA,
  REMOVE_NPL_LOAN_WIDGET_DATA,

  ADD_LOAN_WIDGET_DATA,
  REMOVE_LOAN_WIDGET_DATA,
  ADD_LOAN_COLLATERAL_WIDGET_DATA,
  REMOVE_LOAN_COLLATERAL_WIDGET_DATA,
  ADD_LOAN_LOAN_WIDGET_DATA,
  REMOVE_LOAN_LOAN_WIDGET_DATA,

  ADD_RECEIVABLE_WIDGET_DATA,
  REMOVE_RECEIVABLE_WIDGET_DATA,
  ADD_RECEIVABLE_COLLATERAL_WIDGET_DATA,
  REMOVE_RECEIVABLE_COLLATERAL_WIDGET_DATA,
  ADD_RECEIVABLE_LOAN_WIDGET_DATA,
  REMOVE_RECEIVABLE_LOAN_WIDGET_DATA,

  ADD_VISUALIZATION_WIDGET_DATA,
  REMOVE_VISUALIZATION_WIDGET_DATA,

  REFRESH_PORTFOLIO_PAGE,
} = portfolioWidgetsConstant;

export const addPortfolioCreditWidgetData = (data: any) => {
  return {
    type: ADD_CREDIT_WIDGET_DATA,
    payload: {
      creditWidgetData: data,
    },
  };
};

export const removePortfolioCreditWidgetData = (id: number) => {
  return {
    type: REMOVE_CREDIT_WIDGET_DATA,
    payload: {
      removeId: id,
    },
  };
};

export const addPortfolioNplWidgetData = (data: any) => {
  return {
    type: ADD_NPL_WIDGET_DATA,
    payload: {
      nplWidgetData: data,
    },
  };
};

export const removePortfolioNplWidgetData = (id: number) => {
  return {
    type: REMOVE_NPL_WIDGET_DATA,
    payload: {
      removeId: id,
    },
  };
};

export const addPortfolioNplCollateralWidgetData = (data: any) => {
  return {
    type: ADD_NPL_COLLATERAL_WIDGET_DATA,
    payload: {
      nplCollateralWidgetData: data,
    },
  };
};

export const removePortfolioNplCollateralWidgetData = (id: number) => {
  return {
    type: REMOVE_NPL_COLLATERAL_WIDGET_DATA,
    payload: {
      removeId: id,
    },
  };
};

export const addPortfolioNplLoanWidgetData = (data: any) => {
  return {
    type: ADD_NPL_LOAN_WIDGET_DATA,
    payload: {
      nplLoanWidgetData: data,
    },
  };
};

export const removePortfolioNplLoanWidgetData = (id: number) => {
  return {
    type: REMOVE_NPL_LOAN_WIDGET_DATA,
    payload: {
      removeId: id,
    },
  };
};

export const addPortfolioLoanWidgetData = (data: any) => {
  return {
    type: ADD_LOAN_WIDGET_DATA,
    payload: {
      loanWidgetData: data,
    },
  };
};

export const removePortfolioLoanWidgetData = (id: number) => {
  return {
    type: REMOVE_LOAN_WIDGET_DATA,
    payload: {
      removeId: id,
    },
  };
};

export const addPortfolioLoanCollateralWidgetData = (data: any) => {
  return {
    type: ADD_LOAN_COLLATERAL_WIDGET_DATA,
    payload: {
      loanCollateralWidgetData: data,
    },
  };
};

export const removePortfolioLoanCollateralWidgetData = (id: number) => {
  return {
    type: REMOVE_LOAN_COLLATERAL_WIDGET_DATA,
    payload: {
      removeId: id,
    },
  };
};

export const addPortfolioLoanLoanWidgetData = (data: any) => {
  return {
    type: ADD_LOAN_LOAN_WIDGET_DATA,
    payload: {
      loanLoanWidgetData: data,
    },
  };
};

export const removePortfolioLoanLoanWidgetData = (id: number) => {
  return {
    type: REMOVE_LOAN_LOAN_WIDGET_DATA,
    payload: {
      removeId: id,
    },
  };
};

export const addPortfolioReceivableWidgetData = (data: any) => {
  return {
    type: ADD_RECEIVABLE_WIDGET_DATA,
    payload: {
      receivableWidgetData: data,
    },
  };
};

export const removePortfolioReceivableWidgetData = (id: number) => {
  return {
    type: REMOVE_RECEIVABLE_WIDGET_DATA,
    payload: {
      removeId: id,
    },
  };
};

export const addPortfolioReceivableCollateralWidgetData = (data: any) => {
  return {
    type: ADD_RECEIVABLE_COLLATERAL_WIDGET_DATA,
    payload: {
      receivableCollateralWidgetData: data,
    },
  };
};

export const removePortfolioReceivableCollateralWidgetData = (id: number) => {
  return {
    type: REMOVE_RECEIVABLE_COLLATERAL_WIDGET_DATA,
    payload: {
      removeId: id,
    },
  };
};

export const addPortfolioReceivableLoanWidgetData = (data: any) => {
  return {
    type: ADD_RECEIVABLE_LOAN_WIDGET_DATA,
    payload: {
      receivableLoanWidgetData: data,
    },
  };
};

export const removePortfolioReceivableLoanWidgetData = (id: number) => {
  return {
    type: REMOVE_RECEIVABLE_LOAN_WIDGET_DATA,
    payload: {
      removeId: id,
    },
  };
};

// Visualization data

export const addPortfolioVisualizationWidgetData = (data: any) => {
  return {
    type: ADD_VISUALIZATION_WIDGET_DATA,
    payload: {
      visualizationWidgetData: data,
    },
  };
};

export const addDashboardVisualizationWidgetData = (formattedData: any) => {
  return {
    type: ADD_VISUALIZATION_WIDGET_DATA,
    payload: {
      visualizationWidgetData: formattedData,
    },
  };
};

export const removePortfolioVisualizationWidgetData = (id: number) => {
  return {
    type: REMOVE_VISUALIZATION_WIDGET_DATA,
    payload: {
      removeId: id,
    },
  };
};

export const refreshPortfolioPage = (date: any) => {
  return {
    type: REFRESH_PORTFOLIO_PAGE,
    payload: {
      lastRefreshDate: date,
    },
  };
};