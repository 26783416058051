import {
  // Dispatch,
  // SetStateAction,
  // useCallback,
  useEffect,
  useState,
} from 'react';
import {
  IconCross,
  // IconEditCircle,
  IconEye,
  IconEyeHide,
  // IconFlag,
  IconMinusCircle,
  IconPlusCircle,
} from '../../../../../components/public/Icons';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
// import CustomSelect from '../../../../../components/ui/CustomSelect';
// import MarketPlaceService from '../../../../../services/marketplace.service';
import { showModal } from '../../../../../redux/actions';
import axios from 'axios';
import config from '../../../../../../config';
// import { PortfolioProps } from '../../../../../../@types/Widget';
import { SelectPortfoliosProps } from '../../../../../../@types/PortfolioType';
import AppLoader from '../../../../../components/loaders/AppLoader';
// import { isLoading } from '../../../../redux/selector/LoaderSelector';
// import { useSelector } from 'react-redux';

const SelectPortfolioValues = ({
  selectedVisualisationPortfolio,
  // currentActiveTab,
  onDataArrayChange,
}: SelectPortfoliosProps) => {
  const [headers, setHeaders] = useState<{ numeric: any[]; string: any[] }>({
    numeric: [],
    string: [],
  });
  const [numericModalOpen, setNumericModalOpen] = useState(false);
  const [stringModalOpen, setStringModalOpen] = useState(false);
  const [selectedNumericHeader, setSelectedNumericHeader] = useState<any>(null);
  const [selectedStringHeader, setSelectedStringHeader] = useState<any>(null);
  const [addedRows, setAddedRows] = useState<{ [key: string]: string[] }>({});

  const [isHide, setisHide] = useState<{ [key: string]: boolean }>({});

  const [selectedCheckbox, setSelectedCheckbox] = useState('');

  const [isMainLoading, setIsMainLoading] = useState(false);
  // const [dataArray, setDataArray] = useState<any[]>([]);
  // console.log('DATA ARRAY: ', dataArray);

  const fetchHeaders1 = async () => {
    setIsMainLoading(true);
    try {
      const response = await axios({
        method: 'POST',
        url: `${config.ER_ML_URL}/statistical/portfolio-charts/get-headers`,
        data: { portfolio_ids: selectedVisualisationPortfolio },
      });
      const headerData = response.data?.data || {};

      // Separate numeric and string headers
      const numericHeaders: {
        label: string;
        sum: any;
        arithmetic_average: any;
        weighted_average: any;
        total_fields: any;
      }[] = [];
      const stringHeaders: { label: string; total_fields: any }[] = [];

      if (headerData) {
        Object.keys(headerData).forEach(key => {
          const header = headerData[key];
          if ('sum' in header) {
            // This is a numeric header
            numericHeaders.push({
              label: key,
              sum: header.sum,
              arithmetic_average: header.arithmetic_average,
              weighted_average: header.weighted_average,
              total_fields: header.total_fields,
            });
          } else {
            // This is a string header
            const stringValues = Object.keys(header)
              .filter(k => k.endsWith('Count')) // Only include keys ending with '-Count'
              .reduce((acc: { [key: string]: any }, k: string) => {
                acc[k] = header[k]; // Set the value of the key in the accumulator
                return acc;
              }, {} as { [key: string]: any });

            stringHeaders.push({
              label: key,
              total_fields: header.total_fields,
              ...stringValues, // Add the counts to the string header object
            });
          }
        });
      }

      setHeaders({
        numeric: numericHeaders,
        string: stringHeaders,
      });

      setIsMainLoading(false);
    } catch (error) {
      showModal('something went wrong');
      setIsMainLoading(false);
    } finally {
      setIsMainLoading(false);
    }
  };

  const removeRow = (headerLabel: string, value: string) => {
    setAddedRows(prevAddedRows => {
      const currentRows = prevAddedRows[headerLabel] || [];
      const updatedRows = currentRows.filter(row => row !== value);

      onDataArrayChange((prevDataArray: any[]) => {
        return prevDataArray.filter((item: any) => {
          if (item.label === headerLabel) {
            if (
              value === 'arithmeticAverage' ||
              value === 'weightedAverage' ||
              value === 'fullTotalSum'
            ) {
              return !item[
                value === 'arithmeticAverage'
                  ? 'arithmetic_average'
                  : value === 'weightedAverage'
                  ? 'weighted_average'
                  : 'sum'
              ];
            } else {
              return !item[`${value}-Count`];
            }
          }
          return true;
        });
      });

      return {
        ...prevAddedRows,
        [headerLabel]: updatedRows,
      };
    });
  };

  const handleAddValue = (headerLabel: string, value: string) => {
    setAddedRows(prevAddedRows => {
      const currentRows = prevAddedRows[headerLabel] || [];

      if (!currentRows.includes(value)) {
        // Update the dataArray after adding the row for both numeric and string values
        onDataArrayChange((prevDataArray: any[]) => {
          const header =
            headers.string.find(h => h.label === headerLabel) ||
            headers.numeric.find(h => h.label === headerLabel);

          if (!header) return prevDataArray;

          if ('sum' in header) {
            const key = `${headerLabel}-${value}`;
            return [
              ...prevDataArray,
              {
                label: key,
                value:
                  header[value] ||
                  header[
                    value === 'arithmeticAverage'
                      ? 'arithmetic_average'
                      : value === 'weightedAverage'
                      ? 'weighted_average'
                      : 'sum'
                  ],
              },
            ];
          }

          const key = `${headerLabel}-${value}-Count`;
          return [
            ...prevDataArray,
            {
              label: key,
              value: header[`${value}-Count`] || header[value],
            },
          ];
        });
      }

      return {
        ...prevAddedRows,
        [headerLabel]: [...currentRows, value],
      };
    });
  };

  useEffect(() => {
    if (selectedVisualisationPortfolio) {
      fetchHeaders1();
    }
  }, [selectedVisualisationPortfolio]);

  const toggleHide = (index: number, type: string) => {
    setisHide(prevVisibility => ({
      ...prevVisibility,
      [`${type}-${index}`]: !prevVisibility[`${type}-${index}`],
    }));
  };

  const openNumericModal = (header: any) => {
    setSelectedNumericHeader(header);
    setNumericModalOpen(true);
  };

  const openStringModal = (header: any) => {
    setSelectedStringHeader(header);
    setStringModalOpen(true);
  };

  const toggleNumericModal = () => {
    setNumericModalOpen(!numericModalOpen);
    setSelectedNumericHeader(null);
  };

  const toggleStringModal = () => {
    setStringModalOpen(!stringModalOpen);
    setSelectedStringHeader(null);
  };

  const closeBtn = (
    <button
      className="close"
      onClick={() => {
        if (numericModalOpen) {
          toggleNumericModal();
        } else if (stringModalOpen) {
          toggleStringModal();
        }
      }}
      type="button"
    >
      <IconCross fill="#36567D" />
    </button>
  );

  return (
    <>
      {isMainLoading && <AppLoader />}
      <div className="addwidget-content mw-100">
        <div className="main-widget pe-0 widget-clear">
          <div className="react-grid-item">
            <div className="common-widget">
              <div className="table-responsive tablescroll">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th style={{ width: '55px' }} scope="col">
                        Hide
                      </th>
                      <th style={{ width: '230px' }} scope="col">
                        Data Header Name
                      </th>
                      <th scope="col">Values Configuration</th>
                    </tr>
                  </thead>
                  <tbody>
                    {headers?.numeric?.map((header: any, numericIndex: any) => (
                      <>
                        <tr key={`numeric-${numericIndex}`}>
                          <td className="text-center">
                            <button
                              className="btn btn-icon mx-auto"
                              onClick={() =>
                                toggleHide(numericIndex, 'numeric')
                              }
                            >
                              {isHide[`numeric-${numericIndex}`] ? (
                                <IconEyeHide />
                              ) : (
                                <IconEye />
                              )}
                            </button>
                          </td>
                          <td className="link">{header.label}</td>
                          <td className="">
                            {/* Display the IconPlusCircle only if not all options are added */}
                            {(!addedRows[header.label] ||
                              addedRows[header.label].length < 3) && (
                              <div className="flexbox">
                                <div className="flexbox-align-c gap-4">
                                  <button
                                    className="btn btn-clear btn-icon btn-16"
                                    onClick={() => openNumericModal(header)}
                                  >
                                    <IconPlusCircle width={24} height={24} />
                                  </button>
                                </div>
                              </div>
                            )}
                          </td>
                        </tr>

                        {/* Render rows for each added value (arithmeticAverage, weightedAverage, fullTotalSum) */}
                        {addedRows[header.label]?.includes(
                          'arithmeticAverage',
                        ) && (
                          <tr>
                            <td></td>
                            <td>Arithmetic Average</td>
                            <td>
                              <div className="flexbox">
                                {header.arithmetic_average}

                                <div className="flexbox-align-c gap-3">
                                  <button
                                    className="btn btn-clear btn-icon btn-16 btn-h-danger"
                                    onClick={() =>
                                      removeRow(
                                        header.label,
                                        'arithmeticAverage',
                                      )
                                    }
                                  >
                                    <IconMinusCircle width={24} height={24} />
                                  </button>
                                </div>
                              </div>
                            </td>
                          </tr>
                        )}
                        {addedRows[header.label]?.includes(
                          'weightedAverage',
                        ) && (
                          <tr>
                            <td></td>
                            <td>Weighted Average</td>
                            <td>
                              <div className="flexbox">
                                {header.weighted_average}
                                <button
                                  className="btn btn-clear btn-icon btn-16 btn-h-danger"
                                  onClick={() =>
                                    removeRow(header.label, 'weightedAverage')
                                  }
                                >
                                  <IconMinusCircle width={24} height={24} />
                                </button>
                              </div>
                            </td>
                          </tr>
                        )}
                        {addedRows[header.label]?.includes('fullTotalSum') && (
                          <tr>
                            <td></td>
                            <td>Full Total Sum</td>
                            <td>
                              <div className="flexbox">
                                {header.sum}
                                <button
                                  className="btn btn-clear btn-icon btn-16 btn-h-danger"
                                  onClick={() =>
                                    removeRow(header.label, 'fullTotalSum')
                                  }
                                >
                                  <IconMinusCircle width={24} height={24} />
                                </button>
                              </div>
                            </td>
                          </tr>
                        )}
                      </>
                    ))}

                    {headers?.string?.map((header: any, stringIndex: any) => (
                      <>
                        <tr key={`string-${stringIndex}`}>
                          <td className="text-center">
                            <button
                              className="btn btn-icon mx-auto"
                              onClick={() => toggleHide(stringIndex, 'string')}
                            >
                              {isHide[`string-${stringIndex}`] ? (
                                <IconEyeHide />
                              ) : (
                                <IconEye />
                              )}
                            </button>
                          </td>
                          <td className="link">{header.label}</td>
                          <td className="">
                            {/* Display the IconPlusCircle */}
                            <div className="flexbox">
                              <div className="flexbox-align-c gap-4">
                                <button
                                  className="btn btn-clear btn-icon btn-16"
                                  onClick={() => openStringModal(header)}
                                >
                                  <IconPlusCircle width={24} height={24} />
                                </button>
                              </div>
                            </div>
                          </td>
                        </tr>

                        {/* Render rows for each added string value (e.g., Male, Female) */}
                        {addedRows[header.label]?.map((value: any) => (
                          <tr key={`${header.label}-${value}`}>
                            <td></td>
                            <td>{value}</td>
                            <td>
                              <div className="flexbox">
                                {header[`${value}-Count`]}

                                <div className="flexbox-align-c gap-3">
                                  <button
                                    className="btn btn-clear btn-icon btn-16 btn-h-danger"
                                    onClick={() =>
                                      removeRow(header.label, value)
                                    }
                                  >
                                    <IconMinusCircle width={24} height={24} />
                                  </button>
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal for Numeric Headers */}
      <Modal isOpen={numericModalOpen} toggle={toggleNumericModal} centered>
        <ModalHeader toggle={toggleNumericModal} close={closeBtn}>
          Numeric Header: {selectedNumericHeader?.label}
        </ModalHeader>
        <ModalBody>
          <div className="mt-3 flexbox-c gap-3">
            <div className="form-check mb-3">
              <input
                className="form-check-input"
                type="radio"
                name="flagtriggers"
                id="arithmeticAverage"
                checked={selectedCheckbox === 'arithmeticAverage'}
                onChange={() => setSelectedCheckbox('arithmeticAverage')}
              />
              <label
                className="form-check-label ms-0"
                htmlFor="arithmeticAverage"
              >
                Arithmetic Average
              </label>
            </div>
            <div className="form-check mb-3">
              <input
                className="form-check-input"
                type="radio"
                name="flagtriggers"
                id="weightedAverage"
                checked={selectedCheckbox === 'weightedAverage'}
                onChange={() => setSelectedCheckbox('weightedAverage')}
              />
              <label
                className="form-check-label ms-0"
                htmlFor="weightedAverage"
              >
                Weighted Average
              </label>
            </div>
            <div className="form-check mb-3">
              <input
                className="form-check-input"
                type="radio"
                name="flagtriggers"
                id="fullTotalSum"
                checked={selectedCheckbox === 'fullTotalSum'}
                onChange={() => setSelectedCheckbox('fullTotalSum')}
              />
              <label className="form-check-label ms-0" htmlFor="fullTotalSum">
                Total Sum
              </label>
            </div>
          </div>
        </ModalBody>
        <ModalFooter className="justify-content-end">
          <button className="btn btn-outline-info" onClick={toggleNumericModal}>
            Cancel
          </button>
          <button
            className="btn btn-primary"
            onClick={() => {
              if (selectedNumericHeader && selectedCheckbox) {
                handleAddValue(selectedNumericHeader.label, selectedCheckbox); // Handle addition for numeric
              }
              toggleNumericModal();
            }}
          >
            Add Value
          </button>
        </ModalFooter>
      </Modal>

      {/* Modal for String Headers */}
      <Modal isOpen={stringModalOpen} toggle={toggleStringModal} centered>
        <ModalHeader toggle={toggleStringModal} close={closeBtn}>
          String Header: {selectedStringHeader?.label}
        </ModalHeader>
        <ModalBody>
          <div className="mt-3 flexbox-c gap-3">
            {selectedStringHeader &&
              Object.keys(selectedStringHeader)
                .filter(key => key.endsWith('-Count'))
                .map((value, index) => (
                  <div className="form-check mb-3" key={index}>
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flagtriggers"
                      id={value}
                      checked={selectedCheckbox === value}
                      onChange={() => setSelectedCheckbox(value)}
                    />
                    <label className="form-check-label ms-0" htmlFor={value}>
                      {value.replace('-Count', '')}
                    </label>
                  </div>
                ))}
          </div>
        </ModalBody>
        <ModalFooter className="justify-content-end">
          <button className="btn btn-outline-info" onClick={toggleStringModal}>
            Cancel
          </button>
          <button
            className="btn btn-primary"
            onClick={() => {
              if (selectedStringHeader && selectedCheckbox) {
                handleAddValue(
                  selectedStringHeader.label,
                  selectedCheckbox.replace('-Count', ''), // Handle addition for string
                );
              }
              toggleStringModal();
            }}
          >
            Add Value
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default SelectPortfolioValues;
