import { CHARTS } from '../Charts/constants';

const prepareChartPayload = (chartHeaders: any, chartType: string) => {
  let payload: any;
  const formattedChartType =
    chartType.charAt(0).toUpperCase() + chartType.slice(1).toLowerCase();

  if (chartType === CHARTS.PIE || chartType === CHARTS.RADAR) {
    const valuesArray = chartHeaders?.xAxis?.map(
      (item: { value: string }) => item?.value,
    );

    payload = {
      chart_name: formattedChartType,
      fields: valuesArray,
      required_fields: valuesArray,
    };
  } else if (
    chartType === CHARTS.LINE ||
    chartType === CHARTS.BAR ||
    chartType === CHARTS.BUBBLE
  ) {
    const valuesArray = [
      chartHeaders?.xAxis?.value,
      chartHeaders?.yAxis?.value,
    ];

    payload = {
      chart_name: formattedChartType,
      fields: valuesArray,
      required_fields: [...new Set(valuesArray.flat())],
    };
  } else if (
    chartType === CHARTS.AREA ||
    chartType === CHARTS.WATERFALL ||
    chartType === CHARTS.SCATTER ||
    chartType === CHARTS.STACKED
  ) {
    const valuesArray = [
      [chartHeaders?.xAxis?.value],
      chartHeaders?.yAxis?.map((item: { value: string }) => item?.value),
    ];

    payload = {
      chart_name:
        chartType === CHARTS.STACKED ? 'Stack Bar' : formattedChartType,
      fields: valuesArray,
      required_fields: [...new Set(valuesArray.flat())],
    };
  } else if (chartType === CHARTS.HEATMAP) {
    const valuesArray = [
      chartHeaders?.xAxis?.map((item: { value: string }) => item?.value),
      chartHeaders?.yAxis?.map((item: { value: string }) => item?.value),
    ];

    const data = {
      x: valuesArray[0],
      y: valuesArray[1],
    };

    payload = {
      chart_name: 'Heat Map',
      fields: data,
      required_fields: [...new Set(valuesArray.flat())],
    };
  }

  return payload;
};

export default prepareChartPayload;
