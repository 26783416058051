/* ACTIONS */
import { marketplaceTabConstant } from '../constant';

const { ADD_MARKETPLACE_TAB } = marketplaceTabConstant;

export const addMarketplaceTab = (data: string) => {
  return {
    type: ADD_MARKETPLACE_TAB,
    payload: {
      marketplaceTab: data,
    },
  };
};
