import { useRef, useState } from 'react';
import Plot from 'react-plotly.js';
import {
  getLayoutConfig,
  getXAxisConfig,
  getYAxisConfig,
} from '../../chartsTheme';
import '../styles.css';
import { leftArrow, rangeSlider, rightArrow } from '../../utils/modeBarIcons';

const BarChart = ({
  chartData,
  initialXAxis,
  initialYAxis,
}: {
  chartData: any;
  chartTitle: string;
  initialXAxis: string;
  initialYAxis: string;
}) => {
  const [updatedData, setUpdatedData] = useState(chartData);
  const [isRangeSliderVisible, setIsRangeSliderVisible] = useState(false);
  const layoutConfig = getLayoutConfig();

  const plotRef = useRef<any>(null);

  const resizeLayout = () => {
    plotRef.current.resizeHandler();
  };

  const handleSort = (ascending: boolean) => {
    const sortedData = updatedData.map((trace: any) => {
      const sortedIndices = trace.y
        .map((value: any, index: number) => ({ value, index }))
        .sort((a: any, b: any) =>
          ascending ? a.value - b.value : b.value - a.value,
        )
        .map((item: any) => item.index);

      const sortedY = sortedIndices.map((i: number) => trace.y[i]);
      const sortedX = sortedIndices.map((i: number) => trace.x[i]);

      return {
        ...trace,
        x: sortedX,
        y: sortedY,
      };
    });

    setUpdatedData(sortedData);
  };

  const handleSortAscending = () => {
    handleSort(true);
  };

  const handleSortDescending = () => {
    handleSort(false);
  };

  const toggleRangeSlider = () => {
    setIsRangeSliderVisible(prev => !prev);
  };

  const customButtons = [
    {
      name: 'Sort Ascending',
      icon: leftArrow,
      title: 'Sort Data Ascending',
      click: handleSortAscending,
    },
    {
      name: 'Sort Descending',
      icon: rightArrow,
      title: 'Sort Data Descending',
      click: handleSortDescending,
    },
    {
      name: 'Toggle Range Slider',
      icon: rangeSlider,
      title: 'Toggle Range Slider',
      click: toggleRangeSlider,
    },
  ];

  return (
    <>
      {
        <div className="chart-wrapper" onMouseDown={e => e.stopPropagation()}>
          <Plot
            ref={plotRef}
            data={[
              {
                ...updatedData[0],
                type: 'bar',
                orientation: 'v',
              },
            ]}
            config={{
              scrollZoom: true,
              displaylogo: false,
              editable: true,
              responsive: true,
              modeBarButtonsToAdd: customButtons,
            }}
            layout={{
              title: '',
              bargap: 0.3,
              yaxis: getYAxisConfig(initialYAxis),
              ...layoutConfig,
              xaxis: {
                ...getXAxisConfig(initialXAxis),
                rangeslider: { visible: isRangeSliderVisible },
              },
            }}
            useResizeHandler={true}
            onRelayout={resizeLayout}
          />
        </div>
      }
    </>
  );
};

export default BarChart;
