import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import {
  IconAction,
  IconCross,
  IconEdit,
  IconSend,
  IconTick,
} from '../public/Icons';

type ChartWidgetMenuDropdownProps = {
  dropdownOpen: boolean;
  toggleDropDown: () => void;
  toggleEditWidgetModal?: () => void;
  toggleRemoveWidgetModal: () => void;
  chartSaveModal: () => void;
  toggleShareExternallyModal?: () => void;
  shareVisualizationModal: () => void;
};

const ChartWidgetMenuDropdown = ({
  dropdownOpen,
  toggleDropDown,
  toggleEditWidgetModal,
  toggleRemoveWidgetModal,
  toggleShareExternallyModal,
  chartSaveModal,
  shareVisualizationModal,
}: ChartWidgetMenuDropdownProps) => {
  return (
    <Dropdown
      className="add-dropdown"
      isOpen={dropdownOpen}
      toggle={toggleDropDown}
    >
      <DropdownToggle caret direction="down" className="btn-icon">
        <IconAction />
      </DropdownToggle>
      <DropdownMenu end>
        <DropdownItem onClick={toggleEditWidgetModal}>
          <IconEdit />
          Edit Widget
        </DropdownItem>
        <DropdownItem onClick={chartSaveModal}>
          <IconTick fill="#ffffff" />
          Save Chart
        </DropdownItem>
        <DropdownItem onClick={shareVisualizationModal}>
          <IconSend />
          Share Widget
        </DropdownItem>
        <DropdownItem onClick={toggleShareExternallyModal}>
          <IconSend />
          Share Widget Externally
        </DropdownItem>
        <DropdownItem onClick={toggleRemoveWidgetModal}>
          <IconCross />
          Remove Widget
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

export default ChartWidgetMenuDropdown;
