import React, { useState } from 'react';
import PublicHeader from '../../../components/public/PublicHeader';

import classnames from 'classnames';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import General from './General';
import LicenSesBilling from './LicenSesBilling';
import Statistics from './Statistics';
import Users from './Users';
import { userDetailsData } from '../../../redux/selector/UserDetailsSelector';
import { useSelector } from 'react-redux';
import { userData } from '../../../redux/selector/UserSelector';
import { TextConstants } from '../../../helpers/constants';

const Setting = () => {
  // State for current active Tab
  const [currentActiveTab, setCurrentActiveTab] = useState('1');
  const userDetails = useSelector(userDetailsData);
  const user = useSelector(userData);

  // Toggle active state for Tab
  const toggle = (tab: React.SetStateAction<string>) => {
    if (currentActiveTab !== tab) setCurrentActiveTab(tab);
  };

  return (
    <div className="page-content">
      <PublicHeader />
      <div className="page-middle-content">
        <div className="container">
          <div className="main-widget pe-0">
            <div className="react-grid-item widget-h-full">
              <div className="common-widget">
                <div className="common-widget-header">
                  <div className="common-widget-header-title">
                    <h2 className="me-10">Settings</h2>
                  </div>
                </div>
                <div className="common-widget-content px-0 overflow-hidden">
                  <div className="tabs-container">
                    <div className="tabs-v-menu">
                      <Nav tabs vertical>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: currentActiveTab === '1',
                            })}
                            onClick={() => {
                              toggle('1');
                            }}
                          >
                            General
                          </NavLink>
                        </NavItem>
                        {(user?.userRole === TextConstants.OWNER ||
                          user?.userRole === TextConstants.ADMIN) && (
                          <NavItem>
                            <NavLink
                              className={classnames({
                                active: currentActiveTab === '2',
                              })}
                              onClick={() => {
                                toggle('2');
                              }}
                            >
                              Users
                            </NavLink>
                          </NavItem>
                        )}
                        {user?.userRole === TextConstants.OWNER && (
                          <>
                            <NavItem>
                              <NavLink
                                className={classnames({
                                  active: currentActiveTab === '3',
                                })}
                                onClick={() => {
                                  toggle('3');
                                }}
                              >
                                Statistics
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                className={classnames({
                                  active: currentActiveTab === '4',
                                })}
                                onClick={() => {
                                  toggle('4');
                                }}
                              >
                                LicenSes & Billing
                              </NavLink>
                            </NavItem>
                          </>
                        )}
                      </Nav>
                    </div>
                    <TabContent activeTab={currentActiveTab}>
                      <TabPane tabId="1">
                        {currentActiveTab === '1' && (
                          <General userDetails={userDetails} />
                        )}
                      </TabPane>
                      <TabPane tabId="2">
                        {currentActiveTab === '2' && (
                          <Users userDetails={userDetails} />
                        )}
                      </TabPane>
                      <TabPane tabId="3">
                        {currentActiveTab === '3' && (
                          <Statistics activeTab={currentActiveTab} />
                        )}
                      </TabPane>
                      <TabPane tabId="4">
                        {currentActiveTab === '4' && <LicenSesBilling />}
                      </TabPane>
                    </TabContent>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Setting;
