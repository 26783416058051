import { chatConstant } from '../constant/chat.constant';

const { UPDATE_ALL_MESSAGE, ADD_NEW_MESSAGE } = chatConstant;

import { split } from 'lodash';
import moment from 'moment';

export const updateAllChatMessages = (messages: any) => {
  return {
    type: UPDATE_ALL_MESSAGE,
    payload: { messages },
  };
};

export const addNewMessage = (message: any) => {
  const createdDate = message.createdAt
    ? split(message.createdAt, 'T')[0]
    : moment().format('YYYY-MM-DD');
  
  const newMessage = {
    ...message,
    createdDate, 
  };

  return {
    type: ADD_NEW_MESSAGE,
    payload: { messages: newMessage },
  };
};
