import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { IconInfo } from '../../../../../components/public/Icons';
import { CustomTooltip } from '../../../../../components/ui/CustomTooltip';
import axios from 'axios';
import config from '../../../../../../config';
import { PortfolioProps } from '../../../../../../@types/Widget';
import useAction from '../../../../../components/hooks/useAction';
import InfiniteScroll from 'react-infinite-scroll-component';
import DataLoader from '../../../../../components/loaders/DataLoader';

const SelectPortfoliosPerformance = ({
  selectedPortfolio,
  setSelectedPortfolio,
}: {
  selectedPortfolio: any[];
  setSelectedPortfolio: Dispatch<SetStateAction<any[]>>;
}) => {
  const { showModal } = useAction();
  const [isMainLoading, setIsMainLoading] = useState(false);
  const [fileNamesPage, setFileNamesPage] = useState(1);

  const [portfolio, setPortfolio] = useState<PortfolioProps>(
    {} as PortfolioProps,
  );

  const getPortfolio = async (isPageChange: boolean) => {
    setIsMainLoading(true);

    try {
      const response = await axios({
        method: 'get',
        url: `${config.ER_ML_URL}/portfolio/?limit=10&page=${fileNamesPage}`,
      });
      const responseManipulation = !isPageChange
        ? response.data
        : Object.keys(portfolio).length > 0
        ? {
            ...portfolio,
            data: [...(portfolio?.data || {}), ...(response.data.data || {})],
          }
        : response.data;

      setPortfolio(responseManipulation);
      if (isPageChange) {
        setFileNamesPage(fileNamesPage + 1);
      }
      setIsMainLoading(false);
    } catch (error) {
      showModal('something went wrong');
      setIsMainLoading(false);
    } finally {
      setIsMainLoading(false);
    }
  };

  useEffect(() => {
    getPortfolio(true);
  }, []);
  return (
    <>
      <div className="addwidget-content mw-100">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-6">
            <div className="flexbox">
              <div className="d-flex align-items-center">
                <p className="widget-category me-1 mb-0">SELECT PORTFOLIO(s)</p>
                <span className="d-flex" id="tooltipsppw">
                  <IconInfo />
                </span>
                <CustomTooltip placement="top" target="tooltipsppw" />
              </div>
              <div className="form-check mb-0">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="showonlytime"
                />
                <label className="form-check-label" htmlFor="showonlytime">
                  Show only mine
                </label>
              </div>
            </div>
            <div
              className="create-widget create-widget-portfolio"
              id="scrollableDiv"
            >
              <div className="widget-item">
                <div className="row row-cols-1">
                  <InfiniteScroll
                    dataLength={portfolio?.data?.length || 0}
                    next={() => getPortfolio(true)}
                    hasMore={portfolio?.data?.length <= 0 ? false : true}
                    loader={
                      portfolio?.data?.length <= 0 ? (
                        <div style={{ paddingLeft: '12px' }}>Loading...</div>
                      ) : null
                    }
                    scrollableTarget="scrollableDiv"
                  >
                    {portfolio?.data &&
                      portfolio?.data.length > 0 &&
                      portfolio?.data?.map((item, index) => {
                        return (
                          <div className="col" key={index}>
                            <input
                              type="checkbox"
                              name="select-portfolio"
                              onClick={() => {
                                const isExist = selectedPortfolio.find(
                                  ele => ele === item._id,
                                );
                                if (isExist) {
                                  const filterIsExist = selectedPortfolio.filter(
                                    selected => selected !== item._id,
                                  );
                                  setSelectedPortfolio(filterIsExist);
                                } else {
                                  const duplicate = [...selectedPortfolio];
                                  duplicate.push(item._id);
                                  setSelectedPortfolio(duplicate);
                                }
                              }}
                            />
                            <div className="widget-card">
                              <p>{item.fileName}</p>
                              {/* {item.subtext && <p className="text-info font-14">{item.subtext}</p>} */}
                            </div>
                          </div>
                        );
                      })}
                  </InfiniteScroll>
                  {isMainLoading && <DataLoader />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SelectPortfoliosPerformance;
