import React, { /* useEffect, */ useRef /* useState */ } from 'react';
import Plot from 'react-plotly.js';
import {
  getLayoutConfig,
  getXAxisConfig,
  getYAxisConfig,
} from '../../chartsTheme';
// import '../../styles.css';

const StackedBarChart = ({
  chartData,
  // chartTitle,
  initialXAxis,
  initialYAxis,
}: {
  chartData: any;
  chartTitle: string;
  initialXAxis: string;
  initialYAxis: string;
}) => {
  // const [updatedData, setUpdatedData] = useState([] as any);
  const layoutConfig = getLayoutConfig();

  const plotRef = useRef<any>(null);

  const resizeLayout = () => {
    plotRef.current.resizeHandler();
  };

  // console.log(chartData);
  return (
    <>
      {
        <div className="chart-wrapper" onMouseDown={e => e.stopPropagation()}>
          <Plot
            ref={plotRef}
            // data={updatedData}
            data={chartData}
            config={{
              scrollZoom: true,
              displaylogo: false,
              editable: true,
              responsive: true,
              // autosizable: true,
            }}
            layout={{
              title: '',
              barmode: 'stack',
              bargap: 0.3,
              showlegend: false,
              xaxis: getXAxisConfig(initialXAxis),
              yaxis: getYAxisConfig(initialYAxis),
              ...layoutConfig,
            }}
            useResizeHandler={true}
            onRelayout={resizeLayout}
          />
        </div>
      }
    </>
  );
};

export default StackedBarChart;
