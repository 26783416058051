import React from 'react';

type LoanWidgetHeadingProps = {
  currentActiveTab: string;
};

const LoanWidgetHeading = ({ currentActiveTab }: LoanWidgetHeadingProps) => {
  return (
    <>
      {currentActiveTab === '2' && (
        <h4 className="title-20">Select portfolio for the Loan widget</h4>
      )}
      {currentActiveTab === '3' && (
        <h4 className="title-20">
          Define the goal, geography and time frame of the Loan widget
        </h4>
      )}
      {currentActiveTab === '4' && (
        <h4 className="title-20">
          Define statistical analysis thresholds for the Loan widget
        </h4>
      )}
    </>
  );
};

export default LoanWidgetHeading;
