import { useCallback, useEffect, useState } from 'react';
import CustomSelect from '../../../../../components/ui/CustomSelect';
import { SelectPortfoliosProps } from '../../../../../../@types/PortfolioType';
import axios from 'axios';
import config from '../../../../../../config';
import useAction from '../../../../../components/hooks/useAction';
import DataLoader from '../../../../../components/loaders/DataLoader';
import { CHARTS, optionsVisualisation } from '../../Charts/constants';
import { Formik } from 'formik';
import { Form } from 'reactstrap';

interface Fields {
  xAxis?: any;
  yAxis?: any;
}

interface SavedChartProps {
  chartId: string;
  portfolioId: string;
  visualisationType: string;
  createdAt: string;
  fields: Fields;
  xAxisLabel?: string;
  yAxisLabel?: string;
  chartName?: string;
}

interface APIResponse {
  total_saved_charts: number;
  saved_charts: SavedChartProps[];
}

const SelectTypeAndParameters = ({
  selectedVisualisationPortfolio,
  onFormChange,
  currentActiveTab,
  initialValues,
}: SelectPortfoliosProps) => {
  const { showModal } = useAction();
  const [isLoading, setIsLoading] = useState(false);
  const [axisKeys, setAxisKeys] = useState([] as any);
  const [selectedChartType, setSelectedChartType] = useState(CHARTS.BAR);
  const [savedChartData, setSavedChartData] = useState<SavedChartProps[]>([]);

  const getAxisDataFields = useCallback((id: string) => {
    setIsLoading(true);
    axios
      .get(`${config.ER_ML_URL}/visualization/get-column/${id}`)
      .then(({ data }) => {
        localStorage.removeItem('taskPortfolioId');
        const fileNamesArray = data?.data?.map((key: string | number) => ({
          label: key,
          value: key,
        }));
        setAxisKeys(fileNamesArray);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
        showModal('something went wrong');
      });
  }, []);

  const fetchSavedCharts = async (id: string) => {
    try {
      const response = await axios.get<APIResponse>(
        `${config.ER_ML_URL}/portfolio/fetch-chart/${id}`,
      );

      setSavedChartData(response.data.saved_charts);
      setIsLoading(false);
    } catch (err) {
      showModal('something went wrong');
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (selectedVisualisationPortfolio && currentActiveTab === '3') {
      getAxisDataFields(selectedVisualisationPortfolio);
      fetchSavedCharts(selectedVisualisationPortfolio);
    }
  }, [selectedVisualisationPortfolio, currentActiveTab, getAxisDataFields]);

  const defaultValues = {
    chartTitle: '',
    visualisationType: optionsVisualisation[0],
    xAxisLabel: '',
    yAxisLabel: '',
    lines: [],
    fields: {},
  };

  const updateFields = (fields: any, type: string, value: any) => {
    const updatedFields = { ...fields };
    updatedFields[type] = value;
    return updatedFields;
  };

  return (
    <div className="addwidget-content mw-100">
      <div className="row justify-content-center">
        <div className="col-12 col-lg-6">
          <div className="create-widget wl-440 px-0">
            {isLoading ? (
              <DataLoader title={''} />
            ) : (
              <div className="widget-item">
                <Formik
                  initialValues={initialValues || defaultValues}
                  onSubmit={() => {}}
                  enableReinitialize
                >
                  {({ values, setFieldValue }) => {
                    useEffect(() => {
                      onFormChange(values);
                    }, [values, onFormChange]);
                    return (
                      <Form>
                        {/* Saved Charts Here */}
                        <div className="col-12 col-lg-6">
                          <div className="form-group">
                            <label className="form-label">
                              Saved Charts for this Portfolio
                            </label>
                            <CustomSelect
                              id="savedCharts"
                              name="savedCharts"
                              options={savedChartData.map(chart => ({
                                label: `${chart.chartName} - ${chart.visualisationType}`,
                                value: chart.chartId,
                                chartData: chart,
                              }))}
                              placeholder="Select Saved Chart"
                              onChange={(selectedOption: {
                                chartData: any;
                              }) => {
                                const selectedChart = selectedOption.chartData;

                                const {
                                  visualisationType,
                                  xAxisLabel,
                                  yAxisLabel,
                                  fields,
                                } = selectedChart;
                                const selectedVisualisationType = optionsVisualisation.find(
                                  option =>
                                    option.value === visualisationType ||
                                    option.label === visualisationType,
                                );

                                setFieldValue(
                                  'visualisationType',
                                  selectedVisualisationType || {
                                    label: visualisationType,
                                    value: visualisationType,
                                  },
                                );
                                setSelectedChartType(
                                  selectedVisualisationType?.value ||
                                    CHARTS.BAR,
                                );
                                setFieldValue('xAxisLabel', xAxisLabel || '');
                                setFieldValue('yAxisLabel', yAxisLabel || '');
                                setFieldValue('fields', fields || {});
                              }}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12 col-lg-6">
                            <div className="form-group">
                              <label className="form-label">
                                Visualisation Type
                              </label>
                              <CustomSelect
                                id="visualisationType"
                                name="visualisationType"
                                options={optionsVisualisation}
                                placeholder=""
                                value={values.visualisationType}
                                onChange={(e: any) => {
                                  setSelectedChartType(e.value);
                                  if (
                                    e.value !== values?.visualisationType?.value
                                  ) {
                                    setFieldValue('chartTitle', '');
                                    setFieldValue('xAxisLabel', '');
                                    setFieldValue('yAxisLabel', '');
                                    setFieldValue('lines', []);
                                    setFieldValue('fields', {});
                                    setFieldValue('visualisationType', e);
                                  }
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-12 col-lg-6">
                            <div className="form-group">
                              <label className="form-label">
                                Chart title (Optional)
                              </label>
                              <input
                                id="chartTitle"
                                name="chartTitle"
                                type="text"
                                className="form-control"
                                value={values.chartTitle}
                                onChange={(e: any) => {
                                  setFieldValue('chartTitle', e.target.value);
                                }}
                              />
                            </div>
                          </div>
                          {/* Chart-wise fields */}
                          <div>
                            <div className="row">
                              <div className="col-12 col-lg-6">
                                <div className="form-group">
                                  <label className="form-label">X-Axis</label>
                                  <CustomSelect
                                    id="xAxis"
                                    name="xAxis"
                                    options={axisKeys}
                                    isMulti={
                                      selectedChartType === CHARTS.PIE ||
                                      // selectedChartType === CHARTS.AREA ||
                                      // selectedChartType === CHARTS.WATERFALL ||
                                      selectedChartType === CHARTS.RADAR ||
                                      // selectedChartType === CHARTS.SCATTER ||
                                      // selectedChartType === CHARTS.STACKED ||
                                      selectedChartType === CHARTS.HEATMAP
                                    }
                                    value={values.fields['xAxis']}
                                    onChange={(selectedOptions: any) => {
                                      const updatedValue =
                                        selectedChartType === CHARTS.PIE ||
                                        // selectedChartType === CHARTS.AREA ||
                                        // selectedChartType ===
                                        //   CHARTS.WATERFALL ||
                                        selectedChartType === CHARTS.RADAR ||
                                        // selectedChartType === CHARTS.SCATTER ||
                                        // selectedChartType === CHARTS.STACKED ||
                                        selectedChartType === CHARTS.HEATMAP
                                          ? selectedOptions.map(
                                              (option: any) => ({
                                                label: option.label,
                                                value: option.value,
                                              }),
                                            )
                                          : selectedOptions;

                                      setFieldValue(
                                        'fields',
                                        updateFields(
                                          values.fields,
                                          'xAxis',
                                          updatedValue,
                                        ),
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-12 col-lg-6">
                                <div className="form-group">
                                  <label className="form-label">
                                    X-Axis LABEL (Optional)
                                  </label>
                                  <input
                                    id="xAxisLabel"
                                    name="xAxisLabel"
                                    type="text"
                                    className="form-control"
                                    value={values.xAxisLabel}
                                    onChange={(e: any) => {
                                      setFieldValue(
                                        'xAxisLabel',
                                        e.target.value,
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                              {selectedChartType !== CHARTS.PIE &&
                                selectedChartType !== CHARTS.RADAR && (
                                  <>
                                    <div className="col-12 col-lg-6">
                                      <div className="form-group">
                                        <label className="form-label">
                                          Y-Axis
                                        </label>
                                        <CustomSelect
                                          id="yAxis"
                                          name="yAxis"
                                          options={axisKeys}
                                          value={values.fields['yAxis']}
                                          isMulti={
                                            selectedChartType === CHARTS.AREA ||
                                            selectedChartType ===
                                              CHARTS.WATERFALL ||
                                            selectedChartType ===
                                              CHARTS.SCATTER ||
                                            selectedChartType ===
                                              CHARTS.STACKED ||
                                            selectedChartType === CHARTS.HEATMAP
                                          }
                                          onChange={(selectedOptions: any) => {
                                            const updatedValue =
                                              selectedChartType ===
                                                CHARTS.BUBBLE ||
                                              selectedChartType ===
                                                CHARTS.BAR ||
                                              selectedChartType === CHARTS.LINE
                                                ? selectedOptions
                                                : selectedOptions.map(
                                                    (option: any) => ({
                                                      label: option.label,
                                                      value: option.value,
                                                    }),
                                                  );

                                            setFieldValue(
                                              'fields',
                                              updateFields(
                                                values.fields,
                                                'yAxis',
                                                updatedValue,
                                              ),
                                            );
                                          }}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-12 col-lg-6">
                                      <div className="form-group">
                                        <label className="form-label">
                                          Y-Axis LABEL (Optional)
                                        </label>
                                        <input
                                          id="yAxisLabel"
                                          name="yAxisLabel"
                                          type="text"
                                          className="form-control"
                                          value={values.yAxisLabel}
                                          onChange={(e: any) => {
                                            setFieldValue(
                                              'yAxisLabel',
                                              e.target.value,
                                            );
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </>
                                )}
                            </div>
                          </div>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectTypeAndParameters;
