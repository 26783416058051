import { useEffect, useState } from 'react';
import useAction from '../../../components/hooks/useAction';
import { IconDataSource, IconStar } from '../../../components/public/Icons';
import { CustomTooltip } from '../../../components/ui/CustomTooltip';
import MarketPlaceService from '../../../services/marketplace.service';
import CommonOptionsDropdown from './DataMarketActionDropdown';
import { useSelector } from 'react-redux';
import { saveAugumentationSourceSelector } from '../../../redux/selector/MarketplaceSelector';

type AugmentationSourceListTypes = {
  _id: string;
  serviceName: string;
  serviceType: string;
  serviceUrl: string;
  isPaidService: boolean;
  id: string;
  endpointCount: number;
  isSystemDefined: boolean;
};

type AugmentationSourceProps = {
  // eslint-disable-next-line
  updateSourceId: (data: string) => void;
  toggleDataItemModal: () => void;
};

const marketplaceService = new MarketPlaceService();

const AugmentationDataSources = ({
  toggleDataItemModal,
  updateSourceId,
}: AugmentationSourceProps) => {
  const { showModal, addAugumentationSourceCount } = useAction();

  const [augSourceList, setAugSourceList] = useState<
    Array<AugmentationSourceListTypes>
  >([]);

  const isSaveSourceData = useSelector(saveAugumentationSourceSelector);

  const getAllAugumentationSources = () => {
    marketplaceService
      .getAllSourceList('AUGMENTED_DATA_SOURCE')
      .then(({ data }) => {
        setAugSourceList(data);
        addAugumentationSourceCount(data?.length || 0);
      })
      .catch(err => {
        showModal(err?.response?.data?.message);
      });
  };

  useEffect(() => {
    getAllAugumentationSources();
  }, [isSaveSourceData]);
  return (
    <>
      {augSourceList?.map((augData: AugmentationSourceListTypes) => (
        <div className="col" key={augData?._id}>
          <div className="main-widget">
            <div className="react-grid-item">
              <div className="common-widget">
                <div className="common-widget-header">
                  <div className="common-widget-header-title">
                    <IconDataSource />
                    <h2>{augData?.serviceName}</h2>
                  </div>
                  <div className="common-widget-header-action">
                    <CommonOptionsDropdown
                      sourceId={augData.id}
                      toggleDataItemModal={toggleDataItemModal}
                      updateSourceId={updateSourceId}
                      deleteSuccess={() => {
                        getAllAugumentationSources();
                      }}
                      isSystemDefined={augData?.isSystemDefined}
                    />
                  </div>
                </div>
                <div className="common-widget-content">
                  <p className="gap-1">
                    {augData?.serviceType}
                    {augData?.isPaidService && (
                      <>
                        <span className="d-flex" id="tooltip1">
                          <IconStar />
                        </span>
                        <CustomTooltip placement="top" target="tooltip1" />
                      </>
                    )}
                  </p>

                  <div>
                    <p>Endpoints: {augData?.endpointCount}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default AugmentationDataSources;
