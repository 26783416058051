/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Input,
  InputGroup,
  InputGroupAddon,
} from 'reactstrap';
import { IconExclamationCircle } from '../public/Icons';

type SaveAsReportModalProps = {
  modal: boolean;
  toggle: () => void;
  onSave: (name: string, message: any) => void;
  message: any;
};

const SaveAsReportModal = ({
  modal,
  toggle,
  onSave,
  message,
}: SaveAsReportModalProps) => {
  const [name, setName] = useState('');
  const [isInvalid, setIsInvalid] = useState(false);
  const [editableMessage, setEditableMessage] = useState<string>('');

  useEffect(() => {
    if (modal) {
      setEditableMessage(message?.text || '');
    }
  }, [modal, message]);

  const handleSave = () => {
    if (name.trim()) {
      onSave(name, { ...message, text: editableMessage });
      toggle();
      setIsInvalid(false);
    } else {
      setIsInvalid(true);
    }
  };

  return (
    <Modal isOpen={modal} toggle={toggle} centered size="lg">
      <ModalHeader toggle={toggle}>Save as Report</ModalHeader>
      <ModalBody>
        <p>Please enter a name for this report</p>
        <InputGroup>
          <Input
            type="text"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
              if (e.target.value.trim()) setIsInvalid(false);
            }}
            placeholder="Enter report name"
            style={{
              borderColor: isInvalid ? 'red' : '',
            }}
          />
          {isInvalid && (
            <InputGroupAddon addonType="append">
              <IconExclamationCircle width={20} height={20} />
            </InputGroupAddon>
          )}
        </InputGroup>
        {isInvalid && (
          <p style={{ color: 'red', marginTop: '5px' }}>
            Please enter a valid name.
          </p>
        )}
        <div style={{ marginTop: '20px' }}>
          <p>Preview and Edit</p>
          <textarea
            style={{
              width: '100%',
              height: '150px',
              border: '1px solid #36567d',
              borderRadius: '4px',
              backgroundColor: 'transparent',
              padding: '10px',
              fontSize: '14px',
              color: '#fff',
              overflow: 'auto',
            }}
            value={editableMessage}
            onChange={(e) => setEditableMessage(e.target.value)}
            placeholder="Edit your message here..."
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
        <Button color="primary" onClick={handleSave}>
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default SaveAsReportModal;
