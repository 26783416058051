import { UncontrolledCollapse } from 'reactstrap';
import { JSX, useEffect, useState } from 'react';
// import ImgClaudeAI from '../../../../../../../assets/images/claudeai.png';
// import ImgGemini from '../../../../../../../assets/images/gemini.png';
// import ImgNemotron from '../../../../../../../assets/images/nemotron.png';
// import ImgOrca2 from '../../../../../../../assets/images/orca2.png';
import CustomSelect from '../../../../../../components/ui/CustomSelect';
import {
  IconDropdownArrow,
  IconInfo,
} from '../../../../../../components/public/Icons';
import { CustomTooltip } from '../../../../../../components/ui/CustomTooltip';
import useAction from '../../../../../../components/hooks/useAction';
import { useSelector } from 'react-redux';
import { userDetailsData } from '../../../../../../redux/selector/UserDetailsSelector';
import axios from 'axios';
import config from '../../../../../../../config';

type ModelListTypes = {
  _id: string;
  model_name: string;
  display_name: string;
};

// const roleOptions = [
//   {
//     value: 'cl',
//     label: (
//       <div className="select-img">
//         <img
//           src={ImgClaudeAI}
//           alt="ImgClaudeAI"
//           height="16"
//           width="16"
//           className="img-cover"
//         />
//         <span>ClaudeAI</span>
//       </div>
//     ),
//   },
//   {
//     value: 'ge',
//     label: (
//       <div className="select-img">
//         <img
//           src={ImgGemini}
//           alt="ImgGemini"
//           height="16"
//           width="16"
//           className="img-cover"
//         />
//         <span>Gemini</span>
//       </div>
//     ),
//   },
//   {
//     value: 'ne',
//     label: (
//       <div className="select-img">
//         <img
//           src={ImgNemotron}
//           alt="ImgNemotron"
//           height="16"
//           width="16"
//           className="img-cover"
//         />
//         <span>Nemotron</span>
//       </div>
//     ),
//   },
//   {
//     value: 'or',
//     label: (
//       <div className="select-img">
//         <img
//           src={ImgOrca2}
//           alt="ImgOrca2"
//           height="16"
//           width="16"
//           className="img-cover"
//         />
//         <span>Orca 2</span>
//       </div>
//     ),
//   },
// ];

const KnowledgeBaseItemsListing = () => {
  const { showModal } = useAction();

  const [modelList, setModelList] = useState<
    Array<{
      label: JSX.Element;
      value: string;
    }>
  >([]);
  const userDetails = useSelector(userDetailsData);

  const getFoundationalModelList = async () => {
    try {
      const response = await axios({
        method: 'get',
        url: `${config.ER_ML_URL}/llm/list`,
      });
      const responseManipulation: ModelListTypes[] = response?.data?.data;
      let updatedRes = responseManipulation?.map(x => {
        return {
          label: (
            <div className="select-img">
              {/* <img
                src={ImgOrca2}
                height="16"
                width="16"
                className="img-cover"
              /> */}
              <span>{x.display_name}</span>
            </div>
          ),
          value: x?.display_name,
        };
      });

      setModelList(updatedRes);
    } catch (error) {
      showModal('something went wrong');
    }
  };

  useEffect(() => {
    if (userDetails?._id) getFoundationalModelList();
  }, [userDetails?._id]);

  const SELECT_KNOWLEDGE_BASE = [
    {
      name: 'financial_report_2024.pdf',
    },
    {
      name: 'aviation_industry_report_2023.pdf',
    },
    {
      name: 'crypto_research_Q2-2024.docx',
    },
  ];

  // SELECT KNOWLEDGE BASE ITEMS
  const knowledgeList: any = [];
  SELECT_KNOWLEDGE_BASE.forEach((item, index) => {
    knowledgeList.push(
      <div className="col" key={index}>
        <div className="position-relative">
          <input type="checkbox" name="select-file" id={`toggler${index}`} />
          <div className="widget-card">
            <p>{item.name}</p>
          </div>
        </div>
        <UncontrolledCollapse toggler={`#toggler${index}`}>
          <div className="form-group mb-0 input-ai-tool">
            <textarea
              className="form-control"
              placeholder="Which data would you like to be extracted or summarized?"
              rows={3}
              name="summarized"
              defaultValue={
                'Extract the total revenue, net profit, and key expense categories from the financial report. Additionally, provide a summary of any notable financial trends or anomalies highlighted in the document.'
              }
            />
            {modelList?.length > 0 && (
              <div className="ai-tools">
                <div className="select-clear">
                  <CustomSelect
                    options={modelList}
                    value={modelList?.[0]}
                    isSearchable={false}
                    render
                  />
                  <div className="arrow-icon">
                    <IconDropdownArrow />
                  </div>
                </div>
              </div>
            )}
          </div>
        </UncontrolledCollapse>
      </div>,
    );
  });

  return (
    <>
      <div className="addwidget-content mw-100">
        <div className="row  justify-content-center">
          <div className="col-12 col-lg-6">
            <div className="d-flex align-items-center">
              <p className="widget-category me-1 mb-0">
                SELECT KNOWLEDGE BASE ITEMS
              </p>
              <span className="d-flex" id="tooltip14">
                <IconInfo />
              </span>
              <CustomTooltip placement="top" target="tooltip14" />
              <input
                type="file"
                className="d-none"
                id="uploadKnowledgeportfolio"
              />
              <label htmlFor="uploadKnowledgeportfolio" className="ms-3 link">
                Upload new file
              </label>
            </div>
            <div className="create-widget create-widget-portfolio">
              <div className="widget-item">
                <div className="row row-cols-1">{knowledgeList}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default KnowledgeBaseItemsListing;
