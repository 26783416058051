// Components
// Auth Guard
import AuthGuard from '../app/components/guards/AuthGuard';
import Setting from '../app/containers/client/settings/Settings';
import Dashboard from '../app/containers/client/dashboard/Dashboard';
import UpdateProfile from '../app/containers/auth/UpdateProfile';
import DataMarketplace from '../app/containers/client/marketplace/DataMarketplace';
import Newsfeed from '../app/containers/client/newsfeed/Newsfeed';
import Portfolio from '../app/containers/client/portfolio/Portfolio';
// import Product from '../app/containers/client/product/Product';
// import Trading from '../app/containers/client/trading/Trading';
import Home from '../app/containers/public/Home';
import UsersDetails from '../app/containers/client/settings/UsersDetails';

export const DefaultRoutes = [
  {
    path: '/',
    component: Home,
    children: null,
    guard: null,
  },
];

export const AfterLoginRoute = [
  {
    path: '/dashboard',
    component: Dashboard,
    children: null,
    guard: AuthGuard,
  },

  {
    path: '/portfolio',
    component: Portfolio,
    children: null,
    guard: AuthGuard,
  },
  {
    path: '/newsfeed',
    component: Newsfeed,
    children: null,
    guard: null,
  },

  {
    path: '/data/marketplace',
    component: DataMarketplace,
    children: null,
    guard: AuthGuard,
  },
  {
    path: '/update-profile',
    component: UpdateProfile,
    children: null,
    guard: null,
  },
  {
    path: '/settings',
    component: Setting,
    children: null,
    guard: null,
  },
  {
    path: '/users',
    component: UsersDetails,
    children: null,
    guard: null,
  },
];
