import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { userData } from '../../../redux/selector/UserSelector';
import ScrollableFeed from 'react-scrollable-feed';
import DummyProfile from '../../../../assets/images/dummy-profile.png';
import BackButton from '../../../../assets/images/Back.svg';

const Conversation = ({ socket, setWebSocketConnection, activeTab }: any) => {
  const user = useSelector(userData);
  const [WebSocket, setWebSocket] = useState(socket);
  const [connectionsList, setConnectionsList] = useState<any[]>([]);
  const [selectedUser, setSelectedUser] = useState<any>(null);
  const [inputText, setInputText] = useState('');
  const [messages, setMessages] = useState<any[]>([]);
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);
  const messagesEndRef = useRef<HTMLDivElement | null>(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  // Initialize WebSocket connection
  useEffect(() => {
    if (!WebSocket) {
      const newSocket = setWebSocketConnection();
      setWebSocket(newSocket);
    }

    return () => {
      if (WebSocket) WebSocket.disconnect();
    };
  }, [WebSocket, setWebSocketConnection]);

  // Manage WebSocket listeners
  useEffect(() => {
    if (WebSocket) {
      // Listen for connections list
      WebSocket.on('sendConnectionList', (data: any) => {
        if (data?.userDetails && Array.isArray(data.userDetails)) {
          setConnectionsList(data.userDetails);
        }
      });

      // Listen for conversation messages
      WebSocket.on('conversationMessages', (data: any) => {
        setMessages(data || []);
      });

      if (WebSocket && user?.user_id) {
        WebSocket.emit('join', { userId: user?.user_id });
      }

      // Listen for new messages
      WebSocket.on('newMessage', (data: any) => {
        if (data?.conversationId === selectedUser?.conversationId) {
          setMessages((prev) => [...prev, data]);
        }
      });

      return () => {
        WebSocket.off('sendConnectionList');
        WebSocket.off('conversationMessages');
        WebSocket.off('newMessage');
      };
    }
  }, [WebSocket, selectedUser]);

  // Fetch connections list
  useEffect(() => {
    if (WebSocket && user?.user_id) {
      WebSocket.emit('getConnectionsList', { userId: user?.user_id });
    }
  }, [WebSocket, user?.user_id]);

  const openChat = (connection: any) => {
    setSelectedUser(connection);

    WebSocket.emit('getConversationMessages', {
      userId: user?.user_id,
      recipientId: connection.user_id,
    });
  };

  const goBackToConnections = () => {
    setSelectedUser(null);
    setMessages([]);
  };

  const handleSendMessage = () => {
    if (!inputText.trim()) return;

    const newMessage = {
      senderId: user?.user_id,
      recipientId: selectedUser?.user_id,
      text: inputText,
      timestamp: new Date(),
    };

    WebSocket.emit('sendConversationMessage', newMessage);

    // Optimistic UI update
    setMessages((prev) => [...prev, newMessage]);

    setInputText('');
  };

  if (activeTab !== 'CONVERSATION') {
    return null;
  }

  return (
    <div className="conversation-container">
      {selectedUser ? (
        <div className="chat-screen">
          <div className="chat-header">
            <button className="back-button" onClick={goBackToConnections}>
              <img src={BackButton} alt="Back" className="back-icon" />
            </button>
            <div className="chat-user-details">
              <img src={DummyProfile} alt="Avatar" className="chat-avatar" />
              <div>
                <p className="chat-name">{selectedUser.name}</p>
                <p className="chat-email">{selectedUser.email}</p>
                <p className="chat-role">{selectedUser.userRole}</p>
              </div>
            </div>
          </div>
          <div className="chat-messages">
            {messages.length > 0 ? (
              messages.map((msg, index) => (
                <div
                  key={index}
                  className={`message-container ${msg.senderId === user?.user_id ? 'sent' : 'received'}`}
                >
                  <div
                    className={`message-box ${msg.senderId === user?.user_id ? 'sent' : 'received'}`}
                  >
                    <p className="message-text">{msg.text}</p>
                  </div>
                  <small className="message-time">
                    {new Date(msg.timestamp).toLocaleTimeString()}
                  </small>
                </div>
              ))
            ) : (
              <p>No messages yet. Start the conversation!</p>
            )}
            <div ref={messagesEndRef} />{' '}
          </div>

          <div className="keyboard-access flexbox">
            <textarea
              ref={textAreaRef}
              className="form-control"
              value={inputText}
              onChange={(e) => setInputText(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  handleSendMessage();
                }
              }}
              rows={1}
              placeholder="Enter your message here"
            />
            <button
              type="button"
              className="btn-chat-icon"
              onClick={handleSendMessage}
            >
              Send
            </button>
          </div>
        </div>
      ) : (
        <ScrollableFeed className="sw-content-conversation" forceScroll>
          <div className="receiver-mainbox-conversation message-box">
            {connectionsList.length > 0 ? (
              connectionsList.map((connection: any, index: number) => (
                <div
                  key={index}
                  className="connection-item chat-container"
                  onClick={() => openChat(connection)}
                >
                  <div className="avatar">
                    <img
                      src={DummyProfile}
                      alt="Avatar"
                      className="avatar-img"
                    />
                  </div>
                  <div className="chat-content">
                    <p className="name">{connection.name}</p>
                    <p className="email">{connection.email}</p>
                    <p className="role">{connection.userRole}</p>
                  </div>
                </div>
              ))
            ) : (
              <p>No connections found</p>
            )}
          </div>
        </ScrollableFeed>
      )}
    </div>
  );
};

export default Conversation;
