import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import { TabContent, TabPane } from 'reactstrap';
import { Iconclose } from '../../../../components/public/Icons';
import SelectWidgetType from './SelectWidgetType';
import SelectNewsSource from './newsfeedWidgetWorkflow/SelectNewsSource';
import SelectPortfoliosPerformance from './portfolioPerformanceWorkflow/SelectPortfoliosPerformance';
import SelectPortfolioCharts from './portfolioChartsWidget/SelectPortfolioCharts';
import NewsfeedHeadings from './widgetWorkflowHeadings/NewsfeedHeadings';
import PerformanceHeadings from './widgetWorkflowHeadings/PerformanceHeadings';
import ChartsHeadings from './widgetWorkflowHeadings/ChartsHeadings';
import SelectParametersCharts from './portfolioChartsWidget/SelectParametersCharts';
import useAction from '../../../../components/hooks/useAction';
import NewsService from '../../../../services/news.service';
import { useSelector } from 'react-redux';
import {
  allDashboardWidgetsData,
  dashboardNewsFeedWidgetsDataSelector,
  dashboardTasksWidgetDataSelector,
} from '../../../../redux/selector/DashboardWidgetSelector';
import SelectDefineMonitoring from './portfolioPerformanceWorkflow/SelectDefineMonitoring';
import config from '../../../../../config';
import axios from 'axios';
import {
  GetHeadersResponse,
  PerformanceHeader,
} from '../../../../../@types/DashboardLayout';
import { HeaderType } from '../../../../../@types/PortfolioType';
import PortfolioService from '../../../../services/portfolio.service';
import { isLoading } from '../../../../redux/selector/LoaderSelector';
import AppLoader from '../../../../components/loaders/AppLoader';
import SelectPortfolioValues from './portfolioChartsWidget/SelectPortfolioValues';
import { CHARTS } from '../../portfolio/Charts/constants';

type PortfolioTypes = {
  toggleDashboardWidget: () => void;
};

type NewsSourceListTypes = {
  _id: string;
  serviceName: string;
  serviceType: string;
  serviceUrl: string;
  isPaidService: boolean;
  id: string;
  endpointCount: number;
};

const newsService = new NewsService();
const portfolioService = new PortfolioService();

const AddDashboardWidget = ({ toggleDashboardWidget }: PortfolioTypes) => {
  // State for current active Tab
  const [currentActiveTab, setCurrentActiveTab] = useState('1');
  const [selectedWidget, setSelectedWidget] = useState<string>('');
  const [selectedPortfolio, setSelectedPortfolio] = useState<string[]>([]);
  const [performanceHeaders, setPerformanceHeaders] = useState<HeaderType[]>(
    [],
  );
  const [selectedBenchmark, setSelectedBenchmark] = useState<any>(null);
  const [monitoringHeaders, setMonitoringPortfolio] = useState([]);
  const [customWidgetName, setCustomWidgetName] = useState('');

  const loader = useSelector(isLoading);

  const [selectedRange, setSelectedRange] = useState('');

  const [isMainLoading, setIsMainLoading] = useState(false);
  const allDashboardWidgetReduxData = useSelector(allDashboardWidgetsData);

  const {
    showModal,
    addDashboardTasksData,
    addDashboardNewsFeedData,
    addDashboardPerformancePortfolioData,
    showLoading,
    hideLoading,
    addDashboardEmbeddedBrowserData,
    // removeAllEndDashboardNewsFeedData,removeDashboardPerformancePortfolioData
  } = useAction();
  const taskWidgetData = useSelector(dashboardTasksWidgetDataSelector);
  const newsDashboardWidgetReduxData = useSelector(
    dashboardNewsFeedWidgetsDataSelector,
  );
  const [newsSourceList, setNewsSourceList] = useState<
    Array<NewsSourceListTypes>
  >([]);

  const updateSelectedWidget = (val: string) => {
    setSelectedWidget(val);
  };

  const [
    selectedVisualisationPortfolio,
    setSelectedVisualisationPortfolio,
  ] = useState<string>('');

  const [chartFormValues, setChartFormValues] = useState<any>({});

  const handleChartFormChange = (values: any) => {
    setChartFormValues(values);
  };

  const [dataArray, setDataArray] = useState<any[]>([]); // Store dataArray in parent

  const handleDataArrayChange = (newDataArray: any[]) => {
    setDataArray(newDataArray);
  };

  const buttonText = () => {
    if (selectedWidget === 'TASK' && currentActiveTab === '1') {
      return 'Create Widget';
    } else if (selectedWidget === 'NEWSFEED' && currentActiveTab === '2') {
      return 'Create Widget';
    } else if (
      selectedWidget === 'PORTFOLIO_PERFORMANCE' &&
      currentActiveTab === '3'
    ) {
      return 'Create Widget';
    } else if (
      selectedWidget === 'PORTFOLIO_CHARTS' &&
      currentActiveTab === '4'
    ) {
      return 'Create Widget';
    } else {
      return 'Continue';
    }
  };

  const getChartsData = (chartFormValues?: any) => {
    try {
      setIsMainLoading(true);

      const chartsConfiguration = {
        ...chartFormValues,
        portfolioId: selectedVisualisationPortfolio,
        chartId: Date.now(),
        data: chartFormValues?.data || [],
      };

      addDashboardEmbeddedBrowserData({
        id: allDashboardWidgetReduxData?.length,
        value: 'embedded_browser',
        widgetType: 'DASHBOARD',
        customWidgetName: customWidgetName,

        ...chartsConfiguration,
      });

      toggleDashboardWidget();
      document.body.classList.remove('show-dataitem');

      setIsMainLoading(false);
    } catch (e) {
      setIsMainLoading(false);
      showModal('something went wrong');
    }
  };

  // Toggle active state for Tab
  const toggle = (tab: React.SetStateAction<string>) => {
    if (currentActiveTab !== tab) setCurrentActiveTab(tab);
  };

  const getAllNewsSources = () => {
    newsService
      .getNewsSourceList()
      .then(({ data }) => {
        setNewsSourceList(data);
      })
      .catch(err => {
        showModal(err?.response?.data?.message);
        hideLoading();
      });
  };

  useEffect(() => {
    getAllNewsSources();
  }, []);

  const getHeadersFiles = async () => {
    try {
      showLoading();
      const response = await axios.post<GetHeadersResponse>(
        `${config.ER_ML_URL}/statistical/get-headers`,
        {
          portfolio_ids: selectedPortfolio, // selectedPortfolio should be typed too
        },
      );

      // Convert response into an array of entries
      const dataArray = Object.entries(response?.data?.data) as [
        string,
        PerformanceHeader,
      ][];
      const options: any = dataArray.map(([key, header]) => ({
        value: key,
        label: key,
        rawData: header, // Add raw data for use
      }));

      setPerformanceHeaders(options);
      hideLoading();
    } catch (err) {
      hideLoading();
    }
  };

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  useEffect(() => {
    // Loop through the array to set the boolean value based on your condition
    const shouldDisable = monitoringHeaders?.some((item: any) => {
      if (item.isDefaultHeader) {
        // If it's a default header, check if isHide is true
        return item.isHide === false; // Or you could modify the item to ensure `isHide: true`
      } else {
        // If it's not a default header, check if monitoring array is missing or empty
        return !item.monitoring || item.monitoring.length === 0 || item.isHide;
      }
    });

    setIsButtonDisabled(shouldDisable);
  }, [monitoringHeaders]);
  return (
    <>
      {loader?.isLoading && <AppLoader title={''} />}
      {isMainLoading && <AppLoader title={''} />}
      <div className="addwidget-wrapper">
        <div className="sub-header justify-content-between">
          <h4 className="title-20">Add Dashboard Widget</h4>
          <button
            className="btn btn-icon p-0"
            onClick={() => {
              document.body.classList.remove('show-dataitem');
              toggleDashboardWidget();
            }}
          >
            <Iconclose />
          </button>
        </div>
        <div className="addwidget-main">
          <div className="container container-1024">
            <div className="addwidget-header">
              {currentActiveTab === '1' && (
                <h4 className="title-20">Select widget type</h4>
              )}
              {selectedWidget === 'NEWSFEED' && (
                <NewsfeedHeadings currentActiveTab={currentActiveTab} />
              )}

              {selectedWidget === 'PORTFOLIO_PERFORMANCE' && (
                <PerformanceHeadings currentActiveTab={currentActiveTab} />
              )}

              {selectedWidget === 'PORTFOLIO_CHARTS' && (
                <ChartsHeadings currentActiveTab={currentActiveTab} />
              )}

              <div className="tabs-h-menu"></div>
              {selectedWidget !== 'TASK' && (
                <ul className="addwidget-steps">
                  <li>
                    <button
                      className={classnames({
                        active: currentActiveTab === '1',
                      })}
                      // onClick={() => {
                      //   toggle('1');
                      // }}
                    >
                      1
                    </button>
                  </li>
                  <li>
                    <button
                      className={classnames({
                        active: currentActiveTab === '2',
                      })}
                      // onClick={() => {
                      //   toggle('2');
                      // }}
                    >
                      2
                    </button>
                  </li>
                  {selectedWidget !== 'NEWSFEED' && (
                    <li>
                      <button
                        className={classnames({
                          active: currentActiveTab === '3',
                        })}
                        // onClick={() => {
                        //   toggle('3');
                        // }}
                      >
                        3
                      </button>
                    </li>
                  )}
                  {selectedWidget == 'PORTFOLIO_CHARTS' && (
                    <li>
                      <button
                        className={classnames({
                          active: currentActiveTab === '4',
                        })}
                      >
                        4
                      </button>
                    </li>
                  )}
                </ul>
              )}
            </div>
            <TabContent activeTab={currentActiveTab}>
              <TabPane tabId="1">
                <SelectWidgetType
                  selectedWidget={selectedWidget}
                  updateSelectedWidget={updateSelectedWidget}
                  customWidgetName={customWidgetName}
                  setCustomWidgetName={setCustomWidgetName}
                />
              </TabPane>

              {selectedWidget === 'NEWSFEED' && (
                <TabPane tabId="2">
                  {currentActiveTab === '2' && (
                    <SelectNewsSource
                      newsSourceList={newsSourceList}
                      comeFrom={'isDashboard'}
                      isDashboard={true}
                    />
                  )}
                </TabPane>
              )}

              {selectedWidget === 'PORTFOLIO_PERFORMANCE' && (
                <>
                  <TabPane tabId="2">
                    <SelectPortfoliosPerformance
                      selectedPortfolio={selectedPortfolio}
                      setSelectedPortfolio={setSelectedPortfolio}
                    />
                  </TabPane>
                  <TabPane tabId="3">
                    <SelectDefineMonitoring
                      performanceHeaders={performanceHeaders}
                      setMonitoringPortfolio={setMonitoringPortfolio}
                      selectedBenchmark={selectedBenchmark}
                      setSelectedBenchmark={setSelectedBenchmark}
                      selectedRange={selectedRange}
                      setSelectedRange={setSelectedRange}
                    />
                  </TabPane>
                </>
              )}

              {selectedWidget === 'PORTFOLIO_CHARTS' && (
                <>
                  <TabPane tabId="2">
                    <SelectPortfolioCharts
                      setSelectedVisualisationPortfolio={
                        setSelectedVisualisationPortfolio
                      }
                    />
                  </TabPane>
                  <TabPane tabId="3">
                    <SelectPortfolioValues
                      currentActiveTab={currentActiveTab}
                      selectedVisualisationPortfolio={
                        selectedVisualisationPortfolio
                      }
                      onDataArrayChange={handleDataArrayChange}
                    />
                  </TabPane>
                  <TabPane tabId="4">
                    <SelectParametersCharts
                      dataArray={dataArray}
                      onFormChange={handleChartFormChange}
                    />
                  </TabPane>
                </>
              )}
            </TabContent>
          </div>
          <div className="gap-12 d-flex justify-content-center">
            <button
              className="btn btn-outline-info"
              onClick={() => {
                if (currentActiveTab === '1') {
                  toggleDashboardWidget();
                  document.body.classList.remove('show-dataitem');
                }

                toggle(String(Number(currentActiveTab) - 1));
              }}
            >
              {currentActiveTab === '1' ? 'Cancel' : 'Back'}
            </button>
            <button
              className="btn btn-primary"
              disabled={currentActiveTab === '3' && isButtonDisabled}
              onClick={async () => {
                if (selectedWidget === 'TASK') {
                  if (currentActiveTab === '1') {
                    addDashboardTasksData({
                      id: taskWidgetData?.length || 0,
                      value: 'task',
                      widgetType: 'DASHBOARD',
                      customWidgetName: customWidgetName,
                    });
                    document.body.classList.remove('show-dataitem');
                    toggleDashboardWidget();
                  }
                }

                if (selectedWidget === 'NEWSFEED') {
                  if (currentActiveTab !== '2') {
                    toggle(String(Number(currentActiveTab) + 1));
                  }

                  if (currentActiveTab === '2') {
                    if (newsDashboardWidgetReduxData?.length > 0) {
                      addDashboardNewsFeedData({
                        widgetType: 'NEWS_FEED',
                        newsArray: newsDashboardWidgetReduxData,
                        newsEventId: '',
                        q: '',
                        value: 'news',
                        customWidgetName: customWidgetName,
                      });
                      toggleDashboardWidget();
                      // removeAllEndDashboardNewsFeedData();
                      document.body.classList.remove('show-dataitem');
                    }
                  }
                }
                if (selectedWidget === 'PORTFOLIO_PERFORMANCE') {
                  if (currentActiveTab === '2') {
                    await getHeadersFiles();
                    // await getFileNamesFromId();
                  }
                  if (currentActiveTab !== '3') {
                    toggle(String(Number(currentActiveTab) + 1));
                  }

                  if (currentActiveTab === '3') {
                    showLoading();
                    const payload = {
                      portfolio_ids: selectedPortfolio,
                      service_id: selectedBenchmark?.value,
                      headers: monitoringHeaders
                        ?.filter((i: any) => i?.operation)
                        ?.map((i: any) => {
                          // eslint-disable-next-line no-unused-vars
                          const { value, name, ...rest } = i; // Destructure to remove `value` and extract `label`
                          return {
                            ...rest,
                            name: name,
                          };
                        }),
                    };

                    if (selectedRange !== 'external_benchmark') {
                      delete payload.service_id;
                    }
                    await portfolioService
                      .saveHeaders(payload)
                      .then(({ data }) => {
                        toggleDashboardWidget();
                        document.body.classList.remove('show-dataitem');
                        addDashboardPerformancePortfolioData({
                          id: data?._id,
                          header_id: data?._id,
                          widgetType: 'DASHBOARD',
                          selectedPortfolio: selectedPortfolio,
                          value: 'performance',
                          customWidgetName: customWidgetName,
                        });
                        hideLoading();
                      })
                      .catch(err => {
                        showModal(err?.response?.data?.message);
                        hideLoading();
                      });
                  }
                }

                if (selectedWidget === 'PORTFOLIO_CHARTS') {
                  if (currentActiveTab !== '3') {
                    toggle(String(Number(currentActiveTab) + 1));
                  }

                  if (currentActiveTab === '3') {
                    toggle(String(Number(currentActiveTab) + 1));
                    // toggleDashboardWidget();
                  }

                  if (currentActiveTab === '4') {
                    // const chartType =
                    //   chartFormValues?.visualisationType?.value[0] +
                    //   chartFormValues?.visualisationType?.value
                    //     .slice(1)
                    //     .toLowerCase();

                    if (
                      chartFormValues?.visualisationType?.value ===
                        CHARTS.BAR ||
                      chartFormValues?.visualisationType?.value ===
                        CHARTS.BUBBLE ||
                      chartFormValues?.visualisationType?.value ===
                        CHARTS.LINE ||
                      chartFormValues?.visualisationType?.value ===
                        CHARTS.PIE ||
                      chartFormValues?.visualisationType?.value ===
                        CHARTS.RADAR ||
                      chartFormValues?.visualisationType?.value ===
                        CHARTS.AREA ||
                      chartFormValues?.visualisationType?.value ===
                        CHARTS.SCATTER ||
                      chartFormValues?.visualisationType?.value ===
                        CHARTS.WATERFALL ||
                      chartFormValues?.visualisationType?.value ===
                        CHARTS.STACKED ||
                      chartFormValues?.visualisationType?.value ===
                        CHARTS.HEATMAP
                    ) {
                      if (chartFormValues.fields) {
                        getChartsData(chartFormValues);
                      }
                    }
                  }
                }
              }}
            >
              {buttonText()}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddDashboardWidget;
