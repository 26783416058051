/* ACTIONS */
import { rowSelectionConstant } from '../constant';

const { ADD_ROW_SELECTION_TYPE } = rowSelectionConstant;

export const addRowSelectionType = (data: any) => {
  return {
    type: ADD_ROW_SELECTION_TYPE,
    payload: {
      rowSelectionType: data,
    },
  };
};
