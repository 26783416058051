/* ACTIONS */
import { selectedModelConstant } from '../constant';

const { ADD_SELECTED_MODEL } = selectedModelConstant;

export const addSelectedModel = (data: any) => {
  return {
    type: ADD_SELECTED_MODEL,
    payload: {
      selectedModel: data,
    },
  };
};
