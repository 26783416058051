import { useEffect, useState } from 'react';
import {
  EndpointListTypes,
  NewsSourceListTypes,
} from '../../../../../@types/NewsWidgetType';
import MarketPlaceService from '../../../../services/marketplace.service';
import useAction from '../../../../components/hooks/useAction';
import { useDispatch, useSelector } from 'react-redux';
import {
  IconMinusCircle,
  IconPlusCircle,
  IconStar,
} from '../../../../components/public/Icons';
import { CustomTooltip } from '../../../../components/ui/CustomTooltip';
import { Collapse } from 'reactstrap';
import { selectEndNewsPoint } from '../../../../redux/actions';
import { selectedNewsEndpointSelector } from '../../../../redux/selector/NewsFeedWidgetsSelector';
import DataLoader from '../../../../components/loaders/DataLoader';

type EndpointCollapseTypes = {
  sourceData: NewsSourceListTypes;
  isNewFeed?: boolean;
  isSingleCheck?: boolean;
  selectedId?: string;
  selectedSourceDataId?: string | undefined;
};

const marketplaceService = new MarketPlaceService();

const EndpointNewsCollapse = ({
  sourceData,
  selectedSourceDataId,
}: EndpointCollapseTypes) => {
  const dispatch = useDispatch();
  const { showModal } = useAction();
  const [isLoading, setIsLoading] = useState(false);
  const [isCollapseOpen, setIsCollapseOpen] = useState(false);
  const selectedEndpoint = useSelector(selectedNewsEndpointSelector);

  const [selectedDataSourceId, setSelectedDataSourceId] = useState(
    selectedSourceDataId || '',
  );
  const [dataSourceEndpoints, setDataSourceEndpoints] = useState<
    Array<EndpointListTypes>
  >([]);

  const toggleCollapse = () => setIsCollapseOpen(!isCollapseOpen);

  const getDataSourceEndpoints = (id: string) => {
    setIsLoading(true);

    const payload = {
      sourceId: id,
      q: '',
      isServiceActiveRequired: true,
    };
    marketplaceService
      .getAllServicesOfSources(payload)
      .then(({ data }) => {
        setDataSourceEndpoints(data);
        setIsLoading(false);
      })
      .catch(err => {
        setIsLoading(false);
        showModal(err?.response?.data?.error);
      });
  };

  useEffect(() => {
    if (selectedDataSourceId) {
      getDataSourceEndpoints(selectedDataSourceId);
    }
  }, [selectedDataSourceId]);

  const handleEndpointSelection = (endpoint: EndpointListTypes) => {
    const modifiedEndpoint = {
      ...endpoint,
      sourceId: sourceData?.id,
      sourceName: sourceData?.serviceName,
    };

    dispatch(selectEndNewsPoint(modifiedEndpoint));
  };

  useEffect(() => {
    if (selectedSourceDataId === sourceData?.id) {
      setIsCollapseOpen(true);
    }
  }, [selectedSourceDataId, sourceData?.id]);

  return (
    <div className="col" key={sourceData.id}>
      <input
        type="checkbox"
        name=""
        onClick={() => {
          toggleCollapse();
          setSelectedDataSourceId(sourceData?.id);
        }}
        className="showonly"
      />
      {/* Head part */}
      <div className="widget-card">
        <div className="flexbox-c gap-6">
          <button className="btn-clear btn-icon btn-16">
            {isCollapseOpen ? <IconMinusCircle /> : <IconPlusCircle />}
          </button>
          <p>{sourceData?.serviceName}</p>
          {sourceData?.isPaidService && (
            <>
              <span className="d-flex zindex-1" id="tooltip103">
                <IconStar />
              </span>
              <CustomTooltip placement="top" target="tooltip103" />
            </>
          )}
        </div>
      </div>

      {/* List part for each head */}
      {sourceData?.id === selectedDataSourceId && (
        <Collapse isOpen={isCollapseOpen}>
          {isLoading ? (
            <DataLoader title={''} />
          ) : dataSourceEndpoints?.length > 0 ? (
            dataSourceEndpoints?.map((endpointData: EndpointListTypes) => {
              return (
                <div className="collapse-item" key={endpointData?._id}>
                  <input
                    type={'radio'}
                    name={`sub-options-${sourceData.id}`}
                    onChange={() => handleEndpointSelection(endpointData)}
                    checked={endpointData?.id === selectedEndpoint?.id}
                  />
                  <div className="widget-card">
                    <div className="flexbox-c gap-6">
                      <p>{endpointData?.endpointName}</p>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="collapse-item" key="endpoint-not-available">
              <div className="widget-card">
                <div className="flexbox-c gap-6">
                  <p>Endpoints not available</p>
                </div>
              </div>
            </div>
          )}
        </Collapse>
      )}
    </div>
  );
};

export default EndpointNewsCollapse;
