import { Link, useSearchParams } from 'react-router-dom';
import {
  IconError,
  IconInfo,
  IconLock,
  IconLeftArrowAngle,
} from '../../../components/public/Icons';
import PublicHeader from '../../../components/public/PublicHeader';
import CustomSelect from '../../../components/ui/CustomSelect';
import SettingsService from '../../../services/settings.service';
import { useEffect, useState } from 'react';
import {
  creditsOptions,
  functionsOptions,
  isAxiosError,
  positionOptions,
  productsOptions,
  sharingOptions,
} from '../../../redux/constant';
import useAction from '../../../components/hooks/useAction';
import {
  DepartmentData,
  UserByIdData,
  UserByIdResponse,
} from '../../../../@types/SettingsType';
import AppLoader from '../../../components/loaders/AppLoader';
import { useSelector } from 'react-redux';
import { userDetailsData } from '../../../redux/selector/UserDetailsSelector';
import { roleOptions } from '../../../redux/constant/setting.constant';
import { LicenseData } from '../../../../@types/LicenseType';
import LicenseService from '../../../services/license.service';
import { sexOptions, typesOptions } from '../../public/forms.constant';
import { LabelValue } from '../../../../@types/UserProfileTypes';
import ReactCreatableSelect from '../../../components/ui/ReactCreatableSelect';

const settingsService = new SettingsService();
const licenseService = new LicenseService();

const UsersDetails = () => {
  const { showModal } = useAction();
  const [searchParams] = useSearchParams();
  const userData = useSelector(userDetailsData);
  const [isLoading, setIsLoading] = useState(false);
  const [isDataChanged, setIsDataChanged] = useState(false);
  const [userDetails, setUserDetails] = useState<UserByIdData | null>(null);
  const [allLicenses, setAllLicenses] = useState<Array<LicenseData> | null>(
    null,
  );
  const [allDepartments, setAllDepartments] = useState<Array<
    DepartmentData
  > | null>(null);
  const [selectedDepartment, setSelectedDepartment] = useState<{
    label: string;
    value: string;
  } | null>(null);

  const [selectedRole, setSelectedRole] = useState<{
    label: string;
    value: string;
  } | null>(null);
  const [selectedLicense, setSelectedLicense] = useState<{
    label: string;
    value: string;
  } | null>(null);

  const [selectedSex, setSelectedSex] = useState<{
    label: string;
    value: string;
  } | null>(null);
  const [selectedPosition, setSelectedPosition] = useState<{
    label: string;
    value: string;
  } | null>(null);
  const [selectedTypes, setSelectedTypes] = useState<{
    label: string;
    value: string;
  } | null>(null);

  const [selectedProducts, setSelectedProducts] = useState<Array<{
    label: string;
    value: string;
  }> | null>(null);
  const [selectedFunctions, setSelectedFunctions] = useState<Array<{
    label: string;
    value: string;
  }> | null>(null);
  const [selectedCredits, setSelectedCredits] = useState<Array<{
    label: string;
    value: string;
  }> | null>(null);
  const [selectedSharingDepartment, setSelectedSharingDepartment] = useState<
    | {
        label: string;
        value: string;
      }[]
    | null
  >(null);
  const [selectedSharingEmail, setSelectedSharingEmail] = useState<
    | {
        label: string;
        value: string;
      }[]
    | null
  >(null);

  // Dashboard widgets permission
  const [newsWidget, setNewsWidget] = useState({
    value: false,
    disable: false,
  });
  const [taskWidget, setTaskWidget] = useState({
    value: false,
    disable: false,
  });
  const [
    myPortfolioPerformanceWidget,
    setMyPortfolioPerformanceWidget,
  ] = useState({ value: false, disable: false });
  const [myPortfolioChartsWidget, setMyPortfolioChartsWidget] = useState({
    value: false,
    disable: false,
  });
  const [myPortfolioAlertsWidget, setMyPortfolioAlertsWidget] = useState({
    value: false,
    disable: false,
  });
  //Portfolio Widgets
  const [creditWidget, setCreditWidget] = useState({
    value: false,
    disable: false,
  });
  const [nplWidget, setNplWidget] = useState({ value: false, disable: false });
  const [loanWidget, setLoanWidget] = useState({
    value: false,
    disable: false,
  });
  const [receivableWidget, setReceivableWidget] = useState({
    value: false,
    disable: false,
  });
  const [visualizationWidget, setVisualizationWidget] = useState({
    value: false,
    disable: false,
  });

  const [collaboration, setCollaboration] = useState({
    label: 'Allowed',
    value: 'Allowed',
  });
  const [externalSharing, setExternalSharing] = useState({
    label: 'Allowed',
    value: 'Allowed',
  });

  const getUserById = async () => {
    setIsLoading(true);
    try {
      const payload = {
        userId: searchParams.get('userId') as string,
        organizationId: userData?.organizationId,
      };
      const response: UserByIdResponse = await settingsService.getUserById(
        payload,
      );

      const filteredRole = roleOptions.find(
        option => option.value === response?.data?.userRole,
      );
      const filteredSex = {
        label: response?.data?.userDetail?.sex,
        value: response?.data?.userDetail?.sex,
      };
      const filteredPosition = {
        label: response?.data?.userDetail?.position,
        value: response?.data?.userDetail?.position,
      };
      const filteredType = {
        label: response?.data?.userDetail?.type,
        value: response?.data?.userDetail?.type,
      };
      const filteredProducts = response?.data?.userDetail?.products.map(val => {
        return { label: val, value: val };
      });

      const filteredFunctions = response?.data?.userDetail?.function.map(
        val => {
          return { label: val, value: val };
        },
      );

      const filteredCredits = response?.data?.userDetail?.credits.map(val => {
        return { label: val, value: val };
      });
      setSelectedRole(filteredRole as { label: string; value: string });
      setSelectedSex(filteredSex);
      setSelectedPosition(filteredPosition);
      setSelectedTypes(filteredType);
      setSelectedProducts(filteredProducts);
      setSelectedFunctions(filteredFunctions);
      setSelectedCredits(filteredCredits);

      getAllDepartmentsData(response?.data);
      getAllLicensesData(response?.data);

      getUserPermissionsData(response?.data?.userDetail?.license?.id);
      getUsersSharingPermissions(response?.data?.user_id);

      setUserDetails(response.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if (isAxiosError(error)) {
        showModal(
          (error.response?.data as { error?: string })?.error ||
            'Unexpected Axios error',
        );
      } else {
        showModal('An unexpected error occurred');
      }
    }
  };

  const getAllLicensesData = async (userDetailsObj: UserByIdData) => {
    setIsLoading(true);
    try {
      const response = await licenseService.getAllLicenses();
      setIsLoading(false);

      const filteredLicense =
        response?.data &&
        response?.data
          .map(user => ({
            label:
              user.licenseType.charAt(0).toUpperCase() +
              user.licenseType.slice(1).toLowerCase(),
            value: user.licenseId,
          }))
          .find(
            option => option.value === userDetailsObj?.userDetail?.licenseId,
          );

      setSelectedLicense(filteredLicense as { label: string; value: string });

      setAllLicenses(response?.data);
    } catch (error) {
      setIsLoading(false);

      if (isAxiosError(error)) {
        showModal(
          (error.response?.data as { error?: string })?.error ||
            'Unexpected Axios error',
        );
      } else {
        showModal('An unexpected error occurred');
      }
    }
  };

  // All department data get function
  const getAllDepartmentsData = async (userDetailsObj: UserByIdData) => {
    setIsLoading(true);
    try {
      const response = await settingsService.getAllDepartments(
        userData?.organizationId,
      );
      setIsLoading(false);

      const filteredDepartment = response?.data
        ?.map(item => ({
          label: item?.department,
          value: item?.id,
        }))
        ?.find(
          option => option.value === userDetailsObj?.userDetail?.department?.id,
        );

      setSelectedDepartment(
        filteredDepartment as { label: string; value: string },
      );

      setAllDepartments(response?.data);
    } catch (error) {
      setIsLoading(false);

      if (isAxiosError(error)) {
        showModal(
          (error.response?.data as { error?: string })?.error ||
            'Unexpected Axios error',
        );
      } else {
        showModal('An unexpected error occurred');
      }
    }
  };
  const getUserPermissionsData = async (licenseId: string) => {
    setIsLoading(true);
    try {
      const response = await settingsService.getUserPermissions(
        licenseId,
        userData?.user_id,
      );
      setIsLoading(false);

      setNewsWidget({
        value: response?.data?.permissions?.news_widget?.status,
        disable: response?.data?.permissions?.news_widget?.isLocked,
      });
      setTaskWidget({
        value: response?.data?.permissions?.task_widget?.status,
        disable: response?.data?.permissions?.task_widget?.isLocked,
      });
      setMyPortfolioPerformanceWidget({
        value:
          response?.data?.permissions?.my_portfolio_performance_widget?.status,
        disable:
          response?.data?.permissions?.my_portfolio_performance_widget
            ?.isLocked,
      });
      setMyPortfolioChartsWidget({
        value: response?.data?.permissions?.my_portfolio_charts_widget?.status,
        disable:
          response?.data?.permissions?.my_portfolio_charts_widget?.isLocked,
      });
      setMyPortfolioAlertsWidget({
        value: response?.data?.permissions?.my_portfolio_alerts_widget?.status,
        disable:
          response?.data?.permissions?.my_portfolio_alerts_widget?.isLocked,
      });

      setCreditWidget({
        value: response?.data?.permissions?.credit_widget?.status,
        disable: response?.data?.permissions?.credit_widget.isLocked,
      });
      setNplWidget({
        value: response?.data?.permissions?.nlp_financials_widget?.status,
        disable: response?.data?.permissions?.nlp_financials_widget?.isLocked,
      });
      setLoanWidget({
        value: response?.data?.permissions?.loan_widget?.status,
        disable: response?.data?.permissions?.loan_widget?.isLocked,
      });
      setReceivableWidget({
        value: response?.data?.permissions?.receivables_widget?.status,
        disable: response?.data?.permissions?.receivables_widget?.isLocked,
      });
      setVisualizationWidget({
        value: response?.data?.permissions?.visualisation_widget?.status,
        disable: response?.data?.permissions?.visualisation_widget?.isLocked,
      });
    } catch (error) {
      setIsLoading(false);

      if (isAxiosError(error)) {
        showModal(
          (error.response?.data as { error?: string })?.error ||
            'Unexpected Axios error',
        );
      } else {
        showModal('An unexpected error occurred');
      }
    }
  };

  const getUsersSharingPermissions = async (userId: string) => {
    setIsLoading(true);
    try {
      const response = await settingsService.getUserSharingPermissions(userId);
      setIsLoading(false);

      const filteredDepartmentCollaboration = response?.data?.departmentCollaborationWhitelist?.map(
        x => {
          return {
            label: x,
            value: x,
          };
        },
      );

      const filteredSharingEmail = response?.data?.externalSharingEmailWhitelist?.map(
        x => {
          return {
            label: x,
            value: x,
          };
        },
      );

      setCollaboration({
        label: response?.data?.sendWidgetAsTask,
        value: response?.data?.sendWidgetAsTask,
      });
      setExternalSharing({
        label: response?.data?.shareDataExternally,
        value: response?.data?.shareDataExternally,
      });
      setSelectedSharingDepartment(filteredDepartmentCollaboration);
      setSelectedSharingEmail(filteredSharingEmail);
    } catch (error) {
      setIsLoading(false);

      if (isAxiosError(error)) {
        showModal(
          (error.response?.data as { error?: string })?.error ||
            'Unexpected Axios error',
        );
      } else {
        showModal('An unexpected error occurred');
      }
    }
  };

  const handleSaveSharingPermissions = async () => {
    setIsLoading(true);
    try {
      const payload = {
        sendWidgetAsTask: collaboration.value,
        departmentCollaborationWhitelist: selectedSharingDepartment?.map(
          x => x.value,
        ) as string[],
        shareDataExternally: externalSharing.value,
        externalSharingEmailWhitelist: selectedSharingEmail?.map(
          x => x.value,
        ) as string[],
      };
      await settingsService.updateUserSharingPermissions(
        payload,
        userDetails?.user_id as string,
      );
      getUsersSharingPermissions(userDetails?.user_id as string);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if (isAxiosError(error)) {
        showModal(
          (error.response?.data as { error?: string })?.error ||
            'Unexpected Axios error',
        );
      } else {
        showModal('An unexpected error occurred');
      }
    }
  };

  const handleSaveUserPermissions = async () => {
    setIsLoading(true);
    try {
      const payload = {
        permissions: {
          news_widget: newsWidget.value || false,
          task_widget: taskWidget.value || false,
          my_portfolio_performance_widget:
            myPortfolioPerformanceWidget.value || false,
          my_portfolio_charts_widget: myPortfolioChartsWidget.value || false,
          my_portfolio_alerts_widget: myPortfolioAlertsWidget.value || false,
          credit_widget: creditWidget.value || false,
          visualisation_widget: visualizationWidget.value || false,
          nlp_financials_widget: nplWidget.value || false,

          receivables_widget: receivableWidget.value || false,

          loan_widget: loanWidget.value || false,

          nlp_collateral_widget: true,
          nlp_load_widget: true,
          receivables_collateral_widget: true,
          receivables_loan_widget: true,
          loan_financials_widget: true,
          loan_collateral_widget: true,
        },
        userId: userData.user_id,
      };
      await settingsService.updateUserPermissions(
        payload,
        userDetails?.userDetail?.license?.id as string,
      );
      getUserPermissionsData(userDetails?.userDetail?.license?.id as string);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if (isAxiosError(error)) {
        showModal(
          (error.response?.data as { error?: string })?.error ||
            'Unexpected Axios error',
        );
      } else {
        showModal('An unexpected error occurred');
      }
    }
  };

  const handleSaveUserDetails = async () => {
    setIsLoading(true);
    try {
      const payload = {
        userId: searchParams.get('userId') as string,
        userRole: selectedRole?.value as string,
        departmentId: selectedDepartment?.value as string,
        licenseId: selectedLicense?.value as string,
        position: selectedPosition?.value as string,
        type: selectedTypes?.value as string,
        sex: selectedSex?.value as string,
        functions: selectedFunctions?.map(x => x.value) as string[],
        products: selectedProducts?.map(x => x.value) as string[],
        credits: selectedCredits?.map(x => x.value) as string[],
      };
      await settingsService.updateUserDetails(payload);
      getUserById();

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if (isAxiosError(error)) {
        showModal(
          (error.response?.data as { error?: string })?.error ||
            'Unexpected Axios error',
        );
      } else {
        showModal('An unexpected error occurred');
      }
    }
  };

  useEffect(() => {
    if (userData?.organizationId) {
      getUserById();
    }
  }, [userData?.organizationId]);

  return (
    <>
      {isLoading && <AppLoader />}

      <div className="page-content">
        <PublicHeader />
        <div className="page-middle-content">
          <div className="container">
            <div className="main-widget pe-0">
              <div className="react-grid-item">
                <div className="common-widget show-cw-footer">
                  <div className="common-widget-header">
                    <div className="common-widget-header-title">
                      <h2 className="me-10">Settings</h2>
                    </div>
                  </div>
                  <div className="common-widget-content overflow-hidden">
                    <div className="page-back">
                      <Link to={'/settings'} className="link flexbox-c gap-1">
                        <IconLeftArrowAngle width={16} height={16} />
                        Back to Users
                      </Link>
                    </div>
                    <div className="user-details">
                      <p className="user-name">
                        {userDetails?.firstName} {userDetails?.middleName}{' '}
                        {userDetails?.lastName}{' '}
                      </p>
                      <p className="user-email">{userDetails?.email}</p>
                    </div>
                    <div className="user-form ud-scroll">
                      <div className="user-form-item">
                        <h5 className="fi-heading">Organisation</h5>
                        <div className="row">
                          <div className="col-12 col-lg-10 offset-2">
                            <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3">
                              <div className="col">
                                <div className="form-group">
                                  <label className="form-label">Role</label>
                                  <CustomSelect
                                    options={roleOptions}
                                    isSearchable={false}
                                    value={selectedRole}
                                    onChange={(e: any) => {
                                      setSelectedRole(e);
                                      setIsDataChanged(true);
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col">
                                <div className="form-group">
                                  <label className="form-label">
                                    Department
                                  </label>
                                  <CustomSelect
                                    options={
                                      allDepartments &&
                                      allDepartments?.map(item => ({
                                        label: item?.department,
                                        value: item?.id,
                                      }))
                                    }
                                    placeholder={'All Departments'}
                                    value={selectedDepartment}
                                    onChange={(e: any) => {
                                      setSelectedDepartment(e);
                                      setIsDataChanged(true);
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col">
                                <div className="form-group">
                                  <label className="form-label">
                                    Organisation Name
                                  </label>
                                  <p className="form-data">
                                    {
                                      userDetails?.userDetail?.organization
                                        ?.organizationName
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className="col">
                                <div className="form-group">
                                  <label className="form-label">License</label>
                                  <CustomSelect
                                    options={
                                      allLicenses &&
                                      allLicenses?.map(user => {
                                        const formattedLicenseType =
                                          user?.licenseType
                                            ?.charAt(0)
                                            ?.toUpperCase() +
                                          user?.licenseType
                                            ?.slice(1)
                                            ?.toLowerCase();

                                        return {
                                          label:
                                            user.licenseType === 'BASIC'
                                              ? formattedLicenseType
                                              : `${formattedLicenseType} (${user?.availableLicenses} available)`,
                                          value: user.licenseId,
                                        };
                                      })
                                    }
                                    placeholder={''}
                                    isSearchable={false}
                                    value={selectedLicense}
                                    onChange={(e: {
                                      label: string;
                                      value: string;
                                    }) => {
                                      setSelectedLicense(e);
                                      setIsDataChanged(true);
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="user-form-item">
                        <h5 className="fi-heading">User Profile</h5>
                        <div className="row">
                          <div className="col-12 col-lg-10 offset-2">
                            <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4">
                              <div className="col">
                                <div className="form-group">
                                  <label className="form-label">Gender</label>
                                  <CustomSelect
                                    options={sexOptions}
                                    value={selectedSex}
                                    onChange={(e: {
                                      label: string;
                                      value: string;
                                    }) => {
                                      setSelectedSex(e);
                                      setIsDataChanged(true);
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col">
                                <div className="form-group">
                                  <label className="form-label">Position</label>
                                  <CustomSelect
                                    options={positionOptions}
                                    value={selectedPosition}
                                    onChange={(e: {
                                      label: string;
                                      value: string;
                                    }) => {
                                      setSelectedPosition(e);
                                      setIsDataChanged(true);
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col">
                                <div className="form-group">
                                  <label className="form-label">Type</label>
                                  <CustomSelect
                                    options={typesOptions}
                                    value={selectedTypes}
                                    onChange={(e: {
                                      label: string;
                                      value: string;
                                    }) => {
                                      setSelectedTypes(e);
                                      setIsDataChanged(true);
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col">
                                <div className="form-group">
                                  <label className="form-label">
                                    Last active
                                  </label>
                                  <p className="form-data">
                                    {userDetails?.userDetail?.lastActive ||
                                      'N/A'}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="row row-cols-1 row-cols-lg-2">
                              <div className="col">
                                <div className="form-group">
                                  <label className="form-label">Products</label>
                                  <CustomSelect
                                    isMulti
                                    options={productsOptions}
                                    value={selectedProducts}
                                    placeholder={'Choose'}
                                    onChange={(
                                      selectedOptions: LabelValue[],
                                    ) => {
                                      setSelectedProducts(selectedOptions);
                                      setIsDataChanged(true);
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col">
                                <div className="form-group">
                                  <label className="form-label">
                                    Functions
                                  </label>
                                  <CustomSelect
                                    isMulti
                                    options={functionsOptions}
                                    value={selectedFunctions}
                                    placeholder={'Choose'}
                                    onChange={(
                                      selectedOptions: LabelValue[],
                                    ) => {
                                      setSelectedFunctions(selectedOptions);
                                      setIsDataChanged(true);
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col">
                                <div className="form-group">
                                  <label className="form-label">Credits</label>
                                  <CustomSelect
                                    isMulti
                                    options={creditsOptions}
                                    value={selectedCredits}
                                    placeholder={'Choose'}
                                    onChange={(
                                      selectedOptions: LabelValue[],
                                    ) => {
                                      setSelectedCredits(selectedOptions);
                                      setIsDataChanged(true);
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="user-form-item">
                        <h5 className="fi-heading">Permissions</h5>
                        <div className="row">
                          <div className="col-12 col-lg-10 offset-2">
                            <div className="row">
                              <div className="col-4">
                                <div className="form-group">
                                  <div className="flexbox-c gap-2 mb-2">
                                    <label className="form-label">
                                      Dashboard Widgets
                                    </label>
                                    <button
                                      type="button"
                                      className="btn-clear link mb-6"
                                      onClick={() => {
                                        setNewsWidget({
                                          ...newsWidget,
                                          value: !newsWidget.value,
                                        });
                                        if (!taskWidget.disable) {
                                          setTaskWidget({
                                            ...newsWidget,
                                            value: !newsWidget.value,
                                          });
                                        }
                                        if (
                                          !myPortfolioPerformanceWidget.disable
                                        ) {
                                          setMyPortfolioPerformanceWidget({
                                            ...newsWidget,
                                            value: !newsWidget.value,
                                          });
                                        }
                                        if (!myPortfolioChartsWidget.disable) {
                                          setMyPortfolioChartsWidget({
                                            ...newsWidget,
                                            value: !newsWidget.value,
                                          });
                                        }
                                        if (!myPortfolioAlertsWidget.disable) {
                                          setMyPortfolioAlertsWidget({
                                            ...newsWidget,
                                            value: !newsWidget.value,
                                          });
                                        }
                                        setIsDataChanged(true);
                                      }}
                                    >
                                      Select All/None
                                    </button>
                                  </div>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="newsWidgetPermission"
                                      checked={newsWidget.value}
                                      disabled={newsWidget.disable}
                                      onChange={() => {
                                        setNewsWidget({
                                          ...newsWidget,
                                          value: !newsWidget.value,
                                        });
                                        setIsDataChanged(true);
                                      }}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="newsWidgetPermission"
                                    >
                                      News Widget
                                      {newsWidget.disable && (
                                        <span className="ms-1 d-flex">
                                          <IconLock />
                                        </span>
                                      )}
                                    </label>
                                  </div>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="taskWidgetPermission"
                                      checked={taskWidget.value}
                                      disabled={taskWidget.disable}
                                      onChange={() => {
                                        setTaskWidget({
                                          ...taskWidget,
                                          value: !taskWidget.value,
                                        });
                                        setIsDataChanged(true);
                                      }}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="taskWidgetPermission"
                                    >
                                      Task Widget
                                      {taskWidget.disable && (
                                        <span className="ms-1 d-flex">
                                          <IconLock />
                                        </span>
                                      )}
                                    </label>
                                  </div>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="myPortfolioPerformanceWidgetPermission"
                                      checked={
                                        myPortfolioPerformanceWidget.value
                                      }
                                      disabled={
                                        myPortfolioPerformanceWidget.disable
                                      }
                                      onChange={() => {
                                        setMyPortfolioPerformanceWidget({
                                          ...myPortfolioPerformanceWidget,
                                          value: !myPortfolioPerformanceWidget.value,
                                        });
                                        setIsDataChanged(true);
                                      }}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="myPortfolioPerformanceWidgetPermission"
                                    >
                                      My Portfolio Performance Widget
                                      {myPortfolioPerformanceWidget.disable && (
                                        <span className="ms-1 d-flex">
                                          <IconLock />
                                        </span>
                                      )}
                                    </label>
                                  </div>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="MyPortfolioChartsWidgetPermission"
                                      checked={myPortfolioChartsWidget.value}
                                      disabled={myPortfolioChartsWidget.disable}
                                      onChange={() => {
                                        setMyPortfolioChartsWidget({
                                          ...myPortfolioChartsWidget,
                                          value: !myPortfolioChartsWidget.value,
                                        });
                                        setIsDataChanged(true);
                                      }}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="MyPortfolioChartsWidgetPermission"
                                    >
                                      My Portfolio Charts Widget
                                      {myPortfolioChartsWidget.disable && (
                                        <span className="ms-1 d-flex">
                                          <IconLock />
                                        </span>
                                      )}
                                    </label>
                                  </div>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="MyPortfolioAlertsWidgetPermission"
                                      checked={myPortfolioAlertsWidget.value}
                                      disabled={myPortfolioAlertsWidget.disable}
                                      onChange={() => {
                                        setMyPortfolioAlertsWidget({
                                          ...myPortfolioAlertsWidget,
                                          value: !myPortfolioAlertsWidget.value,
                                        });
                                        setIsDataChanged(true);
                                      }}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="MyPortfolioAlertsWidget"
                                    >
                                      My Portfolio Alerts Widget
                                      {myPortfolioAlertsWidget.disable && (
                                        <span className="ms-1 d-flex">
                                          <IconLock />
                                        </span>
                                      )}
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className="col-8">
                                <div className="form-group">
                                  <div className="flexbox-c gap-2 mb-2">
                                    <label className="form-label">
                                      Portfolio Widgets
                                    </label>
                                    <button
                                      type="button"
                                      className="btn-clear link mb-6"
                                      onClick={() => {
                                        setCreditWidget({
                                          ...creditWidget,
                                          value: !creditWidget.value,
                                        });
                                        if (!nplWidget.disable) {
                                          setNplWidget({
                                            ...creditWidget,
                                            value: !creditWidget.value,
                                          });
                                        }
                                        if (!loanWidget.disable) {
                                          setLoanWidget({
                                            ...creditWidget,
                                            value: !creditWidget.value,
                                          });
                                        }
                                        if (!receivableWidget.disable) {
                                          setReceivableWidget({
                                            ...creditWidget,
                                            value: !creditWidget.value,
                                          });
                                        }
                                        if (!visualizationWidget.disable) {
                                          setVisualizationWidget({
                                            ...creditWidget,
                                            value: !creditWidget.value,
                                          });
                                        }
                                      }}
                                    >
                                      Select All/None
                                    </button>
                                  </div>
                                  <div className="row">
                                    <div className="col-6">
                                      <div className="form-check">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          id="creditWidgetPermission"
                                          checked={creditWidget.value}
                                          disabled={creditWidget.disable}
                                          onChange={() => {
                                            setCreditWidget({
                                              ...creditWidget,
                                              value: !creditWidget.value,
                                            });
                                            setIsDataChanged(true);
                                          }}
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor="creditWidgetPermission"
                                        >
                                          Credit Widget
                                          {creditWidget.disable && (
                                            <span className="ms-1 d-flex">
                                              <IconLock />
                                            </span>
                                          )}
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-6">
                                      <div className="form-check">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          id="visualizationWidgetPermission"
                                          checked={visualizationWidget.value}
                                          disabled={visualizationWidget.disable}
                                          onChange={() => {
                                            setVisualizationWidget({
                                              ...visualizationWidget,
                                              value: !visualizationWidget.value,
                                            });
                                            setIsDataChanged(true);
                                          }}
                                          // disabled
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor="visualizationWidgetPermission"
                                        >
                                          Visualisation Widget
                                          {visualizationWidget.disable && (
                                            <span className="ms-1 d-flex">
                                              <IconLock />
                                            </span>
                                          )}
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-6">
                                      <div className="form-check">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          id="NplWidgetPermission"
                                          checked={nplWidget.value}
                                          disabled={nplWidget.disable}
                                          onChange={() => {
                                            setNplWidget({
                                              ...nplWidget,
                                              value: !nplWidget.value,
                                            });
                                            setIsDataChanged(true);
                                          }}
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor="NplWidgetPermission"
                                        >
                                          NPL Financials Widget
                                          {nplWidget.disable && (
                                            <span className="ms-1 d-flex">
                                              <IconLock />
                                            </span>
                                          )}
                                        </label>
                                      </div>
                                    </div>

                                    <div className="col-6">
                                      <div className="form-check">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          id="receivableWidgetPermission"
                                          checked={receivableWidget.value}
                                          disabled={receivableWidget.disable}
                                          onChange={() => {
                                            setReceivableWidget({
                                              ...receivableWidget,
                                              value: !receivableWidget.value,
                                            });
                                            setIsDataChanged(true);
                                          }}
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor="receivableWidgetPermission"
                                        >
                                          Receivables Widget
                                          {receivableWidget.disable && (
                                            <span className="ms-1 d-flex">
                                              <IconLock />
                                            </span>
                                          )}
                                        </label>
                                      </div>
                                    </div>

                                    <div className="col-6">
                                      <div className="form-check">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          id="loanWidgetPermission"
                                          checked={loanWidget.value}
                                          disabled={loanWidget.disable}
                                          onChange={() => {
                                            setLoanWidget({
                                              ...loanWidget,
                                              value: !loanWidget.value,
                                            });
                                            setIsDataChanged(true);
                                          }}
                                          // disabled
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor="loanWidgetPermission"
                                        >
                                          Loan Widget
                                          {loanWidget.disable && (
                                            <span className="ms-1 d-flex">
                                              <IconLock />
                                            </span>
                                          )}
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="user-form-item">
                        <h5 className="fi-heading">
                          Collaboration & External sharing
                        </h5>
                        <div className="row">
                          <div className="col-12 col-lg-10 offset-2">
                            <div className="row">
                              <div className="col-4">
                                <div className="form-group">
                                  <label className="form-label flexbox-c gap-1">
                                    Collaboration
                                    <IconInfo />
                                  </label>
                                  <CustomSelect
                                    options={sharingOptions}
                                    value={collaboration}
                                    onChange={(e: {
                                      label: string;
                                      value: string;
                                    }) => {
                                      setCollaboration(e);
                                      setIsDataChanged(true);
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-8">
                                <div className="form-group">
                                  <label className="form-label flexbox-c gap-1">
                                    Department collaboration whitelist
                                    <IconInfo />
                                  </label>
                                  <CustomSelect
                                    isMulti
                                    options={
                                      allDepartments &&
                                      allDepartments?.map(item => ({
                                        label: item?.department,
                                        value: item?.department,
                                      }))
                                    }
                                    placeholder={'All Departments'}
                                    value={selectedSharingDepartment}
                                    onChange={(e: LabelValue[]) => {
                                      setSelectedSharingDepartment(e);
                                      setIsDataChanged(true);
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-4">
                                <div className="form-group">
                                  <label className="form-label flexbox-c gap-1">
                                    Share Data Externally
                                    <IconInfo />
                                  </label>
                                  <CustomSelect
                                    options={sharingOptions}
                                    value={externalSharing}
                                    onChange={(e: {
                                      label: string;
                                      value: string;
                                    }) => {
                                      setExternalSharing(e);
                                      setIsDataChanged(true);
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-8">
                                <div className="form-group">
                                  <label className="form-label flexbox-c gap-1">
                                    External sharing E-mail Whitelist
                                    <IconInfo />
                                  </label>

                                  <ReactCreatableSelect
                                    //options={values.modelNumbers}
                                    isMulti
                                    name="selectedSharingEmail"
                                    // className="basic-multi-select"
                                    // classNamePrefix="select"
                                    value={selectedSharingEmail}
                                    isClearable={true}
                                    onChange={(e: LabelValue[]) => {
                                      setSelectedSharingEmail(e);
                                      setIsDataChanged(true);
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {isDataChanged && (
                    <div className="common-widget-footer">
                      <div className="footer-action">
                        <div className="d-flex align-items-center justify-content-between">
                          <p className="flexbox-c gap-8 mb-0">
                            <IconError />
                            Unsaved changes have been detected
                          </p>

                          <div className="gap-12 d-flex justify-content-center">
                            <button
                              className="btn btn-outline-info"
                              onClick={() => {
                                getUserById();
                                setIsDataChanged(false);
                              }}
                            >
                              Discard
                            </button>
                            <button
                              className="btn btn-primary"
                              onClick={() => {
                                if (
                                  userDetails?.userDetail.userStatus !==
                                  'PENDING'
                                ) {
                                  handleSaveUserDetails();
                                  handleSaveUserPermissions();
                                  handleSaveSharingPermissions();
                                  setIsDataChanged(false);
                                } else {
                                  showModal(
                                    'You can not update. your invite is pending right now.',
                                    false,
                                  );
                                }
                              }}
                            >
                              Save changes
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UsersDetails;
