import classnames from 'classnames';
import React, { useState } from 'react';
import { TabContent, TabPane } from 'reactstrap';
import { Iconclose } from '../../../../../../components/public/Icons';
import PortfolioListing from './PortfolioListing';
import PortfolioNormalizing from './PortfolioNormalizing';

type PortfolioTypes = {
  togglePortfolioWidget: () => void;
};

const PortfolioMap = ({ togglePortfolioWidget }: PortfolioTypes) => {
  // State for current active Tab
  const [currentActiveTab, setCurrentActiveTab] = useState('1');

  const buttonText = () => {
    if (currentActiveTab === '2') {
      return 'Add Data to Widget';
    } else {
      return 'Continue';
    }
  };

  // Toggle active state for Tab
  const toggle = (tab: React.SetStateAction<string>) => {
    if (currentActiveTab !== tab) setCurrentActiveTab(tab);
  };

  return (
    <>
      <div className="addwidget-wrapper">
        <div className="sub-header justify-content-between">
          <h4 className="title-20">Edit widget data sources</h4>
          <button
            className="btn btn-icon p-0"
            onClick={() => {
              togglePortfolioWidget();
            }}
          >
            <Iconclose />
          </button>
        </div>
        <div className="addwidget-main">
          <div className="container container-1024">
            <div className="addwidget-header">
              {currentActiveTab === '1' && (
                <h4 className="title-20">
                  Select data sources to add to the Credit widget
                </h4>
              )}

              {currentActiveTab === '2' && (
                <h4 className="title-20">Normalize and map portfolio</h4>
              )}

              <div className="tabs-h-menu"></div>
              <ul className="addwidget-steps">
                <li>
                  <button
                    className={classnames({
                      active: currentActiveTab === '1',
                    })}
                  >
                    1
                  </button>
                </li>
                <li>
                  <button
                    className={classnames({
                      active: currentActiveTab === '2',
                    })}
                  >
                    2
                  </button>
                </li>
              </ul>
            </div>
            <TabContent activeTab={currentActiveTab}>
              <TabPane tabId="1">
                <PortfolioListing />
              </TabPane>

              <TabPane tabId="2">
                <PortfolioNormalizing />
              </TabPane>
            </TabContent>
          </div>
          <div className="gap-12 d-flex justify-content-center">
            <button
              className="btn btn-outline-info"
              onClick={() => {
                if (currentActiveTab === '1') {
                  togglePortfolioWidget();
                }

                toggle(String(Number(currentActiveTab) - 1));
              }}
            >
              {currentActiveTab === '1' ? 'Cancel' : 'Back'}
            </button>
            <button
              className="btn btn-primary"
              onClick={() => {
                if (currentActiveTab !== '2') {
                  toggle(String(Number(currentActiveTab) + 1));
                }

                if (currentActiveTab === '2') {
                  togglePortfolioWidget();
                }
              }}
            >
              {buttonText()}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default PortfolioMap;
