import { useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { IconCross } from '../public/Icons';

type RejectionModalProps = {
  modal: boolean;
  toggle: () => void;
  // eslint-disable-next-line no-unused-vars
  onSubmit: (reason: string) => void;
};

const RejectionModal = ({ modal, toggle, onSubmit }: RejectionModalProps) => {
  const [reason, setReason] = useState('');

  const handleReject = () => {
    onSubmit(reason);
    toggle();
  };

  const closeBtn = (
    <button className="close" onClick={toggle} type="button">
      <IconCross fill="#36567D" />
    </button>
  );

  return (
    <Modal isOpen={modal} toggle={toggle} centered size="md">
      <ModalHeader toggle={toggle} close={closeBtn}>
        Reject Task
      </ModalHeader>
      <ModalBody>
        <p>Please provide a reason for rejection:</p>
        <textarea
          className="form-control"
          value={reason}
          onChange={e => setReason(e.target.value)}
          placeholder="Enter reason"
          style={{ height: '150px' }}
        />
      </ModalBody>
      <ModalFooter className="justify-content-end">
        <button className="btn btn-outline-info" onClick={toggle}>
          Cancel
        </button>
        <button className="btn btn-danger" onClick={handleReject}>
          Reject
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default RejectionModal;
