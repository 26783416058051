import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions } from '../../redux';

const useAction = () => {
  const dispatch = useDispatch();
  return bindActionCreators(actions, dispatch);
};

export default useAction;
