/* ACTIONS */
import { productStructuringWidgetConstant } from '../constant';

const { ADD_SELECTED_PORTFOLIO_NAME } = productStructuringWidgetConstant;

export const addSelectedPortfolio = (data: any) => {
  return {
    type: ADD_SELECTED_PORTFOLIO_NAME,
    payload: {
      selectedPortfolios: data,
    },
  };
};
