import { ElementType, Fragment, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ChildrenRouteType, RoutesType } from '../@types/Routes';
import AppLoader from '../app/components/loaders/AppLoader';
import DashboardLayout from '../app/containers/layout/DashboardLayout';
import PageNotFound from '../app/containers/public/PageNotFound';
import { AfterLoginRoute, DefaultRoutes } from './path';

const RoutesComponent = () => {
  const loadRoutes = (
    routes: Array<RoutesType>,
    Layout: ElementType = Fragment,
  ) =>
    routes.map(
      ({ component: Component, children, path, guard }: RoutesType) => {
        const Guard = guard ? guard : Fragment;
        return children ? (
          children.map(
            ({ component: ChildComponent, path, guard }: ChildrenRouteType) => {
              const Guard = guard ? guard : Fragment;

              return (
                <Route
                  key={path}
                  path={path}
                  element={
                    <Suspense fallback={<AppLoader />}>
                      <Guard>
                        <Layout>
                          <ChildComponent />
                        </Layout>
                      </Guard>
                    </Suspense>
                  }
                />
              );
            },
          )
        ) : (
          <Route
            key={path}
            path={path}
            element={
              <Guard>
                <Layout>
                  <Component />
                </Layout>
              </Guard>
            }
          />
        );
      },
    );

  return (
    <Routes>
      {loadRoutes(DefaultRoutes)}
      {loadRoutes(AfterLoginRoute, DashboardLayout)}

      <Route path={`*/*`} element={<PageNotFound />} />
    </Routes>
  );
};

export default RoutesComponent;
