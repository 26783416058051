
import { chatQueryConstant } from '../constant/chatQuery.constant';

const {
  ADD_CHAT_QUERY,
  ADD_LOAN_CHAT_QUERY,
  ADD_NPL_CHAT_QUERY,
  ADD_RECEIVABLE_CHAT_QUERY,
  ADD_NEWS_QUERY,
  CLEAR_NEWS_QUERY
} = chatQueryConstant;

const initialState = {
  chatQuery: '',
  nplChatQuery: '',
  loanChatQuery: '',
  receivableChatQuery: '',
  newsChatQuery: '',
  clearNewsQuery: ''
};

const ChatQueryReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case ADD_CHAT_QUERY:
      return {
        ...state,
        chatQuery: action.payload.query,
      };

    case ADD_NPL_CHAT_QUERY:
      return {
        ...state,
        nplChatQuery: action.payload.query,
      };

    case ADD_LOAN_CHAT_QUERY:
      return {
        ...state,
        loanChatQuery: action.payload.query,
      };

    case ADD_RECEIVABLE_CHAT_QUERY:
      return {
        ...state,
        receivableChatQuery: action.payload.query,
      };

    case ADD_NEWS_QUERY:
    return {
      ...state,
      newsChatQuery: action.payload.query,
    };

    case CLEAR_NEWS_QUERY:
      return {
        ...state,
        newsChatQuery: '', 
      };

    default:
      return state;
  }
};


export default ChatQueryReducer;
