// const options = [
//   { label: '11 to 50', value: 1 },
//   { label: '51 to 60', value: 2 },
//   { label: '61 to 60', value: 3 },
//   { label: '71 to 70', value: 4 },
// ];

import { useEffect, useState } from 'react';
import {
  IconCross,
  IconMinusCircle,
  IconPlusCircle,
  IconTick,
} from '../../../components/public/Icons';
import SettingsService from '../../../services/settings.service';
import { isAxiosError } from '../../../redux/constant';
import useAction from '../../../components/hooks/useAction';
import {
  LicenseEnum,
  OrganizationLicenseData,
  OrganizationLicenseResponse,
} from '../../../../@types/SettingsType';
import { licenseEnum } from '../../../helpers/utils.helper';
import { useSelector } from 'react-redux';
import { userDetailsData } from '../../../redux/selector/UserDetailsSelector';
import { isEmpty } from 'lodash';
import AppLoader from '../../../components/loaders/AppLoader';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

const settingsService = new SettingsService();

const LicenSesBilling = () => {
  const { showModal } = useAction();
  const userDetails = useSelector(userDetailsData);
  const [isEditAddress, setIsEditAddress] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [totalExpenses, setTotalExpenses] = useState(0);
  const [billingAddress, setBillingAddress] = useState('');
  const [initialAvailableLicenses, setInitialAvailableLicenses] = useState(0);
  const [availableLicense, setAvailableLicense] = useState([
    {
      licenseType: 'SILVER',
      availableLicenses: 0,
      licensesYetToAvailable: 0,
    },
    {
      licenseType: 'GOLD',
      availableLicenses: 0,
      licensesYetToAvailable: 0,
    },
    {
      licenseType: 'PLATINUM',
      availableLicenses: 0,
      licensesYetToAvailable: 0,
    },
  ]);
  const [licenseListing, setLicenseListing] =
    useState<Array<OrganizationLicenseData> | null>(null);

  const handleIncreaseLicense = (type: string) => {
    const value: number =
      availableLicense.find((i) => i.licenseType === type)?.availableLicenses ||
      0;

    const maxLimit: number =
      availableLicense.find((i) => i.licenseType === type)
        ?.licensesYetToAvailable || 0;
    if (value + 1 <= maxLimit) {
      const updatedLicenses = availableLicense.map((i) => {
        if (i.licenseType === type) {
          return {
            ...i,
            availableLicenses: i.availableLicenses + 1,
          };
        } else {
          return i;
        }
      });

      setAvailableLicense(updatedLicenses);
    }
  };

  const handleDecreaseLicense = (type: string) => {
    const value: number =
      availableLicense.find((i) => i.licenseType === type)?.availableLicenses ||
      0;
    if (value - 1 >= 0) {
      const updatedLicenses = availableLicense.map((i) => {
        if (i.licenseType === type) {
          return {
            ...i,
            availableLicenses: i.availableLicenses - 1,
          };
        } else {
          return i;
        }
      });

      setAvailableLicense(updatedLicenses);
    }
  };
  const showEditAddress = () => {
    setIsEditAddress(!isEditAddress);
  };

  const getOrganizationLicensesList = async () => {
    setIsLoading(true);
    try {
      const response: OrganizationLicenseResponse =
        await settingsService.getOrganizationLicenses();

      const totalExpenseValue = response?.data
        .filter((item) => item.totalExpense !== 'Free') // Exclude "Free" expenses
        .map((item) => Number(item.totalExpense.replace(/[$,]/g, ''))) // Remove $ and , and convert to number
        .reduce((acc, curr) => acc + curr, 0);

      setTotalExpenses(totalExpenseValue);
      setLicenseListing(response.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if (isAxiosError(error)) {
        showModal(
          (error.response?.data as { error?: string })?.error ||
            'Unexpected Axios error',
        );
      } else {
        showModal('An unexpected error occurred');
      }
    }
  };

  const updateBillingAddressValue = async () => {
    setIsLoading(true);
    const payload = {
      billingAddress: billingAddress,
    };
    try {
      await settingsService.updateBillingAddress(payload);

      showModal('Billing address is updated!', true);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if (isAxiosError(error)) {
        showModal(
          (error.response?.data as { error?: string })?.error ||
            'Unexpected Axios error',
        );
      } else {
        showModal('An unexpected error occurred');
      }
    }
  };

  const getOrdinalSuffix = (day: number): string => {
    if (day > 3 && day < 21) return 'th'; // Handles 11th, 12th, 13th, etc.
    switch (day % 10) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  };

  const returnBillingData = () => {
    const currentDate = new Date();
    // Set the date to the first day of the current month
    const firstDayOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1,
    );
    const month = firstDayOfMonth.toLocaleString('en-US', { month: 'short' });
    const day = firstDayOfMonth.getDate();
    const year = firstDayOfMonth.getFullYear();

    const formattedDate = `${month} ${day}${getOrdinalSuffix(day)}, ${year}`;
    return formattedDate;
  };

  useEffect(() => {
    if (!isEmpty(userDetails)) {
      setBillingAddress(userDetails?.billingAddress);
    }
    getOrganizationLicensesList();
  }, []);

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const closeBtn = (
    <button className="close" onClick={toggle} type="button">
      <IconCross fill="#36567D" />
    </button>
  );

  useEffect(() => {
    async function getLicenseAvaibility() {
      if (modal) {
        const result: any = await settingsService.getAvailableLicense();
        const sortedLicenses: any = result?.data?.sort((a: any, b: any) => {
          const order = ['SILVER', 'GOLD', 'PLATINUM'];
          return order.indexOf(a.licenseType) - order.indexOf(b.licenseType);
        });

        setInitialAvailableLicenses(
          sortedLicenses
            ?.map((x: any) => x.availableLicenses)
            ?.reduce((acc: number, num: number) => acc + num, 0),
        );
        const totalValue = sortedLicenses.reduce(
          (accumulator: any, license: any) => {
            let multiplier = 0;

            // Determine multiplier based on licenseType
            if (license.licenseType === 'SILVER') {
              multiplier = 1000;
            } else if (license.licenseType === 'GOLD') {
              multiplier = 2000;
            } else if (license.licenseType === 'PLATINUM') {
              multiplier = 4000;
            }

            // Add the calculated value to the accumulator
            return accumulator + license.availableLicenses * multiplier;
          },
          0,
        );
        setInitialAvailableLicenses(totalValue);

        setAvailableLicense(sortedLicenses);
      }
    }

    if (modal) {
      getLicenseAvaibility();
    }
  }, [modal]); // Use modal as the dependency

  const updateLicenseHandler = async () => {
    try {
      setIsLoading(true);
      const payload: any = {
        // eslint-disable-next-line no-unused-vars
        licensesObj: availableLicense.map(({ licenseType, ...rest }) => rest),
      };
      await settingsService.updateLicenses(payload);
      setModal(false);
      getOrganizationLicensesList();
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading && <AppLoader />}
      <div className="user-form wc-scroll">
        <div className="user-form-item">
          <h5 className="fi-heading">Licenses</h5>
          <div className="row">
            <div className="col-12 col-lg-10 offset-2">
              <div className="row">
                <div className="col-12 col-lg-3">
                  <div className="form-group">
                    <label className="form-label">Payment model</label>
                    {/* <CustomSelect options={options} placeholder={'All Users'} /> */}
                    <p className="mt-2 mb-0">Yearly</p>
                  </div>
                </div>
                <div className="col-12 col-lg-3">
                  <div className="form-group">
                    <label className="form-label">Next Billing cycle</label>
                    {/* <CustomSelect
                      options={options}
                      placeholder={'Data Source'}
                    /> */}
                    <p className="mt-2 mb-0">{returnBillingData()}</p>
                  </div>
                </div>
                <div className="col-12 col-lg-3">
                  <div className="form-group">
                    <label className="form-label">Invoice Total</label>
                    {/* <CustomSelect options={options} /> */}
                    <p className="mt-2 mb-0">${totalExpenses}</p>
                  </div>
                </div>
              </div>

              <div className="table-responsive">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">License type</th>
                      <th className="text-end" scope="col">
                        Available to Assign
                      </th>
                      <th className="text-end" scope="col">
                        Used Licenses
                      </th>
                      <th className="text-end" scope="col">
                        Total purchased
                      </th>
                      <th className="text-end">Total Expense (PER YEAR)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {licenseListing?.map((item) => (
                      <tr key={item?.licenseType}>
                        <td>
                          {
                            licenseEnum[
                              `${item?.licenseType as keyof LicenseEnum}`
                            ]
                          }
                        </td>
                        <td className="text-end">{item?.availableLicenses}</td>
                        <td className="text-end">{item?.usedLicenses}</td>
                        <td className="text-end">{item?.totalLicenses}</td>
                        <td className="text-end">{item?.totalExpense}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="action-link">
                  <button className="btn-clear link" onClick={toggle}>
                    Edit Licenses
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="user-form-item">
          <h5 className="fi-heading">Billing</h5>
          <div className="row">
            <div className="col-12 col-lg-10 offset-2">
              <div className="row">
                <div className="col-12 col-lg-4">
                  <div className="form-group mb-2">
                    <label className="form-label">Billing Address</label>
                    <textarea
                      className="form-control readonly"
                      readOnly={isEditAddress ? false : true}
                      rows={4}
                      value={billingAddress}
                      onChange={(e) => {
                        setBillingAddress(e.target.value);
                      }}
                    ></textarea>
                    {isEditAddress && (
                      <>
                        <div className="flexbox-c gap-12 justify-content-end mt-2 pt-1">
                          <button
                            className="btn-clear flexbox-c gap-1 text-info"
                            onClick={() => {
                              showEditAddress();
                              setBillingAddress(
                                userDetails?.billingAddress || '',
                              );
                            }}
                          >
                            <IconCross fill="#36567D" />
                            Cancel
                          </button>
                          <button
                            className="btn-clear flexbox-c gap-1 text-primary"
                            onClick={() => {
                              showEditAddress();
                              updateBillingAddressValue();
                              // setBillingAddress('');
                            }}
                          >
                            <IconTick />
                            Save
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                  {!isEditAddress && (
                    <div className="d-flex">
                      <button
                        className="btn-clear link"
                        onClick={() => {
                          showEditAddress();
                        }}
                      >
                        Edit Address
                      </button>
                    </div>
                  )}
                </div>
                <div className="col-12 col-lg-4">
                  <div className="form-group mb-2">
                    <label className="form-label">Payment Method</label>
                    <input
                      readOnly
                      className="form-control readonly"
                      defaultValue={'**** 1234'}
                    ></input>
                  </div>
                  <div className="d-flex">
                    <button
                      className="btn-clear link"
                      onClick={() => {
                        window.location.href =
                          'https://manage.wix.com/account/wix-payments?referralInfo=sidebar';
                      }}
                    >
                      Edit on Stripe
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal isOpen={modal} toggle={toggle} centered>
        <ModalHeader toggle={toggle} close={closeBtn}>
          Edit Licenses
        </ModalHeader>
        <ModalBody>
          <p className="mb-4">
            Only unused licenses can be removed. Unassign a license from a user
            in order to remove it from your organisational subscription.
          </p>

          <div className="row rows-col-1 rows-cols-md-2 rows-cols-lg-3">
            <div className="col">
              <div className="form-group">
                <label className="form-label">Silver Licenses</label>
                <div className="form-group form-value form-icon mb-10">
                  <button
                    className="input-icon"
                    onClick={() => {
                      handleDecreaseLicense('SILVER');
                    }}
                  >
                    <IconMinusCircle />
                  </button>

                  <input
                    type="text"
                    placeholder="Enter Number"
                    className="form-control"
                    value={
                      availableLicense?.find((x) => x?.licenseType === 'SILVER')
                        ?.availableLicenses
                    }
                    onChange={(e) => {
                      const value = e.target.value;

                      if (/^\d*$/.test(value)) {
                        // Regex to allow only digits
                        const updatedLicenses: any = availableLicense.map(
                          (i) => {
                            if (i.licenseType === 'SILVER') {
                              return { ...i, availableLicenses: value };
                            } else {
                              return i;
                            }
                          },
                        );
                        setAvailableLicense(updatedLicenses);
                      }
                    }}
                  />
                  <button
                    className="input-icon icon-increase"
                    onClick={() => {
                      handleIncreaseLicense('SILVER');
                    }}
                  >
                    <IconPlusCircle />
                  </button>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="form-group">
                <label className="form-label">Gold Licenses</label>
                <div className="form-group form-value form-icon mb-10">
                  <button
                    className="input-icon"
                    onClick={() => {
                      handleDecreaseLicense('GOLD');
                    }}
                  >
                    <IconMinusCircle />
                  </button>
                  <input
                    type="text"
                    placeholder="Enter Number"
                    className="form-control"
                    value={
                      availableLicense?.find((x) => x?.licenseType === 'GOLD')
                        ?.availableLicenses
                    }
                    onChange={(e) => {
                      const value = e.target.value;
                      if (/^\d*$/.test(value)) {
                        // Regex to allow only digits
                        const updatedLicenses: any = availableLicense.map(
                          (i) => {
                            if (i.licenseType === 'GOLD') {
                              return { ...i, availableLicenses: value };
                            } else {
                              return i;
                            }
                          },
                        );
                        setAvailableLicense(updatedLicenses);
                      }
                    }}
                  />
                  <button
                    className="input-icon icon-increase"
                    onClick={() => {
                      handleIncreaseLicense('GOLD');
                    }}
                  >
                    <IconPlusCircle />
                  </button>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="form-group">
                <label className="form-label">Platinum Licenses</label>
                <div className="form-group form-value form-icon mb-10">
                  <button
                    className="input-icon"
                    onClick={() => {
                      handleDecreaseLicense('PLATINUM');
                    }}
                  >
                    <IconMinusCircle />
                  </button>
                  <input
                    type="string"
                    placeholder="Enter Number"
                    className="form-control"
                    value={
                      availableLicense?.find(
                        (x) => x?.licenseType === 'PLATINUM',
                      )?.availableLicenses
                    }
                    onChange={(e) => {
                      const value = e.target.value;
                      if (/^\d*$/.test(value)) {
                        // Regex to allow only digits
                        const updatedLicenses: any = availableLicense.map(
                          (i) => {
                            if (i.licenseType === 'PLATINUM') {
                              return { ...i, availableLicenses: value };
                            } else {
                              return i;
                            }
                          },
                        );
                        setAvailableLicense(updatedLicenses);
                      }
                    }}
                  />
                  <button
                    className="input-icon icon-increase"
                    onClick={() => {
                      handleIncreaseLicense('PLATINUM');
                    }}
                  >
                    <IconPlusCircle />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="row rows-col-1 rows-cols-md-2 rows-cols-lg-3">
            <div className="col">
              <div className="form-group">
                <label className="form-label">Current Expenses</label>
                <p className="mt-2 mb-0">${initialAvailableLicenses}</p>
              </div>
            </div>
            <div className="col">
              <div className="form-group">
                <label className="form-label">New Expenses</label>
                <p className="mt-2 mb-0">
                  {' '}
                  $
                  {availableLicense?.reduce(
                    (accumulator: any, license: any) => {
                      let multiplier = 0;

                      // Determine multiplier based on licenseType
                      if (license.licenseType === 'SILVER') {
                        multiplier = 1000;
                      } else if (license.licenseType === 'GOLD') {
                        multiplier = 2000;
                      } else if (license.licenseType === 'PLATINUM') {
                        multiplier = 4000;
                      }

                      // Add the calculated value to the accumulator
                      return (
                        accumulator + license.availableLicenses * multiplier
                      );
                    },
                    0,
                  )}
                </p>
              </div>
            </div>
            <div className="col">
              <div className="form-group">
                <label className="form-label">Expense Difference</label>
                <p className="mt-2 mb-0">
                  {' '}
                  $
                  {Math.abs(
                    availableLicense?.reduce(
                      (accumulator: any, license: any) => {
                        let multiplier = 0;

                        // Determine multiplier based on licenseType
                        if (license.licenseType === 'SILVER') {
                          multiplier = 1000;
                        } else if (license.licenseType === 'GOLD') {
                          multiplier = 2000;
                        } else if (license.licenseType === 'PLATINUM') {
                          multiplier = 4000;
                        }

                        // Add the calculated value to the accumulator
                        return (
                          accumulator + license.availableLicenses * multiplier
                        );
                      },
                      0,
                    ) - initialAvailableLicenses,
                  )}
                </p>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter className="justify-content-end">
          <button className="btn btn-outline-info" onClick={toggle}>
            Cancel
          </button>
          <button className="btn btn-primary" onClick={updateLicenseHandler}>
            Update Licenses
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default LicenSesBilling;
