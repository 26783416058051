import { ModalType } from '../../../@types/ModalType';
import { errorConstant } from '../constant';

const { SHOW_MODAL, HIDE_MODAL } = errorConstant;

const initialState: ModalType = {
  modal: {
    message: '',
    isShow: false,
    flag: false,
  },
};

const ErrorReducer = (state = initialState, action: ModalType): ModalType => {
  switch (action.type) {
    case SHOW_MODAL:
      return {
        ...state,
        modal: action.modal,
      };

    case HIDE_MODAL:
      return initialState;

    default:
      return state;
  }
};

export default ErrorReducer;
