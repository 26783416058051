import React from 'react';

type NplWidgetHeadingProps = {
  currentActiveTab: string;
};

const NplWidgetHeading = ({ currentActiveTab }: NplWidgetHeadingProps) => {
  return (
    <>
      {currentActiveTab === '2' && (
        <h4 className="title-20">Select portfolio for the Npl widget</h4>
      )}
      {currentActiveTab === '3' && (
        <h4 className="title-20">
          Define the goal, geography and time frame of the Npl widget
        </h4>
      )}
      {currentActiveTab === '4' && (
        <h4 className="title-20">
          Define statistical analysis thresholds for the Npl widget
        </h4>
      )}
    </>
  );
};

export default NplWidgetHeading;
