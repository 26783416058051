import { warrenFileConstant } from '../constant/warrenFile.constant';

const { ADD_WARREN_FILE } = warrenFileConstant;

const initialState: any = {
  warrenFile: [] as any,
};

const WarrenFileReducer = (state = initialState, action: any): any => {
  switch (action.type) {
    case ADD_WARREN_FILE:
      return {
        warrenFile: action.payload.warrenFile,
      };

    default:
      return state;
  }
};
export default WarrenFileReducer;
