import { rowIndexConstant } from '../constant';

const { ADD_ROW_INDEX } = rowIndexConstant;

const initialState: any = {
  rowIndex: [] as any,
};

const RowIndexReducer = (state = initialState, action: any): any => {
  switch (action.type) {
    case ADD_ROW_INDEX:
      return {
        rowIndex: action.payload.rowIndex,
      };

    default:
      return state;
  }
};
export default RowIndexReducer;
