import React, { JSX } from 'react';

// Define a recursive type for dynamic objects
type DataType = {
  [key: string]: string | number | DataType; // Either a string/number or another nested object
};

interface DataTableProps {
  data: DataType;
}

// DataTable component
const DataTable: React.FC<DataTableProps> = ({ data }) => {
  // Function to recursively render keys and values
  const renderRows = (data: DataType): JSX.Element[] => {
    return Object.entries(data).map(([key, value]) => {
      if (typeof value === 'object' && value !== null) {
        // If the value is an object, recursively render the nested keys/values
        return (
          <React.Fragment key={key}>
            <tr>
              <td>{`${key} -> `}</td>
              <td>
                <table>
                  <tbody>{renderRows(value)}</tbody>
                </table>
              </td>
            </tr>
          </React.Fragment>
        );
      } else if (typeof value !== 'object' && value !== null) {
        // If the value is not an object, render the key-value pair
        return (
          <tr key={key}>
            <td>{`${key} -> `}</td>
            <td>{value}</td>
          </tr>
        );
      }

      // Return null explicitly if no value is found
      return (
        <tr key="No_Record_Found">
          <td>{`No Record Found!`}</td>
        </tr>
      );
    });
  };

  return (
    <table>
      <tbody>{renderRows(data)}</tbody>
    </table>
  );
};

export default DataTable;
