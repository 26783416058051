/* ACTIONS */
import { allocationCalculationConstant } from '../constant';

const {
  ADD_NPL_ALLOCATION_DATA,

  ADD_LOAN_ALLOCATION_DATA,
  ADD_RECEIVABLE_ALLOCATION_DATA,
} = allocationCalculationConstant;

export const addAllocationDataNpl = (data: any) => {
  return {
    type: ADD_NPL_ALLOCATION_DATA,
    payload: {
      allocationDataNPL: data,
    },
  };
};

export const addAllocationDataLoan = (data: any) => {
  return {
    type: ADD_LOAN_ALLOCATION_DATA,
    payload: {
      allocationDataLOAN: data,
    },
  };
};

export const addAllocationDataReceivable = (data: any) => {
  return {
    type: ADD_RECEIVABLE_ALLOCATION_DATA,
    payload: {
      allocationDataRECEIVABLE: data,
    },
  };
};
