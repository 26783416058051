// import classnames from 'classnames';
//import { visuallyHidden } from '@mui/utils';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import useAction from '../../../../components/hooks/useAction';
// import SessionModal from '../../../../components/modals/SessionModal';
import React, { useState, useEffect } from 'react';
import {
  // IconChatbot,
  Iconclose,
  // IconDataSource,
  IconFullScreen,
  // IconInfo,
  // IconSort,
  // IconStar,
} from '../../../../components/public/Icons';
import { callTelemetryRecord } from '../../../../helpers/utils.helper';
import { widgetRankData } from '../../../../redux/selector/WidgetRankSelector';
import PublicChat from '../../../../components/public/PublicChat';
// import SendWidgetAsTask from '../../newsfeed/widgets/SendWidgetAsTask';
// import ShareWidgetExternally from '../../newsfeed/widgets/ShareWidgetExternally';
import ExportWidgeTData from '../../newsfeed/widgets/ExportWidgeTData';
import RemoveWidget from '../../newsfeed/widgets/RemoveWidget';
import WidgetMenuDropdown from '../../../../components/ui/WidgetMenuDropdown';
// import { CustomTooltip } from '../../../../components/ui/CustomTooltip';
import TaskWidgetActionDropdown from './TaskWidgetActionDropdown';
import axios from 'axios';
import config from '../../../../../config';
import AppLoader from '../../../../components/loaders/AppLoader';
import { useNavigate } from 'react-router';
import prepareChartPayload from '../../portfolio/addportfoliowidget/ChartsPayload';
import getChartsData from '../../portfolio/addportfoliowidget/GetChartsData';
import { ChartFormDataProps } from '../../../../../@types/PortfolioType';
import { allPortfolioWidgetsSelector } from '../../../../redux/selector/PortfolioWidgetsSelector';
import IngestionService from '../../../../services/ingestion.service';
import { v4 as uuidv4 } from 'uuid';

interface TaskData {
  taskId: string;
  name: string;
  ownerId: string;
  key: string;
  fileName: string;
  directoryName: string;
  type: string;
  description: string;
  sharingDueDate: string;
  isTask: boolean;
  newsId?: string;
  newsName?: string;
  newsSourceId?: string;
  chartHeaders?: any;
  chartType: string;
  portfolioId: string;
  status?: string;
  rejectionReason?: string;
  portfolio_type: string;
  inputDataRange?: { [k: string]: { min: number; max: number }[] };
}

const ingService = new IngestionService();

export default function TasksWidget({
  tasksWidgetIndex,
  onClose,
  widgetFullScreen,
  setWidgetFullScreen,
  creditIndex,
  loanWidgetIndex,
}: // toggleAddItemPortfolio,
// toggleShowDataSources,
// toggleAugumentationDataSourcesModal,
// toggleKnowledgeBaseItemModal,
any) {
  const widRank = useSelector(widgetRankData);

  // const [showSessionModal, setShowSessionModal] = React.useState(false);

  // fullscreen
  const [isChatOpen, setIsChatOpen] = React.useState(false);
  const [taskData, setTaskData] = useState<TaskData[]>([]);
  const [isMainLoading, setIsMainLoading] = useState(false);
  const [sentTaskData, setSentTaskData] = useState<TaskData[]>([]);
  const [activeTab, setActiveTab] = useState('received');

  const toggleWidgetFullScreen = (type?: string) => {
    document.body.classList.toggle('widget-fullscreen');
    setWidgetFullScreen((widgetFullScreen: any) => !widgetFullScreen);
    setIsChatOpen(false);

    // fullscreen widget go to top on fullscreen click
    const pagecontent = document.querySelector('.page-content');
    if (pagecontent) {
      pagecontent.scroll({ top: 0 });
    }

    if (type === 'add') {
      document.body.classList.add(`widget-${tasksWidgetIndex}`);
      document.body.classList.add('widget-fullscreen');

      const addElement = document.getElementById(`fs-task-${tasksWidgetIndex}`);
      if (addElement) {
        const parentElement = addElement.closest('.react-grid-item');
        if (parentElement) {
          setTimeout(() => {
            //
            parentElement.classList.add('widget-active');
          }, 10);
        }
      }
    } else if (type === 'remove') {
      document.body.classList.remove(`widget-${tasksWidgetIndex}`);
      document.body.classList.remove('widget-fullscreen');

      const removeElement = document.getElementById(
        `fsrem-task-${tasksWidgetIndex}`,
      );
      if (removeElement) {
        const parentElement = removeElement.closest('.react-grid-item');
        if (parentElement) {
          parentElement.classList.remove('widget-active');
        }
      }
    }
  };
  const navigate = useNavigate();

  const {
    addWidgetType,
    removePortfolioCreditWidgetData,
    showModal,
    //addPortfolioCreditWidgetData,
    addNewsFeedData,
    addWidgetReferenceId,
    addPortfolioCreditWidgetData,
    addPortfolioLoanWidgetData,
  } = useAction();

  // const toggleShowSessionModal = () => {
  //   setShowSessionModal(!showSessionModal);
  // };

  // const toogleFilterModal = () => {
  //   document.body.classList.toggle('has-overlay');
  //   document.body.classList.toggle('show-widget-chat');
  // };

  const [dropdownOpen, setDropDownOpen] = React.useState(false);
  const toggleDropDown = () => {
    setDropDownOpen((state) => !state);
  };

  //open modal - Widget As Task
  // const [sendWidgetAsTaskModal, setSendWidgetAsTaskModal] = React.useState(
  //   false,
  // // );
  // const toggleSendWidgetAsTaskModal = () =>
  //   setSendWidgetAsTaskModal(!sendWidgetAsTaskModal);

  //open modal - Share Externally
  // const [shareExternallyModal, setShareExternallyModal] = React.useState(false);
  // const toggleShareExternallyModal = () =>
  //   setShareExternallyModal(!shareExternallyModal);

  // //open modal - Export WidgeT Data
  const [exportWidgeTDataModal, setExportWidgeTDataModal] =
    React.useState(false);
  const toggleExportWidgeTDataModal = () =>
    setExportWidgeTDataModal(!exportWidgeTDataModal);

  //remove modal - remove widget
  const [removeWidgetModal, setRemoveWidget] = React.useState(false);
  const toggleRemoveWidgetModal = () => {
    setRemoveWidget(!removeWidgetModal);
  };

  const [isLoading, setIsLoading] = useState(false);
  const { addPortfolioVisualizationWidgetData } = useAction();
  const allPortfolioWidgetReduxData = useSelector(allPortfolioWidgetsSelector);

  const [currentPage, setCurrentPage] = React.useState(1);
  const [searchQuery, setSearchQuery] = React.useState('');
  // const [isCalculated, setIsCalculated] = React.useState(false);
  // const [
  //   creditWidgetPortfolioData,
  //   setCreditWidgetPortfolioData,
  // ] = React.useState([] as any);
  // const isUpdateAddItem: boolean = false;
  const creditWidgetData = allPortfolioWidgetReduxData[creditIndex];

  const removeTaskWidget = () => {
    onClose();
    if (widgetFullScreen) {
      toggleWidgetFullScreen('remove');
    }

    removePortfolioCreditWidgetData(tasksWidgetIndex);
    if (
      widRank &&
      widRank.widgetRank &&
      widRank?.widgetRank?.slots?.length > 0
    ) {
      callTelemetryRecord(
        'widgets',
        widRank?.widgetRank?.event_id,
        widRank?.widgetRank?.slots[0]?.id,
        ['click'],
      );
    }
  };

  type LoanDataType = {
    [key: string]: any;
  };

  const [loanWidgetData, setLoanWidgetData] = React.useState<LoanDataType[]>(
    [],
  );

  const loanWidgetDataRedux = allPortfolioWidgetReduxData[loanWidgetIndex];

  const [widgetCurrentActiveTab, setWidgetCurrentActiveTab] =
    React.useState('WidgetData');

  // const toggle = (tab: React.SetStateAction<string>) => {
  //   if (widgetCurrentActiveTab !== tab) setWidgetCurrentActiveTab(tab);
  // };

  const fetchrecievedTasks = async () => {
    try {
      const response = await axios.get(`${config.ER_ML_URL}/task/`);
      const data = Array.isArray(response.data)
        ? response.data
        : [response.data];
      setTaskData(data);
      setIsMainLoading(false);
    } catch (err) {
      showModal('something went wrong');
      setIsMainLoading(false);
    }
  };

  const fetchSentTasks = async () => {
    try {
      const response = await axios.get(`${config.ER_ML_URL}/task/sent`);
      const data = Array.isArray(response.data)
        ? response.data
        : [response.data];
      setSentTaskData(data);
      setIsMainLoading(false);
    } catch (err) {
      showModal('something went wrong');
      setIsMainLoading(false);
    }
  };

  useEffect(() => {
    if (activeTab === 'received') {
      fetchrecievedTasks();
    } else {
      fetchSentTasks();
    }
  }, [activeTab]);

  const formatDate = (dateStr: string) => {
    const date = new Date(dateStr);
    return date.toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
    });
  };

  const handleAddNewsFeedWidget = (
    newsId?: string,
    newsName?: string,
    newsSourceId?: string,
  ) => {
    if (newsId && newsName && newsSourceId) {
      addNewsFeedData({
        selectedNewsId: newsId,
        selectedNewsName: newsName,
        selectedNewsSourceId: newsSourceId,
        widgetType: 'NEWS_FEED',
        newsArray: [],
        newsEventId: '',
        q: '',
        value: 'news',
      });

      onClose();
      document.body.classList.remove('show-dataitem');
      navigate(`/newsfeed`);
    } else {
      showModal('Please select a news source.');
    }
  };

  const handleReject = async (
    taskId: string,
    reason: string,
    ownerId: string,
  ) => {
    const payload = { taskId, reason, ownerId };
    const response = await axios.post(
      `${config.ER_ML_URL}/task/reject`,
      payload,
    );
    const payloadForNotification = { ownerId, reason };
    if (response.status === 200) {
      fetchrecievedTasks();
      await ingService.shareRejectionStatus(payloadForNotification);
      showModal('Owner will be notified!', true);
    } else {
      showModal('There was a problem rejecting the task', false);
    }
  };

  const handleAddVisualisationWidget = (
    chartHeaders: any,
    chartType: string,
    portfolioId: string,
  ) => {
    if (chartHeaders && chartType && portfolioId) {
      const id = portfolioId;

      const payload = prepareChartPayload(chartHeaders, chartType);
      const chartFormValues: ChartFormDataProps = {
        chartTitle: '',
        visualisationType: {
          label: chartType,
          value: chartType?.toUpperCase(),
        },
        xAxisLabel: 'X Axis',
        yAxisLabel: 'Y Axis',
        fields: chartHeaders,
      };
      if (payload?.fields) {
        getChartsData({
          id,
          payload,
          chartFormValues,
          setIsLoading,
          showModal,
          addPortfolioVisualizationWidgetData,
          allPortfolioWidgetReduxData,
          navigate,
        });
      }
    }
  };

  const handleAddCreditWidget = async (
    key: string,
    portfolioId: string,
    fileName: string,
  ) => {
    if (key && portfolioId && fileName) {
      const columnsToHideQuery = '';
      setSearchQuery('');

      axios
        .get(
          `${config.ER_ML_URL}/portfolio/${portfolioId}?page=${currentPage}&is_single_file=True&${columnsToHideQuery}`,
        )
        .then(({ data }) => {
          const responseManipulation =
            searchQuery === ''
              ? [...(data?.data?.data || {})]
              : currentPage === 1;

          const allFileNames = [
            {
              portfolioId: {
                file_name: fileName,
                key: key,
              },
            },
          ];

          const selectedDataSourcesWidget = [
            {
              file: fileName,
              subFile: '',
              type: 'PORTFOLIO',
            },
          ];
          navigate(`/portfolio`);

          addPortfolioCreditWidgetData({
            ...creditWidgetData,
            widgetReferenceId: uuidv4(),
            portfolio_id: portfolioId,
            value: 'credits',
            widgetType: 'PORTFOLIO',
            rowPreviewData: responseManipulation,
            allFileNames,
            insertedId: [],
            oldPortfoliosData: [],
            combinedArray: [portfolioId],
            id: 0,
            portfoliosData: [],
            selectedDataSourcesWidget,
          });
        });
    }
  };

  const handleAddStatisticalWidget = async (
    portfolioId: string,
    portfolio_type: string,
    inputDataRange?: { [k: string]: { min: number; max: number }[] },
  ) => {
    if (portfolio_type && portfolioId) {
      const payload = {
        portfolio_ids: portfolioId,
      };
      const isUpdateData: boolean = true;

      const response = await axios({
        method: 'post',
        url: `${config.ER_ML_URL}/statistical/get-statistical-data?page=${
          isUpdateData ? 1 : currentPage
        }&limit=20`,
        data: payload,
      });

      let tempLoanWidgetData = [...loanWidgetData];

      if (isUpdateData) {
        tempLoanWidgetData = [];
        setCurrentPage(1);
      }

      const responseManipulation = [
        ...tempLoanWidgetData,
        ...(response?.data?.data || {}),
      ];

      let ranges = inputDataRange;
      const headerWithTypeObj = response?.data?.header_with_type;
      const financialData: { [key: string]: any }[] = [];
      const collateralData: { [key: string]: any }[] = [];

      for (const key in ranges) {
        if (headerWithTypeObj[key] === 'COLLATERAL') {
          collateralData.push({ [key]: ranges[key] });
        } else if (headerWithTypeObj[key] === 'FINANCIAL') {
          financialData.push({ [key]: ranges[key] });
        }
      }

      setLoanWidgetData(responseManipulation);

      addPortfolioLoanWidgetData({
        ...loanWidgetDataRedux,
        id: 0,
        inputDataRange: response?.data?.data,
        widgetReferenceId: uuidv4(),
        newFieldsArray: [],
        selectedStatPortfolio: portfolioId,
        value: 'loan',
        widgetType: 'PORTFOLIO',
        customWidgetName: '',
        collateralData: collateralData,
        financialData: financialData,
        isShared: true,
        loanWidgetData: loanWidgetData,
      });

      navigate(`/portfolio`);
    }
  };

  const handleNavLinkClick = (task: TaskData) => {
    if (task.type === 'NEWS') {
      handleAddNewsFeedWidget(task.newsId, task.newsName, task.newsSourceId);
    }
    if (task.type === 'VISUALISATION WIDGET') {
      handleAddVisualisationWidget(
        task.chartHeaders,
        task.chartType,
        task.portfolioId,
      );
    }
    if (task.type === 'CREDIT WIDGET') {
      handleAddCreditWidget(task.key, task.portfolioId, task.fileName);
    }
    if (task.type === 'STATISTICAL WIDGET') {
      handleAddStatisticalWidget(
        task.portfolioId,
        task.portfolio_type,
        task.inputDataRange,
      );
    }
  };

  const toggleTab = (tab: string) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const handleMarkForReview = async (taskId: string) => {
    const response = await axios.post(
      `${config.ER_ML_URL}/task/review/${taskId}`,
    );
    if (response.status === 200) {
      fetchrecievedTasks();
    } else {
      showModal('Error marking task for review', false);
    }
  };

  const handleMarkResolved = async (taskId: string) => {
    const response = await axios.post(
      `${config.ER_ML_URL}/task/resolve/${taskId}`,
    );
    if (response.status === 200) {
      fetchrecievedTasks();
    } else {
      showModal('Error marking task as resolved', false);
    }
  };

  return (
    <>
      {isLoading && isMainLoading && <AppLoader />}
      <div className={`common-widget`}>
        <div className="common-widget-header">
          {/* add class above line if tabs show in widget header `cwh-tabs` */}
          <div className="common-widget-header-title">
            <h2>Tasks</h2>

            {widgetFullScreen && (
              <div className="ms-6 no-drag">
                {/* Create Component Dropdown  */}
                <WidgetMenuDropdown
                  dropdownOpen={dropdownOpen}
                  toggleDropDown={toggleDropDown}
                  toggleExportWidgeTDataModal={toggleExportWidgeTDataModal}
                  toggleRemoveWidgetModal={toggleRemoveWidgetModal}
                />
              </div>
            )}
            {!widgetFullScreen && (
              <div className="d-flex aling-items-center ms-12 no-drag">
                <button
                  id={`fs-task-${tasksWidgetIndex}`}
                  type="button"
                  className="btn-icon"
                  onClick={() => {
                    toggleWidgetFullScreen('add');
                    addWidgetReferenceId(tasksWidgetIndex);
                    addWidgetType('TASKS');
                  }}
                >
                  <IconFullScreen />
                </button>
              </div>
            )}
            {/* flexbox */}
          </div>

          {widgetFullScreen && (
            <div className="common-widget-header-action no-drag">
              <button
                id={`fsrem-task-${tasksWidgetIndex}`}
                type="button"
                className="btn-icon"
                onClick={() => {
                  toggleWidgetFullScreen('remove');
                  setWidgetCurrentActiveTab('WidgetData');
                }}
              >
                <Iconclose />
              </button>
            </div>
          )}

          {!widgetFullScreen && (
            <div className="common-widget-header-action no-drag">
              {/* Create Component Dropdown  */}
              <WidgetMenuDropdown
                dropdownOpen={dropdownOpen}
                toggleDropDown={toggleDropDown}
                toggleExportWidgeTDataModal={toggleExportWidgeTDataModal}
                toggleRemoveWidgetModal={toggleRemoveWidgetModal}
              />
            </div>
          )}
        </div>
        <div
          className="common-widget-content"
          onClick={() => {
            if (
              widRank &&
              widRank.widgetRank &&
              widRank?.widgetRank?.slots?.length > 0
            ) {
              callTelemetryRecord(
                'widgets',
                widRank?.widgetRank?.event_id,
                widRank?.widgetRank?.slots[0]?.id,
                ['click'],
              );
            }
          }}
        >
          {/* Widget Chat */}

          {/* Chat True Show*/}
          {isChatOpen && !widgetFullScreen && (
            <div className="widget-chat">
              <PublicChat />
            </div>
          )}

          {/* Chat True Hide */}
          {!isChatOpen && (
            <TabContent activeTab={widgetCurrentActiveTab}>
              <div className="tabs-menu-line no-drag">
                <Nav tabs style={{ width: '100%' }}>
                  <NavItem style={{ width: '100%' }}>
                    {' '}
                    {/* Set to 50% to divide equally */}
                    <NavLink
                      className={classnames({
                        active: activeTab === 'received',
                      })}
                      onClick={() => toggleTab('received')}
                    >
                      Received Tasks
                    </NavLink>
                  </NavItem>
                  <NavItem style={{ width: '100%' }}>
                    <NavLink
                      className={classnames({
                        active: activeTab === 'sent',
                      })}
                      onClick={() => toggleTab('sent')}
                    >
                      Sent Tasks
                    </NavLink>
                  </NavItem>
                </Nav>
              </div>
              <TabPane tabId="WidgetData">
                <div className="no-drag">
                  <div className="table-responsive">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th style={{ width: '100px' }} scope="col">
                            Assignee
                          </th>
                          <th style={{ width: '120px' }} scope="col">
                            Widget
                          </th>
                          <th scope="col">Description</th>
                          <th style={{ width: '110px' }} scope="col">
                            Due Date
                          </th>
                          <th style={{ width: '140px' }} scope="col">
                            Status
                          </th>
                          <th style={{ width: '50px' }} scope="col"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {(activeTab === 'received'
                          ? taskData
                          : sentTaskData
                        ).map((task: TaskData, index: number) => (
                          <tr
                            key={index}
                            className={
                              task.status === 'Marked for review'
                                ? 'blink-border-orange'
                                : task.status === 'Resolved'
                                  ? 'blink-border-green'
                                  : task.status === 'Rejected' &&
                                      activeTab === 'sent'
                                    ? 'blink-border-red'
                                    : ''
                            }
                            style={{
                              backgroundColor:
                                task.status === 'Marked for review'
                                  ? '#FF9D0B1A'
                                  : task.status === 'Resolved'
                                    ? '#28a7451A'
                                    : task.status === 'Rejected' &&
                                        activeTab === 'sent'
                                      ? '#ff00001A'
                                      : '',
                            }}
                          >
                            <td>{task.name}</td>
                            <td>
                              <NavLink
                                to="#"
                                className="link"
                                onClick={() => handleNavLinkClick(task)}
                              >
                                {task.type}
                              </NavLink>
                            </td>
                            <td>
                              {task.status === 'Rejected' &&
                              activeTab === 'sent'
                                ? `Reason: ${task.rejectionReason}`
                                : task.description}
                            </td>
                            <td>{formatDate(task.sharingDueDate)}</td>
                            <td>{task.status}</td>

                            <td>
                              {activeTab === 'received' && (
                                <TaskWidgetActionDropdown
                                  onMarkForReview={() =>
                                    handleMarkForReview(task.taskId)
                                  }
                                  onReject={(reason) =>
                                    handleReject(
                                      task.taskId,
                                      reason,
                                      task.ownerId,
                                    )
                                  }
                                  onMarkResolved={() =>
                                    handleMarkResolved(task.taskId)
                                  }
                                />
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </TabPane>
            </TabContent>
          )}
        </div>

        {/* {sendWidgetAsTaskModal && (
          <SendWidgetAsTask
            modal={sendWidgetAsTaskModal}
            toggle={toggleSendWidgetAsTaskModal}
          />
        )}

        {shareExternallyModal && (
          <ShareWidgetExternally
            modal={shareExternallyModal}
            toggle={toggleShareExternallyModal}
          />
        )} */}

        {exportWidgeTDataModal && (
          <ExportWidgeTData
            modal={exportWidgeTDataModal}
            toggle={toggleExportWidgeTDataModal}
          />
        )}

        {removeWidgetModal && (
          <RemoveWidget
            modal={removeWidgetModal}
            toggle={toggleRemoveWidgetModal}
            removeWidget={removeTaskWidget}
          />
        )}
        {/* {showSessionModal && (
          <SessionModal
            modal={showSessionModal}
            toggle={toggleShowSessionModal}
            // fileKey={fileKey}
          />
        )} */}
      </div>
    </>
  );
}
