import React, { useState, useEffect } from 'react';
// import toast, { Toaster } from 'react-hot-toast';
import { requestPermission, onMessageListener } from '../../../firebase';

const Notification_custom = () => {
  const [notification, setNotification] = useState({ title: '', body: '' });
  // const notify = () => toast(<ToastDisplay />);
  // function ToastDisplay() {
  //   return (
  //     <div>
  //       <p>
  //         <b>{notification?.title}</b>
  //       </p>
  //       <p>{notification?.body}</p>
  //     </div>
  //   );
  // }

  useEffect(() => {
    if (notification?.title) {
      // notify();
      new Notification(notification.title, {
        body: notification.body,
      });
    }
    requestPermission();
  }, [notification]);

  onMessageListener().then((payload: any) => {
    setNotification({
      title: payload?.notification?.title,
      body: payload?.notification?.body,
    });
  });

  // return <Toaster />;
  return <></>;
};

export default Notification_custom;
