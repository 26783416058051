import io, { Socket } from 'socket.io-client';
import React from 'react';
import config from '../../config';

let webSocket: Socket | null = null;

const webSocketConnectionInit = () => {
  if (localStorage.getItem('_ERFTKEN')) {
    const WebSocket: any = io(config.WEBSOCKET_URL, {
      //path: '/api/socket.io',
      transportOptions: {
        polling: {
          extraHeaders: {
            'x-er-auth-token': localStorage.getItem('_ERFTKEN'),
          },
        },
      },
    });

    webSocket = WebSocket;

    return WebSocket;
  }
};

const WebSocketHoc = (WrappedComponent: any) => {
  const Hoc = (props: any) => {
    return (
      <WrappedComponent
        {...props}
        webSocket={webSocket}
        setWebSocketConnection={webSocketConnectionInit}
      />
    );
  };
  return Hoc;
};
export default WebSocketHoc;
