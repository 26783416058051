import React, { /* useEffect, */ useRef /* useState */ } from 'react';
import Plot from 'react-plotly.js';
import {
  getXAxisConfig,
  getYAxisConfig,
  getLayoutConfig,
} from '../../chartsTheme';

const HeatMap = ({
  chartData,
  // chartTitle,
  initialXAxis,
  initialYAxis,
}: {
  chartData: any;
  chartTitle: string;
  initialXAxis: string;
  initialYAxis: string;
}) => {
  // const [updatedData, setUpdatedData] = useState([] as any);
  const layoutConfig = getLayoutConfig();

  const plotRef = useRef<any>(null);

  const resizeLayout = () => {
    plotRef.current.resizeHandler();
  };
  return (
    <>
      {
        <div className="chart-wrapper" onMouseDown={e => e.stopPropagation()}>
          <Plot
            ref={plotRef}
            data={chartData}
            // data={updatedData}
            config={{
              scrollZoom: true,
              editable: true,
              responsive: true,
              displaylogo: false,
            }}
            layout={{
              title: '',
              width: document.getElementById('chart-dynamic-div')?.clientWidth,

              xaxis: getXAxisConfig(initialXAxis),
              yaxis: getYAxisConfig(initialYAxis),
              ...layoutConfig,
            }}
            useResizeHandler={true}
            onRelayout={resizeLayout}
          />
        </div>
      }
    </>
  );
};

export default HeatMap;
