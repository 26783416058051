/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Input,
  InputGroup,
  InputGroupAddon,
} from 'reactstrap';
import { IconExclamationCircle } from '../public/Icons';

type CreditWidgetSaveModalProps = {
  modal: boolean;
  toggle: () => void;
  onSave: (chartName: string) => void;
};

const CreditWidgetSaveModal = ({
  modal,
  toggle,
  onSave,
}: CreditWidgetSaveModalProps) => {
  const [name, setName] = useState('');
  const [isInvalid, setIsInvalid] = useState(false);

  const handleSave = () => {
    if (name.trim()) {
      onSave(name);
      toggle();
      setIsInvalid(false);
    } else {
      setIsInvalid(true);
    }
  };

  return (
    <Modal isOpen={modal} toggle={toggle} centered size="md">
      <ModalHeader toggle={toggle}>Save Credit Widget</ModalHeader>
      <ModalBody>
        <p>Please enter a name for your widget</p>
        <InputGroup>
          <Input
            type="text"
            value={name}
            onChange={e => {
              setName(e.target.value);
              if (e.target.value.trim()) setIsInvalid(false);
            }}
            placeholder="Enter Credit Widget Name"
            style={{
              borderColor: isInvalid ? 'red' : '',
            }}
          />
          {isInvalid && (
            <InputGroupAddon addonType="append">
              <IconExclamationCircle width={20} height={20} />
            </InputGroupAddon>
          )}
        </InputGroup>
        {isInvalid && (
          <p style={{ color: 'red', marginTop: '5px' }}>
            Please enter a valid name.
          </p>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
        <Button color="primary" onClick={handleSave}>
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default CreditWidgetSaveModal;
