import { useEffect, useState } from 'react';
import PublicHeader from '../../components/public/PublicHeader';
import CustomSelect from '../../components/ui/CustomSelect';
import AuthService from '../../services/auth.service';
import { LabelValue, User } from '../../../@types/UserProfileTypes';
import { Formik, Form, Field, ErrorMessage } from 'formik';

import queryString from 'query-string';
import {
  creditsOptions,
  functionsOptions,
  genderOptions,
  positionOptions,
  productsOptions,
  typeOptions,
} from '../../redux/constant';
import axios, { AxiosError } from 'axios';
import AppLoader from '../../components/loaders/AppLoader';
import useAction from '../../components/hooks/useAction';
import { useLocation } from 'react-router';
// import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { userDetailsData } from '../../redux/selector/UserDetailsSelector';
import * as Yup from 'yup';
// import { userData } from '../../redux/selector/UserSelector';

const service = new AuthService();

interface UserDetail {
  [key: string]: any;
}

const UpdateProfile = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState<User | null>(null);
  const [formData, setFormData] = useState<User | null>(null);
  const [isChanged, setIsChanged] = useState(false);
  const { updateUserDetails, showModal } = useAction();
  const userDetails = useSelector(userDetailsData);
  // const userDataObj = useSelector(userData);
  // const navigate = useNavigate();

  const isAxiosError = (error: any): error is AxiosError => {
    return axios.isAxiosError(error);
  };

  const { search } = useLocation();

  useEffect(() => {
    let { token } = queryString.parse(search);

    if (token) {
      localStorage.setItem('_ERFTKEN', token as string);
    }

    // if (userDataObj?.userDetail?.license?.licenseType === 'BASIC') {
    //   navigate('/newsfeed');
    // }
  }, [search]);

  const getUserData = async () => {
    setIsLoading(true);
    try {
      const data: any = await service.getLoginUser();
      setIsLoading(false);
      setUser(data);
      setFormData(data);
    } catch (error) {
      setIsLoading(false);
      if (isAxiosError(error)) {
        showModal(
          (error.response?.data as { error?: string })?.error ||
            'Unexpected Axios error',
        );
      } else {
        showModal('An unexpected error occurred');
      }
    }
  };

  useEffect(() => {
    if (userDetails?._id) getUserData();
  }, [userDetails?._id]);

  useEffect(() => {
    if (user && formData) {
      setIsChanged(JSON.stringify(user) !== JSON.stringify(formData));
    }
  }, [user, formData]);

  const selectedCredits = creditsOptions.filter((option) =>
    formData?.userDetail?.credits?.includes(option.value),
  );

  const selectedFunctions = functionsOptions.filter((option) =>
    formData?.userDetail?.function?.includes(option.value),
  );

  const selectedProducts = productsOptions.filter((option) =>
    formData?.userDetail?.products?.includes(option.value),
  );

  const placeholder = 'Select credits';
  const functionsPlaceholder = 'Select functions';
  const productsPlaceholder = 'Select products';

  const handleSelectChange = (selectedOptions: any[], key: string) => {
    const selectedValues = selectedOptions.map((option) => option.value);
    setFormData((prevFormData: any) => ({
      ...prevFormData,
      userDetail: {
        ...prevFormData.userDetail,
        [key]: selectedValues,
      },
    }));
  };

  const handleSingleSelectChange = (
    selectedOption: LabelValue,
    key: string,
  ) => {
    const selectedValue = selectedOption ? selectedOption.value : null;
    setFormData((prevFormData: any) => ({
      ...prevFormData,
      userDetail: {
        ...prevFormData.userDetail,
        [key]: selectedValue,
      },
    }));
  };

  const saveChanges = async () => {
    const updatedFields: UserDetail | undefined = getUpdatedFields(
      user?.userDetail,
      formData?.userDetail,
    );

    if (updatedFields) {
      if (Object.prototype.hasOwnProperty.call(updatedFields, 'function')) {
        updatedFields.userFunction = updatedFields.function;
        delete updatedFields.function;
      }

      setIsLoading(true);
      service
        .updateLoginProfile(updatedFields)
        .then(() => {
          setIsLoading(false);
          showModal('Updated Successfully', true);
          getUserData();
          updateUserDetails(updatedFields);
        })
        .catch((error) => {
          setIsLoading(false);
          if (isAxiosError(error)) {
            showModal(
              (error.response?.data as { error?: string })?.error ||
                'Unexpected Axios error',
            );
          } else {
            showModal('An unexpected error occurred');
          }
        });
    }
  };

  const getUpdatedFields = (
    original: UserDetail | undefined,
    updated: UserDetail | undefined,
  ) => {
    const changes: UserDetail | undefined = {};

    const compareObjects = (
      origObj: UserDetail | undefined,
      updatedObj: UserDetail | undefined,
    ) => {
      for (const key in updatedObj) {
        if (Object.hasOwnProperty.call(updatedObj, key)) {
          const newValue = updatedObj[key];
          const oldValue = origObj ? origObj[key] : undefined;

          if (
            typeof newValue === 'object' &&
            newValue !== null &&
            !Array.isArray(newValue)
          ) {
            compareObjects(oldValue || {}, newValue);
          } else if (newValue !== oldValue) {
            changes[key] = newValue;
          }
        }
      }
    };

    compareObjects(original, updated);
    return changes;
  };

  const PasswordSchema = Yup.object().shape({
    newPassword: Yup.string()
      .min(8, 'Password is too short - should be 8 chars minimum.')
      .required('New password is required'),
    repeatPassword: Yup.string()
      .oneOf([Yup.ref('newPassword'), undefined], 'Passwords must match')
      .required('Repeat password is required'),
  });

  const changePasswordHandler = async (
    values: any,
    { resetForm }: { resetForm: () => void },
  ) => {
    setIsLoading(true);
    try {
      const payload = {
        password: values.newPassword,
      };
      const data: any = await service.changePassword(payload);
      setIsLoading(false);
      setUser(data);
      setFormData(data);
      showModal('Password Changed Successfully.', true);

      // Reset the form after success
      resetForm();
    } catch (error) {
      setIsLoading(false);
      if (isAxiosError(error)) {
        showModal(
          (error.response?.data as { error?: string })?.error ||
            'Unexpected Axios error',
        );
      } else {
        showModal('An unexpected error occurred');
      }
    }
  };
  return (
    <>
      {isLoading && <AppLoader />}
      <div className="page-content">
        <PublicHeader />
        <div className="page-middle-content">
          <div className="container">
            <div className="main-widget pe-0">
              <div className="react-grid-item">
                <div className="common-widget">
                  <div className="common-widget-header">
                    <div className="common-widget-header-title">
                      <h2 className="me-10">User Profile</h2>
                    </div>
                  </div>
                  <div className="common-widget-content overflow-hidden">
                    <div className="user-details">
                      <p className="user-name">{user?.name}</p>
                      <p className="user-email">{user?.email}</p>
                    </div>
                    <div className="user-form ud-scroll">
                      <div className="user-form-item">
                        <h5 className="fi-heading">Details</h5>
                        <div className="row">
                          <div className="col-12 col-lg-10 offset-2">
                            <div className="row">
                              <div className="col-12 col-xl-6">
                                <div className="row">
                                  {user?.userDetail?.organization
                                    ?.organizationName && (
                                    <div className="col-12 col-xl-6">
                                      <div className="form-group">
                                        <label className="form-label">
                                          MY ORGANISATION
                                        </label>
                                        <p className="mb-0">
                                          {
                                            user?.userDetail?.organization
                                              ?.organizationName
                                          }
                                        </p>
                                      </div>
                                    </div>
                                  )}
                                  {user?.userDetail?.department
                                    ?.departmentName && (
                                    <div className="col-12 col-xl-6">
                                      <div className="form-group">
                                        <label className="form-label">
                                          MY DEPARTMENT
                                        </label>
                                        <p className="mb-0">
                                          {
                                            user?.userDetail?.department
                                              ?.departmentName
                                          }
                                        </p>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-12 col-xl-6">
                                <div className="row">
                                  <div className="col-12 col-xl-5">
                                    <div className="form-group">
                                      <label className="form-label">
                                        Gender
                                      </label>
                                      <CustomSelect
                                        options={genderOptions}
                                        value={genderOptions.find(
                                          (option) =>
                                            option.value ===
                                            formData?.userDetail?.sex,
                                        )}
                                        placeholder="Select gender"
                                        onChange={(
                                          selectedOption: LabelValue,
                                        ) =>
                                          handleSingleSelectChange(
                                            selectedOption,
                                            'sex',
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="col-12 col-xl-7">
                                    <div className="form-group">
                                      <label className="form-label">
                                        Position
                                      </label>
                                      <CustomSelect
                                        options={positionOptions}
                                        value={positionOptions.find(
                                          (option) =>
                                            option.value ===
                                            formData?.userDetail?.position,
                                        )}
                                        placeholder="Select position"
                                        onChange={(
                                          selectedOption: LabelValue,
                                        ) =>
                                          handleSingleSelectChange(
                                            selectedOption,
                                            'position',
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-12 col-xl-6">
                                <div className="row">
                                  <div className="col-12 col-xl-7">
                                    <div className="form-group">
                                      <label className="form-label">Type</label>
                                      <CustomSelect
                                        options={typeOptions}
                                        value={typeOptions.find(
                                          (option) =>
                                            option.value ===
                                            formData?.userDetail?.type,
                                        )}
                                        placeholder="Select type"
                                        onChange={(
                                          selectedOption: LabelValue,
                                        ) =>
                                          handleSingleSelectChange(
                                            selectedOption,
                                            'type',
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row row-cols-1 row-cols-lg-2">
                              <div className="col">
                                <div className="form-group">
                                  <label className="form-label">Products</label>
                                  <CustomSelect
                                    options={productsOptions}
                                    value={
                                      selectedProducts.length > 0
                                        ? selectedProducts
                                        : null
                                    }
                                    isMulti
                                    placeholder={
                                      selectedProducts.length === 0
                                        ? productsPlaceholder
                                        : undefined
                                    }
                                    onChange={(selectedOptions: LabelValue[]) =>
                                      handleSelectChange(
                                        selectedOptions,
                                        'products',
                                      )
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col">
                                <div className="form-group">
                                  <label className="form-label">
                                    Functions
                                  </label>
                                  <CustomSelect
                                    options={functionsOptions}
                                    value={
                                      selectedFunctions.length > 0
                                        ? selectedFunctions
                                        : null
                                    }
                                    isMulti
                                    placeholder={
                                      selectedFunctions.length === 0
                                        ? functionsPlaceholder
                                        : undefined
                                    }
                                    onChange={(selectedOptions: LabelValue[]) =>
                                      handleSelectChange(
                                        selectedOptions,
                                        'function',
                                      )
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col">
                                <div className="form-group">
                                  <label className="form-label">Credits</label>
                                  <CustomSelect
                                    options={creditsOptions}
                                    value={
                                      selectedCredits.length > 0
                                        ? selectedCredits
                                        : null
                                    }
                                    isMulti
                                    placeholder={
                                      selectedCredits.length === 0
                                        ? placeholder
                                        : undefined
                                    }
                                    onChange={(selectedOptions: LabelValue[]) =>
                                      handleSelectChange(
                                        selectedOptions,
                                        'credits',
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="user-form-item">
                        <h5 className="fi-heading">Security</h5>
                        <div className="row">
                          <div className="col-12 col-lg-10 offset-2">
                            <div className="row">
                              <div className="col-12 col-lg-11">
                                {/* <div className="col-12 col-lg-4">
                                    <div className="form-group">
                                      <label className="form-label">
                                        Current Password
                                      </label>

                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder={'Enter current password'}
                                      />
                                    </div>
                                  </div> */}
                                <Formik
                                  initialValues={{
                                    newPassword: '',
                                    repeatPassword: '',
                                  }}
                                  validationSchema={PasswordSchema}
                                  onSubmit={changePasswordHandler}
                                >
                                  {({ isValid, dirty }) => (
                                    <Form>
                                      <div className="row">
                                        <div className="col-12 col-lg-4">
                                          <div className="form-group">
                                            <label className="form-label">
                                              New Password
                                            </label>
                                            <Field
                                              type="password"
                                              name="newPassword"
                                              className={`form-control`}
                                              placeholder="Enter new password"
                                            />
                                            <ErrorMessage
                                              name="newPassword"
                                              component="div"
                                              className="invalid-feedback"
                                            />
                                          </div>
                                        </div>

                                        <div className="col-12 col-lg-4">
                                          <div className="form-group">
                                            <label className="form-label">
                                              Repeat New Password
                                            </label>
                                            <Field
                                              type="password"
                                              name="repeatPassword"
                                              className={`form-control`}
                                              // ${
                                              //   errors.repeatPassword &&
                                              //   (touched.repeatPassword ||
                                              //     touched.newPassword)
                                              //     ? 'is-invalid'
                                              //     : ''
                                              // }
                                              placeholder="Enter new password again"
                                            />
                                            <ErrorMessage
                                              name="repeatPassword"
                                              component="div"
                                              className="invalid-feedback"
                                            />
                                          </div>
                                        </div>

                                        <div className="col-12 col-lg-4">
                                          <div className="form-group label-empty">
                                            <button
                                              type="submit"
                                              className="btn btn-primary"
                                              disabled={!dirty || !isValid}
                                            >
                                              Submit
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </Form>
                                  )}
                                </Formik>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="gap-12 d-flex justify-content-center mb-4">
                    <button
                      className="btn btn-outline-info"
                      onClick={() => setFormData(user)}
                    >
                      Discard
                    </button>
                    <button
                      className="btn btn-primary"
                      disabled={!isChanged}
                      onClick={saveChanges}
                    >
                      Save changes
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateProfile;
