import React from 'react';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import {
  IconAction,
  IconArchive,
  IconCross,
  IconTick,
} from '../../../../components/public/Icons';
import RejectionModal from '../../../../components/modals/RejectionModal';

const TaskWidgetActionDropdown = ({
  onMarkForReview,
  onReject,
  onMarkResolved,
}: {
  onMarkForReview: () => void;
  // eslint-disable-next-line no-unused-vars
  onReject: (reason: string) => void;
  onMarkResolved: () => void;
}) => {
  const [dropdownOpen, setDropDownOpen] = React.useState(false);
  const [rejectModalOpen, setRejectModalOpen] = React.useState(false);
  const toggleDropDown = () => {
    setDropDownOpen(state => !state);
  };
  const toggleRejectModal = () => {
    setRejectModalOpen(state => !state);
  };
  return (
    <>
      <Dropdown
        className="add-dropdown"
        isOpen={dropdownOpen}
        toggle={toggleDropDown}
      >
        <DropdownToggle caret direction="down" className="btn-icon icon-hr">
          <IconAction />
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={onMarkForReview}>
            <IconTick fill="#E0E0E0" />
            Mark for Review
          </DropdownItem>
          <DropdownItem onClick={toggleRejectModal}>
            <IconCross />
            Reject
          </DropdownItem>
          <DropdownItem onClick={onMarkResolved}>
            <IconArchive />
            Resolved
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
      <RejectionModal
        modal={rejectModalOpen}
        toggle={toggleRejectModal}
        onSubmit={onReject}
      />
    </>
  );
};

export default TaskWidgetActionDropdown;
