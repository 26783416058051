import React from 'react';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import {
  IconAction,
  IconAsterisk,
  IconCross,
  IconLock,
  IconResendInvite,
} from '../../../components/public/Icons';
import { UserType } from '../../../../@types/SettingsType';

interface UserActionsTypeProps {
  data: UserType;
  toggleSuspendUserModal: () => void;
  toggleActivateUserModal: () => void;
  toggleRemoveUserModal: () => void;
  toggleResendUserModal: () => void;
  toggleResetPasswordUserModal: () => void;
}

const UsersActionDropdown = ({
  data,
  toggleSuspendUserModal,
  toggleActivateUserModal,
  toggleRemoveUserModal,
  toggleResendUserModal,
  toggleResetPasswordUserModal,
}: UserActionsTypeProps) => {
  const [dropdownOpen, setDropDownOpen] = React.useState(false);
  const toggleDropDown = () => {
    setDropDownOpen(state => !state);
  };

  return (
    <>
      <Dropdown
        className="add-dropdown"
        isOpen={dropdownOpen}
        toggle={toggleDropDown}
      >
        <DropdownToggle caret direction="down" className="btn-icon icon-hr">
          <IconAction />
        </DropdownToggle>
        <DropdownMenu>
          {data?.userDetail?.userStatus === 'ACTIVE' && (
            <>
              <DropdownItem onClick={toggleSuspendUserModal}>
                <IconResendInvite fill="#E0E0E0" />
                Suspend
              </DropdownItem>
              <DropdownItem onClick={toggleResetPasswordUserModal}>
                <IconAsterisk />
                Reset Password
              </DropdownItem>
            </>
          )}
          {data?.userDetail?.userStatus === 'INACTIVE' && (
            <DropdownItem onClick={toggleActivateUserModal}>
              <IconLock fill="#E0E0E0" />
              Activate
            </DropdownItem>
          )}
          {data?.userDetail?.userStatus === 'PENDING' && (
            <DropdownItem onClick={toggleResendUserModal}>
              <IconLock fill="#E0E0E0" />
              Resend Invite
            </DropdownItem>
          )}

          <DropdownItem onClick={toggleRemoveUserModal}>
            <IconCross />
            Remove
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

export default UsersActionDropdown;
