/* eslint-disable */
import { useState, useEffect } from 'react';
import PublicHeader from '../../../components/public/PublicHeader';
// import {
//   Dropdown,
//   DropdownItem,
//   DropdownMenu,
//   DropdownToggle,
// } from 'reactstrap';
// import { IconPlus } from '../../../components/public/Icons';

import NPLWidget from './widgets/npl_widgets/NPLWidget';
import { WidthProvider, Responsive } from 'react-grid-layout';
import { useSelector } from 'react-redux';
import { widgetRankData } from '../../../redux/selector/WidgetRankSelector';
import { useLocation } from 'react-router';
import { callTelemetryRecord } from '../../../helpers/utils.helper';
import LOANFinancials from './widgets/loan_widgets/LOANFinancials';
// import useAction from '../../../components/hooks/useAction';
import { AppStateType } from '../../../redux/reducers';
import { allPortfolioWidgetsSelector } from '../../../redux/selector/PortfolioWidgetsSelector';
import CreditWidgetCopy from './widgets/credit_widget/CreditWidget';
import ReceivablesWidget from './widgets/receivables_widgets/ReceivablesWidget';
import PortfolioMap from './widgets/credit_widget/addItemPortfolio/PortfolioMap';
import ShowDataSources from './widgets/credit_widget/addItemDataSource/ShowDataSources';
import ShowAugumentationDataSources from './widgets/credit_widget/addItemAugumentationDataSource/ShowAugumentationDataSources';
import ShowKnowledgeBaseItems from './widgets/credit_widget/addItemKnowledgeBaseItem/ShowKnowledgeBaseItems';
import ChartsWrapper from './Charts/components/ChartsWrapper';
import AddPortfolioWidget from './addportfoliowidget/AddPortfolioWidget';

const jumpBy = [0, 0, 1, 1, 2, 2, 3, 3, 4, 4, 5, 5, 6, 6, 7, 7, 8, 8];

type _widgets =
  | 'credits'
  | 'credits_copy'
  | 'npl'
  | 'npl_collateral'
  | 'npl_loan'
  | 'loan'
  | 'loan_loan'
  | 'loan_collateral'
  | 'receivable'
  | 'receivable_loan'
  | 'receivable_collateral'
  | 'embedded_browser';

const ResponsiveWidth = WidthProvider(Responsive);
const layouts = {
  md: [
    { i: 'a', x: 0, y: 0, w: 6, h: 4 },
    { i: 'b', x: 6, y: 0, w: 6, h: 4 },
    { i: 'c', x: 4, y: 6, w: 6, h: 4 },
    { i: 'd', x: 4, y: 6, w: 6, h: 4 },
    { i: 'e', x: 4, y: 6, w: 6, h: 4 },
    { i: 'f', x: 4, y: 6, w: 6, h: 4 },
    { i: 'g', x: 4, y: 6, w: 6, h: 4 },
  ],
};
const Portfolio = () => {
  // const { creditWidget } = useAction();
  // const [dropdownOpen, setDropDownOpen] = useState(false);
  const location = useLocation();
  const widRank = useSelector(widgetRankData);
  const [widgetFullScreen, setWidgetFullScreen] = useState(false);

  const allPortfolioWidgetReduxData = useSelector(allPortfolioWidgetsSelector);
  // const toggleDropDown = () => {
  //   setDropDownOpen(state => !state);
  // };
  const [widgetModal, setWidgetModal] = useState(false);
  const toggleWidgetWorkflow = () => setWidgetModal(!widgetModal);
  const [widgetIndex, setWidgetIndex] = useState<number | null>(null);
  const [widgetTypeToUpdate, setWidgetTypeToUpdate] = useState('');

  const widgetsCache = useSelector(
    (state: AppStateType) => state.PortfolioWidgetReducer.widgetCache,
  );

  //update portfolio maaping
  const [addItemPortfolioModal, setAddItemPortfolioModal] = useState(false);
  const toggleAddItemPortfolio = () => {
    document.body.classList.toggle('show-dataitem');
    document.body.classList.toggle('widget-fullscreen');
    setAddItemPortfolioModal(!addItemPortfolioModal);
  };

  //update data source maaping
  const [addItemDataSourceModal, setAddItemDataSourceModal] = useState(false);
  const toggleShowDataSources = () => {
    document.body.classList.toggle('show-dataitem');
    document.body.classList.toggle('widget-fullscreen');
    setAddItemDataSourceModal(!addItemDataSourceModal);
  };

  //update augumentation data source maaping
  const [
    addItemAugumentationDataSourceModal,
    setAddItemAugumentationDataSourceModal,
  ] = useState(false);
  const toggleAugumentationDataSourcesModal = () => {
    document.body.classList.toggle('show-dataitem');
    document.body.classList.toggle('widget-fullscreen');
    setAddItemAugumentationDataSourceModal(
      !addItemAugumentationDataSourceModal,
    );
  };

  //update knowledge base items
  const [addKnowledgeBaseItemModal, setAddKnowledgeBaseItemModal] =
    useState(false);
  const toggleKnowledgeBaseItemModal = () => {
    document.body.classList.toggle('show-dataitem');
    document.body.classList.toggle('widget-fullscreen');
    setAddKnowledgeBaseItemModal(!addKnowledgeBaseItemModal);
  };

  const _widgetInitials: {
    label: string;
    value: _widgets;
    component: any;
  }[] = [
    {
      label: 'Credit Widget',
      value: 'credits',
      component: (index: any) => (
        <CreditWidgetCopy
          onClose={(val: _widgets) => closeHandler('credits', index)}
          toggleWidgetWorkflow={toggleWidgetWorkflow}
          setWidgetIndex={setWidgetIndex}
          setWidgetTypeToUpdate={setWidgetTypeToUpdate}
          creditIndex={index}
          type="PORTFOLIO"
          widgetFullScreen={widgetFullScreen}
          setWidgetFullScreen={setWidgetFullScreen}
        />
      ),
    },

    {
      label: 'Analytics Widget',
      value: 'embedded_browser',
      component: (index: number) => {
        return (
          <ChartsWrapper
            onClose={(val: _widgets) => closeHandler('embedded_browser', index)}
            embeddedBrowserWidgetIndex={index}
            type="PORTFOLIO"
            widgetFullScreen={widgetFullScreen}
            setWidgetFullScreen={setWidgetFullScreen}
          />
        );
      },
    },

    {
      label: 'NPL Financials',
      value: 'npl',
      component: (index: any) => (
        <NPLWidget
          onClose={(val: _widgets) => closeHandler('npl', index)}
          nplWidgetIndex={index}
          type="PORTFOLIO"
          widgetFullScreen={widgetFullScreen}
          setWidgetFullScreen={setWidgetFullScreen}
        />
      ),
    },

    {
      label: 'LOAN Financials',
      value: 'loan',
      component: (index: any) => (
        <LOANFinancials
          onClose={(val: _widgets) => closeHandler('loan', index)}
          loanWidgetIndex={index}
          type="PORTFOLIO"
          widgetFullScreen={widgetFullScreen}
          setWidgetFullScreen={setWidgetFullScreen}
        />
      ),
    },

    {
      label: 'Receivables Widget',
      value: 'receivable',
      component: (index: any) => (
        <ReceivablesWidget
          onClose={(val: _widgets) => closeHandler('receivable', index)}
          receivablesWidgetIndex={index}
          type="PORTFOLIO"
          widgetFullScreen={widgetFullScreen}
          setWidgetFullScreen={setWidgetFullScreen}
        />
      ),
    },
  ];

  const [widgetToShow, setWidgetToShow] = useState<
    { label: string; value: _widgets; component: any }[] | null
  >(null);

  const [widgets, setWidgets] = useState<
    {
      label: string;
      value: _widgets;
      component: any;
    }[]
  >(_widgetInitials);

  //const user = useSelector(userData);
  // const [layout, setLayout] = useState(layoutSchema);
  //const [layout] = useState(layoutSchema);

  //const { search } = useLocation();

  const closeHandler = (widgetName: _widgets, index: number) => {
    setWidgetToShow((toShow) => {
      if (toShow === null) {
        return null;
      }
      return toShow.filter((wid, widIndex) => widIndex !== index);
    });
    /*setWidgets(oldWidget => {
      let widgetToAdd = _widgetInitials.find(
        widget => widget.value === widgetName,
      );
      if (widgetToAdd) {
        return [...oldWidget, widgetToAdd];
      }
      return [...oldWidget];
    });*/
  };

  useEffect(() => {
    if (allPortfolioWidgetReduxData?.length) {
      let newArray = [] as any;

      allPortfolioWidgetReduxData.forEach((y: any, widReduxIndex: number) => {
        y.id = widReduxIndex;

        _widgetInitials.forEach((x) => {
          if (y.value === x.value) {
            newArray.push(x);
          }
        });
      });

      setWidgetToShow(newArray);
    }
  }, [allPortfolioWidgetReduxData?.length, widgetFullScreen]);

  useEffect(() => {
    if (!allPortfolioWidgetReduxData.length) {
      if (
        widRank &&
        widRank.widgetRank &&
        widRank?.widgetRank?.slots?.length > 0
      ) {
        let widToShow =
          widRank?.widgetRank?.slots[0]?.widget_group?.features?.filter(
            (x: any) =>
              x.platform_sections.includes(location.pathname.split('/')[1]),
          );

        const newArr = [] as any;
        let widArry = [] as any;

        _widgetInitials.forEach((x: any) => {
          widToShow.forEach((y: any) => {
            if (x.value === y.name) {
              newArr.push(x);
              return;
            } else {
              if (!newArr.includes(x)) {
                widArry.push(x);
              }
            }
          });
        });

        widArry = widArry
          .filter(
            (value: any, index: any, self: any) =>
              index === self.findIndex((t: any) => t.value === value.value),
          )
          .filter((val: any) => !newArr.includes(val));

        if (newArr?.length === 0) {
          widArry = [..._widgetInitials];
        }

        const filtered = widgets.filter((widget) =>
          widgetsCache.includes(widget.value),
        );

        setWidgetToShow([...newArr, ...filtered]);

        //setWidgets(widArry);
        setWidgets([..._widgetInitials]);
      }
    }
  }, [widRank]);

  const [dimensions] = useState<
    {
      i: string;
      x: number;
      y: number;
      w: number;
      h: number;
      minW: number;
      maxW: number;
      minH: number;
      maxH: number;
    }[]
  >([{ i: '0', x: 0, y: 0, w: 6, h: 4, minW: 2.5, minH: 2, maxW: 7, maxH: 5 }]);

  return (
    <>
      {/* <Sidebar /> */}
      <div className="page-content ">
        <PublicHeader />
        {/* <PublicPageHeader /> */}
        <div className="page-middle-content">
          <div className="main-widget" id={'main-widget'}>
            <ResponsiveWidth
              breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
              cols={{ lg: 12, md: 10, sm: 6, xs: 2, xxs: 1 }}
              className="text-white res-view"
              layouts={layouts}
              // margin="[10, 10]"
              draggableCancel=".no-drag"
              // isDraggable={window.innerWidth > 767 ? true : false}
              isDraggable={
                widgetFullScreen
                  ? false
                  : window.innerWidth > 767
                    ? true
                    : false
              }
              onResizeStop={() => {
                if (
                  widRank &&
                  widRank.widgetRank &&
                  widRank?.widgetRank?.slots?.length > 0
                ) {
                  callTelemetryRecord(
                    'widgets',
                    widRank?.widgetRank?.event_id,
                    widRank?.widgetRank?.slots[0]?.id,
                    ['resize'],
                  );
                }
              }}
            >
              {widgetToShow?.map((widget, index) => {
                let grid = dimensions[0];

                if (index % 2 === 1) {
                  grid = {
                    i: `${index}`,
                    x: 0,
                    y: grid.h * jumpBy[index],
                    w: 6,
                    h: 4,
                    minW: 2.5,
                    minH: 2,
                    maxW: 7,
                    maxH: 5,
                  };
                }

                if (index % 2 === 1) {
                  grid = {
                    i: `${index}`,
                    x: 6,
                    y: grid.h * jumpBy[index],
                    w: 6,
                    h: 4,
                    minW: 2.5,
                    minH: 2,
                    maxW: 7,
                    maxH: 5,
                  };
                }

                return (
                  <div key={index} data-grid={grid}>
                    {widget.component(index)}
                  </div>
                );
              })}
            </ResponsiveWidth>
          </div>
        </div>

        {widgetModal && (
          <AddPortfolioWidget
            togglePortfolioWidget={toggleWidgetWorkflow}
            isUpdate={true}
            widgetIdOfIndex={widgetIndex}
            widgetTypeToUpdate={widgetTypeToUpdate}
          />
        )}

        {addItemPortfolioModal && (
          <PortfolioMap togglePortfolioWidget={toggleAddItemPortfolio} />
        )}

        {addItemDataSourceModal && (
          <ShowDataSources toggleShowDataSources={toggleShowDataSources} />
        )}

        {addItemAugumentationDataSourceModal && (
          <ShowAugumentationDataSources
            toggleAugumentationDataSourcesModal={
              toggleAugumentationDataSourcesModal
            }
          />
        )}

        {addKnowledgeBaseItemModal && (
          <ShowKnowledgeBaseItems
            toggleKnowledgeBaseItemModal={toggleKnowledgeBaseItemModal}
          />
        )}
      </div>
    </>
  );
};

export default Portfolio;
