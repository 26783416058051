import { productStructuringWidgetConstant } from '../constant';

const { ADD_SELECTED_PORTFOLIO_NAME } = productStructuringWidgetConstant;

const initialState: any = {
  selectedPortfolios: [] as any,
};

const ProductStructuringReducer = (state = initialState, action: any): any => {
  switch (action.type) {
    case ADD_SELECTED_PORTFOLIO_NAME:
      return {
        ...state,
        selectedPortfolios: action.payload.selectedPortfolios,
      };

    default:
      return state;
  }
};
export default ProductStructuringReducer;
