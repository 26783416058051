import React, { useEffect, useState } from 'react';
import { TabContent, TabPane } from 'reactstrap';
import { Iconclose } from '../../../../components/public/Icons';
import SelectNewsSource from '../../dashboard/addDashboardWidget/newsfeedWidgetWorkflow/SelectNewsSource';
import NewsfeedHeadings from '../../dashboard/addDashboardWidget/widgetWorkflowHeadings/NewsfeedHeadings';
import NewsService from '../../../../services/news.service';
import useAction from '../../../../components/hooks/useAction';
import AppLoader from '../../../../components/loaders/AppLoader';
import { useSelector } from 'react-redux';
import { selectedNewsEndpointSelector } from '../../../../redux/selector/NewsFeedWidgetsSelector';

const newsService = new NewsService();

type PortfolioTypes = {
  toggleNewsfeedWidget: () => void;
};

type NewsSourceListTypes = {
  _id: string;
  serviceName: string;
  serviceType: string;
  serviceUrl: string;
  isPaidService: boolean;
  id: string;
  endpointCount: number;
};

const AddNewsfeedWidget = ({ toggleNewsfeedWidget }: PortfolioTypes) => {
  const { showModal, addNewsFeedData } = useAction();
  const selectedEndpoint = useSelector(selectedNewsEndpointSelector);
  const [isLoading, setIsLoading] = useState(false);
  // State for current active Tab
  const [currentActiveTab, setCurrentActiveTab] = useState('1');

  const [newsSourceList, setNewsSourceList] = useState<
    Array<NewsSourceListTypes>
  >([]);

  // Toggle active state for Tab
  const toggle = (tab: React.SetStateAction<string>) => {
    if (currentActiveTab !== tab) setCurrentActiveTab(tab);
  };

  const getAllNewsSources = () => {
    setIsLoading(true);
    newsService
      .getNewsSourceList()
      .then(({ data }) => {
        setNewsSourceList(data);
        setIsLoading(false);
      })
      .catch(err => {
        showModal(err?.response?.data?.message);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getAllNewsSources();
  }, []);

  return (
    <>
      {isLoading && <AppLoader title={''} />}
      <div className="addwidget-wrapper">
        <div className="sub-header justify-content-between">
          <h4 className="title-20">Add Newsfeed Widget</h4>
          <button
            className="btn btn-icon p-0"
            onClick={() => {
              toggleNewsfeedWidget();
              document.body.classList.remove('show-dataitem');
            }}
          >
            <Iconclose />
          </button>
        </div>
        <div className="addwidget-main">
          <div className="container container-1024">
            <div className="addwidget-header">
              <NewsfeedHeadings currentActiveTab={currentActiveTab} />

              <div className="tabs-h-menu"></div>
            </div>
            <TabContent activeTab={currentActiveTab}>
              <TabPane tabId="1">
                <SelectNewsSource
                  newsSourceList={newsSourceList}
                  isLoading={isLoading}
                />
              </TabPane>
            </TabContent>
          </div>
          <div className="gap-12 d-flex justify-content-center">
            <button
              className="btn btn-outline-info"
              onClick={() => {
                if (currentActiveTab === '1') {
                  toggleNewsfeedWidget();
                  document.body.classList.remove('show-dataitem');
                }

                toggle(String(Number(currentActiveTab) - 1));
                document.body.classList.remove('show-dataitem');
              }}
            >
              {currentActiveTab === '1' ? 'Cancel' : 'Back'}
            </button>
            <button
              className="btn btn-primary"
              onClick={() => {
                if (selectedEndpoint) {
                  addNewsFeedData({
                    selectedNewsId: selectedEndpoint?.id,
                    selectedNewsName: selectedEndpoint?.sourceName,
                    selectedNewsSourceId: selectedEndpoint?.sourceId,
                    widgetType: 'NEWS_FEED',
                    newsArray: [],
                    newsEventId: '',
                    q: '',
                    value: 'news',
                  });
                  toggleNewsfeedWidget();
                  document.body.classList.remove('show-dataitem');
                }
              }}
            >
              Create Widget
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddNewsfeedWidget;
