/* ACTIONS */
import { NewsFeedWidgetDataTypes } from '../../../@types/NewsWidgetType';
import { newsFeedWidgetConstant } from '../constant';

const {
  ADD_NEWS_FEED_DATA,
  UPDATE_NEWS_FEED_DATA,
  REMOVE_NEWS_FEED_DATA,
  ADD_MOODY_NEWS_FEED_DATA,
  REMOVE_MOODY_NEWS_FEED_DATA,
  CLEAR_SELECTED_NEWS_ENDPOINT,
  SELECT_NEWS_ENDPOINT,
} = newsFeedWidgetConstant;

type EndpointListTypes = {
  _id: string;
  endpointName: string;
  endpointDescription: string;
  createdAt: Date;
  isServiceActive: boolean;
  id: string;
};

export const addNewsFeedData = (data: NewsFeedWidgetDataTypes) => {
  return {
    type: ADD_NEWS_FEED_DATA,
    payload: {
      newsFeedWidgetData: data,
    },
  };
};

export const updateNewsFeedData = (id: number, updatedData: NewsFeedWidgetDataTypes) => {
  return {
    type: UPDATE_NEWS_FEED_DATA,
    payload: {
      id,
      updatedData,
    },
  };
};

export const removeNewsFeedData = (id: number) => {
  return {
    type: REMOVE_NEWS_FEED_DATA,
    payload: {
      removeId: id,
    },
  };
};

export const addMoodyNewsFeedData = (data: any) => {
  return {
    type: ADD_MOODY_NEWS_FEED_DATA,
    payload: {
      moodyNewsFeedWidgetData: data,
    },
  };
};

export const removeMoodyNewsFeedData = (id: number) => {
  return {
    type: REMOVE_MOODY_NEWS_FEED_DATA,
    payload: {
      removeId: id,
    },
  };
};

// For news endpoints

export const selectEndNewsPoint = (endpoint: EndpointListTypes) => {
  return {
    type: SELECT_NEWS_ENDPOINT,
    payload: endpoint,
  };
};

export const clearSelectedEndNewsPoint = () => {
  return {
    type: CLEAR_SELECTED_NEWS_ENDPOINT,
  };
};
