import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { IconCross } from '../../../../components/public/Icons';
import CustomSelect from '../../../../components/ui/CustomSelect';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import {
  InviteUser,
  validationSchema,
} from '../../../../redux/constant/setting.constant';
import { DepartmentData } from '../../../../../@types/SettingsType';
import useAction from '../../../../components/hooks/useAction';
import SettingsService from '../../../../services/settings.service';
import { isAxiosError } from '../../../../redux/constant';
import AppLoader from '../../../../components/loaders/AppLoader';
import LicenseService from '../../../../services/license.service';
import { LicenseData } from '../../../../../@types/LicenseType';

type UserInviteModalProps = {
  modal: boolean;
  toggle: () => void;
  // eslint-disable-next-line no-unused-vars
  toggleSend: (values: InviteUser) => void;
  organizationId: string;
  role: string;
  isModalLoading: boolean;
  setIsModalLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

const settingsService = new SettingsService();
const licenseService = new LicenseService();

const UserInviteModal = ({
  modal,
  toggle,
  toggleSend,
  organizationId,
  role,
  isModalLoading,
  setIsModalLoading,
}: UserInviteModalProps) => {
  const { showModal } = useAction();
  const [isLoading, setIsLoading] = useState(false);
  const [allDepartments, setAllDepartments] = useState<Array<
    DepartmentData
  > | null>(null);
  const [allLicenses, setAllLicenses] = useState<Array<LicenseData> | null>(
    null,
  );

  const closeBtn = (
    <button className="close" onClick={toggle} type="button">
      <IconCross fill="#36567D" />
    </button>
  );

  const getAllDepartmentsData = async () => {
    setIsLoading(true);
    try {
      const response = await settingsService.getAllDepartments(organizationId);
      setIsLoading(false);

      setAllDepartments(response?.data);
    } catch (error) {
      setIsLoading(false);

      if (isAxiosError(error)) {
        showModal(
          (error.response?.data as { error?: string })?.error ||
            'Unexpected Axios error',
        );
      } else {
        showModal('An unexpected error occurred');
      }
    }
  };

  const getAllLicensesData = async () => {
    setIsLoading(true);
    try {
      const response = await licenseService.getAllLicenses();
      setIsLoading(false);

      setAllLicenses(response?.data);
    } catch (error) {
      setIsLoading(false);

      if (isAxiosError(error)) {
        showModal(
          (error.response?.data as { error?: string })?.error ||
            'Unexpected Axios error',
        );
      } else {
        showModal('An unexpected error occurred');
      }
    }
  };

  useEffect(() => {
    if (organizationId) {
      getAllDepartmentsData();
      getAllLicensesData();
    }
  }, []);

  return (
    <Modal isOpen={modal} toggle={toggle} centered>
      {(isLoading || isModalLoading) && <AppLoader />}
      <ModalHeader toggle={toggle} close={closeBtn}>
        INVITE NEW USER
      </ModalHeader>
      <Formik
        initialValues={{
          firstName: '',
          middleName: '',
          lastName: '',
          email: '',
          departmentId: '',
          userRole: '',
          licenseId: '',
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          setIsModalLoading(true);
          toggleSend(values);
          setSubmitting(false);
        }}
      >
        {({ isSubmitting, setFieldValue }) => {
          return (
            <Form>
              <ModalBody>
                <div className="row">
                  <div className="col-12 col-lg-6 col-xl-4">
                    <div className="form-group">
                      <label className="form-label">First Name</label>
                      <Field
                        type="text"
                        name="firstName"
                        placeholder="Enter first name"
                        className="form-control"
                      />
                      <ErrorMessage
                        name="firstName"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                  </div>
                  <div className="col-12 col-lg-6 col-xl-4">
                    <div className="form-group">
                      <label className="form-label">
                        Middle Name (optional)
                      </label>
                      <Field
                        type="text"
                        name="middleName"
                        placeholder="Enter middle name"
                        className="form-control"
                      />
                      <ErrorMessage
                        name="middleName"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                  </div>
                  <div className="col-12 col-lg-6 col-xl-4">
                    <div className="form-group">
                      <label className="form-label">Last Name</label>
                      <Field
                        type="text"
                        name="lastName"
                        placeholder="Enter last name"
                        className="form-control"
                      />
                      <ErrorMessage
                        name="lastName"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                  </div>
                  <div className="col-12 col-lg-6">
                    <div className="form-group">
                      <label className="form-label">Company E-mail</label>
                      <Field
                        type="email"
                        name="email"
                        placeholder="Enter e-mail"
                        className="form-control"
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                  </div>
                  <div className="w-100"></div>
                  <div className="col-12 col-lg-6">
                    <div className="form-group">
                      <label className="form-label">Department</label>
                      <CustomSelect
                        options={
                          allDepartments &&
                          allDepartments?.map(user => ({
                            label: user.department,
                            value: user.id,
                          }))
                        }
                        placeholder={'Select department'}
                        onChange={(option: any) =>
                          setFieldValue('departmentId', option.value)
                        }
                      />
                      <ErrorMessage
                        name="departmentId"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                  </div>
                  <div className="col-12 col-lg-6">
                    <div className="form-group">
                      <label className="form-label">Role</label>
                      <CustomSelect
                        options={
                          role === 'ADMIN'
                            ? [{ label: 'Member', value: 'MEMBER' }]
                            : [
                                { label: 'Admin', value: 'ADMIN' },
                                { label: 'Member', value: 'MEMBER' },
                              ]
                        }
                        placeholder={'Select role'}
                        onChange={(option: { label: string; value: string }) =>
                          setFieldValue('userRole', option.value)
                        }
                      />
                      <ErrorMessage
                        name="userRole"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                  </div>
                  <div className="col-12 col-lg-6">
                    <div className="form-group mb-0">
                      <label className="form-label">Licence</label>
                      <CustomSelect
                        options={
                          allLicenses &&
                          allLicenses?.map(user => {
                            const formattedLicenseType =
                              user?.licenseType?.charAt(0)?.toUpperCase() +
                              user?.licenseType?.slice(1)?.toLowerCase();

                            return {
                              label:
                                user.licenseType === 'BASIC'
                                  ? formattedLicenseType
                                  : `${formattedLicenseType} (${user?.availableLicenses} available)`,
                              value: user.licenseId,
                            };
                          })
                        }
                        onChange={(option: { label: string; value: string }) =>
                          setFieldValue('licenseId', option.value)
                        }
                      />
                      <ErrorMessage
                        name="licenseId"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                  </div>
                </div>
              </ModalBody>
              <ModalFooter className="justify-content-end">
                <button
                  className="btn btn-outline-info"
                  onClick={toggle}
                  type="button"
                >
                  Cancel
                </button>
                <button
                  className="btn btn-primary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Send Invite
                </button>
              </ModalFooter>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default UserInviteModal;
