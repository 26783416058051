import { themeConstant } from '../constant';

const { UPDATE_THEME_TYPE } = themeConstant;

const initialState: any = {
  themeType: 'dark',
};

const ThemeReducer = (state = initialState, action: any): any => {
  switch (action.type) {
    case UPDATE_THEME_TYPE:
      return {
        themeType: action.payload.themeType,
      };

    default:
      return state;
  }
};
export default ThemeReducer;
