import moment from 'moment';
import { News } from '../../@types/NewsWidgetType';

export const GET_TOP_FIVE_NEWS = ({
  newsData,
}: {
  newsData: {
    id: string | number;
    news: News;
  }[];
}) => {
  // Type the items explicitly
  const sortedNews = [...newsData].sort((a, b) => {
    const dateA = new Date(a?.news?.date_published).getTime();
    const dateB = new Date(b?.news?.date_published).getTime();
    return dateB - dateA;
  });

  // Get the top 5 news items
  return sortedNews.slice(0, 5);
};

export const formatDateHeading = (date: string) => {
  const today = moment();
  const newsDate = moment(date);
  if (today.isSame(newsDate, 'day')) {
    return 'Today';
  } else if (today.subtract(1, 'day').isSame(newsDate, 'day')) {
    return 'Yesterday';
  } else {
    return newsDate.format('MM/DD/YYYY');
  }
};

export const groupNewsByDate = (
  newsTData: { id: string | number; news: News }[],
) => {
  return newsTData.reduce((acc, newsItem) => {
    const date = moment(newsItem?.news?.date_published).format('YYYY-MM-DD');
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(newsItem);
    return acc;
  }, {} as { [key: string]: { id: string | number; news: News }[] });
};

export const groupAndSortNewsByDate = (
  newsArray: { id: string | number; news: News }[],
) => {
  if (!newsArray || newsArray.length === 0) return [];

  const groupedNews = newsArray.reduce((acc, newsItem) => {
    const date = moment(newsItem?.news?.date_published).format('YYYY-MM-DD');
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(newsItem);
    return acc;
  }, {} as { [key: string]: { id: string | number; news: News }[] });

  const categorizedNews = Object.keys(groupedNews).map(date => {
    const formattedDate = moment(date);
    let title;
    if (formattedDate.isSame(moment(), 'day')) {
      title = 'TODAY';
    } else if (formattedDate.isSame(moment().subtract(1, 'day'), 'day')) {
      title = 'YESTERDAY';
    } else {
      title = formattedDate.format('DD/MM/YYYY');
    }
    return {
      title,
      data: groupedNews[date],
      date: formattedDate,
    };
  });

  categorizedNews.sort((a, b) => {
    if (a.title === 'TODAY') return -1;
    if (b.title === 'TODAY') return 1;
    if (a.title === 'YESTERDAY') return -1;
    if (b.title === 'YESTERDAY') return 1;
    return b.date.valueOf() - a.date.valueOf(); // For older dates, sort in descending order
  });

  return categorizedNews;
};

export const getCurrentYearMonths = () => {
  const currentYear = moment().year();
  const shortMonths = moment.monthsShort();
  const fullMonths = moment.months();

  return shortMonths.map((shortMonth, index) => ({
    label: `${shortMonth} ${currentYear}`,
    value: `${fullMonths[index]} ${currentYear}`,
  }));
};
