import { TabContent, TabPane } from 'reactstrap';
import { Iconclose } from '../../../../../../components/public/Icons';
import KnowledgeBaseItemsListing from './KnowledgeBaseItemsListing';

type ShowKnowledgeBaseItemsTypes = {
  toggleKnowledgeBaseItemModal: () => void;
};

const ShowKnowledgeBaseItems = ({
  toggleKnowledgeBaseItemModal,
}: ShowKnowledgeBaseItemsTypes) => {
  return (
    <>
      <div className="addwidget-wrapper">
        <div className="sub-header justify-content-between">
          <h4 className="title-20">Edit widget data sources</h4>
          <button
            className="btn btn-icon p-0"
            onClick={() => {
              toggleKnowledgeBaseItemModal();
            }}
          >
            <Iconclose />
          </button>
        </div>
        <div className="addwidget-main">
          <div className="container container-1024">
            <div className="addwidget-header">
              <h4 className="title-20">
                Select or upload files for the credit widget
              </h4>

              <div className="tabs-h-menu"></div>
            </div>
            <TabContent activeTab={'1'}>
              <TabPane tabId="1">
                <KnowledgeBaseItemsListing />
              </TabPane>
            </TabContent>
          </div>
          <div className="gap-12 d-flex justify-content-center">
            <button
              className="btn btn-outline-info"
              onClick={() => {
                toggleKnowledgeBaseItemModal();
              }}
            >
              Cancel
            </button>
            <button
              className="btn btn-primary"
              onClick={() => {
                toggleKnowledgeBaseItemModal();
              }}
            >
              Add Items
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShowKnowledgeBaseItems;
