import React, {
  /* useEffect, */ useEffect,
  useRef /* useState */,
  useState,
} from 'react';
import Plot from 'react-plotly.js';
import {
  getXAxisConfig,
  getYAxisConfig,
  getLayoutConfig,
} from '../../chartsTheme';

const WaterfallChart = ({
  chartData,
  // chartTitle,
  initialXAxis,
  initialYAxis,
}: {
  chartData: any;
  chartTitle: string;
  initialXAxis: string;
  initialYAxis: string;
}) => {
  // const [updatedData, setUpdatedData] = useState([] as any);
  const layoutConfig = getLayoutConfig();
  const [updatedChartData, setUpdatedChartData] = useState(chartData);

  useEffect(() => {
    const modifiedData = chartData.map((data: any) => {
      return {
        ...data,
        x: data.x.map((value: any) =>
          value === 0 ? 'Undefined Value' : value,
        ),
      };
    });

    setUpdatedChartData(modifiedData);
  }, [chartData]);

  // console.log(chartData);

  const plotRef = useRef<any>(null);

  const resizeLayout = () => {
    plotRef.current.resizeHandler();
  };
  return (
    <>
      {
        <div className="chart-wrapper" onMouseDown={e => e.stopPropagation()}>
          <Plot
            ref={plotRef}
            data={updatedChartData}
            // data={updatedData}
            config={{
              scrollZoom: true,
              displaylogo: false,
              editable: true,
              responsive: true,
            }}
            layout={{
              title: '',
              autosize: true,
              showlegend: true,

              xaxis: getXAxisConfig(initialXAxis),
              yaxis: getYAxisConfig(initialYAxis),
              ...layoutConfig,
            }}
            useResizeHandler={true}
            onRelayout={resizeLayout}
          />
        </div>
      }
    </>
  );
};

export default WaterfallChart;
