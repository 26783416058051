import ApiUtil from '../../utils/apiUtil';
import { LicenseResponse } from '../../@types/LicenseType';

export default class LicenseService {
  async getAllLicenses(): Promise<LicenseResponse> {
    const url = `/license/available`;
    const res = await ApiUtil.SendAsync<LicenseResponse>({
      url: url,
      method: 'GET',
    });
    return res.data;
  }
}
