import { productsWidgetsConstant } from '../constant';

const {
  ADD_PRODUCT_STRUCTURE_DATA,
  REMOVE_PRODUCT_STRUCTURE_DATA,
  //ADD_SIMPLE_PRICING_DATA,
  //REMOVE_SIMPLE_PRICING_DATA,
  //ADD_DERIVATIVE_DATA,
  //REMOVE_DERIVATIVE_DATA
} = productsWidgetsConstant;

const initialState: any = {
  productSturctureWidgetData: [] as any,
};

const filterByRemoveId = (data: any, widgetId: any) => {
  let dataArray = [...data];
  let tempArray = dataArray.filter(x => x.id !== widgetId);
  return tempArray;
};

const updatedStateData = (data: any, widgetData: any) => {
  //newsData = {}
  let tempData = [...data];

  let widgetIndex = tempData.findIndex((x: any) => x?.id === widgetData.id);

  if (widgetIndex > -1) {
    tempData[widgetIndex] = widgetData;
  } else {
    tempData.push(widgetData);
  }

  return tempData;
};

const ProductsWidgetsReducer = (state = initialState, action: any): any => {
  switch (action.type) {
    case ADD_PRODUCT_STRUCTURE_DATA:
      return {
        ...state,
        productSturctureWidgetData: updatedStateData(
          state.productSturctureWidgetData,
          action.payload.productSturctureWidgetData,
        ),
      };

    case REMOVE_PRODUCT_STRUCTURE_DATA:
      return {
        ...state,
        productSturctureWidgetData: filterByRemoveId(
          state.productSturctureWidgetData,
          action.payload.removeId,
        ),
      };

    default:
      return state;
  }
};
export default ProductsWidgetsReducer;
