import { marketplaceConstant } from '../constant';

type InitialStateTypes = {
  newsSourceCount: number;
  dataSourceCount: number;
  augumentationSourceCount: number;
  foundationalModelCount: number;
  isSaveNewsSource: boolean;
  isSaveDataSource: boolean;
  isSaveAugumentationSource: boolean;
  marketplaceType: { label: string; value: string } | null;
};

const {
  ADD_NEWS_SOURCE_COUNT,
  ADD_DATA_SOURCE_COUNT,
  ADD_AUGUMENTATION_DATA_SOURCE_COUNT,
  ADD_FOUNDATIONAL_MODEL_COUNT,
  IS_SAVE_NEWS_SOURCE,
  IS_SAVE_DATA_SOURCE,
  IS_SAVE_AUGUMENTATION_SOURCE,
  MARKET_PLACE_TYPE,
} = marketplaceConstant;
const initialState: InitialStateTypes = {
  newsSourceCount: 0,
  dataSourceCount: 0,
  augumentationSourceCount: 0,
  foundationalModelCount: 0,
  isSaveNewsSource: false,
  isSaveDataSource: false,
  isSaveAugumentationSource: false,
  marketplaceType: { label: 'Data Source', value: 'DATA_SOURCE' },
};

const MarketplaceReducer = (state = initialState, action: any): any => {
  switch (action.type) {
    case ADD_NEWS_SOURCE_COUNT:
      return {
        ...state,
        newsSourceCount: action.payload.newsSourceCount,
      };

    case ADD_DATA_SOURCE_COUNT:
      return {
        ...state,
        dataSourceCount: action.payload.dataSourceCount,
      };

    case ADD_AUGUMENTATION_DATA_SOURCE_COUNT:
      return {
        ...state,
        augumentationSourceCount: action.payload.augumentationSourceCount,
      };

    case ADD_FOUNDATIONAL_MODEL_COUNT:
      return {
        ...state,
        foundationalModelCount: action.payload.foundationalModelCount,
      };

    case IS_SAVE_NEWS_SOURCE:
      return {
        ...state,
        isSaveNewsSource: action.payload.isSaveNewsSource,
      };

    case IS_SAVE_DATA_SOURCE:
      return {
        ...state,
        isSaveDataSource: action.payload.isSaveDataSource,
      };

    case IS_SAVE_AUGUMENTATION_SOURCE:
      return {
        ...state,
        isSaveAugumentationSource: action.payload.isSaveAugumentationSource,
      };

    case MARKET_PLACE_TYPE:
      return {
        ...state,
        marketplaceType: action.payload.marketplaceType,
      };

    default:
      return state;
  }
};
export default MarketplaceReducer;
