import React from 'react';

interface RangeValues {
  range_raw_count: number;
  range_value_sum: number;
  range_weight: number;
}

// Define the dynamic structure where the outer keys and inner keys are dynamic
interface DynamicDataStructure {
  [outerKey: string]: {
    [rangeKey: string]: RangeValues;
  };
}

interface StatisticalDataTablePropsTypes {
  data: DynamicDataStructure;
  newData: { [key: string]: number }; // Add newData prop to hold totals
}

const StatisticalDataTable = ({
  data,
  newData,
}: StatisticalDataTablePropsTypes) => {
  const rows = Object.entries(data)?.flatMap(([category, ranges]) =>
    Object.entries(ranges).map(([range, values]) => (
      <tr key={`${category}-${range}`}>
        <td>{category}</td>
        <td>{range}</td>
        {/* Dynamically render all key-value pairs in the values object */}
        {Object.entries(values).map(([key, value]) => (
          <td key={key}>
            {typeof value === 'number' && key.includes('weight')
              ? `${value}%`
              : value}
          </td>
        ))}
      </tr>
    )),
  );

  // Extract headers dynamically based on the first row's values
  const dynamicHeaders = Object.keys(
    data[Object.keys(data)[0]][Object.keys(data[Object.keys(data)[0]])[0]],
  );

  return (
    <table className="table">
      <thead>
        <tr>
          <th>Category</th>
          <th>Range</th>
          {dynamicHeaders?.map(header => (
            <th key={header}>{header}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows}
        {/* Add total row using newData */}
        <tr>
          <td colSpan={2}>
            <strong>Total</strong>
          </td>
          {dynamicHeaders?.map(header => (
            <td key={header}>
              {typeof newData?.[header] === 'number'
                ? newData?.[header]?.toFixed(2) // Display total values from newData
                : '-'}
            </td>
          ))}
        </tr>
      </tbody>
    </table>
  );
};

export default StatisticalDataTable;
