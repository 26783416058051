import classnames from 'classnames';
import * as React from 'react';
//import { visuallyHidden } from '@mui/utils';
import { useSelector } from 'react-redux';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import useAction from '../../../../../components/hooks/useAction';
// import SessionModal from '../../../../../components/modals/SessionModal';
import {
  IconChatbot,
  Iconclose,
  IconCross,
  // IconDataSource,
  IconFilter,
  IconFullScreen,
  // IconInfo,
  IconSort,
  IconTick,
  // IconStar,
} from '../../../../../components/public/Icons';
// import PublicPagination from '../../../../../components/public/Pagination';
import PublicChat from '../../../../../components/public/PublicChat';
// import { CustomTooltip } from '../../../../../components/ui/CustomTooltip';
import WidgetMenuDropdown from '../../../../../components/ui/WidgetMenuDropdown';
import { callTelemetryRecord } from '../../../../../helpers/utils.helper';
import { widgetRankData } from '../../../../../redux/selector/WidgetRankSelector';
import ExportWidgeTData from '../../../newsfeed/widgets/ExportWidgeTData';
import RemoveWidget from '../../../newsfeed/widgets/RemoveWidget';
import SendWidgetAsTask from '../../../newsfeed/widgets/SendWidgetAsTask';
import ShareWidgetExternally from '../../../newsfeed/widgets/ShareWidgetExternally';
import { allPortfolioWidgetsSelector } from '../../../../../redux/selector/PortfolioWidgetsSelector';
import config from '../../../../../../config';
import axios from 'axios';
import WidgetLoader from '../../../../../components/loaders/WidgetLoader';
import InfiniteScroll from 'react-infinite-scroll-component';
import { isAxiosError } from '../../../../../redux/constant';
import { isEmpty } from 'lodash';
import StatisticalDataTable from '../../../../../components/ui/StatisticalDataTable';
import { widgetReferenceIdData } from '../../../../../redux/selector/WidgetReferenceIdSelector';
import { searchedNplChatQuery } from '../../../../../redux/selector/ChatQuerySelector';
import { getSelectedModel } from '../../../../../redux/selector/SelectedModelSelector';
import { v4 as uuidv4 } from 'uuid';

type NplDataType = {
  [key: string]: any; // Index signature, where keys are strings, and values can be any type
};

type SelectionRangeTypes = {
  [key: string]: string[]; // Index signature, where keys are strings, and values can be any type
};

interface RangeValues {
  range_raw_count: number;
  range_value_sum: number;
  range_weight: number;
}

// Define the dynamic structure where the outer keys and inner keys are dynamic
interface DynamicDataStructure {
  [outerKey: string]: {
    [rangeKey: string]: RangeValues;
  };
}

export default function NPLWidget({
  onClose,
  nplWidgetIndex,
  widgetFullScreen,
  setWidgetFullScreen,
}: any) {
  const widRank = useSelector(widgetRankData);
  const allPortfolioWidgetReduxData = useSelector(allPortfolioWidgetsSelector);
  const nplWidgetDataRedux = allPortfolioWidgetReduxData[nplWidgetIndex];
  const widgetReferenceIdValue = useSelector(widgetReferenceIdData);

  const chatQueryData = useSelector(searchedNplChatQuery);
  const chatbotSelectedModel = useSelector(getSelectedModel);
  // const [showSessionModal, setShowSessionModal] = React.useState(false);

  // fullscreen
  const [isChatOpen, setIsChatOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [nplWidgetData, setNplWidgetData] = React.useState<NplDataType[]>([]);
  const [calculatedLoanWidgetData, setCalculatedLoanWidgetData] =
    React.useState<DynamicDataStructure>({});
  const [fullSumData, setFullSumData] = React.useState<{
    [key: string]: number;
  }>({});

  const [widgetName, setWidgetName] = React.useState(
    'Npl Financial (Financial portfolio)',
  );
  const [isWidgetNameChanged, setIsWidgetNameChanged] = React.useState(false);

  const [collateralList, setCollateralList] = React.useState<
    {
      [key: string]: any;
    }[]
  >([]);
  const [financialList, setFinancialList] = React.useState<
    {
      [key: string]: any;
    }[]
  >([]);
  const [selectedRanges, setSelectedRanges] =
    React.useState<SelectionRangeTypes>({});
  const [debounceTimer, setDebounceTimer] = React.useState<any>(null);
  const [divHeight, setDivHeight] = React.useState<number>(0);
  const divRef = React.useRef<HTMLDivElement | null>(null);

  const toggleWidgetFullScreen = (type?: string) => {
    document.body.classList.toggle('widget-fullscreen');
    setWidgetFullScreen((widgetFullScreen: any) => !widgetFullScreen);
    setIsChatOpen(false);

    // fullscreen widget go to top on fullscreen click
    const pagecontent = document.querySelector('.page-content');
    if (pagecontent) {
      pagecontent.scroll({ top: 0 });
    }

    if (type === 'add') {
      document.body.classList.add(`widget-${nplWidgetIndex}`);
      document.body.classList.add('widget-fullscreen');

      const addElement = document.getElementById(`fs-npl-${nplWidgetIndex}`);
      if (addElement) {
        const parentElement = addElement.closest('.react-grid-item');
        if (parentElement) {
          setTimeout(() => {
            //
            parentElement.classList.add('widget-active');
          }, 10);
        }
      }
    } else if (type === 'remove') {
      document.body.classList.remove(`widget-${nplWidgetIndex}`);
      document.body.classList.remove('widget-fullscreen');

      const removeElement = document.getElementById(
        `fsrem-npl-${nplWidgetIndex}`,
      );
      if (removeElement) {
        const parentElement = removeElement.closest('.react-grid-item');
        if (parentElement) {
          parentElement.classList.remove('widget-active');
        }
      }
    }
  };

  const {
    addWidgetType,
    showModal,
    addPortfolioNplWidgetData,
    addNplChatQuery,
    addIndependentWidgetReferenceId,
    removeIndependentWidgetReferenceId,

    removePortfolioNplWidgetData,
    addWidgetReferenceId,
  } = useAction();

  // const toggleShowSessionModal = () => {
  //   setShowSessionModal(!showSessionModal);
  // };

  const toogleFilterModal = () => {
    // document.body.classList.toggle('has-overlay');
    document.body.classList.add('show-widget-chat');
  };

  const [dropdownOpen, setDropDownOpen] = React.useState(false);
  const toggleDropDown = () => {
    setDropDownOpen((state) => !state);
  };

  //open modal - Widget As Task
  const [sendWidgetAsTaskModal, setSendWidgetAsTaskModal] =
    React.useState(false);
  const toggleSendWidgetAsTaskModal = () =>
    setSendWidgetAsTaskModal(!sendWidgetAsTaskModal);

  //open modal - Share Externally
  const [shareExternallyModal, setShareExternallyModal] = React.useState(false);
  const toggleShareExternallyModal = () =>
    setShareExternallyModal(!shareExternallyModal);

  //open modal - Export WidgeT Data
  const [exportWidgeTDataModal, setExportWidgeTDataModal] =
    React.useState(false);
  const toggleExportWidgeTDataModal = () =>
    setExportWidgeTDataModal(!exportWidgeTDataModal);

  //remove modal - remove widget
  const [removeWidgetModal, setRemoveWidget] = React.useState(false);
  const toggleRemoveWidgetModal = () => {
    setRemoveWidget(!removeWidgetModal);
  };

  const removeNplWidget = () => {
    onClose();

    if (widgetFullScreen) {
      toggleWidgetFullScreen('remove');
    }

    removePortfolioNplWidgetData(nplWidgetIndex);

    if (
      widRank &&
      widRank.widgetRank &&
      widRank?.widgetRank?.slots?.length > 0
    ) {
      callTelemetryRecord(
        'widgets',
        widRank?.widgetRank?.event_id,
        widRank?.widgetRank?.slots[0]?.id,
        ['close'],
      );
    }
  };

  const [widgetCurrentActiveTab, setWidgetCurrentActiveTab] =
    React.useState('WidgetData');
  const [DataModifiersActiveTab, setDataModifiersActiveTab] =
    React.useState('NPL_COLLATERAL');

  const toggleDataModifiers = (tab: React.SetStateAction<string>) => {
    if (DataModifiersActiveTab !== tab) setDataModifiersActiveTab(tab);
  };

  const toggle = (tab: React.SetStateAction<string>) => {
    if (widgetCurrentActiveTab !== tab) setWidgetCurrentActiveTab(tab);
  };

  const toggleWidgetTabs = () => {
    {
      widgetCurrentActiveTab === 'WidgetData'
        ? setWidgetCurrentActiveTab('DataModifiers')
        : setWidgetCurrentActiveTab('WidgetData');
    }
  };

  const filterDataByMaxValue = (data: {
    [key: string]: { min: number; max: number }[];
  }) => {
    return Object.fromEntries(
      // eslint-disable-next-line no-unused-vars
      Object.entries(data).filter(([key, ranges]) =>
        // Keep the key if not all "max" values are 0
        ranges.some((range) => range.max !== 0),
      ),
    );
  };

  const getNplWidgetData = async (isUpdateData: boolean) => {
    setIsLoading(true);
    try {
      const payload = {
        portfolio_ids: nplWidgetDataRedux?.selectedStatPortfolio,
      };
      const response = await axios({
        method: 'post',
        url: `${config.ER_ML_URL}/statistical/get-statistical-data?page=${
          isUpdateData ? 1 : currentPage
        }&limit=20`,
        data: payload,
      });

      let tempLoanWidgetData = [...nplWidgetData];

      if (isUpdateData) {
        tempLoanWidgetData = [];
        setCurrentPage(1);
      }

      const responseManipulation = [
        ...tempLoanWidgetData,
        ...(response?.data?.data || {}),
      ];

      if (!nplWidgetDataRedux?.widgetReferenceId) {
        addPortfolioNplWidgetData({
          ...nplWidgetDataRedux,
          widgetReferenceId: uuidv4(),
        });
      }

      let ranges = filterDataByMaxValue(nplWidgetDataRedux?.inputDataRange);
      const financialData: { [key: string]: any }[] = [];
      const collateralData: { [key: string]: any }[] = [];
      const headerWithTypeObj = response?.data?.header_with_type;

      // Iterate over obj1 and categorize based on obj2
      for (const key in ranges) {
        if (headerWithTypeObj[key] === 'COLLATERAL') {
          collateralData.push({ [key]: ranges[key] });
        } else if (headerWithTypeObj[key] === 'FINANCIAL') {
          financialData.push({ [key]: ranges[key] });
        }
      }

      setCollateralList(collateralData);
      setFinancialList(financialData);

      setNplWidgetData(responseManipulation);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      showModal('something went wrong');
    }
  };

  const handleCheckboxChange = (
    key: string,
    min: number,
    max: number,
    checked: boolean,
  ) => {
    setSelectedRanges((prevState) => {
      const minMaxValue = `${min}-${max}`;

      // If the key already exists in the state
      if (prevState[key]) {
        return {
          ...prevState,
          [key]: checked
            ? [...prevState[key], minMaxValue] // Add new min-max if checked
            : prevState[key].filter((val) => val !== minMaxValue), // Remove if unchecked
        };
      } else {
        // If the key doesn't exist, create a new entry
        return {
          ...prevState,
          [key]: checked ? [minMaxValue] : [],
        };
      }
    });
  };

  const savedNplType = async () => {
    try {
      const payload: {
        portfolio_ids: string[];
        portfolio_type: string;
      } = {
        portfolio_ids: nplWidgetDataRedux?.selectedStatPortfolio,
        portfolio_type: 'NPL',
      };

      await axios({
        method: 'post',
        url: `${config.ER_ML_URL}/statistical/save-statistical-portfolio`,
        data: payload,
      });
      showModal('Saved successfully!', true);
    } catch (err) {
      if (isAxiosError(err)) {
        showModal(
          (err.response?.data as { error?: string })?.error ||
            'Unexpected Axios error',
        );
      } else {
        showModal('something went wrong');
      }
    }
  };

  const cleanSelectedRange = (selectedRange: SelectionRangeTypes) => {
    const cleanedRange = Object.fromEntries(
      Object.entries(selectedRange).filter((entry) => entry[1].length > 0), // Using array indices directly
    );
    return cleanedRange;
  };

  const callCalculationUpdateApi = async (updatedNewFields?: string[]) => {
    setIsLoading(true);
    try {
      const payload = {
        portfolio_ids: nplWidgetDataRedux?.selectedStatPortfolio,
        selection: cleanSelectedRange(selectedRanges),
        new_fields: updatedNewFields
          ? updatedNewFields
          : nplWidgetDataRedux?.newFieldsArray,
      };
      const response = await axios({
        method: 'post',
        url: `${config.ER_ML_URL}/statistical/filter-new`,
        data: payload,
      });
      const updatedData = response?.data?.response;
      setCalculatedLoanWidgetData(updatedData);
      setFullSumData(response?.data?.final_sum);
      // getNplWidgetData(true);
      showModal('Data updated successfully!', true);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      showModal('something went wrong');
    }
  };

  React.useEffect(() => {
    if (chatQueryData !== '' && widgetReferenceIdValue === nplWidgetIndex) {
      setIsLoading(true);
      addNplChatQuery('');
      const payload = {
        portfolio_ids: nplWidgetDataRedux?.selectedStatPortfolio,
        user_query: chatQueryData,
        model: chatbotSelectedModel,
      };
      axios({
        method: 'post',
        url: `${config.ER_ML_URL}/statistical/add-function`,
        data: payload,
      })
        .then(({ data }) => {
          callCalculationUpdateApi(data?.new_fields);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          showModal(err?.response?.data?.error);
        });
    }
  }, [
    widgetReferenceIdValue === nplWidgetIndex &&
      chatQueryData !== '' &&
      chatQueryData,
  ]);

  React.useEffect(() => {
    if (Object?.keys(selectedRanges)?.length > 0) {
      if (debounceTimer) {
        clearTimeout(debounceTimer); // Clear the previous timer
      }

      // Set a new timer to call the API after 3 seconds
      const timer = setTimeout(() => {
        callCalculationUpdateApi();
      }, 3000);

      // Save the timer in state
      setDebounceTimer(timer);

      // Cleanup the timer on unmount or when selectedValues change
      return () => {
        clearTimeout(timer);
      };
    }
  }, [selectedRanges]);

  React.useEffect(() => {
    setWidgetName(nplWidgetDataRedux?.customWidgetName);
  }, []);

  React.useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        setDivHeight(entry.contentRect.height);
      }
    });

    if (divRef.current) {
      resizeObserver.observe(divRef.current);
    }

    // Cleanup the observer on component unmount
    return () => {
      if (divRef.current) {
        resizeObserver.unobserve(divRef.current);
      }
    };
  }, []);

  React.useEffect(() => {
    if (nplWidgetDataRedux?.selectedStatPortfolio?.length > 0) {
      getNplWidgetData(false);
    }
  }, [
    nplWidgetDataRedux?.selectedStatPortfolio?.length,
    currentPage !== 1 && currentPage,
  ]);

  return (
    <>
      {isLoading && <WidgetLoader title="" />}

      {/* remove class when open chat widget `widget-pagination wp-bottom` */}
      <div
        className={`common-widget ${
          !isChatOpen && 'widget-pagination wp-bottom'
        }`}
        onMouseEnter={() => {
          addWidgetReferenceId(nplWidgetIndex);
        }}
      >
        {/* Added `cwh-tabs` class if tabs inn widget full screen header */}
        <div className="common-widget-header cwh-tabs">
          <div className="common-widget-header-title">
            <h2>
              {!isWidgetNameChanged ? (
                widgetName ? (
                  widgetName
                ) : (
                  'Npl Financial (Financial portfolio)'
                )
              ) : (
                <div className="flexbox-c gap-12 no-drag">
                  <input
                    type="text"
                    placeholder="Enter new widget name"
                    className="form-control form-control-sm mw-260"
                    value={widgetName}
                    onChange={(e) => {
                      setWidgetName(e.target.value);
                    }}
                  />
                  <button
                    className="btn-clear flexbox-c gap-1 text-info"
                    onClick={() => {
                      setIsWidgetNameChanged(false);
                    }}
                  >
                    <IconCross fill="#36567D" />
                    Cancel
                  </button>
                  <button
                    className="btn-clear flexbox-c gap-1 text-primary"
                    onClick={() => {
                      setIsWidgetNameChanged(false);
                    }}
                  >
                    <IconTick />
                    Save
                  </button>
                </div>
              )}
            </h2>

            {widgetFullScreen && (
              <div className="ms-6 no-drag">
                {/* Create Component Dropdown  */}
                <WidgetMenuDropdown
                  type="STATISTICAL"
                  savedStatType={savedNplType}
                  dropdownOpen={dropdownOpen}
                  toggleDropDown={toggleDropDown}
                  toggleSendWidgetAsTaskModal={toggleSendWidgetAsTaskModal}
                  toggleShareExternallyModal={toggleShareExternallyModal}
                  toggleExportWidgeTDataModal={toggleExportWidgeTDataModal}
                  toggleRemoveWidgetModal={toggleRemoveWidgetModal}
                  setIsWidgetNameChanged={setIsWidgetNameChanged}
                />
              </div>
            )}

            {!widgetFullScreen && (
              <div className="d-flex aling-items-center ms-12 no-drag">
                <button
                  id={`fs-npl-${nplWidgetIndex}`}
                  type="button"
                  className="btn-icon"
                  onClick={() => {
                    toggleWidgetFullScreen('add');
                    // addWidgetReferenceId(nplWidgetIndex);
                    addWidgetType('NPL_FINANCIAL');

                    addIndependentWidgetReferenceId(
                      nplWidgetDataRedux?.widgetReferenceId,
                    );
                  }}
                >
                  <IconFullScreen />
                </button>
                <button
                  type="button"
                  className={isChatOpen ? 'icon-active btn-icon' : 'btn-icon'} // apply `icon-active` class for active widget chat
                  onClick={() => {
                    toogleFilterModal();
                    setIsChatOpen(!isChatOpen);
                    // addWidgetReferenceId(nplWidgetIndex);
                    addWidgetType('NPL_FINANCIAL');
                    addIndependentWidgetReferenceId('');

                    // addPortfolioNplWidgetData({
                    //   id: nplWidgetIndex,
                    //   value: 'npl',
                    //   widgetType: 'PORTFOLIO',
                    // });

                    if (
                      widRank &&
                      widRank.widgetRank &&
                      widRank?.widgetRank?.slots?.length > 0
                    ) {
                      callTelemetryRecord(
                        'widgets',
                        widRank?.widgetRank?.event_id,
                        widRank?.widgetRank?.slots[0]?.id,
                        ['chat'],
                      );
                    }
                  }}
                >
                  <IconChatbot index={'nplwidget'} />
                </button>
                <button
                  type="button"
                  className={
                    widgetCurrentActiveTab === 'DataModifiers'
                      ? 'icon-active btn-icon'
                      : 'btn-icon'
                  } // apply `icon-active` class for active widget chat
                  // className={classnames({
                  //   active: widgetCurrentActiveTab === 'DataModifiers',
                  // })}
                  onClick={() => {
                    toggleWidgetTabs();
                  }}
                >
                  <IconFilter />
                </button>
              </div>
            )}
            {/* flexbox */}
            {widgetFullScreen && (
              <div className="tabs-menu-line mb-0 ms-auto no-drag">
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: widgetCurrentActiveTab === 'WidgetData',
                      })}
                      onClick={() => {
                        toggle('WidgetData');
                      }}
                    >
                      Widget Data
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: widgetCurrentActiveTab === 'DataModifiers',
                      })}
                      onClick={() => {
                        toggle('DataModifiers');
                      }}
                    >
                      Data Modifiers
                    </NavLink>
                  </NavItem>
                  {/* <NavItem>
                    <NavLink
                      className={classnames({
                        active: widgetCurrentActiveTab === 'WidgetDataSources',
                      })}
                      onClick={() => {
                        toggle('WidgetDataSources');
                      }}
                    >
                      Widget Data Sources
                    </NavLink>
                  </NavItem> */}
                </Nav>
              </div>
            )}
          </div>

          {widgetFullScreen && (
            <div className="common-widget-header-action no-drag">
              <button
                id={`fsrem-npl-${nplWidgetIndex}`}
                type="button"
                className="btn-icon"
                onClick={() => {
                  toggleWidgetFullScreen('remove');
                  setWidgetCurrentActiveTab('WidgetData');
                  removeIndependentWidgetReferenceId();
                }}
              >
                <Iconclose />
              </button>
            </div>
          )}

          {!widgetFullScreen && (
            <div className="common-widget-header-action no-drag">
              {/* Create Component Dropdown  */}
              <WidgetMenuDropdown
                type="STATISTICAL"
                savedStatType={savedNplType}
                dropdownOpen={dropdownOpen}
                toggleDropDown={toggleDropDown}
                toggleSendWidgetAsTaskModal={toggleSendWidgetAsTaskModal}
                toggleShareExternallyModal={toggleShareExternallyModal}
                toggleExportWidgeTDataModal={toggleExportWidgeTDataModal}
                toggleRemoveWidgetModal={toggleRemoveWidgetModal}
                setIsWidgetNameChanged={setIsWidgetNameChanged}
              />
            </div>
          )}
        </div>
        <div
          ref={divRef}
          className="common-widget-content infinite-widget-content"
          onClick={() => {
            if (
              widRank &&
              widRank.widgetRank &&
              widRank?.widgetRank?.slots?.length > 0
            ) {
              callTelemetryRecord(
                'widgets',
                widRank?.widgetRank?.event_id,
                widRank?.widgetRank?.slots[0]?.id,
                ['click'],
              );
            }
          }}
        >
          {/* Widget Chat */}

          {/* Chat True Show*/}
          {isChatOpen && !widgetFullScreen && (
            <div className="widget-chat">
              <PublicChat
                widgetIndex={nplWidgetIndex}
                widgetReferenceId={nplWidgetDataRedux?.widgetReferenceId}
              />
            </div>
          )}

          {/* Chat True Hide */}
          {!isChatOpen && (
            <TabContent activeTab={widgetCurrentActiveTab}>
              <TabPane tabId="WidgetData">
                {!isEmpty(calculatedLoanWidgetData) ? (
                  <StatisticalDataTable
                    data={calculatedLoanWidgetData}
                    newData={fullSumData}
                  />
                ) : (
                  <div
                    id={`scrollableDiv-npl-${nplWidgetIndex}`}
                    className="scrollbar-hide"
                    style={{
                      height: divHeight,
                      overflow: 'auto',
                      overflowAnchor: 'none',
                    }}
                  >
                    <InfiniteScroll
                      dataLength={nplWidgetData?.length}
                      next={() => setCurrentPage(currentPage + 1)}
                      hasMore={nplWidgetData?.length <= 0 ? false : true}
                      loader={
                        nplWidgetData?.length <= 0 ? (
                          <div style={{ paddingLeft: '12px' }}>Loading...</div>
                        ) : null
                      }
                      scrollableTarget={`scrollableDiv-npl-${nplWidgetIndex}`}
                    >
                      <div className="table-h-xl">
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th style={{ width: '40px' }} scope="col">
                                <div className="flexbox-align-c form-check fc-clear no-drag">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="flexCheckChecked"
                                  />
                                </div>
                              </th>
                              {nplWidgetData?.length > 0 &&
                                Object?.keys(nplWidgetData[0])?.map(
                                  (header) => (
                                    <th
                                      style={{ width: '80px' }}
                                      className="text-end"
                                      scope="col"
                                      key={header}
                                    >
                                      {header}
                                      <span className="sortable asc">
                                        <IconSort />
                                      </span>
                                    </th>
                                  ),
                                )}
                            </tr>
                          </thead>
                          <tbody>
                            {nplWidgetData?.map(
                              (cValue: any, cIndex: number) => (
                                <tr key={`credit-index-${cIndex}`}>
                                  <td>
                                    <div className="flexbox-align-c form-check fc-clear">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="flexCheckChecked"
                                      />
                                    </div>
                                  </td>
                                  {Object?.keys(nplWidgetData[0])?.map(
                                    (header) => (
                                      <td key={header}>{cValue[header]}</td>
                                    ),
                                  )}
                                </tr>
                              ),
                            )}
                          </tbody>
                        </table>
                      </div>
                    </InfiniteScroll>
                    {/* <PublicPagination /> */}
                  </div>
                )}
              </TabPane>

              <TabPane tabId="DataModifiers">
                <div
                  className={
                    widgetFullScreen
                      ? 'widget-tabs-content'
                      : 'widget-tabs-content p-0'
                  }
                >
                  {!widgetFullScreen && (
                    <div className="tabs-menu-line flexbox no-drag">
                      <Nav tabs>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active:
                                DataModifiersActiveTab === 'NPL_COLLATERAL',
                            })}
                            onClick={() => {
                              toggleDataModifiers('NPL_COLLATERAL');
                            }}
                          >
                            NPL COLLATERAL
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active:
                                DataModifiersActiveTab === 'NPL_FINANCIAL',
                            })}
                            onClick={() => {
                              toggleDataModifiers('NPL_FINANCIAL');
                            }}
                          >
                            NPL FINANCIAL
                          </NavLink>
                        </NavItem>
                      </Nav>
                      <div className="">
                        <button className="btn link">Reset All</button>
                      </div>
                    </div>
                  )}

                  <div className="addwidget-wrapper">
                    <div className="container container-1024">
                      {widgetFullScreen && (
                        <div className="addwidget-header">
                          <h4 className="title-20 text-center">
                            Toggle widget modifiers to analyze npl portfolio
                            data
                          </h4>
                        </div>
                      )}
                      <div
                        className={`addwidget-content mw-100 edit-widget ${
                          !widgetFullScreen && 'tab-content-nested'
                        } 
                            
                        `}
                      >
                        <TabContent activeTab={DataModifiersActiveTab}>
                          <div className="row justify-content-center row-g-24 no-drag">
                            <div
                              className={
                                widgetFullScreen ? 'col-12 col-lg-6' : 'col-12'
                              }
                            >
                              <TabPane tabId="NPL_COLLATERAL">
                                {widgetFullScreen && (
                                  <p className="title-underline">
                                    NPL COLLATERAL
                                  </p>
                                )}
                                <div className="row row-cols-1">
                                  <div
                                    className={
                                      widgetFullScreen
                                        ? 'col-12 col-lg-6'
                                        : 'col-12 col-lg-6'
                                    }
                                  >
                                    {collateralList?.map((cVal, cIndex) => {
                                      const [key, value] =
                                        Object.entries(cVal)[0];
                                      return (
                                        <div
                                          className="mb-3"
                                          key={`COL-${key}-${cIndex}`}
                                        >
                                          <p className="widget-category mb-10">
                                            {key}
                                          </p>

                                          {value?.map(
                                            (
                                              vValue: {
                                                min: number;
                                                max: number;
                                              },
                                              vIndex: number,
                                            ) => (
                                              <div
                                                className="form-check mb-10"
                                                key={`COL-${vValue?.min}-${vValue?.max}-${vIndex}`}
                                              >
                                                <input
                                                  className="form-check-input"
                                                  type="checkbox"
                                                  name="loanamount"
                                                  id={`col-${vValue?.min}-${vValue?.max}-${vIndex}`}
                                                  onChange={(e) =>
                                                    handleCheckboxChange(
                                                      key,
                                                      vValue.min,
                                                      vValue.max,
                                                      e.target.checked,
                                                    )
                                                  }
                                                />
                                                <label
                                                  className="form-check-label"
                                                  htmlFor={`col-${vValue?.min}-${vValue?.max}-${vIndex}`}
                                                >
                                                  ${vValue?.min} - $
                                                  {vValue?.max}
                                                </label>
                                              </div>
                                            ),
                                          )}
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              </TabPane>
                            </div>
                            <div
                              className={
                                widgetFullScreen ? 'col-12 col-lg-6' : 'col-12'
                              }
                            >
                              <TabPane tabId="NPL_LOAN">
                                {widgetFullScreen && (
                                  <p className="title-underline">NPL LOAN</p>
                                )}

                                <div className="row row-cols-1">
                                  <div className="col-12 col-lg-6">
                                    {financialList?.map((fVal, fIndex) => {
                                      const [key, value] =
                                        Object.entries(fVal)[0];
                                      return (
                                        <div
                                          className="mb-3"
                                          key={`FIN-${key}-${fIndex}`}
                                        >
                                          <p className="widget-category mb-10">
                                            {key}
                                          </p>

                                          {value?.map(
                                            (
                                              vValue: {
                                                min: number;
                                                max: number;
                                              },
                                              vIndex: number,
                                            ) => (
                                              <div
                                                className="form-check mb-10"
                                                key={`FIN-${vValue?.min}-${vValue?.max}-${vIndex}`}
                                              >
                                                <input
                                                  className="form-check-input"
                                                  type="checkbox"
                                                  name="loanamount"
                                                  id={`${vValue?.min}-${vValue?.max}-${vIndex}`}
                                                  onChange={(e) =>
                                                    handleCheckboxChange(
                                                      key,
                                                      vValue.min,
                                                      vValue.max,
                                                      e.target.checked,
                                                    )
                                                  }
                                                />
                                                <label
                                                  className="form-check-label"
                                                  htmlFor={`${vValue?.min}-${vValue?.max}-${vIndex}`}
                                                >
                                                  ${vValue?.min} - $
                                                  {vValue?.max}
                                                </label>
                                              </div>
                                            ),
                                          )}
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              </TabPane>
                            </div>
                          </div>
                        </TabContent>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPane>
            </TabContent>
          )}
        </div>

        {sendWidgetAsTaskModal && (
          <SendWidgetAsTask
            modal={sendWidgetAsTaskModal}
            toggle={toggleSendWidgetAsTaskModal}
          />
        )}

        {shareExternallyModal && (
          <ShareWidgetExternally
            modal={shareExternallyModal}
            toggle={toggleShareExternallyModal}
          />
        )}

        {exportWidgeTDataModal && (
          <ExportWidgeTData
            modal={exportWidgeTDataModal}
            toggle={toggleExportWidgeTDataModal}
          />
        )}

        {removeWidgetModal && (
          <RemoveWidget
            modal={removeWidgetModal}
            toggle={toggleRemoveWidgetModal}
            removeWidget={removeNplWidget}
          />
        )}
        {/* {showSessionModal && (
          <SessionModal
            modal={showSessionModal}
            toggle={toggleShowSessionModal}
            // fileKey={fileKey}
          />
        )} */}
      </div>
    </>
  );
}
