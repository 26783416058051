import { UserDetail } from '../../../@types/UserProfileTypes';
import { userDetailsConstant } from '../constant';

const {
  SUCCESS_USER_DETAILS,
  SUCCESS_USER_DETAILS_UPDATE,
} = userDetailsConstant;

export const addUserDetails = (userDetails: UserDetail) => {
  return {
    type: SUCCESS_USER_DETAILS,
    payload: { userDetails },
  };
};

export const updateUserDetails = (updatedFields: UserDetail) => {
  return {
    type: SUCCESS_USER_DETAILS_UPDATE,
    payload: { updatedFields },
  };
};
