/* eslint-disable no-unused-vars */
export enum CHARTS {
  AREA = 'AREA',
  BAR = 'BAR',
  LINE = 'LINE',
  PIE = 'PIE',
  RADAR = 'RADAR',
  SCATTER = 'SCATTER',
  BUBBLE = 'BUBBLE',
  HEATMAP = 'HEATMAP',
  STACKED = 'STACKED',
  WATERFALL = 'WATERFALL',
}

export const optionsVisualisation = [
  { label: 'Bar chart', value: CHARTS.BAR },
  { label: 'Line chart', value: CHARTS.LINE },
  { label: 'Pie chart', value: CHARTS.PIE },
  { label: 'Scatter plot', value: CHARTS.SCATTER },
  { label: 'Heat map', value: CHARTS.HEATMAP },
  { label: 'Area chart', value: CHARTS.AREA },
  { label: 'Stacked bar chart', value: CHARTS.STACKED },
  { label: 'Bubble chart', value: CHARTS.BUBBLE },
  { label: 'Waterfall chart', value: CHARTS.WATERFALL },
  { label: 'Radar chart', value: CHARTS.RADAR },
];
