import React, { useState } from 'react';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import {
  IconAction,
  IconCross,
  IconEdit,
  IconNormalize,
  IconPrint,
  IconSend,
  IconShare,
  IconTick,
} from '../public/Icons';
import { useSelector } from 'react-redux';
import { userData } from '../../redux/selector/UserSelector';
import CreditWidgetSaveModal from '../modals/CreditWidgetSaveModal';

type WidgetMenuDropdownProps = {
  type?: string;
  dropdownOpen: boolean;
  toggleDropDown: () => void;
  toggleSendWidgetAsTaskModal?: () => void;
  toggleShareExternallyModal?: () => void;
  toggleExportWidgeTDataModal: () => void;
  toggleRemoveWidgetModal: () => void;
  // eslint-disable-next-line
  savedType?: (data: string, saveName: string) => void;
  savedStatType?: () => void;
  toggleIsNormalize?: () => void;
  setIsWidgetNameChanged?: React.Dispatch<React.SetStateAction<boolean>>;
};

const WidgetMenuDropdown = ({
  type,
  dropdownOpen,
  toggleDropDown,
  toggleSendWidgetAsTaskModal,
  toggleShareExternallyModal,
  toggleExportWidgeTDataModal,
  toggleRemoveWidgetModal,
  savedType,
  savedStatType,
  toggleIsNormalize,
  setIsWidgetNameChanged,
}: WidgetMenuDropdownProps) => {
  const user = useSelector(userData);
  const [modal, setModal] = useState(false);
  const [currentSaveType, setCurrentSaveType] = useState<string | null>(null);

  const toggleModal = () => setModal(!modal);

  const handleSave = (saveName: string) => {
    if (currentSaveType) {
      savedType?.(currentSaveType, saveName);
      toggleDropDown();
    }
  };
  return (
    <>
      <Dropdown
        className="add-dropdown"
        isOpen={dropdownOpen}
        toggle={toggleDropDown}
      >
        <DropdownToggle caret direction="end" className="btn-icon">
          <IconAction />
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem
            onClick={() => {
              setIsWidgetNameChanged?.(true);
            }}
          >
            <IconEdit />
            Edit Name
          </DropdownItem>
          <DropdownItem
            onClick={() => {
              toggleIsNormalize?.();
            }}
          >
            <IconNormalize />
            Normalize & Map
          </DropdownItem>
          <div className="di-divider"></div>
          {type !== 'TASKS' &&
            user?.userDetail?.license?.licenseType !== 'BASIC' &&
            user?.userDetail?.license?.licenseType !== 'SILVER' && (
              <>
                <DropdownItem onClick={toggleSendWidgetAsTaskModal}>
                  <IconSend />
                  Send as Task
                </DropdownItem>
                <DropdownItem onClick={toggleShareExternallyModal}>
                  <IconShare />
                  Share Externally
                </DropdownItem>
              </>
            )}

          {type === 'CREDIT' && (
            <>
              <DropdownItem
                className="sub-dropdown"
                // onClick={() => {
                //   savedType?.('NPL');
                // }}
              >
                <IconTick fill="#E0E0E0" />
                Save as
                <DropdownMenu direction="start">
                  <DropdownItem
                    onClick={() => {
                      setCurrentSaveType('NPL');
                      toggleModal();
                    }}
                  >
                    <IconTick fill="#E0E0E0" />
                    Save as NPL
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      setCurrentSaveType('LOAN');
                      toggleModal();
                    }}
                  >
                    <IconTick fill="#E0E0E0" />
                    Save as LOAN
                  </DropdownItem>

                  <DropdownItem
                    onClick={() => {
                      setCurrentSaveType('RECEIVABLES');
                      toggleModal();
                    }}
                  >
                    <IconTick fill="#E0E0E0" />
                    Save as Receivables
                  </DropdownItem>
                </DropdownMenu>
              </DropdownItem>
            </>
          )}

          {type === 'STATISTICAL' && (
            <DropdownItem
              onClick={() => {
                savedStatType?.();
              }}
            >
              <IconShare />
              Save Portfolio
            </DropdownItem>
          )}

          <DropdownItem onClick={toggleExportWidgeTDataModal}>
            <IconPrint />
            Export Data
          </DropdownItem>
          <div className="di-divider"></div>
          <DropdownItem onClick={toggleRemoveWidgetModal}>
            <IconCross />
            Remove Widget
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
      <CreditWidgetSaveModal
        modal={modal}
        toggle={toggleModal}
        onSave={handleSave}
      />
    </>
  );
};

export default WidgetMenuDropdown;
