import { reloadPortfolioConstant } from '../constant';

const { IS_PORTFOLIO_SAVED, PORTFOLIO_NOT_SAVED } = reloadPortfolioConstant;

const initialState = {
  isReload: false,
};

const ReloadPortfolioReducer = (state = initialState, action: any): any => {
  switch (action.type) {
    case IS_PORTFOLIO_SAVED:
      return {
        isReload: action.payload.isReload,
      };

    case PORTFOLIO_NOT_SAVED:
      return initialState;

    default:
      return state;
  }
};

export default ReloadPortfolioReducer;
