import { isEmpty, split } from 'lodash';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import SpeechRecognition, {
  useSpeechRecognition,
} from 'react-speech-recognition';
import { chatData } from '../../../redux/selector/ChatSelector';
import {
  allPortfolioWidgetsSelector,
  // allPortfolioWidgetsSelector,
  portfolioCreditWidgetSelector,

  // portfolioVisualizationWidgetSelector,
} from '../../../redux/selector/PortfolioWidgetsSelector';
import AIassistantGIF from '../../../../assets/images/ai-assistant.gif';
import { userData } from '../../../redux/selector/UserSelector';
import { warrenFileData } from '../../../redux/selector/WarrenFileSelector';
import {
  independentWidgetReferenceIdData,
  widgetReferenceIdData,
} from '../../../redux/selector/WidgetReferenceIdSelector';
import { widgetTypeData } from '../../../redux/selector/WidgetTypeSelector';
import useAction from '../../hooks/useAction';
import { IconChatMesage, IconSend, IconAttachment, IconCopy } from '../Icons';
import axios from 'axios';
import config from '../../../../config';
import FileSelectorModal from '../FileSelector';
import CustomSelectForChatBotNew from '../../ui/CustomSelectForChatBot';
//import { totalRowsData } from '../../../redux/selector/TotalRowsSelector';
//import { rowIndexData } from '../../../redux/selector/RowIndexSelector';

const textareaStyle: React.CSSProperties = {
  width: '100%',
  minHeight: '1.5rem', // Approx height for 1 line
  maxHeight: '4.5rem', // Approx height for 3 lines
  overflowY: 'auto', // Allow scrolling once the max height is reached
  resize: 'none', // Disable manual resizing
  padding: '8px',
  boxSizing: 'border-box',
};

const MessageInput = ({
  socket,
  // widgetIndex,
  widgetReferenceId,
  activeTab,
  selectedModal,
}: any) => {
  const [inputText, setInputText] = useState('');

  const {
    addNewMessage,
    showModal,
    // addRowIndex,
    addRowSelectionType,
    addTypingStatus,
    addChatQuery,
    addNewsQuery,
    addLoanChatQuery,
    addNplChatQuery,
    addReceivableChatQuery,
  } = useAction();

  const user = useSelector(userData);
  const widgetName = useSelector(widgetTypeData);
  const chatMsg = useSelector(chatData);
  const warrenFileNames = useSelector(warrenFileData);
  const widgetReferenceIdValue = useSelector(widgetReferenceIdData);
  const independentWidgetReferenceIdValue = useSelector(
    independentWidgetReferenceIdData,
  );

  const allPortfolioWidgetReduxData = useSelector(allPortfolioWidgetsSelector);

  const chartsConfiguration =
    allPortfolioWidgetReduxData[widgetReferenceIdValue];

  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);
  const [modal, setModal] = useState(false);
  const [showAttachmentOptions, setShowAttachmentOptions] = useState(false);
  const toggleModal = () => setModal(!modal);

  const portfolioCreditReduxData = useSelector(portfolioCreditWidgetSelector);

  //speech to text
  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();

  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadingFileIndex, setUploadingFileIndex] = useState<number | null>(
    null,
  );
  const [isFile, setIsFile] = useState(false);
  const [documentIds, setDocumentIds] = useState<string[]>([]);
  const [fileNames, setFileNames] = useState<string[]>([]);
  const [selectedFilesArray, setSelectedFilesArray] = useState<
    { _id: string; file_name: string }[]
  >([]);

  const options = [
    { value: 'upload', label: 'Upload Files' },
    { value: 'localStorage', label: 'Use LocalStorage' },
  ];

  const fileInputRef = useRef<HTMLInputElement>(null);
  if (!browserSupportsSpeechRecognition) {
    return <span>Browser doesn't support speech recognition.</span>;
  }

  useEffect(() => {
    setInputText('');
  }, [widgetReferenceIdValue, widgetName]);

  const onInputChange = (e: any) => {
    setInputText(e.target.value);

    const textarea = textAreaRef.current;

    if (textarea) {
      textarea.style.height = 'auto';

      textarea.style.height = `${Math.min(textarea.scrollHeight, 72)}px`;
    }
  };

  const appendFileName = (fileName: string) => {
    setInputText((prevText) => `${prevText} ${fileName}`);
    const textarea = textAreaRef.current;

    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = `${Math.min(textarea.scrollHeight, 72)}px`;

      textarea.focus();
      textarea.setSelectionRange(textarea.value.length, textarea.value.length);
    }
  };

  const handleMessageValidation = () => {
    if (!isEmpty(inputText.trim())) {
      return true;
    } else {
      return false;
    }
  };

  // console.log('Selected Modal in Input: ', selectedModal.value);
  let isMainChatBot = 'False';

  if (widgetName === 'MAIN') {
    isMainChatBot = 'True';
  }
  const handleSendMessage = () => {
    const isValid = handleMessageValidation();

    let currentDate = moment().format();
    if (isValid) {
      if (!isEmpty(socket)) {
        let searchValue = [];
        for (let chat in chatMsg) {
          let filteredVal = chatMsg[chat]
            ?.filter((a: any) => a.widget_type?.toUpperCase() === widgetName)
            ?.reverse();
          searchValue.unshift(filteredVal);
        }

        let botMsg = searchValue[0]?.find(
          (x: any) => Number(x.referenceId) + 1 === x.id,
        );

        let newMessage = {
          isMainChatBot: isMainChatBot,
          selectedModal: selectedModal,
          message: inputText.trim(),
          //messageType: widgetName?.includes('NEWS') ? 'NEWS' : 'TEXT',
          messageType: 'TEXT',
          widget_type: widgetName,
          created: currentDate,
          createdDate: split(currentDate, 'T')[0],
          isSearch: true,
          fromUser: true,
          from_id: user.user_id,
          activeTab: activeTab,
          isChat: activeTab === 'CHAT',
          isHistory: activeTab === 'HISTORY',
          widgetReferenceId: widgetReferenceId
            ? widgetReferenceId
            : independentWidgetReferenceIdValue,
          isFile: isFile,
          documentIds: isFile ? documentIds : [],
        };

        addNewMessage(newMessage);

        if (
          !warrenFileNames.length ||
          (warrenFileNames.length && widgetName !== 'CREDIT')
        ) {
          if (widgetName === 'CREDIT') {
            addChatQuery(inputText.trim());
            socket.emit('sendNewMessage', {
              isMainChatBot: isMainChatBot,
              selectedModal: selectedModal,
              message: inputText.trim(),
              messageType: 'TEXT',
              widgetType: widgetName,
              isChat: activeTab === 'CHAT',
              isHistory: activeTab === 'HISTORY',
              activeTab: activeTab,
              widgetReferenceId: independentWidgetReferenceIdValue,
              chartsConfiguration: chartsConfiguration,
            });
          }

          if (widgetName === 'LOAN_FINANCIAL') {
            addLoanChatQuery(inputText.trim());
          }

          if (widgetName === 'NPL_FINANCIAL') {
            addNplChatQuery(inputText.trim());
          }

          if (widgetName === 'RECEIVABLE') {
            addReceivableChatQuery(inputText.trim());
          }

          if (widgetName === 'NEWS_FEED') {
            addNewsQuery(inputText.trim());
            socket.emit('sendNewMessage', {
              isMainChatBot: isMainChatBot,
              selectedModal: selectedModal,
              message: inputText.trim(),
              messageType: 'TEXT',
              file: '',
              widgetType: widgetName,
              activeTab: activeTab,
              fromId: user.user_id,
              isChat: activeTab === 'CHAT',
              isHistory: activeTab === 'HISTORY',
              // widgetReferenceId: widgetReferenceIdValue,
              widgetReferenceId: independentWidgetReferenceIdValue,
              referenceId: botMsg?.id,
              key:
                widgetName === 'CREDIT'
                  ? portfolioCreditReduxData[0]?.fileKey || ''
                  : '',
              isFile: isFile, // Pass isFile
              documentIds: isFile ? documentIds : [],
            });
          }
          if (widgetName === 'EMBEDDED_BROWSER') {
            socket.emit('sendNewMessage', {
              isMainChatBot: isMainChatBot,
              selectedModal: selectedModal,
              message: inputText.trim(),
              messageType: 'TEXT',
              widgetType: widgetName,
              isChat: activeTab === 'CHAT',
              isHistory: activeTab === 'HISTORY',
              activeTab: activeTab,
              widgetReferenceId: independentWidgetReferenceIdValue,
              chartsConfiguration: chartsConfiguration,
            });
          }
          if (widgetName === 'MAIN') {
            socket.emit('sendNewMessage', {
              isMainChatBot: isMainChatBot,
              selectedModal: selectedModal,
              message: inputText.trim(),
              messageType: 'TEXT',
              file: '',
              selectedFiles: selectedFilesArray,
              widgetType: widgetName,
              activeTab: activeTab,
              fromId: user.user_id,
              isChat: activeTab === 'CHAT',
              isHistory: activeTab === 'HISTORY',
              // widgetReferenceId: widgetReferenceIdValue,
              widgetReferenceId: independentWidgetReferenceIdValue,
              referenceId: botMsg?.id,
              key:
                widgetName === 'CREDIT'
                  ? portfolioCreditReduxData[0]?.fileKey || ''
                  : '',
              isFile: isFile, // Pass isFile
              documentIds: isFile ? documentIds : [],
            });
          }
        }

        socket.on('typing', ({ processType }: any) => {
          addTypingStatus(processType);
        });

        socket.on('socket_error', (e: any) => {
          showModal(`Connection Error: ${e}`);
        });

        resetTranscript();
        setInputText('');
      }
    }
  };

  useEffect(() => {
    if (listening) {
      setInputText(transcript);
    }
  }, [transcript]);

  /*const toggleCloseModal = () => {
    document.body.classList.remove('has-overlay');
    document.body.classList.remove('show-chat');
  };*/
  const handleUploadFile = async (files: FileList | null) => {
    if (files) {
      const formData = new FormData();

      for (let i = 0; i < files.length; i++) {
        formData.append('file', files[i]);
      }

      try {
        setUploadingFileIndex(0);

        const fileExtension = files[0].name.split('.').pop()?.toLowerCase();

        if (
          fileExtension === 'csv' ||
          fileExtension === 'xlsx' ||
          fileExtension === 'xls'
        ) {
          showModal('Use the Credit Widget for CSV/XLSX!', false);
          return;
        } else if (fileExtension === 'pdf') {
          const response = await axios({
            method: 'post',
            url: `${config.ER_ML_URL}/file/`,
            data: formData,
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });

          if (response.status === 200) {
            setDocumentIds((prevIds) => [
              ...prevIds,
              response?.data?.DocumentId,
            ]);
            const newFileEntry = {
              _id: response.data.DocumentId,
              file_name: response.data.file_name,
            };
            setSelectedFilesArray((prevArray) => [...prevArray, newFileEntry]);
            setIsFile(true);
            showModal('Uploaded successfully!', true);
          }
        } else {
          showModal('Unsupported file type', false);
        }
      } catch (error) {
        showModal('Something went wrong');
      } finally {
        setUploadingFileIndex(null);
        setIsUploading(false);
      }
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    if (files) {
      const fileArray = Array.from(files);

      const validFiles = fileArray.filter((file) => {
        const fileExtension = file.name.split('.').pop()?.toLowerCase();
        if (
          fileExtension === 'csv' ||
          fileExtension === 'xlsx' ||
          fileExtension === 'xls'
        ) {
          showModal('Use the Credit Widget for CSV/XLSX!', false);
          return false;
        }
        return true;
      });

      if (validFiles.length > 0) {
        setSelectedFiles((prevFiles) => {
          const mergedFiles = [...prevFiles, ...validFiles];
          const uniqueFiles = mergedFiles.filter(
            (file, index, self) =>
              index === self.findIndex((f) => f.name === file.name), // Deduplicate by file name
          );
          return uniqueFiles;
        });

        handleUploadFile(validFiles as unknown as FileList);
      }
    }
  };

  const handleUploadFiles = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleRemoveFile = (index: number) => {
    const updatedFiles = selectedFiles.filter((_, i) => i !== index);
    const updatedDocumentIds = documentIds.filter((_, i) => i !== index);
    const updatedFilesArray = selectedFilesArray.filter((_, i) => i !== index);

    setSelectedFiles(updatedFiles);
    setDocumentIds(updatedDocumentIds);
    setSelectedFilesArray(updatedFilesArray);
    // if (updatedFiles.length === 0) {
    //   setIsFile(false);
    // } else {
    //   setIsFile(true);
    // }
  };

  useEffect(() => {
    setIsFile(selectedFilesArray.length > 0);
  }, [selectedFiles, selectedFilesArray]);

  const handleUseLocalStorage = () => {
    toggleModal();
  };

  const handleLocalStorageFileSelect = (
    selectedFiles: { _id: string; file_name: string }[],
  ) => {
    toggleModal();

    setSelectedFilesArray((prevFiles) => {
      const mergedFiles = [...prevFiles, ...selectedFiles];
      const uniqueFiles = mergedFiles.filter(
        (file, index, self) =>
          index === self.findIndex((f) => f._id === file._id),
      );
      return uniqueFiles;
    });

    const newDocumentIds = selectedFiles.map((file) => file._id);
    const newFileNames = selectedFiles.map((file) => file.file_name);

    setDocumentIds((prevIds) => {
      const mergedIds = [...prevIds, ...newDocumentIds];
      const uniqueIds = [...new Set(mergedIds)];
      return uniqueIds;
    });

    setFileNames((prevNames) => {
      const mergedNames = [...prevNames, ...newFileNames];
      const uniqueNames = [...new Set(mergedNames)];
      return uniqueNames;
    });

    setIsFile(selectedFiles.length > 0);
  };

  const handleRemoveLocalStorageSelectedFile = (index: number) => {
    const updatedFileNames = fileNames.filter((_, i) => i !== index);
    const updatedDocumentIds = documentIds.filter((_, i) => i !== index);
    const updatedFilesArray = selectedFilesArray.filter((_, i) => i !== index);

    setFileNames(updatedFileNames);
    setDocumentIds(updatedDocumentIds);
    setSelectedFilesArray(updatedFilesArray);
  };

  const handleSelectChange = (selectedOption: any) => {
    if (selectedOption.value === 'upload') {
      handleUploadFiles();
    } else if (selectedOption.value === 'localStorage') {
      handleUseLocalStorage();
    }
    setShowAttachmentOptions(false);
  };

  return (
    <>
      {selectedFiles.length > 0 && (
        <div style={{ marginTop: '10px', color: 'green', width: '100%' }}>
          {selectedFiles.map((file, index) => (
            <div
              key={index}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '5px',
                background: 'transparent',
                marginBottom: '5px',
              }}
            >
              <span>{file.name}</span>

              <div style={{ display: 'flex', alignItems: 'center' }}>
                {uploadingFileIndex === index ? (
                  <img
                    src={AIassistantGIF}
                    alt="Uploading..."
                    style={{ width: '70px', height: '70px' }}
                  />
                ) : (
                  <>
                    <button
                      type="button"
                      style={{
                        color: 'red',
                        cursor: 'pointer',
                        border: 'none',
                        background: 'none',
                        marginRight: '5px',
                      }}
                      onClick={() => handleRemoveFile(index)}
                    >
                      ✕
                    </button>
                    <button
                      type="button"
                      style={{
                        color: 'blue',
                        cursor: 'pointer',
                        border: 'none',
                        background: 'none',
                      }}
                      onClick={() => appendFileName(file.name)}
                    >
                      <IconCopy width={14} height={14} fill="white" />
                    </button>
                  </>
                )}
              </div>
            </div>
          ))}
        </div>
      )}

      {fileNames.length > 0 && (
        <div style={{ marginTop: '10px', color: 'green', width: '100%' }}>
          {fileNames.map((file_name, index) => (
            <div
              key={index}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '5px',
                background: 'transparent',
                marginBottom: '5px',
              }}
            >
              <span>{file_name}</span>

              <div style={{ display: 'flex', alignItems: 'center' }}>
                <button
                  type="button"
                  style={{
                    color: 'red',
                    cursor: 'pointer',
                    border: 'none',
                    background: 'none',
                    marginRight: '5px',
                  }}
                  onClick={() => handleRemoveLocalStorageSelectedFile(index)}
                >
                  ✕
                </button>
                <button
                  type="button"
                  style={{
                    color: 'blue',
                    cursor: 'pointer',
                    border: 'none',
                    background: 'none',
                  }}
                  onClick={() => appendFileName(file_name)}
                >
                  <IconCopy width={14} height={14} fill="white" />
                </button>
              </div>
            </div>
          ))}
        </div>
      )}

      {activeTab !== 'CONVERSATION' && (
        <div className="keyboard-access flexbox">
          {!listening && (
            <textarea
              ref={textAreaRef}
              className={'form-control'}
              style={textareaStyle}
              value={inputText}
              autoFocus
              onChange={onInputChange}
              onKeyDown={(e) => {
                const target = e.target as HTMLTextAreaElement;
                if (e.key === 'Enter') {
                  if (e.shiftKey) {
                    e.preventDefault();
                    const cursorPosition = target.selectionStart;
                    const newValue =
                      inputText.slice(0, cursorPosition) +
                      '\n' +
                      inputText.slice(cursorPosition);
                    onInputChange({
                      target: { value: newValue },
                    } as React.ChangeEvent<HTMLTextAreaElement>);
                  } else {
                    e.preventDefault();
                    handleSendMessage();
                  }
                }
              }}
              onClick={() => {
                addRowSelectionType('CHATBOX');
              }}
              rows={1}
              placeholder="Enter a prompt here"
            />
          )}
          {listening && (
            <p className="text-speak">
              <span></span>Speak into the microphone, I’m listening...
            </p>
          )}

          {listening ? (
            <button
              type="button"
              className="btn-chat-icon"
              onClick={(e) => {
                e.preventDefault();
                SpeechRecognition.stopListening();
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleSendMessage();
                }
              }}
            >
              <IconChatMesage fill="fill-orange" />
            </button>
          ) : (
            <>
              <button
                type="button"
                className="btn-chat-icon"
                onClick={(e) => {
                  e.preventDefault();
                  SpeechRecognition.startListening();
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleSendMessage();
                  }
                }}
              >
                <IconChatMesage />
              </button>
              <button
                type="button"
                className="btn-chat-icon ms-2"
                onClick={() => setShowAttachmentOptions((prev) => !prev)}
              >
                <IconAttachment />
              </button>

              {showAttachmentOptions && (
                <div
                  style={{
                    width: '200px',
                    position: 'absolute',
                    right: '0',
                    bottom: '60px',
                    zIndex: 1,
                  }}
                >
                  <CustomSelectForChatBotNew
                    options={options}
                    isMulti={false}
                    onChange={handleSelectChange}
                    value={null}
                    isClearable={false}
                    menuIsOpen={true}
                    isSearchable={false}
                  />
                </div>
              )}
            </>
          )}
          {inputText?.length > 0 && (
            <button
              type="button"
              className="btn-chat-icon ms-2"
              onClick={handleSendMessage}
              disabled={isUploading}
            >
              <IconSend width={24} height={24} fill="fill-info" />
            </button>
          )}

          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={handleFileChange}
            accept="*/*"
            multiple
          />
        </div>
      )}

      <FileSelectorModal
        modal={modal}
        toggle={toggleModal}
        onSelect={handleLocalStorageFileSelect}
      />
    </>
  );
};

export default MessageInput;
