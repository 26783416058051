import CreatableSelect from 'react-select/creatable';

const ReactCreatableSelect = ({
  options,
  isMulti,
  name,
  // className,
  // classNamePrefix,
  value,
  isClearable,
  styles,
  onChange,
  ...props
}: any) => {
  return (
    <CreatableSelect
      options={options}
      isMulti={isMulti}
      name={name}
      // className={className}
      // classNamePrefix={classNamePrefix}
      className="react-select-container"
      classNamePrefix="react-select"
      value={value}
      isClearable={isClearable}
      styles={styles}
      onChange={onChange}
      {...props}
    />
  );
};

export default ReactCreatableSelect;
