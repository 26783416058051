import { useEffect, useState } from 'react';
import {
  IconDownArrow,
  IconSearch,
  IconSort,
  IconUpArrow,
} from '../../../../../components/public/Icons';
import AddNewDataItemModal from '../../modals/AddNewDataItemModal';
import MarketPlaceService from '../../../../../services/marketplace.service';
import useAction from '../../../../../components/hooks/useAction';
import moment from 'moment';
import EndpointsActionDropdown from './EndpointsActionDropdown';
import useDebounce from '../../../../../components/hooks/useDebounce';
import { UncontrolledCollapse } from 'reactstrap';
import ContentLoader from '../../../../../components/loaders/ContentLoader';

const marketplaceService = new MarketPlaceService();

type EndpointListTypes = {
  _id: string;
  endpointName: string;
  endpointDescription: string;
  createdAt: Date;
  isServiceActive: boolean;
  id: string;
};

type AddSubDataSetProps = {
  sourceId: string;
};

export type EndpointDataTypes = {
  _id: string;
  secretKey: string;
  secretKeyName: string;
  serviceUrl: string;
  userName: string;
  password: string;
  id: string;
  endpointName: string;
  endpointDescription: string;
  sourceId: string;
  source: Source;
};

export type Source = {
  id: string;
  serviceName: string;
  serviceType: string;
  isPaidService: boolean;
};

type CollapseState = {
  [key: number]: boolean;
};

const AddSubDataSet = ({ sourceId }: AddSubDataSetProps) => {
  const { showModal } = useAction();

  const [addSubsetModal, setAddSubsetModal] = useState(false);
  const [endPointData, setEndpointData] = useState<EndpointDataTypes | null>();
  const [endpointList, setEndpointList] = useState<Array<EndpointListTypes>>(
    [],
  );
  const [searchQuery, setSearchQuery] = useState('');
  const [endpointTableData, setEndpointTableData] = useState<any>();
  const [activeRowIndex, setActiveRowIndex] = useState<number>(-1);
  const [serviceId, setServiceId] = useState('');

  const [isOpen, setIsOpen] = useState<CollapseState>({});

  const debouncedUserInput = useDebounce(searchQuery, 1000);
  const [isLoading, setIsLoading] = useState(false);
  const toggleSubsetModal = () => {
    setAddSubsetModal(!addSubsetModal);
  };

  const toggleCollapse = (index: number) => {
    setIsOpen(prevIsOpen => {
      return {
        ...prevIsOpen,
        [index]: !prevIsOpen[index],
      };
    });
  };

  const getEndpointTableData = (serviceId: string) => {
    // setActiveRowIndex(index);
    setEndpointTableData([]);

    setIsLoading(true);

    marketplaceService
      .searchEndpointTableMetaData(serviceId)
      .then(({ data }) => {
        setEndpointTableData(data?.metadata || []);

        setIsLoading(false);
      })
      .catch(err => {
        setIsLoading(false);
        showModal(err?.response?.data?.error);
      });
  };

  const updateEndpointServiceActiveStatus = (id: string, status: boolean) => {
    setIsLoading(true);
    const payload = {
      serviceId: id,
      isServiceActive: status,
    };
    marketplaceService
      .updateEndpointActiveStatus(payload)
      .then(() => {
        getAllEndpointList();
        setIsLoading(false);
      })
      .catch(err => {
        setIsLoading(false);
        showModal(err?.response?.data?.error);
      });
  };

  const updateEndpointServiceActiveStatusAll = (status: boolean) => {
    setIsLoading(true);
    const payload = {
      sourceId: sourceId,
      isServiceActive: status,
    };
    marketplaceService
      .updateEndpointActiveStatusAll(payload)
      .then(() => {
        getAllEndpointList();
        setIsLoading(false);
      })
      .catch(err => {
        setIsLoading(false);
        showModal(err?.response?.data?.error);
      });
  };

  const getNewsSourceEndpointData = (endpointId: string) => {
    setIsLoading(true);
    marketplaceService
      .getEndpointById(endpointId)
      .then(({ data }) => {
        setEndpointData(data);
        toggleSubsetModal();
        setIsLoading(false);
      })
      .catch(err => {
        setIsLoading(false);
        showModal(err?.response?.data?.error);
      });
  };

  const getAllEndpointList = () => {
    setIsLoading(true);
    const payload = {
      sourceId: sourceId,
      q: searchQuery,
    };
    marketplaceService
      .getAllServicesOfSources(payload)
      .then(({ data }) => {
        setEndpointList(data);
        setIsLoading(false);
      })
      .catch(err => {
        setIsLoading(false);
        showModal(err?.response?.data?.error);
      });
  };

  useEffect(() => {
    if (activeRowIndex >= 0 && serviceId) {
      getEndpointTableData(serviceId);
    }
  }, [activeRowIndex]);

  useEffect(() => {
    if (sourceId) getAllEndpointList();
  }, [sourceId, debouncedUserInput]);

  return (
    <>
      <div className="addwidget-content mw-100 position-relative">
        {isLoading && <ContentLoader title={''} />}
        <div className="main-widget pe-0 widget-clear">
          <div className="react-grid-item wc-scroll">
            <div className="common-widget">
              {endpointList?.length >= 1 && (
                <>
                  <div className="flexbox">
                    <div className="flexbox-c gap-3 mb-2">
                      <div className="form-group form-icon mb-0">
                        <span className="input-icon">
                          <IconSearch />
                        </span>
                        <input
                          id="searchQueryEndpoints"
                          type="text"
                          placeholder="Search"
                          className="form-control"
                          value={searchQuery}
                          onChange={e => {
                            //React.ChangeEvent<HTMLInputElement>
                            setSearchQuery(e.target.value);
                          }}
                        />
                      </div>
                      <button
                        className="btn btn-outline-secondary text-info"
                        type="button"
                        onClick={() => {
                          updateEndpointServiceActiveStatusAll(
                            !endpointList[0]?.isServiceActive,
                          );
                        }}
                      >
                        Toggle All
                      </button>
                    </div>
                    <div className="mb-2">
                      <button
                        className="btn btn-outline-secondary text-info"
                        onClick={toggleSubsetModal}
                      >
                        Add Endpoint
                      </button>
                    </div>
                  </div>
                  <div className="table-responsive marketplace-table">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th
                            style={{ width: '58px' }}
                            scope="col"
                            className="text-center"
                          ></th>
                          <th style={{ width: '260px' }} scope="col">
                            Name
                            <span className="sortable asc">
                              <IconSort />
                            </span>
                          </th>
                          <th scope="col">Description</th>
                          <th style={{ width: '110px' }} scope="col">
                            Date Added
                          </th>
                          <th style={{ width: '80px' }} scope="col"></th>
                          <th style={{ width: '80px' }} scope="col">
                            Preview
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {endpointList?.map((row, index) => {
                          return (
                            <>
                              <tr key={index}>
                                <td className="text-center">
                                  <div className="form-check form-switch">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      role="switch"
                                      id={row.id}
                                      checked={row.isServiceActive}
                                      onChange={() => {
                                        updateEndpointServiceActiveStatus(
                                          row.id,
                                          !row.isServiceActive,
                                        );
                                      }}
                                    />
                                  </div>
                                </td>
                                <td>{row?.endpointName}</td>
                                <td>{row?.endpointDescription}</td>
                                <td>
                                  {moment(row?.createdAt).format('DD-MM-YYYY')}
                                </td>
                                <td>
                                  <EndpointsActionDropdown
                                    endpointId={row.id}
                                    editSuccess={() => {
                                      getNewsSourceEndpointData(row.id);
                                    }}
                                    deleteSuccess={() => {
                                      getAllEndpointList();
                                    }}
                                  />
                                </td>
                                <td>
                                  <button
                                    className="btn-icon mx-auto"
                                    id={`toggler${index}`}
                                    type="button"
                                    onClick={() => {
                                      setActiveRowIndex(index);
                                      setServiceId(row?.id);
                                      toggleCollapse(index);

                                      // getEndpointTableData(row.id, index);
                                    }}
                                  >
                                    {index === activeRowIndex &&
                                    isOpen[index] ? (
                                      <IconUpArrow />
                                    ) : (
                                      <IconDownArrow />
                                    )}
                                  </button>
                                </td>
                              </tr>

                              {index === activeRowIndex && (
                                <tr>
                                  <td colSpan={6} className="">
                                    {/*add `child-table` class in above td if table cols item more then 10 `02-Aug` */}
                                    <UncontrolledCollapse
                                      toggler={`#toggler${index}`}
                                      isOpen={isOpen[index]}
                                    >
                                      <div className="table-responsive">
                                        <table className="table table-bordered">
                                          <thead>
                                            <tr>
                                              {/* {endpointTableData?.length > 0 &&
                                                Object.keys(
                                                  endpointTableData[0]?.news,
                                                )?.map(headerData => (
                                                  <th
                                                    // style={{ width: '150px' }}
                                                    scope="col"
                                                  >
                                                    <div className="flexbox-c">
                                                      {headerData}
                                                      <span className="sortable asc">
                                                        <IconSort />
                                                      </span>
                                                      <button className="btn btn-icon btn-16 ms-6">
                                                        <IconEye />
                                                      </button>
                                                    </div>
                                                  </th>
                                                ))} */}

                                              <th
                                                // style={{ width: '150px' }}
                                                scope="col"
                                              >
                                                <div className="flexbox-c">
                                                  Meta Data
                                                </div>
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <div className="row row-cols-md-4">
                                              {endpointTableData?.length > 0 &&
                                                !isLoading &&
                                                endpointTableData?.map(
                                                  (
                                                    endData: string,
                                                    endIndex: number,
                                                  ) => (
                                                    <div
                                                      className="col"
                                                      key={`${endIndex}-table-data`}
                                                    >
                                                      {/* text-disable */}
                                                      <p className="mb-0 text-truncate">
                                                        {endData}
                                                      </p>
                                                    </div>
                                                  ),
                                                )}
                                            </div>
                                            {endpointTableData?.length <= 0 &&
                                              !isLoading && (
                                                <tr>
                                                  <td>
                                                    <p className="my-1">
                                                      Data Not Found!
                                                    </p>
                                                  </td>
                                                </tr>
                                              )}
                                          </tbody>
                                        </table>
                                      </div>
                                    </UncontrolledCollapse>
                                  </td>
                                </tr>
                              )}
                            </>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </>
              )}

              {endpointList?.length < 1 && searchQuery && (
                <>
                  <div className="flexbox">
                    <div className="flexbox-c gap-3 mb-2">
                      <div className="form-group form-icon mb-0">
                        <span className="input-icon">
                          <IconSearch />
                        </span>
                        <input
                          id="searchQueryEndpoints"
                          type="text"
                          placeholder="Search"
                          className="form-control"
                          value={searchQuery}
                          onChange={e => {
                            //React.ChangeEvent<HTMLInputElement>
                            setSearchQuery(e.target.value);
                          }}
                        />
                      </div>
                      <button
                        className="btn btn-outline-secondary text-info"
                        type="button"
                        onClick={() => {
                          updateEndpointServiceActiveStatusAll(
                            !endpointList[0]?.isServiceActive,
                          );
                        }}
                      >
                        Toggle All
                      </button>
                    </div>
                    <div className="mb-2">
                      <button
                        className="btn btn-outline-secondary text-info"
                        onClick={toggleSubsetModal}
                      >
                        Add Endpoint
                      </button>
                    </div>
                  </div>
                  <div className="flexbox-align-c flex-column marketplace-table">
                    <p className="text-info mb-2">No Record Found!</p>
                  </div>
                </>
              )}

              {endpointList?.length < 1 && !searchQuery && (
                <div className="flexbox-align-c flex-column marketplace-table">
                  <p className="text-info mb-2">
                    No sub-datasets have been added yet
                  </p>
                  <button
                    className="btn-clear link"
                    onClick={toggleSubsetModal}
                  >
                    Click here to add a data source endpoint
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {addSubsetModal && (
        <AddNewDataItemModal
          modal={addSubsetModal}
          toggle={toggleSubsetModal}
          endpointData={endPointData as EndpointDataTypes}
          sourceId={sourceId}
          onCloseWithoutSave={() => {
            setEndpointData(null);
          }}
          onSuccess={() => {
            getAllEndpointList();
          }}
        />
      )}
    </>
  );
};

export default AddSubDataSet;
