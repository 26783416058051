import { useEffect, useState } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
// import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
} from 'reactstrap';
// import { AppContext } from '../../../App';
// import LightLogo from '../../../assets/images/exr-logo.svg';
import Scalata_Aqua from '../../../assets/images/Scalata_Aqua.svg';
import { __LocalStorage } from '../../helpers/constants';
// import { _SET_GlobaColor, hexToRGBCustom } from '../../helpers/utils.helper';
import { userData } from '../../redux/selector/UserSelector';
import useAction from '../hooks/useAction';
import NotificationModal from '../modals/NotificationModal';
import {
  IconDashboard,
  IconDataMarketplace,
  IconDropdownArrow,
  IconNewsfeed,
  IconNotification,
  IconPortfolio,
  // IconProducts,
  IconSettings,
} from './Icons';
import PublicChat from './PublicChat';
import { isEmpty } from 'lodash';

type PublicHeaderTypes = {
  dataItemModal?: boolean;
  toggleDataItemModal?: () => void;
  getFoundationalModelList?: () => Promise<void>;
};

const PublicHeader = ({
  dataItemModal,
  toggleDataItemModal,
  getFoundationalModelList,
}: PublicHeaderTypes) => {
  const toggleShowNotificationModal = () => {
    // setShowNotificationModal(!showNotificationModal);
    document.body.classList.toggle('show-notification');
  };

  const ctoggleDropDown = () => {
    setsearchDropDownOpen((state) => !state);
  };

  const [searchdropdownOpen, setsearchDropDownOpen] = useState(false);
  const { updateThemeType, logoutUser } = useAction();
  const user = useSelector(userData);
  const navigate = useNavigate();
  const { addMarketplaceTab } = useAction();

  const [addDataItemModal, setAddDataItemModal] = useState(false);
  const toggleAddDataItemModal = () => {
    setAddDataItemModal(!addDataItemModal);
    document.body.classList.toggle('show-dataitem');
  };

  const remove_GlobalCustomStyle = () => {
    const r = document.querySelector('body') as any;

    r.style = '';
  };

  const toggleTheme = (type: string) => {
    if (type === 'light') {
      document.body.classList.add('theme-light');
      document.body.classList.remove('theme-custom');
      // setThemeType('light');
      updateThemeType('light');
      localStorage.setItem('themeType', 'light');
      localStorage.removeItem(__LocalStorage.colors);
      remove_GlobalCustomStyle();
    }

    if (type === 'dark') {
      document.body.classList.remove('theme-light');
      document.body.classList.remove('theme-custom');
      // setThemeType('dark');
      updateThemeType('dark');
      localStorage.setItem('themeType', 'dark');
      localStorage.removeItem(__LocalStorage.colors);
      remove_GlobalCustomStyle();
    }
    if (type === 'custom') {
      document.body.classList.remove('theme-light');
      document.body.classList.add('theme-custom');
      // setThemeType('custom');
      updateThemeType('custom');
      localStorage.setItem('themeType', 'custom');
    }
  };

  useEffect(() => {
    toggleTheme(localStorage.getItem('themeType') || 'dark');
  }, []);

  const removeClassFunction = () => {
    document.body.classList.remove('show-dataitem');
    document.body.classList.remove('widget-fullscreen');
  };

  return (
    <>
      <PublicChat
        addDataItemModal={addDataItemModal}
        toggleAddDataItemModal={toggleAddDataItemModal}
        getFoundationalModelListMarketplace={getFoundationalModelList}
      />
      {/* <NotificationModal /> */}
      <header className="header">
        <div className="inner-header flexbox">
          <div className="nav-logo">
            <Link to={'/dashboard'} className="d-block">
              {/* <img src={LightLogo} alt="" height={38} /> */}
              <img src={Scalata_Aqua} alt="" height={60} />
              {/* {themeType === 'dark' ? (
                <img src={LightLogo} alt="" height={40} />
              ) : (
                <img src={LightLogo} alt="" height={40} />
              )} */}
            </Link>
          </div>
          <div className="menu-horizontal">
            {!isEmpty(user?.userDetail) && (
              <Nav tabs className="nav-tabs nav-tabs-menu">
                {user?.userDetail?.license?.licenseType !== 'BASIC' && (
                  <NavItem onClick={removeClassFunction}>
                    <NavLink to="/dashboard" className="nav-link">
                      <i className="nav-icon">
                        <IconDashboard />
                      </i>
                      Dashboard
                    </NavLink>
                  </NavItem>
                )}
                {user?.userDetail?.license?.licenseType !== 'BASIC' && (
                  <NavItem onClick={removeClassFunction}>
                    <NavLink to="/portfolio" className="nav-link">
                      <i className="nav-icon">
                        <IconPortfolio />
                      </i>
                      Portfolio
                    </NavLink>
                  </NavItem>
                )}

                <NavItem onClick={removeClassFunction}>
                  <NavLink to="/newsfeed" className="nav-link">
                    <i className="nav-icon">
                      <IconNewsfeed />
                    </i>
                    Newsfeed
                  </NavLink>
                </NavItem>
                {/* <NavItem>
                <NavLink to="/products" className="nav-link">
                  <i className="nav-icon">
                    <IconProducts />
                  </i>
                  Products
                </NavLink>
              </NavItem> */}
                {user?.userDetail?.license?.licenseType !== 'BASIC' && (
                  <NavItem
                    onClick={() => {
                      addMarketplaceTab('1');
                      if (!dataItemModal && !addDataItemModal) {
                        removeClassFunction();
                      }

                      if (dataItemModal) {
                        toggleDataItemModal?.();
                      }

                      if (addDataItemModal) {
                        toggleAddDataItemModal?.();
                      }
                    }}
                  >
                    <NavLink to="/data/marketplace" className="nav-link">
                      <i className="nav-icon">
                        <IconDataMarketplace />
                      </i>
                      Data Marketplace
                    </NavLink>
                  </NavItem>
                )}
              </Nav>
            )}
          </div>
          {/* <div className="nav-menu">
            <button
              type="button"
              onClick={() => context.toggleSideBar()}
              className="bg-transparent border-0 d-lg-none me-3 p-0"
            >
              <IconMenu fill="#fff" width={16} height={15} />
            </button>
            <h1 className="d-none d-lg-block">{capitalize(pageName)}</h1>
          </div> */}
          <ul className="list-unstyled nav-right">
            <li className="nav-r-item me-3">
              <Dropdown isOpen={searchdropdownOpen} toggle={ctoggleDropDown}>
                <DropdownToggle className="">
                  {user.name}
                  <IconDropdownArrow />
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem
                    onClick={() => {
                      navigate('/update-profile');
                      removeClassFunction();
                    }}
                  >
                    Profile
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      logoutUser();
                    }}
                  >
                    Logout
                  </DropdownItem>
                  {/* <DropdownItem>Text</DropdownItem> */}
                </DropdownMenu>
              </Dropdown>
            </li>
            <li className="nav-r-item">
              {/* <Link to={'/setting'} className="btn-icon">
                <IconSettings />
              </Link> */}
              <NavLink
                to={'/settings'}
                className="btn-icon"
                onClick={removeClassFunction}
              >
                <IconSettings />
              </NavLink>
            </li>
            <li className="nav-r-item">
              <button
                onClick={() => {
                  toggleShowNotificationModal();
                }}
                className="btn-icon"
              >
                <IconNotification />
              </button>
            </li>
          </ul>
        </div>
      </header>

      {/* {showNotificationModal && ( */}
      <NotificationModal
        // modal={showNotificationModal}
        toggle={toggleShowNotificationModal}
      />
      {/* )} */}
    </>
  );
};
export default PublicHeader;
