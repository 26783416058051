import { portfolioWidgetsConstant } from '../constant';

const {
  ADD_CREDIT_WIDGET_DATA,
  REMOVE_CREDIT_WIDGET_DATA,
  ADD_NPL_WIDGET_DATA,
  REMOVE_NPL_WIDGET_DATA,
  ADD_NPL_COLLATERAL_WIDGET_DATA,
  REMOVE_NPL_COLLATERAL_WIDGET_DATA,
  ADD_NPL_LOAN_WIDGET_DATA,
  REMOVE_NPL_LOAN_WIDGET_DATA,

  ADD_LOAN_WIDGET_DATA,
  REMOVE_LOAN_WIDGET_DATA,
  ADD_LOAN_COLLATERAL_WIDGET_DATA,
  REMOVE_LOAN_COLLATERAL_WIDGET_DATA,
  ADD_LOAN_LOAN_WIDGET_DATA,
  REMOVE_LOAN_LOAN_WIDGET_DATA,

  ADD_RECEIVABLE_WIDGET_DATA,
  REMOVE_RECEIVABLE_WIDGET_DATA,
  ADD_RECEIVABLE_COLLATERAL_WIDGET_DATA,
  REMOVE_RECEIVABLE_COLLATERAL_WIDGET_DATA,
  ADD_RECEIVABLE_LOAN_WIDGET_DATA,
  REMOVE_RECEIVABLE_LOAN_WIDGET_DATA,

  ADD_VISUALIZATION_WIDGET_DATA,
  REMOVE_VISUALIZATION_WIDGET_DATA,

  REFRESH_PORTFOLIO_PAGE,
} = portfolioWidgetsConstant;

const initialState: any = {
  creditWidgetData: [] as any,
  nplWidgetData: [] as any,
  nplCollateralWidgetData: [] as any,
  nplLoanWidgetData: [] as any,
  loanWidgetData: [] as any,
  loanCollateralWidgetData: [] as any,
  loanLoanWidgetData: [] as any,

  receivableWidgetData: [] as any,
  receivableCollateralWidgetData: [] as any,
  receivableLoanWidgetData: [] as any,

  visualizationWidgetData: [] as any,

  lastRefreshDate: new Date(),
};

const filterByRemoveId = (data: any, widgetId: any) => {
  let dataArray = [...data];
  let tempArray = dataArray.filter(x => x.id !== widgetId);
  return tempArray;
};

const updatedStateData = (data: any, widgetData: any) => {
  //newsData = {}
  let tempData = [...data];

  let widgetIndex = tempData.findIndex((x: any) => x?.id === widgetData.id);

  if (widgetIndex > -1) {
    tempData[widgetIndex] = widgetData;
  } else {
    tempData.push(widgetData);
  }

  return tempData;
};

const PortfolioWidgetsReducer = (state = initialState, action: any): any => {
  switch (action.type) {
    case ADD_CREDIT_WIDGET_DATA:
      return {
        ...state,
        creditWidgetData: updatedStateData(
          state.creditWidgetData,
          action.payload.creditWidgetData,
        ),
      };

    case REMOVE_CREDIT_WIDGET_DATA:
      return {
        ...state,
        creditWidgetData: filterByRemoveId(
          state.creditWidgetData,
          action.payload.removeId,
        ),
      };

    case ADD_NPL_WIDGET_DATA:
      return {
        ...state,
        nplWidgetData: updatedStateData(
          state.nplWidgetData,
          action.payload.nplWidgetData,
        ),
      };

    case REMOVE_NPL_WIDGET_DATA:
      return {
        ...state,
        nplWidgetData: filterByRemoveId(
          state.nplWidgetData,
          action.payload.removeId,
        ),
      };

    case ADD_NPL_COLLATERAL_WIDGET_DATA:
      return {
        ...state,
        nplCollateralWidgetData: updatedStateData(
          state.nplCollateralWidgetData,
          action.payload.nplCollateralWidgetData,
        ),
      };

    case REMOVE_NPL_COLLATERAL_WIDGET_DATA:
      return {
        ...state,
        nplCollateralWidgetData: filterByRemoveId(
          state.nplCollateralWidgetData,
          action.payload.removeId,
        ),
      };

    case ADD_NPL_LOAN_WIDGET_DATA:
      return {
        ...state,
        nplLoanWidgetData: updatedStateData(
          state.nplLoanWidgetData,
          action.payload.nplLoanWidgetData,
        ),
      };

    case REMOVE_NPL_LOAN_WIDGET_DATA:
      return {
        ...state,
        nplLoanWidgetData: filterByRemoveId(
          state.nplLoanWidgetData,
          action.payload.removeId,
        ),
      };

    case ADD_LOAN_WIDGET_DATA:
      return {
        ...state,
        loanWidgetData: updatedStateData(
          state.loanWidgetData,
          action.payload.loanWidgetData,
        ),
      };

    case REMOVE_LOAN_WIDGET_DATA:
      return {
        ...state,
        loanWidgetData: filterByRemoveId(
          state.loanWidgetData,
          action.payload.removeId,
        ),
      };

    case ADD_LOAN_COLLATERAL_WIDGET_DATA:
      return {
        ...state,
        loanCollateralWidgetData: updatedStateData(
          state.loanCollateralWidgetData,
          action.payload.loanCollateralWidgetData,
        ),
      };

    case REMOVE_LOAN_COLLATERAL_WIDGET_DATA:
      return {
        ...state,
        loanCollateralWidgetData: filterByRemoveId(
          state.loanCollateralWidgetData,
          action.payload.removeId,
        ),
      };

    case ADD_LOAN_LOAN_WIDGET_DATA:
      return {
        ...state,
        loanLoanWidgetData: updatedStateData(
          state.loanLoanWidgetData,
          action.payload.loanLoanWidgetData,
        ),
      };

    case REMOVE_LOAN_LOAN_WIDGET_DATA:
      return {
        ...state,
        loanLoanWidgetData: filterByRemoveId(
          state.loanLoanWidgetData,
          action.payload.removeId,
        ),
      };

    case ADD_RECEIVABLE_WIDGET_DATA:
      return {
        ...state,
        receivableWidgetData: updatedStateData(
          state.receivableWidgetData,
          action.payload.receivableWidgetData,
        ),
      };

    case REMOVE_RECEIVABLE_WIDGET_DATA:
      return {
        ...state,
        receivableWidgetData: filterByRemoveId(
          state.receivableWidgetData,
          action.payload.removeId,
        ),
      };

    case ADD_RECEIVABLE_COLLATERAL_WIDGET_DATA:
      return {
        ...state,
        receivableCollateralWidgetData: updatedStateData(
          state.receivableCollateralWidgetData,
          action.payload.receivableCollateralWidgetData,
        ),
      };

    case REMOVE_RECEIVABLE_COLLATERAL_WIDGET_DATA:
      return {
        ...state,
        receivableCollateralWidgetData: filterByRemoveId(
          state.receivableCollateralWidgetData,
          action.payload.removeId,
        ),
      };

    case ADD_RECEIVABLE_LOAN_WIDGET_DATA:
      return {
        ...state,
        receivableLoanWidgetData: updatedStateData(
          state.receivableLoanWidgetData,
          action.payload.receivableLoanWidgetData,
        ),
      };

    case REMOVE_RECEIVABLE_LOAN_WIDGET_DATA:
      return {
        ...state,
        receivableLoanWidgetData: filterByRemoveId(
          state.receivableLoanWidgetData,
          action.payload.removeId,
        ),
      };

    case ADD_VISUALIZATION_WIDGET_DATA:
      return {
        ...state,
        visualizationWidgetData: updatedStateData(
          state.visualizationWidgetData,
          action.payload.visualizationWidgetData,
        ),
      };

    case REMOVE_VISUALIZATION_WIDGET_DATA:
      return {
        ...state,
        visualizationWidgetData: filterByRemoveId(
          state.visualizationWidgetData,
          action.payload.removeId,
        ),
      };

    case REFRESH_PORTFOLIO_PAGE:
      return {
        ...state,
        lastRefreshDate: action.payload,
      };

    default:
      return state;
  }
};
export default PortfolioWidgetsReducer;
