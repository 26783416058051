import React from 'react';

type ReceivablesWidgetHeadingProps = {
  currentActiveTab: string;
};

const ReceivablesWidgetHeading = ({
  currentActiveTab,
}: ReceivablesWidgetHeadingProps) => {
  return (
    <>
      {currentActiveTab === '2' && (
        <h4 className="title-20">
          Select portfolio for the Receivables widget
        </h4>
      )}
      {currentActiveTab === '3' && (
        <h4 className="title-20">
          Define the goal, geography and time frame of the Receivables widget
        </h4>
      )}
      {currentActiveTab === '4' && (
        <h4 className="title-20">
          Define statistical analysis thresholds for the Receivables widget
        </h4>
      )}
    </>
  );
};

export default ReceivablesWidgetHeading;
