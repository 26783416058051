import React, { useEffect, useState } from 'react';
import { IconInfo } from '../../../../../components/public/Icons';
import { CustomTooltip } from '../../../../../components/ui/CustomTooltip';
import PortfolioService from '../../../../../services/portfolio.service';
import DataLoader from '../../../../../components/loaders/DataLoader';
import CustomSelect from '../../../../../components/ui/CustomSelect';
import { statisticalWidgetOptions } from '../../../../../redux/constant';

const portfolioService = new PortfolioService();

type SelectStatPortfoliosTypes = {
  selectedWidget: string;
  // eslint-disable-next-line no-unused-vars
  updateSelectedWidget: (val: string) => void;
  selectedPortfolioStat: string[];
  setSelectedPortfolioStat: React.Dispatch<React.SetStateAction<string[]>>;
  statPortfolioList: {
    label: string;
    value: string;
  }[];
  setStatPortfolioList: React.Dispatch<
    React.SetStateAction<
      {
        label: string;
        value: string;
      }[]
    >
  >;
};

const SelectStatPortfolios = ({
  selectedWidget,
  updateSelectedWidget,
  setSelectedPortfolioStat,
  selectedPortfolioStat,
  statPortfolioList,
  setStatPortfolioList,
}: SelectStatPortfoliosTypes) => {
  const [isLoading, setIsLoading] = useState(true);
  const [selectedStatWidgetType, setSelectedStatWidgetType] = useState<{
    label: string;
    value: string;
  } | null>(null);

  const callGetNplPortfolioList = () => {
    setIsLoading(true);
    const payload = {
      category: selectedWidget,
    };
    portfolioService
      .getPortfolioList(payload)
      .then(({ data }) => {
        setIsLoading(false);
        setStatPortfolioList(
          data.map((x: any) => {
            return {
              label: x.saveName || x.fileName,
              value: x._id,
            };
          }),
        );
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (
      selectedWidget === 'LOAN' ||
      selectedWidget === 'RECEIVABLE' ||
      selectedWidget === 'NPL'
    ) {
      callGetNplPortfolioList();
    }
  }, [selectedWidget]);

  return (
    <>
      <div className="addwidget-content mw-100">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-6">
            <div className="create-widget create-widget-portfolio">
              <div className="form-group">
                <label className="form-label">Select widget Type</label>
                <CustomSelect
                  id="statWidgetTypes"
                  name="statWidgetTypes"
                  options={statisticalWidgetOptions}
                  value={selectedStatWidgetType}
                  placeholder="Select Widget Type"
                  onChange={(e: { label: string; value: string }) => {
                    setSelectedStatWidgetType(e);
                    updateSelectedWidget(e?.value);
                  }}
                />
              </div>

              {(selectedWidget === 'LOAN' ||
                selectedWidget === 'RECEIVABLE' ||
                selectedWidget === 'NPL') && (
                <>
                  <div className="d-flex align-items-center mb-1">
                    <p className="widget-category me-1 mb-0">
                      SELECT {selectedWidget} PORTFOLIO
                    </p>
                    <span className="d-flex" id="tooltip113">
                      <IconInfo />
                    </span>
                    <CustomTooltip placement="top" target="tooltip113" />
                  </div>

                  <div className="widget-item">
                    <div className="row row-cols-1">
                      {statPortfolioList &&
                        statPortfolioList?.length > 0 &&
                        statPortfolioList?.map((item, index) => (
                          <div className="col" key={index}>
                            <input
                              type="checkbox"
                              name="select-portfolio"
                              onClick={() => {
                                let tempArr = [...selectedPortfolioStat];

                                const itemIndex = tempArr?.findIndex(
                                  x => x === item?.value,
                                );

                                if (itemIndex > -1) {
                                  tempArr?.splice(itemIndex, 1);
                                } else {
                                  tempArr.push(item?.value);
                                }
                                setSelectedPortfolioStat(tempArr);
                              }}
                            />
                            <div className="widget-card">
                              <p>{item.label}</p>
                            </div>
                          </div>
                        ))}
                      {isLoading && <DataLoader />}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SelectStatPortfolios;
