import { widgetrankConstant } from '../constant';

const { ADD_WIDGET_RANK, FAILURE_WIDGET_RANK } = widgetrankConstant;

const initialState: any = {
  widgetRank: {},
  message: '',
};

const WidgetRankReducer = (
  state = initialState,
  { type, payload }: any,
): any => {
  switch (type) {
    case ADD_WIDGET_RANK:
      return {
        widgetRank: { ...payload.widgetRank },
        message: payload.message,
      };

    case FAILURE_WIDGET_RANK:
      return payload;

    default:
      return state;
  }
};
export default WidgetRankReducer;
