import { useState } from 'react';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import {
  IconCross,
  IconEdit,
  IconAction,
} from '../../../components/public/Icons';
import MarketPlaceService from '../../../services/marketplace.service';
import useAction from '../../../components/hooks/useAction';
import axios from 'axios';
import config from '../../../../config';
import { CustomTooltip } from '../../../components/ui/CustomTooltip';

const marketplaceService = new MarketPlaceService();

type DataMarketActionDropdownProps = {
  sourceId?: string;
  deleteSuccess?: () => void;
  modelType?: string;
  // eslint-disable-next-line
  updateSourceId: (sourceId: string) => void;
  toggleDataItemModal?: () => void;
  isSystemDefined?: boolean;
};

const DataMarketActionDropdown = ({
  sourceId,
  deleteSuccess,
  updateSourceId,
  toggleDataItemModal,
  modelType,
  isSystemDefined,
}: DataMarketActionDropdownProps) => {
  const { showModal } = useAction();
  const [dropdownOpen, setDropDownOpen] = useState(false);
  const toggleDropDown = () => {
    setDropDownOpen(state => !state);
  };

  const removeFoundationSource = async () => {
    try {
      const payload = {
        model_id: sourceId,
      };
      await axios({
        method: 'post',
        url: `${config.ER_ML_URL}/llm/remove`,
        data: payload,
      });
      deleteSuccess?.();
      showModal('Deleted Successfully!', true);
    } catch (error) {
      showModal('something went wrong');
    }
  };

  const removeSource = () => {
    marketplaceService
      .deleteSourceById(sourceId as string)
      .then(() => {
        deleteSuccess?.();
      })
      .catch(err => {
        showModal(err?.response?.data?.message);
      });
  };

  return (
    <>
      <Dropdown
        className="add-dropdown"
        isOpen={dropdownOpen}
        toggle={toggleDropDown}
        id={`tooltip-${sourceId}`}
      >
        {isSystemDefined && (
          <CustomTooltip
            placement="top"
            target={`tooltip-${sourceId}`}
            content={'System defined endpoints can not be edit or removed'}
          />
        )}

        <DropdownToggle caret direction="end" className="btn-icon">
          <IconAction />
        </DropdownToggle>
        {/* container="body" */}
        {!isSystemDefined && (
          <DropdownMenu>
            <DropdownItem
              onClick={() => {
                updateSourceId(sourceId as string);
                toggleDataItemModal?.();
              }}
            >
              <IconEdit />
              Edit
            </DropdownItem>
            <DropdownItem
              onClick={() => {
                if (modelType === 'FOUNDATIONAL_MODELS') {
                  removeFoundationSource();
                } else {
                  removeSource();
                }
              }}
            >
              <IconCross />
              Remove
            </DropdownItem>
          </DropdownMenu>
        )}
      </Dropdown>

      {/* {dataItemModal && (
        <AddNewDataItemModal
          modal={dataItemModal}
          toggle={toggleDataItemModal}
        />
      )} */}
    </>
  );
};

export default DataMarketActionDropdown;
