import ApiUtil from '../../utils/apiUtil';
import {
  AvailableLicenseResponse,
  BillingPayload,
  BillingResponse,
  CreateDepartmentPayload,
  CreateDepartmentResponse,
  DepartmentResponse,
  InviteResponse,
  OptionType,
  OptionTypeResponse,
  OrganizationLicenseResponse,
  OrganizationResponse,
  StatisticsData,
  UpdateOrganizationPayload,
  UpdateOrganizationResponse,
  UpdateUserPermissionPayload,
  UpdateUserPermissionResponse,
  UpdateUserSharingPermissionPayload,
  UpdateUserSharingPermissionResponse,
  UserByIdPayload,
  UserByIdResponse,
  UserDetailsPayload,
  UserDetailsResponse,
  UserPermissionsResponse,
  UserSharingPermissionsResponse,
} from '../../@types/SettingsType';
import { InviteUser } from '../redux/constant/setting.constant';

interface ApiPayload {
  page: number;
  limit: number;
  organizationId: string;
  departmentId?: string;
  roleId?: string;
  q?: string;
  userRole?: string;
}

export default class SettingsService {
  async getOrganization(id: string): Promise<OrganizationResponse> {
    const url = `/organization/${id}`;
    const res = await ApiUtil.SendAsync<OrganizationResponse>({
      url: url,
      method: 'GET',
    });
    return res.data;
  }

  async updateOrganization(
    payload: UpdateOrganizationPayload,
  ): Promise<UpdateOrganizationResponse> {
    const url = `/organization`;
    const res = await ApiUtil.SendAsync<UpdateOrganizationResponse>({
      url: url,
      method: 'PUT',
      data: payload,
    });
    return res.data;
  }

  async getAllDepartments(id: string): Promise<DepartmentResponse> {
    const url = `/department/${id}`;
    const res = await ApiUtil.SendAsync<DepartmentResponse>({
      url: url,
      method: 'GET',
    });
    return res.data;
  }

  async createDepartment(
    payload: CreateDepartmentPayload,
  ): Promise<CreateDepartmentResponse> {
    const url = `/department/`;
    const res = await ApiUtil.SendAsync<CreateDepartmentResponse>({
      url: url,
      method: 'POST',
      data: payload,
    });
    return res.data;
  }

  async updateDepartment(
    payload: CreateDepartmentPayload,
  ): Promise<CreateDepartmentResponse> {
    const url = `/department/`;
    const res = await ApiUtil.SendAsync<CreateDepartmentResponse>({
      url: url,
      method: 'PUT',
      data: payload,
    });
    return res.data;
  }

  async removeDepartment(id: string): Promise<CreateDepartmentResponse> {
    const url = `/department/${id}`;
    const res = await ApiUtil.SendAsync<CreateDepartmentResponse>({
      url: url,
      method: 'DELETE',
    });
    return res.data;
  }

  // Users Tab

  async getAllUsers(payload: ApiPayload): Promise<any> {
    let url = `/settings?page=${payload.page}&limit=${payload.limit}&organizationId=${payload.organizationId}`;

    if (payload.departmentId) {
      url += `&departmentId=${payload.departmentId}`;
    }

    if (payload.userRole) {
      url += `&userRole=${payload.userRole}`;
    }

    if (payload.q) {
      url += `&q=${payload.q}`;
    }
    const res = await ApiUtil.SendAsync<DepartmentResponse>({
      url: url,
      method: 'GET',
    });
    return res.data;
  }

  async getUserById(payload: UserByIdPayload): Promise<UserByIdResponse> {
    const url = `/settings/userDetail/${payload.userId}/${payload.organizationId}`;
    const res = await ApiUtil.SendAsync<UserByIdResponse>({
      url: url,
      method: 'GET',
    });
    return res.data;
  }

  // Remove User By Id
  async removeUserById(id: string): Promise<OptionTypeResponse> {
    const url = `/settings/${id}`;
    const res = await ApiUtil.SendAsync<OptionTypeResponse>({
      url: url,
      method: 'DELETE',
    });
    return res.data;
  }

  // Invite User
  async inviteUser(payload: InviteUser): Promise<InviteResponse> {
    const url = `/settings`;
    const res = await ApiUtil.SendAsync<InviteResponse>({
      url: url,
      method: 'POST',
      data: payload,
    });
    return res.data;
  }

  // Update Options Users based

  async updateOptions(payload: OptionType): Promise<OptionTypeResponse> {
    const url = `/settings/`;
    const res = await ApiUtil.SendAsync<OptionTypeResponse>({
      url: url,
      method: 'PUT',
      data: payload,
    });
    return res.data;
  }

  // Statistics

  async getStatisticsUsers(): Promise<DepartmentResponse> {
    const url = `/settings/listUsers`;
    const res = await ApiUtil.SendAsync<DepartmentResponse>({
      url: url,
      method: 'GET',
    });
    return res.data;
  }

  async getStatisticsData(
    payload?: StatisticsData,
  ): Promise<CreateDepartmentResponse> {
    const url = `/settings/userStatistics`;
    const res = await ApiUtil.SendAsync<CreateDepartmentResponse>({
      url: url,
      method: 'POST',
      data: payload,
    });
    return res.data;
  }

  //License and Billing
  async getOrganizationLicenses(): Promise<OrganizationLicenseResponse> {
    const url = `/license/`;
    const res = await ApiUtil.SendAsync<OrganizationLicenseResponse>({
      url: url,
      method: 'GET',
    });
    return res.data;
  }

  async getAvailableLicense(): Promise<any> {
    const url = `/license/yetToAvailable/`;
    const res = await ApiUtil.SendAsync<OrganizationLicenseResponse>({
      url: url,
      method: 'GET',
    });
    return res.data;
  }

  async updateLicenses(payload: {
    licensesObj: AvailableLicenseResponse[];
  }): Promise<BillingResponse> {
    const url = `/license/`;
    const res = await ApiUtil.SendAsync<BillingResponse>({
      url: url,
      method: 'PUT',
      data: payload,
    });
    return res.data;
  }

  async updateBillingAddress(
    payload: BillingPayload,
  ): Promise<BillingResponse> {
    const url = `/license/updateAddress`;
    const res = await ApiUtil.SendAsync<BillingResponse>({
      url: url,
      method: 'PUT',
      data: payload,
    });
    return res.data;
  }

  async updateUserDetails(
    payload: UserDetailsPayload,
  ): Promise<UserDetailsResponse> {
    const url = `/settings/userDetail`;
    const res = await ApiUtil.SendAsync<UserDetailsResponse>({
      url: url,
      method: 'PUT',
      data: payload,
    });
    return res.data;
  }
  async getUserPermissions(
    id: string,
    userId: string,
  ): Promise<UserPermissionsResponse> {
    const url = `/settings/userDetail/permissions/${id}/${userId}`;
    const res = await ApiUtil.SendAsync<UserPermissionsResponse>({
      url: url,
      method: 'GET',
    });
    return res.data;
  }
  async updateUserPermissions(
    payload: UpdateUserPermissionPayload,
    licenseId: string,
  ): Promise<UpdateUserPermissionResponse> {
    const url = `/settings/userDetail/permissions/${licenseId}`;
    const res = await ApiUtil.SendAsync<UpdateUserPermissionResponse>({
      url: url,
      method: 'PUT',
      data: payload,
    });
    return res.data;
  }

  async getUserSharingPermissions(
    userId: string,
  ): Promise<UserSharingPermissionsResponse> {
    const url = `/settings/sharing/${userId}`;
    // const customUrl = `http://127.0.0.1:3001/api/settings/sharing/${userId}`
    const res = await ApiUtil.SendAsync<UserSharingPermissionsResponse>({
      url: url,
      method: 'GET',
    });
    return res.data;
  }

  async updateUserSharingPermissions(
    payload: UpdateUserSharingPermissionPayload,
    userId: string,
  ): Promise<UpdateUserSharingPermissionResponse> {
    const url = `/settings/sharing/${userId}`;
    const res = await ApiUtil.SendAsync<UpdateUserSharingPermissionResponse>({
      url: url,
      method: 'PUT',
      data: payload,
    });
    return res.data;
  }
}
