import { Collapse } from 'reactstrap';

import { useState } from 'react';
import {
  IconDataSource,
  IconInfo,
  IconMinusCircle,
  IconPlusCircle,
  IconStar,
} from '../../../../../../components/public/Icons';
import { CustomTooltip } from '../../../../../../components/ui/CustomTooltip';

const AugumentationDataSourcesListing = () => {
  const [isCollapseOpen, setIsCollapseOpen] = useState(false);
  const toggleCollapse = () => setIsCollapseOpen(!isCollapseOpen);

  const AUGMENTATION_DATA = [
    {
      name: 'Morningstar',
      star: true,
    },
    {
      name: 'Equifax',
      star: true,
    },
  ];

  // SELECT AUGMENTATION Data Source(S)
  const AugmentationData: any = [];
  AUGMENTATION_DATA.forEach((item, index) => {
    AugmentationData.push(
      <div className="col" key={index}>
        <input type="checkbox" className="" name="select-file" />
        <div className="widget-card">
          <div className="flexbox-c gap-6">
            <IconDataSource />
            <p>{item.name}</p>
          </div>
        </div>
      </div>,
    );
  });

  return (
    <>
      <div className="addwidget-content mw-100">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-6">
            <div className="d-flex align-items-center">
              <p className="widget-category me-1 mb-0">
                SELECT AUGMENTATION Data Source(S)
              </p>
              <span className="d-flex" id="tooltip12">
                <IconInfo />
              </span>
              <CustomTooltip placement="top" target="tooltip12" />
            </div>
            <div className="create-widget create-widget-portfolio">
              <div className="widget-item">
                <div className="row row-cols-1">
                  {AugmentationData}
                  <div className="col">
                    <input
                      type="checkbox"
                      name=""
                      onClick={toggleCollapse}
                      className="showonly"
                    />
                    <div className="widget-card">
                      <div className="flexbox-c gap-6">
                        <button className="btn-clear btn-icon btn-16">
                          {isCollapseOpen ? (
                            <IconMinusCircle />
                          ) : (
                            <IconPlusCircle />
                          )}
                        </button>
                        <p>Equifax</p>
                        <span className="d-flex zindex-1" id="tooltip103">
                          <IconStar />
                        </span>
                        <CustomTooltip placement="top" target="tooltip103" />
                      </div>
                    </div>
                    <Collapse isOpen={isCollapseOpen}>
                      <div className="collapse-item">
                        <input type="checkbox" name="sub-options" />
                        <div className="widget-card">
                          <div className="flexbox-c gap-6">
                            <p>Equifax</p>
                          </div>
                        </div>
                      </div>
                      <div className="collapse-item">
                        <input type="checkbox" name="sub-options" />
                        <div className="widget-card">
                          <div className="flexbox-c gap-6">
                            <p>Equifax</p>
                            <span className="d-flex zindex-1" id="tooltip1020">
                              <IconStar />
                            </span>
                            <CustomTooltip
                              placement="top"
                              target="tooltip1020"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="collapse-item">
                        <input type="checkbox" name="sub-options" />
                        <div className="widget-card">
                          <div className="flexbox-c gap-6">
                            <p>Equifax</p>
                          </div>
                        </div>
                      </div>
                    </Collapse>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AugumentationDataSourcesListing;
