import {
  /* useEffect, */
  useRef /* useState */,
  useState,
} from 'react';
import Plot from 'react-plotly.js';
import {
  getLayoutConfig,
  getXAxisConfig,
  getYAxisConfig,
} from '../../chartsTheme';
import '../styles.css';
import { leftArrow, rangeSlider, rightArrow } from '../../utils/modeBarIcons';

const BarChart = ({
  chartData,
  // chartTitle,
  initialXAxis,
  initialYAxis,
}: {
  chartData: any;
  chartTitle: string;
  initialXAxis: string;
  initialYAxis: string;
}) => {
  const [updatedData, setUpdatedData] = useState(chartData);
  const [isRangeSliderVisible, setIsRangeSliderVisible] = useState(false);
  const layoutConfig = getLayoutConfig();

  const plotRef = useRef<any>(null);

  const resizeLayout = () => {
    plotRef.current.resizeHandler();
  };

  const isXAxisString = typeof chartData[0].x[0] === 'string';
  const isYAxisString = typeof chartData[0].y[0] === 'string';

  const chartType = isYAxisString ? 'bar' : isXAxisString ? 'bar' : 'bar';

  const handleSort = (ascending: boolean) => {
    const sortedData = [...updatedData].map(trace => {
      const sortedIndices = Array.from(
        { length: trace.x.length },
        (_, i) => i,
      ).sort((a, b) => {
        const keyA = isXAxisString ? trace.y[a] : trace.x[a];
        const keyB = isXAxisString ? trace.y[b] : trace.x[b];
        return ascending ? keyA - keyB : keyB - keyA;
      });

      const sortedX = sortedIndices.map(i => trace.x[i]);
      const sortedY = sortedIndices.map(i => trace.y[i]);

      return {
        ...trace,
        x: sortedX,
        y: sortedY,
      };
    });

    setUpdatedData(sortedData);
  };

  const handleSortAscending = () => {
    handleSort(true);
  };

  const handleSortDescending = () => {
    handleSort(false);
  };

  const toggleRangeSlider = () => {
    setIsRangeSliderVisible(prev => !prev);
  };

  const customButtons = [
    {
      name: 'Sort Ascending',
      icon: leftArrow,
      title: 'Sort Data Ascending',
      click: handleSortAscending,
    },
    {
      name: 'Sort Descending',
      icon: rightArrow,
      title: 'Sort Data Descending',
      click: handleSortDescending,
    },
  ];

  if (isXAxisString) {
    customButtons.push({
      name: 'Toggle Range Slider',
      icon: rangeSlider,
      title: 'Toggle Range Slider',
      click: toggleRangeSlider,
    });
  }

  return (
    <>
      {
        <div className="chart-wrapper" onMouseDown={e => e.stopPropagation()}>
          <Plot
            // data={updatedData}
            ref={plotRef}
            data={[
              {
                ...updatedData[0],
                type: chartType,
                orientation: isXAxisString ? 'v' : 'h',
              },
            ]}
            config={{
              scrollZoom: true,
              displaylogo: false,
              editable: true,
              responsive: true,
              modeBarButtonsToAdd: customButtons,
            }}
            layout={{
              title: '',
              bargap: 0.3,
              yaxis: getYAxisConfig(initialYAxis),
              ...layoutConfig,
              xaxis: {
                ...getXAxisConfig(initialXAxis),
                rangeslider: !isXAxisString
                  ? undefined
                  : { visible: isRangeSliderVisible },
              },
            }}
            useResizeHandler={true}
            onRelayout={resizeLayout}
          />
        </div>
      }
    </>
  );
};

export default BarChart;
