import { marketplaceTabConstant } from '../constant';

const { ADD_MARKETPLACE_TAB } = marketplaceTabConstant;

const initialState: { marketplaceTab: string } = {
  marketplaceTab: '1',
};

const MarketplaceTabReducer = (state = initialState, action: any): any => {
  switch (action.type) {
    case ADD_MARKETPLACE_TAB:
      return {
        marketplaceTab: action.payload.marketplaceTab,
      };

    default:
      return state;
  }
};
export default MarketplaceTabReducer;
