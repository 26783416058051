import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { IconCross } from '../../../../components/public/Icons';
import CustomSelect from '../../../../components/ui/CustomSelect';
import { useState } from 'react';

type ExportWidgeTDataProps = {
  modal: boolean;
  toggle: () => void;
  // eslint-disable-next-line
  handleExport?: (formatType: string) => void;
};

const ExportWidgeTData = ({
  modal,
  toggle,
  handleExport,
}: ExportWidgeTDataProps) => {
  const options = [
    { label: 'CSV', value: 'csv' },
    { label: 'XLSX', value: 'xlsx' },
  ];

  const [selectedFormat, setSelectedFormat] = useState({
    label: 'CSV',
    value: 'csv',
  });

  const closeBtn = (
    <button className="close" onClick={toggle} type="button">
      <IconCross fill="#36567D" />
    </button>
  );

  return (
    <Modal isOpen={modal} toggle={toggle} centered size="sm">
      <ModalHeader toggle={toggle} close={closeBtn}>
        Export WidgeT Data
      </ModalHeader>
      <ModalBody>
        <div className="form-group mb-0 select-scroll-sm">
          <label className="form-label">Select Export Format</label>
          <CustomSelect
            options={options}
            placeholder="Select"
            value={selectedFormat}
            onChange={(val: { label: string; value: string }) => {
              setSelectedFormat(val);
            }}
          />
        </div>
      </ModalBody>
      <ModalFooter className="justify-content-end">
        <button className="btn btn-outline-info" onClick={toggle}>
          Cancel
        </button>
        <button
          className="btn btn-primary"
          onClick={() => {
            handleExport?.(selectedFormat.value);
            toggle();
          }}
        >
          Export
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default ExportWidgeTData;
