import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import useAction from '../../../../components/hooks/useAction';

import {
  IconChatbot,
  Iconclose,
  IconCross,
  IconFlag,
  IconFullScreen,
  IconSort,
  IconTick,
} from '../../../../components/public/Icons';
import {
  callTelemetryRecord,
  // colorOptions,
} from '../../../../helpers/utils.helper';
import { widgetRankData } from '../../../../redux/selector/WidgetRankSelector';
import PublicChat from '../../../../components/public/PublicChat';
import SendWidgetAsTask from '../../newsfeed/widgets/SendWidgetAsTask';
import ShareWidgetExternally from '../../newsfeed/widgets/ShareWidgetExternally';
import ExportWidgeTData from '../../newsfeed/widgets/ExportWidgeTData';
import RemoveWidget from '../../newsfeed/widgets/RemoveWidget';
import WidgetMenuDropdown from '../../../../components/ui/WidgetMenuDropdown';
import { dashboardPerformancePortfolioWidgetData } from '../../../../redux/selector/DashboardWidgetSelector';
import PortfolioService from '../../../../services/portfolio.service';
import { isLoading } from '../../../../redux/selector/LoaderSelector';
import AppLoader from '../../../../components/loaders/AppLoader';
import { get } from 'lodash';
// import { widgetReferenceIdData } from '../../../../redux/selector/WidgetReferenceIdSelector';

const portfolioService = new PortfolioService();

export default function MyPortfolioPerformanceWidget({
  performanceIndex,
  onClose,
  widgetFullScreen,
  setWidgetFullScreen,
}: any) {
  const widRank = useSelector(widgetRankData);

  // fullscreen
  const [isChatOpen, setIsChatOpen] = React.useState(false);

  const [allConfigResponse, setAllConfigResponse] = useState([] as any);
  const [headersResponse, setHeadersResponse] = useState([] as any);
  const [individualConfigResponse, setIndividualResponse] = useState([] as any);

  const [widgetName, setWidgetName] = React.useState(
    'My Portfolio Performance Widget',
  );
  const [isWidgetNameChanged, setIsWidgetNameChanged] = React.useState(false);

  const loader = useSelector(isLoading);
  // const widgetReferenceIdValue = useSelector(widgetReferenceIdData);
  // const [currentPage, setCurrentPage] = React.useState(1);
  // const [totalRecord, setTotalRecord] = React.useState(100);

  const toggleWidgetFullScreen = (type?: string) => {
    document.body.classList.toggle('widget-fullscreen');
    setWidgetFullScreen((widgetFullScreen: any) => !widgetFullScreen);
    setIsChatOpen(false);

    // fullscreen widget go to top on fullscreen click
    const pagecontent = document.querySelector('.page-content');
    if (pagecontent) {
      pagecontent.scroll({ top: 0 });
    }

    if (type === 'add') {
      document.body.classList.add(`widget-${performanceIndex}`);
      document.body.classList.add('widget-fullscreen');

      const addElement = document.getElementById(
        `fs-performance-${performanceIndex}`,
      );
      if (addElement) {
        const parentElement = addElement.closest('.react-grid-item');
        if (parentElement) {
          setTimeout(() => {
            //
            parentElement.classList.add('widget-active');
          }, 10);
        }
      }
    } else if (type === 'remove') {
      document.body.classList.remove(`widget-${performanceIndex}`);
      document.body.classList.remove('widget-fullscreen');

      const removeElement = document.getElementById(
        `fsrem-performance-${performanceIndex}`,
      );
      if (removeElement) {
        const parentElement = removeElement.closest('.react-grid-item');
        if (parentElement) {
          parentElement.classList.remove('widget-active');
        }
      }
    }
  };

  const {
    addWidgetType,
    removeDashboardPerformancePortfolioData,
    addDashboardPerformancePortfolioData,
    addWidgetReferenceId,
    showLoading,
    hideLoading,
  } = useAction();

  const toogleFilterModal = () => {
    // document.body.classList.toggle('has-overlay');
    document.body.classList.add('show-widget-chat');
  };

  const [dropdownOpen, setDropDownOpen] = React.useState(false);
  const toggleDropDown = () => {
    setDropDownOpen((state) => !state);
  };

  //open modal - Widget As Task
  const [sendWidgetAsTaskModal, setSendWidgetAsTaskModal] =
    React.useState(false);
  const portfolioData = useSelector(dashboardPerformancePortfolioWidgetData);
  const toggleSendWidgetAsTaskModal = () =>
    setSendWidgetAsTaskModal(!sendWidgetAsTaskModal);

  //open modal - Share Externally
  const [shareExternallyModal, setShareExternallyModal] = React.useState(false);
  const toggleShareExternallyModal = () =>
    setShareExternallyModal(!shareExternallyModal);

  //open modal - Export WidgeT Data
  const [exportWidgeTDataModal, setExportWidgeTDataModal] =
    React.useState(false);
  const toggleExportWidgeTDataModal = () =>
    setExportWidgeTDataModal(!exportWidgeTDataModal);

  //remove modal - remove widget
  const [removeWidgetModal, setRemoveWidget] = React.useState(false);

  const toggleRemoveWidgetModal = () => {
    setRemoveWidget(!removeWidgetModal);
  };

  const removePerformanceWidget = () => {
    onClose();
    if (widgetFullScreen) {
      toggleWidgetFullScreen('remove');
    }

    removeDashboardPerformancePortfolioData(performanceIndex);
    if (
      widRank &&
      widRank.widgetRank &&
      widRank?.widgetRank?.slots?.length > 0
    ) {
      callTelemetryRecord(
        'widgets',
        widRank?.widgetRank?.event_id,
        widRank?.widgetRank?.slots[0]?.id,
        ['click'],
      );
    }
  };

  const [widgetCurrentActiveTab, setWidgetCurrentActiveTab] =
    React.useState('WidgetData');

  const toggle = (tab: React.SetStateAction<string>) => {
    if (widgetCurrentActiveTab !== tab) setWidgetCurrentActiveTab(tab);
  };

  const { showModal } = useAction();

  async function getPortfolioDataHeaders() {
    showLoading();
    if (portfolioData?.[performanceIndex]?.header_id) {
      const payload = {
        id: portfolioData?.[performanceIndex]?.header_id,
        portfolio_ids: portfolioData?.[performanceIndex]?.selectedPortfolio,
      };
      await portfolioService
        .getPerformanceWidgetPortfolio(payload)
        .then(({ response }) => {
          let tempAllResposne = [];
          let tempHeadersResponse = [];
          let tempIndividualResponse = [];

          tempAllResposne = response?.map((val: any) => {
            return {
              key: val?.headers?.label,
              value: val?.all_configurations_monitoring_response,
            };
          });

          tempHeadersResponse = response?.map((val: any) => {
            return {
              key: val?.headers?.label,
              value: val?.headers,
            };
          });

          tempIndividualResponse = response?.map((val: any) => {
            return {
              key: val?.headers?.label,
              value: val?.individual_configurations_monitoring_response,
            };
          });

          setHeadersResponse(tempHeadersResponse);
          setAllConfigResponse(tempAllResposne);
          setIndividualResponse(tempIndividualResponse);
          addDashboardPerformancePortfolioData({
            ...portfolioData,
            tempAllResposne: tempAllResposne,
            tempHeadersResponse: tempHeadersResponse,
            tempIndividualResponse: tempIndividualResponse,
          });

          hideLoading();
          // setTotalRecord(total_records);
          // showModal('Headers Saved', true);
        })
        .catch((err) => {
          showModal(err?.response?.data?.message);
          hideLoading();
        });
    }
  }

  useEffect(() => {
    if (
      portfolioData?.[performanceIndex]?.tempAllResposne?.length > 0 ||
      portfolioData?.[performanceIndex]?.tempHeadersResponse?.length > 0 ||
      portfolioData?.[performanceIndex]?.tempIndividualResponse?.length > 0
    ) {
      setHeadersResponse(
        portfolioData?.[performanceIndex]?.tempHeadersResponse,
      );
      setAllConfigResponse(portfolioData?.[performanceIndex]?.tempAllResposne);
      setIndividualResponse(
        portfolioData?.[performanceIndex]?.tempIndividualResponse,
      );
    } else {
      if (portfolioData?.[performanceIndex]?.header_id) {
        getPortfolioDataHeaders();
      }
    }
  }, []);

  React.useEffect(() => {
    setWidgetName(portfolioData[performanceIndex]?.customWidgetName);
  }, []);

  return (
    <>
      {loader?.isLoading && <AppLoader title={''} />}
      <div
        className={`common-widget`}
        onMouseEnter={() => {
          addWidgetReferenceId(performanceIndex);
        }}
      >
        <div className="common-widget-header cwh-tabs">
          {/* add class above line if tabs show in widget header `cwh-tabs` */}
          <div className="common-widget-header-title">
            <h2>
              {!isWidgetNameChanged ? (
                widgetName ? (
                  widgetName
                ) : (
                  'My Portfolio Performance Widget'
                )
              ) : (
                <div className="flexbox-c gap-12 no-drag">
                  <input
                    type="text"
                    placeholder="Enter new widget name"
                    className="form-control form-control-sm mw-260"
                    value={widgetName}
                    onChange={(e) => {
                      setWidgetName(e.target.value);
                    }}
                  />
                  <button
                    className="btn-clear flexbox-c gap-1 text-info"
                    onClick={() => {
                      setIsWidgetNameChanged(false);
                    }}
                  >
                    <IconCross fill="#36567D" />
                    Cancel
                  </button>
                  <button
                    className="btn-clear flexbox-c gap-1 text-primary"
                    onClick={() => {
                      setIsWidgetNameChanged(false);
                    }}
                  >
                    <IconTick />
                    Save
                  </button>
                </div>
              )}
            </h2>
            {widgetFullScreen && (
              <div className="ms-6 no-drag">
                {/* Create Component Dropdown  */}
                <WidgetMenuDropdown
                  dropdownOpen={dropdownOpen}
                  toggleDropDown={toggleDropDown}
                  toggleSendWidgetAsTaskModal={toggleSendWidgetAsTaskModal}
                  toggleShareExternallyModal={toggleShareExternallyModal}
                  toggleExportWidgeTDataModal={toggleExportWidgeTDataModal}
                  toggleRemoveWidgetModal={toggleRemoveWidgetModal}
                  setIsWidgetNameChanged={setIsWidgetNameChanged}
                />
              </div>
            )}

            {!widgetFullScreen && (
              <div className="d-flex aling-items-center ms-12 no-drag">
                <button
                  id={`fs-performance-${performanceIndex}`}
                  type="button"
                  className="btn-icon"
                  onClick={() => {
                    toggleWidgetFullScreen('add');
                    // addWidgetReferenceId(performanceIndex);
                    addWidgetType('PERFORMANCE');
                  }}
                >
                  <IconFullScreen />
                </button>
                <button
                  type="button"
                  className={isChatOpen ? 'icon-active btn-icon' : 'btn-icon'} // apply `icon-active` class for active widget chat
                  onClick={() => {
                    toogleFilterModal();
                    setIsChatOpen(!isChatOpen);
                    // addWidgetReferenceId(performanceIndex);
                    addWidgetType('PERFORMANCE');
                    if (
                      widRank &&
                      widRank.widgetRank &&
                      widRank?.widgetRank?.slots?.length > 0
                    ) {
                      callTelemetryRecord(
                        'widgets',
                        widRank?.widgetRank?.event_id,
                        widRank?.widgetRank?.slots[0]?.id,
                        ['chat'],
                      );
                    }
                  }}
                >
                  <IconChatbot index={'nplwidget'} />
                </button>
              </div>
            )}
            {/* flexbox */}
            {widgetFullScreen && (
              <div className="tabs-menu-line mb-0 ms-auto no-drag">
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: widgetCurrentActiveTab === 'WidgetData',
                      })}
                      onClick={() => {
                        toggle('WidgetData');
                      }}
                    >
                      Widget Data
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active:
                          widgetCurrentActiveTab === 'WidgetConfiguration',
                      })}
                      onClick={() => {
                        toggle('WidgetConfiguration');
                      }}
                    >
                      Widget Configuration
                    </NavLink>
                  </NavItem>
                </Nav>
              </div>
            )}
          </div>

          {widgetFullScreen && (
            <div className="common-widget-header-action no-drag">
              <button
                id={`fsrem-performance-${performanceIndex}`}
                type="button"
                className="btn-icon"
                onClick={() => {
                  toggleWidgetFullScreen('remove');
                  setWidgetCurrentActiveTab('WidgetData');
                }}
              >
                <Iconclose />
              </button>
            </div>
          )}

          {!widgetFullScreen && (
            <div className="common-widget-header-action no-drag">
              {/* Create Component Dropdown  */}
              <WidgetMenuDropdown
                dropdownOpen={dropdownOpen}
                toggleDropDown={toggleDropDown}
                toggleSendWidgetAsTaskModal={toggleSendWidgetAsTaskModal}
                toggleShareExternallyModal={toggleShareExternallyModal}
                toggleExportWidgeTDataModal={toggleExportWidgeTDataModal}
                toggleRemoveWidgetModal={toggleRemoveWidgetModal}
                setIsWidgetNameChanged={setIsWidgetNameChanged}
              />
            </div>
          )}
        </div>
        <div
          className="common-widget-content"
          onClick={() => {
            if (
              widRank &&
              widRank.widgetRank &&
              widRank?.widgetRank?.slots?.length > 0
            ) {
              callTelemetryRecord(
                'widgets',
                widRank?.widgetRank?.event_id,
                widRank?.widgetRank?.slots[0]?.id,
                ['click'],
              );
            }
          }}
        >
          {/* Widget Chat */}

          {/* Chat True Show*/}
          {isChatOpen && !widgetFullScreen && (
            <div className="widget-chat">
              <PublicChat />
            </div>
          )}

          {/* Chat True Hide */}
          {!isChatOpen && (
            <TabContent activeTab={widgetCurrentActiveTab}>
              <TabPane tabId="WidgetData">
                <div className="no-drag">
                  {headersResponse?.length > 0 && (
                    <div className="table-responsive">
                      <table
                        className="table table-bordered"
                        style={{
                          minWidth: `${(headersResponse?.length + 1) * 200}px`,
                        }}
                      >
                        <thead>
                          <tr>
                            <th
                              style={{ width: '120px' }}
                              scope="col"
                              key="portoflio-name-list"
                            >
                              Portfolio Name
                              <span className="sortable asc">
                                <IconSort />
                              </span>
                            </th>
                            {headersResponse
                              ?.map((hVal: any) => hVal?.key)
                              ?.map((hValue: any) => (
                                <th
                                  key={hValue}
                                  style={{ width: '120px' }}
                                  scope="col"
                                >
                                  {hValue}
                                </th>
                              ))}
                          </tr>
                        </thead>

                        <tbody>
                          {(() => {
                            // Create a map to store rows grouped by file_name
                            const groupedByFileName: {
                              [fileName: string]: any;
                            } = {};

                            // Loop through individualResponse to group data by file_name
                            individualConfigResponse.forEach((indVal: any) => {
                              // Extract the entries (dynamic keys and their associated values)
                              const portfolioEntries = Object.entries(
                                indVal.value,
                              );

                              // Loop through each entry
                              /* eslint-disable no-unused-vars */
                              portfolioEntries.forEach(
                                ([dynamicId, portfolioData]: [string, any]) => {
                                  const fileName =
                                    portfolioData?.file_name || 'Unknown File';
                                  if (!groupedByFileName[fileName]) {
                                    groupedByFileName[fileName] = {};
                                  }

                                  // For each key except 'file_name', map it under its group
                                  Object.keys(portfolioData).forEach(
                                    (dynamicKey) => {
                                      if (dynamicKey !== 'file_name') {
                                        groupedByFileName[fileName][
                                          dynamicKey
                                        ] = portfolioData[dynamicKey] || 0;
                                      }
                                    },
                                  );
                                },
                              );
                            });

                            // Now render the grouped data for portfolios
                            const rows = Object.keys(groupedByFileName).map(
                              (fileName) => {
                                const dynamicValues =
                                  groupedByFileName[fileName];
                                return (
                                  <tr key={fileName}>
                                    <td>{fileName}</td>

                                    {/* Render dynamic field values like AUCTIONDATE fullTotalSum, COUNTY fullTotalSum */}
                                    {headersResponse.map((header: any) => {
                                      const fieldValue =
                                        dynamicValues[header.key] || 0;
                                      const monitoringConfig =
                                        header.value?.monitoring;

                                      let flagToShow: any = null;
                                      let flagColorForCondition: string = '';
                                      if (
                                        header?.value?.monitoring_type !==
                                        'external_benchmark'
                                      ) {
                                        if (monitoringConfig) {
                                          monitoringConfig.forEach(
                                            (config: any) => {
                                              const {
                                                max_value,
                                                min_value,
                                                flag,
                                                flag_color,
                                              } =
                                                config.monitoring_configuration;

                                              // Check if the type is "Individual"
                                              if (
                                                config.type === 'Individual'
                                              ) {
                                                // Check if the field value is between the min and max values for "underrange" and "overrange"
                                                if (
                                                  flag === 'underrange' &&
                                                  fieldValue > min_value &&
                                                  fieldValue < max_value
                                                ) {
                                                  flagColorForCondition =
                                                    flag_color;
                                                  flagToShow = (
                                                    <IconFlag
                                                      fill={flag_color}
                                                    />
                                                  );
                                                } else if (
                                                  flag === 'overrange' &&
                                                  (fieldValue > max_value ||
                                                    fieldValue < min_value)
                                                ) {
                                                  flagColorForCondition =
                                                    flag_color;
                                                  flagToShow = (
                                                    <IconFlag
                                                      fill={flag_color}
                                                    />
                                                  );
                                                } else if (
                                                  flag === 'underrange' &&
                                                  fieldValue >=
                                                    min_value * 0.9 &&
                                                  fieldValue <= min_value // 10% below min
                                                ) {
                                                  flagColorForCondition =
                                                    'yellow';
                                                  flagToShow = (
                                                    <IconFlag fill="yellow" />
                                                  ); // Default yellow flag for underrange
                                                } else if (
                                                  flag === 'underrange' &&
                                                  fieldValue <=
                                                    max_value * 1.1 &&
                                                  fieldValue >= max_value // 10% above max
                                                ) {
                                                  flagColorForCondition =
                                                    'yellow';
                                                  flagToShow = (
                                                    <IconFlag fill="yellow" />
                                                  ); // Default yellow flag for underrange
                                                }
                                              }
                                            },
                                          );
                                        }
                                      }
                                      if (
                                        header?.value?.monitoring_type ===
                                          'external_benchmark' &&
                                        dynamicValues[
                                          'new_flag_needs_to_display'
                                        ]
                                      ) {
                                        flagColorForCondition =
                                          dynamicValues[
                                            'new_flag_needs_to_display'
                                          ];
                                      }

                                      return (
                                        <td
                                          key={header.key}
                                          className={`col-highlight ${
                                            flagColorForCondition ===
                                              'yellow' && 'col-h-warning'
                                          } ${
                                            flagColorForCondition === 'red' &&
                                            'col-h-danger'
                                          } ${
                                            flagColorForCondition === 'green' &&
                                            'col-h-success'
                                          }`}
                                        >
                                          {flagToShow} {fieldValue}
                                        </td>
                                      );
                                    })}
                                  </tr>
                                );
                              },
                            );

                            // Render the total row based on allConfigResponse
                            const totalRow = (
                              <tr key="total">
                                <td>Total</td>
                                {headersResponse.map((header: any) => {
                                  // Find the total value for the current header key
                                  const totalConfig = allConfigResponse.find(
                                    (allConfig: any) =>
                                      allConfig.key === header.key,
                                  );

                                  const totalValue =
                                    totalConfig?.value[header.key] || 'N/A';

                                  // Get monitoring configurations for the current header
                                  const monitoringConfig =
                                    header.value?.monitoring;

                                  // Determine if a flag should be shown based on monitoring_config with type "all"
                                  let flagToShow: any = null;
                                  let flagColorForCondition: string = '';
                                  if (
                                    header?.value?.monitoring_type !==
                                    'external_benchmark'
                                  ) {
                                    if (monitoringConfig) {
                                      monitoringConfig.forEach(
                                        (config: any) => {
                                          const {
                                            max_value,
                                            min_value,
                                            flag,
                                            flag_color,
                                          } = config.monitoring_configuration;

                                          // Check if the type is "all"
                                          if (config.type === 'all') {
                                            // Check if the totalValue is between the min and max values for "underrange" and "overrange"
                                            if (
                                              flag === 'underrange' &&
                                              totalValue > min_value &&
                                              totalValue < max_value
                                            ) {
                                              flagColorForCondition =
                                                flag_color;
                                              flagToShow = (
                                                <IconFlag fill={flag_color} />
                                              );
                                            } else if (
                                              flag === 'overrange' &&
                                              (totalValue > max_value ||
                                                totalValue < min_value)
                                            ) {
                                              flagColorForCondition =
                                                flag_color;
                                              flagToShow = (
                                                <IconFlag fill={flag_color} />
                                              );
                                            } else if (
                                              flag === 'underrange' &&
                                              totalValue >= min_value * 0.9 &&
                                              totalValue <= min_value // 10% below min
                                            ) {
                                              flagColorForCondition = 'yellow';
                                              flagToShow = (
                                                <IconFlag fill="yellow" />
                                              ); // Default yellow flag for underrange
                                            } else if (
                                              flag === 'underrange' &&
                                              totalValue <= max_value * 1.1 &&
                                              totalValue >= max_value // 10% above max
                                            ) {
                                              flagColorForCondition = 'yellow';
                                              flagToShow = (
                                                <IconFlag fill="yellow" />
                                              ); // Default yellow flag for underrange
                                            }
                                          }
                                        },
                                      );
                                    }
                                  }

                                  if (
                                    header?.value?.monitoring_type ===
                                      'external_benchmark' &&
                                    get(
                                      totalConfig,
                                      'value.new_flag_needs_to_display',
                                    )
                                  ) {
                                    flagColorForCondition =
                                      totalConfig?.value[
                                        'new_flag_needs_to_display'
                                      ];
                                  }

                                  return (
                                    <td
                                      key={header.key}
                                      className={`col-highlight ${
                                        flagColorForCondition === 'yellow' &&
                                        'col-h-warning'
                                      } ${
                                        flagColorForCondition === 'red' &&
                                        'col-h-danger'
                                      } ${
                                        flagColorForCondition === 'green' &&
                                        'col-h-success'
                                      }`}
                                    >
                                      {flagToShow}
                                      {totalValue}
                                    </td>
                                  );
                                })}
                              </tr>
                            );

                            return [...rows, totalRow];
                          })()}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </TabPane>
              <TabPane tabId="WidgetConfiguration">
                <div className="widget-tabs-content no-drag">
                  <div className="addwidget-wrapper">
                    <div className="container container-1024">
                      <div className="addwidget-header">
                        <h4 className="title-20 text-center">
                          Customize monitoring configuration
                        </h4>
                      </div>
                      <div className="addwidget-content mw-100 edit-widget">
                        <h2 className="text-center">Table Component</h2>
                      </div>
                      <div className="gap-12 d-flex justify-content-center">
                        <button className="btn btn-primary" disabled>
                          Save changes
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPane>
            </TabContent>
          )}
        </div>

        {sendWidgetAsTaskModal && (
          <SendWidgetAsTask
            modal={sendWidgetAsTaskModal}
            toggle={toggleSendWidgetAsTaskModal}
          />
        )}

        {shareExternallyModal && (
          <ShareWidgetExternally
            modal={shareExternallyModal}
            toggle={toggleShareExternallyModal}
          />
        )}

        {exportWidgeTDataModal && (
          <ExportWidgeTData
            modal={exportWidgeTDataModal}
            toggle={toggleExportWidgeTDataModal}
          />
        )}

        {removeWidgetModal && (
          <RemoveWidget
            modal={removeWidgetModal}
            toggle={toggleRemoveWidgetModal}
            removeWidget={removePerformanceWidget}
          />
        )}
        {/* {showSessionModal && (
          <SessionModal
            modal={showSessionModal}
            toggle={toggleShowSessionModal}
            // fileKey={fileKey}
          />
        )} */}
      </div>
    </>
  );
}
