import classnames from 'classnames';
import React, { useEffect, useState, useRef, JSX } from 'react';
import { TabContent, TabPane } from 'reactstrap';
import { Iconclose } from '../../../../components/public/Icons';
import SelectDataSources from './creditWidgetWorkflow/SelectDataSources';
import SelectFiles from './creditWidgetWorkflow/SelectFiles';
import SelectWidgetType from './SelectWidgetType';
import MapPortfolioData from './creditWidgetWorkflow/MapPortfolioData';
import SelectPortfolios from './analyticsWidgetWorkflow/SelectPortfolios';
// import loaderGIF from '../../../../../assets/images/building-data-loader.gif';
import SelectTypeAndParameters from './analyticsWidgetWorkflow/SelectTypeAndParameters';
import SelectStatPortfolios from './statisticalWidgetWorkflow/SelectStatPortfolios';
import TypeChat from './statisticalWidgetWorkflow/TypeChat';
import AnalysisResult from './statisticalWidgetWorkflow/AnalysisResult';
import CreditWidgetHeading from './WidgetWorkflowHeadings/CreditWidgetHeading';
import AnalyticsWidgetHeading from './WidgetWorkflowHeadings/AnalyticsWidgetHeading';
import LoanWidgetHeading from './WidgetWorkflowHeadings/LoanWidgetHeading';
import ReceivablesWidgetHeading from './WidgetWorkflowHeadings/ReceivablesWidgetHeading';
import NplWidgetHeading from './WidgetWorkflowHeadings/NplWidgetHeading';
import useAction from '../../../../components/hooks/useAction';
// import { __LocalStorage } from '../../../../helpers/constants';
import { allPortfolioWidgetsSelector } from '../../../../redux/selector/PortfolioWidgetsSelector';
import { useSelector } from 'react-redux';
import axios from 'axios';
import config from '../../../../../config';
import {
  AvailableAliasTypes,
  ChartFormDataProps,
  HeaderSchema,
  NodeFileResponse,
} from '../../../../../@types/PortfolioType';
import AppLoader from '../../../../components/loaders/AppLoader';
import { CHARTS } from '../Charts/constants';
import { isAxiosError } from '../../../../redux/constant';
import { cloneDeep, isEmpty } from 'lodash';
import PortfolioService from '../../../../services/portfolio.service';
// import unidecode from 'unidecode';
// import { searchedChatQuery } from '../../../../redux/selector/ChatQuerySelector';
// import { widgetReferenceIdData } from '../../../../redux/selector/WidgetReferenceIdSelector';

type PortfolioTypes = {
  widgetIdOfIndex: number | null;
  isUpdate: boolean;
  widgetTypeToUpdate?: string;
  togglePortfolioWidget: () => void;
};
interface FieldData {
  type: string;
  actualFieldName: string;
  alias_suggestion_required: boolean;
  hidden?: boolean;
}
type ResponseObject = Record<string, FieldData>;

type DynamicObject = {
  [key: string]: number | string;
};

type RowPreviewDataTypes = {
  [key: string]: Array<{ [key: string]: string | number | null }>;
};
interface DynamicCSVStructure {
  [key: string]: {
    [column: string]: string;
  };
}

interface DataItem {
  key: string;
  value: { [key: string]: FieldData };
}

interface MinMax {
  min: number;
  max: number;
}

type DataStructure = {
  [key: string]: MinMax[]; // Keys are unknown (e.g., key1, key2, key3), each with an array of MinMax objects
};

interface ResponseItem {
  key: string;
  value: Record<string, any>;
}

type PayloadField = {
  actualFieldName: string;
  synonymsField: string;
  type: 'FINANCIAL' | 'COLLATERAL' | 'OTHER' | string;
};

type PortfoliosData = {
  key: string;
  value: {
    [key: string]: FieldData;
  };
};

type selectedDataSourceType = {
  id?: string;
  file: string;
  subFile: string;
  type: string;
};

const portfolioService = new PortfolioService();

const AddPortfolioWidget = ({
  togglePortfolioWidget,
  isUpdate,
  widgetIdOfIndex,
  widgetTypeToUpdate,
}: PortfolioTypes) => {
  // State for current active Tab
  const [isLoading, setIsLoading] = useState(false);
  // const [isAiLoading, setIsAiLoading] = useState(false);
  const [currentActiveTab, setCurrentActiveTab] = useState('1');
  const [selectedWidget, setSelectedWidget] = useState<string>('');
  const [customWidgetName, setCustomWidgetName] = useState('');

  const [selectedDataSourcesWidget, setSelectedDataSourcesWidget] = useState<
    selectedDataSourceType[]
  >([]);

  const [rowPreviewData, setRowPreviewData] = useState<RowPreviewDataTypes>({});

  const [allFileNames, setAllFileNames] = useState<{
    [key: string]: {
      key: string;
      file_name: string;
    };
  }>({});
  const [allPortfoliosListing, setAllPortfoliosListing] = useState<
    Array<{
      key: string;
      value: {
        key: string;
        file_name: string;
      };
    }>
  >([]);
  const [inputData, setInputData] = useState<DataStructure>({});
  const [statPortfolioList, setStatPortfolioList] = useState<
    Array<{ label: string; value: string }>
  >([]);

  const [
    selectedVisualisationPortfolio,
    setSelectedVisualisationPortfolio,
  ] = useState<string>('');
  const [chartFormValues, setChartFormValues] = useState<any>({});
  const allPortfolioWidgetReduxData = useSelector(allPortfolioWidgetsSelector);

  const [portfoliosData, setPortfoliosData] = useState<DataItem[]>([]);
  const [oldPortfoliosData, setOldPortfoliosData] = useState<DataItem[]>([]);
  const [selectedMergeType, setSelectedMergeType] = useState<{
    label: string;
    value: string;
  }>({ label: 'Union', value: 'union' });
  const [defaultFileMerge, setDefaultFileMerge] = useState('');
  const [joinKey, setJoinKey] = useState<{ [key: string]: string }>({});

  const [selectedPortfolio, setSelectedPortfolio] = useState<string[]>([]);

  const [selectedKnowledgeBaseFile, setSelectedKnowledgeBaseFile] = useState(
    [] as any,
  );
  const [insertedId, setInsertedId] = useState<Array<string>>([]);
  const [amplifyDataArr, setAmplifyDataArr] = useState<
    { fileName: string; id: string }[] | []
  >([]);

  const [nodeEndpointArr, setNodeEndpointArr] = useState<
    { fileName: string; id: string }[] | []
  >([]);
  const [nodeEndpointResponse, setNodeEndpointResponse] = useState<Array<
    NodeFileResponse
  > | null>(null);
  const [amplifyEndpointResponse, setAmplifyEndpointResponse] = useState<{
    [key: string]: string;
  }>({});
  const [availableAliasName, setAvailableAliasName] = useState<
    AvailableAliasTypes
  >({});
  // const [categoryData, setCategoryData] = useState<
  //   Array<{
  //     actualFieldName: string;
  //     synonymsField: string;
  //     type: string;
  //   }>
  // >([]);

  const {
    showModal,
    // refreshPortfolioPage,
    addPortfolioCreditWidgetData,
    addPortfolioNplWidgetData,
    addPortfolioLoanWidgetData,
    addPortfolioReceivableWidgetData,
    addPortfolioVisualizationWidgetData,
  } = useAction();

  //Statistical Widgets
  const [selectedPortfolioStat, setSelectedPortfolioStat] = useState<
    Array<string>
  >([]);
  const [isStatValueCaclculated, setIsStatValueCalculated] = useState(false);
  const [statQuery, setStatQuery] = useState('');
  const [statSelectedModel, setStatSelectedModel] = useState<{
    label: JSX.Element;
    value: string;
  } | null>(null);
  const [isStatLoading, setIsStatLoading] = useState(false);
  const [newFieldsArray, setNewFieldsArray] = useState<Array<string>>([]);

  const updateSelectedWidget = (val: string) => {
    setSelectedWidget(val);
  };

  const handleChartFormChange = (values: any) => {
    setChartFormValues(values);
  };

  // Fix for memory leak
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const buttonText = () => {
    if (selectedWidget === 'CREDIT' && currentActiveTab === '4') {
      return isUpdate ? 'Update Widget' : 'Create Widget';
    } else if (selectedWidget === 'ANALYTICS' && currentActiveTab === '3') {
      return 'Create Widget';
    } else if (
      (selectedWidget === 'LOAN' ||
        selectedWidget === 'RECEIVABLE' ||
        selectedWidget === 'NPL') &&
      currentActiveTab === '4'
    ) {
      return 'Create Widget';
    } else {
      return 'Continue';
    }
  };

  // Toggle active state for Tab
  const toggle = (tab: React.SetStateAction<string>) => {
    if (currentActiveTab !== tab) setCurrentActiveTab(tab);
  };

  const saveAmplifyDataEndpoints = (
    data: { fileName: string; id: string }[],
  ): Promise<void> => {
    // setIsAiLoading(true);
    const payload = {
      ids: data.map(x => x.id),
    };

    return axios
      .post(`${config.ER_ML_URL}/amplify/upload-product-data`, payload)
      .then(response => {
        // setIsAiLoading(false);
        setAmplifyEndpointResponse(response?.data?.Portfolio_id);
      })
      .catch(err => {
        // setIsAiLoading(false);
        if (isAxiosError(err)) {
          showModal(
            (err.response?.data as { error?: string })?.error ||
              'Unexpected Axios error',
          );
        } else {
          showModal('something went wrong');
        }
        // Re-throw the error so it can be caught in the Promise.all
        throw new Error(err);
      });
  };

  const saveNodeEndpointData = (
    data: { fileName: string; id: string }[],
  ): Promise<void> => {
    // setIsAiLoading(true);
    const payload = {
      serviceIds: data.map(x => x.id),
    };

    return portfolioService
      .saveNodeEndpoints(payload)
      .then(response => {
        setNodeEndpointResponse(response?.data);
        // setIsAiLoading(false);
      })
      .catch(err => {
        // setIsAiLoading(false);
        if (isAxiosError(err)) {
          showModal(
            (err.response?.data as { error?: string })?.error ||
              'Unexpected Axios error',
          );
        } else {
          showModal('something went wrong');
        }
        // Re-throw the error so it can be caught in the Promise.all chain
        throw err;
      });
  };

  const saveJsonDataKnowledgeBase = (
    summaryData: { fileName: string; jsonData: DynamicObject }[],
  ) => {
    setIsLoading(true);

    const payload = summaryData?.map(x => {
      return {
        file_name: x.fileName,
        data: x.jsonData,
      };
    });

    axios
      .post(`${config.ER_ML_URL}/file/save_info`, payload)
      .then(response => {
        const knowledgeFileInsertedId = response?.data?.portfolio_ids;
        // const idsInArray = new Set(summaryData.map(item => item.fileName));
        let updatedSummaryFiles = [...selectedDataSourcesWidget];

        // Process each item in summaryData
        summaryData.forEach(item => {
          const fileIndex = updatedSummaryFiles.findIndex(
            val => val.file === item.fileName,
          );

          if (fileIndex >= 0) {
            // File exists, remove it
            updatedSummaryFiles.splice(fileIndex, 1);
          } else {
            // File does not exist, add it
            updatedSummaryFiles.push({
              file: item.fileName,
              subFile: '',
              type: 'KNOWLEDGE_BASE',
            });
          }
        });

        setSelectedDataSourcesWidget(updatedSummaryFiles);

        // Handle inserted IDs
        setInsertedId(Object.values(knowledgeFileInsertedId));

        // Update UI state
        toggle(String(Number(currentActiveTab) + 1));
        setIsLoading(false);
      })
      .catch(err => {
        setIsLoading(false);
        if (isAxiosError(err)) {
          showModal(
            (err.response?.data as { error?: string })?.error ||
              'Unexpected Axios error',
          );
        } else {
          showModal('something went wrong');
        }
      });
  };

  const transformPortfoliosData = (
    data: PortfoliosData[],
    previousData: { key: string; value: any }[] = [], // Previous data with key and value
  ): { fields: PayloadField[] } => {
    const fields: PayloadField[] = data.flatMap(item =>
      Object.entries(item.value).flatMap(
        ([synonymsField, field]): PayloadField[] => {
          const {
            type,
            actualFieldName,
            // alias_suggestion_required
          } = field;

          // Find the corresponding entry in previousData by key
          const previousItem = previousData.find(
            prevItem => prevItem.key === item.key,
          );
          const previousField = previousItem?.value[synonymsField];

          // Check if the actualFieldName has changed
          const isChanged =
            previousField &&
            (previousField?.actualFieldName !== actualFieldName ||
              (type
                ? type !== previousField?.type
                : availableAliasName[synonymsField]?.field_type !==
                  previousField?.type));

          // Only add fields where the actualFieldName has changed
          if (isChanged) {
            return [
              {
                actualFieldName: actualFieldName
                  ? actualFieldName
                  : availableAliasName[synonymsField]?.alias_name ||
                    synonymsField,
                synonymsField,
                type: (type ||
                  availableAliasName[synonymsField]?.field_type) as
                  | 'FINANCIAL'
                  | 'COLLATERAL',
              },
            ];
          }

          // If no changes, return an empty array
          return [];
        },
      ),
    );

    return { fields };
  };

  const saveSynonymsData = () => {
    setIsLoading(true);
    const payload = transformPortfoliosData(portfoliosData, oldPortfoliosData);
    if (payload?.fields?.length > 0) {
      portfolioService
        .addSynonymsFields(payload)
        .then(() => {
          // setIsLoading(false);

          getHeaderDataOneByOne();
        })
        .catch(err => {
          setIsLoading(false);
          if (isAxiosError(err)) {
            showModal(
              (err.response?.data as { error?: string })?.error ||
                'Unexpected Axios error',
            );
          } else {
            showModal('something went wrong');
          }
        });
    } else {
      getHeaderDataOneByOne();
    }
  };

  const getChartsData = (
    id: string,
    payload: { fields: string[] },
    chartFormValues: ChartFormDataProps,
  ) => {
    setIsLoading(true);
    axios
      .post(`${config.ER_ML_URL}/visualization/get-average/${id}`, payload)
      .then(({ data }) => {
        if (!isMounted.current) return;
        let responseData;
        try {
          responseData = JSON.parse(data?.data);
        } catch (e) {
          responseData = data?.data;
        }

        if (responseData?.error) {
          showModal(responseData.error);
          setIsLoading(false);
          return;
        }

        const chartsConfiguration = {
          ...chartFormValues,
          portfolioId: selectedVisualisationPortfolio,
          chartId: Date.now(),
          data: responseData || [],
        };

        addPortfolioVisualizationWidgetData({
          id: allPortfolioWidgetReduxData?.length,
          value: 'embedded_browser',
          widgetType: 'PORTFOLIO',
          ...chartsConfiguration,
        });
        togglePortfolioWidget();
        document.body.classList.remove('show-dataitem');

        if (!isMounted.current) return;
        setIsLoading(false);
      })
      .catch(err => {
        if (!isMounted.current) return;
        setIsLoading(false);
        if (isAxiosError(err)) {
          showModal(
            (err.response?.data as { error?: string })?.error ||
              'Unexpected Axios error',
          );
        } else {
          showModal('something went wrong');
        }
      });
  };

  let nodeDataSourceArray =
    nodeEndpointResponse?.map(x => x.fileDetailsId) || [];

  let amplifyDataSourceArray = Object.values(amplifyEndpointResponse);
  let combinedArray = [
    ...insertedId,
    ...selectedPortfolio,
    ...nodeDataSourceArray,
    ...amplifyDataSourceArray,
  ];

  const transformDataForMerge = (
    data: Record<
      string,
      {
        type: string;
        actualFieldName: string;
        alias_suggestion_required: boolean;
      }
    >,
  ) => {
    const transformedData: Record<string, string> = {};

    for (const [key, value] of Object.entries(data)) {
      if (value.actualFieldName) {
        transformedData[key] = value.actualFieldName;
      } else {
        transformedData[key] = key;
      }
    }

    return transformedData;
  };

  const addHiddenProperty = (data: ResponseObject): ResponseObject => {
    return Object.entries(data).reduce((acc, [key, field]) => {
      acc[key] = { ...field, hidden: false }; // Initialize hidden as false
      return acc;
    }, {} as ResponseObject);
  };

  const existingKeysMap = new Map<string, any>(
    portfoliosData.map(item => [item.key, item.value]),
  );

  const fetchData = async (id: string): Promise<DynamicCSVStructure | null> => {
    if (existingKeysMap.has(id)) {
      // Return existing data if the key is present
      return {
        [allFileNames[id]?.key]: transformDataForMerge(existingKeysMap.get(id)),
      };
    } else {
      // Fetch data from API if the key is not present
      try {
        const response = await axios({
          method: 'get',
          url: `${config.ER_ML_URL}/portfolio/get-headers/${id}`,
        });

        const responseManipulation: HeaderSchema =
          response?.data?.header_schema;

        if (responseManipulation.isError && responseManipulation?.message) {
          showModal(responseManipulation?.message);
          return null;
        }

        const data = addHiddenProperty(responseManipulation?.data) || {};
        // Update portfoliosData with the new data
        portfoliosData.push({
          key: id,
          value: data,
        });

        return {
          [allFileNames[id]?.key]: transformDataForMerge(
            responseManipulation?.data,
          ),
        };
      } catch (error) {
        return null;
      }
    }
  };

  const checkForFinancialOrCollateral = (data: DataItem[]): boolean => {
    return data.every(item =>
      Object.values(item.value).every(
        field => field.type === 'FINANCIAL' || field.type === 'COLLATERAL',
      ),
    );
  };

  const getHeaderDataOneByOne = async () => {
    if (combinedArray?.length === 1) {
      if (checkForFinancialOrCollateral(portfoliosData)) {
        addPortfolioCreditWidgetData({
          id: isUpdate ? widgetIdOfIndex : allPortfolioWidgetReduxData?.length,
          value: 'credits',
          widgetType: 'PORTFOLIO',
          // run_id: response?.data?.run_id,
          selectedPortfolio: selectedPortfolio,
          portfolio_id: combinedArray[0],
          combinedArray: combinedArray,
          portfoliosData: portfoliosData,
          allFileNames: allFileNames,
          allPortfolios: allPortfoliosListing,
          selectedDataSourcesWidget: selectedDataSourcesWidget,
          rowPreviewData: rowPreviewData,
          insertedId: insertedId,
          availableAliasName: availableAliasName,
          oldPortfoliosData: oldPortfoliosData,
          customWidgetName: customWidgetName,
        });

        togglePortfolioWidget();
        document.body.classList.remove('show-dataitem');
        if (isUpdate) {
          document.body.classList.add('widget-fullscreen');
        }
      } else {
        setIsLoading(false);

        showModal('please select all row data mapping');
      }
    }

    if (
      combinedArray?.length === portfoliosData?.length &&
      combinedArray?.length !== 1
    ) {
      if (checkForFinancialOrCollateral(portfoliosData)) {
        mergeApiCall([], []);
      } else {
        setIsLoading(false);
        showModal('please select all row data mapping');
      }
    }

    if (
      combinedArray?.length !== portfoliosData?.length &&
      combinedArray?.length !== 1
    ) {
      if (checkForFinancialOrCollateral(portfoliosData)) {
        try {
          let portfolioArray: DataItem[] = [];
          const results = await Promise.all(
            combinedArray.map(id => fetchData(id)),
          );

          // Filter out null results and convert to an array of DynamicCSVStructure
          const dynamicCSVStructures: DynamicCSVStructure[] = results.filter(
            (result): result is DynamicCSVStructure => result !== null,
          );

          mergeApiCall(dynamicCSVStructures, portfolioArray);
        } catch (err) {
          if (isAxiosError(err)) {
            showModal(
              (err.response?.data as { error?: string })?.error ||
                'Unexpected Axios error',
            );
          } else {
            showModal('something went wrong');
          }
        }
      } else {
        setIsLoading(false);
        showModal('please select all row data mapping');
      }
    }
  };

  const toggleFieldVisibility = (key: string, fieldName: string) => {
    setPortfoliosData(prevData =>
      prevData.map(item =>
        item.key === key
          ? {
              ...item,
              value: {
                ...item.value,
                [fieldName]: {
                  ...item.value[fieldName],
                  hidden: !item.value[fieldName]?.hidden,
                },
              },
            }
          : item,
      ),
    );
  };

  const arrayToObject = (arr: Record<string, any>[]) => {
    return arr.reduce((acc, current) => {
      return { ...acc, ...current };
    }, {});
  };

  const formatData = (data: ResponseItem[]) => {
    return data.map(item => {
      const formattedValue: Record<string, any> = {};

      Object.entries(item.value).forEach(([key, obj]) => {
        if (!obj.hidden) {
          formattedValue[key] = {
            ...obj,
            [key]: obj.actualFieldName || key,
          };
        }
      });

      return {
        [allFileNames[item.key]?.key]: transformDataForMerge(formattedValue),
      };
    });
  };

  const filterJsonFiles = (data: Record<string, any>, isJson: boolean) => {
    if (isJson) {
      return Object.fromEntries(
        Object.entries(data).filter(([key]) => key.endsWith('.json')),
      );
    } else {
      return Object.fromEntries(
        Object.entries(data).filter(([key]) => !key.endsWith('.json')),
      );
    }
  };

  // const replaceInnerQuotes = (jsonString: string): string => {
  //   // Replace only double quotes inside the string content with single quotes
  //   return jsonString.replace(/([a-zA-Z])"([a-zA-Z])/g, "$1'$2");
  // };

  // const preprocessJsonData = (jsonData: any) => {
  //   // Convert JSON object to string
  //   let jsonString = JSON.stringify(jsonData);

  //   // Remove or replace non-ASCII characters
  //   jsonString = unidecode(jsonString);

  //   // Parse the processed string back to JSON
  //   return JSON.parse(jsonString);
  // };

  const mergeApiCall = async (
    data: DynamicCSVStructure[],
    portfoliosDataArrayAll: DataItem[],
  ) => {
    const payload = {
      parquet_files:
        data?.length > 0
          ? filterJsonFiles(arrayToObject(data), false)
          : filterJsonFiles(arrayToObject(formatData(portfoliosData)), false),
      json_files:
        data?.length > 0
          ? filterJsonFiles(arrayToObject(data), true)
          : filterJsonFiles(arrayToObject(formatData(portfoliosData)), true),

      join_type: selectedMergeType?.value,
      default_file_name:
        selectedMergeType?.value === 'join' ? defaultFileMerge || '' : '',
      join_key_name_one:
        selectedMergeType?.value === 'join'
          ? Object?.values(joinKey)[0] || ''
          : '',
      join_key_name_two:
        selectedMergeType?.value === 'join'
          ? Object?.values(joinKey)[1] || ''
          : '',
    };

    // const payload = {
    //   parquet_files:
    //     data?.length > 0
    //       ? replaceInnerQuotes(
    //           JSON.stringify(
    //             preprocessJsonData(filterJsonFiles(arrayToObject(data), false)),
    //           ),
    //         )
    //       : replaceInnerQuotes(
    //           JSON.stringify(
    //             preprocessJsonData(
    //               filterJsonFiles(
    //                 arrayToObject(formatData(portfoliosData)),
    //                 false,
    //               ),
    //             ),
    //           ),
    //         ),
    //   json_files:
    //     data?.length > 0
    //       ? replaceInnerQuotes(
    //           JSON.stringify(
    //             preprocessJsonData(filterJsonFiles(arrayToObject(data), true)),
    //           ),
    //         )
    //       : replaceInnerQuotes(
    //           JSON.stringify(
    //             preprocessJsonData(
    //               filterJsonFiles(
    //                 arrayToObject(formatData(portfoliosData)),
    //                 true,
    //               ),
    //             ),
    //           ),
    //         ),
    // };

    axios
      .post(`${config.ER_ML_URL}/data-bricks/run-merge-job`, payload)
      .then(response => {
        setIsLoading(false);
        // getMergedPortfolioData(
        //   response?.data?.portfolio_id,
        //   response?.data?.run_id,
        // );

        addPortfolioCreditWidgetData({
          id: isUpdate ? widgetIdOfIndex : allPortfolioWidgetReduxData?.length,
          value: 'credits',
          widgetType: 'PORTFOLIO',
          portfolio_id: response?.data?.portfolio_id,
          selectedPortfolio: selectedPortfolio,
          combinedArray: combinedArray,
          portfoliosData:
            portfoliosDataArrayAll?.length > 0
              ? portfoliosDataArrayAll
              : portfoliosData,
          allFileNames: allFileNames,
          allPortfolios: allPortfoliosListing,
          selectedDataSourcesWidget: selectedDataSourcesWidget,
          rowPreviewData: rowPreviewData,
          insertedId: insertedId,
          availableAliasName: availableAliasName,
          oldPortfoliosData: oldPortfoliosData,
          customWidgetName: customWidgetName,
        });
        togglePortfolioWidget();
        document.body.classList.remove('show-dataitem');
        if (isUpdate) {
          document.body.classList.add('widget-fullscreen');
        }
      })
      .catch(err => {
        setIsLoading(false);
        if (isAxiosError(err)) {
          showModal(
            (err.response?.data as { error?: string })?.error ||
              'Unexpected Axios error',
          );
        } else {
          showModal('something went wrong');
        }
      });
  };

  useEffect(() => {
    if (isUpdate) {
      updateSelectedWidget(widgetTypeToUpdate as string);
      setCurrentActiveTab('2');
      setSelectedDataSourcesWidget(
        allPortfolioWidgetReduxData[widgetIdOfIndex as number]
          ?.selectedDataSourcesWidget,
      );
      setOldPortfoliosData(
        cloneDeep(
          allPortfolioWidgetReduxData[widgetIdOfIndex as number]
            ?.portfoliosData,
        ),
      );
      setPortfoliosData(
        allPortfolioWidgetReduxData[widgetIdOfIndex as number]?.portfoliosData,
      );
      setInsertedId(
        allPortfolioWidgetReduxData[widgetIdOfIndex as number]?.insertedId,
      );
    }
  }, []);

  useEffect(() => {
    setDefaultFileMerge('');
    setJoinKey({});
  }, [selectedMergeType?.value]);

  return (
    <>
      {isLoading && <AppLoader />}
      {/* {isAiLoading && (
        <div className="loader flexbox-align-c">
          <img src={loaderGIF} alt="" />
        </div>
      )} */}
      <div className="addwidget-wrapper">
        <div className="sub-header justify-content-between">
          <h4 className="title-20">Add Portfolio Widget</h4>
          <button
            className="btn btn-icon p-0"
            onClick={() => {
              togglePortfolioWidget();
              document.body.classList.remove('show-dataitem');
              if (isUpdate) {
                document.body.classList.add('widget-fullscreen');
              }
            }}
          >
            <Iconclose />
          </button>
        </div>
        <div className="addwidget-main">
          <div className="container container-1024">
            <div className="addwidget-header">
              {currentActiveTab === '1' && (
                <h4 className="title-20">Select widget type</h4>
              )}
              {selectedWidget === 'CREDIT' && (
                <CreditWidgetHeading currentActiveTab={currentActiveTab} />
              )}

              {selectedWidget === 'ANALYTICS' && (
                <AnalyticsWidgetHeading currentActiveTab={currentActiveTab} />
              )}

              {selectedWidget === 'LOAN' && (
                <LoanWidgetHeading currentActiveTab={currentActiveTab} />
              )}

              {selectedWidget === 'RECEIVABLE' && (
                <ReceivablesWidgetHeading currentActiveTab={currentActiveTab} />
              )}

              {selectedWidget === 'NPL' && (
                <NplWidgetHeading currentActiveTab={currentActiveTab} />
              )}

              <div className="tabs-h-menu"></div>
              <ul className="addwidget-steps">
                <li>
                  <button
                    className={classnames({
                      active: currentActiveTab === '1',
                    })}
                  >
                    1
                  </button>
                </li>
                <li>
                  <button
                    className={classnames({
                      active: currentActiveTab === '2',
                    })}
                  >
                    2
                  </button>
                </li>
                <li>
                  <button
                    className={classnames({
                      active: currentActiveTab === '3',
                    })}
                  >
                    3
                  </button>
                </li>
                {selectedWidget !== 'ANALYTICS' && (
                  <li>
                    <button
                      className={classnames({
                        active: currentActiveTab === '4',
                      })}
                    >
                      4
                    </button>
                  </li>
                )}
              </ul>
            </div>
            <TabContent activeTab={currentActiveTab}>
              <TabPane tabId="1">
                <SelectWidgetType
                  selectedWidget={selectedWidget}
                  updateSelectedWidget={updateSelectedWidget}
                  customWidgetName={customWidgetName}
                  setCustomWidgetName={setCustomWidgetName}
                />
              </TabPane>
              {selectedWidget === 'CREDIT' && (
                <>
                  <TabPane tabId="2">
                    <SelectFiles
                      isUpdate={isUpdate}
                      reduxSelectedPortfolios={
                        allPortfolioWidgetReduxData[widgetIdOfIndex as number]
                          ?.selectedPortfolio
                      }
                      selectedPortfolio={selectedPortfolio}
                      setSelectedPortfolio={setSelectedPortfolio}
                      selectedKnowledgeBaseFile={selectedKnowledgeBaseFile}
                      setSelectedKnowledgeBaseFile={
                        setSelectedKnowledgeBaseFile
                      }
                      selectedDataSourcesWidget={selectedDataSourcesWidget}
                      setSelectedDataSourcesWidget={
                        setSelectedDataSourcesWidget
                      }
                      selectedMergeType={selectedMergeType}
                      setSelectedMergeType={setSelectedMergeType}
                    />
                  </TabPane>
                  <TabPane tabId="3">
                    <SelectDataSources
                      isUpdate={isUpdate}
                      amplifyDataArr={amplifyDataArr}
                      setAmplifyDataArr={setAmplifyDataArr}
                      nodeEndpointArr={nodeEndpointArr}
                      setNodeEndpointArr={setNodeEndpointArr}
                      selectedDataSourcesWidget={selectedDataSourcesWidget}
                      setSelectedDataSourcesWidget={
                        setSelectedDataSourcesWidget
                      }
                    />
                  </TabPane>

                  <TabPane tabId="4">
                    {combinedArray.length > 0 && currentActiveTab === '4' && (
                      <MapPortfolioData
                        portfoliosData={portfoliosData}
                        selectedPortfolio={selectedPortfolio}
                        combinedArray={combinedArray}
                        setPortfoliosData={setPortfoliosData}
                        oldPortfoliosData={oldPortfoliosData}
                        setOldPortfoliosData={setOldPortfoliosData}
                        setAllFileNames={setAllFileNames}
                        toggleField={toggleFieldVisibility}
                        setAllPortfoliosListing={setAllPortfoliosListing}
                        availableAliasName={availableAliasName}
                        setAvailableAliasName={setAvailableAliasName}
                        rowPreviewData={rowPreviewData}
                        setRowPreviewData={setRowPreviewData}
                        defaultFileMerge={defaultFileMerge}
                        setDefaultFileMerge={setDefaultFileMerge}
                        joinKey={joinKey}
                        setJoinKey={setJoinKey}
                        selectedMergeType={selectedMergeType}
                      />
                    )}
                  </TabPane>
                </>
              )}

              {selectedWidget === 'ANALYTICS' && (
                <>
                  <TabPane tabId="2">
                    <SelectPortfolios
                      setSelectedVisualisationPortfolio={
                        setSelectedVisualisationPortfolio
                      }
                    />
                  </TabPane>
                  <TabPane tabId="3">
                    <SelectTypeAndParameters
                      currentActiveTab={currentActiveTab}
                      selectedVisualisationPortfolio={
                        selectedVisualisationPortfolio
                      }
                      onFormChange={handleChartFormChange}
                    />
                  </TabPane>
                </>
              )}

              {(selectedWidget === 'LOAN' ||
                selectedWidget === 'RECEIVABLE' ||
                selectedWidget === 'NPL' ||
                selectedWidget === 'STATISTICAL') && (
                <>
                  <TabPane tabId="2">
                    <SelectStatPortfolios
                      selectedWidget={selectedWidget}
                      updateSelectedWidget={updateSelectedWidget}
                      selectedPortfolioStat={selectedPortfolioStat}
                      setSelectedPortfolioStat={setSelectedPortfolioStat}
                      statPortfolioList={statPortfolioList}
                      setStatPortfolioList={setStatPortfolioList}
                    />
                  </TabPane>
                  <TabPane tabId="3">
                    <TypeChat
                      selectedPortfolioStat={selectedPortfolioStat}
                      statQuery={statQuery}
                      setStatQuery={setStatQuery}
                      statSelectedModel={statSelectedModel}
                      setStatSelectedModel={setStatSelectedModel}
                      isStatLoading={isStatLoading}
                      setIsStatLoading={setIsStatLoading}
                      setNewFieldsArray={setNewFieldsArray}
                      setIsStatValueCalculated={setIsStatValueCalculated}
                    />
                  </TabPane>
                  <TabPane tabId="4">
                    {currentActiveTab === '4' && (
                      <AnalysisResult
                        inputData={inputData}
                        setInputData={setInputData}
                        selectedPortfolioStat={selectedPortfolioStat}
                        statQuery={statQuery}
                      />
                    )}
                  </TabPane>
                </>
              )}
            </TabContent>
          </div>
          <div className="gap-12 d-flex justify-content-center">
            <button
              className="btn btn-outline-info"
              onClick={() => {
                if (currentActiveTab === '1') {
                  togglePortfolioWidget();
                  document.body.classList.remove('show-dataitem');
                }

                toggle(String(Number(currentActiveTab) - 1));
              }}
            >
              {currentActiveTab === '1' ? 'Cancel' : 'Back'}
            </button>
            <button
              className="btn btn-primary"
              onClick={() => {
                if (selectedWidget === 'CREDIT') {
                  if (currentActiveTab === '1') {
                    toggle(String(Number(currentActiveTab) + 1));
                  }

                  if (currentActiveTab === '2') {
                    if (selectedKnowledgeBaseFile.length > 0) {
                      saveJsonDataKnowledgeBase(selectedKnowledgeBaseFile);
                    } else {
                      toggle(String(Number(currentActiveTab) + 1));
                    }
                  }

                  if (currentActiveTab === '3') {
                    if (
                      selectedMergeType?.value === 'join' &&
                      [
                        ...insertedId,
                        ...selectedPortfolio,
                        ...nodeEndpointArr,
                        ...amplifyDataArr,
                      ]?.length !== 2
                    ) {
                      showModal('Only 2 files allowed for join operation');
                    }

                    if (
                      (selectedMergeType?.value === 'join' &&
                        [
                          ...insertedId,
                          ...selectedPortfolio,
                          ...nodeEndpointArr,
                          ...amplifyDataArr,
                        ]?.length === 2) ||
                      selectedMergeType?.value === 'union'
                    ) {
                      if (selectedDataSourcesWidget?.length > 0) {
                        const promises: Promise<void>[] = [];

                        if (amplifyDataArr?.length > 0) {
                          promises.push(
                            saveAmplifyDataEndpoints(amplifyDataArr),
                          );
                        }

                        if (nodeEndpointArr?.length > 0) {
                          promises.push(saveNodeEndpointData(nodeEndpointArr));
                        }

                        Promise.all(promises)
                          .then(() => {
                            // if (combinedArray?.length > 0) {
                            toggle(String(Number(currentActiveTab) + 1));
                            // } else {
                            //   showModal('Please select atleast 1 file');
                            // }
                          })
                          .catch(() => {
                            showModal('something went wrong.');
                            // Optionally handle the error globally
                          });
                      } else {
                        showModal('Please select at least 1 portfolio');
                      }
                    }
                  }

                  if (currentActiveTab === '4') {
                    saveSynonymsData();

                    // togglePortfolioWidget();
                  }
                }

                if (selectedWidget === 'ANALYTICS') {
                  if (currentActiveTab !== '3') {
                    toggle(String(Number(currentActiveTab) + 1));
                  }

                  if (currentActiveTab === '3') {
                    let payload: any;
                    const chartType =
                      chartFormValues?.visualisationType?.value[0] +
                      chartFormValues?.visualisationType?.value
                        .slice(1)
                        .toLowerCase();

                    if (
                      chartFormValues?.visualisationType?.value ===
                        CHARTS.PIE ||
                      chartFormValues?.visualisationType?.value === CHARTS.RADAR
                    ) {
                      const valuesArray = chartFormValues?.fields?.xAxis?.map(
                        (item: { value: string }) => item?.value,
                      );

                      payload = {
                        chart_name: chartType,
                        fields: valuesArray,
                        required_fields: valuesArray,
                      };
                    }
                    // else if (
                    //   chartFormValues?.visualisationType?.value ===
                    //   CHARTS.BUBBLE
                    // ) {
                    //   const valuesArray = Object.keys(
                    //     chartFormValues?.fields,
                    //   ).map(axis => chartFormValues?.fields[axis].value);
                    //   payload = {
                    //     chart_name: chartType,
                    //     fields: valuesArray,
                    //     required_fields: valuesArray,
                    //   };
                    // }
                    else if (
                      chartFormValues?.visualisationType?.value ===
                        CHARTS.LINE ||
                      chartFormValues?.visualisationType?.value ===
                        CHARTS.BAR ||
                      chartFormValues?.visualisationType?.value ===
                        CHARTS.BUBBLE
                    ) {
                      if (
                        isEmpty(chartFormValues?.fields?.xAxis) ||
                        isEmpty(chartFormValues?.fields?.yAxis)
                      ) {
                        showModal('The length of xAxis and yAxis must match.');
                      } else {
                        const valuesArray = [
                          chartFormValues?.fields?.xAxis?.value,
                          chartFormValues?.fields?.yAxis?.value,
                        ];

                        if (valuesArray?.length) {
                          // const mergedArray = valuesArray[0].map(
                          //   (value: string, index: string | number) => {
                          //     return [value, valuesArray[1][index]];
                          //   },
                          // );

                          // const flattenedArray: string[] = valuesArray.flat();
                          const uniqueArray: string[] = [];
                          for (const item of valuesArray) {
                            if (!uniqueArray.includes(item)) {
                              uniqueArray.push(item);
                            }
                          }

                          payload = {
                            chart_name: chartType,
                            fields: valuesArray,
                            required_fields: uniqueArray,
                          };
                        }
                      }
                    } else if (
                      chartFormValues?.visualisationType?.value ===
                      CHARTS.WATERFALL
                    ) {
                      const valuesArray = [
                        [chartFormValues?.fields?.xAxis.value],
                        chartFormValues?.fields?.yAxis.map(
                          (item: { value: string }) => item.value,
                        ),
                      ];

                      if (valuesArray?.length) {
                        const flattenedArray: string[] = valuesArray.flat();
                        const uniqueArray: string[] = [];
                        for (const item of flattenedArray) {
                          if (!uniqueArray.includes(item)) {
                            uniqueArray.push(item);
                          }
                        }

                        payload = {
                          chart_name: chartType,
                          fields: valuesArray,
                          required_fields: uniqueArray,
                        };
                      }
                    } else if (
                      chartFormValues?.visualisationType?.value === CHARTS.AREA
                    ) {
                      const valuesArray = [
                        [chartFormValues?.fields?.xAxis.value],
                        chartFormValues?.fields?.yAxis.map(
                          (item: { value: string }) => item.value,
                        ),
                      ];

                      if (valuesArray?.length) {
                        const flattenedArray: string[] = valuesArray.flat();
                        const uniqueArray: string[] = [];
                        for (const item of flattenedArray) {
                          if (!uniqueArray.includes(item)) {
                            uniqueArray.push(item);
                          }
                        }
                        payload = {
                          chart_name: 'Area',
                          fields: valuesArray,
                          required_fields: uniqueArray,
                        };
                      }
                    } else if (
                      chartFormValues?.visualisationType?.value ===
                      CHARTS.STACKED
                    ) {
                      const valuesArray = [
                        [chartFormValues?.fields?.xAxis.value],
                        chartFormValues?.fields?.yAxis.map(
                          (item: { value: string }) => item.value,
                        ),
                      ];

                      if (valuesArray?.length) {
                        const flattenedArray: string[] = valuesArray.flat();
                        const uniqueArray: string[] = [];
                        for (const item of flattenedArray) {
                          if (!uniqueArray.includes(item)) {
                            uniqueArray.push(item);
                          }
                        }
                        payload = {
                          chart_name: 'Stack Bar',
                          fields: valuesArray,
                          required_fields: uniqueArray,
                        };
                      }
                    } else if (
                      chartFormValues?.visualisationType?.value ===
                      CHARTS.SCATTER
                    ) {
                      const valuesArray = [
                        [chartFormValues?.fields?.xAxis.value],
                        chartFormValues?.fields?.yAxis.map(
                          (item: { value: string }) => item.value,
                        ),
                      ];

                      if (valuesArray?.length) {
                        const flattenedArray: string[] = valuesArray.flat();
                        const uniqueArray: string[] = [];
                        for (const item of flattenedArray) {
                          if (!uniqueArray.includes(item)) {
                            uniqueArray.push(item);
                          }
                        }
                        payload = {
                          chart_name: 'Scatter',
                          fields: valuesArray,
                          required_fields: uniqueArray,
                        };
                      }
                    } else if (
                      chartFormValues?.visualisationType?.value ===
                      CHARTS.HEATMAP
                    ) {
                      if (
                        chartFormValues?.fields?.xAxis?.length !==
                        chartFormValues?.fields?.yAxis?.length
                      ) {
                        showModal('The length of xAxis and yAxis must match.');
                      } else {
                        const valuesArray = [
                          chartFormValues?.fields?.xAxis.map(
                            (item: { value: string }) => item.value,
                          ),
                          chartFormValues?.fields?.yAxis.map(
                            (item: { value: string }) => item.value,
                          ),
                        ];

                        if (valuesArray?.length) {
                          const data = {
                            x: valuesArray[0],
                            y: valuesArray[1],
                          };
                          const flattenedArray: string[] = valuesArray.flat();
                          const uniqueArray: string[] = [];
                          for (const item of flattenedArray) {
                            if (!uniqueArray.includes(item)) {
                              uniqueArray.push(item);
                            }
                          }
                          payload = {
                            chart_name: 'Heat Map',
                            fields: data,
                            required_fields: uniqueArray,
                          };
                        }
                      }
                    } else {
                      // console.log('other charts ');
                    }

                    if (payload?.fields) {
                      getChartsData(
                        selectedVisualisationPortfolio,
                        payload,
                        chartFormValues,
                      );
                    }
                  }
                }

                if (
                  selectedWidget === 'LOAN' ||
                  selectedWidget === 'RECEIVABLE' ||
                  selectedWidget === 'NPL' ||
                  selectedWidget === 'STATISTICAL'
                ) {
                  if (currentActiveTab === '2') {
                    if (selectedWidget === 'STATISTICAL') {
                      showModal('Please select widget type');
                    } else {
                      if (selectedPortfolioStat?.length > 0) {
                        toggle(String(Number(currentActiveTab) + 1));
                      } else {
                        showModal('Please select at least 1 portfolio');
                      }
                    }
                  }

                  if (currentActiveTab === '1') {
                    toggle(String(Number(currentActiveTab) + 1));
                  }
                  if (currentActiveTab === '3') {
                    if (statQuery !== '' && isStatValueCaclculated) {
                      toggle(String(Number(currentActiveTab) + 1));
                    } else {
                      showModal('Please perform any calculation!');
                    }
                  }

                  if (currentActiveTab === '4') {
                    if (selectedWidget === 'NPL') {
                      addPortfolioNplWidgetData({
                        id: allPortfolioWidgetReduxData?.length,
                        value: 'npl',
                        widgetType: 'PORTFOLIO',
                        inputDataRange: inputData,
                        selectedStatPortfolio: selectedPortfolioStat,
                        newFieldsArray: newFieldsArray,
                        customWidgetName: customWidgetName,
                      });
                    }
                    if (selectedWidget === 'LOAN') {
                      addPortfolioLoanWidgetData({
                        id: allPortfolioWidgetReduxData?.length,
                        value: 'loan',
                        widgetType: 'PORTFOLIO',
                        inputDataRange: inputData,
                        selectedStatPortfolio: selectedPortfolioStat,
                        newFieldsArray: newFieldsArray,
                        customWidgetName: customWidgetName,
                      });
                    }
                    if (selectedWidget === 'RECEIVABLE') {
                      addPortfolioReceivableWidgetData({
                        id: allPortfolioWidgetReduxData?.length,
                        value: 'receivable',
                        widgetType: 'PORTFOLIO',
                        inputDataRange: inputData,
                        selectedStatPortfolio: selectedPortfolioStat,
                        newFieldsArray: newFieldsArray,
                        customWidgetName: customWidgetName,
                      });
                    }
                    document.body.classList.remove('show-dataitem');
                    togglePortfolioWidget();
                  }
                }
              }}
            >
              {buttonText()}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddPortfolioWidget;
