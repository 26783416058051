import React, {
  /* useEffect, */ useEffect,
  useRef /* useState */,
  useState,
} from 'react';
import Plot from 'react-plotly.js';
import {
  getLayoutConfig,
  getXAxisConfig,
  getYAxisConfig,
} from '../../chartsTheme';
import { rangeSlider } from '../../utils/modeBarIcons';
import Plotly from 'plotly.js';

const LineChart = ({
  chartData,
  // chartTitle,
  initialXAxis,
  initialYAxis,
}: {
  chartData: any;
  chartTitle: string;
  initialXAxis: string;
  initialYAxis: string;
}) => {
  const layoutConfig = getLayoutConfig();
  // const [isFilled, setIsFilled] = useState(false);
  // const [updatedData, setUpdatedData] = useState(chartData);
  const [isRangeSliderVisible, setIsRangeSliderVisible] = useState(false);
  const [xAxisRange, setXAxisRange] = useState<number[] | undefined>(undefined);
  const [updatedChartData, setUpdatedChartData] = useState(chartData);
  const [isSpikesVisible, setIsSpikesVisible] = useState(false);

  // console.log(chartData[0].x);

  useEffect(() => {
    // Check if the x-axis has more than 100 data points
    if (chartData[0]?.x?.length > 100) {
      const minX = Math.min(...chartData[0].x);
      setXAxisRange([minX - 1, minX + 10]); // Set the range from lowest number to lowest number + 10
      setIsRangeSliderVisible(true);
    } else {
      setXAxisRange(undefined);
      setIsRangeSliderVisible(false);
    }

    const modifiedData = chartData.map((data: any) => {
      return {
        ...data,
        x: data?.x?.map((value: any) =>
          value === 0 ? 'Undefined Value' : value,
        ),
      };
    });

    setUpdatedChartData(modifiedData);
  }, [chartData]);

  const plotRef = useRef<any>(null);

  const resizeLayout = () => {
    plotRef.current.resizeHandler();
  };
  // console.log(chartData[0]);

  const toggleRangeSlider = () => {
    setIsRangeSliderVisible(prev => !prev);
  };

  const toggleSpikeLines = () => {
    setIsSpikesVisible(prev => !prev);
  };

  const customButtons = [
    {
      name: 'Toggle Range Slider',
      icon: rangeSlider,
      title: 'Toggle Range Slider',
      click: toggleRangeSlider,
    },
    {
      name: 'Toggle Spike Lines',
      icon: Plotly.Icons.spikeline,
      title: 'Toggle Spike Lines',
      click: toggleSpikeLines,
    },
  ];

  return (
    <>
      {
        <div className="chart-wrapper" onMouseDown={e => e.stopPropagation()}>
          <Plot
            ref={plotRef}
            // data={updatedData}
            data={updatedChartData}
            config={{
              scrollZoom: true,
              displaylogo: false,
              editable: true,
              responsive: true,
              autosizable: true,
              modeBarButtonsToAdd: customButtons,
            }}
            layout={{
              title: '',
              xaxis: {
                ...getXAxisConfig(initialXAxis),
                range: xAxisRange,
                spikemode: 'toaxis',
                showspikes: isSpikesVisible,
                rangeslider: { visible: isRangeSliderVisible },
              },
              yaxis: {
                ...getYAxisConfig(initialYAxis),
                showspikes: isSpikesVisible,
                spikemode: 'toaxis',
              },
              ...layoutConfig,
            }}
            useResizeHandler={true}
            onRelayout={resizeLayout}
          />
        </div>
      }
    </>
  );
};

export default LineChart;
