import { getMessaging, onMessage } from '@firebase/messaging';
import { initializeApp } from 'firebase/app';

import toast from 'react-hot-toast';
// import NotificationService from './app/services/notification.service';
// import AuthService from './app/services/auth.service';
import NotificationService from './app/services/notification.service';
// import config from './config';

// const authService = new AuthService();
const notificationService = new NotificationService();

const firebaseConfig = {
  apiKey: 'AIzaSyCj14WafeA15OER8L-BaA-fXPYg9rxe8Z0',
  authDomain: 'fluid-shadow-392700.firebaseapp.com',
  projectId: 'fluid-shadow-392700',
  storageBucket: 'fluid-shadow-392700.appspot.com',
  messagingSenderId: '281097389391',
  appId: '1:281097389391:web:c723268822a4f8d969b13a',
  measurementId: 'G-JH2XYHLZ9E',
};

export const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);

export async function requestPermission() {
  const hasRequestedPermission = localStorage.getItem(
    'hasRequestedNotificationPermission',
  );

  if (hasRequestedPermission || Notification.permission !== 'default') {
    return;
  }

  const permission = await Notification.requestPermission();

  if (permission === 'granted') {
    try {
      notificationService.getNotificationCount().then(({ data }: any) => {
        localStorage.setItem('notificationCountER', data);
      });

      // Set the flag in localStorage to prevent re-asking
      localStorage.setItem('hasRequestedNotificationPermission', 'true');
    } catch (e) {
      toast.error('Notification Error');
    }
  } else if (permission === 'denied') {
    // Set the flag to avoid asking again
    localStorage.setItem('hasRequestedNotificationPermission', 'true');
  }
}

export const onMessageListener = () => {
  return new Promise(resolve => {
    onMessage(messaging, payload => {
      resolve(payload);
    });
  });
};
