import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { AuthGuardPropsType } from '../../../@types/Routes';
import { userData } from '../../redux/selector/UserSelector';

const AuthGuard = ({ children }: AuthGuardPropsType) => {
  const user = useSelector(userData);
  // const isLoggedIn = localStorage.getItem('_ERFTKEN');
  const isLicenseAuth =
    user?.userDetail?.license?.licenseType === 'BASIC' ? false : true;
  return isLicenseAuth ? <>{children}</> : <Navigate to="/newsfeed" replace />;
};

export default AuthGuard;
