import { selectedModelConstant } from '../constant';

const { ADD_SELECTED_MODEL } = selectedModelConstant;

const initialState: { selectedModel: string } = {
  selectedModel: '',
};

const SelectedModelReducer = (state = initialState, action: any): any => {
  switch (action.type) {
    case ADD_SELECTED_MODEL:
      return {
        ...state,
        selectedModel: action.payload.selectedModel,
      };

    default:
      return state;
  }
};
export default SelectedModelReducer;
