import React from 'react';
type NewsfeedHeadingsProps = {
  currentActiveTab: string;
};

const NewsfeedHeadings = ({ currentActiveTab }: NewsfeedHeadingsProps) => {
  return (
    <>
      {currentActiveTab ===
        `${location?.pathname?.includes('newsfeed') ? '1' : '2'}` && (
        <h4 className="title-20">Select news sources for the News widget</h4>
      )}
    </>
  );
};

export default NewsfeedHeadings;
