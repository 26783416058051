export const locations = [
  'Afghanistan',
  'Albania',
  'Algeria',
  'Andorra',
  'Angola',
  'Antigua and Barbuda',
  'Argentina',
  'Armenia',
  'Australia',
  'Austria',
  'Austrian Empire*',
  'Azerbaijan',
  'Baden*',
  'Bahamas, The',
  'Bahrain',
  'Bangladesh',
  'Barbados',
  'Bavaria*',
  'Belarus',
  'Belgium',
  'Belize',
  'Benin (Dahomey)',
  'Bolivia',
  'Bosnia and Herzegovina',
  'Botswana',
  'Brazil',
  'Brunei',
  'Brunswick and Lüneburg',
  'Bulgaria',
  'Burkina Faso (Upper Volta)',
  'Burma',
  'Burundi',
  'Cabo Verde',
  'Cambodia',
  'Cameroon',
  'Canada',
  'Cayman Islands, The',
  'Central African Republic',
  'Central American Federation*',
  'Chad',
  'Chile',
  'China',
  'Colombia',
  'Comoros',
  'Congo Free State, The',
  'Costa Rica',
  'Cote d’Ivoire (Ivory Coast)',
  'Croatia',
  'Cuba',
  'Cyprus',
  'Czechia',
  'Czechoslovakia',
  'Democratic Republic of the Congo',
  'Denmark',
  'Djibouti',
  'Dominica',
  'Dominican Republic',
  'Duchy of Parma, The*',
  'East Germany (German Democratic Republic)*',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'Equatorial Guinea',
  'Eritrea',
  'Estonia',
  'Eswatini',
  'Ethiopia',
  'Federal Government of Germany (1848-49)*',
  'Fiji',
  'Finland',
  'France',
  'Gabon',
  'Gambia',
  'The',
  'Georgia',
  'Germany',
  'Ghana',
  'Grand Duchy of Tuscany',
  'The*',
  'Greece',
  'Grenada',
  'Guatemala',
  'Guinea',
  'Guinea-Bissau',
  'Guyana',
  'Haiti',
  'Hanover*',
  'Hanseatic Republics*',
  'Hawaii*',
  'Hesse*',
  'Holy See',
  'Honduras',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Iran',
  'Iraq',
  'Ireland',
  'Israel',
  'Italy',
  'Jamaica',
  'Japan',
  'Jordan',
  'Kazakhstan',
  'Kenya',
  'Kingdom of Serbia/Yugoslavia*',
  'Kiribati',
  'Korea',
  'Kosovo',
  'Kuwait',
  'Kyrgyzstan',
  'Laos',
  'Latvia',
  'Lebanon',
  'Lesotho',
  'Lew Chew (Loochoo)*',
  'Liberia',
  'Libya',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Maldives',
  'Mali',
  'Malta',
  'Marshall Islands',
  'Mauritania',
  'Mauritius',
  'Mecklenburg-Schwerin*',
  'Mecklenburg-Strelitz*',
  'Mexico',
  'Micronesia',
  'Moldova',
  'Monaco',
  'Mongolia',
  'Montenegro',
  'Morocco',
  'Mozambique',
  'Namibia',
  'Nassau*',
  'Nauru',
  'Nepal',
  'Netherlands, The',
  'New Zealand',
  'Nicaragua',
  'Niger',
  'Nigeria',
  'North German Confederation*',
  'North German Union*',
  'North Macedonia',
  'Norway',
  'Oldenburg*',
  'Oman',
  'Orange Free State*',
  'Pakistan',
  'Palau',
  'Panama',
  'Papal States*',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Philippines',
  'Piedmont-Sardinia*',
  'Poland',
  'Portugal',
  'Qatar',
  'Republic of Genoa*',
  'Republic of Korea (South Korea)',
  'Republic of the Congo',
  'Romania',
  'Russia',
  'Rwanda',
  'Saint Kitts and Nevis',
  'Saint Lucia',
  'Saint Vincent and the Grenadines',
  'Samoa',
  'San Marino',
  'Sao Tome and Principe',
  'Saudi Arabia',
  'Schaumburg-Lippe*',
  'Senegal',
  'Serbia',
  'Seychelles',
  'Sierra Leone',
  'Singapore',
  'Slovakia',
  'Slovenia',
  'Solomon Islands, The',
  'Somalia',
  'South Africa',
  'South Sudan',
  'Spain',
  'Sri Lanka',
  'Sudan',
  'Suriname',
  'Sweden',
  'Switzerland',
  'Syria',
  'Tajikistan',
  'Tanzania',
  'Texas*',
  'Thailand',
  'Timor-Leste',
  'Togo',
  'Tonga',
  'Trinidad and Tobago',
  'Tunisia',
  'Turkey',
  'Turkmenistan',
  'Tuvalu',
  'Two Sicilies*',
  'Uganda',
  'Ukraine',
  'Union of Soviet Socialist Republics*',
  'United Arab Emirates, The',
  'United Kingdom, The',
  'Uruguay',
  'Uzbekistan',
  'Vanuatu',
  'Venezuela',
  'Vietnam',
  'Württemberg*',
  'Yemen',
  'Zambia',
  'Zimbabwe',
];

export const organizations = [
  'Fintech Marketplace',
  'Small Credit Bank',
  'Large Corporate Bank',
  'Morgage Bank',
  'Morage Broker',
  'Loan Bank',
  'Loan Broker',
  'Small Corporate',
  'Medium Corporate',
  'Large Corporate Bank',
  'Trade Finance Broker',
  'Supply Chain Management',
  'Hedge Fund',
  'Investment Bank',
  'Private Equity Fund',
  'Family Office',
];

export const positionsOptions = [
  { label: 'Marketing', value: 'Marketing' },
  { label: 'Sales', value: 'Sales' },
  { label: 'Distribution', value: 'Distribution' },
  { label: 'Credit Risk', value: 'Credit Risk' },
  { label: 'Credit Management', value: 'Credit Management' },
  { label: 'Portfolio Manager', value: 'Portfolio Manager' },
  { label: 'Underwriter', value: 'Underwriter' },
  { label: 'Controller', value: 'Controller' },
  { label: 'Structuring', value: 'Structuring' },
  { label: 'Issuer', value: 'Issuer' },
  { label: 'Broker', value: 'Broker' },
];

export const positions = [
  'Marketing',
  'Sales',
  'Distribution',
  'Credit Risk',
  'Credit Management',
  'Portfolio Manager',
  'Underwriter',
  'Controller',
  'Structuring',
  'Issuer',
  'Broker',
];

export const functions = [
  'Credit',
  'Due Diligence',
  'Analyst',
  'Trading',
  'Underwriting',
  'Market Data',
  'Research',
  'Monitoring',
];

export const options = [
  {
    value: 'Credit',
    label: 'Credit',
  },
  {
    value: 'Due Diligence',
    label: 'Due Diligence',
  },
  {
    value: 'Analyst',
    label: 'Analyst',
  },
  {
    value: 'Trading',
    label: 'Trading',
  },
  {
    value: 'UnderWriting',
    label: 'UnderWriting',
  },
  {
    value: 'Market Data',
    label: 'Market Data',
  },
  {
    value: 'Research',
    label: 'Research',
  },
  {
    value: 'Monitoring',
    label: 'Monitoring',
  },
];

export const roles = ['Indivudual', 'Organization'];

export const typesOptions = [
  {
    value: 'Seller',
    label: 'Seller',
  },
  {
    value: 'Buyer',
    label: 'Buyer',
  },
  {
    value: 'Data Provider',
    label: 'Data Provider',
  },
  {
    value: 'Intermediary',
    label: 'Intermediary',
  },
];
export const types = ['Seller', 'Buyer', 'Data Provider', 'Intermediary'];
export const products = [
  'Stock',
  'Bonds',
  'Funds',
  'Futures',
  'Options',
  'Swaps',
  'CDO',
  'ABS',
  'Leverage Loans',
];

export const productsArray = [
  {
    value: 'Stock',
    label: 'Stock',
  },
  {
    value: 'Bonds',
    label: 'Bonds',
  },
  {
    value: 'Funds',
    label: 'Funds',
  },
  {
    value: 'Futures',
    label: 'Futures',
  },
  {
    value: 'Options',
    label: 'Options',
  },
  {
    value: 'Swaps',
    label: 'Swaps',
  },
  {
    value: 'CDO',
    label: 'CDO',
  },
  {
    value: 'ABS',
    label: 'ABS',
  },
  {
    value: 'Leverage Loans',
    label: 'Leverage Loans',
  },
];

export const sizes = [
  '1 to 10',
  '11 to 50',
  '50 to 200',
  '200 to 1000',
  'Over 1000',
];

export const credits = [
  'Real Estate',
  'Receivables',
  'Tax',
  'Loans',
  'Mortgages',
  'Leasing',
  'Credit Cards',
];

export const creditsArray = [
  {
    value: 'Real Estate',
    label: 'Real Estate',
  },
  {
    value: 'Receivables',
    label: 'Receivables',
  },
  {
    value: 'Tax',
    label: 'Tax',
  },
  {
    value: 'Loans',
    label: 'Loans',
  },
  {
    value: 'Mortgages',
    label: 'Mortgages',
  },
  {
    value: 'Leasing',
    label: 'Leasing',
  },
  {
    value: 'Credit Cards',
    label: 'Credit Cards',
  },
];

export const sexOptions = [
  { label: 'Male', value: 'Male' },
  { label: 'Female', value: 'Female' },
];

export const sexes = ['Male', 'Female'];
