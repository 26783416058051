export const newsFeedWidgetConstant = {
  ADD_NEWS_FEED_DATA: 'ADD_NEWS_FEED_DATA',
  UPDATE_NEWS_FEED_DATA: 'UPDATE_NEWS_FEED_DATA',
  REMOVE_NEWS_FEED_DATA: 'REMOVE_NEWS_FEED_DATA',
  ADD_MOODY_NEWS_FEED_DATA: 'ADD_MOODY_NEWS_FEED_DATA',
  REMOVE_MOODY_NEWS_FEED_DATA: 'REMOVE_MOODY_NEWS_FEED_DATA',


  // For news endpoints
  SELECT_NEWS_ENDPOINT: 'SELECT_NEWS_ENDPOINT',
  CLEAR_SELECTED_NEWS_ENDPOINT: 'CLEAR_SELECTED_ENDPOINT',
};
