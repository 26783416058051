/* eslint-disable no-unused-vars */
import axios from 'axios';
import config from '../../../../../config';
import { isAxiosError } from '../../../../redux/constant';

const getChartsData = ({
  id,
  payload,
  chartFormValues,
  setIsLoading,
  showModal,
  addPortfolioVisualizationWidgetData,
  allPortfolioWidgetReduxData,
  navigate,
}: {
  id: string;
  payload: { fields: string[] };
  chartFormValues: any;
  setIsLoading: (loading: boolean) => void;
  showModal: (message: string) => void;
  addPortfolioVisualizationWidgetData: (data: any) => void;
  allPortfolioWidgetReduxData: any;
  navigate: (path: string) => void;
}) => {
  setIsLoading(true);
  axios
    .post(`${config.ER_ML_URL}/visualization/get-average/${id}`, payload)
    .then(({ data }) => {
      let responseData;
      try {
        responseData = JSON.parse(data?.data);
      } catch (e) {
        responseData = data?.data;
      }

      if (responseData?.error) {
        showModal(responseData.error);
        setIsLoading(false);
        return;
      }

      const chartsConfiguration = {
        ...chartFormValues,
        portfolioId: id,
        chartId: Date.now(),
        data: responseData || [],
      };

      addPortfolioVisualizationWidgetData({
        id: allPortfolioWidgetReduxData?.length,
        value: 'embedded_browser',
        widgetType: 'PORTFOLIO',
        ...chartsConfiguration,
      });

      document.body.classList.remove('show-dataitem');
      setIsLoading(false);
      navigate(`/portfolio`);
    })
    .catch(err => {
      setIsLoading(false);
      if (isAxiosError(err)) {
        showModal(
          (err.response?.data as { error?: string })?.error ||
            'Unexpected Axios error',
        );
      } else {
        showModal('something went wrong');
      }
    });
};

export default getChartsData;
