import React from 'react';

type ChartsHeadingsProps = {
  currentActiveTab: string;
};

const ChartsHeadings = ({ currentActiveTab }: ChartsHeadingsProps) => {
  return (
    <>
      {currentActiveTab === '2' && (
        <h4 className="title-20">
          Select a portfolio for the Portfolio Charts widget
        </h4>
      )}
      {currentActiveTab === '3' && (
        <h4 className="title-20">
          Select a portfolio for the Portfolio Charts widget
        </h4>
      )}
    </>
  );
};

export default ChartsHeadings;
