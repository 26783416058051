/* ACTIONS */
import { productsWidgetsConstant } from '../constant';

const {
  ADD_PRODUCT_STRUCTURE_DATA,
  REMOVE_PRODUCT_STRUCTURE_DATA,
  //ADD_SIMPLE_PRICING_DATA,
  //REMOVE_SIMPLE_PRICING_DATA,
  //ADD_DERIVATIVE_DATA,
  //REMOVE_DERIVATIVE_DATA
} = productsWidgetsConstant;

export const addProductStructureData = (data: any) => {
  return {
    type: ADD_PRODUCT_STRUCTURE_DATA,
    payload: {
      productSturctureWidgetData: data,
    },
  };
};

export const removeProductStructureData = (id: number) => {
  return {
    type: REMOVE_PRODUCT_STRUCTURE_DATA,
    payload: {
      removeId: id,
    },
  };
};
