import React, { /* useEffect, */ useRef /* useState */ } from 'react';
import Plot from 'react-plotly.js';
import { getLayoutConfig } from '../../chartsTheme';

const PieChart = ({
  chartData,
  chartTitle,
}: {
  chartData: any;
  chartTitle: string;
}) => {
  // const [updatedData, setUpdatedData] = useState([] as any);
  const layoutConfig = getLayoutConfig();

  const plotRef = useRef<any>(null);

  const resizeLayout = () => {
    plotRef.current.resizeHandler();
  };
  // console.log(chartData);
  return (
    <>
      {
        <div className="chart-wrapper" onMouseDown={e => e.stopPropagation()}>
          <Plot
            ref={plotRef}
            data={chartData}
            // data={updatedData}
            config={{
              scrollZoom: true,
              displaylogo: false,
              editable: true,
              responsive: true,
            }}
            layout={{
              title: chartTitle,
              ...layoutConfig,
            }}
            useResizeHandler={true}
            onRelayout={resizeLayout}
          />
        </div>
      }
    </>
  );
};

export default PieChart;
