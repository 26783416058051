import { UncontrolledTooltip } from 'reactstrap';
import { TooltipPropsType } from '../../../@types/CommonComponentType';

export const CustomTooltip = ({
  placement,
  target,
  content,
}: TooltipPropsType) => {
  return (
    <>
      <UncontrolledTooltip
        // autohide={false}
        // delay={{ show: 200, hide: 50000000 }}
        placement={placement}
        target={target}
      >
        {content ? (
          <div
            dangerouslySetInnerHTML={{
              __html: content,
            }}
          ></div>
        ) : (
          'Suggested by AI'
        )}
      </UncontrolledTooltip>
    </>
  );
};
