import { allocationCalculationConstant } from '../constant';

const {
  ADD_LOAN_ALLOCATION_DATA,
  ADD_NPL_ALLOCATION_DATA,
  ADD_RECEIVABLE_ALLOCATION_DATA,
} = allocationCalculationConstant;

const initialState: any = {
  allocationDataNPL: {} as any,
  allocationDataLOAN: {} as any,
  allocationDataRECEIVABLE: {} as any,
};

const AllocationCalculationReducer = (
  state = initialState,
  action: any,
): any => {
  switch (action.type) {
    case ADD_NPL_ALLOCATION_DATA:
      return {
        ...state,
        allocationDataNPL: action.payload.allocationDataNPL,
      };

    case ADD_LOAN_ALLOCATION_DATA:
      return {
        ...state,
        allocationDataLOAN: action.payload.allocationDataLOAN,
      };

    case ADD_RECEIVABLE_ALLOCATION_DATA:
      return {
        ...state,
        allocationDataRECEIVABLE: action.payload.allocationDataRECEIVABLE,
      };

    default:
      return state;
  }
};
export default AllocationCalculationReducer;
