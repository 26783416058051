import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
// import { IconCross } from '../public/Icons';
import React from 'react';

type UploadProgressModalProps = {
  modal: boolean;
  toggle: () => void;
  progress: number;
};

const UploadProgressModal = ({
  modal,
  toggle,
  progress,
}: UploadProgressModalProps) => {
  // const closeBtn = (
  //   <button className="close" onClick={toggle} type="button">
  //     <IconCross fill="#36567D" />
  //   </button>
  // );

  return (
    <Modal isOpen={modal} toggle={toggle} centered size="md">
      <ModalHeader toggle={toggle} close={progress >= 100}>
        Uploading File
      </ModalHeader>
      <ModalBody>
        <p className="mb-4">
          {' '}
          Hold tight! We’re cleaning your data. This can take up to a few
          minutes depending on file size.
        </p>
        <div className="progress">
          <div
            className="progress-bar"
            role="progressbar"
            style={{
              width: `${progress}%`,
              transition: 'width 0.4s ease',
            }}
            aria-valuenow={progress}
            aria-valuemin={0}
            aria-valuemax={100}
          >
            {progress}%
          </div>
        </div>
        {/* {progress === 100 && (
          <p className="mt-3 text-success">Upload complete!</p>
        )} */}
      </ModalBody>
      <ModalFooter className="justify-content-end" />
    </Modal>
  );
};

export default UploadProgressModal;
