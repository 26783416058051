import WidgetRankService from '../../services/widgetRank.service';
import { widgetrankConstant } from '../constant';
import { AppDispatchType } from '../store';

const { ADD_WIDGET_RANK, FAILURE_WIDGET_RANK } = widgetrankConstant;

const widgetRankService = new WidgetRankService();

export const getWidgetRank = () => {
  return async (dispatch: AppDispatchType) => {
    try {
      const { data } = await widgetRankService.getWidgetRank();
      dispatch({
        type: ADD_WIDGET_RANK,
        payload: {
          widgetRank: data.personalizesWidgetRank,
          message: 'Got the Widget Rank data',
        },
      });
    } catch (error) {
      let errorMessage = 'Failed to do something exceptional';
      if (error instanceof Error) {
        errorMessage = error.message;
      }
      dispatch({
        type: FAILURE_WIDGET_RANK,
        payload: {
          widgetRank: {},
          message: errorMessage,
        },
      });
    }
  };
};
