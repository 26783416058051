import { useEffect, useState } from 'react';
import { IconNetwork, IconStar } from '../../../components/public/Icons';
import { CustomTooltip } from '../../../components/ui/CustomTooltip';
import MarketPlaceService from '../../../services/marketplace.service';
import CommonOptionsDropdown from './DataMarketActionDropdown';
import useAction from '../../../components/hooks/useAction';
import { useSelector } from 'react-redux';
import { saveNewsSourceSelector } from '../../../redux/selector/MarketplaceSelector';

type NewsSourceListTypes = {
  _id: string;
  serviceName: string;
  serviceType: string;
  serviceUrl: string;
  isPaidService: boolean;
  id: string;
  endpointCount: number;
  isSystemDefined: boolean;
};

type NewsSourceProps = {
  // eslint-disable-next-line
  updateSourceId: (data: string) => void;
  toggleDataItemModal: () => void;
};

const marketplaceService = new MarketPlaceService();

const NewsSources = ({
  toggleDataItemModal,
  updateSourceId,
}: NewsSourceProps) => {
  const { showModal, addNewsSourceCount } = useAction();
  //open modal

  const [newsSourceList, setNewsSourceList] = useState<
    Array<NewsSourceListTypes>
  >([]);

  const isSaveSourceData = useSelector(saveNewsSourceSelector);

  const getAllNewsSources = () => {
    marketplaceService
      .getAllSourceList('NEWS_SOURCES')
      .then(({ data }) => {
        setNewsSourceList(data);
        addNewsSourceCount(data?.length || 0);
      })
      .catch(err => {
        showModal(err?.response?.data?.message);
      });
  };

  useEffect(() => {
    getAllNewsSources();
  }, [isSaveSourceData]);

  return (
    <>
      {newsSourceList?.map((newsData: NewsSourceListTypes) => (
        <div className="col" key={newsData?._id}>
          <div className="main-widget">
            <div className="react-grid-item">
              <div className="common-widget">
                <div className="common-widget-header">
                  <div className="common-widget-header-title">
                    <IconNetwork />
                    <h2>{newsData?.serviceName}</h2>
                  </div>
                  <div className="common-widget-header-action">
                    {/*Change below _id to id -> for remove and edit */}
                    <CommonOptionsDropdown
                      sourceId={newsData.id}
                      toggleDataItemModal={toggleDataItemModal}
                      updateSourceId={updateSourceId}
                      deleteSuccess={() => {
                        getAllNewsSources();
                      }}
                      isSystemDefined={newsData?.isSystemDefined}
                    />
                  </div>
                </div>
                <div className="common-widget-content">
                  <p className="gap-1">
                    {newsData?.serviceType}
                    {newsData?.isPaidService && (
                      <>
                        <span className="d-flex" id="tooltip7">
                          <IconStar />
                        </span>
                        <CustomTooltip placement="top" target="tooltip7" />
                      </>
                    )}
                  </p>

                  <div>
                    <p>Endpoints: {newsData?.endpointCount}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default NewsSources;
