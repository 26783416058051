import { userDetailsConstant } from '../constant';

const {
  SUCCESS_USER_DETAILS,
  SUCCESS_USER_DETAILS_UPDATE,
} = userDetailsConstant;

const initialState: any = {
  userDetails: {},
};

const UserDetailsReducer = (
  state = initialState,
  { type, payload }: any,
): any => {
  switch (type) {
    case SUCCESS_USER_DETAILS:
      return {
        userDetails: { ...payload.userDetails },
      };

    case SUCCESS_USER_DETAILS_UPDATE:
      return {
        ...state,
        userDetails: state.userDetails
          ? {
              ...state.userDetails,
              ...payload.updatedFields,
            }
          : null,
      };

    default:
      return state;
  }
};
export default UserDetailsReducer;
