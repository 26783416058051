export const rightArrow = {
        'width': 500,
        'height': 600,
        'path': 'M62.5 327.6L327.9 592.9c8.5 8.5 22.3 8.5 30.8 0l36.3-36.3c8.5-8.5 8.5-22.3 0-30.8L175.6 300 395 80.1c8.5-8.5 8.5-22.3 0-30.8l-36.3-36.3c-8.5-8.5-22.3-8.5-30.8 0L62.5 272.9c-8.5 8.5-8.5 22.3 0 30.8z'
}

export const leftArrow = {
        'width': 500,
        'height': 600,
        'path': 'M437.5 272.4L172.1 7.1c-8.5-8.5-22.3-8.5-30.8 0l-36.3 36.3c-8.5 8.5-8.5 22.3 0 30.8L324.4 300 105 519.9c-8.5 8.5-8.5 22.3 0 30.8l36.3 36.3c8.5 8.5 22.3 8.5 30.8 0l265.4-265.3c8.5-8.5 8.5-22.3 0-30.8z' 
}

export const rangeSlider = {
    'width': 500,
    'height': 600,
    'path': 'M50 250h400c17.67 0 32-14.33 32-32s-14.33-32-32-32H50c-17.67 0-32 14.33-32 32s14.33 32 32 32zm-32 50v100c0 17.67 14.33 32 32 32h50c17.67 0 32-14.33 32-32V300c0-17.67-14.33-32-32-32H50c-17.67 0-32 14.33-32 32zm382 0v100c0 17.67 14.33 32 32 32h50c17.67 0 32-14.33 32-32V300c0-17.67-14.33-32-32-32h-50c-17.67 0-32 14.33-32 32z'
  };
  