import React from 'react';
type PerformanceHeadingsProps = {
  currentActiveTab: string;
};
const PerformanceHeadings = ({
  currentActiveTab,
}: PerformanceHeadingsProps) => {
  return (
    <>
      {currentActiveTab === '2' && (
        <h4 className="title-20">
          Select a portfolio for the Portfolio Performance widget
        </h4>
      )}
      {currentActiveTab === '3' && (
        <h4 className="title-20">Define monitoring parameters and triggers</h4>
      )}
    </>
  );
};

export default PerformanceHeadings;
