import { _CreditWidget } from '../actions';
// import { storeWidget } from '../constant/portfolioWidget.constant';

// const { STORE_WIDGET } = storeWidget;

export type _widgets =
  | 'credits'
  | 'credits_copy'
  | 'npl'
  | 'npl_collateral'
  | 'npl_loan'
  | 'loan'
  | 'loan_loan'
  | 'loan_collateral'
  | 'receivable'
  | 'receivable_loan'
  | 'receivable_collateral'
  | 'embedded_browser';

interface IntialState {
  widgetCache: _widgets[];
}

const initialState: IntialState = {
  widgetCache: [],
};

const PortfolioWidgetReducer = (
  state = initialState,
  action: _CreditWidget,
): IntialState => {
  switch (action.type) {
    case 'STORE_WIDGET':
      return {
        ...state,
        widgetCache: [...state.widgetCache, action.payload],
      };
    default:
      return state;
  }
};

export default PortfolioWidgetReducer;
