import { useEffect, useState } from 'react';
import { IconCross, IconTick } from '../../../components/public/Icons';
import CustomSelect from '../../../components/ui/CustomSelect';
import DepartmentsActionDropdown from './DepartmentsActionDropdown';
import SettingsService from '../../../services/settings.service';
import {
  DepartmentData,
  GeneralTypes,
  OrganizationData,
} from '../../../../@types/SettingsType';
import useAction from '../../../components/hooks/useAction';
import axios, { AxiosError } from 'axios';
import AppLoader from '../../../components/loaders/AppLoader';
import useDebounce from '../../../components/hooks/useDebounce';
import { userData } from '../../../redux/selector/UserSelector';
import { useSelector } from 'react-redux';
import { TextConstants } from '../../../helpers/constants';
const options = [
  { label: '1 to 10', value: '1 to 10' },
  { label: '50 to 200', value: '50 to 200' },
  { label: '200 to 500', value: '200 to 500' },
  { label: '500 to 1000', value: '500 to 1000' },
  { label: '1000+', value: '1000+' },
];

const settingsService = new SettingsService();

const General = ({ userDetails }: GeneralTypes) => {
  const user = useSelector(userData);

  const { showModal } = useAction();

  const [isLoading, setIsLoading] = useState(false);

  const [
    organizationData,
    setOrganizationData,
  ] = useState<OrganizationData | null>(null);
  const [organizationName, setOrganizationName] = useState('');

  const [addDepartment, setAddDepartment] = useState(false);
  const [allDepartments, setAllDepartments] = useState<Array<
    DepartmentData
  > | null>(null);
  const [departmentName, setDepartmentName] = useState('');
  const [
    editingDepartment,
    setEditingDepartment,
  ] = useState<DepartmentData | null>(null);

  const [shouldOrganizationUpdate, setShouldOrganizationUpdate] = useState(
    false,
  );
  const debouncedUserInput = useDebounce(organizationName, 1000);

  const ShowDepartmentRow = () => {
    setAddDepartment(!addDepartment);
  };

  const isAxiosError = (error: any): error is AxiosError => {
    return axios.isAxiosError(error);
  };

  const getOrganizationData = async () => {
    try {
      const response = await settingsService.getOrganization(
        userDetails?.organizationId,
      );
      setOrganizationData(response?.data);
      setOrganizationName(response?.data?.organizationName);
    } catch (error) {
      if (isAxiosError(error)) {
        showModal(
          (error.response?.data as { error?: string })?.error ||
            'Unexpected Axios error',
        );
      } else {
        showModal('An unexpected error occurred');
      }
    }
  };

  const updateOrganizationData = async (
    organizationName?: string,
    size?: string,
  ) => {
    try {
      setIsLoading(true);
      const payload = {
        organizationId: userDetails?.organizationId,
        organizationName: organizationName as string,
        size: size as string,
      };
      await settingsService.updateOrganization(payload);
      getOrganizationData();
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if (isAxiosError(error)) {
        showModal(
          (error.response?.data as { error?: string })?.error ||
            'Unexpected Axios error',
        );
      } else {
        showModal('An unexpected error occurred');
      }
    }
  };

  const getAllDepartmentsData = async () => {
    setIsLoading(true);
    try {
      const response = await settingsService.getAllDepartments(
        userDetails?.organizationId,
      );
      setIsLoading(false);

      setAllDepartments(response?.data);
    } catch (error) {
      setIsLoading(false);

      if (isAxiosError(error)) {
        showModal(
          (error.response?.data as { error?: string })?.error ||
            'Unexpected Axios error',
        );
      } else {
        showModal('An unexpected error occurred');
      }
    }
  };

  const saveNewDepartment = async () => {
    try {
      setIsLoading(true);
      const payload = {
        organizationId: userDetails?.organizationId,
        departmentName: departmentName,
      };
      await settingsService.createDepartment(payload);
      getAllDepartmentsData();
      ShowDepartmentRow();
      setDepartmentName('');
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if (isAxiosError(error)) {
        showModal(
          (error.response?.data as { error?: string })?.error ||
            'Unexpected Axios error',
        );
      } else {
        showModal('An unexpected error occurred');
      }
    }
  };

  useEffect(() => {
    if (shouldOrganizationUpdate) {
      updateOrganizationData(organizationName, organizationData?.size);
    }
  }, [debouncedUserInput]);

  useEffect(() => {
    if (userDetails?.organizationId) {
      getOrganizationData();
      getAllDepartmentsData();
    }
  }, [userDetails?.organizationId]);

  const toggleRemoveDepartment = (department: DepartmentData) => {
    settingsService
      .removeDepartment(department?.id as string)
      .then(() => {
        getAllDepartmentsData();
      })
      .catch(err => {
        if (isAxiosError(err)) {
          showModal(
            (err.response?.data as { error?: string })?.error ||
              'Unexpected Axios error',
          );
        } else {
          showModal('An unexpected error occurred');
        }
      });
  };

  const toggleEditDepartment = (department: DepartmentData) => {
    setEditingDepartment(department);
    setDepartmentName(department?.department);
  };

  const updateNewDepartment = async () => {
    try {
      setIsLoading(true);
      const payload = {
        departmentId: editingDepartment?.id,
        departmentName: departmentName,
      };
      await settingsService.updateDepartment(payload);
      setEditingDepartment(null);
      getAllDepartmentsData();
      setDepartmentName('');
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if (isAxiosError(error)) {
        showModal(
          (error.response?.data as { error?: string })?.error ||
            'Unexpected Axios error',
        );
      } else {
        showModal('An unexpected error occurred');
      }
    }
  };

  return (
    <>
      {isLoading && <AppLoader />}
      <div className="user-form wc-scroll">
        <div className="user-form-item">
          <h5 className="fi-heading">Organisation</h5>
          <div className="row">
            <div className="col-12 col-lg-10 offset-2">
              <div className="row">
                <div className="col-12 col-lg-5">
                  <div className="form-group">
                    <label className="form-label">Name</label>
                    <input
                      type="text"
                      placeholder="Organisation Name"
                      className="form-control"
                      value={organizationName}
                      onChange={e => {
                        setShouldOrganizationUpdate(true);
                        setOrganizationName(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="col-12 col-lg-3">
                  <div className="form-group">
                    <label className="form-label">size</label>
                    <CustomSelect
                      isDisable={
                        user?.userRole === TextConstants.OWNER ? false : true
                      }
                      id="size_dropdown"
                      options={options}
                      value={{
                        label: organizationData?.size,
                        value: organizationData?.size,
                      }}
                      onChange={(e: any) => {
                        updateOrganizationData(
                          organizationData?.organizationName,
                          e.value,
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="user-form-item">
          <h5 className="fi-heading">Departments</h5>
          <div className="row">
            <div className="col-12 col-lg-10 offset-2">
              <div className="table-responsive">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">Department Name</th>
                      <th style={{ width: '160px' }} scope="col">
                        Members
                      </th>
                      {user?.userRole === TextConstants.OWNER && (
                        <th style={{ width: '48px' }}></th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {allDepartments?.map(item => (
                      <tr key={item.department}>
                        {editingDepartment?.id === item?.id ? (
                          <>
                            <td className="py-1">
                              <div className="flexbox-c gap-12">
                                <input
                                  type="text"
                                  placeholder="Enter new department name"
                                  className="form-control form-control-sm mw-260"
                                  value={departmentName}
                                  onChange={e => {
                                    setDepartmentName(e.target.value);
                                  }}
                                />
                                <button
                                  className="btn-clear flexbox-c gap-1 text-info"
                                  onClick={() => {
                                    setEditingDepartment(null);
                                    setDepartmentName('');
                                  }}
                                >
                                  <IconCross fill="#36567D" />
                                  Cancel
                                </button>
                                <button
                                  className="btn-clear flexbox-c gap-1 text-primary"
                                  onClick={() => {
                                    updateNewDepartment();
                                  }}
                                >
                                  <IconTick />
                                  Save
                                </button>
                              </div>
                            </td>
                            <td></td>
                            <td></td>
                          </>
                        ) : (
                          <>
                            <td>{item.department}</td>
                            <td>{item.count}</td>
                            {user?.userRole === TextConstants.OWNER && (
                              <td>
                                <DepartmentsActionDropdown
                                  toggleRemoveModal={() =>
                                    toggleRemoveDepartment(item)
                                  }
                                  toggleEditModal={() =>
                                    toggleEditDepartment(item)
                                  }
                                />
                              </td>
                            )}
                          </>
                        )}
                      </tr>
                    ))}

                    {addDepartment && (
                      <tr>
                        <td className="py-1">
                          <div className="flexbox-c gap-12">
                            <input
                              type="text"
                              placeholder="Enter new department name"
                              className="form-control form-control-sm mw-260"
                              value={departmentName}
                              onChange={e => {
                                setDepartmentName(e.target.value);
                              }}
                            />
                            <button
                              className="btn-clear flexbox-c gap-1 text-info"
                              onClick={() => {
                                ShowDepartmentRow();
                                setDepartmentName('');
                              }}
                            >
                              <IconCross fill="#36567D" />
                              Cancel
                            </button>
                            <button
                              className="btn-clear flexbox-c gap-1 text-primary"
                              onClick={() => {
                                saveNewDepartment();
                              }}
                            >
                              <IconTick />
                              Save
                            </button>
                          </div>
                        </td>
                        <td></td>
                        <td></td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              {user?.userRole === TextConstants.OWNER && (
                <div className="action-link">
                  <button
                    onClick={() => ShowDepartmentRow()}
                    className="btn-clear link"
                  >
                    Add Department
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default General;
