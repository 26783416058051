import { Collapse } from 'reactstrap';

import { useState } from 'react';
import {
  IconDataSource,
  IconError,
  IconInfo,
  IconMinusCircle,
  IconPlusCircle,
  IconStar,
} from '../../../../../../components/public/Icons';
import { CustomTooltip } from '../../../../../../components/ui/CustomTooltip';

const DataSourcesListing = () => {
  const [isCollapseOpen, setIsCollapseOpen] = useState(false);
  const toggleCollapse = () => setIsCollapseOpen(!isCollapseOpen);

  const DataSource = [
    {
      name: 'Moody Analytics',
      star: true,
    },
    {
      name: 'ThThomson Reuters Eikon',
      star: true,
    },
    {
      name: 'Bloomberg',
      star: true,
    },
    {
      name: 'The Warren Group',
    },
    {
      name: 'S&P Global Market Intelligence',
      star: true,
      warning: true,
    },
    {
      name: 'Federal Reserve Economic Data',
    },
    {
      name: 'World Bank Open Data',
    },
  ];

  // SELECT Data Source(S)
  const DataSourceList: any = [];

  DataSource.forEach((item, index) => {
    DataSourceList.push(
      <>
        <div className="col" key={index}>
          <input
            type="checkbox"
            name="select-portfolio"
            disabled={`${item.warning}` == 'true' ? true : false}
          />
          <div className="widget-card">
            <div className="flexbox-c gap-6">
              <IconDataSource />
              <p className={`${item.warning}` == 'true' ? 'text-warning' : ''}>
                {item.name}
              </p>
              {item.name == 'Equifax' && <IconError />}
              {item.star && (
                <>
                  <span className="d-flex zindex-1" id="tooltip10">
                    <IconStar />
                  </span>
                  <CustomTooltip placement="top" target="tooltip10" />
                </>
              )}
            </div>
          </div>
        </div>
      </>,
    );
  });

  return (
    <>
      <div className="addwidget-content mw-100">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-6">
            <div className="d-flex align-items-center">
              <p className="widget-category me-1 mb-0">SELECT Data Source(S)</p>
              <span className="d-flex" id="tooltip11">
                <IconInfo />
              </span>
              <CustomTooltip placement="top" target="tooltip11" />
            </div>
            <div className="create-widget create-widget-portfolio">
              <div className="widget-item">
                <div className="row row-cols-1">
                  <div className="col">
                    <input
                      type="checkbox"
                      name=""
                      onClick={toggleCollapse}
                      className="showonly"
                    />
                    <div className="widget-card">
                      <div className="flexbox-c gap-6">
                        <button className="btn-clear btn-icon btn-16">
                          {isCollapseOpen ? (
                            <IconMinusCircle />
                          ) : (
                            <IconPlusCircle />
                          )}
                        </button>
                        <p>Bloomberg</p>
                        <span className="d-flex zindex-1" id="tooltip103">
                          <IconStar />
                        </span>
                        <CustomTooltip placement="top" target="tooltip103" />
                      </div>
                    </div>
                    <Collapse isOpen={isCollapseOpen}>
                      <div className="collapse-item">
                        <input type="checkbox" name="sub-options" />
                        <div className="widget-card">
                          <div className="flexbox-c gap-6">
                            <p>Bloomberg</p>
                          </div>
                        </div>
                      </div>
                      <div className="collapse-item">
                        <input type="checkbox" name="sub-options" />
                        <div className="widget-card">
                          <div className="flexbox-c gap-6">
                            <p>Bloomberg</p>
                            <span className="d-flex zindex-1" id="tooltip102">
                              <IconStar />
                            </span>
                            <CustomTooltip
                              placement="top"
                              target="tooltip102"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="collapse-item">
                        <input type="checkbox" name="sub-options" />
                        <div className="widget-card">
                          <div className="flexbox-c gap-6">
                            <p>Bloomberg</p>
                          </div>
                        </div>
                      </div>
                    </Collapse>
                  </div>
                  {DataSourceList}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DataSourcesListing;
