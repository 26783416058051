/* ACTIONS */
import { NewsEndpointListTypes } from '../../../@types/NewsWidgetType';
import { dashboardWidgetConstant } from '../constant';

const {
  ADD_NEWS_DASHBOARD_FEED_DATA,
  REMOVE_NEWS_DASHBOARD_FEED_DATA,
  ADD_MOODY_NEWS_FEED_DATA,
  REMOVE_MOODY_NEWS_FEED_DATA,
  ADD_TASKS_WIDGET_DATA,
  REMOVE_TASKS_WIDGET_DATA,
  ADD_NPL_FINANCIAL_WIDGET_DATA,
  REMOVE_NPL_FINANCIAL_WIDGET_DATA,
  ADD_TRANSACTION_REPORTS_WIDGET_DATA,
  REMOVE_TRANSACTION_REPORTS_WIDGET_DATA,
  ADD_EMBEDDED_BROWSER_WIDGET_DATA,
  REMOVE_EMBEDDED_BROWSER_WIDGET_DATA,
  CLEAR_DASHBOARD_SELECTED_NEWS_ENDPOINT,
  SELECT_DASHBOARD_NEWS_ENDPOINT,
  REMOVE_DASHBOARD_NEWS_ENDPOINT,
  ADD_PERFORMANCE_PORTFOLIO_DATA,
  REMOVE_PERFORMANCE_PORTFOLIO_DATA,
} = dashboardWidgetConstant;

export const addDashboardNewsFeedData = (data: any) => {
  return {
    type: ADD_NEWS_DASHBOARD_FEED_DATA,
    payload: {
      newsFeedWidgetData: data,
    },
  };
};

export const removeDashboardNewsFeedData = (id: number) => {
  return {
    type: REMOVE_NEWS_DASHBOARD_FEED_DATA,
    payload: {
      removeId: id,
    },
  };
};

export const addDashboardPerformancePortfolioData = (data: any) => {
  return {
    type: ADD_PERFORMANCE_PORTFOLIO_DATA,
    payload: {
      performancePortfolioData: data,
    },
  };
};

export const removeDashboardPerformancePortfolioData = (id: number) => {
  return {
    type: REMOVE_PERFORMANCE_PORTFOLIO_DATA,
    payload: {
      removeId: id,
    },
  };
};
export const addDashboardMoodyNewsFeedData = (data: any) => {
  return {
    type: ADD_MOODY_NEWS_FEED_DATA,
    payload: {
      moodyNewsFeedWidgetData: data,
    },
  };
};

export const removeDashboardMoodyNewsFeedData = (id: number) => {
  return {
    type: REMOVE_MOODY_NEWS_FEED_DATA,
    payload: {
      removeId: id,
    },
  };
};

export const addDashboardTasksData = (data: any) => {
  return {
    type: ADD_TASKS_WIDGET_DATA,
    payload: {
      tasksWidgetData: data,
    },
  };
};

export const removeDashboardTasksData = (id: number) => {
  return {
    type: REMOVE_TASKS_WIDGET_DATA,
    payload: {
      removeId: id,
    },
  };
};

export const addDashboardNplFinancialsData = (data: any) => {
  return {
    type: ADD_NPL_FINANCIAL_WIDGET_DATA,
    payload: {
      nplFinancialWidgetData: data,
    },
  };
};

export const removeDashboardNplFinancialsData = (id: number) => {
  return {
    type: REMOVE_NPL_FINANCIAL_WIDGET_DATA,
    payload: {
      removeId: id,
    },
  };
};

export const addDashboardTransactionReportsData = (data: any) => {
  return {
    type: ADD_TRANSACTION_REPORTS_WIDGET_DATA,
    payload: {
      transactionReportsWidgetData: data,
    },
  };
};

export const removeDashboardTransactionReportsData = (id: number) => {
  return {
    type: REMOVE_TRANSACTION_REPORTS_WIDGET_DATA,
    payload: {
      removeId: id,
    },
  };
};

export const addDashboardEmbeddedBrowserData = (data: any) => {
  return {
    type: ADD_EMBEDDED_BROWSER_WIDGET_DATA,
    payload: {
      embeddedBrowserWidgetData: data,
    },
  };
};

export const removeDashboardEmbeddedBrowserData = (id: number) => {
  return {
    type: REMOVE_EMBEDDED_BROWSER_WIDGET_DATA,
    payload: {
      removeId: id,
    },
  };
};

// For dashboard news endpoints

export const selectEndDashboardNewsPoint = (data: NewsEndpointListTypes) => {
  return {
    type: SELECT_DASHBOARD_NEWS_ENDPOINT,
    payload: { selectedNewsWidgetData: data },
  };
};

export const removeSelectedEndDashboardNewsFeedData = (id: number) => {
  return {
    type: CLEAR_DASHBOARD_SELECTED_NEWS_ENDPOINT,
    payload: {
      removeId: id,
    },
  };
};

export const removeAllEndDashboardNewsFeedData = () => {
  return {
    type: REMOVE_DASHBOARD_NEWS_ENDPOINT,
  };
};
