import { UncontrolledCollapse } from 'reactstrap';
import CustomSelect from '../../../../../../components/ui/CustomSelect';
import { IconDropdownArrow } from '../../../../../../components/public/Icons';
import { ReactNode, useEffect, useState } from 'react';
import config from '../../../../../../../config';
import axios from 'axios';
import useAction from '../../../../../../components/hooks/useAction';
import useDebounce from '../../../../../../components/hooks/useDebounce';
import DataLoader from '../../../../../../components/loaders/DataLoader';
import DataTable from '../../../../../../components/ui/DataTable';

type selectedDataSourceType = {
  file: string;
  subFile: string;
  type: string;
};

type KnowledgeBaseTextareaProps = {
  selectedDataSourcesWidget: selectedDataSourceType[];
  knowFiles: string;
  knowIndex: number;
  roleOptions: {
    value: string;
    label: ReactNode;
  }[];
  selectedKnowledgeBaseFile: {
    fileName: string;
    jsonData: string | { [key: string]: string | number } | {};
  }[];
  setSelectedKnowledgeBaseFile: any;
};

type DataType = {
  [key: string]: string | number | DataType; // Recursive type
};

const KnowledgeBaseTextarea = ({
  knowFiles,
  knowIndex,
  roleOptions,
  selectedKnowledgeBaseFile,
  setSelectedKnowledgeBaseFile,
  selectedDataSourcesWidget,
}: KnowledgeBaseTextareaProps) => {
  const { showModal } = useAction();
  const [query, setQuery] = useState('');
  const [summary, setSummary] = useState<DataType | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSummaryCalled, setIsSummaryCalled] = useState(false);
  const debouncedUserInput = useDebounce(query, 3000);

  const getKnowledgeSummary = async () => {
    setIsLoading(true);
    try {
      const payload = {
        query: query,
        file: knowFiles,
      };
      const response = await axios({
        method: 'post',
        url: `${config.ER_ML_URL}/file/query`,
        data: payload,
      });
      const responseManipulation = await response?.data?.message;

      let tempArray = [...selectedKnowledgeBaseFile];

      let kIndex = tempArray.findIndex(x => x.fileName === knowFiles);

      if (kIndex > -1) {
        tempArray[kIndex] = {
          ...tempArray[kIndex],
          jsonData:
            isFlatObject(responseManipulation) ||
            isNestedObject(responseManipulation)
              ? responseManipulation
              : {},
        };
      } else {
        tempArray.push({
          fileName: knowFiles,
          jsonData:
            isFlatObject(responseManipulation) ||
            isNestedObject(responseManipulation)
              ? responseManipulation
              : {},
        });
      }

      const filteredData = tempArray.filter(
        item => Object.keys(item.jsonData).length > 0,
      );

      setSelectedKnowledgeBaseFile(filteredData);
      setIsSummaryCalled(true);
      setSummary(responseManipulation);
      setIsLoading(false);
    } catch (error) {
      showModal('something went wrong');
      setIsLoading(false);
    }
  };

  // Function to check if an object is flat
  const isFlatObject = (obj: DataType): boolean => {
    // First check if the object has at least one key-value pair
    if (Object.keys(obj).length === 0) {
      return false; // Return false if the object is empty
    }

    // Check if all values are primitive (not objects or null)
    return Object.values(obj).every(
      value => typeof value !== 'object' && value !== null,
    );
  };

  // Function to check if an object is nested (at least one value is an object)
  const isNestedObject = (obj: DataType): boolean => {
    return Object.values(obj).some(
      value => typeof value === 'object' && value !== null,
    );
  };

  useEffect(() => {
    if (query) getKnowledgeSummary();
  }, [debouncedUserInput]);

  return (
    <div className="col" key={knowIndex}>
      <div className="position-relative">
        <input
          type="checkbox"
          name="select-file"
          id={`toggler${knowIndex}`}
          checked={
            selectedDataSourcesWidget?.some(x => x?.file === knowFiles) || false
          }
          onClick={() => {
            let tempArr = [...(selectedKnowledgeBaseFile || [])];

            const valIndex = tempArr.findIndex(x => x?.fileName === knowFiles);

            if (valIndex > -1) {
              tempArr.splice(valIndex, 1);
            } else {
              tempArr.push({
                fileName: knowFiles,
                jsonData:
                  summary && (isFlatObject(summary) || isNestedObject(summary))
                    ? summary
                    : {},
              });
            }

            const filteredData = tempArr.filter(
              item => Object.keys(item.jsonData).length > 0,
            );

            setSelectedKnowledgeBaseFile?.(filteredData || []);
          }}
        />
        <div className="widget-card">
          <p>{knowFiles}</p>
        </div>
      </div>
      <UncontrolledCollapse toggler={`#toggler${knowIndex}`}>
        <div className="form-group mb-0 input-ai-tool">
          <div className="position-relative">
            <textarea
              className="form-control"
              placeholder="Which data would you like to be extracted or summarized?"
              rows={3}
              name="summarized"
              value={query}
              onChange={e => {
                setQuery(e.target.value);
              }}
            />
            {roleOptions?.length > 0 && (
              <div className="ai-tools">
                <div className="select-clear">
                  <CustomSelect
                    options={roleOptions}
                    value={roleOptions[0]}
                    isSearchable={false}
                    render
                  />
                  <div className="arrow-icon">
                    <IconDropdownArrow />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </UncontrolledCollapse>
      {isLoading ? (
        <DataLoader />
      ) : (
        <p>
          {isSummaryCalled && (
            <>
              {summary && (isNestedObject(summary) || isFlatObject(summary)) ? (
                <DataTable data={summary} />
              ) : (
                'No Data Found!'
              )}
            </>
          )}
        </p>
      )}
    </div>
  );
};

export default KnowledgeBaseTextarea;
