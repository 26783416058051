import { useDispatch, useSelector } from 'react-redux';
import {
  NewsFeedWidgetDataTypes,
  SelectNewsSourceProps,
} from '../../../../../../@types/NewsWidgetType';
import { IconInfo } from '../../../../../components/public/Icons';
import { CustomTooltip } from '../../../../../components/ui/CustomTooltip';
import EndpointNewsCollapse from '../../../newsfeed/customComponents/EndpointNewsCollapse';
import {
  newsFeedWidgetsDataSelector,
  selectedNewsEndpointSelector,
} from '../../../../../redux/selector/NewsFeedWidgetsSelector';
import useAction from '../../../../../components/hooks/useAction';

const SelectWidgetNewsSources = ({
  newsSourceList,
  selectedId,
  selectedSourceDataId
}: SelectNewsSourceProps) => {

  const { updateNewsFeedData } = useAction();
  const dispatch = useDispatch();
  const selectedEndpoint = useSelector(selectedNewsEndpointSelector);
  const newsFeedData = useSelector(newsFeedWidgetsDataSelector);

  const handleSaveChanges = () => {
    const updatedNewsSource = newsFeedData?.find(
      (source: NewsFeedWidgetDataTypes) =>
        source?.selectedNewsId === selectedId,
    );
    if (updatedNewsSource) {
      const updatedData: NewsFeedWidgetDataTypes = {
        ...updatedNewsSource,
        selectedNewsId: selectedEndpoint.id,
        selectedNewsName: selectedEndpoint?.sourceName,
        selectedNewsSourceId: selectedEndpoint?.sourceId,
      };

      dispatch(updateNewsFeedData(updatedNewsSource?.id, updatedData));
    }
  };

  return (
    <div className="widget-tabs-content">
      <div className="addwidget-wrapper">
        <div className="container container-1024">
          <div className="addwidget-header">
            <h4 className="title-20 text-center">
              Add or remove data sources you’d like to expose to your widget
            </h4>
          </div>
          <div className="addwidget-content mw-100">
            <div className="row row-divider justify-content-center">
              <div className="col-12 col-lg-6">
                <div className="d-flex align-items-center">
                  <p className="widget-category me-1 mb-0">NEW SOURCE(S)</p>
                  <span className="d-flex" id="tooltipnewitem13">
                    <IconInfo />
                  </span>
                  <CustomTooltip placement="top" target="tooltipnewitem13" />

                  {/* <button
                    className="ms-3 btn-clear link"
                    // onClick={toggleAddItemPortfolio}
                  >
                    Add item
                  </button> */}
                </div>
                <div className="create-widget create-widget-portfolio">
                  <div className="widget-item">
                    <div className="row row-cols-1">
                      {newsSourceList?.length > 0
                        ? newsSourceList?.map(sourceData => (
                            <EndpointNewsCollapse
                              sourceData={sourceData}
                              selectedId={selectedId}
                              selectedSourceDataId={selectedSourceDataId}
                            />
                          ))
                        : 'No Record Found!'}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="gap-12 d-flex justify-content-center">
            <button
              className="btn btn-primary"
              disabled={selectedEndpoint?.id === selectedId}
              onClick={() => handleSaveChanges()}
            >
              Save changes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectWidgetNewsSources;
