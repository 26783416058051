export const DEFAULTFIELDTYPE = 'text';

export const __LocalStorage = {
  colors: 'colorsToShow',
  themeType: 'themeType',
  custom: 'custom',
  portfolioCharts: 'portfolioCharts',
  chartsGridLayout: 'chartsGridLayout',
};

export const TextConstants = {
  OWNER: 'OWNER',
  MEMBER: 'MEMBER',
  ADMIN: 'ADMIN',
};
