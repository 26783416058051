import React from 'react';

type AnalyticsWidgetHeadingProps = {
  currentActiveTab: string;
};

const AnalyticsWidgetHeading = ({
  currentActiveTab,
}: AnalyticsWidgetHeadingProps) => {
  return (
    <>
      {currentActiveTab === '2' && (
        <h4 className="title-20">
          Select portfolio for the Visualisation widget
        </h4>
      )}
      {currentActiveTab === '3' && (
        <h4 className="title-20">Select visualisation type and parameters</h4>
      )}
    </>
  );
};

export default AnalyticsWidgetHeading;
