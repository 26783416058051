import { useEffect, useState } from 'react';
import { PortfolioProps } from '../../../../../../../@types/Widget';
import IngestionService from '../../../../../../services/ingestion.service';
import useAction from '../../../../../../components/hooks/useAction';
import axios from 'axios';
import config from '../../../../../../../config';
import { IconInfo } from '../../../../../../components/public/Icons';
import { CustomTooltip } from '../../../../../../components/ui/CustomTooltip';
import ContentLoader from '../../../../../../components/loaders/ContentLoader';
import InfiniteScroll from 'react-infinite-scroll-component';
import DataLoader from '../../../../../../components/loaders/DataLoader';

const PortfolioListing = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [fileNamesPage, setFileNamesPage] = useState(1);
  const [portfolio, setPortfolio] = useState<PortfolioProps>(
    {} as PortfolioProps,
  );

  const [selectedPortfolio, setSelectedPortfolio] = useState<string[]>([]);
  const ingService = new IngestionService();

  const { showModal } = useAction();

  const SELECT_PORTFOLIO = [
    {
      name: 'Credit portfolio 2024-02-20T19:12:34.638Z',
    },
    {
      name: 'Credit portfolio 2024-02-20T19:18:45.638Z',
    },
    {
      name: 'Credit portfolio 2024-02-20T19:18:45.638Z',
    },
    {
      name: 'Credit portfolio 2024-02-20T20:18:22.638Z',
    },
    {
      name: 'Credit portfolio 2024-02-12T08:20:44.638Z',
    },
    {
      name: 'Credit portfolio 2024-02-15T20:18:34.638Z',
    },
    {
      name: 'Credit portfolio 2024-02-19T05:16:11.638Z',
    },
    {
      name: 'Credit portfolio 2024-02-19T05:14:11.638Z',
    },
    {
      name: 'Credit portfolio 2024-02-19T05:12:11.638Z',
    },
    {
      name: 'Credit portfolio 2024-02-19T05:10:11.638Z',
    },
    {
      name: 'Credit portfolio 2024-02-19T05:8:11.638Z',
    },
    {
      name: 'Credit portfolio 2024-02-19T05:6:11.638Z',
    },
    {
      name: 'Credit portfolio 2024-02-19T05:4:11.638Z',
    },
    {
      name: 'Credit portfolio 2024-02-19T05:2:11.638Z',
    },
    {
      name: 'Credit portfolio 2024-02-19T05:1:11.638Z',
    },
    {
      name: 'Credit portfolio 2024-02-19T05:2:11.638Z',
    },
    {
      name: 'Credit portfolio 2024-02-19T05:3:11.638Z',
    },
    {
      name: 'Credit portfolio 2024-02-19T05:4:11.638Z',
    },
  ];

  // SELECT PORTFOLIO(S)
  const portfolioList: any = [];

  SELECT_PORTFOLIO.forEach((item, index) => {
    portfolioList.push(
      <div className="col" key={index}>
        <input type="checkbox" name="select-portfolio" />
        <div className="widget-card">
          <p>{item.name}</p>
        </div>
      </div>,
    );
  });

  const handleUploadFile = async (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    const file = e.target.files[0];

    if (file) {
      setIsLoading(true);

      try {
        const formData = new FormData();

        formData.append('uploaded_file', e.target.files[0]);

        ingService
          .uploadCreditWidgetFile(formData)
          .then(() => {
            //addDefaultCategoryForHeaders(data.headingSchema, data);
            showModal('Uploaded successfully!', true);
            getPortfolio(false);
            setIsLoading(false);
          })
          .catch(() => {
            setIsLoading(false);
            showModal('something went wrong');
          });
      } catch (e) {
        setIsLoading(false);
      }
    }
    e.target.value = null;
  };

  const getPortfolio = async (isPageChange: boolean) => {
    try {
      const response = await axios({
        method: 'get',
        url: `${config.ER_ML_URL}/portfolio/?limit=10&page=${fileNamesPage}`,
      });
      const responseManipulation = !isPageChange
        ? response.data
        : Object.keys(portfolio).length > 0
        ? {
            ...portfolio,
            data: [...(portfolio?.data || {}), ...(response.data.data || {})],
          }
        : response.data;

      setPortfolio(responseManipulation);
      if (isPageChange) {
        setFileNamesPage(fileNamesPage + 1);
      }
    } catch (error) {
      showModal('something went wrong');
    }
  };

  useEffect(() => {
    getPortfolio(true);
  }, []);
  return (
    <>
      <div className="addwidget-content mw-100">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-6">
            <div className="d-flex align-items-center">
              <p className="widget-category me-1 mb-0">SELECT PORTFOLIO(S)</p>
              <span className="d-flex" id="tooltip13">
                <IconInfo />
              </span>
              <CustomTooltip placement="top" target="tooltip13" />
              <input
                type="file"
                className="d-none"
                id="uploadportfolio"
                onChange={handleUploadFile}
              />
              <label htmlFor="uploadportfolio" className="ms-3 link">
                Upload new portfolio
              </label>
            </div>
            <div
              className="create-widget create-widget-portfolio"
              id="scrollableDiv"
            >
              <div className="widget-item">
                <div className="row row-cols-1">
                  {isLoading ? (
                    <ContentLoader
                      title=""
                      styleProps={{ width: '48%', marginTop: '15px' }}
                    />
                  ) : (
                    <>
                      <InfiniteScroll
                        dataLength={portfolio?.data?.length || 0}
                        next={() => getPortfolio(true)}
                        hasMore={portfolio?.data?.length <= 0 ? false : true}
                        loader={
                          portfolio?.data?.length <= 0 ? (
                            <div style={{ paddingLeft: '12px' }}>
                              Loading...
                            </div>
                          ) : null
                        }
                        scrollableTarget="scrollableDiv"
                      >
                        {portfolio?.data &&
                          portfolio?.data.length > 0 &&
                          portfolio?.data?.map((item, index) => {
                            return (
                              <div className="col" key={index}>
                                <input
                                  type="checkbox"
                                  name="select-portfolio"
                                  onClick={() => {
                                    const isExist = selectedPortfolio.find(
                                      ele => ele === item._id,
                                    );
                                    if (isExist) {
                                      const filterIsExist = selectedPortfolio.filter(
                                        selected => selected !== item._id,
                                      );
                                      setSelectedPortfolio(filterIsExist);
                                    } else {
                                      const duplicate = [...selectedPortfolio];
                                      duplicate.push(item._id);
                                      setSelectedPortfolio(duplicate);
                                    }
                                  }}
                                />
                                <div className="widget-card">
                                  <p>{item.fileName}</p>
                                </div>
                              </div>
                            );
                          })}
                      </InfiniteScroll>
                      {isLoading && <DataLoader />}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PortfolioListing;
