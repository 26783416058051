import { reloadPortfolioConstant } from '../constant';

const { IS_PORTFOLIO_SAVED, PORTFOLIO_NOT_SAVED } = reloadPortfolioConstant;

export const reloadPortfolio = (val: boolean) => {
  return {
    type: IS_PORTFOLIO_SAVED,
    payload: { isReload: val },
  };
};

export const notReloadPortfolio = () => {
  return {
    type: PORTFOLIO_NOT_SAVED,
    payload: { isReload: false },
  };
};
