export const portfolioWidgetsConstant = {
  ADD_CREDIT_WIDGET_DATA: 'ADD_CREDIT_WIDGET_DATA',
  REMOVE_CREDIT_WIDGET_DATA: 'REMOVE_NEWS_FEED_DATA',

  ADD_NPL_WIDGET_DATA: 'ADD_NPL_WIDGET_DATA',
  REMOVE_NPL_WIDGET_DATA: 'REMOVE_NPL_WIDGET_DATA',
  ADD_NPL_COLLATERAL_WIDGET_DATA: 'ADD_NPL_COLLATERAL_WIDGET_DATA',
  REMOVE_NPL_COLLATERAL_WIDGET_DATA: 'REMOVE_NPL_COLLATERAL_WIDGET_DATA',
  ADD_NPL_LOAN_WIDGET_DATA: 'ADD_NPL_LOAN_WIDGET_DATA',
  REMOVE_NPL_LOAN_WIDGET_DATA: 'REMOVE_NPL_LOAN_WIDGET_DATA',

  ADD_LOAN_WIDGET_DATA: 'ADD_LOAN_WIDGET_DATA',
  REMOVE_LOAN_WIDGET_DATA: 'REMOVE_LOAN_WIDGET_DATA',
  ADD_LOAN_COLLATERAL_WIDGET_DATA: 'ADD_LOAN_COLLATERAL_WIDGET_DATA',
  REMOVE_LOAN_COLLATERAL_WIDGET_DATA: 'REMOVE_LOAN_COLLATERAL_DATA',
  ADD_LOAN_LOAN_WIDGET_DATA: 'ADD_LOAN_LOAN_WIDGET_DATA',
  REMOVE_LOAN_LOAN_WIDGET_DATA: 'REMOVE_LOAN_LOAN_WIDGET_DATA',

  ADD_RECEIVABLE_WIDGET_DATA: 'ADD_RECEIVABLE_WIDGET_DATA',
  REMOVE_RECEIVABLE_WIDGET_DATA: 'REMOVE_RECEIVABLE_WIDGET_DATA',
  ADD_RECEIVABLE_COLLATERAL_WIDGET_DATA:
    'ADD_RECEIVABLE_COLLATERAL_WIDGET_DATA',
  REMOVE_RECEIVABLE_COLLATERAL_WIDGET_DATA: 'REMOVE_RECEIVABLE_COLLATERAL_DATA',
  ADD_RECEIVABLE_LOAN_WIDGET_DATA: 'ADD_RECEIVABLE_LOAN_WIDGET_DATA',
  REMOVE_RECEIVABLE_LOAN_WIDGET_DATA: 'REMOVE_RECEIVABLE_LOAN_WIDGET_DATA',
  REFRESH_PORTFOLIO_PAGE: 'REFRESH_PORTFOLIO_PAGE',

  // For Visualization data
  ADD_VISUALIZATION_WIDGET_DATA: 'ADD_VISUALIZATION_WIDGET_DATA',
  REMOVE_VISUALIZATION_WIDGET_DATA: 'REMOVE_VISUALIZATION_WIDGET_DATA',
};
