import React from 'react';
import {
  // IconEye,
  IconStar,
  IconTick,
} from '../../../../../components/public/Icons';
import CustomSelect from '../../../../../components/ui/CustomSelect';

type AddNameAndTypeProps = {
  serviceName: string;
  serviceType: {
    label: string;
    value: string;
  };
  isPaidService: boolean;
  modelName?: {
    label: string;
    value: string;
  } | null;
  apiKey?: string;
  azureApiVersion?: string;
  azureEndpoint?: string;
  azureDeploymentName?: string;
  isBuildConnection: boolean;
  isBuildConnectionRunning: boolean;
  isBuildConnectionError: boolean;
  isInvalid: boolean;
  setIsInvalid: React.Dispatch<React.SetStateAction<boolean>>;

  // eslint-disable-next-line
  updateServiceName: (data: string) => void;
  // eslint-disable-next-line
  updateServiceType: (data: { label: string; value: string }) => void;
  // eslint-disable-next-line
  updateIsPaidService: (data: boolean) => void;
  // eslint-disable-next-line
  updateModelName: (data: { label: string; value: string }) => void;
  // eslint-disable-next-line
  updateApiKeyValue: (data: string) => void;
  // eslint-disable-next-line
  updateAzureApiVersion?: (data: string) => void;
  // eslint-disable-next-line
  updateAzureEndpoint?: (data: string) => void;
  // eslint-disable-next-line
  updateAzureDeploymentName?: (data: string) => void;
  // eslint-disable-next-line
  buildConnectionFoundationalModel: () => void;
};

const AddNameAndType = ({
  serviceName,
  serviceType,
  isPaidService,
  modelName,
  apiKey,
  azureApiVersion,
  azureEndpoint,
  azureDeploymentName,
  isBuildConnection,
  isBuildConnectionError,
  isBuildConnectionRunning,
  isInvalid,
  setIsInvalid,
  updateServiceName,
  updateServiceType,
  updateIsPaidService,
  updateModelName,
  updateApiKeyValue,
  updateAzureApiVersion,
  updateAzureEndpoint,
  updateAzureDeploymentName,
  buildConnectionFoundationalModel,
}: AddNameAndTypeProps) => {
  const options = [
    { label: 'Data Source', value: 'DATA_SOURCE' },
    { label: 'Augumentation data source', value: 'AUGMENTED_DATA_SOURCE' },
    { label: 'News source', value: 'NEWS_SOURCES' },
    { label: 'Foundational modals', value: 'FOUNDATIONAL_MODALS' },
  ];

  const modelOptions = [
    { label: 'AzureOpenAI', value: 'azure' },
    { label: 'Gpt4', value: 'openai' },
    { label: 'Gemini', value: 'gemini' },
    { label: 'Claude', value: 'claude' },
    { label: 'Mistral', value: 'mistral' },
    { label: 'Llama', value: 'llama' },
  ];

  // const returnDataItemType = (data: string) => {
  //   if (data === 'DATA_SOURCE') {
  //     return { label: 'Data Source', value: 'DATA_SOURCE' };
  //   }

  //   if (data === 'AUGMENTED_DATA_SOURCE') {
  //     return {
  //       label: 'Augumentation data source',
  //       value: 'AUGMENTED_DATA_SOURCE',
  //     };
  //   }

  //   if (data === 'NEWS_SOURCES') {
  //     return { label: 'News source', value: 'NEWS_SOURCES' };
  //   }

  //   if (data === 'FOUNDATIONAL_MODALS') {
  //     return { label: 'Foundational modals', value: 'FOUNDATIONAL_MODALS' };
  //   }
  // };

  return (
    <div className="addwidget-content mw-100">
      <div className="row justify-content-center">
        <div className="col-12 col-lg-6">
          <div className="create-widget dm-widget">
            <div className="">
              {/* widget-item */}
              <div className="form-group">
                <p className="text-end font-12">*All fields are required</p>
              </div>
              <div className="form-group flexbox-c gap-3">
                <div className="flex-fill">
                  <label className="form-label">Data Item Type</label>

                  <CustomSelect
                    options={options}
                    // defaultValue={options[0]}
                    value={serviceType}
                    isClearable
                    onChange={(e: { label: string; value: string }) => {
                      updateServiceType(e);
                    }}
                  />
                </div>
                <div className="flexbox-c gap-3 label-empty">
                  <div className="form-check form-check-inline m-0 fc-rounded">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="freeService"
                      id="freeservice"
                      checked={!isPaidService}
                      onChange={() => {
                        updateIsPaidService(false);
                      }}
                    />
                    <label
                      className="form-check-label ms-0"
                      htmlFor="freeservice"
                    >
                      Free
                    </label>
                  </div>
                  <div className="form-check form-check-inline m-0 fc-rounded">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="paidService"
                      id="paidservice"
                      checked={isPaidService}
                      onChange={() => {
                        updateIsPaidService(true);
                      }}
                    />
                    <label
                      className="form-check-label flexbox-c gap-1 ms-0"
                      htmlFor="paidservice"
                    >
                      Paid <IconStar />
                    </label>
                  </div>
                </div>
              </div>

              <div className="form-group">
                <label className="form-label">Name</label>
                {serviceType.value === 'FOUNDATIONAL_MODALS' ? (
                  <CustomSelect
                    options={modelOptions}
                    value={modelName}
                    defaultValue={modelName}
                    isClearable
                    onChange={(e: { label: string; value: string }) => {
                      updateModelName(e);
                    }}
                  />
                ) : (
                  <input
                    id="serviceName"
                    type="text"
                    placeholder="Enter name"
                    className="form-control"
                    // defaultValue={'The Warren Group'}
                    value={serviceName}
                    onChange={e => {
                      updateServiceName(e.target.value);
                    }}
                  />
                )}
              </div>
              {serviceType.value === 'FOUNDATIONAL_MODALS' && (
                <>
                  <div className="form-group mb-3">
                    <label className="form-label mb-0">Authentication</label>
                  </div>

                  <div className="form-group mb-1">
                    <label>Auth URL</label>
                    <input
                      id="authURL"
                      type="text"
                      placeholder="Enter unique API key"
                      className="form-control"
                      value={apiKey}
                      onChange={e => {
                        updateApiKeyValue(e.target.value);
                      }}
                    />
                  </div>
                  {modelName?.value === 'azure' && (
                    <div className="form-group mb-1">
                      <label>Azure Api Version</label>
                      <input
                        id="azure_api_version"
                        type="text"
                        placeholder="Enter Azure Api Version"
                        className="form-control"
                        value={azureApiVersion}
                        onChange={e => {
                          updateAzureApiVersion?.(e.target.value);
                        }}
                      />
                    </div>
                  )}
                  {(modelName?.value === 'azure' ||
                    modelName?.value === 'mistral' ||
                    modelName?.value === 'llama') && (
                    <div className="form-group mb-1">
                      <label>Azure Endpoint</label>
                      <input
                        id="azure_endpoint"
                        type="text"
                        placeholder="Enter Azure Endpoint"
                        className="form-control"
                        value={azureEndpoint}
                        onChange={e => {
                          if (modelName?.value === 'mistral') {
                            if (e.target.value.endsWith('.com')) {
                              setIsInvalid(false);
                            } else {
                              setIsInvalid(true);
                            }
                          } else if (modelName?.value === 'llama') {
                            if (e.target.value.endsWith('/chat/completions')) {
                              setIsInvalid(false);
                            } else {
                              setIsInvalid(true);
                            }
                          }

                          updateAzureEndpoint?.(e.target.value);
                        }}
                      />
                      {modelName?.value === 'mistral' && isInvalid && (
                        <p style={{ color: 'red' }}>
                          Endpoint must be ends with .com
                        </p>
                      )}
                      {modelName?.value === 'llama' && isInvalid && (
                        <p style={{ color: 'red' }}>
                          Endpoint must be ends with /chat/completions
                        </p>
                      )}
                    </div>
                  )}
                  {modelName?.value === 'azure' && (
                    <div className="form-group mb-1">
                      <label>Azure Deployment Name</label>
                      <input
                        id="azure_deployment_name"
                        type="text"
                        placeholder="Enter Azure Deployment Name"
                        className="form-control"
                        value={azureDeploymentName}
                        onChange={e => {
                          updateAzureDeploymentName?.(e.target.value);
                        }}
                      />
                    </div>
                  )}

                  <div className="d-flex flex-column align-item-end mb-0">
                    {!isBuildConnectionRunning && (
                      <button
                        className="btn-clear link"
                        type="button"
                        disabled={isInvalid}
                        onClick={buildConnectionFoundationalModel}
                      >
                        Build connection
                      </button>
                    )}
                    {isBuildConnectionError && (
                      <p className="mb-0 text-info">
                        There was an error or no response. Please try again.
                      </p>
                    )}
                    {isBuildConnectionRunning && (
                      <p className="text-warning mb-0">
                        Building connection - please wait...
                      </p>
                    )}
                    {isBuildConnection && (
                      <p className="text-primary mb-0 flexbox-c">
                        <IconTick />
                        Connection OK
                      </p>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddNameAndType;
