import { widgetTypeConstant } from '../constant';

const { ADD_WIDGET_TYPE } = widgetTypeConstant;

const initialState = {
  widgetType: '',
};

const WidgetTypeReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case ADD_WIDGET_TYPE:
      return {
        widgetType: action.payload.widgetType,
      };

    default:
      return state;
  }
};

export default WidgetTypeReducer;
