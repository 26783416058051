export const sand = '#E9CD6B';
export const squash = '#FF9D0B';
export const chartTextColor = '#36567D';
export const chartGridColor = '#0B213BB3';
export const plotBgColor = '#FFFFFF00';
export const paperBgColor = '#00000000';
export const modeBarBgColor = '#00000000';
export const modeBarActiveColor = squash;

export const chartColors = [
  '#00E0D2', // blue
  '#FF576B', // light red
  '#00FF94', // mint green
  '#D7202B', // red
  '#E5FF17', // lemon yellow
  '#0E84CD', // light blue
  '#0F00C1', // deep blue
  '#813AB8', // purple
  '#FF8617', // orange
  '#00A224', // deep green
  '#B2B2B2', // grey
  '#FF00E6', // pink
];

export const stackedChartColors = [
  '#5D30AF',
  '#7D59BF',
  '#9E83CF',
  '#BEACDF'
]

export const bubbleColors = [
  '#2C3E50', // Dark Blue-Gray
  '#8E44AD', // Dark Purple
  '#2980B9', // Dark Blue
  '#16A085', // Dark Cyan
  '#F39C12', // Dark Orange
  '#D35400', // Dark Red-Orange
  '#C0392B', // Dark Red
  '#7F8C8D', // Dark Gray
]

export const PurpleExrColor = [
  'rgba(149,68,213,1)'
]

// #2771C8
// #034A9D

export const heatMapColors = [
  '#007ac6',
  '#00dbcc',
  // '#dcdcdc',
  '#005f99',  // Darker shade of blue to match '#007ac6'
  '#80e6db',  // Lighter, pastel shade to match '#00dbcc'
  '#b0b0b0', 
]

export const getXAxisConfig = (xTitle: string) => {
  return {
    title: {
      text: xTitle,
      font: {
        color: chartTextColor,
      },
    },
    tickfont: {
      color: chartTextColor,
    },
    gridcolor: chartGridColor,
    zerolinecolor: chartGridColor,
    showgrid: false,
  };
};


export const getYAxisConfig = (yTitle: string) => {
  return {
    title: {
      text: yTitle,
      font: {
        color: chartTextColor,
      },
    },
    tickfont: {
      color: chartTextColor,
    },
    gridcolor: chartGridColor,
    zerolinecolor: chartGridColor,
    showgrid: false,

  };
};


export const getZAxisConfig = (zTitle: string) => {
  return {
    title: {
      text: zTitle,
      font: {
        color: chartTextColor,
      },
    },
    tickfont: {
      color: chartTextColor,
    },
    gridcolor: chartGridColor,
    zerolinecolor: chartGridColor,
  };
};

export const getLayoutConfig = () => {
  return {
    font: {
      color: chartTextColor,
    },
    plot_bgcolor: plotBgColor,
    paper_bgcolor: paperBgColor,
    
    modebar: {
      bgcolor: modeBarBgColor,
      color: chartTextColor,
      activecolor: modeBarActiveColor,
    },
  };
};
