import { Parser } from 'json2csv';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';

interface DataObject {
  [key: string]: string | number | boolean;
}

export default function exportToCSV<T extends DataObject>(
  data: T[],
  fileName: string,
  format: string,
): void {
  if (format === 'csv') {
    const json2csvParser = new Parser<T>();
    const csv = json2csvParser.parse(data);

    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, `${fileName}.csv`);
  } else {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    XLSX.writeFile(wb, `${fileName}.xlsx`);
  }
}
