/* REST */
import { UserData } from '../../../@types/UserType';
import config from '../../../config';
//import history from '../../helpers/history';
import AuthService from '../../services/auth.service';
/* ACTIONS */
import { userConstant } from '../constant';
import { AppDispatchType } from '../store';

const { USERS_LOGOUT, USER_FAILURE, USER } = userConstant;

const service = new AuthService();

export const getLoggedInUser = () => {
  return async (dispatch: AppDispatchType) => {
    try {
      const data = await service.getLoginUser();
      dispatch({
        type: USER,
        payload: {
          user: data,
          message: 'User Logged In Successfully',
          isLoggedIn: true,
          //type: data.type,
        },
      });
    } catch (error) {
      let errorMessage = 'Failed to do something exceptional';
      if (error instanceof Error) {
        errorMessage = error.message;
      }

      Promise.resolve()
        .then(() => {
          localStorage.removeItem('_ERFTKEN');
          dispatch({
            type: USERS_LOGOUT,
            payload: {
              user: {} as UserData,
              message: '',
              isLoggedIn: false,
              //type: data.type,
            },
          });
        })
        .then(() => {
          window.location.href = `https://erauthp.b2clogin.com/erauthp.onmicrosoft.com/B2C_1_er/oauth2/v2.0/logout?post_logout_redirect_uri=${config.EXCHANGE_ROBOTICS_LINK}`;
        });

      dispatch({
        type: USER_FAILURE,
        payload: {
          user: {} as UserData,
          //type: undefined,
          isLoggedIn: false,
          message: errorMessage,
        },
      });
    }
  };
};

export const logoutUser = () => {
  return async (dispatch: AppDispatchType) => {
    try {
      await service.logout();
      Promise.resolve()
        .then(() => {
          localStorage.removeItem('_ERFTKEN');
          dispatch({
            type: USERS_LOGOUT,
            payload: {
              user: {} as UserData,
              message: '',
              isLoggedIn: false,
              //type: data.type,
            },
          });
        })
        .then(() => {
          window.location.href = `https://erauthp.b2clogin.com/erauthp.onmicrosoft.com/B2C_1_er/oauth2/v2.0/logout?post_logout_redirect_uri=${config.EXCHANGE_ROBOTICS_LINK}`;
        });

      //history.replace('/');
    } catch (error) {
      let errorMessage = 'Failed to do something exceptional';
      if (error instanceof Error) {
        errorMessage = error.message;
      }
      dispatch({
        type: USERS_LOGOUT,
        payload: {
          user: {} as UserData,
          //type: undefined,
          isLoggedIn: false,
          message: errorMessage,
        },
      });
    }
  };
};
