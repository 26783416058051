import React, { useEffect, useState } from 'react';
import {
  IconCross,
  // IconEditCircle,
  IconEye,
  IconEyeHide,
  // IconFlag,
  IconMinusCircle,
  IconPlusCircle,
  // IconPlusCircle,
} from '../../../../../components/public/Icons';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import CustomSelect from '../../../../../components/ui/CustomSelect';
import MarketPlaceService from '../../../../../services/marketplace.service';
import { HeaderType } from '../../../../../../@types/PortfolioType';
import { colorOptions, colorStyles } from '../../../../../helpers/utils.helper';
import Select from 'react-select';
import useAction from '../../../../../components/hooks/useAction';
import { Cron } from 'react-js-cron';
import 'react-js-cron/dist/styles.css';

const marketplaceService = new MarketPlaceService();
const SelectDefineMonitoring = ({
  performanceHeaders,
  setMonitoringPortfolio,
  selectedBenchmark,
  setSelectedBenchmark,
  selectedRange,
  setSelectedRange,
}: {
  performanceHeaders: HeaderType[];
  setMonitoringPortfolio: any;
  selectedBenchmark: any;
  setSelectedBenchmark: any;
  selectedRange: string;
  setSelectedRange: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const [allHeaderHide, setAllHeaderHide] = useState(false);

  const [selectedHeader, setSelectedHeader] = useState<any>([]);
  const [selectedCheckbox, setSelectedCheckbox] = useState('');
  const [
    performancePortfolioHeaders,
    setPerformancePortfolioHeaders,
  ] = useState<HeaderType[]>([]);
  const [manualRange, setManualRange] = useState<any>({
    min: '',
    max: '',
  });
  const { showModal } = useAction();
  const [timePeriod, setTimePeriod] = useState('30 5 * * 1,6');

  const [modal, setModal] = useState(false);
  const [headerModal, setHeaderModal] = useState(false);
  const [activeModalValue, setActiveModalValue] = useState<any>({});
  const [benchmarks, setBenchmarks] = useState([]);

  const [
    selectedBenchmarkPreviewValue,
    setSelectedBenchmarkPreviewValue,
  ] = useState<any>(null);
  const [benchmarkPreview, setBenchmarkPreview] = useState<any>([]);
  const [selectedFlag, setSelectedFlag] = useState<any>({
    value: null,
    flag: null,
  });
  const [augmentedSources, setAugmentedSources] = useState([]);
  const [selectedModalHeader, setSelectedModalHeader] = useState<HeaderType>();
  const [selectedSource, setSelectedSource] = useState<any>(null);
  const [monitoringType, setMonitoringType] = useState('');

  useEffect(() => {
    if (performanceHeaders) {
      const newHeaders = performanceHeaders.map(i => {
        return { ...i, isHide: false, isDefaultHeader: true };
      });
      setPerformancePortfolioHeaders(newHeaders);
    }
  }, [performanceHeaders]);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedCheckbox(event.target.id);
  };
  const toggleHide = (index: number) => {
    setPerformancePortfolioHeaders(prevHeaders =>
      prevHeaders.map((header, idx) =>
        idx === index
          ? { ...header, isHide: !header.isHide } // Toggle isHide for the specific index
          : header,
      ),
    );
  };

  const processArray = (data: any[]) => {
    return data.map(item => {
      // Initialize the result object
      let result: any = {};

      // Handle 'headers' array if it exists
      if (item.headers && Array.isArray(item.headers)) {
        result.headers = item.headers.map((header: string) => header.trim()); // Ensure headers are trimmed
      }

      // Handle 'isDefaultHeader' if it exists
      if (item.isDefaultHeader) {
        result.isDefaultHeader = item.isDefaultHeader;
      }

      // Handle string 'label'
      if (typeof item.label === 'string') {
        result.label = item.label.trim();
      }

      // Handle numeric 'value'
      if (typeof item.value === 'number') {
        result.value = item.value; // Retain the number value
      }

      // Add 'operation' if it exists
      if (item.operation) {
        result.operation = item.operation;
      }

      // Handle 'monitoring_type' if it exists
      if (item.monitoring_type) {
        result.monitoring_type = item.monitoring_type;
      }

      // Handle 'isHide' if it exists
      if (item.isHide !== undefined) {
        result.isHide = item.isHide;
      }

      // Conditionally handle the 'monitoring_configuration' section
      if (item.monitoring) {
        result.monitoring = item.monitoring.map((monitoringItem: any) => {
          let config: any = {};

          if (monitoringItem.monitoring_configuration?.max_value) {
            config.max_value = parseFloat(
              monitoringItem.monitoring_configuration.max_value,
            );
          }
          if (monitoringItem.monitoring_configuration?.min_value) {
            config.min_value = parseFloat(
              monitoringItem.monitoring_configuration.min_value,
            );
          }
          if (monitoringItem.monitoring_configuration?.flag) {
            config.flag = monitoringItem.monitoring_configuration.flag;
          }
          if (monitoringItem.monitoring_configuration?.flag_color) {
            config.flag_color =
              monitoringItem.monitoring_configuration.flag_color;
          }
          if (monitoringItem.monitoring_configuration?.benchmark_value) {
            config.benchmark_value =
              monitoringItem.monitoring_configuration.benchmark_value;
          }
          if (monitoringItem.monitoring_configuration?.time_period) {
            config.time_period =
              monitoringItem.monitoring_configuration.time_period;
          }

          // Add monitoring_configuration only if it has values
          return {
            ...monitoringItem,
            monitoring_configuration: Object.keys(config).length
              ? config
              : undefined,
          };
        });
      }

      // Clean up empty values from the result object
      Object.keys(result).forEach(key => {
        if (
          result[key] === undefined ||
          result[key] === null ||
          result[key] === ''
        ) {
          delete result[key];
        }
      });

      return result;
    });
  };

  useEffect(() => {
    if (performancePortfolioHeaders) {
      const transformedData: any = processArray(performancePortfolioHeaders);
      setMonitoringPortfolio(transformedData);
    }
  }, [performancePortfolioHeaders]);

  const toggle = () => {
    if (modal) {
      if (
        (selectedRange !== 'external_benchmark'
          ? manualRange.max && manualRange.min
          : selectedBenchmark?.value) &&
        selectedFlag?.value &&
        timePeriod
      ) {
        const individualMonitoring = {
          type: 'Individual',
          // monitoring_type: selectedRange,
          monitoring_configuration: {
            ...(selectedRange === 'manual'
              ? {
                  max_value: manualRange.max,
                  min_value: manualRange.min,
                }
              : {
                  benchmark_value: benchmarkPreview?.value,
                  // serviceId: selectedBenchmark?.value,
                }),
            flag: selectedFlag.value,
            flag_color: selectedFlag?.flag?.value,
            time_period: timePeriod,
          },
        };

        const allMonitoring = {
          type: 'all',
          // monitoring_type: selectedRange,
          monitoring_configuration: {
            ...(selectedRange === 'manual'
              ? {
                  max_value: manualRange.max,
                  min_value: manualRange.min,
                }
              : {
                  benchmark_value: benchmarkPreview?.value,
                  // serviceId: selectedBenchmark?.value,
                }),
            flag: selectedFlag.value,
            flag_color: selectedFlag?.flag?.value,
            time_period: timePeriod,
          },
        };

        setPerformancePortfolioHeaders((prevState: any) =>
          prevState.map((item: any, index: number) => {
            if (index === activeModalValue?.index) {
              let newMonitoring = [...(item.monitoring || [])];

              // Check if monitoring for 'all' already exists
              const existingAllMonitoringIndex = newMonitoring.findIndex(
                monitor => monitor.type === 'all',
              );

              // Check if monitoring for 'Individual' already exists
              const existingIndividualMonitoringIndex = newMonitoring.findIndex(
                monitor => monitor.type === 'Individual',
              );

              // If 'Individual' monitoring is selected, add or update its entry
              if (monitoringType === 'Individual') {
                if (existingIndividualMonitoringIndex > -1) {
                  // Update the existing 'Individual' monitoring
                  newMonitoring[
                    existingIndividualMonitoringIndex
                  ] = individualMonitoring;
                } else {
                  // Add new 'Individual' monitoring
                  newMonitoring.push(individualMonitoring);
                }
              }

              // If 'all' monitoring is selected, add or update its entry
              if (monitoringType === 'all') {
                if (existingAllMonitoringIndex > -1) {
                  // Update the existing 'all' monitoring
                  newMonitoring[existingAllMonitoringIndex] = allMonitoring;
                } else {
                  // Add new 'all' monitoring
                  newMonitoring.push(allMonitoring);
                }
              }

              return {
                ...item,
                ...activeModalValue,
                monitoring_type: selectedRange,
                monitoring: newMonitoring, // Update with the new monitoring array
              };
            }
            return item; // Return unchanged if it doesn't match
          }),
        );

        setModal(!modal);
      } else {
        showModal('Please fill all values');
      }
    }
  };

  const headerToggle = () => {
    setHeaderModal(!headerModal);
    setSelectedHeader(null);
    setSelectedCheckbox('');
  };

  const BenchmarkOptions = [
    { label: 'Benchmark', value: 'Benchmark' },
    { label: 'Benchmark 2', value: 'Benchmark 2' },
    { label: 'Benchmark 3', value: 'Benchmark 3' },
  ];

  const closeBtn = (
    <button
      className="close"
      onClick={() => (modal ? setModal(false) : headerToggle())}
      type="button"
    >
      <IconCross fill="#36567D" />
    </button>
  );

  useEffect(() => {
    if (selectedRange === 'external_benchmark') {
      marketplaceService
        .getAllSourceList('AUGMENTED_DATA_SOURCE')
        .then(({ data }) => {
          const updatedData = data?.map((i: any) => {
            return { ...i, value: i?.id, label: i?.serviceName };
          });
          setAugmentedSources(updatedData);

          // setAugSourceList(data);
        })
        .catch(err => {
          showModal(err?.response?.data?.message);
        });
    }
  }, [selectedRange]);

  useEffect(() => {
    if (selectedSource) {
      setSelectedBenchmark(null);
      setBenchmarks([]);
      setBenchmarkPreview('');
      const payload = {
        sourceId: selectedSource?.value,
        q: '',
      };
      marketplaceService
        .getAllServicesOfSources(payload)
        .then(({ data }) => {
          const array = data?.map((i: any) => {
            return {
              ...i,
              value: i.id,
              label: i.endpointName,
            };
          });
          setBenchmarks(array);
          // setIsLoading(false);
        })
        .catch(err => {
          // setIsLoading(false);
          showModal(err?.response?.data?.error);
        });
    }
  }, [selectedSource]);

  const addMonitoringHandler = () => {
    let answer: number = 0;

    // Generate the label based on the selected headers and checkbox
    const mainHeaderTitle = selectedHeader?.length
      ? selectedHeader?.map((header: HeaderType) => header.label).join(' - ') +
        ' - '
      : '';

    const newLabel =
      mainHeaderTitle +
      (selectedModalHeader?.label || '') +
      ' ' +
      selectedCheckbox +
      ' ';

    // Normalize the headers to prevent "CoapplicantIncome - ApplicantIncome" and "ApplicantIncome - CoapplicantIncome"
    const allHeaders = [
      ...(selectedHeader?.map((header: HeaderType) => header.value) || []), // Ensure it's an array, even if empty
      selectedModalHeader?.value || '', // Add the selectedModalHeader value or an empty string
    ].sort(); // Sort alphabetically

    // Check if the label already exists in the array (based on normalized headers)
    const labelExists: any =
      performancePortfolioHeaders.filter(
        (header: any) =>
          header?.headers?.sort().join(' - ') === allHeaders.join(' - ') &&
          header.operation === selectedCheckbox,
      ) || [];

    if (labelExists?.length === 2) {
      showModal('This Headers is already added');
      return;
    }

    // Perform the operation if the label doesn't exist
    if (selectedCheckbox === 'fullTotalSum') {
      // Sum the rawData.sum from all selected headers
      answer =
        (selectedHeader?.length > 0
          ? selectedHeader?.reduce(
              (total: any, header: HeaderType) => total + header?.rawData?.sum,
              0,
            )
          : 0) + selectedModalHeader?.rawData?.sum;
    } else if (
      selectedCheckbox === 'weightedAverage' &&
      selectedHeader?.length &&
      selectedModalHeader
    ) {
      answer = 0; // Add your weighted average calculation here
    } else if (selectedCheckbox === 'arithmeticAverage') {
      // Sum the averages from all selected headers
      answer =
        (selectedHeader?.length > 0
          ? selectedHeader?.reduce(
              (total: any, header: HeaderType) =>
                total + header?.rawData?.average,
              0,
            )
          : 0) + selectedModalHeader?.rawData.average;
    }

    // Add the new entry to the state
    setPerformancePortfolioHeaders((prevHeaders: any) => [
      ...prevHeaders,
      {
        headers: allHeaders, // Add all headers as an array of strings
        operation: selectedCheckbox,
        value: answer,
        label: newLabel,
      },
    ]);

    // Close the modal or perform any additional actions
    headerToggle();
  };

  const removeHeaderHandler = (index: number) => {
    const updatedArray = [...performancePortfolioHeaders]; // Create a copy of the array
    updatedArray.splice(index, 1); // Remove the item at the specified index
    setPerformancePortfolioHeaders(updatedArray); // Update the state with the new array
  };

  // Handle checkbox selection
  const handleFlagChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id } = e.target;
    setSelectedFlag((prevState: any) =>
      prevState === id ? null : { ...prevState, value: id },
    ); // Toggle if already selected, otherwise select new one
  };

  useEffect(() => {
    if (selectedBenchmark?.value) {
      marketplaceService
        .searchEndpointTableMetaData(selectedBenchmark?.value)
        .then(({ data }) => {
          setBenchmarkPreview(JSON.stringify(data?.uniqueKeysWithValues));

          let tempArr = Object?.values(data?.uniqueKeysWithValues)?.map(val => {
            return {
              label: val,
              value: val,
            };
          });

          setBenchmarkPreview(tempArr?.[0]);
          setSelectedBenchmarkPreviewValue(tempArr);
        })
        .catch(err => {
          showModal(err?.response?.data?.error);
        });
    }
  }, [selectedBenchmark]);

  useEffect(() => {
    const newArray = performancePortfolioHeaders.map(i => {
      return { ...i, isHide: allHeaderHide }; // Toggle the value of isHide
    });
    setPerformancePortfolioHeaders(newArray);
  }, [allHeaderHide]);

  const isMonitoringTypePresent: boolean = performancePortfolioHeaders?.some(
    (header: any, index: number) =>
      header?.headers?.sort().join(' - ') ===
        activeModalValue?.headers?.sort().join(' - ') &&
      header?.monitoring_type === 'manual' &&
      activeModalValue?.index !== index,
  );

  const isExternalBenchmarkPresent: boolean = performancePortfolioHeaders.some(
    (header: any) =>
      header?.headers?.sort().join(' - ') ===
        activeModalValue?.headers?.sort().join(' - ') &&
      header?.monitoring_type === 'all',
  );
  return (
    <>
      <div className="addwidget-content mw-100 ">
        <div className="main-widget pe-0 widget-clear">
          <div className="react-grid-item">
            <div className="common-widget">
              <div className="table-responsive tablescroll">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th style={{ width: '55px' }} scope="col">
                        <span className="flexbox-c gap-1">
                          Hide{' '}
                          <button
                            className="btn btn-icon btn-16 mx-auto p-0"
                            onClick={() => setAllHeaderHide(!allHeaderHide)}
                          >
                            <IconEye width={16} height={16} />
                          </button>
                        </span>
                      </th>
                      <th style={{ width: '230px' }} scope="col">
                        Data Header Name
                      </th>
                      <th scope="col">Monitoring Configuration</th>
                    </tr>
                  </thead>
                  <tbody>
                    {performancePortfolioHeaders?.map(
                      (i: HeaderType, index: number) => {
                        return (
                          <tr key={index}>
                            <td className="text-center">
                              <button
                                className="btn btn-icon mx-auto"
                                onClick={() => toggleHide(index)}
                              >
                                {i.isHide ? <IconEyeHide /> : <IconEye />}
                              </button>
                            </td>
                            <td>
                              {!i.operation ? (
                                <button
                                  className="btn-clear  link"
                                  onClick={() => {
                                    setSelectedModalHeader(i);
                                    setHeaderModal(true);
                                  }}
                                  disabled={typeof i.value === 'number'}
                                >
                                  {i.label}{' '}
                                  {i?.monitoring_type && (
                                    <> {'(' + i.monitoring_type + ')'}</>
                                  )}
                                </button>
                              ) : (
                                <p>
                                  {' '}
                                  {i.label}{' '}
                                  {i?.monitoring_type && (
                                    <> {'(' + i.monitoring_type + ')'}</>
                                  )}
                                </p>
                              )}

                              {/* {index === 3 && <IconFlag />} */}
                            </td>
                            <td className="">
                              <div className="flexbox">
                                {i?.isDefaultHeader && (
                                  <button
                                    className="btn btn-clear btn-icon"
                                    onClick={() => {
                                      setSelectedModalHeader(i);
                                      setHeaderModal(true);
                                    }}
                                  >
                                    <IconPlusCircle width={24} height={24} />
                                  </button>
                                )}
                                <ul className=" mb-0 ms-3 ps-1">
                                  {typeof i.value === 'number' && (
                                    <li>
                                      {(i?.value as number)?.toFixed(2) || ''}
                                    </li>
                                  )}
                                  {i?.monitoring?.length > 0 ? (
                                    <>
                                      {' '}
                                      {i?.monitoring?.map(monitor => {
                                        return (
                                          <>
                                            <li>
                                              {monitor?.type}
                                              <p>
                                                {monitor
                                                  ?.monitoring_configuration
                                                  ?.min_value &&
                                                  `Min:
                                                ${monitor?.monitoring_configuration?.min_value}, `}
                                                {monitor
                                                  ?.monitoring_configuration
                                                  ?.max_value &&
                                                  `Max:
                                                ${monitor?.monitoring_configuration?.max_value}, `}
                                                {monitor
                                                  ?.monitoring_configuration
                                                  ?.flag &&
                                                  `Flag:
                                                ${monitor?.monitoring_configuration?.flag}, `}
                                                {monitor
                                                  ?.monitoring_configuration
                                                  ?.flag_color &&
                                                  `Flag_color:
                                                ${monitor?.monitoring_configuration?.flag_color}, `}

                                                {monitor
                                                  ?.monitoring_configuration
                                                  ?.benchmark_value &&
                                                  `Benchmark_value:
                                                ${monitor?.monitoring_configuration?.benchmark_value}, `}
                                              </p>
                                            </li>
                                          </>
                                        );
                                      })}
                                      {/* <li>
                                        {i?.monitoring_configuration &&
                                        i?.monitoring_configuration
                                          ?.min_value &&
                                        i?.monitoring_configuration
                                          ?.max_value ? (
                                          <p>
                                            Min:{' '}
                                            {
                                              i?.monitoring_configuration
                                                ?.min_value
                                            }{' '}
                                            Max:{' '}
                                            {
                                              i?.monitoring_configuration
                                                ?.max_value
                                            }{' '}
                                          </p>
                                        ) : (
                                          ''
                                        )}
                                      </li>
                                      {i?.monitoring_configuration && (
                                        <>
                                          {i?.monitoring_configuration
                                            ?.flag && (
                                            <li>
                                              {
                                                i?.monitoring_configuration
                                                  ?.flag
                                              }
                                            </li>
                                          )}
                                          {i?.monitoring_configuration
                                            ?.time_period && (
                                            <li>
                                              {
                                                i?.monitoring_configuration
                                                  ?.time_period
                                              }
                                            </li>
                                          )}{' '}
                                        </>
                                      )} */}
                                    </>
                                  ) : (
                                    ''
                                  )}
                                </ul>
                                {i.operation && (
                                  <div className="flexbox-align-c gap-2">
                                    {/* Monitor Individual Portfolio Checkbox */}
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={
                                          i.monitoring?.some(
                                            monitoringItem =>
                                              monitoringItem.type ===
                                              'Individual',
                                          ) || false
                                        }
                                        onChange={e => {
                                          if (e.target.checked) {
                                            setMonitoringType('Individual');
                                            setActiveModalValue({
                                              ...i,
                                              index: index,
                                            });
                                            setModal(true);
                                            setSelectedSource(null);
                                            setSelectedBenchmark(null);
                                            setBenchmarks([]);
                                            setBenchmarkPreview({});
                                            setManualRange({
                                              min: '',
                                              max: '',
                                            });
                                            setTimePeriod('30 5 * * 1,6');
                                            setSelectedFlag({
                                              value: null,
                                              flag: null,
                                            });
                                          } else {
                                            // Remove the 'Individual' object based on label comparison
                                            setPerformancePortfolioHeaders(
                                              prevState =>
                                                prevState.map(item =>
                                                  item.label === i.label
                                                    ? {
                                                        ...item,
                                                        monitoring_type: '',
                                                        monitoring: item.monitoring?.filter(
                                                          monitoringItem =>
                                                            monitoringItem.type !==
                                                            'Individual',
                                                        ),
                                                      }
                                                    : item,
                                                ),
                                            );
                                          }
                                        }}
                                      />
                                      <label className="form-check-label">
                                        Monitor Individual Portfolio
                                      </label>
                                    </div>

                                    {/* Monitor All Portfolios Checkbox */}

                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={
                                          i.monitoring?.some(
                                            monitoringItem =>
                                              monitoringItem.type === 'all',
                                          ) || false
                                        }
                                        onChange={e => {
                                          if (e.target.checked) {
                                            setMonitoringType('all');
                                            setActiveModalValue(i);
                                            setSelectedSource(null);
                                            setModal(true);
                                            setSelectedBenchmark(null);
                                            setBenchmarks([]);
                                            setBenchmarkPreview({});
                                            setManualRange({
                                              min: '',
                                              max: '',
                                            });
                                            setTimePeriod('30 5 * * 1,6');
                                            setSelectedFlag({
                                              value: null,
                                              flag: null,
                                            });
                                          } else {
                                            // Remove the 'all' object based on label comparison
                                            setPerformancePortfolioHeaders(
                                              prevState =>
                                                prevState.map(item =>
                                                  item.label === i.label
                                                    ? {
                                                        ...item,
                                                        monitoring_type: '',
                                                        monitoring: item.monitoring?.filter(
                                                          monitoringItem =>
                                                            monitoringItem.type !==
                                                            'all',
                                                        ),
                                                      }
                                                    : item,
                                                ),
                                            );
                                          }
                                        }}
                                      />
                                      <label className="form-check-label">
                                        Monitor All Portfolios
                                      </label>
                                    </div>

                                    {/* Remove Header Button */}
                                    {i?.operation && (
                                      <button
                                        className="btn btn-clear btn-icon btn-h-danger"
                                        onClick={() =>
                                          removeHeaderHandler(index)
                                        }
                                      >
                                        <IconMinusCircle
                                          width={24}
                                          height={24}
                                        />
                                      </button>
                                    )}
                                  </div>
                                )}
                              </div>
                              {/* <div className="flexbox">
                                <div className="flexbox-align-c gap-4">
                                  {typeof i.value === 'number' && i.value}
                                </div>
                              </div> */}
                            </td>
                          </tr>
                        );
                      },
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal isOpen={modal} toggle={toggle} centered>
        <ModalHeader toggle={toggle} close={closeBtn}>
          Add Monitoring
        </ModalHeader>
        <ModalBody>
          <p>
            Add a monitoring trigger(s) for{' '}
            <span className="text-primary">Loan-to-Value Ratio (LTV):</span>
          </p>
          <div className="form-group flexbox-c gap-3 fc-rounded">
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="monitoringvalue"
                id="manualrange"
                checked={selectedRange === 'manual'}
                onChange={() => {
                  setSelectedRange('manual');
                }}
                disabled={isMonitoringTypePresent}
              />
              <label className="form-check-label" htmlFor="manualrange">
                Manual range
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="monitoringvalue"
                id="externalbenchmark"
                checked={selectedRange === 'external_benchmark'}
                onChange={() => {
                  setSelectedRange('external_benchmark');
                  setSelectedFlag({
                    value: null,
                    flag: null,
                  });
                }}
                disabled={isExternalBenchmarkPresent}
              />
              <label className="form-check-label" htmlFor="externalbenchmark">
                External benchmark
              </label>
            </div>
          </div>

          {/* Manual range */}
          {selectedRange === 'manual' && (
            <div className="row">
              <div className="col-12 col-lg-6">
                <div className="form-group">
                  <label className="form-label">Min Value</label>
                  <input
                    type="text"
                    placeholder="Enter value"
                    className="form-control"
                    value={manualRange.min}
                    onChange={e => {
                      const newValue = e.target.value;
                      // if (newValue <= activeModalValue?.value) {
                      setManualRange({ ...manualRange, min: newValue });
                      // }
                    }}
                  />
                  {activeModalValue?.value && (
                    <small className="text-muted mt-2">
                      Value should be less than{' '}
                      {+activeModalValue?.value?.toFixed(2)}
                    </small>
                  )}
                </div>
                <div className="form-group">
                  <label className="form-label">Max Value</label>
                  <input
                    type="text"
                    placeholder="Enter value"
                    className="form-control"
                    value={manualRange.max}
                    onChange={e => {
                      const newValue = e.target.value;
                      // Allow only numeric characters and a decimal point
                      if (/^\d*\.?\d*$/.test(newValue)) {
                        setManualRange({ ...manualRange, max: newValue });
                      }
                    }}
                  />

                  {activeModalValue?.value && (
                    <small className="text-muted mt-2">
                      Value should be greater than{' '}
                      {+activeModalValue?.value?.toFixed(2)}
                    </small>
                  )}
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="form-group mb-0">
                  <label className="form-label">Flag Triggers</label>
                </div>
                <div className="row">
                  <div className="col-12 col-lg-6">
                    <div className="form-group">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="flagtriggers"
                          id="overrange"
                          checked={selectedFlag.value === 'overrange'}
                          onChange={handleFlagChange}
                        />
                        <label className="form-check-label" htmlFor="overrange">
                          Flag if over range
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-6">
                    <div className="form-group">
                      <Select
                        className="react-select-container flex-fill"
                        classNamePrefix="react-select"
                        closeMenuOnSelect={false}
                        options={colorOptions}
                        styles={colorStyles}
                        isDisabled={selectedFlag.value !== 'overrange'}
                        value={
                          selectedFlag.value === 'overrange'
                            ? selectedFlag.flag
                            : null
                        }
                        onChange={e =>
                          setSelectedFlag({
                            value: 'overrange',
                            flag: { ...e },
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-lg-6">
                    <div className="form-group">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="flagtriggers"
                          id="underrange"
                          checked={selectedFlag.value === 'underrange'}
                          onChange={handleFlagChange}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="underrange"
                        >
                          Flag if under range
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-6">
                    <div className="form-group">
                      <Select
                        closeMenuOnSelect={false}
                        className="react-select-container flex-fill"
                        classNamePrefix="react-select"
                        options={colorOptions}
                        styles={colorStyles}
                        isDisabled={selectedFlag.value !== 'underrange'}
                        value={
                          selectedFlag.value === 'underrange'
                            ? selectedFlag.flag
                            : null
                        }
                        onChange={e =>
                          setSelectedFlag({
                            value: 'underrange',
                            flag: { ...e },
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="form-group">
                  <label className="form-label">Time period</label>
                  <Cron
                    value={timePeriod}
                    setValue={setTimePeriod}
                    clearButton={false}
                    className="custom-cron"
                  />
                </div>
              </div>
            </div>
          )}

          {/* External benchmark */}
          {selectedRange === 'external_benchmark' && (
            <div className="row">
              <div className="col-12 col-lg-6">
                <div className="form-group">
                  <label className="form-label">Augmentation Data Source</label>
                  <CustomSelect
                    options={augmentedSources}
                    value={selectedSource}
                    onChange={(value: any) => setSelectedSource(value)}
                  />
                </div>
                <div className="form-group">
                  <label className="form-label">Benchmark value</label>
                  <CustomSelect
                    options={benchmarks}
                    value={selectedBenchmark}
                    defaultValue={BenchmarkOptions[0]}
                    onChange={(e: any) => setSelectedBenchmark(e)}
                  />
                </div>

                <div className="form-group">
                  <label className="form-label">Benchmark Value Preview</label>
                  {selectedBenchmarkPreviewValue?.length > 0 && (
                    <p className="mb-0">
                      {/* {benchmarkPreview || '-'} */}
                      <CustomSelect
                        options={selectedBenchmarkPreviewValue}
                        value={benchmarkPreview}
                        onChange={(e: any) => setBenchmarkPreview(e)}
                      />
                    </p>
                  )}
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="form-group">
                  <label className="form-label">Flag Triggers</label>
                  <div className="row">
                    <div className="row">
                      <div className="col-12 col-lg-6">
                        <div className="form-group">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="flagtriggers"
                              id="overbenchmark"
                              checked={selectedFlag.value === 'overbenchmark'}
                              onChange={handleFlagChange}
                            />
                            <label
                              className="form-check-label ms-0"
                              htmlFor="overbenchmark"
                            >
                              Flag if over benchmark
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-lg-6">
                        <div className="form-group">
                          <Select
                            closeMenuOnSelect={false}
                            className="react-select-container flex-fill"
                            classNamePrefix="react-select"
                            options={colorOptions}
                            styles={colorStyles}
                            isDisabled={selectedFlag.value !== 'overbenchmark'}
                            value={
                              selectedFlag.value === 'overbenchmark'
                                ? selectedFlag.flag
                                : null
                            }
                            onChange={e =>
                              setSelectedFlag({
                                value: 'overbenchmark',
                                flag: { ...e },
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-lg-6">
                        <div className="form-group">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="flagtriggers"
                              id="underbenchmark"
                              checked={selectedFlag.value === 'underbenchmark'}
                              onChange={handleFlagChange}
                            />
                            <label
                              className="form-check-label ms-0"
                              htmlFor="underbenchmark"
                            >
                              Flag if under benchmark
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-lg-6">
                        <div className="form-group">
                          <Select
                            closeMenuOnSelect={false}
                            className="react-select-container flex-fill"
                            classNamePrefix="react-select"
                            options={colorOptions}
                            styles={colorStyles}
                            isDisabled={selectedFlag.value !== 'underbenchmark'}
                            value={
                              selectedFlag.value === 'underbenchmark'
                                ? selectedFlag.flag
                                : null
                            }
                            onChange={e =>
                              setSelectedFlag({
                                value: 'underbenchmark',
                                flag: { ...e },
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-lg-6">
                        <div className="form-group">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="flagtriggers"
                              id="equaltobenchmark"
                              checked={
                                selectedFlag.value === 'equaltobenchmark'
                              }
                              onChange={handleFlagChange}
                            />
                            <label
                              className="form-check-label ms-0"
                              htmlFor="equaltobenchmark"
                            >
                              Flag if equal to benchmark
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-lg-6">
                        <div className="form-group">
                          <Select
                            closeMenuOnSelect={false}
                            className="react-select-container flex-fill"
                            classNamePrefix="react-select"
                            options={colorOptions}
                            styles={colorStyles}
                            isDisabled={
                              selectedFlag.value !== 'equaltobenchmark'
                            }
                            value={
                              selectedFlag.value === 'equaltobenchmark'
                                ? selectedFlag.flag
                                : null
                            }
                            onChange={e =>
                              setSelectedFlag({
                                value: 'equaltobenchmark',
                                flag: { ...e },
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="form-group">
                  <label className="form-label">Time period</label>
                  <Cron
                    value={timePeriod}
                    setValue={setTimePeriod}
                    clearButton={false}
                    className="custom-cron"
                  />
                </div>
              </div>
            </div>
          )}
        </ModalBody>
        <ModalFooter className="justify-content-end">
          <button
            className="btn btn-outline-info"
            onClick={() => {
              setModal(!modal);
            }}
          >
            Cancel
          </button>
          <button className="btn btn-primary" onClick={toggle}>
            Add Monitoring
          </button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={headerModal} toggle={headerToggle} centered>
        <ModalHeader toggle={headerToggle} close={closeBtn}>
          Headers
        </ModalHeader>
        <ModalBody>
          <CustomSelect
            options={performanceHeaders.filter(
              i => i.label !== selectedModalHeader?.label,
            )}
            isSearchable={true}
            value={selectedHeader}
            isMulti
            placeholder="Select Header"
            onChange={(selectedOption: any) =>
              setSelectedHeader(selectedOption)
            }
          />
          <div className="mt-3 flexbox-c gap-3">
            <div className="form-check mb-3">
              <input
                className="form-check-input"
                type="checkbox"
                name="flagtriggers"
                id="arithmeticAverage"
                checked={selectedCheckbox === 'arithmeticAverage'}
                onChange={handleCheckboxChange}
              />
              <label
                className="form-check-label ms-0"
                htmlFor="arithmeticAverage"
              >
                Arithmetic Average
              </label>
            </div>
            <div className="form-check mb-3">
              <input
                className="form-check-input"
                type="checkbox"
                name="flagtriggers"
                id="weightedAverage"
                checked={selectedCheckbox === 'weightedAverage'}
                onChange={handleCheckboxChange}
              />
              <label
                className="form-check-label ms-0"
                htmlFor="weightedAverage"
              >
                Weighted Average
              </label>
            </div>
            <div className="form-check mb-3">
              <input
                className="form-check-input"
                type="checkbox"
                name="flagtriggers"
                id="fullTotalSum"
                checked={selectedCheckbox === 'fullTotalSum'}
                onChange={handleCheckboxChange}
              />
              <label className="form-check-label ms-0" htmlFor="fullTotalSum">
                Full Total Sum
              </label>
            </div>
          </div>
        </ModalBody>
        <ModalFooter className="justify-content-end">
          <button className="btn btn-outline-info" onClick={headerToggle}>
            Cancel
          </button>
          <button
            className="btn btn-primary"
            onClick={addMonitoringHandler}
            disabled={!selectedCheckbox}
          >
            Add Monitoring
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default SelectDefineMonitoring;
