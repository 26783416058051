import { IconSearch } from '../../../components/public/Icons';
import CustomSelect from '../../../components/ui/CustomSelect';
import { useEffect, useState } from 'react';
import UserInviteModal from './modals/UserInviteModal';
import AppLoader from '../../../components/loaders/AppLoader';
import UsersTable from './tables/UsersTable';
import SettingsService from '../../../services/settings.service';
import axios, { AxiosError } from 'axios';
import {
  DepartmentData,
  GeneralTypes,
  OptionType,
  UserSettingResponse,
  UserType,
} from '../../../../@types/SettingsType';
import {
  InviteUser,
  roleOptions,
} from '../../../redux/constant/setting.constant';
import { useSelector } from 'react-redux';
import { userData } from '../../../redux/selector/UserSelector';
import useAction from '../../../components/hooks/useAction';
import { TextConstants } from '../../../helpers/constants';
import LicenseService from '../../../services/license.service';
import { LicenseData } from '../../../../@types/LicenseType';
import useDebounce from '../../../components/hooks/useDebounce';
import PublicPagination from '../../../components/public/Pagination';
import AuthService from '../../../services/auth.service';
import ActionUserModal from './modals/ActionUserModal';

const settingsService = new SettingsService();
const licenseService = new LicenseService();
const service = new AuthService();

const Users = ({ userDetails }: GeneralTypes) => {
  const user = useSelector(userData);
  const { showModal } = useAction();

  //invite modal
  const [isLoading, setIsLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isLoadingRemove, setIsLoadingRemove] = useState(false);

  const [allDepartments, setAllDepartments] = useState<Array<
    DepartmentData
  > | null>(null);
  const [selectedDepartment, setSelectedDepartment] = useState<{
    label: string;
    value: string;
  } | null>(null);
  const [selectedRole, setSelectedRole] = useState<{
    label: string;
    value: string;
  } | null>(null);

  // Table related states
  const initLimit = 10;
  const initPage = 1;
  const [pageIs, setPageIs] = useState(initPage);
  const [totalData, setTotalData] = useState(1);
  const [limit, setLimit] = useState(initLimit);
  const [userDatas, setUserData] = useState<UserType[] | []>([]);
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [selectedLicenseId, setSelectedLicenseId] = useState<string | null>(
    null,
  );
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
  const [selectedUser, setSelectedUser] = useState<UserType | null>(null);
  const [allLicenses, setAllLicenses] = useState<Array<LicenseData> | null>(
    null,
  );
  const [searchQuery, setSearchQuery] = useState('');
  const debouncedUserInput = useDebounce(searchQuery, 1000);

  const handleUserSelection = (user: UserType | null, type?: string) => {
    if (user) {
      switch (type) {
        case 'remove':
          setSelectedUser({ ...user, type: 'remove' });
          break;
        case 'resend':
          setSelectedUser({ ...user, type: 'resend' });
          break;
        case 'reset':
          setSelectedUser({ ...user, type: 'reset' });
          break;
        case 'suspend':
          setSelectedUser({ ...user, type: 'suspend' });
          break;
        case 'active':
          setSelectedUser({ ...user, type: 'active' });
          break;
        default:
          setSelectedUser(user);
          break;
      }
    } else {
      setSelectedUser(null);
    }
  };

  const [userInviteModal, setUserInviteModal] = useState(false);
  const toggleUserInviteModal = () => {
    setUserInviteModal(!userInviteModal);
  };

  const isAxiosError = (error: any): error is AxiosError => {
    return axios.isAxiosError(error);
  };

  // All department data get function
  const getAllDepartmentsData = async () => {
    setIsLoading(true);
    try {
      const response = await settingsService.getAllDepartments(
        userDetails?.organizationId,
      );
      setIsLoading(false);
      const updatedDepartments = [...(response?.data || {})]; // Create a copy of the original data
      updatedDepartments.unshift({
        id: '',
        department: 'All Departments',
        count: 0,
      });
      setAllDepartments(updatedDepartments);
    } catch (error) {
      setIsLoading(false);

      if (isAxiosError(error)) {
        showModal(
          (error.response?.data as { error?: string })?.error ||
            'Unexpected Axios error',
        );
      } else {
        showModal('An unexpected error occurred');
      }
    }
  };

  const getAllUsers = async (params?: {
    q?: string;
    departmentId?: string;
    organizationId?: string;
    page?: number;
    limit?: number;
    userRole?: string;
  }) => {
    setIsLoading(true);
    try {
      const payload = {
        ...params,
        page: params?.page || pageIs,
        limit: params?.limit || limit,
        organizationId: userDetails?.organizationId,
      };
      const response: UserSettingResponse = await settingsService.getAllUsers(
        payload,
      );
      setIsLoading(false);
      setUserData(response?.data?.users);
      setTotalData(response?.data?.totalCount);
    } catch (error) {
      setIsLoading(false);

      if (isAxiosError(error)) {
        showModal(
          (error.response?.data as { error?: string })?.error ||
            'Unexpected Axios error',
        );
      } else {
        showModal('An unexpected error occurred');
      }
    }
  };

  const getAllLicensesData = async () => {
    setIsLoading(true);
    try {
      const response = await licenseService.getAllLicenses();
      setIsLoading(false);

      setAllLicenses(response?.data);
    } catch (error) {
      setIsLoading(false);

      if (isAxiosError(error)) {
        showModal(
          (error.response?.data as { error?: string })?.error ||
            'Unexpected Axios error',
        );
      } else {
        showModal('An unexpected error occurred');
      }
    }
  };

  useEffect(() => {
    if (userDetails?.organizationId) {
      getAllDepartmentsData();
      getAllLicensesData();
    }
  }, [userDetails?.organizationId]);

  useEffect(() => {
    if (userDetails?.organizationId) {
      const params: {
        q?: string;
        departmentId?: string;
        organizationId?: string;
        userRole?: string;
      } = {};

      if (searchQuery) {
        params.q = searchQuery;
      }
      if (selectedDepartment) {
        params.departmentId = selectedDepartment?.value;
      }
      if (selectedRole) {
        params.userRole = selectedRole?.value;
      }

      setPageIs(initPage);
      setLimit(initLimit);

      if (Object.keys(params).length > 0)
        getAllUsers({
          ...params,
          page: initPage,
          limit: initLimit,
        });
      else
        getAllUsers({
          page: initPage,
          limit: initLimit,
        });
    }
  }, [
    userDetails?.organizationId,
    debouncedUserInput,
    selectedDepartment,
    selectedRole,
  ]);

  useEffect(() => {
    if (userDetails?.organizationId) {
      getAllUsers({
        q: searchQuery,
        departmentId: selectedDepartment?.value,
        organizationId: userDetails?.organizationId,
        userRole: selectedRole?.value,
        page: pageIs,
        limit: limit,
      });
    }
  }, [pageIs, limit]);

  const updateOptionData = async () => {
    try {
      if (selectedUser) {
        setIsLoadingRemove(true);
      } else {
        setIsLoading(true);
      }
      const payload: OptionType = {};

      if (selectedOption) {
        payload.userId = selectedUserId;
        payload.userRole = selectedOption;
      }

      if (selectedLicenseId) {
        payload.userId = selectedUserId;
        payload.licenseId = selectedLicenseId;
      }

      if (selectedUser) {
        payload.userId = selectedUser?.user_id;
        payload.userStatus =
          selectedUser?.type === 'active' ? 'ACTIVE' : 'INACTIVE';
      }

      await settingsService.updateOptions(payload);
      setSelectedOption(null);
      setSelectedUserId(null);
      setSelectedLicenseId(null);
      setSelectedUser(null);
      setIsLoading(false);
      setIsLoadingRemove(false);
      getAllUsers();
      getAllLicensesData();
    } catch (error) {
      setIsLoading(false);
      setIsLoadingRemove(false);
      if (isAxiosError(error)) {
        showModal(
          (error.response?.data as { error?: string })?.error ||
            'Unexpected Axios error',
        );
      } else {
        showModal('An unexpected error occurred');
      }
    }
  };

  // For select dropdownData Option
  useEffect(() => {
    if (selectedOption !== null || selectedLicenseId !== null) {
      updateOptionData();
    }
  }, [selectedOption, selectedLicenseId]);

  // Remove api call
  const handleRemoveUser = async () => {
    setIsLoadingRemove(true);
    if (selectedUser) {
      settingsService
        .removeUserById(selectedUser?.user_id as string)
        .then(() => {
          setIsLoadingRemove(false);
          setSelectedUser(null);
          getAllUsers({ page: initPage, limit: initLimit });
        })
        .catch(err => {
          setIsLoadingRemove(false);
          if (isAxiosError(err)) {
            showModal(
              (err.response?.data as { error?: string })?.error ||
                'Unexpected Axios error',
            );
          } else {
            showModal('An unexpected error occurred');
          }
        });
    }
  };

  // Resend api call
  const handleResendLinkUser = async (item: any) => {
    try {
      setIsLoadingRemove(true);
      await service.resendInviteUser(item.user_id);
      showModal('Invite Sent Successfully.', true);
      setIsLoadingRemove(false);
    } catch (error) {
      if (isAxiosError(error)) {
        showModal(
          (error.response?.data as { error?: string })?.error ||
            'Unexpected Axios error',
        );
      } else {
        showModal('An unexpected error occurred');
      }
      setIsLoadingRemove(false);
    }
  };

  // Approve Suspend api call
  const handleApproveSuspendUser = async () => {
    updateOptionData();
  };

  // Reset password call
  const handleResetPassword = async (password: string) => {
    try {
      const payload = {
        password: password,
        userId: selectedUser?.user_id,
      };
      await service.resetPasswordUser(payload);
      showModal('Password Reset Successfully.', true);
    } catch (error) {
      if (isAxiosError(error)) {
        showModal(
          (error.response?.data as { error?: string })?.error ||
            'Unexpected Axios error',
        );
      } else {
        showModal('An unexpected error occurred');
      }
    }
  };

  // Send Invite
  const handleSendInvite = async (values: InviteUser) => {
    try {
      setIsLoading(true);
      if (userDetails?.organizationId) {
        values.organizationId = userDetails?.organizationId;
      }
      if (!values.middleName) {
        delete values.middleName;
      }

      await settingsService.inviteUser(values);
      setUserInviteModal(false);
      getAllUsers({ page: initPage, limit: initLimit });
      setIsLoading(false);
    } catch (error) {
      if (isAxiosError(error)) {
        showModal(
          (error.response?.data as { error?: string })?.error ||
            'Unexpected Axios error',
        );
      } else {
        showModal('An unexpected error occurred');
      }
      setIsLoading(false);
    }
  };

  const handlePageChange = (newPage: number, newLimit = limit) => {
    setPageIs(newPage);
    setLimit(newLimit);
  };

  return (
    <>
      {isLoading && <AppLoader />}
      <div className="wc-scroll">
        <div className="mt-2">
          <div className="row">
            <div className="col-12 col-lg-10">
              <div className="row gx-3">
                <div className="col-12 col-lg-3">
                  <div className="form-group form-icon mb-3">
                    <span className="input-icon">
                      <IconSearch />
                    </span>
                    <input
                      type="text"
                      placeholder="Search"
                      className="form-control"
                      value={searchQuery}
                      onChange={e => {
                        setSearchQuery(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="col-12 col-lg-3">
                  <div className="form-group mb-3">
                    <CustomSelect
                      options={
                        allDepartments &&
                        allDepartments?.map(item => ({
                          label: item?.department,
                          value: item?.id,
                        }))
                      }
                      placeholder={'All Departments'}
                      value={selectedDepartment}
                      onChange={(e: any) => {
                        setSelectedDepartment(e);
                      }}
                    />
                  </div>
                </div>
                <div className="col-12 col-lg-3">
                  <div className="form-group mb-3">
                    <CustomSelect
                      options={
                        user?.userRole === TextConstants.ADMIN
                          ? [
                              { label: 'All Roles', value: '' },
                              { label: 'Admin', value: 'ADMIN' },
                              { label: 'Member', value: 'MEMBER' },
                            ]
                          : roleOptions
                      }
                      placeholder={'All Roles'}
                      value={selectedRole}
                      onChange={(e: any) => {
                        setSelectedRole(e);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            {(user?.userRole === TextConstants.OWNER ||
              user?.userRole === TextConstants.ADMIN) && (
              <div className="col-12 col-lg-2 text-end mb-3">
                <button
                  className="btn btn-primary me-2"
                  onClick={() => {
                    toggleUserInviteModal();
                  }}
                >
                  Invite
                </button>
              </div>
            )}
          </div>
        </div>

        <UsersTable
          data={userDatas}
          setSelectedUserId={setSelectedUserId}
          setSelectedOption={setSelectedOption}
          setSelectedLicenseId={setSelectedLicenseId}
          onUserSelect={handleUserSelection}
          allLicenses={allLicenses}
        />
        {!isLoading && userDatas?.length > 0 && (
          <PublicPagination
            totalData={totalData}
            currentPage={pageIs}
            limit={limit}
            onPageChange={handlePageChange}
          />
        )}

        {!isLoading && userDatas?.length === 0 && (
          <div className="text-center mt-6 py-5 mb-5">No Record Found!</div>
        )}
      </div>
      {userInviteModal && (
        <UserInviteModal
          modal={userInviteModal}
          toggle={() => setUserInviteModal(false)}
          toggleSend={handleSendInvite}
          organizationId={userDetails?.organizationId}
          role={user?.userRole}
          isModalLoading={isLoading}
          setIsModalLoading={setIsLoading}
        />
      )}

      {/* Remove user modal */}
      {selectedUser && (
        <ActionUserModal
          loading={isLoadingRemove}
          modal={!!selectedUser}
          toggle={() => setSelectedUser(null)}
          removeWidget={
            selectedUser?.type === 'reset'
              ? handleResetPassword
              : selectedUser?.type === 'active' ||
                selectedUser?.type === 'suspend'
              ? handleApproveSuspendUser
              : selectedUser?.type === 'remove'
              ? handleRemoveUser
              : handleResendLinkUser
          }
          item={selectedUser}
          title={
            selectedUser?.type === 'reset'
              ? 'Reset Account Password'
              : selectedUser?.type === 'active'
              ? 'Activate User Account'
              : selectedUser?.type === 'suspend'
              ? 'Suspend User Account'
              : selectedUser?.type === 'remove'
              ? 'Remove User Account'
              : 'Resend User Invite'
          }
          description={`Are you sure you want to ${
            selectedUser?.type === 'reset'
              ? 'reset the account password for user'
              : selectedUser?.type === 'active'
              ? 'activate the account for user'
              : selectedUser?.type === 'suspend'
              ? 'suspend the account for user'
              : selectedUser?.type === 'remove'
              ? 'delete the user account for'
              : 'resend the user invite to'
          }`}
          subDescription={
            selectedUser?.type === 'reset'
              ? `The user will get an e-mail link with a request to reset their account's password.`
              : selectedUser?.type === 'active'
              ? 'The user will be able to access the platform'
              : selectedUser?.type === 'suspend'
              ? `The user won't be able to use the platform until activated`
              : selectedUser?.type === 'remove'
              ? 'This action can not be undone.'
              : ''
          }
          actionLabel={
            selectedUser?.type === 'reset'
              ? 'Reset Password'
              : selectedUser?.type === 'active'
              ? 'Activate User'
              : selectedUser?.type === 'suspend'
              ? 'Suspend User'
              : selectedUser?.type === 'remove'
              ? 'Remove User'
              : 'Resend Invite'
          }
          color={selectedUser?.type === 'remove' ? 'btn-danger' : 'btn-primary'}
        />
      )}
    </>
  );
};

export default Users;
