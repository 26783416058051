import ApiUtil from '../../utils/apiUtil';
import {
  DeleteLocalStorageResponse,
  LocalStorageResponse,
} from '../../@types/MarketPlaceType';

export default class MarketPlaceService {
  async addSourceStep1(payload: any): Promise<any> {
    const url = '/dataMarketplace/source';
    const res = await ApiUtil.SendAsync<any>({
      url: url,
      method: 'POST',
      data: payload,
    });
    return res.data;
  }

  async updateSourceStep1(payload: any): Promise<any> {
    const url = '/dataMarketplace/source';
    const res = await ApiUtil.SendAsync<any>({
      url: url,
      method: 'PUT',
      data: payload,
    });
    return res.data;
  }

  async getAllSourceList(type: string): Promise<any> {
    const url = `/dataMarketplace/source/${type}`;
    const res = await ApiUtil.SendAsync<any>({
      url: url,
      method: 'GET',
    });
    return res.data;
  }

  async getSourceById(id: string): Promise<any> {
    const url = `/dataMarketplace/source?sourceId=${id}`;
    const res = await ApiUtil.SendAsync<any>({
      url: url,
      method: 'GET',
    });
    return res.data;
  }

  async deleteSourceById(id: string): Promise<any> {
    const url = `/dataMarketplace/source/${id}`;
    const res = await ApiUtil.SendAsync<any>({
      url: url,
      method: 'DELETE',
    });
    return res.data;
  }

  async buildConnection(payload: any): Promise<any> {
    const url = '/dataMarketplace/service/buildConnection';
    const res = await ApiUtil.SendAsync<any>({
      url: url,
      method: 'POST',
      data: payload,
    });
    return res.data;
  }

  async addSourceEndpoint(payload: any): Promise<any> {
    const url = '/dataMarketplace/service/source';
    const res = await ApiUtil.SendAsync<any>({
      url: url,
      method: 'POST',
      data: payload,
    });
    return res.data;
  }

  async deleteSourceEndpointById(id: string): Promise<any> {
    const url = `/dataMarketplace/service/source/${id}`;
    const res = await ApiUtil.SendAsync<any>({
      url: url,
      method: 'DELETE',
    });
    return res.data;
  }

  async getAllServicesOfSources(payload: any): Promise<any> {
    const url = '/dataMarketplace/service/source/search';
    const res = await ApiUtil.SendAsync<any>({
      url: url,
      method: 'POST',
      data: payload,
    });
    return res.data;
  }

  async getEndpointById(id: string): Promise<any> {
    const url = `/dataMarketplace/service/source?serviceId=${id}`;
    const res = await ApiUtil.SendAsync<any>({
      url: url,
      method: 'GET',
    });
    return res.data;
  }

  async updateEndpoint(payload: any): Promise<any> {
    const url = '/dataMarketplace/service/source';
    const res = await ApiUtil.SendAsync<any>({
      url: url,
      method: 'PUT',
      data: payload,
    });
    return res.data;
  }

  async updateEndpointActiveStatus(payload: any): Promise<any> {
    const url = '/dataMarketplace/service/source/status';
    const res = await ApiUtil.SendAsync<any>({
      url: url,
      method: 'PUT',
      data: payload,
    });
    return res.data;
  }

  async updateEndpointActiveStatusAll(payload: any): Promise<any> {
    const url = '/dataMarketplace/service/source/toggleAll';
    const res = await ApiUtil.SendAsync<any>({
      url: url,
      method: 'PUT',
      data: payload,
    });
    return res.data;
  }

  async searchEndpointTable(payload: any): Promise<any> {
    const url = '/dataMarketplace/service/news/search';
    const res = await ApiUtil.SendAsync<any>({
      url: url,
      method: 'POST',
      data: payload,
    });
    return res.data;
  }

  async searchEndpointTableMetaData(id: string): Promise<any> {
    const url = `/dataMarketplace/service/metadata/${id}`;
    const res = await ApiUtil.SendAsync<any>({
      url: url,
      method: 'GET',
    });
    return res.data;
  }

  //local storage
  async getLocalStorageFiles(
    searchQuery: string,
  ): Promise<LocalStorageResponse> {
    const url = `/v1/getFiles${searchQuery && `?search=${searchQuery}`}`;
    const res = await ApiUtil.SendAsync<LocalStorageResponse>({
      url: url,
      method: 'GET',
    });
    return res.data;
  }

  async deleteLocalStorage(id: string): Promise<DeleteLocalStorageResponse> {
    const url = `/v1/deleteFiles/${id}`;
    const res = await ApiUtil.SendAsync<DeleteLocalStorageResponse>({
      url: url,
      method: 'DELETE',
    });
    return res.data;
  }

  async downloadLocalStorageFiles(id: string) {
    const url = `/v1/downloadFiles/${id}`;
    const res = await ApiUtil.SendAsync({
      url: url,
      method: 'POST',
      responseType: 'blob',
    });
    return res;
  }
}
