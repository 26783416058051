/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Input,
  InputGroup,
  InputGroupAddon,
} from 'reactstrap';
import { IconExclamationCircle } from '../public/Icons';

type ChartSaveModalProps = {
  modal: boolean;
  toggle: () => void;
  onSave: (chartName: string) => void;
};

const ChartSaveModal = ({ modal, toggle, onSave }: ChartSaveModalProps) => {
  const [chartName, setChartName] = useState('');
  const [isInvalid, setIsInvalid] = useState(false);

  const handleSave = () => {
    if (chartName.trim()) {
      onSave(chartName);
      toggle();
      setIsInvalid(false);
    } else {
      setIsInvalid(true);
    }
  };

  return (
    <Modal isOpen={modal} toggle={toggle} centered size="md">
      <ModalHeader toggle={toggle}>Save Chart</ModalHeader>
      <ModalBody>
        <p>Please enter a name for your chart</p>
        <InputGroup>
          <Input
            type="text"
            value={chartName}
            onChange={e => {
              setChartName(e.target.value);
              if (e.target.value.trim()) setIsInvalid(false);
            }}
            placeholder="Enter chart name"
            style={{
              borderColor: isInvalid ? 'red' : '',
            }}
          />
          {isInvalid && (
            <InputGroupAddon addonType="append">
              <IconExclamationCircle width={20} height={20} />
            </InputGroupAddon>
          )}
        </InputGroup>
        {isInvalid && (
          <p style={{ color: 'red', marginTop: '5px' }}>
            Please enter a valid chart name.
          </p>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
        <Button color="primary" onClick={handleSave}>
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ChartSaveModal;
