import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { IconCross } from '../../../../components/public/Icons';
import { UserType } from '../../../../../@types/SettingsType';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

type RemoveWidgetProps = {
  loading?: boolean;
  modal: boolean;
  toggle: () => void;
  removeWidget: any;
  item: UserType;
  title?: string;
  description?: string;
  subDescription?: string;
  actionLabel?: string;
  color: string;
};

const ActionUserModal = ({
  loading,
  modal,
  toggle,
  removeWidget,
  item,
  title,
  description,
  subDescription,
  actionLabel,
  color,
}: RemoveWidgetProps) => {
  const closeBtn = (
    <button className="close" onClick={toggle} type="button">
      <IconCross fill="#36567D" />
    </button>
  );

  const PasswordSchema = Yup.object().shape({
    password: Yup.string()
      .required('Password is required')
      .min(8, 'Password is too short - should be 8 chars minimum.'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), undefined], 'Passwords must match')
      .required('Confirm Password is required'),
  });

  return (
    <Modal isOpen={modal} toggle={toggle} centered size="sm">
      <ModalHeader toggle={toggle} close={closeBtn}>
        {title}
      </ModalHeader>
      <ModalBody>
        <p className="mb-4">
          {description}{' '}
          <span className="text-primary font-500">
            {item?.firstName} {item?.middleName} {item?.lastName}?
          </span>
        </p>
        <p className="mb-0">{subDescription}</p>
        {item.type === 'reset' ? (
          <Formik
            initialValues={{ password: '', confirmPassword: '' }}
            validationSchema={PasswordSchema}
            onSubmit={async (values, { resetForm }) => {
              await removeWidget(values.password);
              resetForm();
              toggle();
            }}
            validateOnChange={true}
            validateOnMount={true}
          >
            {({ errors, touched, submitForm }) => {
              return (
                <>
                  <Form>
                    {item.type === 'reset' && (
                      <>
                        <div className="form-group mt-3">
                          <label>Password</label>
                          <Field
                            type="password"
                            name="password"
                            placeholder="Enter Password"
                            className={`form-control form-control-sm ${
                              errors.password && touched.password
                                ? 'is-invalid'
                                : ''
                            }`}
                          />
                          <ErrorMessage
                            name="password"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>

                        <div className="form-group mb-0">
                          <label>Confirm Password</label>
                          <Field
                            type="password"
                            name="confirmPassword"
                            placeholder="Enter Confirm Password"
                            className={`form-control form-control-sm ${
                              errors.confirmPassword && touched.confirmPassword
                                ? 'is-invalid'
                                : ''
                            }`}
                          />
                          <ErrorMessage
                            name="confirmPassword"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </>
                    )}
                  </Form>
                  <ModalFooter className="justify-content-end">
                    <button className="btn btn-outline-info" onClick={toggle}>
                      Cancel
                    </button>
                    <button
                      disabled={loading}
                      className={`btn ${color}`}
                      onClick={submitForm} // Trigger form submission
                    >
                      {actionLabel}
                    </button>
                  </ModalFooter>
                </>
              );
            }}
          </Formik>
        ) : (
          <ModalFooter className="justify-content-end">
            <button className="btn btn-outline-info" onClick={toggle}>
              Cancel
            </button>
            <button
              disabled={loading}
              className={`btn ${color}`}
              onClick={async () => {
                await removeWidget(item);
                toggle();
              }}
            >
              {actionLabel}
            </button>
          </ModalFooter>
        )}
      </ModalBody>
    </Modal>
  );
};

export default ActionUserModal;
