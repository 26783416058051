import React from 'react';

type SelectedWidgetProps = {
  selectedWidget: string;
  // eslint-disable-next-line no-unused-vars
  updateSelectedWidget: (val: string) => void;
  customWidgetName: string;
  setCustomWidgetName: React.Dispatch<React.SetStateAction<string>>;
};

const SelectWidgetType = ({
  selectedWidget,
  updateSelectedWidget,
  customWidgetName,
  setCustomWidgetName,
}: SelectedWidgetProps) => {
  const WIDGET_TYPE = [
    {
      type: 'Essentials',
      items: [
        {
          title: 'Task Management',
          desc: 'Shows ingoing and outgoing widget-related tasks.',
          value: 'TASK',
        },
        {
          title: 'Newsfeed',
          desc: 'Shows latest articles from a news data source.',
          value: 'NEWSFEED',
        },
      ],
    },

    {
      type: 'PORTFOLIO',
      items: [
        {
          title: 'My Portfolio Performance',
          desc: 'Shows performance of an existing portfolio.',
          value: 'PORTFOLIO_PERFORMANCE',
        },
        {
          title: 'My Portfolio Charts',
          desc: 'Shows existing portfolio data using predefined charts.',
          value: 'PORTFOLIO_CHARTS',
        },
      ],
    },
  ];

  const WidgetypeList: any = [];

  WIDGET_TYPE.forEach((item, index) => {
    WidgetypeList.push(
      <div className="widget-item" key={index}>
        <p className="widget-category">{item.type}</p>
        <div className="row row-cols-1 row-cols-md-2">
          {item.items.map((subItem, subIndex) => {
            return (
              <div className="col" key={subIndex}>
                <input
                  type="radio"
                  className=""
                  name="widget-type"
                  checked={selectedWidget === subItem.value}
                  onChange={() => {
                    updateSelectedWidget(subItem.value);
                  }}
                />
                <div className="widget-card">
                  <h6 className="widget-title">{subItem.title}</h6>
                  <p>{subItem.desc}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>,
    );
  });

  return (
    <>
      <div className="addwidget-content">
        <div className="addwidget-c-item">
          <div className="form-group mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Enter custom widget title (optional)"
              value={customWidgetName}
              onChange={e => {
                setCustomWidgetName(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="create-widget h-m-unset">{WidgetypeList}</div>
      </div>
    </>
  );
};

export default SelectWidgetType;
