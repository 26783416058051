import { NewsFeedWidgetDataTypes } from '../../../@types/NewsWidgetType';
import { newsFeedWidgetConstant } from '../constant';

const {
  ADD_NEWS_FEED_DATA,
  UPDATE_NEWS_FEED_DATA,
  REMOVE_NEWS_FEED_DATA,
  ADD_MOODY_NEWS_FEED_DATA,
  REMOVE_MOODY_NEWS_FEED_DATA,
  SELECT_NEWS_ENDPOINT,
  CLEAR_SELECTED_NEWS_ENDPOINT,
} = newsFeedWidgetConstant;

type EndpointListTypes = {
  _id: string;
  endpointName: string;
  endpointDescription: string;
  createdAt: Date;
  isServiceActive: boolean;
  id: string;
};

const initialState: any = {
  newsFeedWidgetData: [] as NewsFeedWidgetDataTypes[],
  moodyNewsFeedWidgetData: [] as any,
  selectedNewsEndpoint: null as EndpointListTypes | null,
};

const filterByRemoveId = (newsData: any, widgetId: any) => {
  let newsArray = [...newsData];
  let tempArray = newsArray.filter(x => x.id !== widgetId);
  return tempArray;
};

const updatedNewsFeedData = (
  newsData: any,
  newNewsFeedData: NewsFeedWidgetDataTypes,
) => {
  //newsData = {}
  let tempData = [...newsData];

  let newsIndex = tempData.findIndex((x: any) => x.id === newNewsFeedData.id);

  if (newsIndex > -1) {
    tempData[newsIndex] = newNewsFeedData;
  } else {
    tempData.push(newNewsFeedData);
  }

  return tempData;
};

const NewsFeedWidgetsReducer = (state = initialState, action: any): any => {
  switch (action.type) {
    case ADD_NEWS_FEED_DATA:
      return {
        ...state,
        newsFeedWidgetData: updatedNewsFeedData(
          state.newsFeedWidgetData,
          action.payload.newsFeedWidgetData,
        ),
      };
    case UPDATE_NEWS_FEED_DATA:
      return {
        ...state,
        newsFeedWidgetData: state.newsFeedWidgetData.map((data:NewsFeedWidgetDataTypes) =>
          data.id === action.payload.id
            ? { ...data, ...action.payload.updatedData }
            : data,
        ),
      };
    case REMOVE_NEWS_FEED_DATA:
      return {
        ...state,
        newsFeedWidgetData: filterByRemoveId(
          state.newsFeedWidgetData,
          action.payload.removeId,
        ),
      };

    case ADD_MOODY_NEWS_FEED_DATA:
      return {
        ...state,
        moodyNewsFeedWidgetData: updatedNewsFeedData(
          state.moodyNewsFeedWidgetData,
          action.payload.moodyNewsFeedWidgetData
        ),
      };

    case REMOVE_MOODY_NEWS_FEED_DATA:
      return {
        ...state,
        moodyNewsFeedWidgetData: filterByRemoveId(
          state.moodyNewsFeedWidgetData,
          action.payload.removeId,
        ),
      };
    // For news endpoints

    case SELECT_NEWS_ENDPOINT:
      return {
        ...state,
        selectedNewsEndpoint: action.payload,
      };

    case CLEAR_SELECTED_NEWS_ENDPOINT:
      return {
        ...state,
        selectedNewsEndpoint: null,
      };
    default:
      return state;
  }
};
export default NewsFeedWidgetsReducer;
