import { selectedRowsConstant } from '../constant';

const {
  ADD_SELECTED_ROWS,
  REMOVE_SELECTED_ROWS,
  ADD_COLUMN_TYPE,
} = selectedRowsConstant;

const initialState: any = {
  selectedRows: [] as any,
  columnType: {} as any,
};

const SelectedRowsReducer = (state = initialState, action: any): any => {
  switch (action.type) {
    case ADD_SELECTED_ROWS:
      return {
        ...state,
        selectedRows: action.payload.selectedRows,
      };

    case ADD_COLUMN_TYPE:
      return {
        ...state,
        columnType: action.payload.columnType,
      };

    case REMOVE_SELECTED_ROWS:
      return {
        selectedRows: [],
      };

    default:
      return state;
  }
};
export default SelectedRowsReducer;
