import React from 'react';
import { LoaderPropsType } from '../../../@types/AppLoaderType';

const ContentLoader = (props: LoaderPropsType) => (
  <div
    style={{
      //backgroundColor: 'rgba(0, 0, 0, 0.9)',
      position: 'absolute',
      width: 'calc(100% - 20px)',
      height: 'calc(100% - 20px)',
      zIndex: '999999',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'hidden',
      top: '10px',
      left: '10px',
      backgroundColor: 'rgba(0,0,0,0.4)',
      ...props?.styleProps,
    }}
  >
    <div
      className="spinner-border text-info"
      style={{ width: '5rem', height: '5rem' }}
      role="status"
    >
      <span className="sr-only">{props.title}</span>
    </div>
  </div>
);

export default ContentLoader;
