import React, {
  /* useEffect, */ useEffect,
  useRef /* useState */,
  useState,
} from 'react';
import Plot from 'react-plotly.js';
import {
  getXAxisConfig,
  getYAxisConfig,
  getLayoutConfig,
  heatMapColors,
} from '../../chartsTheme';
import { rangeSlider } from '../../utils/modeBarIcons';
import Plotly from 'plotly.js';
import useAction from '../../../../../../components/hooks/useAction';

const BubbleChart = ({
  chartData,
  // chartTitle,
  initialXAxis,
  initialYAxis,
}: {
  chartData: any;
  chartTitle: string;
  initialXAxis: string;
  initialYAxis: string;
}) => {
  // const [updatedData, setUpdatedData] = useState([] as any);
  const layoutConfig = getLayoutConfig();
  const [isRangeSliderVisible, setIsRangeSliderVisible] = useState(false);
  const [xAxisRange, setXAxisRange] = useState<number[] | undefined>(undefined);
  const [updatedChartData, setUpdatedChartData] = useState(chartData);
  const [isSpikesVisible, setIsSpikesVisible] = useState(false);
  const { showModal } = useAction();

  const plotRef = useRef<any>(null);

  const resizeLayout = () => {
    plotRef.current.resizeHandler();
  };

  // console.log(chartData);

  useEffect(() => {
    if (chartData[0]?.x?.length > 100) {
      setXAxisRange([0, 100]);
    } else {
      setXAxisRange(undefined);
    }

    const modifiedData = chartData.map((data: any) => {
      return {
        ...data,
        x: data.x.map((value: any) =>
          value === 0 ? 'Undefined Value' : value,
        ),
      };
    });

    setUpdatedChartData(modifiedData);
  }, [chartData]);

  const isYDataNumerical = updatedChartData[0].y.every(
    (value: any) => !isNaN(value),
  );
  const isXDataNumerical = updatedChartData[0].x.every(
    (value: any) => !isNaN(value),
  );

  let sizeData;
  let colorData;
  let sizeref;

  if (isYDataNumerical) {
    sizeData = updatedChartData[0].y.map((value: number) => value * 50);
  } else if (isXDataNumerical) {
    sizeData = updatedChartData[0].x.map((value: number) => value * 50);
  } else {
    showModal(
      'Neither x nor y data is numerical. Cannot generate bubble sizes.',
    );
    sizeData = [];
  }

  if (sizeData.length > 0) {
    colorData = sizeData.map(
      (_: any, index: any) => heatMapColors[index % heatMapColors.length],
    );
    const desiredMaximumMarkerSize = 70;
    sizeref = (2.0 * Math.max(...sizeData)) / desiredMaximumMarkerSize ** 2;
  } else {
    colorData = [];
    sizeref = 1;
  }

  const plotData = [
    {
      ...updatedChartData[0],
      marker: {
        color: colorData,
        size: sizeData,
        sizeref: sizeref,
        sizemode: 'area',
        showScale: true,
      },
    },
  ];

  const toggleRangeSlider = () => {
    setIsRangeSliderVisible(prev => !prev);
  };

  const toggleSpikeLines = () => {
    setIsSpikesVisible(prev => !prev);
  };

  const customButtons = [
    {
      name: 'Toggle Range Slider',
      icon: rangeSlider,
      title: 'Toggle Range Slider',
      click: toggleRangeSlider,
    },
    {
      name: 'Toggle Spike Lines',
      icon: Plotly.Icons.spikeline,
      title: 'Toggle Spike Lines',
      click: toggleSpikeLines,
    },
  ];

  return (
    <>
      {
        <div className="chart-wrapper" onMouseDown={e => e.stopPropagation()}>
          <Plot
            ref={plotRef}
            data={plotData}
            // data={updatedData}
            config={{
              scrollZoom: true,
              displaylogo: false,
              editable: true,
              responsive: true,
              modeBarButtonsToAdd: customButtons,
            }}
            layout={{
              title: '',
              showlegend: false,

              xaxis: {
                ...getXAxisConfig(initialXAxis),
                showspikes: isSpikesVisible,
                spikemode: 'toaxis',
                range: xAxisRange,
                rangeslider: { visible: isRangeSliderVisible },
              },
              yaxis: {
                ...getYAxisConfig(initialYAxis),
                showspikes: isSpikesVisible,
                spikemode: 'toaxis',
              },
              ...layoutConfig,
            }}
            useResizeHandler={true}
            onRelayout={resizeLayout}
          />
        </div>
      }
    </>
  );
};

export default BubbleChart;
