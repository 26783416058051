// import { storeWidget } from '../constant/portfolioWidget.constant';
import { _widgets } from '../reducers/PortfolioWidgetReducer';

// const { STORE_WIDGET } = storeWidget;

export interface _CreditWidget {
  type: 'STORE_WIDGET';
  payload: _widgets;
}

export const creditWidget = (data: _widgets): _CreditWidget => {
  return {
    type: 'STORE_WIDGET',
    payload: data,
  };
};
