import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { IconCross } from '../../../../components/public/Icons';
import CustomSelect from '../../../../components/ui/CustomSelect';
import { useSelector } from 'react-redux';
import { userData } from '../../../../redux/selector/UserSelector';
import SettingsService from '../../../../services/settings.service';
import { isAxiosError } from '../../../../redux/constant';
import { useEffect, useState } from 'react';
import useAction from '../../../../components/hooks/useAction';
import moment from 'moment';
import SharingService from '../../../../services/sharing.service';
import CustomLoader from '../../../../components/loaders/CustomLoader';
// import ChartHeader from '../../portfolio/Charts/components/ChartHeader';
// import axios from 'axios';

type ShareWidgetExternallyProps = {
  type?: string;
  modal: boolean;
  headers?: any;
  toggle: () => void;
  portfolioId?: string;
  chartType?: string;
  xData?: any;
  yData?: any;
  zData?: any;
  measure?: any;
  portfolioType?: any;
  inputDataRange?: any;
  newFieldsArray?: any;
};

const ShareWidgetExternally = ({
  type,
  modal,
  toggle,
  headers,
  portfolioId,
  chartType,
  xData,
  yData,
  zData,
  measure,
  portfolioType,
  inputDataRange,
  newFieldsArray,
}: ShareWidgetExternallyProps) => {
  const { showModal } = useAction();
  const settingsService = new SettingsService();
  const sharingService = new SharingService();
  const user = useSelector(userData);
  const [emails, setEmails] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedEmail, setSelectedEmail] = useState<string | null>(null);
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [message, setMessage] = useState<string>('');

  const paid_license_ids = [
    '718a2649-60b3-41dd-babd-c438549b28f2', // Platinum
    'c9a6acc0-2861-42f6-9e12-65ae83ea36f1', // Gold
  ];

  const getSharingEmails = async () => {
    const userId = user?.user_id;
    try {
      const licenseId = user?.userDetail?.license?.id;

      if (!paid_license_ids.includes(licenseId)) {
        showModal('Please request a license from the owner.', false);
        return;
      }

      const response = await settingsService.getUserSharingPermissions(userId);

      if (response?.data?.shareDataExternally === 'Restricted') {
        showModal('You are not allowed to Share Widgets Externally!', false);
        return;
      } else {
        setEmails(response?.data?.externalSharingEmailWhitelist);
      }
    } catch (error) {
      if (isAxiosError(error)) {
        showModal(
          (error.response?.data as { error?: string })?.error ||
            'Unexpected Axios error',
        );
      } else {
        showModal('An unexpected error occurred', false);
      }
    }
  };

  useEffect(() => {
    if (user?.user_id) {
      getSharingEmails();
    }
  }, [user?.user_id]);

  const emailOptions = emails.map((email: string) => ({
    label: email,
    value: email,
  }));

  const handleDateChange = (event: any) => {
    const date = moment(event.target.value, 'YYYY-MM-DD').format('MM/DD/YYYY');
    setSelectedDate(date);
  };

  const handleShare = async () => {
    if (type === 'VISUALISATION WIDGET') {
      const payload = {
        recieverEmail: selectedEmail,
        message: message,
        dueDate: selectedDate,
        type: 'VISUALISATION_WIDGET',
        chartHeaders: headers,
        chartType: chartType,
        portfolioId: portfolioId,
        xData: xData,
        yData: yData,
        zData: zData,
        measure: measure,
      };

      try {
        setIsLoading(true);
        await sharingService.shareVisualisationWidgetExternal(payload);
        toggle();
        showModal('Reciever should get an Email shortly!', true);
        setIsLoading(false);
      } catch (e) {
        toggle();
        showModal('An unexpected error occurred', false);
        setIsLoading(false);
      }
    } else if (type === 'CREDIT WIDGET') {
      const payload = {
        recieverEmail: selectedEmail,
        message: message,
        dueDate: selectedDate,
        type: 'CREDIT_WIDGET',
        portfolioId: portfolioId,
      };

      try {
        setIsLoading(true);
        await sharingService.shareCreditWidgetExternal(payload);
        toggle();
        showModal('Reciever should get an Email shortly!', true);
        setIsLoading(false);
      } catch (e) {
        toggle();
        showModal('An unexpected error occurred', false);
        setIsLoading(false);
      }
    } else if (type === 'STATISTICAL WIDGET') {
      const payload = {
        recieverEmail: selectedEmail,
        message: message,
        dueDate: selectedDate,
        type: 'STATISTICAL_WIDGET',
        portfolioId: portfolioId,
        portfolioType: portfolioType,
        inputDataRange: inputDataRange,
        newFields: newFieldsArray,
      };

      try {
        setIsLoading(true);
        await sharingService.shareStatisticalWidget(payload);
        toggle();
        showModal('Reciever should get an Email shortly!', true);
        setIsLoading(false);
      } catch (e) {
        toggle();
        showModal('An unexpected error occurred', false);
        setIsLoading(false);
      }
    }
  };

  const closeBtn = (
    <button className="close" onClick={toggle} type="button">
      <IconCross fill="#36567D" />
    </button>
  );

  return (
    <Modal isOpen={modal} toggle={toggle} centered>
      {isLoading && <CustomLoader />}
      <ModalHeader toggle={toggle} close={closeBtn}>
        Share widget externally
      </ModalHeader>

      <ModalBody>
        <div className="form-group">
          <p className="mb-0">
            Share “Credit (My Credit Calc Workbench)” widget data to a user
            outside of your organization
          </p>
        </div>
        <div className="row">
          <div className="col-12 col-lg-6">
            <div className="form-group">
              <label className="form-label">Share to E-mail</label>
              <CustomSelect
                options={emailOptions}
                placeholder="Select whitelisted e-mail address"
                onChange={(selected: any) => setSelectedEmail(selected?.value)}
              />
            </div>
          </div>
          <div className="col-12 col-lg-6">
            <div className="form-group">
              <label className="form-label">Link Expiration Date</label>
              <div className="form-icon form-icon-end">
                {/* <span
                  className="input-icon"
                  onClick={() => dateInputRef.current?.focus()}
                >
                  <IconCalendar />
                </span> */}
                <input
                  type="date"
                  placeholder="Select date"
                  className="form-control"
                  value={
                    selectedDate
                      ? moment(selectedDate, 'MM/DD/YYYY').format('YYYY-MM-DD')
                      : ''
                  }
                  onChange={handleDateChange}
                />
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="form-group">
              <label className="form-label">Message (OPTIONAL)</label>
              <textarea
                placeholder="Add a custom message for the recipient"
                className="form-control"
                rows={4}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </div>
          </div>
        </div>
        <p className="font-12 font-300 mb-0">*All fields are required</p>
      </ModalBody>
      <ModalFooter className="justify-content-end">
        <button className="btn btn-outline-info" onClick={toggle}>
          Cancel
        </button>
        <button className="btn btn-primary" onClick={handleShare}>
          Share
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default ShareWidgetExternally;
