import { combineReducers } from 'redux';
import ChatReducer from './ChatReducer';
import ErrorReducer from './ErrorReducer';
import LoadingReducer from './LoaderReducer';
import UserReducer from './UserReducer';
import WidgetRankReducer from './WidgetRankReducer';
import PortfolioReducer from './PortfolioReducer';
import ReloadPortfolioReducer from './ReloadPortfolioReducer';
import AllocationCalculationReducer from './AllocationCalculationReducer';
import ThemeReducer from './ThemeReducer';
import WidgetTypeReducer from './WidgetTypeReducer';
import UserDetailsReducer from './UserDetailsReducer';
import SelectedRowsReducer from './SelectedRowsReducer';
import RowIndexReducer from './RowIndexReducer';
import TotalRowsReducer from './TotalRowsReducer';
import PortfolioWidgetReducer from './PortfolioWidgetReducer';
import RowSelectionTypeReducer from './RowSelectionTypeReducer';
import ProductStructuringReducer from './ProductStructuringReducer';

import NewsFeedWidgetsReducer from './NewsFeedWidgetsReducer';
import DashboardWidgetReducer from './DashboardWidgetReducer';
import ProductsWidgetsReducer from './ProductsWidgetsReducer';
import PortfolioWidgetsReducer from './PortfolioWidgetsReducer';
import TypingReducer from './TypingReducer';
import WidgetReferenceIdReducer from './WidgetReferenceIdReducer';
import WarrenFileReducer from './WarrenFileReducer';
import ChatQueryReducer from './ChatQueryReducer';
import MarketplaceTabReducer from './MarketplaceTabReducer';
import MarketplaceReducer from './MarketplaceReducer';
import SelectedModelReducer from './SelectedModelReducer';

const rootReducer = combineReducers({
  LoadingReducer,
  UserReducer,
  ErrorReducer,
  ChatReducer,
  WidgetRankReducer,
  PortfolioReducer,
  ReloadPortfolioReducer,
  AllocationCalculationReducer,
  ThemeReducer,
  WidgetTypeReducer,
  UserDetailsReducer,
  SelectedRowsReducer,
  RowIndexReducer,
  TotalRowsReducer,
  PortfolioWidgetReducer,
  RowSelectionTypeReducer,
  ProductStructuringReducer,

  NewsFeedWidgetsReducer,
  DashboardWidgetReducer,
  ProductsWidgetsReducer,
  PortfolioWidgetsReducer,
  TypingReducer,
  WidgetReferenceIdReducer,
  WarrenFileReducer,
  ChatQueryReducer,
  MarketplaceTabReducer,
  MarketplaceReducer,
  SelectedModelReducer,
});

export default rootReducer;

type RootReducerType = typeof rootReducer;
export type AppStateType = ReturnType<RootReducerType>;
