import React from 'react';
import { LoaderPropsType } from '../../../@types/AppLoaderType';

const DataLoader = (props: LoaderPropsType) => (
  <div
    style={{
      position: 'relative',
      width: '100%',
      height: 'auto',
      zIndex: '9',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'hidden',
      top: '0',
      left: '0',
      padding: '8px',
      ...props?.styleProps,
    }}
  >
    <div
      className="spinner-border text-info"
      style={{ width: '3rem', height: '3rem' }}
      role="status"
    >
      <span className="sr-only">{props.title}</span>
    </div>
  </div>
);

export default DataLoader;
