import axios, { AxiosError } from 'axios';

export const errorConstant = {
  SHOW_MODAL: 'SHOW_MODAL',
  HIDE_MODAL: 'HIDE_MODAL',
};

export const isAxiosError = (error: any): error is AxiosError => {
  return axios.isAxiosError(error);
};
