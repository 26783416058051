/* ACTIONS */
import { typingConstant } from '../constant';

const { ADD_TYPING_STATUS } = typingConstant;

export const addTypingStatus = (data: any) => {
  return {
    type: ADD_TYPING_STATUS,
    payload: {
      typingStatus: data,
    },
  };
};
