import { useEffect, useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import CustomSelect from '../../../../components/ui/CustomSelect';
import {
  /*IconCalendar,*/ IconCross,
} from '../../../../components/public/Icons';
import { StatisticsUser } from '../../../../../@types/SettingsType';
import SettingsService from '../../../../services/settings.service';
import axios, { AxiosError } from 'axios';
import AppLoader from '../../../../components/loaders/AppLoader';
import moment from 'moment';
import IngestionService from '../../../../services/ingestion.service';
import useAction from '../../../../components/hooks/useAction';
// import config from '../../../../../config';
// import { useSelector } from 'react-redux';
// import { chatData } from '../../../../redux/selector/ChatSelector';
import config from '../../../../../config';
import { useNavigate } from 'react-router';
import ProfileService from '../../../../services/profile.service';

type SendWidgetAsTaskProps = {
  type?: string;
  modal: boolean;
  toggle: () => void;
  data?: any;
  portfolioId?: string;
  newsId?: string;
  newsName?: number | string;
  newsSourceId?: string;
  chartType?: string;
  headers?: any;
  key?: string;
  portfolioType?: string;
  inputDataRange?: { [k: string]: { min: number; max: number }[] };
};

const ingService = new IngestionService();
const profileService = new ProfileService();

const SendWidgetAsTask = ({
  type,
  modal,
  toggle,
  portfolioId,
  headers,
  newsId,
  newsName,
  newsSourceId,
  chartType,
  // key,
  portfolioType,
  inputDataRange,
}: // data,
SendWidgetAsTaskProps) => {
  const { showModal } = useAction();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState('');
  const [description, setDescription] = useState('');
  const [allUsers, setAllUsers] = useState<Array<StatisticsUser> | null>(null);
  const [selectedUser, setSelectedUser] = useState<{
    label: string;
    value: string;
  } | null>(null);
  const settingsService = new SettingsService();

  // const chatMessages = useSelector(chatData);

  const isAxiosError = (error: any): error is AxiosError => {
    return axios.isAxiosError(error);
  };
  const getUserData = async () => {
    setIsLoading(true);
    try {
      const response: any = await settingsService.getStatisticsUsers();
      setAllUsers(response.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if (isAxiosError(error)) {
        showModal(
          (error.response?.data as { error?: string })?.error ||
            'Unexpected Axios error',
        );
      } else {
        showModal('An unexpected error occurred');
      }
    }
  };

  const handleDateChange = (event: any) => {
    const date = moment(event.target.value, 'YYYY-MM-DD').format('MM/DD/YYYY');
    setSelectedDate(date);
  };
  const closeBtn = (
    <button className="close" onClick={toggle} type="button">
      <IconCross fill="#36567D" />
    </button>
  );

  useEffect(() => {
    getUserData();
  }, []);

  const paid_license_ids = [
    '718a2649-60b3-41dd-babd-c438549b28f2', // Platinum
    'c9a6acc0-2861-42f6-9e12-65ae83ea36f1', // Gold
  ];

  const sendTaskHandler = async () => {
    try {
      setIsLoading(true);

      if (!selectedUser || !selectedUser.value) {
        setIsLoading(false);
        showModal('Please select a user to share the widget.');
        return;
      }

      const departmentResponse = await axios.get(
        `${config.ER_ML_URL}/portfolio/department-name/${selectedUser.value}`,
      );

      const departmentName = departmentResponse.data;

      const userPermissionsResponse = await settingsService.getUserSharingPermissions(
        selectedUser.value,
      );

      const result = await profileService.getUserProfile();
      const licenseId = result?.data?.licenseId;

      if (!paid_license_ids.includes(licenseId)) {
        setIsLoading(false);
        showModal('Please request a license from the owner.');
        return;
      }

      const departmentWhitelist =
        userPermissionsResponse?.data?.departmentCollaborationWhitelist;

      if (
        !departmentWhitelist ||
        !departmentWhitelist.includes(departmentName)
      ) {
        setIsLoading(false);
        showModal(
          `You don't have permission to share with the department: ${departmentName}`,
        );
        navigate(`/users?userId=${selectedUser.value}`);
        return;
      }

      if (type === 'NEWS') {
        const payloadForDB = {
          recieverUserId: selectedUser.value,
          description: description,
          sharingDueDate: selectedDate,
          type: type,
          newsId: newsId,
          newsName: newsName,
          newsSourceId: newsSourceId,
        };

        await axios.post(
          `${config.ER_ML_URL}/share-widget/share`,
          payloadForDB,
        );

        const payload = {
          userId: selectedUser?.value,
        };
        await ingService.shareNewsWidget(payload);
      }

      if (type === 'VISUALISATION WIDGET') {
        const payloadForDB = {
          recieverUserId: selectedUser.value,
          description: description,
          sharingDueDate: selectedDate,
          type: type,
          chartHeaders: headers,
          chartType: chartType,
          portfolioId: portfolioId,
        };

        await axios.post(
          `${config.ER_ML_URL}/share-widget/share`,
          payloadForDB,
        );
        const payload = {
          userId: selectedUser?.value,
        };
        await ingService.shareVisualisationWidget(payload);
      }

      if (type === 'CREDIT WIDGET') {
        const payloadForDB = {
          recieverUserId: selectedUser.value,
          portfolioId: portfolioId,
          description: description,
          sharingDueDate: selectedDate,
          type: type,
        };
        const payload = {
          userId: selectedUser?.value,
          portfolioId: portfolioId,
        };

        await axios.post(
          `${config.ER_ML_URL}/share-widget/share`,
          payloadForDB,
        );
        await ingService.shareCreditWidget(payload);
        setIsLoading(false);
        toggle();
        showModal('Widget Shared Successfully', true);
      }

      if (type === 'STATISTICAL WIDGET') {
        const payloadForDB = {
          recieverUserId: selectedUser.value,
          portfolioId: portfolioId,
          description: description,
          sharingDueDate: selectedDate,
          type: type,
          portfolioType: portfolioType,
          inputDataRange: inputDataRange,
        };

        try {
          const response = await axios.post(
            `${config.ER_ML_URL}/share-widget/share`,
            payloadForDB,
          );
          if (response.status === 200) {
            const payload = {
              userId: selectedUser?.value,
            };

            await ingService.shareStatisticalWidget(payload);
            showModal('Widget Shared Successfully', true);
          } else {
            showModal('Failed to share widget', false);
          }
        } catch (error) {
          showModal('Failed to notify user', false);
        } finally {
          setIsLoading(false);
          toggle();
        }
      }
    } catch (error) {
      setIsLoading(false);
      if (isAxiosError(error)) {
        showModal(
          (error.response?.data as { error?: string })?.error ||
            'Unexpected Axios error',
        );
      } else {
        showModal('An unexpected error occurred');
      }
    }
  };

  return (
    <Modal isOpen={modal} toggle={toggle} centered>
      {isLoading && <AppLoader />}
      <ModalHeader toggle={toggle} close={closeBtn}>
        Send widget as task
      </ModalHeader>
      <ModalBody>
        <div className="form-group">
          <p className="mb-0">
            Send “Credit (My Credit Calc Workbench)” widget as a task to another
            user
          </p>
        </div>
        <div className="row">
          <div className="col-12 col-lg-6">
            <div className="form-group">
              <label className="form-label">Task Assignee</label>
              <CustomSelect
                options={
                  allUsers &&
                  allUsers.map(user => ({
                    label: user.firstName
                      ? `${user.firstName} ${user.lastName} ( ${user.email} )`
                      : user.email,
                    value: user.user_id,
                  }))
                }
                value={selectedUser}
                onChange={(e: any) => {
                  setSelectedUser(e);
                }}
                placeholder="Select task recipient"
              />
            </div>
          </div>
          <div className="col-12 col-lg-6">
            <div className="form-group">
              <label className="form-label">Due Date</label>
              <div className="form-icon form-icon-end">
                {/* <span className="input-icon">
                  <IconCalendar />
                </span> */}
                <input
                  type="date"
                  placeholder="Select date"
                  className="form-control"
                  value={
                    selectedDate
                      ? moment(selectedDate, 'MM/DD/YYYY').format('YYYY-MM-DD')
                      : ''
                  }
                  onChange={handleDateChange}
                />
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="form-group">
              <label className="form-label">Task description</label>
              <textarea
                placeholder="Describe what you want the task recipient to do"
                className="form-control"
                rows={4}
                value={description}
                onChange={e => setDescription(e.target.value)}
              />
            </div>
          </div>
        </div>
        <p className="font-12 font-300 mb-0">*All fields are required</p>
      </ModalBody>
      <ModalFooter className="justify-content-end">
        <button className="btn btn-outline-info" onClick={toggle}>
          Cancel
        </button>
        <button className="btn btn-primary" onClick={sendTaskHandler}>
          Send Task
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default SendWidgetAsTask;
