import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
/* COMPONENTS */
import App from './App';
import ScrollToTop from './app/components/public/ScrollToTop';
// React-Redux store
import store from './app/redux/store';

import reportWebVitals from './reportWebVitals';
/* VENDOR */
import './vendor';

const rootElement = document.getElementById('root')!;
const root = ReactDOM.createRoot(rootElement); // Use createRoot instead of render

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <ScrollToTop>
        <App />
      </ScrollToTop>
    </BrowserRouter>
  </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
