import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { IconCross } from '../../../../components/public/Icons';

type RemoveWidgetProps = {
  modal: boolean;
  toggle: () => void;
  removeWidget: () => void;
};

const RemoveWidget = ({ modal, toggle, removeWidget }: RemoveWidgetProps) => {
  const closeBtn = (
    <button className="close" onClick={toggle} type="button">
      <IconCross fill="#36567D" />
    </button>
  );

  return (
    <Modal isOpen={modal} toggle={toggle} centered size="sm">
      <ModalHeader toggle={toggle} close={closeBtn}>
        Remove widget
      </ModalHeader>
      <ModalBody>
        <p className="mb-4">
          Are you sure you want to remove the{' '}
          <span className="text-primary font-500">
            Credit (My Credit Calc Workbench)
          </span>{' '}
          widget?
        </p>
        <p className="mb-0">This action can not be undone.</p>
      </ModalBody>
      <ModalFooter className="justify-content-end">
        <button className="btn btn-outline-info" onClick={toggle}>
          Cancel
        </button>
        <button
          className="btn btn-danger"
          onClick={() => {
            toggle();
            removeWidget();
          }}
        >
          Remove Widget
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default RemoveWidget;
