export const marketplaceConstant = {
  ADD_NEWS_SOURCE_COUNT: 'ADD_NEWS_SOURCE_COUNT',
  ADD_DATA_SOURCE_COUNT: 'ADD_DATA_SOURCE_COUNT',
  ADD_AUGUMENTATION_DATA_SOURCE_COUNT: 'ADD_AUGUMENTATION_DATA_SOURCE_COUNT',
  ADD_FOUNDATIONAL_MODEL_COUNT: 'ADD_FOUNDATIONAL_MODEL_COUNT',
  IS_SAVE_NEWS_SOURCE: 'IS_SAVE_NEWS_SOURCE',
  IS_SAVE_DATA_SOURCE: 'IS_SAVE_DATA_SOURCE',
  IS_SAVE_AUGUMENTATION_SOURCE: 'IS_SAVE_AUGUMENTATION_SOURCE',
  MARKET_PLACE_TYPE: 'MARKET_PLACE_TYPE',
};
