import React from 'react';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import {
  IconAction,
  IconCross,
  IconDownload,
} from '../../../components/public/Icons';
import MarketPlaceService from '../../../services/marketplace.service';
import useAction from '../../../components/hooks/useAction';

type LocalStorageActionDropdownTypes = {
  localStorageId: string;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  deleteSuccess: () => void;
  // item?: any;
};

const marketplaceService = new MarketPlaceService();

const LocalStorageActionDropdown = ({
  localStorageId,
  setIsLoading,
  deleteSuccess,
}: // item,
LocalStorageActionDropdownTypes) => {
  const { showModal } = useAction();
  const [dropdownOpen, setDropDownOpen] = React.useState(false);

  const toggleDropDown = () => {
    setDropDownOpen(state => !state);
  };

  const deleteLocalStorageFile = () => {
    setIsLoading(true);
    marketplaceService
      .deleteLocalStorage(localStorageId)
      .then(() => {
        setIsLoading(false);
        deleteSuccess();

        showModal('Deleted Successfully!', true);
      })
      .catch(err => {
        setIsLoading(false);
        showModal(err?.response?.data?.message);
      });
  };

  const downloadLocalStorageFiles = () => {
    setIsLoading(true);

    marketplaceService
      .downloadLocalStorageFiles(localStorageId)
      .then((response: any) => {
        setIsLoading(false);

        const url = window.URL.createObjectURL(
          new Blob([response.data], {
            type:
              'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          }),
        );
        const link = document.createElement('a');
        link.href = url;

        const contentDisposition = response.headers['content-disposition'];
        const fileName = contentDisposition
          ? contentDisposition.split('filename=')[1].replace(/['"]/g, '')
          : 'downloaded_file.docx';

        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch(err => {
        setIsLoading(false);
        showModal(err?.response?.data?.message);
      });
  };

  return (
    <Dropdown
      className="add-dropdown"
      isOpen={dropdownOpen}
      toggle={toggleDropDown}
    >
      <DropdownToggle caret direction="down" className="btn-icon icon-hr">
        <IconAction />
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem
          onClick={() => {
            downloadLocalStorageFiles();
          }}
        >
          <IconDownload />
          Download
        </DropdownItem>
        <DropdownItem
          onClick={() => {
            deleteLocalStorageFile();
          }}
        >
          <IconCross />
          Remove
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

export default LocalStorageActionDropdown;
