/* ACTIONS */
import { totalRowsConstant, totalRowsIndex } from '../constant';
import {
  AllCalculation,
  AllWidgetRow,
  TableHeader,
} from '../reducers/TotalRowsReducer';

const { ADD_ALL_ROWS } = totalRowsConstant;
const { ADD_WIDGET_INDEX } = totalRowsIndex;
// const { HEADER_SCHEMA } = headerSchema;

export interface _HeaderSchema {
  type: 'HEADER_SCHEMA';
  payload: {
    allHeaders: TableHeader;
  };
}

export interface _CreditCalculation {
  type: 'CALCULATE_DATA';
  payload: {
    allCalculateData: AllCalculation;
  };
}

export const addTotalRows = (data: any) => {
  return {
    type: ADD_ALL_ROWS,
    payload: {
      allRows: data,
    },
  };
};

export const addCsvToWidget = (data: AllWidgetRow) => {
  return {
    type: ADD_WIDGET_INDEX,
    payload: {
      allWidgetRow: data,
    },
  };
};

export const addHsToWIdget = (data: TableHeader): _HeaderSchema => {
  return {
    type: 'HEADER_SCHEMA',
    payload: {
      allHeaders: data,
    },
  };
};

export const addCalToWidgets = (data: AllCalculation): _CreditCalculation => {
  return {
    type: 'CALCULATE_DATA',
    payload: {
      allCalculateData: data,
    },
  };
};
