export const chatQueryConstant = {
  ADD_CHAT_QUERY: 'ADD_CHAT_QUERY',
  REMOVE_CHAT_QUERY: 'REMOVE_CHAT_QUERY',

  ADD_NPL_CHAT_QUERY: 'ADD_NPL_CHAT_QUERY',
  ADD_LOAN_CHAT_QUERY: 'ADD_LOAN_CHAT_QUERY',
  ADD_RECEIVABLE_CHAT_QUERY: 'ADD_RECEIVABLE_CHAT_QUERY',
  ADD_NEWS_QUERY: 'ADD_NEWS_QUERY',
  CLEAR_NEWS_QUERY: 'CLEAR_NEWS_QUERY',
};
