export const dashboardWidgetConstant = {
  ADD_NEWS_DASHBOARD_FEED_DATA: 'ADD_NEWS_DASHBOARD_FEED_DATA',
  REMOVE_NEWS_DASHBOARD_FEED_DATA: 'REMOVE_NEWS_DASHBOARD_FEED_DATA',
  ADD_MOODY_NEWS_FEED_DATA: 'ADD_MOODY_NEWS_FEED_DATA',
  REMOVE_MOODY_NEWS_FEED_DATA: 'REMOVE_MOODY_NEWS_FEED_DATA',

  ADD_TASKS_WIDGET_DATA: 'ADD_TASKS_WIDGET_DATA',
  REMOVE_TASKS_WIDGET_DATA: 'REMOVE_TASKS_WIDGET_DATA',
  ADD_NPL_FINANCIAL_WIDGET_DATA: 'ADD_NPL_FINANCIAL_WIDGET_DATA',
  REMOVE_NPL_FINANCIAL_WIDGET_DATA: 'REMOVE_NPL_FINANCIAL_WIDGET_DATA',
  ADD_TRANSACTION_REPORTS_WIDGET_DATA: 'ADD_TRANSACTION_REPORTS_WIDGET_DATA',
  REMOVE_TRANSACTION_REPORTS_WIDGET_DATA:
    'REMOVE_TRANSACTION_REPORTS_WIDGET_DATA',
  ADD_EMBEDDED_BROWSER_WIDGET_DATA: 'ADD_EMBEDDED_BROWSER_WIDGET_DATA',
  REMOVE_EMBEDDED_BROWSER_WIDGET_DATA: 'REMOVE_EMBEDDED_BROWSER_WIDGET_DATA',

  // For news dashboard endpoints
  SELECT_DASHBOARD_NEWS_ENDPOINT: 'SELECT_DASHBOARD_NEWS_ENDPOINT',
  CLEAR_DASHBOARD_SELECTED_NEWS_ENDPOINT: 'CLEAR_DASHBOARD_SELECTED_ENDPOINT',
  REMOVE_DASHBOARD_NEWS_ENDPOINT: 'REMOVE_DASHBOARD_NEWS_ENDPOINT',

  //For performance portfolio endpoints
  ADD_PERFORMANCE_PORTFOLIO_DATA: 'ADD_PERFORMANCE_PORTFOLIO_DATA',
  REMOVE_PERFORMANCE_PORTFOLIO_DATA: 'REMOVE_PERFORMANCE_PORTFOLIO_DATA',
};
