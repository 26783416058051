import Select from 'react-select';

const CustomSelect = ({
  options,
  isMulti,
  loadOptions,
  menuPlacement,
  name,
  isDisable,
  // className,
  // classNamePrefix,
  value,
  isClearable,
  // styles,
  onChange,
  ...props
}: any) => {
  return (
    <Select
      isDisabled={isDisable || false}
      // menuPortalTarget={document.body}
      menuPlacement={menuPlacement || 'auto'}
      placeholder={'Choose'}
      options={options}
      isMulti={isMulti}
      loadOptions={loadOptions}
      name={name}
      className="react-select-container"
      classNamePrefix="react-select"
      value={value}
      isClearable={isClearable}
      onChange={onChange}
      // menuIsOpen
      {...props}
      styles={{
        control: (base: any) => ({
          ...base,
        }),
      }}
    />
  );
};

export default CustomSelect;
