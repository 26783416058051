/* eslint-disable no-unused-vars */
import React from 'react';
import { News } from '../../../../../@types/NewsWidgetType';
import moment from 'moment';
import NewsImage from '../../../../../assets/images/dummy-news.jpg';
import { useSelector } from 'react-redux';
import { newsFeedWidgetsDataSelector } from '../../../../redux/selector/NewsFeedWidgetsSelector';

type LatestNewsCardViewProps = {
  newsTopData: any[];
  handleNewsClick: (url: string) => void;
  newsWidgetIndex: number | string;
};

const LatestNewsCardView = ({
  newsTopData,
  handleNewsClick,
  newsWidgetIndex,
}: LatestNewsCardViewProps) => {
  const newsFeedData = useSelector(newsFeedWidgetsDataSelector);

  return (
    <>
      <div className="news-widget-date">
        <h5 className="mb-0">Latest News</h5>
      </div>
      <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-3 row-cols-xxl-5 row-g-10">
        {newsTopData?.map(newsItem => (
          <div key={newsItem?.id} className={`newsfeed-item col`}>
            <div
              className="news-inner-item"
              onClick={() => {
                handleNewsClick(newsItem?.url); // Use the function passed as a prop
                // callTelemetryRecord('news', newsEventId, news?.id, [
                //   'click',
                // ]);
              }}
            >
              <div className="newsfeed-image">
                <img
                  src={newsItem?.thumbnail_url}
                  alt="news_image"
                  onError={e => {
                    e.currentTarget.src = NewsImage;
                  }}
                />
              </div>
              <div className="newsfeed-description">
                <div className="newsfeed-publisher">
                  <p
                    className="newsfeed-date"
                    dangerouslySetInnerHTML={{
                      __html: moment(newsItem?.date_published).isValid()
                        ? moment(newsItem?.date_published).format('MM/DD/YYYY')
                        : newsItem?.date_published,
                    }}
                  ></p>
                  <span className="text-info">•</span>
                  <span className="">
                    {newsFeedData[newsWidgetIndex]?.selectedNewsName}
                  </span>
                </div>
                <h2
                  dangerouslySetInnerHTML={{
                    __html: newsItem?.title,
                  }}
                ></h2>
                <p
                  dangerouslySetInnerHTML={{
                    __html: newsItem?.description,
                  }}
                ></p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default LatestNewsCardView;
