import { AppStateType } from '../reducers';

export const dashboardNewsFeedWidgetDataSelector = (state: AppStateType) => {
  return state.DashboardWidgetReducer.newsFeedWidgetData;
};

export const dashboardMoodyNewsFeedWidgetDataSelector = (
  state: AppStateType,
) => {
  return state.DashboardWidgetReducer.moodyNewsFeedWidgetData;
};

export const dashboardTasksWidgetDataSelector = (state: AppStateType) => {
  return state.DashboardWidgetReducer.tasksWidgetData;
};

export const dashboardNplFinancialsWidgetDataSelector = (
  state: AppStateType,
) => {
  return state.DashboardWidgetReducer.nplFinancialWidgetData;
};

export const dashboardTransactionReportsWidgetDataSelector = (
  state: AppStateType,
) => {
  return state.DashboardWidgetReducer.transactionReportsWidgetData;
};

export const dashboardEmbeddedBrowserWidgetDataSelector = (
  state: AppStateType,
) => {
  return state.DashboardWidgetReducer.embeddedBrowserWidgetData;
};

export const allDashboardWidgetsData = (state: AppStateType) => {
  return [
    ...state.DashboardWidgetReducer.newsFeedWidgetData,
    ...state.DashboardWidgetReducer.moodyNewsFeedWidgetData,
    ...state.DashboardWidgetReducer.tasksWidgetData,
    ...state.DashboardWidgetReducer.nplFinancialWidgetData,
    ...state.DashboardWidgetReducer.transactionReportsWidgetData,
    ...state.DashboardWidgetReducer.embeddedBrowserWidgetData,
    ...state.DashboardWidgetReducer.selectedNewsWidgetData,
    ...state.DashboardWidgetReducer.performancePortfolioData,
  ];
};

export const dashboardNewsFeedWidgetsDataSelector = (state: AppStateType) => {
  return state.DashboardWidgetReducer.selectedNewsWidgetData;
};

export const dashboardPerformancePortfolioWidgetData = (
  state: AppStateType,
) => {
  return state.DashboardWidgetReducer.performancePortfolioData;
};
