import ApiUtil from '../../utils/apiUtil';

export default class SharingService {
    async shareVisualisationWidgetExternal(payload: any): Promise<any> {
        const url = '/externalShare/shareVisualisationExternally';
        const res = await ApiUtil.SendAsync<any> ({
          url: url,
          method: 'POST',
          data: payload
        })
        return res.data
    }

    async shareCreditWidgetExternal(payload: any): Promise<any> {
        const url = '/externalShare/shareCreditWidgetExternally';
        const res = await ApiUtil.SendAsync<any> ({
          url: url,
          method: 'POST',
          data: payload
        })
        return res.data
    }

    async shareStatisticalWidget(payload: any): Promise<any> {
        const url = '/externalShare/shareStatisticalWidgetExternally';
        const res = await ApiUtil.SendAsync<any> ({
          url: url,
          method: 'POST',
          data: payload
        })
        return res.data
    }
}