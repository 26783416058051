import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import { TabContent, TabPane } from 'reactstrap';
import { Iconclose } from '../../../../components/public/Icons';
import AddNameAndType from './step-1/AddNameAndType';
import AddSubDataSet from './step-2/AddSubDataSet';
import MarketPlaceService from '../../../../services/marketplace.service';
import useAction from '../../../../components/hooks/useAction';
import AppLoader from '../../../../components/loaders/AppLoader';
import { useSelector } from 'react-redux';
import {
  marketplaceTypeSelector,
  saveAugumentationSourceSelector,
  saveDataSourceSelector,
  saveNewsSourceSelector,
} from '../../../../redux/selector/MarketplaceSelector';
import axios from 'axios';
import config from '../../../../../config';

const marketplaceService = new MarketPlaceService();

type SaveFoundationalModelProps = {
  model_id?: string;
  model_name: string;
  api_key: string;
  isPaidService: boolean;
  serviceType: string;
  azure_api_version?: string;
  azure_endpoint?: string;
  azure_deployment_name?: string;
};

type AddDataMarketplaceProps = {
  toggleAddDataMarketplace: () => void;
  sourceId?: string;
  getFoundationalModelList?: () => void;
};

type ServiceTypeTypes = {
  label: string;
  value: string;
};

const AddDataMarketplace = ({
  toggleAddDataMarketplace,
  sourceId: sourceID,
  getFoundationalModelList,
}: AddDataMarketplaceProps) => {
  //Redux actions
  const {
    showModal,
    saveNewsSource,
    saveDataSource,
    saveAugumentationSource,
  } = useAction();
  const isSaveNewsSourceData = useSelector(saveNewsSourceSelector);
  const isSaveDataSourceData = useSelector(saveDataSourceSelector);
  const isSaveAugumentationSourceData = useSelector(
    saveAugumentationSourceSelector,
  );

  const marketplaceTypeData = useSelector(marketplaceTypeSelector);

  // State for current active Tab
  const [currentActiveTab, setCurrentActiveTab] = useState('1');
  const [isLoading, setIsLoading] = useState(false);

  const [isBuildConnectionRunning, setIsBuildConnectionRunning] = useState(
    false,
  );
  const [isBuildConnection, setIsBuildConnection] = useState(false);
  const [isBuildConnectionError, setIsBuildConnectionError] = useState(false);
  const [isInvalid, setIsInvalid] = useState<boolean>(false);

  //serviceName state and update function
  const [serviceName, setServiceName] = useState('');
  const updateServiceName = (data: string) => {
    setServiceName(data);
  };

  const [apiKey, setApiKey] = useState('');
  const updateApiKeyValue = (data: string) => {
    setApiKey(data);
  };

  const [modelName, setModelName] = useState<{
    label: string;
    value: string;
  } | null>(null);
  const updateModelName = (data: { label: string; value: string }) => {
    setModelName(data);
  };

  const [azureApiVersion, setAzureApiVersion] = useState('');
  const updateAzureApiVersion = (data: string) => {
    setAzureApiVersion(data);
  };
  const [azureEndpoint, setAzureEndpoint] = useState('');
  const updateAzureEndpoint = (data: string) => {
    setAzureEndpoint(data);
  };
  const [azureDeploymentName, setAzureDeploymentName] = useState('');
  const updateAzureDeploymentName = (data: string) => {
    setAzureDeploymentName(data);
  };
  //serviceType state and update function
  const [serviceType, setServiceType] = useState<ServiceTypeTypes>({
    label: 'Data Source',
    value: 'DATA_SOURCE',
  });
  const updateServiceType = (data: { label: string; value: string }) => {
    setServiceType(data);
  };

  //isPaidService state and update function
  const [isPaidService, setIsPaidService] = useState(false);
  const updateIsPaidService = (data: boolean) => {
    setIsPaidService(data);
  };

  const [sourceId, setSourceId] = useState('');

  const buttonText = () => {
    if (serviceType.value === 'FOUNDATIONAL_MODALS') {
      return 'Add Foundational Model';
    }
    if (currentActiveTab === '2') {
      return 'Add Data Item';
    } else {
      return 'Continue';
    }
  };

  // Toggle active state for Tab
  const toggle = (tab: React.SetStateAction<string>) => {
    if (currentActiveTab !== tab) setCurrentActiveTab(tab);
  };

  // const returnDataItemType = (data: string) => {
  //   if (data === 'DATA_SOURCE') {
  //     return { label: 'Data Source', value: 'DATA_SOURCE' };
  //   }

  //   if (data === 'AUGMENTED_DATA_SOURCE') {
  //     return {
  //       label: 'Augumentation data source',
  //       value: 'AUGMENTED_DATA_SOURCE',
  //     };
  //   }

  //   if (data === 'NEWS_SOURCES') {
  //     return { label: 'News source', value: 'NEWS_SOURCES' };
  //   }

  //   if (data === 'FOUNDATIONAL_MODALS') {
  //     return { label: 'Foundational modals', value: 'FOUNDATIONAL_MODALS' };
  //   }
  // };

  const getSourceDataById = () => {
    setIsLoading(true);
    marketplaceService
      .getSourceById(sourceID as string)
      .then(({ data }) => {
        setServiceName(data?.serviceName);
        // setServiceType(
        //   data?.serviceType && returnDataItemType(data?.serviceType),
        // );
        setIsPaidService(data?.isPaidService);
        setSourceId(sourceID as string);
        setIsLoading(false);
      })
      .catch(err => {
        setIsLoading(false);
        showModal(err?.response?.data?.error);
      });
  };

  const returnModelName = (data: string) => {
    if (data === 'azure') {
      return { label: 'AzureOpenAI', value: 'azure' };
    }
    if (data === 'openai') {
      return { label: 'Gpt4', value: 'openai' };
    }
    if (data === 'gemini') {
      return { label: 'Gemini', value: 'gemini' };
    }
    if (data === 'claude') {
      return { label: 'Claude', value: 'claude' };
    }

    if (data === 'mistral') {
      return { label: 'Mistral', value: 'mistral' };
    }

    if (data === 'llama') {
      return { label: 'Llama', value: 'llama' };
    }
  };

  const getFoundationalModelDataById = async () => {
    try {
      setIsLoading(true);
      const response = await axios({
        method: 'get',
        url: `${config.ER_ML_URL}/llm/detail/${sourceID}`,
      });
      const updatedResponse = response?.data?.data;
      setModelName(
        updatedResponse?.model_name &&
          returnModelName(updatedResponse?.model_name),
      );
      setIsPaidService(updatedResponse?.isPaidService);
      setApiKey(updatedResponse?.api_key);
      setAzureApiVersion(updatedResponse?.azure_api_version);
      setAzureEndpoint(updatedResponse?.azure_endpoint);
      setAzureDeploymentName(updatedResponse?.azure_deployment_name);
      setSourceId(sourceID as string);
      setIsLoading(false);
    } catch (error) {
      showModal('something went wrong');
      setIsLoading(false);
    }
  };

  const updateFoundationalModel = async () => {
    try {
      setIsLoading(true);
      const payload: SaveFoundationalModelProps = {
        model_id: sourceID,
        model_name: modelName?.value as string,
        api_key: apiKey,
        isPaidService: isPaidService,
        serviceType: serviceType.value,
        azure_api_version: azureApiVersion,
        azure_endpoint: azureEndpoint,
        azure_deployment_name: azureDeploymentName,
      };

      if (
        payload?.model_name !== 'azure' &&
        payload?.model_name !== 'mistral' &&
        payload?.model_name !== 'llama'
      ) {
        delete payload.azure_endpoint;
      }

      if (payload.model_name !== 'azure') {
        delete payload.azure_api_version;

        delete payload.azure_deployment_name;
      }
      await axios({
        method: 'post',
        url: `${config.ER_ML_URL}/llm/edit`,
        data: payload,
      });
      showModal('Updated successfully!', true);
      setIsLoading(false);
      toggleAddDataMarketplace();
      getFoundationalModelList?.();
      setIsBuildConnection(false);
      setIsBuildConnectionError(false);
      setIsBuildConnectionRunning(false);
    } catch (error) {
      showModal('something went wrong');
      setIsLoading(false);
    }
  };

  const saveFoundationalModel = async () => {
    try {
      setIsLoading(true);
      const payload: SaveFoundationalModelProps = {
        model_name: modelName?.value as string,
        api_key: apiKey,
        isPaidService: isPaidService,
        serviceType: serviceType.value,
        azure_api_version: azureApiVersion,
        azure_endpoint: azureEndpoint,
        azure_deployment_name: azureDeploymentName,
      };

      if (payload.model_name !== 'azure') {
        delete payload.azure_api_version;
        delete payload.azure_endpoint;
        delete payload.azure_deployment_name;
      }
      await axios({
        method: 'post',
        url: `${config.ER_ML_URL}/llm/save`,
        data: payload,
      });
      showModal('Saved successfully!', true);
      setIsLoading(false);
      toggleAddDataMarketplace();
      getFoundationalModelList?.();
      setIsBuildConnection(false);
      setIsBuildConnectionError(false);
      setIsBuildConnectionRunning(false);
    } catch (error) {
      showModal('something went wrong');
      setIsLoading(false);
    }
  };

  const buildConnectionFoundationalModel = async () => {
    setIsBuildConnectionRunning(true);
    setIsBuildConnection(false);
    setIsBuildConnectionError(false);
    try {
      const payload: SaveFoundationalModelProps = {
        model_name: modelName?.value as string,
        api_key: apiKey,
        isPaidService: isPaidService,
        serviceType: serviceType.value,
        azure_api_version: azureApiVersion,
        azure_endpoint: azureEndpoint,
        azure_deployment_name: azureDeploymentName,
      };

      if (payload.model_name !== 'azure') {
        delete payload.azure_api_version;
        delete payload.azure_endpoint;
        delete payload.azure_deployment_name;
      }
      await axios({
        method: 'post',
        url: `${config.ER_ML_URL}/llm/build-connection`,
        data: payload,
      });
      setIsBuildConnection(true);
      setIsBuildConnectionError(false);
      setIsBuildConnectionRunning(false);
      showModal('Success!', true);
    } catch (error) {
      showModal('something went wrong');
      setIsBuildConnection(false);
      setIsBuildConnectionError(true);
      setIsBuildConnectionRunning(false);
    }
  };

  const saveMarketplaceButton = () => {
    if (
      currentActiveTab === '1' &&
      serviceType.value === 'FOUNDATIONAL_MODALS'
    ) {
      if (!sourceID) {
        saveFoundationalModel();
      }

      if (sourceID) {
        updateFoundationalModel();
      }
    }

    if (
      currentActiveTab === '1' &&
      (serviceType.value === 'NEWS_SOURCES' ||
        serviceType.value === 'DATA_SOURCE' ||
        serviceType.value === 'AUGMENTED_DATA_SOURCE') &&
      !sourceID
    ) {
      const payload = {
        serviceName,
        isPaidService,
        serviceType: serviceType.value,
      };
      marketplaceService
        .addSourceStep1(payload)
        .then(({ data }) => {
          // showModal('Field Updated!', true);
          toggle(String(Number(currentActiveTab) + 1));
          setSourceId(data.id);
        })
        .catch(err => {
          showModal(err?.response?.data?.error);
        });
    }

    if (
      currentActiveTab === '1' &&
      (serviceType.value === 'NEWS_SOURCES' ||
        serviceType.value === 'DATA_SOURCE' ||
        serviceType.value === 'AUGMENTED_DATA_SOURCE') &&
      sourceID
    ) {
      const payload = {
        serviceName,
        isPaidService,
        serviceType: serviceType.value,
        sourceId: sourceID,
      };
      marketplaceService
        .updateSourceStep1(payload)
        .then(() => {
          toggle(String(Number(currentActiveTab) + 1));
        })
        .catch(err => {
          showModal(err?.response?.data?.error);
        });
    }
    if (currentActiveTab === '2' && serviceType.value === 'NEWS_SOURCES') {
      saveNewsSource(!isSaveNewsSourceData);
      toggleAddDataMarketplace();
    }

    if (currentActiveTab === '2' && serviceType.value === 'DATA_SOURCE') {
      saveDataSource(!isSaveDataSourceData);
      toggleAddDataMarketplace();
    }
    if (
      currentActiveTab === '2' &&
      serviceType.value === 'AUGMENTED_DATA_SOURCE'
    ) {
      saveAugumentationSource(!isSaveAugumentationSourceData);
      toggleAddDataMarketplace();
    }

    if (
      currentActiveTab !== '2' &&
      serviceType.value !== 'NEWS_SOURCES' &&
      serviceType.value !== 'DATA_SOURCE' &&
      serviceType.value !== 'AUGMENTED_DATA_SOURCE' &&
      serviceType.value !== 'FOUNDATIONAL_MODALS'
    ) {
      toggle(String(Number(currentActiveTab) + 1));
    }

    if (
      currentActiveTab === '2' &&
      serviceType.value !== 'NEWS_SOURCES' &&
      serviceType.value !== 'DATA_SOURCE' &&
      serviceType.value !== 'AUGMENTED_DATA_SOURCE'
    ) {
      toggleAddDataMarketplace();
    }
  };

  useEffect(() => {
    setServiceType(marketplaceTypeData);
  }, [marketplaceTypeData]);

  useEffect(() => {
    if (sourceID) {
      if (marketplaceTypeData.value !== 'FOUNDATIONAL_MODALS') {
        getSourceDataById();
      }

      if (marketplaceTypeData.value === 'FOUNDATIONAL_MODALS') {
        getFoundationalModelDataById();
      }
    }
  }, []);

  return (
    <>
      {isLoading && <AppLoader title={''} />}
      <div className="addwidget-wrapper">
        <div className="sub-header justify-content-between">
          <h4 className="title-20">Add Data Marketplace Item</h4>
          <button
            className="btn btn-icon p-0"
            onClick={() => {
              if (
                currentActiveTab === '2' &&
                serviceType.value === 'NEWS_SOURCES'
              )
                saveNewsSource(!isSaveNewsSourceData);
              if (
                currentActiveTab === '2' &&
                serviceType.value === 'DATA_SOURCE'
              )
                saveDataSource(!isSaveDataSourceData);
              if (
                currentActiveTab === '2' &&
                serviceType.value === 'AUGMENTED_DATA_SOURCE'
              )
                saveAugumentationSource(!isSaveAugumentationSourceData);
              toggleAddDataMarketplace();
            }}
          >
            <Iconclose />
          </button>
        </div>
        <div className="addwidget-main">
          <div className="container container-1024">
            <div className="addwidget-header">
              {currentActiveTab === '1' && (
                <h4 className="title-20">Choose data item type and name</h4>
              )}
              {currentActiveTab === '2' && (
                <h4 className="title-20">
                  Select available endpoints for "{serviceName}"
                </h4>
              )}

              <div className="tabs-h-menu"></div>
              <ul className="addwidget-steps">
                <li>
                  <button
                    className={classnames({
                      active: currentActiveTab === '1',
                    })}
                    // onClick={() => {
                    //   toggle('1');
                    // }}
                  >
                    1
                  </button>
                </li>
                {serviceType.value !== 'FOUNDATIONAL_MODALS' && (
                  <li>
                    <button
                      className={classnames({
                        active: currentActiveTab === '2',
                      })}
                      // onClick={() => {
                      //   toggle('2');
                      // }}
                    >
                      2
                    </button>
                  </li>
                )}
              </ul>
            </div>
            <TabContent activeTab={currentActiveTab}>
              <TabPane tabId="1">
                <AddNameAndType
                  serviceName={serviceName}
                  serviceType={serviceType}
                  isPaidService={isPaidService}
                  modelName={modelName}
                  apiKey={apiKey}
                  azureApiVersion={azureApiVersion}
                  azureEndpoint={azureEndpoint}
                  azureDeploymentName={azureDeploymentName}
                  isBuildConnection={isBuildConnection}
                  isBuildConnectionRunning={isBuildConnectionRunning}
                  isBuildConnectionError={isBuildConnectionError}
                  updateServiceName={updateServiceName}
                  updateServiceType={updateServiceType}
                  updateIsPaidService={updateIsPaidService}
                  updateModelName={updateModelName}
                  updateApiKeyValue={updateApiKeyValue}
                  updateAzureApiVersion={updateAzureApiVersion}
                  updateAzureEndpoint={updateAzureEndpoint}
                  updateAzureDeploymentName={updateAzureDeploymentName}
                  buildConnectionFoundationalModel={
                    buildConnectionFoundationalModel
                  }
                  isInvalid={isInvalid}
                  setIsInvalid={setIsInvalid}
                />
              </TabPane>

              <TabPane tabId="2">
                {currentActiveTab === '2' && (
                  <AddSubDataSet sourceId={sourceId} />
                )}
              </TabPane>
            </TabContent>
          </div>
          <div className="gap-12 d-flex justify-content-center">
            <button
              className="btn btn-outline-info"
              onClick={() => {
                if (currentActiveTab === '1') {
                  toggleAddDataMarketplace();
                }

                toggle(String(Number(currentActiveTab) - 1));
              }}
            >
              {currentActiveTab === '1' ? 'Cancel' : 'Back'}
            </button>
            <button
              className="btn btn-primary"
              onClick={saveMarketplaceButton}
              disabled={
                serviceType.value === 'FOUNDATIONAL_MODALS' &&
                isInvalid &&
                !isBuildConnection
              }
            >
              {buttonText()}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddDataMarketplace;
