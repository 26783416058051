import { IconInfo } from '../../../../../components/public/Icons';
import { CustomTooltip } from '../../../../../components/ui/CustomTooltip';
import EndpointCollapse from '../../../portfolio/addportfoliowidget/creditWidgetWorkflow/customComponents/EndpointCollapse';

type NewsSourceListTypes = {
  _id: string;
  serviceName: string;
  serviceType: string;
  serviceUrl: string;
  isPaidService: boolean;
  id: string;
  endpointCount: number;
};

type SelectNewsSourceProps = {
  newsSourceList: Array<NewsSourceListTypes>;
  comeFrom?: string;
  isDashboard?: boolean;
  isLoading?: boolean;
};

const SelectNewsSource = ({
  newsSourceList,
  comeFrom,
  isDashboard,
  isLoading,
}: SelectNewsSourceProps) => {
  return (
    <>
      <div className="addwidget-content mw-100">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-6">
            <div className="d-flex align-items-center">
              <p className="widget-category me-1 mb-0">Select News Source(s)</p>
              <span className="d-flex" id="tooltip11">
                <IconInfo />
              </span>
              <CustomTooltip placement="top" target="tooltip11" />
            </div>
            <div className="create-widget create-widget-portfolio">
              <div className="widget-item">
                <div className="row row-cols-1">
                  {newsSourceList?.length > 0
                    ? newsSourceList?.map(sourceData => (
                        <EndpointCollapse
                          sourceData={sourceData}
                          isSingleCheck={
                            comeFrom === 'isDashboard' ? false : true
                          }
                          isDashboard={isDashboard}
                        />
                      ))
                    : !isLoading && 'No Record Found!'}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SelectNewsSource;
