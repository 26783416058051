/* ACTIONS */
import { rowIndexConstant } from '../constant';

const { ADD_ROW_INDEX } = rowIndexConstant;

export const addRowIndex = (data: any) => {
  return {
    type: ADD_ROW_INDEX,
    payload: {
      rowIndex: data,
    },
  };
};
