import { AppStateType } from '../reducers';

export const newsSourceCountSelector = (state: AppStateType) => {
  return state.MarketplaceReducer.newsSourceCount;
};
export const dataSourceCountSelector = (state: AppStateType) => {
  return state.MarketplaceReducer.dataSourceCount;
};
export const augumentationSourceCountSelector = (state: AppStateType) => {
  return state.MarketplaceReducer.augumentationSourceCount;
};

export const foundationalModelCountSelector = (state: AppStateType) => {
  return state.MarketplaceReducer.foundationalModelCount;
};

export const saveNewsSourceSelector = (state: AppStateType) => {
  return state.MarketplaceReducer.isSaveNewsSource;
};

export const saveDataSourceSelector = (state: AppStateType) => {
  return state.MarketplaceReducer.isSaveDataSource;
};

export const saveAugumentationSourceSelector = (state: AppStateType) => {
  return state.MarketplaceReducer.isSaveAugumentationSource;
};

export const marketplaceTypeSelector = (state: AppStateType) => {
  return state.MarketplaceReducer.marketplaceType;
};
