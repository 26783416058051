import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//import { useSelector } from 'react-redux';
// import ImgNews from '../../../../../assets/images/news.png';
import { Nav, NavItem, NavLink } from 'reactstrap';
// import { NewsWidgetDataPayload } from '../../../../../@types/NewsWidgetType';
import useAction from '../../../../components/hooks/useAction';
import useDebounce from '../../../../components/hooks/useDebounce';
import WidgetLoader from '../../../../components/loaders/WidgetLoader';
//import { userData } from '../../../redux/selector/UserSelector';
import {
  IconChatbot,
  IconCross,
  IconFullScreen,
  IconTick,
  Iconclose,
} from '../../../../components/public/Icons';
import { callTelemetryRecord } from '../../../../helpers/utils.helper';
import { chatData } from '../../../../redux/selector/ChatSelector';
// import { dashboardNewsFeedWidgetDataSelector } from '../../../../redux/selector/DashboardWidgetSelector';
import {
  newsFeedWidgetsDataSelector,
  selectedNewsEndpointSelector,
} from '../../../../redux/selector/NewsFeedWidgetsSelector';
import { widgetRankData } from '../../../../redux/selector/WidgetRankSelector';
import { widgetReferenceIdData } from '../../../../redux/selector/WidgetReferenceIdSelector';
// import NewsService from '../../../../services/news.service';
import moment from 'moment';
import SendWidgetAsTask from './SendWidgetAsTask';
import ShareWidgetExternally from './ShareWidgetExternally';
import ExportWidgeTData from './ExportWidgeTData';
import RemoveWidget from './RemoveWidget';
// import PublicChat from '../../../../components/public/PublicChat';
import MarketPlaceService from '../../../../services/marketplace.service';
import classnames from 'classnames';
import {
  News,
  NewsSourceListTypes,
} from '../../../../../@types/NewsWidgetType';
import NewsService from '../../../../services/news.service';
import SelectWidgetNewsSources from '../../dashboard/addDashboardWidget/newsfeedWidgetWorkflow/SelectWidgetNewsSources';
import LatestNewsCardView from '../customComponents/LatestNewsCardView';
import {
  GET_TOP_FIVE_NEWS,
  groupAndSortNewsByDate,
} from '../../../../helpers/constants.function';
import DateWiseNewsCard from '../customComponents/DateWiseNewsCard';
import WidgetMenuDropdown from '../../../../components/ui/WidgetMenuDropdown';
import PublicChat from '../../../../components/public/PublicChat';
import NewsImage from '../../../../../assets/images/dummy-news.jpg';
// import { addNewsFeedData } from '../../../../redux/actions';
import {
  dashboardNewsFeedWidgetDataSelector,
  dashboardNewsFeedWidgetsDataSelector,
} from '../../../../redux/selector/DashboardWidgetSelector';
import { serachedNewsQuery } from '../../../../redux/selector/ChatQuerySelector';
import { clearNewsQuery, updateNewsFeedData } from '../../../../redux/actions';
// import { updateNewsFeedData } from '../../../../redux/actions';

const marketplaceService = new MarketPlaceService();
const newsService = new NewsService();

const NewsfeedsWidget = ({
  onClose,
  newsWidgetIndex,
  type,
  widgetFullScreen,
  setWidgetFullScreen,
}: any) => {
  // const slider = useRef<any>();

  const [isChatOpen, setIsChatOpen] = React.useState(false);
  const dispatch = useDispatch();
  const [widgetCurrentActiveTab, setWidgetCurrentActiveTab] =
    React.useState('WidgetData');
  const newsQueryData = useSelector(serachedNewsQuery);

  const [widgetName, setWidgetName] = React.useState('Newsfeed');
  const [isWidgetNameChanged, setIsWidgetNameChanged] = React.useState(false);
  const [explodedIndex, setExplodedIndex] = React.useState('');

  const toggle = (tab: React.SetStateAction<string>) => {
    if (widgetCurrentActiveTab !== tab) setWidgetCurrentActiveTab(tab);
  };

  const toggleFilterModal = () => {
    // document.body.classList.toggle('has-overlay');
    document.body.classList.add('show-widget-chat');
  };

  const toggleWidgetFullScreen = (type?: string) => {
    document.body.classList.toggle('widget-fullscreen');
    setWidgetFullScreen((widgetFullScreen: any) => !widgetFullScreen);
    setIsChatOpen(false);

    // fullscreen widget go to top on fullscreen click
    const pagecontent = document.querySelector('.page-content');
    if (pagecontent) {
      pagecontent.scroll({ top: 0 });
    }

    if (type === 'add') {
      document.body.classList.add(`widget-${newsWidgetIndex}`);
      document.body.classList.add('widget-fullscreen');

      const addElement = document.getElementById(
        `fs-newsfeed-${newsWidgetIndex}`,
      );
      setExplodedIndex(newsWidgetIndex);
      if (addElement) {
        const parentElement = addElement.closest('.react-grid-item');
        if (parentElement) {
          setTimeout(() => {
            //
            parentElement.classList.add('widget-active');
          }, 10);
        }
      }
    } else if (type === 'remove') {
      document.body.classList.remove(`widget-${newsWidgetIndex}`);
      document.body.classList.remove('widget-fullscreen');

      const removeElement = document.getElementById(
        `fsrem-newsfeed-${newsWidgetIndex}`,
      );
      setExplodedIndex('');
      if (removeElement) {
        const parentElement = removeElement.closest('.react-grid-item');
        if (parentElement) {
          parentElement.classList.remove('widget-active');
        }
      }
    }
  };
  //open modal - Widget As Task
  const [sendWidgetAsTaskModal, setSendWidgetAsTaskModal] = useState(false);
  const toggleSendWidgetAsTaskModal = () =>
    setSendWidgetAsTaskModal(!sendWidgetAsTaskModal);

  //open modal - Share Externally
  const [shareExternallyModal, setShareExternallyModal] = useState(false);
  const toggleShareExternallyModal = () =>
    setShareExternallyModal(!shareExternallyModal);

  //open modal - Export WidgeT Data
  const [exportWidgeTDataModal, setExportWidgeTDataModal] = useState(false);
  const toggleExportWidgeTDataModal = () =>
    setExportWidgeTDataModal(!exportWidgeTDataModal);

  //remove modal - remove widget
  const [removeWidgetModal, setRemoveWidget] = useState(false);
  const toggleRemoveWidgetModal = () => setRemoveWidget(!removeWidgetModal);

  // const [viewType, setViewType] = useState('list');
  const [newsArray, setNewsArray] = useState<
    {
      id: string | number;
      news: News;
    }[]
  >([]);

  const [newsSourceList, setNewsSourceList] = useState<
    Array<NewsSourceListTypes>
  >([]);
  const [searchParams, setSearchParams] = useState('');
  // Use Actions
  const {
    showModal,
    addWidgetType,
    removeNewsFeedData,
    addNewsFeedData,
    // addDashboardNewsFeedData,
    removeDashboardNewsFeedData,
    addWidgetReferenceId,
    clearSelectedEndNewsPoint,
  } = useAction();
  //const [isSearching, setIsSearching] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  // const [newsEventId, setNewsEventId] = useState('');
  const widRank = useSelector(widgetRankData);
  const chatMsg = useSelector(chatData);
  const newsFeedData = useSelector(newsFeedWidgetsDataSelector);
  const selectedNewsWidgetData = newsFeedData[newsWidgetIndex];
  const selectedEndpoint = useSelector(selectedNewsEndpointSelector);

  const dashboardNewsFeedData = useSelector(
    dashboardNewsFeedWidgetDataSelector,
  );

  const selectedDashboardNewsFeedData = useSelector(
    dashboardNewsFeedWidgetsDataSelector,
  );

  const debouncedUserInput = useDebounce(searchParams, 1000);
  const widgetReferenceIdValue = useSelector(widgetReferenceIdData);
  const [dropdownOpen, setDropDownOpen] = useState(false);
  const toggleDropDown = () => {
    setDropDownOpen((state) => !state);
  };

  useEffect(() => {
    let searchValue = [];
    if (newsWidgetIndex === widgetReferenceIdValue) {
      for (let chat in chatMsg) {
        let filteredVal = chatMsg[chat]
          ?.filter((a: any) => a.widgetType?.toUpperCase() === 'NEWS_FEED')
          ?.reverse();
        searchValue.unshift(filteredVal);
      }

      if (searchValue[0]?.[0]?.isSearch === true) {
        setSearchParams(searchValue[0]?.[0]?.message || '');
      } /*else {
      setSearchParams(searchValue[1]?.[0]?.message || '');
    }*/
    }

    //setIsSearching(true);
  }, [chatMsg]);

  useEffect(() => {
    if (!selectedNewsWidgetData?.newsList) {
      if (location?.pathname?.includes('newsfeed')) {
        getNewsData();
      }
    } else {
      setNewsArray(newsFeedData?.[newsWidgetIndex]?.newsList || []);
    }
  }, [debouncedUserInput, newsFeedData.length]);

  useEffect(() => {
    if (
      location?.pathname?.includes('dashboard') &&
      dashboardNewsFeedData?.[0]?.newsArray?.length > 0
    ) {
      getAllDashboardNewsSources();
    }
  }, [dashboardNewsFeedData]);

  const getAllNewsSources = () => {
    setIsLoading(true);
    newsService
      .getNewsSourceList()
      .then(({ data }) => {
        setNewsSourceList(data);
        setIsLoading(false);
      })
      .catch((err) => {
        showModal(err?.response?.data?.message);
        setIsLoading(false);
      });
  };

  const getAllDashboardNewsSources = () => {
    setIsLoading(true);
    // const idArray = dashboardNewsFeedData?.[0]?.newsArray?.map(
    //   (item: any) => item._id,
    // );

    newsService
      .getDashboardNewsSourceList({
        serviceIds: selectedDashboardNewsFeedData?.map(
          (x: any) => x?.selectedNewsId,
        ),
      })
      .then(({ data }) => {
        setNewsArray(data);
        setIsLoading(false);
      })
      .catch((err) => {
        showModal(err?.response?.data?.message);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (widgetCurrentActiveTab === 'WidgetDataSources') {
      getAllNewsSources();
    }
  }, [widgetCurrentActiveTab]);

  const handleNewsClick = async (url: string) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  // const handleSearchChange = (e: any) => {
  //   setSearchParams(e.target.value);
  //   //setIsSearching(true);
  // };

  const removeNewsFeedWidget = () => {
    onClose();

    if (widgetFullScreen) {
      toggleWidgetFullScreen('remove');
    }

    if (type === 'NEWSFEED') {
      removeNewsFeedData(newsWidgetIndex);
      clearSelectedEndNewsPoint();
    }

    if (type === 'DASHBOARD') {
      removeDashboardNewsFeedData(newsWidgetIndex);
    }

    if (
      widRank &&
      widRank.widgetRank &&
      widRank?.widgetRank?.slots?.length > 0
    ) {
      callTelemetryRecord(
        'widgets',
        widRank?.widgetRank?.event_id,
        widRank?.widgetRank?.slots[0]?.id,
        ['close'],
      );
    }
  };

  const getNewsData = async () => {
    if (newsFeedData?.length > 0) {
      setIsLoading(true);
      const selectedNewsObject = newsFeedData[newsWidgetIndex];
      const { selectedNewsId } = selectedNewsObject;

      const payload = {
        serviceId: selectedNewsId,
      };

      marketplaceService
        .searchEndpointTable(payload)
        .then(({ data }) => {
          setNewsArray(data);
          setIsLoading(false);
          dispatch(
            addNewsFeedData({
              ...selectedNewsWidgetData,
              newsList: data,
            }),
          );
        })
        .catch((err) => {
          removeNewsFeedData(newsWidgetIndex);
          clearSelectedEndNewsPoint();
          setIsLoading(false);
          showModal(err?.response?.data?.error);
        });
    }
  };

  React.useEffect(() => {
    if (!newsQueryData) return;

    if (location?.pathname?.includes('newsfeed')) {
      const selectedNewsObject = newsFeedData[newsWidgetIndex];
      const { selectedNewsId, id } = selectedNewsObject;

      if (id === explodedIndex) {
        const keyword = newsQueryData.replace(/^search\s*/i, '').trim();
        const payload = {
          serviceId: selectedNewsId,
          searchQuery: keyword,
        };

        const fetchNewsData = () => {
          setIsLoading(true);
          newsService
            .getUpdatedNews(payload)
            .then(({ data }) => {
              setNewsArray(data);
              dispatch(
                updateNewsFeedData(id, {
                  ...selectedNewsWidgetData,
                  newsList: data,
                }),
              );
              setIsLoading(false);
              dispatch(clearNewsQuery());
            })
            .catch(err => {
              showModal(err?.response?.data?.error);
              setIsLoading(false);
            });
        };

        fetchNewsData();
      }
    } else {
      const keyword = newsQueryData.replace(/^search\s*/i, '').trim();
      const selectedNewsId =
        dashboardNewsFeedData?.[0]?.newsArray?.[0]?.selectedNewsId;

      const payload = {
        serviceId: selectedNewsId,
        searchQuery: keyword,
      };
      setIsLoading(true);
      newsService
        .getUpdatedNews(payload)
        .then(({ data }) => {
          if (Array.isArray(data)) {
            setNewsArray(data);
            dispatch(
              addNewsFeedData({
                ...selectedNewsWidgetData,
                newsList: data,
              }),
            );
            setIsLoading(false);
            dispatch(clearNewsQuery());
          }
        })
        .catch((err) => {
          showModal(err?.response?.data?.error);
          setIsLoading(false);
        });
    }
  }, [newsQueryData]);

  React.useEffect(() => {
    setWidgetName(dashboardNewsFeedData[newsWidgetIndex]?.customWidgetName);
  }, []);

  const categorizedNews =
    newsArray?.length > 0 ? groupAndSortNewsByDate(newsArray) : [];

  return (
    <>
      {isLoading && <WidgetLoader title="" />}
      <div className="common-widget newsfeed-widget">
        <div className="common-widget-header cwh-tabs">
          <div className="common-widget-header-title">
            <h2>
              {!isWidgetNameChanged ? (
                widgetName ? (
                  widgetName
                ) : (
                  <>
                    Newsfeed
                    <span>
                      {newsFeedData[newsWidgetIndex]?.selectedNewsName}
                    </span>
                  </>
                )
              ) : (
                <div className="flexbox-c gap-12 no-drag">
                  <input
                    type="text"
                    placeholder="Enter new widget name"
                    className="form-control form-control-sm mw-260"
                    value={widgetName}
                    onChange={(e) => {
                      setWidgetName(e.target.value);
                    }}
                  />
                  <button
                    className="btn-clear flexbox-c gap-1 text-info"
                    onClick={() => {
                      setIsWidgetNameChanged(false);
                    }}
                  >
                    <IconCross fill="#36567D" />
                    Cancel
                  </button>
                  <button
                    className="btn-clear flexbox-c gap-1 text-primary"
                    onClick={() => {
                      setIsWidgetNameChanged(false);
                    }}
                  >
                    <IconTick />
                    Save
                  </button>
                </div>
              )}
            </h2>

            {widgetFullScreen && (
              <div className="ms-6 no-drag">
                <WidgetMenuDropdown
                  dropdownOpen={dropdownOpen}
                  toggleDropDown={toggleDropDown}
                  toggleSendWidgetAsTaskModal={toggleSendWidgetAsTaskModal}
                  toggleShareExternallyModal={toggleShareExternallyModal}
                  toggleExportWidgeTDataModal={toggleExportWidgeTDataModal}
                  toggleRemoveWidgetModal={toggleRemoveWidgetModal}
                  setIsWidgetNameChanged={setIsWidgetNameChanged}
                />
              </div>
            )}

            {!widgetFullScreen && (
              <div className="d-flex aling-items-center ms-12 no-drag">
                <button
                  id={`fs-newsfeed-${newsWidgetIndex}`}
                  type="button"
                  className="btn-icon"
                  onClick={() => {
                    toggleWidgetFullScreen('add');
                    addWidgetType('NEWS_FEED');
                  }}
                >
                  <IconFullScreen />
                </button>
                <button
                  type="button"
                  className={isChatOpen ? 'icon-active btn-icon' : 'btn-icon'}
                  onClick={() => {
                    setIsChatOpen(!isChatOpen);
                    toggleFilterModal();
                    addWidgetType('NEWS_FEED');
                    addWidgetReferenceId(newsWidgetIndex);
                    if (
                      widRank &&
                      widRank.widgetRank &&
                      widRank?.widgetRank?.slots?.length > 0
                    ) {
                      callTelemetryRecord(
                        'widgets',
                        widRank?.widgetRank?.event_id,
                        widRank?.widgetRank?.slots[0]?.id,
                        ['chat'],
                      );
                    }
                  }}
                >
                  <IconChatbot index={'derivative'} />
                </button>
              </div>
            )}

            {widgetFullScreen && (
              <div className="tabs-menu-line mb-0 ms-auto no-drag">
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: widgetCurrentActiveTab === 'WidgetData',
                      })}
                      onClick={() => {
                        toggle('WidgetData');
                      }}
                    >
                      Widget Data
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: widgetCurrentActiveTab === 'WidgetDataSources',
                      })}
                      onClick={() => {
                        toggle('WidgetDataSources');
                      }}
                    >
                      Widget Data Sources
                    </NavLink>
                  </NavItem>
                </Nav>
              </div>
            )}
          </div>

          {widgetFullScreen && (
            <div className="common-widget-header-action no-drag">
              <button
                id={`fsrem-newsfeed-${newsWidgetIndex}`}
                type="button"
                className="btn-icon"
                onClick={() => {
                  toggleWidgetFullScreen('remove');
                  // document.body.classList.toggle('has-overlay');
                  // document.body.classList.toggle('show-widget-chat');
                }}
              >
                <Iconclose />
              </button>
            </div>
          )}

          {!widgetFullScreen && (
            <div className="common-widget-header-action no-drag">
              {/* Create Component Dropdown  */}
              <WidgetMenuDropdown
                dropdownOpen={dropdownOpen}
                toggleDropDown={toggleDropDown}
                toggleSendWidgetAsTaskModal={toggleSendWidgetAsTaskModal}
                toggleShareExternallyModal={toggleShareExternallyModal}
                toggleExportWidgeTDataModal={toggleExportWidgeTDataModal}
                toggleRemoveWidgetModal={toggleRemoveWidgetModal}
                setIsWidgetNameChanged={setIsWidgetNameChanged}
              />
            </div>
          )}
        </div>

        {/* Widget Body */}

        <div
          className="common-widget-content"
          onClick={() => {
            if (
              widRank &&
              widRank.widgetRank &&
              widRank?.widgetRank?.slots?.length > 0
            ) {
              callTelemetryRecord(
                'widgets',
                widRank?.widgetRank?.event_id,
                widRank?.widgetRank?.slots[0]?.id,
                ['click'],
              );
            }
          }}
        >
          {/* Chat True Show*/}
          {isChatOpen && !widgetFullScreen && (
            <div className="widget-chat">
              <PublicChat />
            </div>
          )}
          {/* grid view && show only full widget */}
          {widgetFullScreen && widgetCurrentActiveTab === 'WidgetData' && (
            <>
              <div className={`news-widget grid-view no-drag`}>
                {newsArray?.length > 0 ? (
                  <>
                    <LatestNewsCardView
                      newsTopData={GET_TOP_FIVE_NEWS({ newsData: newsArray })}
                      handleNewsClick={handleNewsClick}
                      newsWidgetIndex={newsWidgetIndex}
                    />
                  </>
                ) : (
                  <div
                    className="col-12 no-record"
                    style={{ minHeight: ' 250px' }}
                  >
                    {!isLoading && 'No Record Found'}
                  </div>
                )}
              </div>

              {/* Data other than latest date */}
              <div className={`news-widget no-drag`}>
                {newsArray?.length > 0 ? (
                  <>
                    {categorizedNews?.length &&
                      categorizedNews?.map((category, index) => (
                        <DateWiseNewsCard
                          key={index}
                          newsTopData={category.data}
                          handleNewsClick={handleNewsClick}
                          newsWidgetIndex={newsWidgetIndex}
                          title={category.title}
                        />
                      ))}
                  </>
                ) : (
                  <div
                    className="col-12 no-record"
                    style={{ minHeight: ' 250px' }}
                  >
                    {!isLoading && 'No Record Found'}
                  </div>
                )}
              </div>
            </>
          )}

          {widgetFullScreen &&
            widgetCurrentActiveTab === 'WidgetDataSources' && (
              <SelectWidgetNewsSources
                newsSourceList={newsSourceList}
                selectedId={newsFeedData[newsWidgetIndex]?.selectedNewsId}
                selectedSourceDataId={
                  newsFeedData[newsWidgetIndex]?.selectedNewsSourceId
                }
              />
            )}

          {/* Chat True Hide */}
          {!isChatOpen && !widgetFullScreen && (
            <>
              {/* List view */}
              <div className={`news-widget no-drag`}>
                {/* FullScreen */}
                <div className={`row-g-10 row`}>
                  {newsArray?.length ? (
                    newsArray?.map((news: any) => (
                      <div
                        key={news?.id}
                        className={`newsfeed-item col-md-12 col-xl-6`}
                      >
                        <div
                          className="news-inner-item"
                          onClick={() => {
                            handleNewsClick(news?.url);
                            // callTelemetryRecord('news', newsEventId, news?.id, [
                            //   'click',
                            // ]);
                          }}
                        >
                          <div className="newsfeed-image">
                            <img
                              src={news?.thumbnail_url}
                              alt="news_image"
                              onError={(e) => {
                                e.currentTarget.src = NewsImage;
                              }}
                            />
                          </div>
                          <div className="newsfeed-description">
                            <div className="newsfeed-publisher">
                              <p
                                className="newsfeed-date"
                                dangerouslySetInnerHTML={{
                                  __html: moment(news?.date_published).isValid()
                                    ? moment(news?.date_published).format(
                                        'MM/DD/YYYY',
                                      )
                                    : news?.date_published,
                                }}
                              ></p>
                            </div>
                            <h2
                              dangerouslySetInnerHTML={{
                                __html: news?.title,
                              }}
                            ></h2>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: news?.description,
                              }}
                            ></p>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div
                      className="col-12 no-record"
                      style={{ minHeight: ' 250px' }}
                    >
                      {!isLoading && 'No Result Found'}
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      {sendWidgetAsTaskModal && (
        <SendWidgetAsTask
          modal={sendWidgetAsTaskModal}
          toggle={toggleSendWidgetAsTaskModal}
          type="NEWS"
          newsId={selectedEndpoint?.id}
          newsName={selectedEndpoint?.sourceName}
          newsSourceId={selectedEndpoint?.sourceId}
        />
      )}

      {shareExternallyModal && (
        <ShareWidgetExternally
          modal={shareExternallyModal}
          toggle={toggleShareExternallyModal}
        />
      )}

      {exportWidgeTDataModal && (
        <ExportWidgeTData
          modal={exportWidgeTDataModal}
          toggle={toggleExportWidgeTDataModal}
        />
      )}

      {removeWidgetModal && (
        <RemoveWidget
          modal={removeWidgetModal}
          toggle={toggleRemoveWidgetModal}
          removeWidget={removeNewsFeedWidget}
        />
      )}
    </>
  );
};

export default NewsfeedsWidget;
