export const userDetailsConstant = {
  SUCCESS_USER_DETAILS: 'SUCCESS_USER_DETAILS',
  SUCCESS_USER_DETAILS_UPDATE: 'SUCCESS_USER_DETAILS_UPDATE',
};

export const genderOptions = [
  { label: 'Male', value: 'Male' },
  { label: 'Female', value: 'Female' },
  { label: 'Other', value: 'Other' },
];

export const sharingOptions = [
  { label: 'Allowed', value: 'Allowed' },
  { label: 'Restricted', value: 'Restricted' },
];

export const positionOptions = [
  { label: 'Credit Management', value: 'Credit Management' },
  { label: 'Portfolio Manager', value: 'Portfolio Manager' },
  { label: 'Controller', value: 'Controller' },
  { label: 'Structuring', value: 'Structuring' },
  { label: 'Underwriter', value: 'Underwriter' },
  { label: 'Credit Risk', value: 'Credit Risk' },
  { label: 'Distribution', value: 'Distribution' },
  { label: 'Sales', value: 'Sales' },
  { label: 'Marketing', value: 'Marketing' },
  { label: 'Issuer', value: 'Issuer' },
  { label: 'Broker', value: 'Broker' },
];

export const typeOptions = [
  { label: 'Intermediary', value: 'Intermediary' },
  { label: 'Data Provider', value: 'Data Provider' },
  { label: 'Buyer', value: 'Buyer' },
  { label: 'Seller', value: 'Seller' },
];

export const productsOptions = [
  {
    value: 'Stock',
    label: 'Stock',
  },
  {
    value: 'Bonds',
    label: 'Bonds',
  },
  {
    value: 'Funds',
    label: 'Funds',
  },
  {
    value: 'Futures',
    label: 'Futures',
  },
  {
    value: 'Options',
    label: 'Options',
  },
  {
    value: 'Swaps',
    label: 'Swaps',
  },
  {
    value: 'CDO',
    label: 'CDO',
  },
  {
    value: 'ABS',
    label: 'ABS',
  },
  {
    value: 'Leverage Loans',
    label: 'Leverage Loans',
  },
];

export const creditsOptions = [
  {
    value: 'Real Estate',
    label: 'Real Estate',
  },
  {
    value: 'Receivables',
    label: 'Receivables',
  },
  {
    value: 'Tax',
    label: 'Tax',
  },
  {
    value: 'Loans',
    label: 'Loans',
  },
  {
    value: 'Mortgages',
    label: 'Mortgages',
  },
  {
    value: 'Leasing',
    label: 'Leasing',
  },
  {
    value: 'Credit Cards',
    label: 'Credit Cards',
  },
];

export const functionsOptions = [
  {
    value: 'Credit',
    label: 'Credit',
  },
  {
    value: 'Due Diligence',
    label: 'Due Diligence',
  },
  {
    value: 'Analyst',
    label: 'Analyst',
  },
  {
    value: 'Trading',
    label: 'Trading',
  },
  {
    value: 'UnderWriting',
    label: 'UnderWriting',
  },
  {
    value: 'Market Data',
    label: 'Market Data',
  },
  {
    value: 'Research',
    label: 'Research',
  },
  {
    value: 'Monitoring',
    label: 'Monitoring',
  },
];

export const statisticalWidgetOptions = [
  {
    value: 'NPL',
    label: 'NPL',
  },
  {
    value: 'LOAN',
    label: 'LOAN',
  },
  {
    value: 'RECEIVABLE',
    label: 'RECEIVABLE',
  },
];
