import ChartBody from './ChartBody';
import {
  chartColors,
  // darkColors,
  heatMapColors,
  // PurpleExrColor,
  stackedChartColors,
} from '../chartsTheme';
import { CHARTS } from '../constants';
// import {
//   barChartTestData,
//   heatMapTestData,
//   radarChartTestData,
//   stackedBarChartTestData,
//   waterfallChartTestData,
// } from '../testData';
import React, { useState } from 'react';
import { widgetRankData } from '../../../../../../../redux/selector/WidgetRankSelector';
import {
  IconChatbot,
  Iconclose,
  // IconCross,
  IconFullScreen,
} from '../../../../../../../components/public/Icons';
import ChartWidgetMenuDropdown from '../../../../../../../components/ui/ChartWidgetMenuDropdown';
import RemoveWidget from '../../../../../newsfeed/widgets/RemoveWidget';
import { useSelector } from 'react-redux';
// import { allPortfolioWidgetsSelector } from '../../../../../../../redux/selector/PortfolioWidgetsSelector';
import { callTelemetryRecord } from '../../../../../../../helpers/utils.helper';
import useAction from '../../../../../../../components/hooks/useAction';
import PublicChat from '../../../../../../../components/public/PublicChat';
import {
  heatType,
  LineType,
  RadarType,
  ScatterType,
  StackedType,
} from '../../../../../../../../@types/PortfolioType';
import { allDashboardWidgetsData } from '../../../../../../../redux/selector/DashboardWidgetSelector';
// import { barChartTestData } from '../testData';

interface ChartsWrapperProps {
  onClose: any;
  chartsWidgetIndex: number;
  type?: string;
  widgetFullScreen: boolean;
  setWidgetFullScreen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ChartsWrapper = ({
  onClose,
  chartsWidgetIndex,
  widgetFullScreen,
  setWidgetFullScreen,
}: // type
ChartsWrapperProps) => {
  const widRank = useSelector(widgetRankData);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const allDashboardWidgetReduxData = useSelector(allDashboardWidgetsData);
  const {
    removePortfolioVisualizationWidgetData,
    addWidgetReferenceId,
    addWidgetType,
  } = useAction();

  const chartsConfiguration = allDashboardWidgetReduxData[chartsWidgetIndex];

  const chartType = chartsConfiguration?.visualisationType?.value;

  const toggleFilterModal = () => {
    // document.body.classList.toggle('has-overlay');
    document.body.classList.add('show-widget-chat');
  };

  const toggleWidgetFullScreen = (type?: string) => {
    document.body.classList.toggle('widget-fullscreen');
    setWidgetFullScreen((widgetFullScreen: any) => !widgetFullScreen);
    setIsChatOpen(false);

    // fullscreen widget go to top on fullscreen click
    const pagecontent = document.querySelector('.page-content');
    if (pagecontent) {
      pagecontent.scroll({ top: 0 });
    }

    if (type === 'add') {
      document.body.classList.add(`widget-${chartsWidgetIndex}`);
      document.body.classList.add('widget-fullscreen');

      const addElement = document.getElementById(
        `fs-charts-${chartsWidgetIndex}`,
      );
      if (addElement) {
        const parentElement = addElement.closest('.react-grid-item');
        if (parentElement) {
          setTimeout(() => {
            //
            parentElement.classList.add('widget-active');
          }, 10);
        }
      }
    } else if (type === 'remove') {
      document.body.classList.remove(`widget-${chartsWidgetIndex}`);
      document.body.classList.remove('widget-fullscreen');

      const removeElement = document.getElementById(
        `fsrem-charts-${chartsWidgetIndex}`,
      );
      if (removeElement) {
        const parentElement = removeElement.closest('.react-grid-item');
        if (parentElement) {
          parentElement.classList.remove('widget-active');
        }
      }
    }
  };

  const [dropdownOpen, setDropDownOpen] = useState(false);
  const toggleDropDown = () => {
    setDropDownOpen((state) => !state);
  };

  const [removeWidgetModal, setRemoveWidget] = useState(false);
  const toggleRemoveWidgetModal = () => setRemoveWidget(!removeWidgetModal);

  const removeChartsWidget = () => {
    onClose();

    if (widgetFullScreen) {
      toggleWidgetFullScreen('remove');
    }

    removePortfolioVisualizationWidgetData(chartsWidgetIndex);
    document.body.classList.remove('widget-fullscreen');

    if (
      widRank &&
      widRank.widgetRank &&
      widRank?.widgetRank?.slots?.length > 0
    ) {
      callTelemetryRecord(
        'widgets',
        widRank?.widgetRank?.event_id,
        widRank?.widgetRank?.slots[0]?.id,
        ['close'],
      );
    }
  };

  const combinedLabels = [
    ...(chartsConfiguration?.fields?.xAxis.map((item: any) => item.label) ||
      []),
    ...(chartsConfiguration?.fields?.yAxis.map((item: any) => item.label) ||
      []),
  ];

  const combinedValues = [
    ...(chartsConfiguration?.fields?.xAxis.map((item: any) => item.value) ||
      []),
    ...(chartsConfiguration?.fields?.yAxis.map((item: any) => item.value) ||
      []),
  ];

  // const [editWidgetModal, setEditWidgetModal] = useState(false);
  // const toggleEditWidgetModal = () => setEditWidgetModal(!editWidgetModal);

  const returnTestData = () => {
    const tempData = chartsConfiguration?.data;
    let lineChartData: LineType[] = [];
    let radarChartData: RadarType[] = [];
    let scatterChartData: ScatterType[] = [];
    let stackedChartData: StackedType[] = [];
    let heatChartData: heatType[] = [];
    let pieChartData: any[] = [];
    let waterfallChartData: any[] = [];

    const barChartData = [
      {
        x: combinedLabels,
        y: combinedValues,
        type: chartType?.toLowerCase(),
        marker: {
          color: 'rgba(149,68,213,1)',
          line: {
            width: 1,
            color: 'rgba(149,68,213,1)',
          },
        },
      },
    ];

    const bubbleChartData = [
      {
        x: combinedLabels,
        y: combinedValues,
        mode: 'markers',
        type: 'scatter',
      },
    ];

    if (chartType === CHARTS.LINE) {
      if (tempData) {
        lineChartData = [
          {
            x: combinedLabels,
            y: combinedValues,
            fill: '',
            type: 'scatter',
            mode: 'lines+markers',
            marker: {
              color: 'rgba(149,68,213,1)',
            },
          },
        ];
      }
    }

    if (chartType === CHARTS.PIE) {
      if (tempData) {
        pieChartData = [
          {
            labels: combinedLabels,
            values: combinedValues,
            type: 'pie',
            marker: {
              colors: heatMapColors,
            },
          },
        ];
      }
    }

    // if (chartType === CHARTS.WATERFALL) {
    //   if (tempData) {
    //     lineChartData = Object.keys(tempData).map((key, index) => {
    //       return {
    //         x: tempData[key]?.x[0],
    //         y: tempData[key]?.y[0],
    //         fill: 'tonexty',
    //         type: 'scatter',
    //         mode: '',
    //         marker: {
    //           color: chartColors[index % chartColors.length],
    //         },
    //       };
    //     });
    //   }
    // }

    if (chartType === CHARTS.AREA) {
      if (tempData) {
        lineChartData = Object.keys(combinedLabels).map(() => {
          return {
            x: combinedLabels,
            y: combinedValues,
            fill: 'tonexty',
            mode: '',
            type: 'scatter',
            marker: {
              color: 'rgba(149,68,213,1)',
            },
          };
        });
      }
    }

    if (chartType === CHARTS.RADAR) {
      if (tempData) {
        radarChartData = [
          {
            r: tempData?.r,
            type: 'scatterpolar',
            theta: tempData?.theta,
            fill: 'toself',
            name: 'Group A',
            marker: {
              color: chartColors[0],
            },
            line: {
              color: chartColors[7],
            },
          },
        ];
      }
    }

    if (chartType === CHARTS.SCATTER) {
      if (tempData) {
        scatterChartData = [
          {
            x: combinedLabels,
            y: combinedValues,
            mode: 'markers',
            type: 'scatter',
            marker: {
              size: 12,
              color: combinedLabels.map(
                (_: any, index: any) =>
                  heatMapColors[index % heatMapColors.length],
              ),
            },
          },
        ];
      }
    }

    if (chartType === CHARTS.STACKED) {
      if (tempData) {
        // console.log(tempData);
        stackedChartData = Object.keys(combinedLabels).map((key, index) => {
          return {
            x: combinedLabels,
            y: combinedValues,
            type: 'bar',
            marker: {
              color: stackedChartColors[index % stackedChartColors.length],
            },
          };
        });
      }
    }

    if (chartType === CHARTS.HEATMAP) {
      if (tempData) {
        const heatMapColorscale = [
          [0, heatMapColors[0]],
          [0.3, heatMapColors[1]],
          [0.6, heatMapColors[2]],
          [0.8, heatMapColors[3]],
          [1, heatMapColors[4]],
        ];
        heatChartData.push({
          z: tempData?.z,
          x: tempData?.x,
          y: tempData?.y,
          type: 'heatmap',
          hoverongaps: false,
          colorscale: heatMapColorscale,
        });
      }
    }

    if (chartType === CHARTS.WATERFALL) {
      if (tempData) {
        waterfallChartData = Object.keys(combinedLabels).map((key, index) => {
          // console.log(tempData);
          return {
            x: combinedLabels, // x-axis data
            y: combinedValues, // y-axis data
            type: 'waterfall',
            orientation: 'v',
            textposition: 'outside',
            measure: [
              'relative',
              'relative',
              'total',
              'relative',
              'relative',
              'relative',
            ],
            // text: tempData[key]?.text, // Texts for labels (e.g., 'Increase', 'Decrease', 'Total')
            decreasing: { marker: { color: chartColors[0] } },
            increasing: { marker: { color: chartColors[1] } },
            totals: { marker: { color: chartColors[2] } },
            connector: {
              line: {
                color: 'rgba(63, 63, 63, 0.5)',
              },
            },
            marker: {
              color: chartColors[index % chartColors.length],
            },
          };
        });
      }
    }

    switch (chartType) {
      case CHARTS.BAR:
        return barChartData;
      // return barChartTestData;
      case CHARTS.LINE:
        return lineChartData;
      case CHARTS.STACKED:
        return stackedChartData;
      // return stackedBarChartTestData;
      case CHARTS.PIE:
        return pieChartData;
      case CHARTS.SCATTER:
        return scatterChartData;
      case CHARTS.AREA:
        return lineChartData;
      case CHARTS.BUBBLE:
        return bubbleChartData;
      case CHARTS.HEATMAP:
        return heatChartData;
      // return heatMapTestData;
      case CHARTS.RADAR:
        return radarChartData;
      // return radarChartTestData;
      case CHARTS.WATERFALL:
        return waterfallChartData;
      default:
        return [];
    }
  };

  return (
    <>
      <div className="common-widget">
        <div className="common-widget-header">
          <div className="common-widget-header-title">
            <h2>{chartsConfiguration?.chartTitle || 'Analytics widget'}</h2>

            {widgetFullScreen && (
              <div className="ms-6 no-drag">
                <ChartWidgetMenuDropdown
                  dropdownOpen={dropdownOpen}
                  toggleDropDown={toggleDropDown}
                  chartSaveModal={() => {}}
                  shareVisualizationModal={() => {}}
                  // toggleEditWidgetModal={toggleEditWidgetModal}
                  toggleRemoveWidgetModal={toggleRemoveWidgetModal}
                />
              </div>
            )}

            {!widgetFullScreen && (
              <div className="d-flex aling-items-center ms-12 no-drag">
                <button
                  id={`fs-charts-${chartsWidgetIndex}`}
                  type="button"
                  className="btn-icon"
                  onClick={() => {
                    toggleWidgetFullScreen('add');
                    addWidgetType('EMBEDDED_BROWSER');
                  }}
                >
                  <IconFullScreen />
                </button>
                <button
                  type="button"
                  className={isChatOpen ? 'icon-active btn-icon' : 'btn-icon'}
                  onClick={() => {
                    setIsChatOpen(!isChatOpen);
                    toggleFilterModal();
                    addWidgetType('EMBEDDED_BROWSER');
                    addWidgetReferenceId(chartsWidgetIndex);
                    if (
                      widRank &&
                      widRank.widgetRank &&
                      widRank?.widgetRank?.slots?.length > 0
                    ) {
                      callTelemetryRecord(
                        'widgets',
                        widRank?.widgetRank?.event_id,
                        widRank?.widgetRank?.slots[0]?.id,
                        ['chat'],
                      );
                    }
                  }}
                >
                  <IconChatbot index={'chart'} />
                </button>
              </div>
            )}
          </div>

          {widgetFullScreen && (
            <div className="common-widget-header-action no-drag">
              <button
                id={`fsrem-charts-${chartsWidgetIndex}`}
                type="button"
                className="btn-icon"
                onClick={() => {
                  toggleWidgetFullScreen('remove');
                }}
              >
                <Iconclose />
              </button>
            </div>
          )}

          {!widgetFullScreen && (
            <div className="common-widget-header-action no-drag">
              <ChartWidgetMenuDropdown
                dropdownOpen={dropdownOpen}
                toggleDropDown={toggleDropDown}
                chartSaveModal={() => {}}
                shareVisualizationModal={() => {}}
                // toggleEditWidgetModal={toggleEditWidgetModal}
                toggleRemoveWidgetModal={toggleRemoveWidgetModal}
              />
            </div>
          )}
        </div>

        <div
          id="chart-dynamic-div"
          className="common-widget-content"
          onClick={() => {
            if (
              widRank &&
              widRank.widgetRank &&
              widRank?.widgetRank?.slots?.length > 0
            ) {
              callTelemetryRecord(
                'widgets',
                widRank?.widgetRank?.event_id,
                widRank?.widgetRank?.slots[0]?.id,
                ['click'],
              );
            }
          }}
        >
          {/* Chat True Show*/}
          {isChatOpen && !widgetFullScreen && (
            <div className="widget-chat">
              <PublicChat />
            </div>
          )}
          {widgetFullScreen && chartType && (
            <ChartBody
              chart={chartsConfiguration}
              chartData={returnTestData()}
            />
          )}
          {!isChatOpen && (
            <ChartBody
              chart={chartsConfiguration}
              // chartData={getDataFromJson()} // USE THIS TO TEST WITH JSON DATA - TRANSFORM IT FIRST
              // chartData={getSelectedFileData()} // USE THIS TO TEST WITH REALTIME DATA - TRANSFORM IT FIRST
              chartData={returnTestData()}
            />
          )}
        </div>
      </div>

      {removeWidgetModal && (
        <RemoveWidget
          modal={removeWidgetModal}
          toggle={toggleRemoveWidgetModal}
          removeWidget={removeChartsWidget}
        />
      )}
    </>
  );
};

export default ChartsWrapper;
