import { applyMiddleware, compose, createStore } from 'redux';
import logger from 'redux-logger';
import thunk from 'redux-thunk';
import rootReducer from './reducers';

// Need Help
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const middleWares: any = [thunk]; // add your middleware here

if (
  process.env.REACT_APP_STAGE === 'dev' ||
  process.env.REACT_APP_STAGE === undefined
) {
  middleWares.push(logger);
}

const store = createStore(
  rootReducer,
  compose(applyMiddleware(...middleWares)),
);

export type AppDispatchType = typeof store.dispatch;
export default store;
