import React, { /* useEffect, */ useRef /* useState */ } from 'react';
import Plot from 'react-plotly.js';
import {
  getXAxisConfig,
  getYAxisConfig,
  getLayoutConfig,
  chartTextColor,
  // chartGridColor,
} from '../../chartsTheme';

const RadarChart = ({
  chartData,
  // chartTitle,
  initialXAxis,
  initialYAxis,
}: {
  chartData: any;
  chartTitle: string;
  initialXAxis: string;
  initialYAxis: string;
}) => {
  // const [updatedData, setUpdatedData] = useState([] as any);
  const layoutConfig = getLayoutConfig();

  const plotRef = useRef<any>(null);

  const resizeLayout = () => {
    plotRef.current.resizeHandler();
  };

  const rValues = chartData[0]?.r || [];
  // const rMin = Math.min(...rValues);
  const rMax = Math.max(...rValues);
  // console.log(chartData[0]);
  // console.log(rMin, rMax);
  return (
    <>
      {
        <div className="chart-wrapper" onMouseDown={e => e.stopPropagation()}>
          <Plot
            ref={plotRef}
            data={chartData}
            // data={updatedData}
            config={{
              scrollZoom: true,
              displaylogo: false,
              editable: true,
              responsive: true,
              // autosizable: true,
            }}
            layout={{
              title: '',
              polar: {
                bgcolor: 'transparent',
                radialaxis: {
                  visible: true,
                  range: [0, rMax],
                  gridcolor: chartTextColor,
                  linecolor: chartTextColor,
                },
                angularaxis: {
                  gridcolor: chartTextColor,
                  linecolor: chartTextColor,
                },
              },

              xaxis: getXAxisConfig(initialXAxis),
              yaxis: getYAxisConfig(initialYAxis),
              ...layoutConfig,
            }}
            useResizeHandler={true}
            onRelayout={resizeLayout}
          />
        </div>
      }
    </>
  );
};

export default RadarChart;
