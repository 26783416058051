import classnames from 'classnames';
import React, { useEffect, useState, JSX } from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
// import ImgNemotron from '../../../../../../assets/images/nemotron.png';
// import ImgOrca2 from '../../../../../../assets/images/orca2.png';
// import ImgClaudeAI from '../../../../../../assets/images/claudeai.png';
// import ImgGemini from '../../../../../../assets/images/gemini.png';
import loaderGIF from '../../../../../../assets/images/building-data-loader.gif';
import {
  IconDropdownArrow,
  IconEye,
  IconEyeHide,
  IconInfo,
  IconStar,
  // IconSearch,
} from '../../../../../components/public/Icons';
import { CustomTooltip } from '../../../../../components/ui/CustomTooltip';
import LoanCollateralButton from '../components/LoanCollateralButton';
import CustomSelect from '../../../../../components/ui/CustomSelect';
import useAction from '../../../../../components/hooks/useAction';
import { useSelector } from 'react-redux';
import { userDetailsData } from '../../../../../redux/selector/UserDetailsSelector';
import axios from 'axios';
import config from '../../../../../../config';
// import { isEmpty } from 'lodash';
import {
  AvailableAliasTypes,
  HeaderSchema,
} from '../../../../../../@types/PortfolioType';
import { isAxiosError } from '../../../../../redux/constant';

type ModelListTypes = {
  _id: string;
  model_name: string;
  display_name: string;
};

type RowPreviewDataTypes = {
  [key: string]: Array<{ [key: string]: string | number | null }>;
};

interface FieldData {
  type: string;
  actualFieldName: string;
  alias_suggestion_required: boolean;
  hidden?: boolean;
}

type ResponseObject = Record<string, FieldData>;

interface ValueData {
  [key: string]: FieldData;
}

interface DataObject {
  key: string;
  value: ValueData;
}

type MapPortfolioDataPropsTypes = {
  selectedPortfolio: string[];
  oldPortfoliosData: Array<DataObject>;
  combinedArray: Array<string>;
  portfoliosData: Array<DataObject>;
  setAllFileNames: React.Dispatch<
    React.SetStateAction<{
      [key: string]: {
        key: string;
        file_name: string;
      };
    }>
  >;
  setOldPortfoliosData: React.Dispatch<React.SetStateAction<DataObject[]>>;
  setPortfoliosData: React.Dispatch<React.SetStateAction<DataObject[]>>;
  // eslint-disable-next-line no-unused-vars
  toggleField: (dataKey: string, fieldKey: string) => void;
  setAllPortfoliosListing: React.Dispatch<
    React.SetStateAction<
      {
        key: string;
        value: {
          key: string;
          file_name: string;
        };
      }[]
    >
  >;
  availableAliasName: AvailableAliasTypes;
  setAvailableAliasName: React.Dispatch<
    React.SetStateAction<AvailableAliasTypes>
  >;
  rowPreviewData: RowPreviewDataTypes;
  setRowPreviewData: React.Dispatch<React.SetStateAction<RowPreviewDataTypes>>;
  defaultFileMerge: string;
  setDefaultFileMerge: React.Dispatch<React.SetStateAction<string>>;
  joinKey: {
    [key: string]: string;
  };
  setJoinKey: React.Dispatch<
    React.SetStateAction<{
      [key: string]: string;
    }>
  >;
  selectedMergeType: {
    label: string;
    value: string;
  };
};

const MapPortfolioData = ({
  combinedArray,
  portfoliosData,
  setPortfoliosData,
  setAllFileNames,
  toggleField,
  setAllPortfoliosListing,
  availableAliasName,
  setAvailableAliasName,
  rowPreviewData,
  setRowPreviewData,
  selectedPortfolio,
  oldPortfoliosData,
  setOldPortfoliosData,
  defaultFileMerge,
  setDefaultFileMerge,
  joinKey,
  setJoinKey,
  selectedMergeType,
}: MapPortfolioDataPropsTypes) => {
  const [isLoading, setIsLoading] = useState(true);
  const { showModal } = useAction();

  const [allPortfolios, setAllPortfolios] = useState<
    Array<{
      key: string;
      value: {
        key: string;
        file_name: string;
      };
    }>
  >([]);
  const [modelList, setModelList] = useState<
    Array<{
      label: JSX.Element;
      value: string;
    }>
  >([]);
  const userDetails = useSelector(userDetailsData);

  const [selectedModelList, setSelectedModelList] = useState<{
    label: JSX.Element;
    value: string;
  } | null>(null);

  const [currentActiveTab, setCurrentActiveTab] = useState('1');

  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    if (currentIndex < rowPreviewData?.[currentActiveTab]?.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  // Toggle active state for Tab
  const toggle = (tab: React.SetStateAction<string>) => {
    setCurrentIndex(0);
    if (currentActiveTab !== tab) setCurrentActiveTab(tab);
  };

  const getPortfolioDataById = async (id: string) => {
    axios
      .get(
        `${config.ER_ML_URL}/portfolio/${id}?page=1&required_calculated_file=False&is_single_file=True`,
      )
      .then(({ data }) => {
        const responseManipulation = data?.data?.data;
        setRowPreviewData(prev => ({
          ...prev,
          [id]: responseManipulation, // Store each portfolio's data by its id
        }));
      })
      .catch(err => {
        if (isAxiosError(err)) {
          showModal(
            (err.response?.data as { error?: string })?.error ||
              'Unexpected Axios error',
          );
        } else {
          showModal('something went wrong');
        }
      });
  };

  const getFileNamesFromId = async () => {
    try {
      const response = await axios({
        method: 'post',
        url: `${config.ER_ML_URL}/portfolio/get-file-names`,
        data: combinedArray,
      });
      const responseManipulation: {
        [key: string]: {
          key: string;
          file_name: string;
        };
      } = response?.data?.files;
      const keyValuePairs = Object.entries(responseManipulation).map(
        ([key, value]) => ({
          key,
          value,
        }),
      );

      setAllFileNames(responseManipulation);

      if (
        portfoliosData?.findIndex(x => x?.key === keyValuePairs[0]?.key) < 0
      ) {
        fetchAllHeaders(keyValuePairs?.map(x => x?.key));
        await Promise.all(
          keyValuePairs?.map(val => {
            if (selectedPortfolio?.includes(val?.key)) {
              getPortfolioDataById(val?.key);
            }
          }),
        );
      } else {
        setIsLoading(false);
      }

      toggle(keyValuePairs[0]?.key);
      // setDefaultFileMerge(keyValuePairs[0]?.value?.key);

      setAllPortfolios(keyValuePairs);
      setAllPortfoliosListing(keyValuePairs);
    } catch (error) {
      showModal('something went wrong');
    }
  };

  const getFoundationalModelList = async () => {
    try {
      const response = await axios({
        method: 'get',
        url: `${config.ER_ML_URL}/llm/list`,
      });
      const responseManipulation: ModelListTypes[] = response?.data?.data || [];
      if (responseManipulation?.length > 0) {
        let updatedRes = responseManipulation?.map(x => {
          return {
            label: (
              <div className="select-img">
                {/* <img
                src={ImgOrca2}
                height="16"
                width="16"
                className="img-cover"
              /> */}
                <span>{x.display_name}</span>
              </div>
            ),
            value: x?.display_name,
          };
        });

        setSelectedModelList(updatedRes[0]);
        setModelList(updatedRes);
      }
    } catch (error) {
      showModal('something went wrong');
    }
  };

  const getAliasSuggestionsForAllEmptyFields = async () => {
    // setIsLoading(true);
    const fieldsNeedingAlias: string[] = [];
    const fieldIndices: number[] = [];

    portfoliosData.forEach(portfolio => {
      Object.keys(portfolio.value).forEach((fieldName, index) => {
        const field = portfolio.value[fieldName];
        if (field.alias_suggestion_required && !availableAliasName[fieldName]) {
          fieldsNeedingAlias.push(fieldName);
          fieldIndices.push(index);
        }
      });
    });

    if (fieldsNeedingAlias.length > 0 && selectedModelList?.value) {
      try {
        const payload = {
          field_name: fieldsNeedingAlias,
          model_id: selectedModelList.value,
        };

        const response = await axios.post(
          `${config.ER_ML_URL}/portfolio/get-alias-suggestion`,
          payload,
        );

        const aliasSuggestions = response?.data?.name.split('\n');

        setPortfoliosData(prevPortfolios => {
          const updatedPortfolios = [...prevPortfolios];

          updatedPortfolios.forEach(portfolio => {
            fieldIndices.forEach((fieldIndex, i) => {
              const alias = aliasSuggestions[i];
              const fieldName = Object.keys(portfolio.value)[fieldIndex];

              if (fieldName) {
                portfolio.value[fieldName].actualFieldName = alias;
              }
            });
          });
          return updatedPortfolios;
        });
        setIsLoading(false);
      } catch (error) {
        showModal('Something went wrong while fetching alias suggestions');
        setIsLoading(false);
      }
    }
  };

  const getAliasSuggestionForSingleField = async (
    fieldName: string,
    index: number,
  ) => {
    setIsLoading(true);
    if (selectedModelList?.value) {
      try {
        const payload = {
          field_name: [fieldName],
          model_id: selectedModelList.value,
        };

        const response = await axios.post(
          `${config.ER_ML_URL}/portfolio/get-alias-suggestion`,
          payload,
        );

        const aliasSuggestion = response?.data?.name.split('\n')[0];

        setPortfoliosData(prevPortfolios => {
          const updatedPortfolios = [...prevPortfolios];
          const activePortfolio = updatedPortfolios.find(
            portfolio => portfolio.key === currentActiveTab,
          );

          if (activePortfolio) {
            const fieldNames = Object.keys(activePortfolio.value);
            const field = fieldNames[index];

            if (field === fieldName) {
              activePortfolio.value[field].actualFieldName = aliasSuggestion;
            }
          }

          return updatedPortfolios;
        });
        setIsLoading(false);
      } catch (error) {
        showModal('Something went wrong while fetching the alias suggestion');
        setIsLoading(false);
      }
    }
  };

  const addHiddenProperty = (data: ResponseObject): ResponseObject => {
    return Object.entries(data).reduce((acc, [key, field]) => {
      acc[key] = { ...field, hidden: false }; // Initialize hidden as false
      return acc;
    }, {} as ResponseObject);
  };

  const getAllHeaders = async (id: string) => {
    try {
      const response = await axios({
        method: 'get',
        url: `${config.ER_ML_URL}/portfolio/get-headers/${id}`,
      });

      const responseManipulation: HeaderSchema = response?.data?.header_schema;

      if (responseManipulation.isError && responseManipulation?.message) {
        showModal(responseManipulation?.message);
      }

      // Ensure alias names conform to AvailableAliasTypes structure
      const aliasNames: AvailableAliasTypes =
        response?.data?.header_schema?.alias_names || {};
      let oldTempArr = [...oldPortfoliosData];

      oldTempArr.push({
        key: id,
        value: addHiddenProperty(responseManipulation?.data) || {},
      });

      setOldPortfoliosData(oldTempArr);

      return {
        key: id,
        value: addHiddenProperty(responseManipulation?.data) || {},
        aliasNames,
      };
    } catch (error) {
      showModal('Something went wrong while fetching headers');
      return null;
    }
  };

  const fetchAllHeaders = async (ids: string[]) => {
    setIsLoading(true);
    try {
      const allResponses = await Promise.all(ids.map(id => getAllHeaders(id)));

      // Filter out any failed (null) requests
      const successfulResponses = allResponses.filter(
        response => response !== null,
      ) as {
        key: string;
        value: ResponseObject;
        aliasNames: AvailableAliasTypes;
      }[];

      // Collect alias names of type AvailableAliasTypes
      let aliasNames: AvailableAliasTypes = {};
      successfulResponses.forEach(item => {
        if (item?.aliasNames) {
          aliasNames = {
            ...aliasNames,
            ...item.aliasNames, // Merge all alias names into one object
          };
        }
      });
      setAvailableAliasName(aliasNames);

      // Update portfoliosData and oldPortfoliosData
      let tempArr = [...portfoliosData];

      successfulResponses.forEach(({ key, value, aliasNames }) => {
        let pIndex = tempArr?.findIndex(x => x.key === key);

        Object.keys(value).forEach(fieldKey => {
          if (aliasNames[fieldKey]) {
            // Replace `actualFieldName` with `alias_name` and `type` with `field_type`
            value[fieldKey].actualFieldName =
              aliasNames[fieldKey].alias_name ||
              value[fieldKey].actualFieldName;
            value[fieldKey].type =
              aliasNames[fieldKey].field_type || value[fieldKey].type;
          }
        });

        if (pIndex > -1) {
          tempArr[pIndex] = { ...tempArr[pIndex], value };
        } else {
          tempArr.push({ key, value });
        }
      });

      setPortfoliosData(tempArr);
    } catch (error) {
      showModal('Something went wrong');
    } finally {
      setIsLoading(false);
    }
  };

  const updateCategory = (
    fieldName: string,
    category: 'FINANCIAL' | 'COLLATERAL' | 'OTHER',
  ) => {
    setPortfoliosData(prevData =>
      prevData.map(data => ({
        ...data,
        value: Object.fromEntries(
          Object.entries(data.value).map(([key, field]) => {
            const updatedField =
              key === fieldName
                ? {
                    ...field,
                    type: category,
                  }
                : field;

            return [key, updatedField] as [string, FieldData];
          }),
        ),
      })),
    );
  };

  const handleInputChange = (index: number, value: string) => {
    setPortfoliosData(prevPortfolios => {
      const updatedPortfolios = [...prevPortfolios];
      const activePortfolio = updatedPortfolios.find(
        item => item.key === currentActiveTab,
      );
      if (activePortfolio) {
        const fieldNames = Object.keys(activePortfolio.value);
        const fieldName = fieldNames[index];
        if (fieldName) {
          activePortfolio.value[fieldName].actualFieldName = value;
        }
      }

      return updatedPortfolios;
    });
  };

  useEffect(() => {
    if (combinedArray.length > 0) getFileNamesFromId();
  }, [combinedArray.length]);

  useEffect(() => {
    if (userDetails?._id) getFoundationalModelList();
  }, [userDetails?._id]);

  useEffect(() => {
    if (portfoliosData.length > 0) {
      getAliasSuggestionsForAllEmptyFields();
    }
  }, [portfoliosData]);

  const activeData = portfoliosData.find(item => item.key === currentActiveTab);
  const currentRow = rowPreviewData?.[currentActiveTab]?.[currentIndex];

  const toggleAllFieldsVisibility = (key: string, shouldHide: boolean) => {
    setPortfoliosData(prevData =>
      prevData.map(item =>
        item.key === key
          ? {
              ...item,
              value: Object.fromEntries(
                Object.entries(item.value).map(([field, data]) => [
                  field,
                  {
                    ...data,
                    hidden: shouldHide,
                  },
                ]),
              ),
            }
          : item,
      ),
    );
  };

  const determineShouldHideAll = (item: {
    value: { [key: string]: { hidden?: boolean } };
  }) => {
    return Object.values(item.value).some(
      field => field && field.hidden !== true,
    );
  };

  // Usage in component
  const handleToggleAll = (key: string) => {
    // Find the specific item by key
    const currentItem = portfoliosData.find(item => item.key === key);

    if (currentItem) {
      // Determine whether to hide or show all based on current visibility
      const shouldHideAll = determineShouldHideAll(currentItem);

      // Toggle all fields based on the above check
      toggleAllFieldsVisibility(key, shouldHideAll);
    }
  };

  const getCustomMatchedValue = (
    row: Record<string, any> = {},
    field: string = '',
    actualFieldName: string = '',
  ) => {
    const normalize = (str: string) =>
      str.toLowerCase().replace(/[\s-_]+/g, '');

    const normalizedField = normalize(field);
    const normalizedActualFieldName = actualFieldName
      ? normalize(actualFieldName)
      : '';

    const matchedKey = Object.keys(row).find(key => {
      const normalizedKey = normalize(key);
      return (
        normalizedKey === normalizedField ||
        normalizedKey === normalizedActualFieldName
      );
    });

    return matchedKey ? row[matchedKey] : 'N/A';
  };

  return (
    <>
      <div className="addwidget-content mw-100 ">
        {isLoading ? (
          <div className="loader flexbox-align-c">
            <img src={loaderGIF} alt="" />
          </div>
        ) : (
          <>
            <div className="main-widget pe-0 widget-clear">
              <div className="react-grid-item">
                <div className="common-widget">
                  <div className="tabs-menu-line">
                    <Nav tabs>
                      {allPortfolios?.map(pList => (
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: currentActiveTab === pList.key,
                            })}
                            onClick={() => {
                              toggle(pList.key);
                            }}
                          >
                            {pList.value?.file_name}
                          </NavLink>
                        </NavItem>
                      ))}
                    </Nav>
                  </div>
                  <TabContent activeTab={currentActiveTab}>
                    <TabPane tabId={currentActiveTab}>
                      <div className="flexbox">
                        <div className="flexbox-c gap-4 mb-2">
                          <button
                            className="btn btn-outline-secondary text-info"
                            type="button"
                            onClick={() => {
                              handleToggleAll(activeData?.key as string);
                            }}
                          >
                            Toggle Hide All
                          </button>

                          {selectedMergeType?.value === 'join' && (
                            <div className="form-check flexbox-align-c">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id={`default-file-merge`}
                                checked={
                                  allPortfolios?.find(
                                    portfolio =>
                                      portfolio.key === currentActiveTab,
                                  )?.value?.key === defaultFileMerge
                                }
                                onChange={() => {
                                  // Find the portfolio matching the currentActiveTab key
                                  const matchingPortfolio = allPortfolios?.find(
                                    portfolio =>
                                      portfolio.key === currentActiveTab,
                                  );

                                  // Extract the `key` from the `value` object if a match is found
                                  const valueKey =
                                    matchingPortfolio?.value?.key;

                                  // Save the extracted `key` value using setDefaultFileMerge
                                  if (valueKey) {
                                    setDefaultFileMerge(valueKey);
                                  }
                                }}
                              />
                              <label
                                className="ms-2 form-check-label"
                                htmlFor="default-file-merge"
                              >
                                Primary Portfolio
                              </label>
                            </div>
                          )}
                        </div>
                        {modelList?.length > 0 && (
                          <div className="mb-2 me-2">
                            <div className="ai-tools">
                              <div className="select-clear">
                                <CustomSelect
                                  options={modelList}
                                  value={selectedModelList}
                                  isSearchable={false}
                                  render
                                  onChange={(e: {
                                    label: JSX.Element;
                                    value: string;
                                  }) => {
                                    setSelectedModelList(e);
                                  }}
                                />
                                <div className="arrow-icon">
                                  <IconDropdownArrow />
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="table-responsive tablescroll">
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th
                                style={{ width: '55px' }}
                                scope="col"
                                className="text-center"
                              >
                                Hide
                              </th>
                              <th style={{ width: '220px' }} scope="col">
                                <div className="flexbox-c gap-1">
                                  Row Data Mapping
                                  <span
                                    className="d-flex zindex-1"
                                    id="bloomberg"
                                  >
                                    <IconInfo />
                                  </span>
                                  <CustomTooltip
                                    placement="top"
                                    target="bloomberg"
                                  />
                                </div>
                              </th>
                              <th style={{ width: '170px' }} scope="col">
                                Imported header
                              </th>
                              <th style={{ width: '210px' }} scope="col">
                                Header Alias
                              </th>
                              <th style={{ width: '200px' }} scope="col">
                                ROW Data Preview
                              </th>
                              <th>
                                <div className="flexbox gap-12">
                                  <button
                                    className="btn-clear link"
                                    onClick={handlePrevious}
                                    disabled={currentIndex === 0}
                                  >
                                    Previous
                                  </button>
                                  <button
                                    className="btn-clear link"
                                    onClick={handleNext}
                                    disabled={
                                      currentIndex ===
                                      rowPreviewData?.[currentActiveTab]
                                        ?.length -
                                        1
                                    }
                                  >
                                    Next
                                  </button>
                                </div>
                              </th>
                            </tr>
                          </thead>

                          <tbody>
                            {activeData &&
                              Object.entries(activeData.value).map(
                                ([fieldName, details], index) => (
                                  <tr key={`${currentActiveTab}-${fieldName}`}>
                                    <td className="text-center">
                                      <button
                                        className="btn btn-icon mx-auto"
                                        onClick={() => {
                                          toggleField(
                                            activeData?.key,
                                            fieldName,
                                          );
                                        }}
                                      >
                                        {details?.hidden ? (
                                          <IconEyeHide />
                                        ) : (
                                          <IconEye />
                                        )}
                                      </button>
                                    </td>
                                    <td className="text-uppercase">
                                      <LoanCollateralButton
                                        index={index}
                                        // row={row}
                                        fieldName={fieldName}
                                        categoryData={details?.type}
                                        availableAliasName={availableAliasName}
                                        updateCategory={updateCategory}
                                        aliasSuggestion={
                                          details?.alias_suggestion_required
                                        }
                                        // aliasSuggestion={true}
                                        getAliasSuggestion={() => {
                                          getAliasSuggestionForSingleField(
                                            fieldName,
                                            index,
                                          );
                                        }}
                                      />
                                    </td>
                                    <td
                                      className="c-pointer"
                                      onClick={() => {
                                        if (
                                          selectedMergeType?.value === 'join'
                                        ) {
                                          let tempObj = { ...joinKey };

                                          if (
                                            tempObj[currentActiveTab] ===
                                            fieldName
                                          ) {
                                            delete tempObj[currentActiveTab];
                                          } else {
                                            tempObj[
                                              currentActiveTab
                                            ] = fieldName;
                                          }

                                          setJoinKey(tempObj);
                                        }
                                      }}
                                    >
                                      <button className="btn btn-clear d-flex gap-2 align-items-center">
                                        {fieldName}
                                        {selectedMergeType?.value === 'join' &&
                                          joinKey[currentActiveTab] ===
                                            fieldName && <IconStar />}
                                      </button>
                                    </td>
                                    <td>
                                      <input
                                        type="text"
                                        placeholder="Click to name"
                                        className="form-control min-w-150"
                                        value={
                                          // details?.alias_suggestion_required
                                          details?.actualFieldName || ''
                                        }
                                        onChange={e => {
                                          handleInputChange(
                                            index,
                                            e.target.value,
                                          );
                                        }}
                                      />
                                    </td>
                                    <td colSpan={2}>
                                      {getCustomMatchedValue(
                                        currentRow,
                                        fieldName,
                                        details.actualFieldName,
                                      )}
                                    </td>
                                  </tr>
                                ),
                              )}
                          </tbody>
                        </table>
                      </div>
                    </TabPane>
                  </TabContent>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default MapPortfolioData;
